import {
  getItem,
  setItem,
} from "@avinet/adaptive-ui-core/utils/syncedLocalStorage";

import LOCAL_STORAGE from "../../contants/LocalStorage";

async function saveToStore<T>(key: LOCAL_STORAGE, value: T): Promise<void> {
  return await setItem(key, JSON.stringify(value));
}

async function getFromStore<T>(key: LOCAL_STORAGE): Promise<T | null> {
  const result = await getItem(key);
  try {
    if (result) {
      return JSON.parse(result) as T;
    } else {
      return Promise.resolve(null);
    }
  } catch (err) {
    console.log("Problem secure store");
    return Promise.reject(err);
  }
}

async function removeFromStore(key: LOCAL_STORAGE): Promise<void> {
  return window.localStorage.removeItem(key);
}

export { saveToStore, getFromStore, removeFromStore };
