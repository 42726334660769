import { useCallback, useEffect, useMemo, useState } from "react";

import { IMediaFeature, PostMedia, isPostMedia } from "../api/types";
import { FEATURE_MEDIA_TYPE } from "../contants/FeatureUuid";
import { fileToPostMedia } from "../utils/index.utils";
import { useAttachments } from "./useAttachments";

export function useAttachmentEdit(
  featureType: FEATURE_MEDIA_TYPE,
  featureId: number | undefined
) {
  const { getAttachments } = useAttachments();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const [attachmentsState, setAttachmentsState] = useState<
    (IMediaFeature | PostMedia)[]
  >([]);

  const handleDropImage = useCallback(async (file: File) => {
    const postMedia = await fileToPostMedia(file);
    setAttachmentsState((prev) => [...prev, postMedia]);
  }, []);

  const handleRemoveDropImage = useCallback(
    (attachment: IMediaFeature | PostMedia) => {
      if (isPostMedia(attachment)) {
        setAttachmentsState((prev) =>
          prev.filter(
            (a) =>
              !(
                isPostMedia(a) && a.generated_uuid === attachment.generated_uuid
              )
          )
        );
      } else {
        setAttachmentsState((prev) =>
          prev.map((a) =>
            a.uuid === attachment.uuid ? { ...a, _deleted: true } : a
          )
        );
      }
    },
    [setAttachmentsState]
  );

  const openAttachmentModal = useCallback(() => {
    setShowAttachmentModal(true);
  }, []);

  const closeAttachmentModal = useCallback(
    (data?: Record<string, IMediaFeature | PostMedia>) => {
      if (data) {
        setAttachmentsState(Object.values(data).filter(Boolean));
      }
      setShowAttachmentModal(false);
    },
    []
  );

  const attachmentThumbnail = useMemo(() => {
    return attachmentsState.find((media) => media?.is_thumbnail)?.media;
  }, [attachmentsState]);

  useEffect(() => {
    if (!featureId) return;

    getAttachments(featureType, featureId).then((res) => {
      if (res) setAttachmentsState(res);
    });
  }, [featureId, featureType, getAttachments]);

  return {
    attachmentsState,
    setAttachmentsState,
    handleDropImage,
    showAttachmentModal,
    openAttachmentModal,
    closeAttachmentModal,
    attachmentThumbnail,
    handleRemoveDropImage,
  };
}
