import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import { ExcelExportResponse } from "../api/types";
import { Api } from "./api/api";

export class ExportApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async apiGetExportExcel(
    uuid: string,
    orgId: number,
    filter: string
  ): Promise<ExcelExportResponse> {
    const response: ApiResponse<Blob> = await this.api.apisauce.get(
      `ombruk/exportdata/runexport/${uuid}`,
      {
        organizationId: orgId,
        offset: 0,
        limit: 1000,
        filter: filter,
        sort_order: "DESC",
        sort_column: "id",
      },
      { responseType: "blob" }
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }
}
