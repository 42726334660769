import React, {
  Context,
  SetStateAction,
  createContext,
  useCallback,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import * as API from "../../api/types";
import { DashboardApi } from "../../services/DashboardApi";
import { IContext } from "../ContextInterfaces";

interface DashboardContextConfig {
  getWasteStatistics: (
    org_id: number | undefined | null,
    year: number
  ) => Promise<
    | void
    | API.IWasteStatisticsResponse
    | SetStateAction<API.IWasteStatisticsResponse>
    | KIND_RESPONSE
  >;
  getStatistics: (
    org_id: number | undefined | null,
    month?: number,
    year?: number
  ) => Promise<void | API.IStatisticsResponse>;
}

const defaultConfig: DashboardContextConfig = {
  getWasteStatistics: () => Promise.reject(),
  getStatistics: () => Promise.reject(),
};

export const DashboardContext: Context<DashboardContextConfig> =
  createContext(defaultConfig);

export const DashboardContextProvider = ({
  children,
  environment,
}: IContext) => {
  const getWasteStatistics = useCallback(
    async (org_id: number | null | undefined, year: number) => {
      return new DashboardApi(environment.api)
        .getWasteStatistics(org_id, year)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            return response.data;
          } else {
            return response.kind;
          }
        })
        .catch(console.error);
    },
    [environment.api]
  );

  const getStatistics = useCallback(
    async (
      org_id: number | undefined | null,
      month?: number,
      year?: number
    ) => {
      return new DashboardApi(environment.api)
        .getStatistics(org_id, month, year)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            return response?.data;
          }
        })
        .catch(console.error);
    },
    [environment.api]
  );

  if (!environment) {
    return null;
  }

  return (
    <DashboardContext.Provider
      value={{
        getWasteStatistics,
        getStatistics,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
