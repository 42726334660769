import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import chairImage from "../../assets/images/objects/chair_green.png";
import closetImage from "../../assets/images/objects/closet.png";
import sofaImage from "../../assets/images/objects/sofa.png";
import teacanImage from "../../assets/images/objects/teacan.png";
import { Copyrights } from "../../components/copyrights";
import { dashboardUrl } from "../../contants/Routes";
import { AppUserContext } from "../../context/user-context/UserContext";
import "./errorPage.scss";

export function Error404() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useContext(AppUserContext);

  const isUserLoggedIn = user.value !== undefined;

  return (
    <div className="page-container">
      <div className="errorpage-container">
        <div className="logo">
          <Icon name="logoName" className="sidebar__top__logo" />
        </div>
        <div className="image-container">
          <img className="img teacan" src={teacanImage} alt=""></img>
          <img className="img chair" src={chairImage} alt=""></img>
          <img className="img sofa" src={sofaImage} alt=""></img>
          <img className="img closet" src={closetImage} alt=""></img>

          <div className="circle large"></div>
          <div className="circle medium"></div>
          <div className="circle small"></div>
        </div>

        <div className="text-box">
          <div className="header">
            <h4>404</h4>
            <h2>{t("errorPages.404.title")}</h2>
          </div>
          <div className="seperation-line"></div>
          <div className="text-container">
            <p>{t("errorPages.404.description_1")}</p>
            <p>{t("errorPages.404.description_2")}</p>
            <p>{t("errorPages.404.description_3")}</p>
          </div>
          <button
            className="btn light-purple"
            onClick={() => {
              navigate(dashboardUrl);
            }}
          >
            {isUserLoggedIn
              ? t("errorPages.dashboard")
              : t("errorPages.register")}
          </button>
        </div>
        <div className="copyrights">
          <p className="copyrights__copy">{t("errorPages.copyrights")}</p>
        </div>
      </div>
    </div>
  );
}
