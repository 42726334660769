import { t } from "i18next";
import React, { useCallback } from "react";

import { Icon } from "../../icon/Icon";
import { SortOrderDirection } from "../../page/page-table/PageTable";
import { IPageTableColumnMeta } from "../../page/page-table/table-header/index.props";
import { PopupMenu } from "../../popup-menu/PopupMenu";
import { PopupMenuButton } from "../../popup-menu/PopupMenuButton";
import { Text } from "../../text";
import { TableSortButtonProps } from "./TableSortButton.props";
import "./TableSortButton.styles.scss";

export function TableSortButton({
  columns,
  handleSortItem,
  resetAllColumns,
  disabled,
  activeHeader,
  sortOrder,
}: TableSortButtonProps) {
  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      resetAllColumns();
    },
    [resetAllColumns]
  );

  const preventPropagation = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <PopupMenuButton
      icon="chevron"
      iconRotate={90}
      className="btn light"
      disabled={disabled}
      menu={
        <PopupMenu
          className="table-sort-button"
          fullScreenOn={{ maxWidth: 450, maxHeight: 830 }}
        >
          <div className="columns-modal-content" onClick={preventPropagation}>
            <div className="header">
              <h4>{t("components.sortChoose.title")}</h4>
              <Text tag="p" text={t("components.sortChoose.description")} />
            </div>
            <div className="columnchoose-wrapper">
              {columns.map(
                (element: IPageTableColumnMeta) =>
                  element?.sortable && (
                    <button
                      id={element.key}
                      className="aui-check-box column-checkbox"
                      key={element.key}
                      onClick={() => handleSortItem(element)}
                    >
                      {element.title}
                      <Icon
                        name="arrow"
                        className={`sort-arrow ${
                          activeHeader && activeHeader === element.key
                            ? "sort-arrow--visible"
                            : ""
                        }`}
                        rotate={
                          sortOrder === SortOrderDirection.DESC ? 270 : 90
                        }
                      />
                    </button>
                  )
              )}
            </div>
            <button className="btn-link reset" onClick={handleReset}>
              <Icon name="arrowReset" className="icon" />
              {t("components.sortChoose.reset")}
            </button>
          </div>
        </PopupMenu>
      }
    >
      <span className="table-sort-button__text">
        {t("components.pageTable.sort")}
      </span>
    </PopupMenuButton>
  );
}
