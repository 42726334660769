import { NumberInputEl } from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import { useCallback } from "react";

import { handleTranslateQuantityUnit } from "../../utils/index.utils";
import { Icon } from "../icon/Icon";
import { CounterProps } from "./index.props";
import "./styles.scss";

export function ElementsCounter({
  counter,
  onEditQuantity,
  mapId,
  quantityUnit,
}: CounterProps) {
  const handleCounterInput = useCallback(
    (value: number | null, isValid: boolean) => {
      if (onEditQuantity && isValid && value !== null) {
        onEditQuantity(mapId, value, false);
      }
    },
    [mapId, onEditQuantity]
  );

  return (
    <div className="elements-counter">
      <button
        className={`elements-counter__btn elements-counter__btn--minus ${
          counter <= 1 ? "elements-counter__btn--disabled" : ""
        }`}
        disabled={counter <= 1}
        onClick={() => onEditQuantity(mapId, -1, true)}
      >
        <Icon name="subtract" />
      </button>
      <div className="counter-container">
        <NumberInputEl
          id="counter-input"
          value={counter}
          label={null}
          onChange={handleCounterInput}
        />
        <div className="counter-container__quantity-unit">
          {handleTranslateQuantityUnit(quantityUnit as string)}
        </div>
      </div>
      <button
        className="elements-counter__btn elements-counter__btn--plus"
        onClick={() => onEditQuantity(mapId, 1, true)}
      >
        <Icon name="add" />
      </button>
    </div>
  );
}
