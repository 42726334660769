import Skeleton from "react-loading-skeleton";

import { Text } from "../../text";
import { FeatureDeleteInfoProps } from "./FeatureDeleteInfo.props";
import "./FeatureDeleteInfo.scss";

export function FeatureDeleteInfo({
  infoText,
  amountText,
  isTextLoading,
  amountRedColor,
}: FeatureDeleteInfoProps) {
  return (
    <div className="feature-delete-info">
      <Text
        text={infoText}
        fontWeight={500}
        size="md"
        className="feature-delete-info__feature"
      />
      {isTextLoading ? (
        <Skeleton
          width={22}
          height={21}
          containerClassName="feature-delete-info__skeleton-text"
        />
      ) : (
        <>
          <Text
            text={amountText}
            fontWeight={700}
            size="medium"
            className={`feature-delete-info__amount ${
              amountRedColor ? "feature-delete-info__amount--red" : ""
            }`}
          />
        </>
      )}
    </div>
  );
}
