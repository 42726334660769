import { useMemo } from "react";

import { LOCATION_STATUS } from "../contants/Enums";
import { ILocationFilter } from "../context/filter-context/FilterContext";

export function useLocationFilter(
  locationFilter: Partial<ILocationFilter> | undefined
) {
  const filterString = useMemo(() => {
    // status
    const status = locationFilter?.status;
    const statusFilter = status && `status='${status}'`;

    // location
    //when
    const yearBuilt = locationFilter?.year_built;
    const yearBuiltFilter = yearBuilt && `building_year=${yearBuilt}`;

    //type
    const buildingCategory = locationFilter?.building_category;
    const buildingCategoryFilter =
      buildingCategory && `building_category='${buildingCategory}'`;

    //main material
    const materialType = locationFilter?.material_type;
    const materialTypeFilter =
      materialType && `main_material_use ILIKE '%${materialType}%'`;
    //size
    const fromSize = locationFilter?.from_size;
    const fromSizeFilter = fromSize && `area >= ${fromSize}`;
    const toSize = locationFilter?.to_size;
    const toSizeFilter = toSize && `area <= ${toSize}`;
    const sizeFilter =
      (fromSize && toSize && `area BETWEEN ${fromSize} AND ${toSize}`) ||
      [fromSizeFilter, toSizeFilter].filter(Boolean).join(" AND ");

    //renovation date
    const renovationDate = locationFilter?.renovation_date;
    const renovationDateFilter =
      renovationDate && `rehab_year='${renovationDate}'`;

    //demolition date
    const demolitionDate = locationFilter?.demolition_date;
    const demolitionDateFilter =
      demolitionDate && `demolition_date=DATE('${demolitionDate}')`;

    // active tab
    const activeTab = locationFilter?.activeTab;
    let activeTabFilter = "";
    if (activeTab === "Oppussing") {
      activeTabFilter = `status='${LOCATION_STATUS.RENOVATION}'`;
    } else if (activeTab === "Oppføring") {
      activeTabFilter = `status='${LOCATION_STATUS.NEW}'`;
    } else if (activeTab === "Skal rives") {
      activeTabFilter = `status='${LOCATION_STATUS.DEMOLISHED}'`;
    } else if (activeTab === "Statisk") {
      activeTabFilter = `status='${LOCATION_STATUS.STATIC}'`;
    } else if (activeTab === "Fullført") {
      activeTabFilter = `status='${LOCATION_STATUS.COMPLETED}'`;
    }

    console.log("activeTabFilter", activeTabFilter);

    return [
      statusFilter,
      yearBuiltFilter,
      buildingCategoryFilter,
      materialTypeFilter,
      sizeFilter,
      renovationDateFilter,
      demolitionDateFilter,
      activeTabFilter,
    ]
      .filter(Boolean)
      .join(" AND ");
  }, [locationFilter]);

  return filterString;
}
