export const colors = {
  avGreenDark: "#1D3C34",
  avGreenLight: "#F6F8F1",
  avGreenFrontPage: "#F0F4E3",
  lightContrast: "#F9F9FB",
  darkTextColor: "#111322",
  lightTextColor: "#4A5578",
  gray: "#EFF1F5",
  lightGray: "#fcfcfd",
  mediumGray: "#DCDFEA",
  darkGray: "#30374F",
  white: "#ffffff",
  success: "#D1FADF",
  warning: "#FEF0C7",
  error: "#FEE4E2",
  warning50: "#fffaeb",
  warning700: "#b54708",
  success50: "#ecfdf3",
  success700: "#027a48",
  error50: "#fef3f2",
  error700: "#b42318",
  nocondition50: "#fef3f2",
  darkContrast: "#404968",
};
