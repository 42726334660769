import { format } from "date-fns";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useMemo } from "react";

import { IProductWithMappings } from "../api/types";
import {
  IPageTableColumnData,
  IPageTableColumnMeta,
  IPageTableContent,
  PageTableColumnTypes,
} from "../components/page/page-table/table-header/index.props";
import { OBJECT_STATUS } from "../contants/Enums";
import { FEATURE_UUID } from "../contants/FeatureUuid";
import { DictionaryContext } from "../context/dictionary-context/DictionaryContext";
import { FeatureContext } from "../context/feature-context/FeatureContext";
import { handleTranslateQuantityUnit } from "../utils/index.utils";
import { useColumnState } from "./useColumnState";

export const useObjectTableColumnsWithMappings = (
  product: IProductWithMappings[]
) => {
  const { getCategoryName, getElementName, getParentCategoryName } =
    useContext(DictionaryContext);

  const initialColumnMeta: IPageTableColumnMeta[] = useMemo(() => {
    return [
      {
        key: "id",
        title: t("tables.id"),
        active: false,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "image",
        title: t("tables.picture"),
        active: true,
        sortable: false,
        type: PageTableColumnTypes.IMAGE,
      },
      {
        key: "specification",
        title: t("tables.name"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "obj_condition",
        title: t("tables.condition"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.CONDITION,
      },
      {
        key: "quantity",
        title: t("tables.quantity"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "quantity_unit",
        title: t("tables.quantity_unit"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "fk_location",
        title: t("tables.location"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },

      {
        key: "floor_name",
        title: t("tables.floor"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "room_name",
        title: t("tables.room"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "parent_category",
        title: t("tables.category"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "category",
        title: t("tables.subCategory"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "element",
        title: t("tables.element"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "date_created",
        title: t("tables.dateCreated"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "cost",
        title: t("tables.cost"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "co2",
        title: t("tables.co2"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "production_year",
        title: t("tables.productionYear"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "producer",
        title: t("tables.producer"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "demountability_score",
        title: t("tables.demountabilityScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "remaining_lifetime_score",
        title: t("tables.remainingLifetimeScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "volume_score",
        title: t("tables.volumeScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "demand_score",
        title: t("tables.demandScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "environmental_effect_score",
        title: t("tables.environmentalEffectScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "cost_use_score",
        title: t("tables.costUseScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
    ];
  }, []);

  const { columnState, updateColumnVisibility, resetAllColumns } =
    useColumnState(initialColumnMeta);

  const { locationsList, downloadGenericFeature } = useContext(FeatureContext);

  useEffect(() => {
    downloadGenericFeature(FEATURE_UUID.LOCATION);
  }, [downloadGenericFeature]);

  const columns = useMemo(() => {
    const columnData = product.map((product) => {
      const categoryName = getCategoryName(product.category) || "-";
      const parentCategoryName =
        getParentCategoryName(product.parent_category) || "-";
      const elementName = getElementName(product.element) || "-";
      const locationId = product?.fk_location;

      const location = locationsList.find(
        (location) => location.id === locationId
      );

      const locationName = location?.name || "-";
      const floorName = product.floor_name || "-";
      const roomName = product.room_name || "-";

      return [
        {
          key: "object_id",
          value: product.mapping_id,
          sortable: false,
        },
        {
          key: "id",
          value: product.id,
          sortable: true,
        },
        {
          key: "image",
          value: {
            image: product.mapping_image ?? product.image,
            name: product.specification + " " + product.image,
          },
          sortable: true,
        },
        {
          key: "specification",
          value: product.specification,
          sortable: true,
        },
        {
          key: "obj_condition",
          value: product,
          sortable: true,
        },
        {
          key: "quantity",
          value: product.quantity,
          sortable: true,
        },
        {
          key: "quantity_unit",
          value: handleTranslateQuantityUnit(product.quantity_unit),
          sortable: true,
        },
        {
          key: "fk_location",
          value: locationName,
          sortable: true,
        },
        {
          key: "floor_name",
          value: floorName,
          sortable: true,
        },
        {
          key: "room_name",
          value: roomName,
          sortable: true,
        },
        {
          key: "parent_category",
          value: parentCategoryName,
          sortable: true,
        },
        {
          key: "category",
          value: categoryName,
          sortable: true,
        },
        {
          key: "element",
          value: elementName,
          sortable: true,
        },
        {
          key: "date_created",
          value: product.date_created
            ? format(new Date(product.date_created as string), "dd-MM-yyyy")
            : "-",
          sortable: true,
        },
        {
          key: "cost",
          value: product.cost,
        },
        {
          key: "co2",
          value: product.co2,
          sortable: true,
        },
        {
          key: "production_year",
          value: product.production_year || "-",
          sortable: true,
        },
        {
          key: "producer",
          value: product.producer || "-",
          sortable: true,
        },
        {
          key: "demountability_score",
          value: product.demountability_score || "-",
          sortable: true,
        },
        {
          key: "remaining_lifetime_score",
          value: product.remaining_lifetime_score || "-",
          sortable: true,
        },
        {
          key: "volume_score",
          value: product.volume_score || "-",
          sortable: true,
        },
        {
          key: "demand_score",
          value: product.demand_score || "-",
          sortable: true,
        },
        {
          key: "environmental_effect_score",
          value: product.environmental_effect_score || "-",
          sortable: true,
        },
        {
          key: "cost_use_score",
          value: product.cost_use_score || "-",
          sortable: true,
        },
      ] as IPageTableColumnData[];
    });
    return {
      columns: columnState,
      data: columnData,
      updateColumnVisibility,
      resetAllColumns,
    } as IPageTableContent;
  }, [
    product,
    columnState,
    updateColumnVisibility,
    resetAllColumns,
    getCategoryName,
    getParentCategoryName,
    getElementName,
    locationsList,
  ]);

  const objectTableTabs = useMemo(
    () => [
      {
        text: t("components.pageTable.tabs.locations.all"),
        status: OBJECT_STATUS.ALL,
      },
      {
        text: t("options.condition.good"),
        status: OBJECT_STATUS.GOOD,
      },
      {
        text: t("options.condition.medium"),
        status: OBJECT_STATUS.MEDIUM,
      },
      {
        text: t("options.condition.bad"),
        status: OBJECT_STATUS.BAD,
      },
      {
        text: t("options.condition.noCondition"),
        status: OBJECT_STATUS.NO_CONDITION,
      },
    ],
    []
  );

  const renderObjectTableTitle = useCallback((activeFilter: string) => {
    switch (activeFilter) {
      case t("components.pageTable.tabs.locations.all"):
        return t("pages.objects.titleFilters", {
          filter: t("options.assessmentRating.all"),
        });
      case t("options.condition.good"):
        return t("pages.objects.titleFilters", {
          filter: t("options.condition.good"),
        });
      case t("options.condition.medium"):
        return t("pages.objects.titleFilters", {
          filter: t("options.condition.medium"),
        });
      case t("options.condition.bad"):
        return t("pages.objects.titleFilters", {
          filter: t("options.condition.bad"),
        });
      case t("options.condition.noCondition"):
        return t("pages.objects.titleFilters", {
          filter: t("options.condition.noCondition"),
        });
      default:
        return "";
    }
  }, []);

  return {
    columns,
    objectTableTabs,
    renderObjectTableTitle,
  };
};
