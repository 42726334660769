import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";

import { ALERT_MESSAGE_STATUS, ALERT_MESSAGE_TYPE } from "../../contants/Enums";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { useNotificationApi } from "../../context/NotificationProvider";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import { ISelectedOption } from "../../utils/types/types";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { InputMultiSearchSelect } from "../input-multi-search";
import { Modal } from "../modal";
import { ConnectFeatureModalProps } from "./ConnectFeatureModal.props";
import "./ConnectFeatureModal.scss";

export function ConnectFeatureModal({
  featureUuid,
  projectDetails,
  locationDetails,
  onClose,
  setTotalProducts,
}: ConnectFeatureModalProps) {
  const { t } = useTranslation();
  const {
    selectProjectsName,
    selectLocationsName,
    connectLocationToProject,
    connectProjectToLocation,
    downloadGenericFeature,
  } = useContext(FeatureContext);

  const mode = projectDetails ? "project" : "location";
  const { toast } = useNotificationApi();

  const onSubmit = useCallback(
    async (data: {
      location_list: number[];
      projects: { value: number }[];
    }) => {
      if (featureUuid === FEATURE_UUID.PROJECT) {
        projectDetails?.id &&
          (await connectLocationToProject(
            projectDetails?.id,
            data.location_list
          )
            .then((res) => {
              res && onClose();
              toast({
                title: t("common.success"),
                message: t("common.saveSuccessMessage"),
                type: ALERT_MESSAGE_TYPE.PROFILE,
                status: ALERT_MESSAGE_STATUS.SUCCESS,
              });
              downloadGenericFeature(
                FEATURE_UUID.PROJECT_LOCATIONS,
                `fk_project=${projectDetails?.id}`
              );
              downloadGenericFeature(
                FEATURE_UUID.PROJECT_PRODUCTS,
                `fk_project=${projectDetails?.id}`
              ).then(
                (res) =>
                  res &&
                  setTotalProducts &&
                  typeof res === "number" &&
                  setTotalProducts(res)
              );
            })
            .catch(() => {
              toast({
                title: t("common.error"),
                message: t("common.saveError"),
                type: ALERT_MESSAGE_TYPE.PROFILE,
                status: ALERT_MESSAGE_STATUS.FAILED,
              });
            }));
      }
      if (featureUuid === FEATURE_UUID.LOCATION) {
        locationDetails?.id &&
          (await connectProjectToLocation(
            locationDetails?.id,
            data.projects.map((p) => ({ fk_project: p.value }))
          )
            .then((res) => {
              res && onClose();
              toast({
                title: t("common.success"),
                message: t("common.saveSuccessMessage"),
                type: ALERT_MESSAGE_TYPE.PROFILE,
                status: ALERT_MESSAGE_STATUS.SUCCESS,
              });
            })
            .catch(() => {
              toast({
                title: t("common.error"),
                message: t("common.saveError"),
                type: ALERT_MESSAGE_TYPE.PROFILE,
                status: ALERT_MESSAGE_STATUS.FAILED,
              });
            }));
      }
    },
    [
      featureUuid,
      projectDetails?.id,
      connectLocationToProject,
      onClose,
      toast,
      t,
      downloadGenericFeature,
      setTotalProducts,
      locationDetails?.id,
      connectProjectToLocation,
    ]
  );

  const projects = useMemo(
    () =>
      (locationDetails?.projects
        ?.map((p) => p.id !== undefined && { value: p.id })
        .filter(Boolean) as { value: number }[]) ?? [],
    [locationDetails?.projects]
  );

  const { form, state } = useForm({
    defaultValues: {
      location_list: projectDetails?.location_list ?? [],
      projects,
    },
    onSubmit,
  });

  const [text, setText] = useState({
    title: t("components.connectLocation.titleLocation"),
    description: t("components.connectLocation.descriptionProject"),
    label: t("components.connectLocation.location"),
    placeholder: t("components.projectModal.form.placeholders.newLocation"),
  });

  const renderText = useCallback(() => {
    switch (featureUuid) {
      case FEATURE_UUID.PROJECT:
        return setText({
          title: t("components.connectLocation.titleProject"),
          description: t("components.connectLocation.descriptionProject"),
          label: t("components.connectLocation.location"),
          placeholder: t("components.connectLocation.titleProject"),
        });
      case FEATURE_UUID.LOCATION:
        return setText({
          title: t("components.connectLocation.titleLocation"),
          description: t("components.connectLocation.descriptionLocation"),
          label: t("components.connectLocation.project"),
          placeholder: t("components.connectLocation.titleLocation"),
        });
      default:
        return setText({
          title: t("components.connectLocation.titleProject"),
          description: t("components.connectLocation.descriptionProject"),
          label: t("components.connectLocation.location"),
          placeholder: t("components.connectLocation.titleProject"),
        });
    }
  }, [t, featureUuid]);

  const locationFormValue = form.getValue("location_list");

  const locationsValue = useMemo(
    () =>
      selectLocationsName.filter((location) =>
        locationFormValue.some(
          (locationId: number) => locationId === location.value
        )
      ),
    [locationFormValue, selectLocationsName]
  );

  const handleSelectLocation = useCallback(
    (value: MultiValue<ISelectedOption>) => {
      form.setValue(
        "location_list",
        value.map((location) => location?.value)
      );
    },
    [form]
  );

  const projectFormValue = form.getValue("projects");

  const projectsValue = useMemo(
    () =>
      selectProjectsName.filter((projectOption) =>
        projectFormValue.some(
          (projectValue) => projectValue.value === projectOption.value
        )
      ),
    [projectFormValue, selectProjectsName]
  );

  const handleSelectProject = useCallback(
    (value: MultiValue<ISelectedOption>) => {
      form.setValue("projects", value);
    },
    [form]
  );

  useEffect(() => {
    featureUuid && renderText();
  }, [featureUuid, renderText]);

  return (
    <Modal className="report-options" show>
      <div className="modal-content-container">
        <FeatureModalHeader
          onClose={() => onClose()}
          title={text.title}
          subtitle={text.description}
        />
        <FormProvider form={form} state={state}>
          <form id="report-options" onSubmit={form.submit}>
            {mode === "project" && (
              <InputMultiSearchSelect
                id="location_list"
                options={selectLocationsName}
                label={text.label}
                placeholder={text.placeholder}
                onChange={(value) => handleSelectLocation(value)}
                value={locationsValue}
              />
            )}
            {mode === "location" && (
              <InputMultiSearchSelect
                id="project_list"
                options={selectProjectsName}
                label={text.label}
                placeholder={text.placeholder}
                onChange={(value) => handleSelectProject(value)}
                value={projectsValue}
              />
            )}
          </form>
          <Row className="buttom-buttons" wrap>
            <button className="btn light" type="button" onClick={onClose}>
              {t("common.cancel")}
            </button>
            <button className="btn" type="submit" form="report-options">
              {t("common.connect")}
            </button>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
}
