import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { joinOrganisationUrl, loginUrl } from "../../contants/Routes";
import { IInvite } from "../../utils/types/types";

export const JoinOrganisation = () => {
  const location = useLocation();

  const getQueryParam = (name: string) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const inviteCode = getQueryParam("kode");
  const email = getQueryParam("epost");
  const navigate = useNavigate();

  const urlExtension = useMemo(
    () => "?kode=" + inviteCode + "&epost=" + email,
    [inviteCode, email]
  );

  useEffect(() => {
    if (inviteCode !== null && email !== null) {
      const storedInvites = localStorage.getItem("invites");
      const existingInvites = storedInvites ? JSON.parse(storedInvites) : [];

      const isDuplicate = existingInvites.some(
        (invite: IInvite) =>
          invite.inviteCode === inviteCode && invite.email === email
      );

      if (!isDuplicate) {
        const newInvite = {
          inviteCode: inviteCode,
          email: email,
        };

        const updatedInvites = [...existingInvites, newInvite];

        localStorage.setItem("invites", JSON.stringify(updatedInvites));
      }
    }
  }, [inviteCode, email]);

  useEffect(() => {
    navigate(`${loginUrl}?redirect=${joinOrganisationUrl}${urlExtension}`);
  }, [inviteCode, email, navigate, urlExtension]);

  return <></>;
};
