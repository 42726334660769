import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import React, { FC } from "react";

import { InputPhoneProps } from "./index.props";
import "./styles.scss";

export const InputPhone: FC<InputPhoneProps> = ({
  label,
  placeholder,
  id,
  disabled,
}) => {
  return (
    <Row className="input-phone">
      {/*  Phone number prefix hided for now */}
      {/*<Input*/}
      {/*    className="input-phone__prefix"*/}
      {/*    id="phone_prefix"*/}
      {/*    type="text"*/}
      {/*    defaultValue={"+47"}*/}
      {/*    disabled*/}
      {/*/>*/}
      <Input
        className="input-phone__number"
        id={id}
        type="text"
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        required
      />
    </Row>
  );
};
