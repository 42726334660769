import React, { FC } from "react";

import { AddNewLinkProps } from "./index.props";
import "./styles.scss";

export const AddNewLink: FC<AddNewLinkProps> = ({ link }) => {
  return (
    <div className="add-new-link" onClick={link.onClick}>
      <div className="add-new-link__icon">{link.icon}</div>
      <h4 className="add-new-link__title">{link.title}</h4>
      <h6 className="add-new-link__description">{link.description}</h6>
    </div>
  );
};
