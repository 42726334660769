import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  ALERT_MESSAGE_STATUS,
  ALERT_MESSAGE_TYPE,
  FEATURE_TYPE,
} from "../contants/Enums";

export const useAlertMessage = (invitationsSent?: number) => {
  const { t } = useTranslation();

  const successProfileMessage = {
    title: t("messages.profileSuccessTitle"),
    description: t("messages.profileSuccessDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  };

  const failedProfileMessage = {
    title: t("messages.profileFailedTitle"),
    description: t("messages.profileFailedDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.FAILED,
  };

  const successRegisterMessage = {
    title: t("messages.registerSuccessTitle"),
    description: t("messages.registerSuccessDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  };

  const failedRegisterMessage = {
    title: t("messages.registerFailedTitle"),
    description: t("messages.registerFailedDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.FAILED,
  };

  const successInviteMessage = {
    title: t("messages.orgInviteSuccessTitle"),
    description: `${t(
      "messages.orgInviteSuccessDescriptionPart1"
    )} ${invitationsSent} ${t("messages.orgInviteSuccessDescriptionPart2")}`,
    type: ALERT_MESSAGE_TYPE.INVITE,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  };

  const successDeleteMessage = {
    title: t("messages.orgDeleteSuccessTitle"),
    description: t("messages.orgDeleteSuccessDescription"),
    type: ALERT_MESSAGE_TYPE.INVITE,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  };

  const failedChangedEmailMessage = {
    title: t("messages.profileFailedTitle"),
    description: t("messages.profileEmailFailedDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.FAILED,
  };

  const successChangePlanMessage = {
    title: t("messages.changePlanSuccessTitle"),
    description: t("messages.changePlanSuccessDescription"),
    type: ALERT_MESSAGE_TYPE.PLAN,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  };

  const successUpdateOrgMessage = {
    title: t("messages.orgUpdateSuccessTitle"),
    description: t("messages.orgUpdateDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  };

  const failedUpdateOrgMessage = {
    title: t("messages.orgUpdateFailedTitle"),
    description: t("messages.orgUpdateFailedDescription"),
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.FAILED,
  };

  const handleSetFeatureText = useCallback(
    (featureType: FEATURE_TYPE) => {
      switch (featureType) {
        case FEATURE_TYPE.LOCATION:
          return t("components.noElements.location");
        case FEATURE_TYPE.OBJECT:
          return t("components.noElements.object");
        case FEATURE_TYPE.OBJECT_MAPPING:
          return t("components.noElements.object");
        case FEATURE_TYPE.PROJECT:
          return t("components.noElements.project");
        default:
          return t("components.noElements.location");
      }
    },
    [t]
  );

  const successDeleteFeatureMessage = useCallback(
    (featureType: FEATURE_TYPE) => {
      return {
        title: t("messages.deleteFeatureSuccessTitle", {
          feature: handleSetFeatureText(featureType),
        }),
        description: t("messages.deleteFeatureSuccessDescription", {
          feature: handleSetFeatureText(featureType),
        }),
        type: ALERT_MESSAGE_TYPE.PROFILE,
        status: ALERT_MESSAGE_STATUS.SUCCESS,
      };
    },
    [t, handleSetFeatureText]
  );

  const failedDeleteFeatureMessage = useCallback(
    (featureType: FEATURE_TYPE) => ({
      title: t("messages.deleteFeatureFailedTitle", {
        feature: handleSetFeatureText(featureType),
      }),
      description: t("messages.deleteFeatureFailedDescription"),
      type: ALERT_MESSAGE_TYPE.PROFILE,
      status: ALERT_MESSAGE_STATUS.FAILED,
    }),
    [t, handleSetFeatureText]
  );

  return {
    successProfileMessage,
    failedProfileMessage,
    successRegisterMessage,
    failedRegisterMessage,
    successInviteMessage,
    successDeleteMessage,
    failedChangedEmailMessage,
    successChangePlanMessage,
    successUpdateOrgMessage,
    failedUpdateOrgMessage,
    successDeleteFeatureMessage,
    failedDeleteFeatureMessage,
  };
};
