import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperRefType } from "swiper/types";

import slideOneImage from "../../assets/images/help-modal/slide-1.png";
import slideTwoImage from "../../assets/images/help-modal/slide-2.png";
import slideThreeImage from "../../assets/images/help-modal/slide-3.png";
import { HelpModalSlide } from "../help-modal-slide";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import { HelpModalProps } from "./index.props";
import "./styles.scss";

export const HelpModal: FC<HelpModalProps> = ({
  showHelpModal,
  modalState,
}) => {
  const { t } = useTranslation();
  const [swiperRef, setSwiperRef] = useState<SwiperRefType>();
  const [slide, setSlide] = useState<number>(0);

  const handleNextButton = useCallback(() => {
    if (slide === 2) {
      modalState(false);
    } else {
      swiperRef?.slideNext();
    }
  }, [modalState, swiperRef, slide]);

  const nextButtonText = useCallback(() => {
    switch (slide) {
      case 0:
        return t("common.next");
      case 1:
        return t("common.next");
      case 2:
        return t("common.getStarted");
      default:
        return t("common.next");
    }
  }, [t, slide]);

  return (
    <Modal className="help-modal" show={showHelpModal}>
      <div className="help-modal-container">
        <Swiper
          onSwiper={setSwiperRef}
          centeredSlides
          slidesPerView={1}
          onSlideChange={(slide: SwiperRefType) => setSlide(slide.activeIndex)}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <HelpModalSlide
              image={slideOneImage}
              title={t("pages.selectOrganisation.helpModal.slides.1.title")}
              description={t(
                "pages.selectOrganisation.helpModal.slides.1.description"
              )}
            />
          </SwiperSlide>

          <SwiperSlide>
            <HelpModalSlide
              image={slideTwoImage}
              title={t("pages.selectOrganisation.helpModal.slides.2.title")}
              description={t(
                "pages.selectOrganisation.helpModal.slides.2.description"
              )}
            />
          </SwiperSlide>
          <SwiperSlide>
            <HelpModalSlide
              image={slideThreeImage}
              title={t("pages.selectOrganisation.helpModal.slides.3.title")}
              description={t(
                "pages.selectOrganisation.helpModal.slides.3.description"
              )}
            />
          </SwiperSlide>
        </Swiper>
        <SubmitButtons
          cancelText={t("common.skip")}
          submitText={nextButtonText()}
          onCancel={() => modalState(false)}
          onSubmit={handleNextButton}
        />
      </div>
    </Modal>
  );
};
