/* eslint-disable */
import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import { StatisticsResponse, WasteStatisticsResponse } from "../api/types";
import { Api } from "./api/api";

export class DashboardApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getWasteStatistics(
    org_id: number | undefined | null,
    year: number
  ): Promise<WasteStatisticsResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/organization/wastestatistics?org_id=${org_id}&year=${year}`
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getStatistics(
    org_id: number | undefined | null,
    year?: number,
    month?: number
  ): Promise<StatisticsResponse> {
    let url = `ombruk/organization/statistics?org_id=${org_id}`;
    if (month) {
      url += `&month=${month}`;
    }
    if (year) {
      url += `&year=${year}`;
    }

    const response: ApiResponse<any> = await this.api.apisauce.get(url);
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }
}

/* eslint-enable */
