import Column from "@avinet/adaptive-ui-core/layout/Column";
import { t } from "i18next";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { KIND_RESPONSE } from "../../api/api-problem";
import { IDeleteInfoResponse } from "../../api/types";
import { FEATURE_TYPE } from "../../contants/Enums";
import { locationsUrl, objectsUrl, projectsUrl } from "../../contants/Routes";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import { Text } from "../text";
import { FeatureDeleteModalProps } from "./FeatureDeleteModal.props";
import "./FeatureDeleteModal.scss";
import { FeatureDeleteInfo } from "./feature-delete-info/FeatureDeleteInfo";

export function FeatureDeleteModal({
  onClose,
  multipleFeatures,
  feature,
  featureType,
  previewPageType,
  updatePreviewTableData,
}: FeatureDeleteModalProps) {
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const [deleteValidationError, setDeleteValidationError] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState<IDeleteInfoResponse | null>(
    null
  );
  const [deleteInfoLoading, setDeleteInfoLoading] = useState<boolean>(true);
  const { getDeletedItemInfo, deleteFeature } = useContext(FeatureContext);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    getDeletedItemInfo(
      multipleFeatures?.length ? multipleFeatures : [feature?.id ?? 0],
      featureType
    ).then((res: IDeleteInfoResponse) => {
      res && setDeleteInfo(res);
      setDeleteInfoLoading(false);
    });
  }, [getDeletedItemInfo, feature?.id, featureType, multipleFeatures]);

  const handleCloseModal = useCallback(() => {
    onClose();
    setDeleteInfo(null);
  }, [onClose]);

  const handleNavigateToTablePage = useCallback(() => {
    switch (previewPageType) {
      case FEATURE_TYPE.LOCATION:
        return navigate(locationsUrl);
      case FEATURE_TYPE.OBJECT:
        return navigate(objectsUrl);
      case FEATURE_TYPE.PROJECT:
        return navigate(projectsUrl);
      case FEATURE_TYPE.PROJECT_LOCATION:
      case FEATURE_TYPE.PROJECT_PRODUCTS:
      case FEATURE_TYPE.LOCATION_PROJECT:
      case FEATURE_TYPE.LOCATION_PRODUCT:
      case FEATURE_TYPE.OBJECT_PROJECT:
        return updatePreviewTableData && updatePreviewTableData();
    }
  }, [previewPageType, navigate, updatePreviewTableData]);

  const renderFeatureType = useCallback(() => {
    switch (featureType) {
      case FEATURE_TYPE.LOCATION:
        return multipleFeatures?.length
          ? `${multipleFeatures?.length} ${t(
              "components.noElements.locations"
            )}`
          : t("components.noElements.location");
      case FEATURE_TYPE.OBJECT:
        return multipleFeatures?.length
          ? `${multipleFeatures?.length} ${t("components.noElements.objects")}`
          : t("components.noElements.object");
      case FEATURE_TYPE.PROJECT:
        return multipleFeatures?.length
          ? `${multipleFeatures?.length} ${t("components.noElements.projects")}`
          : t("components.noElements.project");
    }
  }, [featureType, multipleFeatures]);

  const handleDeleteFeature = useCallback(() => {
    if (
      deleteConfirmText.trim() !==
      t("components.featureDeleteModal.deleteConfirmTemplate", {
        featureName: multipleFeatures?.length
          ? renderFeatureType()
          : feature?.name ?? feature?.specification,
      }).trim()
    ) {
      setDeleteValidationError(true);
      return;
    }

    deleteFeature(
      featureType,
      multipleFeatures?.length ? multipleFeatures : [feature?.id ?? 0]
    )?.then((res) => {
      if (res && res.kind === KIND_RESPONSE.OK) {
        previewPageType && handleNavigateToTablePage();
      }

      handleCloseModal();
    });
  }, [
    deleteConfirmText,
    handleCloseModal,
    feature,
    deleteFeature,
    featureType,
    previewPageType,
    handleNavigateToTablePage,
    renderFeatureType,
    multipleFeatures,
  ]);

  const renderDeletedItemsAmount = useCallback(() => {
    switch (featureType) {
      case FEATURE_TYPE.LOCATION:
        return (
          <>
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.location")}
              amountText={String(deleteInfo?.location_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.components")}
              amountText={String(deleteInfo?.product_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.images")}
              amountText={String(deleteInfo?.media_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
          </>
        );
      case FEATURE_TYPE.OBJECT:
        return (
          <>
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.components")}
              amountText={String(deleteInfo?.product_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.images")}
              amountText={String(deleteInfo?.media_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.subComponents")}
              amountText={String(deleteInfo?.product_mapping_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
          </>
        );
      case FEATURE_TYPE.OBJECT_MAPPING:
        return (
          <FeatureDeleteInfo
            infoText={t("components.featureDeleteModal.items.images")}
            amountText={String(deleteInfo?.media_quantity)}
            amountRedColor
            isTextLoading={deleteInfoLoading}
          />
        );
      case FEATURE_TYPE.PROJECT:
        return (
          <>
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.project")}
              amountText={String(deleteInfo?.project_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
            <FeatureDeleteInfo
              infoText={t("components.featureDeleteModal.items.assessments")}
              amountText={String(deleteInfo?.product_recommendation_quantity)}
              amountRedColor
              isTextLoading={deleteInfoLoading}
            />
          </>
        );
    }
  }, [featureType, deleteInfo, deleteInfoLoading]);

  const handleConfirmDelete = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDeleteConfirmText(e.target.value);
      deleteValidationError && setDeleteValidationError(false);
    },
    [deleteValidationError]
  );

  return (
    <Modal className="feature-delete-modal" show>
      <div className="feature-delete-modal-container">
        <FeatureModalHeader
          onClose={handleCloseModal}
          title={t("components.featureDeleteModal.title")}
        />

        {featureType == FEATURE_TYPE.OBJECT_MAPPING ? (
          <>
            <Text
              text={t("components.featureDeleteModal.descriptionComponentPt1")}
              size="sm"
              tag="span"
            />
            <Text
              className="feature-delete-modal__sub-text"
              text={t("components.featureDeleteModal.descriptionComponentPt2")}
              size="sm"
              tag="span"
            />
          </>
        ) : (
          <Text
            className="feature-delete-modal__description"
            text={t("components.featureDeleteModal.descriptionPt1", {
              featureType: renderFeatureType(),
            })}
            size="sm"
            tag="span"
          >
            <>
              <Text
                text={feature?.name?.toString() ?? feature?.specification ?? ""}
                size="sm"
                tag="span"
                fontWeight={700}
              />
              {multipleFeatures?.length ? null : " "}
              {t("components.featureDeleteModal.descriptionPt2")}
            </>
          </Text>
        )}
        <Column align="left">
          <Text
            text={t("components.featureDeleteModal.itemsDeleteListTitle")}
            fontWeight={700}
            size="md"
            className="feature-delete-modal__title feature-delete-modal__red-text"
          />
          <div className="feature-delete-modal__info-wrapper">
            {renderDeletedItemsAmount()}
          </div>
        </Column>

        {featureType !== FEATURE_TYPE.PROJECT &&
          featureType !== FEATURE_TYPE.OBJECT_MAPPING && (
            <Column align="left">
              <Text
                text={t("components.featureDeleteModal.affectedProject")}
                fontWeight={700}
                size="md"
                className="feature-delete-modal__title"
              />
              <div className="feature-delete-modal__info-wrapper">
                <FeatureDeleteInfo
                  infoText={t("components.featureDeleteModal.projectsNumber")}
                  amountText={String(deleteInfo?.project_quantity)}
                  isTextLoading={deleteInfoLoading}
                />
              </div>
            </Column>
          )}

        {featureType === FEATURE_TYPE.LOCATION && (
          <Column align="left">
            <Text
              text={t("components.featureDeleteModal.affectedLocations")}
              fontWeight={700}
              size="md"
              className="feature-delete-modal__title"
            />
            <div className="feature-delete-modal__info-wrapper">
              <FeatureDeleteInfo
                infoText={t("components.featureDeleteModal.locationsNumber")}
                amountText={String(deleteInfo?.location_quantity)}
                isTextLoading={deleteInfoLoading}
              />
            </div>
          </Column>
        )}

        {featureType !== FEATURE_TYPE.OBJECT_MAPPING && (
          <Column
            align="left"
            className="feature-delete-modal__validation-wrapper"
          >
            <div>
              <Text
                text={t(
                  "components.featureDeleteModal.deleteTypeConfirmTextPt1"
                )}
                size="sm"
                fontWeight={500}
              >
                <>
                  <Text
                    text={t(
                      "components.featureDeleteModal.deleteConfirmTemplate",
                      {
                        featureName: multipleFeatures?.length
                          ? renderFeatureType()
                          : feature?.name ?? feature?.specification,
                      }
                    )}
                    size="sm"
                    fontWeight={700}
                    className="feature-delete-modal__red-text"
                  />{" "}
                  {t("components.featureDeleteModal.deleteTypeConfirmTextPt2")}
                </>
              </Text>
            </div>
            <div className="aui-form-control aui-text">
              <input
                id="deleteConfirmText"
                type="text"
                onChange={(e) => handleConfirmDelete(e)}
              />
            </div>
            {deleteValidationError && (
              <Text
                text={t("components.featureDeleteModal.validationErrorMessage")}
                size="xs"
                tag="span"
                className="feature-delete-modal__error-msg feature-delete-modal__red-text"
              />
            )}
          </Column>
        )}

        <SubmitButtons
          deleteMode
          onCancel={handleCloseModal}
          onSubmit={handleDeleteFeature}
          cancelText={t("common.cancel")}
          submitText={t("common.next")}
        />
      </div>
    </Modal>
  );
}
