import { useCallback, useContext, useState } from "react";

import { THUMBNAIL_SIZE } from "../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../contants/FeatureUuid";
import { MediaContext } from "../context/media-context/MediaContext";
import { AppUserContext } from "../context/user-context/UserContext";
import { getMediaUri } from "../utils/index.utils";
import { IAttachment } from "../utils/types/types";

export const useDropImage = () => {
  const { saveMedia, updateMedia, deleteMedia, setAttachments } =
    useContext(MediaContext);
  const [addedImg, setAddedImg] = useState<string>("");
  const [imgId, setImgId] = useState<string>("");
  const [addedFile, setAddedFile] = useState<File | null>(null);
  const { token } = useContext(AppUserContext);

  const onDropImage = useCallback(
    async (file: File, attachment = false) => {
      if (file) {
        const fileUrl = file.name && URL.createObjectURL(file);

        if (!attachment) {
          setAddedImg(fileUrl);
          setAddedFile(file);
        }
        await saveMedia(file, false).then((response) => {
          attachment
            ? setAttachments((prev) => [
                ...prev,
                {
                  mediaUuid: response || "",
                  title: file?.name || "",
                  owner: "",
                  description: "",
                  file: file,
                },
              ])
            : setImgId(response || "");
        });
      }
    },
    [saveMedia, setAttachments]
  );

  const updateImage = useCallback(
    async (
      featureType: FEATURE_MEDIA_TYPE,
      featureId: number,
      attachment?: IAttachment,
      rowId?: number
    ) => {
      if (attachment) {
        await updateMedia(
          attachment.file ?? null,
          attachment.mediaUuid,
          featureType,
          featureId,
          attachment,
          rowId
        );
      } else {
        if (imgId.length && addedFile) {
          await updateMedia(addedFile, imgId, featureType, featureId);
        }
      }
    },
    [imgId, addedFile, updateMedia]
  );

  const handleClearImage = useCallback(
    async (deleteMediaItem?: boolean) => {
      deleteMediaItem && imgId && (await deleteMedia(Number(imgId)));
      setAddedImg("");
      setAddedFile(null);
      setImgId("");
    },
    [deleteMedia, imgId]
  );

  const removeAttachment = (attachmentId: number) => {
    setAttachments((prevState) =>
      prevState.filter((prevItem, index) => index !== attachmentId)
    );
  };

  const getMediaData = useCallback(
    (featureImgId: string) => {
      const img = getMediaUri(featureImgId, token);
      setImgId(featureImgId);
      setAddedImg(img);

      return img;
    },
    [token]
  );

  const downloadImage = useCallback(
    (featureImgId: string) => {
      getMediaUri(featureImgId, token, THUMBNAIL_SIZE.MEDIUM);
    },
    [token]
  );

  return {
    addedImg,
    onDropImage,
    handleClearImage,
    imgId,
    removeAttachment,
    updateImage,
    getMediaData,
    downloadImage,
  };
};
