import { SVGProps } from "react";

export const DocIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21 3H7C6.4475 3 6 3.4475 6 4V7.5015H22V4C22 3.4475 21.5525 3 21 3Z"
      fill="url(#paint0_linear_11044_454)"
    />
    <path
      d="M6 16.527V20C6 20.5525 6.4475 21 7 21H21C21.5525 21 22 20.5525 22 20V16.527H6Z"
      fill="url(#paint1_linear_11044_454)"
    />
    <path
      d="M6 7.50146H22V12.0025H6V7.50146Z"
      fill="url(#paint2_linear_11044_454)"
    />
    <path
      d="M6 12.0024H22V16.5274H6V12.0024Z"
      fill="url(#paint3_linear_11044_454)"
    />
    <path
      opacity="0.05"
      d="M11.1595 6.5H6V18.5H11.1595C12.176 18.5 13 17.676 13 16.6595V8.3405C13 7.324 12.176 6.5 11.1595 6.5Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M11.1065 18H6V6.6665H11.1065C11.9685 6.6665 12.667 7.365 12.667 8.227V16.4395C12.6665 17.3015 11.968 18 11.1065 18Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M11.053 17.5H6V6.8335H11.053C11.76 6.8335 12.333 7.4065 12.333 8.1135V16.22C12.3335 16.927 11.76 17.5 11.053 17.5Z"
      fill="black"
    />
    <path
      d="M11 17H3C2.4475 17 2 16.5525 2 16V8C2 7.4475 2.4475 7 3 7H11C11.5525 7 12 7.4475 12 8V16C12 16.5525 11.5525 17 11 17Z"
      fill="url(#paint4_linear_11044_454)"
    />
    <path
      d="M9.2015 9.5L8.4285 13.132L7.572 9.5H6.4785L5.595 13.2445L4.7985 9.5H3.8205L4.9925 14.5H6.1685L7.025 10.6555L7.882 14.5H9.0075L10.1795 9.5H9.2015Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11044_454"
        x1="14"
        y1="7.483"
        x2="14"
        y2="3.225"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42A3F2" />
        <stop offset="1" stopColor="#42A4EB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11044_454"
        x1="14"
        y1="21"
        x2="14"
        y2="16.527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#11408A" />
        <stop offset="1" stopColor="#103F8F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_11044_454"
        x1="14"
        y1="-7.73003"
        x2="14"
        y2="-7.76053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3079D6" />
        <stop offset="1" stopColor="#297CD2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_11044_454"
        x1="6"
        y1="14.2649"
        x2="22"
        y2="14.2649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D59B3" />
        <stop offset="1" stopColor="#195BBC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_11044_454"
        x1="2.372"
        y1="7.372"
        x2="11.747"
        y2="16.7465"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#256AC2" />
        <stop offset="1" stopColor="#1247AD" />
      </linearGradient>
    </defs>
  </svg>
);
