import { ApiResponse } from "apisauce";

import { DEFAULT_API_CONFIG } from "../api/api-config";
import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import {
  AcceptPrivacyConsentResponse,
  IAcceptPrivacyConsent,
  IPrivacyConsentConfigResponse,
  IPrivacyConsentResponse,
  PrivacyConsentConfigResponse,
  PrivacyConsentResponse,
} from "../api/types";
import { Api } from "./api/api";

export class PrivacyConsentApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async loadUserPrivacyConsent(): Promise<PrivacyConsentResponse> {
    const response: ApiResponse<IPrivacyConsentResponse> =
      await this.api.apisauce.post(
        "WebServices/generic/Privacy.asmx/Consents",
        {
          baseURL: DEFAULT_API_CONFIG.webUrl,
        }
      );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async loadPrivacyConsentConfig(): Promise<PrivacyConsentConfigResponse> {
    const response: ApiResponse<IPrivacyConsentConfigResponse> =
      await this.api.apisauce.post("WebServices/generic/Privacy.asmx/Config", {
        baseURL: DEFAULT_API_CONFIG.webUrl,
      });

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async accept(
    body: IAcceptPrivacyConsent
  ): Promise<AcceptPrivacyConsentResponse> {
    const response: ApiResponse<IPrivacyConsentResponse> =
      await this.api.apisauce.post(
        "WebServices/generic/Privacy.asmx/Accept",
        body,
        { baseURL: DEFAULT_API_CONFIG.webUrl }
      );
    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }
}
