import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ILocationProperties,
  IObjectProperties,
  IObjectResponse,
  IProjectProperties,
} from "../api/types";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../contants/FeatureUuid";
import { locationsUrl, objectsUrl, projectsUrl } from "../contants/Routes";
import { FeatureContext } from "../context/feature-context/FeatureContext";
import { MediaContext } from "../context/media-context/MediaContext";

export const useTableSelect = (
  featureId?: string,
  featureType?: FEATURE_UUID
) => {
  const { getAttachments } = useContext(MediaContext);
  const {
    objectProjects,
    projectLocations,
    locationProjects,
    downloadGenericFeature,
    projectProducts,
    singleProduct,
  } = useContext(FeatureContext);
  const [selectedTableItem, setSelectedTableItem] = useState<
    IProjectProperties | ILocationProperties | IObjectProperties | null
  >(null);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const navigate = useNavigate();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const isProjectDataTable = useMemo(
    () =>
      featureType === FEATURE_UUID.PROJECT ||
      featureType === FEATURE_UUID.LOCATION_PROJECTS ||
      featureType === FEATURE_UUID.PRODUCT_PROJECTS,
    [featureType]
  );

  const [objectModal, setObjectModal] = useState<{
    mode: "add" | "edit";
    defaultValues?: IObjectResponse;
  }>();

  const setProject = useCallback(
    (id: string | number, connectedLocations?: ILocationProperties[]) => {
      const location_list = connectedLocations
        ?.map((location) => location.id)
        .filter(Boolean) as number[] | undefined;

      if (featureType === FEATURE_UUID.LOCATION_PROJECTS) {
        const selectedItem = locationProjects?.find(
          (project) => project.id === id
        );

        setSelectedTableItem(
          selectedItem ? { ...selectedItem, location_list } : null
        );
        return;
      }

      if (featureType === FEATURE_UUID.PRODUCT_PROJECTS) {
        const selectedItem = objectProjects?.find(
          (project) => project.id === id
        );
        setSelectedTableItem(
          selectedItem ? { ...selectedItem, location_list } : null
        );
        return;
      }

      if (featureType === FEATURE_UUID.PROJECT_LOCATIONS) {
        const selectedItem = projectLocations?.find(
          (location) => location.id === id
        );

        downloadGenericFeature(
          FEATURE_UUID.LOCATION_PROJECTS,
          `fk_location=${id}`
        );

        setSelectedTableItem(selectedItem ?? null);
        return;
      }

      if (featureType === FEATURE_UUID.PROJECT_PRODUCTS) {
        const selectedItem = projectProducts?.find(
          (product) => product.id === id
        );
        setSelectedTableItem(selectedItem ?? null);
        return;
      }
    },
    [
      featureType,
      objectProjects,
      locationProjects,
      projectLocations,
      projectProducts,
      downloadGenericFeature,
    ]
  );

  const handleAttachmentModal = useCallback(
    (refreshAttachments?: boolean) => {
      featureId &&
        refreshAttachments &&
        getAttachments(Number(featureId), FEATURE_MEDIA_TYPE.PRODUCT);
      setShowAttachmentModal((prev) => !prev);
    },
    [featureId, getAttachments]
  );

  const handleRecordEdit = useCallback(
    async (id: number | string) => {
      let connectedLocations = undefined;

      if (isProjectDataTable) {
        await downloadGenericFeature(
          FEATURE_UUID.PROJECT_LOCATIONS,
          `fk_project=${Number(id)}`
        ).then((res) => {
          if (res !== null) {
            connectedLocations = res;
          }
        });
        setProject(id, connectedLocations);
        setShowProjectModal(true);
      }

      if (featureType === FEATURE_UUID.PROJECT_LOCATIONS) {
        setProject(id);
        setShowLocationModal(true);
      }

      if (featureType === FEATURE_UUID.PROJECT_PRODUCTS) {
        setProject(id);
        const res = await singleProduct(Number(id), navigate);
        setObjectModal({
          mode: "edit",
          defaultValues: res,
        });
      }
    },
    [
      featureType,
      setProject,
      isProjectDataTable,
      downloadGenericFeature,
      singleProduct,
      navigate,
    ]
  );

  const handleRecordPreview = useCallback(
    (id: string | number, mapping_id?: number) => {
      // preview for products in project
      if (featureType === FEATURE_UUID.PROJECT_PRODUCTS) {
        const selectedItem = projectProducts?.find(
          (product) => product.mapping_id === mapping_id
        );

        setSelectedTableItem(selectedItem as IObjectProperties);

        if (selectedItem?.mapping_id === mapping_id) {
          setIsSidebarVisible(true);
        } else {
          setIsSidebarVisible((prev) => !prev);
        }
      } // preview for locations in project
      else if (featureType === FEATURE_UUID.PROJECT_LOCATIONS) {
        const selectedItem = projectLocations?.find(
          (location) => location.id === id
        );

        setSelectedTableItem(selectedItem as ILocationProperties);

        if (selectedItem?.id === id) {
          setIsSidebarVisible(true);
        } else {
          setIsSidebarVisible((prev) => !prev);
        }
      } // preview for projects
      else {
        setProject(id);
        setIsSidebarVisible((prev) => !prev);
      }
    },
    [featureType, projectLocations, projectProducts, setProject]
  );

  const handleRecordSelect = useCallback(
    (id: string | number) => {
      if (featureType === FEATURE_UUID.PROJECT_LOCATIONS) {
        navigate(`${locationsUrl}/${String(id)}`);
      }

      if (featureType === FEATURE_UUID.PROJECT_PRODUCTS) {
        navigate(`${objectsUrl}/${String(id)}`);
      }

      if (isProjectDataTable) {
        navigate(`${projectsUrl}/${String(id)}`);
      }
    },
    [featureType, navigate, isProjectDataTable]
  );

  const handleProjectModal = useCallback((state: boolean) => {
    setShowProjectModal(state);
  }, []);

  const handleLocationModal = useCallback((state: boolean) => {
    setShowLocationModal(state);
  }, []);

  return {
    setProject,
    handleAttachmentModal,
    handleRecordEdit,
    handleRecordPreview,
    handleRecordSelect,
    handleProjectModal,
    selectedTableItem,
    isSidebarVisible,
    setIsSidebarVisible,
    setShowProjectModal,
    showProjectModal,
    showAttachmentModal,
    setShowAttachmentModal,
    showLocationModal,
    handleLocationModal,
    objectModal,
    setObjectModal,
    setSelectedTableItem,
  };
};
