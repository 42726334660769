import React, { FC } from "react";

const PhoneIcon: FC = () => {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 0C10.9926 0 12 1.00736 12 2.25V17.75C12 18.9926 10.9926 20 9.75 20H2.25C1.00736 20 0 18.9926 0 17.75V2.25C0 1.00736 1.00736 0 2.25 0H9.75ZM9.75 1.5H2.25C1.83579 1.5 1.5 1.83579 1.5 2.25V17.75C1.5 18.1642 1.83579 18.5 2.25 18.5H9.75C10.1642 18.5 10.5 18.1642 10.5 17.75V2.25C10.5 1.83579 10.1642 1.5 9.75 1.5ZM7.24887 15.5C7.66308 15.4994 7.99938 15.8347 8 16.2489C8.00062 16.6631 7.66534 16.9994 7.25113 17L4.75113 17.0038C4.33692 17.0044 4.00062 16.6691 4 16.2549C3.99938 15.8407 4.33466 15.5044 4.74887 15.5038L7.24887 15.5Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default PhoneIcon;
