import Row from "@avinet/adaptive-ui-core/layout/Row";
import { t } from "i18next";
import { ReactNode, useCallback } from "react";

export function StatisticsCard({
  icon,
  title,
  amount,
  period,
}: {
  icon: ReactNode;
  title: string;
  amount: number;
  period: string;
}) {
  const periodTranslation = useCallback(() => {
    switch (period) {
      case "month":
        return t("pages.dashboard.thisMonth");
      case "year":
        return t("pages.dashboard.thisYear");
      case "total":
        return t("pages.dashboard.total");
      default:
        return;
    }
  }, [period]);

  return (
    <div className="card statistics-card">
      <Row wrap>
        {icon}
        <h4>{title}</h4>
      </Row>
      <Row wrap className="amount-container">
        <span className="amount">{amount}</span>
        <span className="unit">{periodTranslation()}</span>
      </Row>
    </div>
  );
}
