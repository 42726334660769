import { ApiResponse } from "apisauce";

import { DEFAULT_API_CONFIG } from "../api/api-config";
import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import * as Types from "../api/types";
import { ICreateMedia, IMediaASMXResponse } from "../api/types";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../contants/FeatureUuid";
import { Api } from "./api/api";

export class MediaApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async postMedia(media: Types.PostMedia): Promise<Types.MediaResponse> {
    const body = {
      data: media,
      srid: 0,
    };

    const response: ApiResponse<Types.IMediaFeature> =
      await this.api.apisauce.post(
        "api/features/f805c4b0-b104-4676-bd49-c33047e0f43d",
        body,
        { baseURL: DEFAULT_API_CONFIG.webUrl }
      );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async createMedia(body: ICreateMedia): Promise<Types.MediaASMXResponse> {
    const response: ApiResponse<IMediaASMXResponse> =
      await this.api.apisauce.post(
        "WebServices/generic/Media.asmx/Create",
        body,
        { baseURL: DEFAULT_API_CONFIG.webUrl }
      );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async saveMedia(uuid: string, file: File): Promise<Types.MediaASMXResponse> {
    const form = new FormData();
    form.append("file", file);

    const response: ApiResponse<any> = await this.api.apisauce.post(
      "WebServices/generic/Media.asmx/Upload?uuid=" + uuid,
      form,
      {
        baseURL: DEFAULT_API_CONFIG.webUrl,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async updateMedia(
    theme_uuid: string,
    body: Types.IMediaFeature
  ): Promise<Types.MediaASMXResponse> {
    const response: ApiResponse<IMediaASMXResponse> =
      await this.api.apisauce.post(
        `WebServices/client/DataView.asmx/Save`,
        { theme_uuid, data: body },
        { baseURL: DEFAULT_API_CONFIG.webUrl }
      );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async deleteMedia(
    theme_uuid: string,
    id: number
  ): Promise<Types.MediaASMXResponse> {
    const response: ApiResponse<IMediaASMXResponse> =
      await this.api.apisauce.post(
        `WebServices/client/DataView.asmx/Delete`,
        { theme_uuid, id },
        { baseURL: DEFAULT_API_CONFIG.webUrl }
      );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async downloadAttachments(
    filter: string
  ): Promise<Types.DownloadAttachmentsResponse> {
    const response: ApiResponse<Types.IMediaFeatures> =
      await this.api.apisauce.get(
        `features/${FEATURE_UUID.MEDIA}/json?filter=${filter}`
      );
    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }
}
