import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import { SelectItem } from "@avinet/adaptive-ui-core/form/controls/Select";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { t } from "i18next";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useActiveMenu } from "react-active-menu";

import { KIND_RESPONSE } from "../../../api/api-problem";
import {
  AddObjectForm,
  ILocationProperties,
  IObjectResponse,
} from "../../../api/types";
import {
  ALERT_MESSAGE_STATUS,
  ALERT_MESSAGE_TYPE,
  FEATURE_TYPE,
  QUANTITY_UNIT,
} from "../../../contants/Enums";
import {
  FEATURE_MEDIA_TYPE,
  FEATURE_UUID,
} from "../../../contants/FeatureUuid";
import { useNotificationApi } from "../../../context/NotificationProvider";
import { DictionaryContext } from "../../../context/dictionary-context/DictionaryContext";
import { FeatureContext } from "../../../context/feature-context/FeatureContext";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { useAttachmentEdit } from "../../../hooks/useAttachmentEdit";
import { useAttachments } from "../../../hooks/useAttachments";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { useTranslationPath } from "../../../hooks/useTranslationPath";
import { guidGenerator } from "../../../utils/index.utils";
import { AttachmentModal } from "../../attachment-modal/AttachmentModal";
import { FormSectionLinksModalAside } from "../../feature/feature-modal-aside";
import { FeatureModalCreated } from "../../feature/feature-modal-created";
import { FeatureModalHeader } from "../../feature/feature-modal-header";
import { FeatureMainTitle } from "../../feature/feature-modal-main-title";
import { FeatureModalWrapper } from "../../feature/feature-modal-wrapper";
import { FileDropzone } from "../../file-dropzone";
import { InputSelect } from "../../input-select/InputSelect";
import { Modal } from "../../modal";
import "../styles.scss";
import { addObjectAside, editObjectAside } from "./ObjectModal.const";
import { ObjectModalProps } from "./ObjectModal.props";
import "./ProductModal.scss";

export function ObjectModal({
  mode = "add",
  onClose,
  locationId: locationIdProp,
  objectDetails,
  projectId,
  locationOptionsList,
}: ObjectModalProps) {
  const [locationId, setLocationId] = useState(locationIdProp);

  const [successModalId, setSuccessModalId] = useState<number>();

  const handleSelectLocation = useCallback((locationId: number) => {
    setLocationId(locationId);
  }, []);

  const handleClose = useCallback(
    (id: number | undefined) => {
      if (mode === "add" && id !== undefined) {
        setSuccessModalId(id);
      } else {
        onClose();
      }
    },
    [mode, onClose]
  );

  const closeSuccessModal = useCallback(() => {
    setSuccessModalId(undefined);
    onClose();
  }, [onClose]);

  const handleNewComponent = useCallback(() => {
    setSuccessModalId(undefined);
  }, []);

  if (successModalId !== undefined) {
    return (
      <Modal show>
        <div className="success-modal modal-content-container">
          <FeatureModalHeader
            onClose={closeSuccessModal}
            title={null}
            subtitle={null}
          />
          <FeatureModalCreated
            featureType={FEATURE_TYPE.OBJECT}
            createdFeatureId={successModalId}
            locationId={locationId}
            handleNewComponent={handleNewComponent}
            resetForm={closeSuccessModal}
          />
        </div>
      </Modal>
    );
  }

  if (mode === "add" && locationId === undefined) {
    return (
      <SelectLocationModal
        onLocationSelect={handleSelectLocation}
        onClose={onClose}
        locationOptionsList={locationOptionsList}
      />
    );
  } else {
    return (
      <EditorForm
        mode={mode}
        onClose={handleClose}
        objectDetails={objectDetails}
        locationId={locationId}
        projectId={projectId}
      />
    );
  }
}

function EditorForm({
  mode = "add",
  onClose,
  objectDetails,
  locationId,
  projectId,
}: {
  mode: "add" | "edit";
  onClose: (id?: number) => void;
  objectDetails?: IObjectResponse;
  locationId?: number;
  projectId?: number;
}) {
  const [error, setError] = useState<string>("");
  const { conditionStatus } = useSelectOptions();
  const { notificationDialog, toast } = useNotificationApi();
  const { selectedOrganisation } = useContext(OrganisationContext);

  const tForm = useTranslationPath("components.objectModal.form");
  const tCancel = useTranslationPath("components.objectModal.confirmCancel");
  const tToast = useTranslationPath("components.objectModal.toast");
  const tModal = useTranslationPath("components.objectModal");
  const tCommon = useTranslationPath("common");

  const { getLocationFloor, locationFloors, addNewProduct, editProduct } =
    useContext(FeatureContext);

  const {
    attachmentsState,
    handleDropImage,
    showAttachmentModal,
    openAttachmentModal,
    closeAttachmentModal,
    attachmentThumbnail,
    handleRemoveDropImage,
  } = useAttachmentEdit(FEATURE_MEDIA_TYPE.PRODUCT, objectDetails?.product.id);

  const { postOrUpdateMedia } = useAttachments();

  const { activeId, registerContainer, registerSection, registerTrigger } =
    useActiveMenu({
      smooth: true,
      offset: 30,
    });

  const {
    categoryDictionary,
    parentCategoryDictionary,
    elementDictionary,
    getDictionaries,
  } = useContext(DictionaryContext);

  useLayoutEffect(() => {
    getDictionaries();
    if (!locationId) return;
    getLocationFloor(locationId);
  }, [getDictionaries, getLocationFloor, locationId]);

  const onSubmit = useCallback(
    async (values: AddObjectForm) => {
      const data = {
        ...values,
        fk_location: locationId ?? values.fk_location,
        productLocationMappings: objectDetails?.productLocationMappings ?? [],
      };

      let id = data.id;

      function submitAttachments(id: number) {
        return postOrUpdateMedia(
          attachmentsState,
          FEATURE_MEDIA_TYPE.PRODUCT,
          id
        );
      }

      if (mode === "add") {
        if (!locationId || !selectedOrganisation?.id) return;

        const newMappingObject = {
          uuid: guidGenerator(),
          fk_product: 0,
          fk_organization: selectedOrganisation?.id,
          fk_location: locationId,
          fk_floor: data.fk_floor,
          fk_room: data.fk_room,
          quantity: data.quantity,
          obj_condition: data.obj_condition,
        };

        const addData = {
          ...data,
          productLocationMappings: [newMappingObject],
        };

        if (!data.fk_location) return;

        const res = await addNewProduct(addData);
        if (res?.kind === KIND_RESPONSE.OK) {
          id = res.data.product.id;
          submitAttachments(id);
          toast({
            title: tToast("title"),
            message: tToast("message"),
            type: ALERT_MESSAGE_TYPE.PROFILE,
            status: ALERT_MESSAGE_STATUS.SUCCESS,
          });
          onClose(id);
        } else {
          setError(t("errors.unexpectedError") ?? "");
        }
      } else {
        if (!id) return;
        const res = await editProduct(data, Number(id), projectId);
        if (res?.kind === KIND_RESPONSE.OK) {
          submitAttachments(Number(id));
          toast({
            title: tToast("title"),
            message: tToast("message"),
            type: ALERT_MESSAGE_TYPE.PROFILE,
            status: ALERT_MESSAGE_STATUS.SUCCESS,
          });
          onClose();
        } else {
          setError(t("errors.unexpectedError") ?? "");
        }
      }
    },
    [
      addNewProduct,
      attachmentsState,
      editProduct,
      locationId,
      mode,
      objectDetails?.productLocationMappings,
      onClose,
      postOrUpdateMedia,
      projectId,
      selectedOrganisation?.id,
      tToast,
      toast,
    ]
  );

  const { form, state, isDirty, setDirty } = useForm({
    defaultValues: objectDetails?.product as unknown as AddObjectForm,
    onSubmit,
  });

  const handleClose = useCallback(async () => {
    if (isDirty) {
      const choices = await notificationDialog({
        title: tCancel("title"),
        message: tCancel("message"),
        icon: "warning",
        variant: "danger",
        choices: [
          {
            label: tCommon("keep"),
            value: "keep",
          },
          {
            label: tCommon("discard"),
            value: "discard",
            primaryOption: true,
          },
        ],
      });

      if (choices === "keep") {
        return;
      }
    }

    onClose();
  }, [isDirty, notificationDialog, onClose, tCancel, tCommon]);

  const units = useMemo(
    () => [
      {
        label: tForm("labels.quantity"),
        value: QUANTITY_UNIT.STK,
      },
      {
        label: tForm("labels.m2"),
        value: QUANTITY_UNIT.M2,
      },
      {
        label: tForm("labels.m3"),
        value: QUANTITY_UNIT.M3,
      },
      {
        label: tForm("labels.lm"),
        value: QUANTITY_UNIT.LM,
      },
      {
        label: tForm("labels.other"),
        value: QUANTITY_UNIT.OTHER,
      },
    ],
    [tForm]
  );

  const floorsOptions = useMemo(() => {
    const options = locationFloors
      .map((floor) => ({
        value: floor.id,
        label: floor.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return options || [];
  }, [locationFloors]);

  const roomsOptions = useMemo(() => {
    const selectedFloor = locationFloors.find(
      (floor) => floor.id === state.fk_floor
    );

    if (!selectedFloor) return [];

    return selectedFloor?.room_list
      .map((room) => ({
        value: room.id,
        label: room.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [locationFloors, state.fk_floor]);

  const filteredCategoryDictionary = useMemo(() => {
    if (state.parent_category) {
      return categoryDictionary.filter(
        (category) => category.parent_category === state.parent_category
      );
    } else {
      return categoryDictionary;
    }
  }, [categoryDictionary, state.parent_category]);

  const filteredElementsDictionary = useMemo(() => {
    if (state.category) {
      return elementDictionary.filter(
        (element) => element.parent_category === state.category
      );
    } else {
      return elementDictionary;
    }
  }, [elementDictionary, state.category]);

  const formLinks = useMemo(() => {
    state;
    const items = mode === "add" ? addObjectAside : editObjectAside;

    return items.map((a) => ({
      ...a,
      error: !!document.getElementById(a.trigger)?.querySelectorAll(":invalid")
        .length,
    }));
  }, [mode, state]);

  const { title, subtitle } = useMemo(() => {
    if (mode === "edit") {
      return {
        title: tModal("titleEditObject"),
        subtitle: tModal("descriptionEditObject"),
      };
    } else {
      return {
        title: tModal("titleRegisterObject"),
        subtitle: tModal("descriptionRegisterObject"),
      };
    }
  }, [mode, tModal]);

  return (
    <>
      <Modal className="feature-modal" show>
        <div className="modal-content-container">
          <div className="product-editor-modal">
            <FeatureModalHeader
              onClose={handleClose}
              title={title}
              subtitle={subtitle}
            />
            <div className="form-and-links-container">
              <FormProvider form={form} state={state}>
                <form
                  id="product-editor-form"
                  className="product-editor-form"
                  onSubmit={form.submit}
                  ref={registerContainer}
                >
                  <FeatureModalWrapper>
                    <section
                      className="feature-modal-drop"
                      ref={registerSection("attachments")}
                      id="attachments"
                    >
                      <FileDropzone
                        onDrop={handleDropImage}
                        attachmentButtonClick={openAttachmentModal}
                        setDirty={setDirty}
                        attachmentsCount={attachmentsState.length}
                        text={t("components.locationModal.asideAttachments")}
                        maxFiles={20}
                        attachmentPreview
                        thumbnailMedia={attachmentThumbnail}
                      />
                    </section>
                  </FeatureModalWrapper>
                  <FeatureModalWrapper>
                    <section ref={registerSection("amount")} id="amount">
                      {mode === "add" ? (
                        <>
                          <FeatureMainTitle
                            text={t("components.objectModal.aside.amount")}
                          />
                          <div className="input-unit">
                            <label htmlFor="quantity">
                              {tForm("labels.amount")}
                            </label>
                            <NumberInput
                              id="quantity"
                              label={null}
                              placeholder={tForm("placeholders.amount")}
                              type="integer"
                              required
                            />
                            <InputSelect
                              id="quantity_unit"
                              items={units}
                              label={null}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <FeatureMainTitle
                            text={t("components.objectModal.aside.unit")}
                          />
                          <InputSelect
                            id="quantity_unit"
                            items={units}
                            label={null}
                          />
                        </>
                      )}
                    </section>
                  </FeatureModalWrapper>
                  <FeatureModalWrapper>
                    <section
                      ref={registerSection("category")}
                      className="category"
                      id="category"
                    >
                      <FeatureMainTitle
                        text={t("components.objectModal.aside.nameAndCategory")}
                      />
                      <Input
                        id="specification"
                        type="text"
                        minLength={2}
                        required
                        label={tForm("labels.name")}
                        placeholder={tForm("placeholders.name")}
                      />
                      <Input
                        id="description"
                        type="textarea"
                        label={tForm("labels.description")}
                        placeholder={tForm("placeholders.description")}
                      />
                      <InputSelect
                        id="parent_category"
                        items={parentCategoryDictionary}
                        label={tForm("labels.otherCategory")}
                        placeholder={tForm("placeholders.otherCategory")}
                        required
                      />
                      <InputSelect
                        id="category"
                        items={filteredCategoryDictionary}
                        label={tForm("labels.category")}
                        placeholder={tForm("placeholders.category")}
                        required
                      />
                      <InputSelect
                        id="element"
                        items={filteredElementsDictionary}
                        label={tForm("labels.element")}
                        placeholder={tForm("placeholders.element")}
                      />
                    </section>
                  </FeatureModalWrapper>
                  <FeatureModalWrapper>
                    <section
                      ref={registerSection("dimensions")}
                      id="dimensions"
                    >
                      <FeatureMainTitle
                        text={t("components.objectModal.aside.dimensions")}
                      />
                      <NumberInput
                        id="height"
                        label={tForm("labels.height")}
                        placeholder={tForm("placeholders.height")}
                      />
                      <NumberInput
                        id="width"
                        label={tForm("labels.width")}
                        placeholder={tForm("placeholders.width")}
                      />
                      <NumberInput
                        id="length"
                        label={tForm("labels.length")}
                        placeholder={tForm("placeholders.length")}
                      />
                      <NumberInput
                        id="diameter"
                        label={tForm("labels.diameter")}
                        placeholder={tForm("placeholders.diameter")}
                      />
                      <NumberInput
                        id="weight"
                        label={tForm("labels.weight")}
                        placeholder={tForm("placeholders.weight")}
                      />
                    </section>
                  </FeatureModalWrapper>
                  {mode === "add" && (
                    <FeatureModalWrapper>
                      <section
                        ref={registerSection("placement")}
                        id="placement"
                      >
                        <FeatureMainTitle
                          text={t("components.objectModal.aside.floor")}
                        />
                        <InputSelect
                          id="obj_condition"
                          items={conditionStatus}
                          label={tForm("labels.condition")}
                          placeholder={tForm("placeholders.condition")}
                        />
                        <InputSelect
                          id="fk_floor"
                          items={floorsOptions}
                          label={tForm("labels.floor")}
                          placeholder={tForm("placeholders.floor")}
                        />
                        <InputSelect
                          id="fk_room"
                          disabled={!roomsOptions.length}
                          items={roomsOptions}
                          label={tForm("labels.room")}
                          placeholder={tForm("placeholders.room")}
                        />
                      </section>
                    </FeatureModalWrapper>
                  )}
                  <FeatureModalWrapper>
                    <section
                      ref={registerSection("information")}
                      id="information"
                    >
                      <FeatureMainTitle
                        text={t("components.objectModal.aside.additionalInfo")}
                      />
                      <Input
                        id="producer"
                        type="text"
                        minLength={1}
                        label={tForm("labels.producer")}
                        placeholder={tForm("placeholders.producer")}
                      />
                      <NumberInput
                        id="production_year"
                        min={1000}
                        max={9999}
                        label={tForm("labels.productionYear")}
                        placeholder={tForm("placeholders.productionYear")}
                      />
                      <NumberInput
                        id="cost"
                        label={tForm("labels.cost")}
                        placeholder={tForm("placeholders.cost")}
                        type="decimal"
                      />
                      <NumberInput
                        id="co2"
                        label={tForm("labels.co2")}
                        placeholder={tForm("placeholders.co2")}
                        type="decimal"
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper>
                    <section ref={registerSection("comment")} id="comment">
                      <FeatureMainTitle
                        text={t(
                          "components.objectModal.aside.additionalComment"
                        )}
                      />
                      <Input
                        id="additional_comments"
                        type="textarea"
                        label={tForm("labels.additionalComment")}
                        placeholder={tForm("placeholders.additionalComment")}
                      />
                    </section>
                  </FeatureModalWrapper>
                </form>
              </FormProvider>
              <FormSectionLinksModalAside
                data={formLinks}
                active={activeId}
                trigger={registerTrigger}
              />
            </div>
            <span className="error-message-output">{error || <>&nbsp;</>}</span>
            <Row className="buttom-buttons" wrap>
              <button className="btn light" type="button" onClick={handleClose}>
                {t("common.cancel")}
              </button>
              <button className="btn" type="submit" form="product-editor-form">
                {t("common.save")}
              </button>
            </Row>
          </div>
        </div>
      </Modal>
      {showAttachmentModal && (
        <AttachmentModal
          onClose={closeAttachmentModal}
          attachments={attachmentsState}
          setDirty={setDirty}
          onDropImage={handleDropImage}
          handleRemoveDropImage={handleRemoveDropImage}
        />
      )}
    </>
  );
}

function SelectLocationModal({
  onClose,
  onLocationSelect,
  locationOptionsList,
}: {
  onClose: () => void;
  onLocationSelect: (locationId: number) => void;
  locationOptionsList?: ILocationProperties[];
}) {
  const tLocationModal = useTranslationPath("components.objectModal");
  const { locationsList, downloadGenericFeature } = useContext(FeatureContext);

  useLayoutEffect(() => {
    downloadGenericFeature(FEATURE_UUID.LOCATION);
  }, [downloadGenericFeature]);

  const locationSelectItems = useMemo(() => {
    if (locationOptionsList) {
      return locationOptionsList
        .map((location) => ({
          label: location.name,
          value: location.id,
        }))
        .filter((location) => location.value && location.label) as SelectItem[];
    } else {
      return locationsList
        .map((location) => ({
          label: location.name,
          value: location.id,
        }))
        .filter((location) => location.value && location.label) as SelectItem[];
    }
  }, [locationOptionsList, locationsList]);

  const onSubmit = useCallback(
    ({ fk_location }: { fk_location: number }) => {
      onLocationSelect(fk_location);
    },
    [onLocationSelect]
  );

  const { form, state } = useForm({
    onSubmit,
  });

  return (
    <Modal className="select-location-for-product" show>
      <div className="modal-content-container">
        <FeatureModalHeader
          onClose={onClose}
          title={tLocationModal("titleLocation")}
          subtitle={tLocationModal("descriptionLocation")}
        />
        <FormProvider form={form} state={state}>
          <form id="select-location" onSubmit={form.submit}>
            <InputSelect
              id="fk_location"
              items={locationSelectItems}
              label={tLocationModal("form.labels.location")}
              placeholder={tLocationModal("form.placeholders.location")}
              required
            />
          </form>
          <Row className="buttom-buttons" wrap>
            <button className="btn light" type="button" onClick={onClose}>
              {t("common.cancel")}
            </button>
            <button className="btn" type="submit" form="select-location">
              {t("common.next")}
            </button>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
}
