import React, { FC } from "react";

const PlanCrownIcon: FC = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="7.5"
        fill="#FFFCF5"
        stroke="#FDB022"
      />
      <g clipPath="url(#clip0_6748_449663)">
        <path
          d="M48.6357 27.8789L44.5148 40.8352C40.3012 39.3441 34.6188 38.4316 28.3725 38.4316C22.1263 38.4316 16.4476 39.3441 12.2303 40.8352L8.10938 27.8789C8.10938 27.8789 20.1456 42.7974 15.8244 21.0873C15.8244 21.0873 26.288 43.3278 28.3725 12.5264C30.4608 43.3278 40.9206 21.0873 40.9206 21.0873C36.5995 42.7974 48.6357 27.8789 48.6357 27.8789Z"
          fill="#FDB022"
        />
        <path
          d="M44.2245 42.0036L43.6756 44.2106C39.5621 42.8864 34.2172 42.0852 28.3715 42.0852C22.5259 42.0852 17.3182 42.8642 13.2233 44.1624L12.5742 41.9851C16.7544 40.5644 22.2922 39.7002 28.3715 39.7002C34.4509 39.7002 40.0406 40.5719 44.2245 42.0036Z"
          fill="#FDB022"
        />
      </g>
      <path
        d="M31.317 31.3156L56.0012 5.15771V47.9998C56.0012 52.4181 52.4195 55.9998 48.0012 55.9998H6.63281L31.317 31.3156Z"
        fill="black"
        fillOpacity="0.05"
      />
      <defs>
        <clipPath id="clip0_6748_449663">
          <rect
            width="40.5263"
            height="31.6842"
            fill="white"
            transform="translate(8.10938 12.5264)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlanCrownIcon;
