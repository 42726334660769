import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";

import { KIND_RESPONSE } from "../../../api/api-problem";
import { ChangePasswordRequest } from "../../../api/types";
import { USER_PROFILE_MODAL_TYPE } from "../../../contants/Enums";
import { AppUserContext } from "../../../context/user-context/UserContext";
import theme from "../../../theme";
import { ErrorMessage } from "../../error-message";
import { Heading } from "../../heading";
import { PasswordInput } from "../../password-input/PasswordInput";
import { ProfileCard } from "../profile-card";
import { ProfileInfo } from "../profile-info";
import { ProfileModal } from "../profile-modal";

export const ProfileSecurity: FC = () => {
  const { changePassword } = useContext(AppUserContext);
  const { t } = useTranslation();
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [profileTypeModal, setProfileTypeModal] = useState<
    USER_PROFILE_MODAL_TYPE | undefined
  >(undefined);
  const modalTextData = {
    icon: "shieldIcon",
    title: t("components.profileUpdateModal.titlePassword"),
    description: t("components.profileUpdateModal.titlePasswordDescription"),
    submitButtonText: t("common.changePassword"),
  };

  const formLabels = useMemo(
    () => ({
      oldPassword: t("form.oldPassword"),
      newPassword: t("form.newPassword"),
    }),
    [t]
  );

  const formPlaceholders = useMemo(
    () => ({
      oldPasswordPlaceholder: t("form.oldPasswordPlaceholder"),
      newPasswordPlaceholder: t("form.newPasswordPlaceholder"),
    }),
    [t]
  );

  const { form, state } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
    },
    onSubmit: (data: ChangePasswordRequest) => handleSubmitForm(data),
  });

  const handleSetProfileModal = useCallback(
    (type: USER_PROFILE_MODAL_TYPE | undefined) => {
      setShowProfileModal(!showProfileModal);
      setProfileTypeModal(type);
      setPasswordError(null);

      state.oldPassword = "";
      state.newPassword = "";
    },
    [showProfileModal, state]
  );

  const handleSubmitForm = (data: ChangePasswordRequest) => {
    changePassword(data)
      .then((response) => {
        if (response === KIND_RESPONSE.UNAUTHORIZED) {
          setPasswordError(t("errors.passwordNotValid"));
        } else {
          handleSetProfileModal(undefined);
        }
      })
      .catch(() => {
        console.error(data);
      });
  };

  const renderInputs = useCallback(() => {
    switch (profileTypeModal) {
      case USER_PROFILE_MODAL_TYPE.PASSWORD:
        return (
          <>
            <PasswordInput
              id="oldPassword"
              label={formLabels.oldPassword}
              placeholder={formPlaceholders.oldPasswordPlaceholder}
              required
            />
            <PasswordInput
              id="newPassword"
              label={formLabels.newPassword}
              placeholder={formPlaceholders.newPasswordPlaceholder}
              required
            />
            <PasswordStrengthBar
              className="profile-modal__password-strength"
              password={state.newPassword}
              barColors={[
                "#E3E8EF",
                `${theme.danger}`,
                "#f6b44d",
                `${theme.secondary}`,
                `${theme.primary}`,
              ]}
            />
            <ErrorMessage
              className="profile-modal__error"
              text={passwordError !== null ? passwordError : ""}
            />
          </>
        );
      default:
        return <></>;
    }
  }, [
    profileTypeModal,
    formLabels,
    formPlaceholders,
    state.newPassword,
    passwordError,
  ]);

  return (
    <>
      <Heading
        title={t("pages.profile.headings.security.title")}
        description={t("pages.profile.headings.security.description")}
        small
      />
      <ProfileCard lastCard>
        <ProfileInfo
          editOnly={t("common.changePassword")}
          title={t("form.password")}
          onClick={() =>
            handleSetProfileModal(USER_PROFILE_MODAL_TYPE.PASSWORD)
          }
        />
      </ProfileCard>
      <ProfileModal
        iconName={modalTextData.icon}
        title={modalTextData.title}
        description={modalTextData.description}
        showModal={showProfileModal}
        cancelButtonClick={() => handleSetProfileModal(undefined)}
        submitButtonClick={form.submit}
        submitButtonText={modalTextData.submitButtonText}
      >
        <FormProvider form={form} state={state}>
          <form className="form">{renderInputs()}</form>
        </FormProvider>
      </ProfileModal>
    </>
  );
};
