import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { DefaultTFuncReturn } from "i18next";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiValue, SingleValue } from "react-select";

import { USER_PROFILE_MODAL_TYPE } from "../../../contants/Enums";
import { Heading } from "../../heading";
import { LanguageSelect } from "../../language-select";
import { ProfileCard } from "../profile-card";
import { ProfileInfo } from "../profile-info";
import { ProfileModal } from "../profile-modal";
import { ProfileNotificationOption } from "../profile-notification-option";

export type ISelectedOption = {
  value: string;
  label: string | ReactElement | DefaultTFuncReturn;
};

export const ProfilePreferences: FC = () => {
  const { t, i18n } = useTranslation();
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [profileTypeModal, setProfileTypeModal] = useState<
    USER_PROFILE_MODAL_TYPE | undefined
  >(undefined);
  const [modalTextData, setModalTextData] = useState({
    icon: "bellIcon",
    title: t("components.profileUpdateModal.titleNotifications"),
    description: t(
      "components.profileUpdateModal.titleNotificationsDescription"
    ),
    submitButtonText: t("common.confirm"),
  });

  const [mentionedMessage, setMentionedMessage] = useState<string>("In-app");
  const [replyMessage, setReplyMessage] = useState<string>("In-app");
  const [assignedTask, setAssignedTask] = useState<string>("In-app");
  const [selectedOption, setSelectedOption] = useState<
    | ISelectedOption
    | MultiValue<ISelectedOption>
    | SingleValue<ISelectedOption>
    | null
  >({
    value: i18n.language,
    label: t(`components.languages.${i18n.language}`),
  });

  const { form, state } = useForm({
    defaultValues: {
      mentionedMessage: "",
      replyMessage: "",
      assignedTask: "",
    },
    onSubmit: () => handleSubmitForm(),
  });

  const renderInputs = useCallback(() => {
    switch (profileTypeModal) {
      case USER_PROFILE_MODAL_TYPE.NOTIFICATION:
        return (
          <>
            <ProfileNotificationOption
              text={t("components.profilePreferences.mentionedMessage")}
              selectedOption={mentionedMessage}
              selectedOptionChange={setMentionedMessage}
            />
            <ProfileNotificationOption
              text={t("components.profilePreferences.replyMessage")}
              selectedOption={replyMessage}
              selectedOptionChange={setReplyMessage}
            />
            <ProfileNotificationOption
              text={t("components.profilePreferences.assignedTask")}
              selectedOption={assignedTask}
              selectedOptionChange={setAssignedTask}
            />
          </>
        );
      case USER_PROFILE_MODAL_TYPE.LANGUAGE:
        return (
          <LanguageSelect
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        );
      default:
        return <></>;
    }
  }, [
    t,
    profileTypeModal,
    mentionedMessage,
    setMentionedMessage,
    replyMessage,
    assignedTask,
    setReplyMessage,
    setAssignedTask,
    selectedOption,
  ]);

  const renderModalText = useCallback(() => {
    switch (profileTypeModal) {
      case USER_PROFILE_MODAL_TYPE.NOTIFICATION:
        return setModalTextData({
          icon: "bellIcon",
          title: t("components.profileUpdateModal.titleNotifications"),
          description: t(
            "components.profileUpdateModal.titleNotificationsDescription"
          ),
          submitButtonText: t("common.confirm"),
        });
      case USER_PROFILE_MODAL_TYPE.LANGUAGE:
        return setModalTextData({
          icon: "language",
          title: t("components.profileUpdateModal.titleLanguage"),
          description: t(
            "components.profileUpdateModal.titleLanguageDescription"
          ),
          submitButtonText: t("common.changeLanguage"),
        });
      default:
        return setModalTextData({
          icon: "bellIcon",
          title: t("components.profileUpdateModal.titleNotifications"),
          description: t(
            "components.profileUpdateModal.titleNotificationsDescription"
          ),
          submitButtonText: t("common.changeName"),
        });
    }
  }, [profileTypeModal, t]);

  const handleSetProfileModal = useCallback(
    (type: USER_PROFILE_MODAL_TYPE | undefined) => {
      setShowProfileModal(!showProfileModal);
      setProfileTypeModal(type);
    },
    [showProfileModal]
  );

  useEffect(() => {
    showProfileModal && renderModalText();
  }, [showProfileModal, renderModalText]);

  const handleChangeLanguage = useCallback(async () => {
    if (selectedOption !== null) {
      if (Object.values(selectedOption)?.[0]) {
        await i18n.changeLanguage(Object.values(selectedOption)?.[0]);
        handleSetProfileModal(USER_PROFILE_MODAL_TYPE.LANGUAGE);
      }
    }
  }, [selectedOption, i18n, handleSetProfileModal]);

  const handleSetNotificationModalSubmit = useCallback(() => {
    setShowProfileModal(false);
  }, [setShowProfileModal]);

  const handleSubmitForm = useCallback(() => {
    switch (profileTypeModal) {
      case USER_PROFILE_MODAL_TYPE.NOTIFICATION:
        return handleSetNotificationModalSubmit();
      case USER_PROFILE_MODAL_TYPE.LANGUAGE:
        return handleChangeLanguage();
      default:
        return null;
    }
  }, [
    profileTypeModal,
    handleChangeLanguage,
    handleSetNotificationModalSubmit,
  ]);

  return (
    <>
      <Heading
        title={t("pages.profile.headings.preferences.title")}
        description={t("pages.profile.headings.preferences.description")}
        small
      />
      <ProfileCard lastCard>
        {/* TODO: notifications v2 */}
        {/*<ProfileInfo*/}
        {/*  editOnly={t("components.profilePreferences.titleNotifications")}*/}
        {/*  title={t("components.profilePreferences.buttonNotifications")}*/}
        {/*  onClick={() =>*/}
        {/*    handleSetProfileModal(USER_PROFILE_MODAL_TYPE.NOTIFICATION)*/}
        {/*  }*/}
        {/*/>*/}
        <ProfileInfo
          editOnly={t("components.profilePreferences.titleAccount")}
          title={t("components.profilePreferences.buttonLanguage")}
          onClick={() =>
            handleSetProfileModal(USER_PROFILE_MODAL_TYPE.LANGUAGE)
          }
        />
      </ProfileCard>
      <ProfileModal
        className={
          profileTypeModal === USER_PROFILE_MODAL_TYPE.NOTIFICATION
            ? "profile-modal--notification"
            : ""
        }
        iconName={modalTextData.icon}
        title={modalTextData.title}
        description={modalTextData.description}
        showModal={showProfileModal}
        cancelButtonClick={() => setShowProfileModal(false)}
        submitButtonClick={form.submit}
        submitButtonText={modalTextData.submitButtonText}
      >
        <FormProvider form={form} state={state}>
          <form className="form">{renderInputs()}</form>
        </FormProvider>
      </ProfileModal>
    </>
  );
};
