import { FC, Fragment } from "react";
import { NavLink } from "react-router-dom";

import { ChevronRight } from "../../../assets/icons/chevron-right/ChevronRight";
import { HomeLineIcon } from "../../../assets/icons/home-line/HomeLineIcon";
import { PageHeaderProps } from "./index.props";
import "./styles.scss";

export const PageHeader: FC<PageHeaderProps> = ({ routes }) => {
  return (
    <div className="pageheader-wrapper">
      <HomeLineIcon />

      {routes.map((route, index) => {
        return (
          <Fragment key={index}>
            <ChevronRight />
            <NavLink
              className={`breadcrumb ${
                routes.length === index + 1 ? "breadcrumb__last-item" : ""
              }`}
              to={route.url}
            >
              {route.title}
            </NavLink>
          </Fragment>
        );
      })}
    </div>
  );
};
