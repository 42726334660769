import type { ButtonHTMLAttributes, ReactElement, ReactNode } from "react";

import { Icon, IconEnum } from "../icon/Icon";
import { PopupMenuTarget } from "./PopupMenu";
import "./PopupMenuButton.scss";

type ButtonInheritedProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "type"
>;

interface PopupMenuButtonProps extends ButtonInheritedProps {
  menu: ReactElement;
  icon?: IconEnum | false | ReactNode;
  iconRotate?: 0 | 90 | 180 | 270;
  closeInside?: boolean;
}

export function PopupMenuButton({
  menu,
  className,
  children,
  closeInside = true,
  icon = "more",
  iconRotate = 0,
  ...props
}: PopupMenuButtonProps) {
  return (
    <PopupMenuTarget closeInside={closeInside} menu={menu}>
      {(onToggle, isVisible) => (
        <button
          aria-haspopup="menu"
          type="button"
          className={`${className} popup-menu-button ${
            isVisible ? "active" : ""
          }`}
          onClick={onToggle}
          {...props}
        >
          {icon !== false && typeof icon === "string" ? (
            <Icon
              name={isVisible ? "dismiss" : (icon as IconEnum)}
              rotate={iconRotate}
            />
          ) : (
            icon
          )}
          {children}
        </button>
      )}
    </PopupMenuTarget>
  );
}
