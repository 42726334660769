import React, { FC } from "react";

const PasswordEye: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6318_109172)">
        <path
          d="M-1.78033 -1.78033C-2.0466 -1.51406 -2.0708 -1.0974 -1.85295 -0.803788L-1.78033 -0.71967L2.25424 3.3149C0.332249 4.66437 -1.10423 6.67992 -1.70112 9.06439C-1.8017 9.4662 -1.5575 9.87348 -1.15569 9.97406C-0.753874 10.0746 -0.346598 9.83045 -0.246014 9.42863C0.283463 7.31347 1.59112 5.53947 3.33416 4.39452L5.14379 6.20425C4.43628 6.92578 4 7.91426 4 9.00462C4 11.2138 5.79086 13.0046 8 13.0046C9.09037 13.0046 10.0788 12.5683 10.8004 11.8608L16.7197 17.7803C17.0126 18.0732 17.4874 18.0732 17.7803 17.7803C18.0466 17.5141 18.0708 17.0974 17.8529 16.8038L17.7803 16.7197L11.6668 10.6055L11.668 10.604L10.4679 9.40613L7.59797 6.53678L7.6 6.536L4.71877 3.65782L4.72 3.656L3.58672 2.52549L-0.719669 -1.78033C-1.01256 -2.07322 -1.48744 -2.07322 -1.78033 -1.78033ZM6.20407 7.26547L9.73915 10.8006C9.28918 11.2364 8.67592 11.5046 8 11.5046C6.61929 11.5046 5.5 10.3853 5.5 9.00462C5.5 8.32871 5.76824 7.71544 6.20407 7.26547ZM8 1.5C6.99974 1.5 6.02914 1.64807 5.11109 1.925L6.34807 3.16119C6.88386 3.05532 7.4364 3 8 3C11.9231 3 15.3099 5.68026 16.2471 9.43315C16.3475 9.83502 16.7546 10.0794 17.1565 9.97908C17.5584 9.87872 17.8028 9.47158 17.7024 9.06971C16.5994 4.65272 12.6155 1.5 8 1.5ZM8.19468 5.00928L11.996 8.81C11.8942 6.75308 10.2472 5.10764 8.19468 5.00928Z"
          fill="#5D6B98"
        />
      </g>
      <defs>
        <clipPath id="clip0_6318_109172">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PasswordEye;
