import React, { FC } from "react";

const BellIcon: FC = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 0.996094C13.0499 0.996094 16.3567 4.19084 16.4958 8.24515L16.5 8.49609V12.5931L17.88 15.7491C17.949 15.907 17.9847 16.0775 17.9847 16.2499C17.9847 16.9402 17.425 17.4999 16.7347 17.4999L12 17.5014C12 19.1582 10.6568 20.5014 8.99999 20.5014C7.40231 20.5014 6.09633 19.2524 6.00508 17.6776L5.99954 17.4991L1.27485 17.4999C1.10351 17.4999 0.934005 17.4646 0.776852 17.3964C0.14365 17.1213 -0.146699 16.3851 0.12834 15.7519L1.49999 12.594V8.49599C1.50059 4.3412 4.85208 0.996094 8.99999 0.996094ZM10.4995 17.4991L7.49999 17.5014C7.49999 18.3298 8.17156 19.0014 8.99999 19.0014C9.77969 19.0014 10.4204 18.4065 10.4931 17.6458L10.4995 17.4991ZM8.99999 2.49609C5.67983 2.49609 3.00047 5.17035 2.99999 8.49609V12.9057L1.65601 15.9999H16.3525L15 12.9067L15.0001 8.50895L14.9964 8.28375C14.8853 5.05027 12.2416 2.49609 8.99999 2.49609Z"
        fill="#7D89B0"
      />
    </svg>
  );
};

export default BellIcon;
