import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";
import React from "react";

import { convertToInitials } from "../../../utils/index.utils";
import { Modal } from "../../modal";
import { ProfileCard } from "../profile-card";
import { ProfileMetadataModalProps } from "./ProfileMetadataModal.props";
import { ProfileMetadataRow } from "./ProfileMetadataRow";
import "./styles.scss";

export function ProfileMetadataModal({
  title,
  showModal,
  cancelButtonClick,
  user,
}: ProfileMetadataModalProps) {
  return (
    <Modal show={showModal}>
      <div className="profile-metadata-modal">
        <div
          onClick={cancelButtonClick}
          className="profile-metadata-modal__close"
        >
          <Icon name="closeIcon" />
        </div>
        <div className="profile-metadata-modal__title-container">
          <p className="profile-metadata-modal__title">{title}</p>
        </div>
        <div>
          <ProfileCard className="profile-metadata-modal__profile-card-image">
            {user?.image ? (
              <img
                src={user?.image}
                alt={`${user?.first_name}-${user?.last_name}}`}
                className="profile-metadata-modal__user__img"
              />
            ) : (
              <div className="profile-metadata-modal__user__img">
                <span>
                  {convertToInitials(user?.first_name, user?.last_name)}
                </span>
              </div>
            )}
            <p className="profile-metadata-modal__name">
              {user?.first_name} {user?.last_name}
            </p>
          </ProfileCard>
          <ProfileCard className="profile-metadata-modal__profile-card-info">
            <ProfileMetadataRow type="email" value={user?.email} />
            <span className="separator" />
            <ProfileMetadataRow type="workplace" value={user?.company} />
            <span className="separator" />
            <ProfileMetadataRow type="address" value={user?.address} />
            <span className="separator" />
            <ProfileMetadataRow type="phone" value={user?.phone} />
          </ProfileCard>
        </div>

        <button className="btn btn-close" onClick={cancelButtonClick}>
          {t("common.close")}
        </button>
      </div>
    </Modal>
  );
}
