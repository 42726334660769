import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { useTranslation } from "react-i18next";

import { OBJECT_STATUS } from "../../../contants/Enums";
import { ElementsCounter } from "../../counter";
import { Icon } from "../../icon/Icon";
import { ObjectStatus } from "../object-status";
import { ObjectStateInfoMappingProps } from "./index.props";
import "./styles.scss";

export function ObjectStateInfoMapping({
  className,
  mapping,
  onEditQuantity,
  onDelete,
  onEdit,
  quantityUnit,
}: ObjectStateInfoMappingProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`object-state-info-mapping ${className ? className : ""}`}
      key={mapping?.uuid}
    >
      <Row className="object-state-info-mapping__status-wrapper">
        <button className="btn-link delete" onClick={onDelete}>
          <Icon name="delete" />
        </button>
        <ObjectStatus
          text={
            mapping?.obj_condition?.length
              ? mapping?.obj_condition
              : t("options.condition.noCondition")
          }
          status={
            mapping?.obj_condition?.length
              ? mapping?.obj_condition
              : OBJECT_STATUS.NO_CONDITION
          }
        />
      </Row>
      <Grow />
      <ElementsCounter
        mapId={mapping.uuid}
        counter={mapping?.quantity}
        onEditQuantity={onEditQuantity}
        quantityUnit={quantityUnit}
      />
      <button className="btn-link" onClick={onEdit}>
        <Icon name="edit" />
      </button>
    </div>
  );
}
