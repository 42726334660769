import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import DelioLogo from "../assets/DelioLogoName2.png";
import ReportFrontPageImage from "../assets/NewReportFrontImage.jpg";
import { useTranslationPath } from "../hooks/useTranslationPath";
import { ReportFooter } from "./ReportFooter";
import { IReportData } from "./ReportTypes";
import { colors } from "./colors";

const styles = StyleSheet.create({
  frontPage: {
    backgroundColor: colors.white,
    minHeight: "100%",
    minWidth: "100%",
    color: colors.darkTextColor,
  },
  frontPageImage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.white,
  },
  frontPageTextBox: {
    position: "absolute",
    top: 270,
    left: 30,
    display: "flex",
    flexDirection: "column",
    color: colors.darkTextColor,
    fontSize: 16,
  },
  frontPageTextBoxTop: { marginBottom: 100 },
  frontPageTextBoxBottomValue: {
    fontSize: 28,
    paddingTop: 10,
    paddingBottom: 20,
  },
  delioLogo: {
    position: "absolute",
    border: "none",
    top: 30,
    left: 32,
    width: 77,
    height: 24,
  },
});

export const FrontPage = ({
  reportData,
  logoPath,
}: {
  reportData: IReportData;
  logoPath?: string;
}) => {
  const t = useTranslationPath("report.frontPage");
  const currentDate = new Date();
  const formattedDate = new Intl.DateTimeFormat("nb-NO", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(currentDate);
  return (
    <Page>
      <View break={false} style={styles.frontPage}>
        <View style={styles.delioLogo}>
          <Image src={DelioLogo} />
        </View>
        <View style={styles.frontPageImage}>
          <Image src={ReportFrontPageImage} />
        </View>
        <View style={styles.frontPageTextBox}>
          <View style={styles.frontPageTextBoxTop}>
            <Text
              style={{
                fontSize: 60,
                fontWeight: "bold",
                marginBottom: 30,
                marginRight: 30,
              }}
            >
              {reportData.projectDetails?.name ??
                t("delio") + "-" + t("report")}
            </Text>
            <Text>{formattedDate}</Text>
          </View>
          <View>
            {reportData.projectDetails?.customer && (
              <View>
                <Text>{t("customer")}</Text>
                <Text style={styles.frontPageTextBoxBottomValue}>
                  {reportData.projectDetails?.customer}
                </Text>
              </View>
            )}
            {reportData.projectDetails?.project_leader && (
              <View>
                <Text>{t("projectLeader")}</Text>
                <Text style={styles.frontPageTextBoxBottomValue}>
                  {reportData.projectDetails?.project_leader}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
      <ReportFooter logoPath={logoPath} />
    </Page>
  );
};
