import { useTranslation } from "react-i18next";

import { Icon } from "../icon/Icon";
import { SubmitButtonsProps } from "./index.props";
import "./styles.scss";

export const SubmitButtons = ({
  cancelText,
  submitText,
  formId,
  onCancel,
  onSubmit,
  deleteMode,
  resetMode,
  submitDisabled,
  cancelDisabled,
}: SubmitButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`submit-buttons ${!onCancel ? "submit-buttons--center" : ""}`}
    >
      {onCancel && (
        <button
          className="btn light submit-buttons--cancel"
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          {resetMode && <Icon name="arrowReset" />}
          {cancelText?.length ? cancelText : t("common.cancel")}
        </button>
      )}
      <button
        className={`btn ${deleteMode ? "btn-delete" : ""}`}
        type="submit"
        form={formId}
        onClick={onSubmit}
        disabled={submitDisabled}
      >
        {submitText?.length ? submitText : t("common.create")}
      </button>
    </div>
  );
};
