import { t } from "i18next";
import { useCallback, useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ILocationProperties } from "../../api/types";
import { LocationModal } from "../../components/add-new-feature/location/LocationModal";
import { ObjectModal } from "../../components/add-new-feature/object/ObjectModal";
import { ConnectFeatureModal } from "../../components/connect-feature-modal/ConnectFeatureModal";
import { FeatureDeleteModal } from "../../components/feature-delete-modal/FeatureDeleteModal";
import { LocationFilterModal } from "../../components/filter-modal/LocationFilterModal";
import { Icon } from "../../components/icon/Icon";
import { PageHeader } from "../../components/page/page-header";
import { PageSidebar } from "../../components/page/page-sidebar";
import { PageTable } from "../../components/page/page-table/PageTable";
import { PageTitle } from "../../components/page/page-title";
import { PageWrapper } from "../../components/page/page-wrapper";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { dashboardUrl, locationsUrl } from "../../contants/Routes";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import {
  FilterContext,
  ILocationFilter,
} from "../../context/filter-context/FilterContext";
import { useLocationFilter } from "../../hooks/useLocationFilter";
import { useLocationTableColumns } from "../../hooks/useLocationTableColumns";

export function Locations() {
  const { downloadGenericFeature, locationsList, locationProjects } =
    useContext(FeatureContext);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [locationModal, setLocationModal] = useState<{
    mode: "add" | "edit";
    defaultValues?: ILocationProperties | null;
  }>();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { locationFilter, handleLocationFilter } = useContext(FilterContext);
  const [selectedTableItem, setSelectedTableItem] =
    useState<ILocationProperties | null>(null);
  const [connectFeatureModal, setConnectFeatureModal] = useState(false);
  const [showObjectModal, setShowObjectModal] = useState(false);
  const [createdLocationId, setCreatedLocationId] = useState<
    number | undefined
  >(undefined);
  const navigate = useNavigate();
  const [multipleItems, setMultipleItems] = useState<number[]>([]);

  const handleFilterModal = useCallback(() => {
    setShowFilterModal((prev) => !prev);
  }, []);

  const filterString = useLocationFilter(locationFilter);

  const filterActive = !!filterString;

  const routes = [
    { url: dashboardUrl, title: t("common.home") },
    { url: locationsUrl, title: t("common.locations") },
  ];

  const { columns, tabs, renderLocationTableTitle } =
    useLocationTableColumns(locationsList);

  const handleCloseSidebar = useCallback(() => {
    setSelectedTableItem(null);
    setIsSidebarVisible(false);
  }, []);

  const handleNewLocation = useCallback(() => {
    setLocationModal({ mode: "add" });
  }, []);

  const closeLocationModal = useCallback(() => {
    setLocationModal(undefined);
  }, []);

  const setLocation = useCallback(
    (id: string | number) => {
      const selectedItem = locationsList?.find(
        (location) => location.id === id
      );

      downloadGenericFeature(
        FEATURE_UUID.LOCATION_PROJECTS,
        `fk_location=${id}`
      );

      setSelectedTableItem(selectedItem ?? null);
    },
    [locationsList, downloadGenericFeature]
  );

  const handleRecordEdit = useCallback(
    (id: string | number) => {
      const selectedItem = locationsList?.find(
        (location) => location.id === id
      );

      setLocationModal({ mode: "edit", defaultValues: selectedItem });
    },
    [locationsList]
  );

  const handleRecordPreview = useCallback(
    (id: string | number) => {
      if (selectedTableItem?.id === id) {
        setIsSidebarVisible((prev) => !prev);
      } else {
        setLocation(id);
        setIsSidebarVisible(true);
      }
    },
    [selectedTableItem?.id, setLocation]
  );

  const handleRecordSelect = useCallback(
    (id: string | number) => {
      navigate(String(id));
    },
    [navigate]
  );

  const handleConnectFeatureModal = useCallback(() => {
    setConnectFeatureModal((prev) => !prev);
  }, []);

  const closeObjectModal = useCallback(() => {
    setShowObjectModal(false);
    createdLocationId && setCreatedLocationId(undefined);
  }, [createdLocationId]);

  const handleAddNewComponentFromLocation = useCallback((id?: number) => {
    setShowObjectModal((prev) => !prev);
    setCreatedLocationId(id);
  }, []);

  const handleApplyFilter = useCallback(
    (filter: Partial<ILocationFilter>) => {
      handleLocationFilter(filter as Partial<ILocationFilter>);
      setShowFilterModal(false);
    },
    [handleLocationFilter]
  );

  const handleDeleteModal = useCallback(
    (id?: string | number | number[]) => {
      if (Array.isArray(id)) {
        const selectedItems = id?.filter((item) => !isNaN(Number(item)));
        setMultipleItems(selectedItems);
      } else {
        const selectedItem = locationsList?.find(
          (location) => location.id === id
        );

        setSelectedTableItem(selectedItem ?? null);
      }

      setShowDeleteModal((prev) => !prev);
    },
    [locationsList]
  );

  useLayoutEffect(() => {
    console.log(filterString);
    downloadGenericFeature(
      FEATURE_UUID.LOCATION,
      filterString,
      null,
      locationFilter?.limit,
      locationFilter?.offset,
      locationFilter?.sort_order,
      locationFilter?.sort_column
    );
  }, [downloadGenericFeature, filterString, locationFilter]);

  return (
    <SidebarPage>
      <PageWrapper className="pagewrapper-wrapper--table">
        <PageHeader routes={routes} />
        <PageTitle
          title={t("common.locations")}
          description={t("pages.locations.description")}
        >
          <button
            className="btn locations-title-button"
            onClick={handleNewLocation}
          >
            <Icon name="add" />
            <span>{t("components.sidebarAddNew.location.title")}</span>
          </button>
        </PageTitle>
        <PageTable
          title={t("components.pageTable.building")}
          subtitle={t("components.pageTable.buildings")}
          tabs={tabs}
          columns={columns}
          featureUUid={FEATURE_UUID.LOCATION}
          onRecordSelect={handleRecordSelect}
          onRecordEdit={handleRecordEdit}
          onRecordDelete={handleDeleteModal}
          onRecordPreview={handleRecordPreview}
          noFeatureHandler={handleNewLocation}
          onFilterClick={handleFilterModal}
          featurePreviewFilter={filterString}
          filterActive={filterActive}
          tableHeaderText={renderLocationTableTitle}
        />
        <PageSidebar
          isSidebarVisible={isSidebarVisible}
          onClose={handleCloseSidebar}
          onEdit={handleRecordEdit}
          setConnectProjectMode={setConnectFeatureModal}
          type={FEATURE_TYPE.LOCATION}
          featureDetails={selectedTableItem}
        />
      </PageWrapper>
      {locationModal && (
        <LocationModal
          locationDetails={locationModal.defaultValues}
          onClose={closeLocationModal}
          handleAddNewComponent={handleAddNewComponentFromLocation}
        />
      )}
      {showObjectModal && (
        <ObjectModal
          mode="add"
          onClose={closeObjectModal}
          locationId={createdLocationId}
        />
      )}
      {connectFeatureModal && (
        <ConnectFeatureModal
          featureUuid={FEATURE_UUID.LOCATION}
          locationDetails={{
            ...selectedTableItem,
            projects: locationProjects,
          }}
          onClose={handleConnectFeatureModal}
        />
      )}
      {showFilterModal && (
        <LocationFilterModal
          onClose={handleFilterModal}
          locationFilter={locationFilter}
          onApplyFilter={handleApplyFilter}
          showFilterModal={showFilterModal}
        />
      )}
      {showDeleteModal && (multipleItems?.length || selectedTableItem?.id) && (
        <FeatureDeleteModal
          multipleFeatures={multipleItems}
          onClose={() => {
            handleDeleteModal();
            setMultipleItems([]);
          }}
          feature={selectedTableItem}
          featureType={FEATURE_TYPE.LOCATION}
        />
      )}
    </SidebarPage>
  );
}
