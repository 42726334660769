import Row from "@avinet/adaptive-ui-core/layout/Row";
import { PopupMenu } from "@avinet/adaptive-ui-core/ui/PopupMenu";
import { PopupMenuButton } from "@avinet/adaptive-ui-core/ui/PopupMenu";
import { t } from "i18next";

import { Text } from "../../components/text";
import "./EmissionCard.scss";

export function EmissionCard({
  title,
  description,
  totalAmount,
  unit,
}: {
  title: string;
  description: string;
  totalAmount: number;
  yearAmount: number;
  unit: string;
}) {
  return (
    <div className="card emissions-card">
      <Row wrap className="header">
        <h4>{title}</h4>
        <PopupMenuButton
          className="info-icon"
          icon="info"
          menu={
            <PopupMenu className="emission-card-info-popup">
              <Text text={title} fontWeight={700} size="small" color="dark" />
              <Text text={description} size="small" />
            </PopupMenu>
          }
        />
      </Row>
      <p>{t("pages.dashboard.potentialSavings")}</p>
      <Row wrap className="amount-container">
        <span className="amount">
          {totalAmount} {unit}
        </span>
      </Row>
      {/* MAY BE USED AT A LATER STAGE */}
      {/* <p>{t("pages.dashboard.realizedSavings")}</p>
      <Row wrap className="amount-container">
        <span className="amount">{totalAmount}</span>
        <span className="unit">{t("pages.dashboard.thisYear")}</span>
      </Row>
      <p>{t("pages.dashboard.plannedSavings")}</p>
      <Row wrap className="amount-container small">
        <span className="amount">{yearAmount}</span>
        <span className="unit">{t("pages.dashboard.thisYear")}</span>
      </Row> */}
    </div>
  );
}
