import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { THUMBNAIL_SIZE } from "../../contants/Enums";
import { AppUserContext } from "../../context/user-context/UserContext";
import { getMediaUri } from "../../utils/index.utils";
import { NoImagePlaceholer } from "../no-image";

export function ImagePreview({
  image,
  size,
  name,
  className,
}: {
  image: string | { dataUrl: string } | null | undefined;
  name?: string;
  size: THUMBNAIL_SIZE;
  className?: string;
}) {
  const { token } = useContext(AppUserContext);

  if (!image) return <NoImagePlaceholer className="table--no-data" />;

  return (
    <LazyLoadImage
      alt={name}
      height="100%"
      src={
        typeof image === "string"
          ? getMediaUri(image, token, size)
          : image?.dataUrl
      }
      effect="blur"
      className="pagetable-body-image"
      wrapperClassName={className}
    />
  );
}
