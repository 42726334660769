import { t } from "i18next";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  IFeatureDeleteType,
  IFullscreenModalData,
  ILocationProperties,
  IObjectProperties,
  IProductWithMappings,
  IProjectProperties,
} from "../../api/types";
import { LocationModal } from "../../components/add-new-feature/location/LocationModal";
import { ObjectModal } from "../../components/add-new-feature/object/ObjectModal";
import { ProjectModal } from "../../components/add-new-feature/project/ProjectModal";
import { Assessment } from "../../components/assessment/Assessment";
import { AssessmentSkeleton } from "../../components/assessment/AssessmentSkeleton";
import { AttachmentModal } from "../../components/attachment-modal/AttachmentModal";
import { ConnectFeatureModal } from "../../components/connect-feature-modal/ConnectFeatureModal";
import { FeatureDeleteModal } from "../../components/feature-delete-modal/FeatureDeleteModal";
import { ComponentFilterModal } from "../../components/filter-modal/ComponentFilterModal";
import { LocationFilterModal } from "../../components/filter-modal/LocationFilterModal";
import { FullscreenImgModal } from "../../components/fullscreen-img-modal/FullscreenImgModal";
import { Icon } from "../../components/icon/Icon";
import { NoFeaturePlaceholder } from "../../components/no-feature-placeholder";
import { PageHeader } from "../../components/page/page-header";
import { PageSidebar } from "../../components/page/page-sidebar";
import { PageTable } from "../../components/page/page-table/PageTable";
import { HorizontalTabs } from "../../components/page/page-table/horizontal-tabs";
import { PageTitle } from "../../components/page/page-title";
import { PageWrapper } from "../../components/page/page-wrapper";
import { ProjectSummary } from "../../components/project-summary/ProjectSummary";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../../contants/FeatureUuid";
import {
  dashboardUrl,
  projectsTabAssessments,
  projectsTabLocations,
  projectsUrl,
  reportUrl,
} from "../../contants/Routes";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import {
  FilterContext,
  IComponentFilter,
  ILocationFilter,
} from "../../context/filter-context/FilterContext";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import { useAttachmentModal } from "../../hooks/useAttachmentModal";
import { useComponentFilter } from "../../hooks/useComponentFilter";
import { useLocationFilter } from "../../hooks/useLocationFilter";
import { useLocationTableColumns } from "../../hooks/useLocationTableColumns";
import { useProjectObjectTable } from "../../hooks/useProjectObjectTable";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { useTableSelect } from "../../hooks/useTableSelect";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import { ReportOptionsModal } from "../../report/ReportOptionsModal";
import { IReportData } from "../../report/ReportTypes";
import "./styles.scss";

export function ProjectsPreviewPage() {
  const { id: featureId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    singleProject,
    setLocationsProducts,
    getAllProducts,
    downloadSelectFeature,
    downloadGenericFeature,
    projectProducts,
    projectLocations,
    locationProjects,
    featureLoader,
    getProjectsAssessments,
    projectAssessments,
    totalProjectProductsNumber,
  } = useContext(FeatureContext);
  const [projectDetails, setProjectDetails] = useState<IProjectProperties>();
  const [projectLoad, setProjectLoad] = useState(false);
  const [deleteFeatureType, setDeleteFeatureType] =
    useState<IFeatureDeleteType | null>(null);
  const { selectedOrganisation } = useContext(OrganisationContext);
  const fetchProjectDetails = useCallback(() => {
    selectedOrganisation?.id &&
      singleProject(parseInt(featureId ?? ""), navigate).then((res) => {
        setProjectLoad(true);
        setProjectDetails(res);
      });
  }, [featureId, singleProject, selectedOrganisation?.id, navigate]);
  const generateMappingImage = true;

  const {
    objectTableColumns,
    objectTableTabs,
    renderObjectAssessmentTableTitle,
  } = useProjectObjectTable(projectProducts, generateMappingImage);
  const [multipleItems, setMultipleItems] = useState<number[]>([]);
  const [resetBatchSelect, setResetBatchSelect] = useState(false);
  const { tabs: locationTableTabs, renderLocationTableTitle } =
    useLocationTableColumns(projectLocations);

  const [totalObjectsCount, setTotalObjectsCount] = useState(
    projectProducts?.length
  );

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    number | string | undefined
  >(undefined);
  const [showFullscreenModal, setShowFullscreenModal] =
    useState<IFullscreenModalData>({
      showModal: false,
      sliderData: [],
    });

  const {
    handleAttachmentModal,
    handleRecordEdit,
    handleRecordPreview,
    handleRecordSelect,
    handleProjectModal,
    selectedTableItem,
    setSelectedTableItem,
    isSidebarVisible,
    setIsSidebarVisible,
    showProjectModal,
    showLocationModal,
    handleLocationModal,
    setObjectModal,
    objectModal,
  } = useTableSelect(
    featureId,
    location.pathname?.split("/").pop() === projectsTabLocations
      ? FEATURE_UUID.PROJECT_LOCATIONS
      : FEATURE_UUID.PROJECT_PRODUCTS
  );
  const { columns } = useLocationTableColumns(projectLocations);
  const { buildStatuses } = useSelectOptions();
  const {
    componentProjectFilter,
    handleComponentProjectFilter,
    locationProjectFilter,
    handleLocationProjectFilter,
  } = useContext(FilterContext);

  const [displayReportOptionsModal, setDisplayReportOptionsModal] =
    useState(false);
  const [connectFeatureModal, setConnectFeatureModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createdLocationId, setCreatedLocationId] = useState<
    number | undefined
  >(undefined);

  const routes = useMemo(
    () =>
      projectDetails?.name
        ? [
            { url: dashboardUrl, title: t("common.home") },
            { url: projectsUrl, title: t("pages.projects.title") },
            { url: "", title: projectDetails?.name },
          ]
        : [
            { url: dashboardUrl, title: t("common.home") },
            { url: projectsUrl, title: t("pages.projects.title") },
          ],
    [projectDetails?.name]
  );
  const tTabs = useTranslationPath("components.projectsPreview.tabs");

  const tabs = useMemo(
    () => [
      { text: tTabs("summary"), to: "" },
      { text: tTabs("assessments"), to: projectsTabAssessments },
      { text: tTabs("locations"), to: projectsTabLocations },
      // { text: tTabs("externalFiles"), to: projectsTabExternalFiles }, TODO: implement later
    ],
    [tTabs]
  );

  const [activeTab, setActiveTab] = useState("");

  const activeTabUpdate = useCallback(() => {
    const pathname = tabs.find(
      (tab) => tab.to === location.pathname?.split("/").pop()
    );

    pathname?.text && setActiveTab(pathname.text);
  }, [tabs, location.pathname]);

  const objects = useMemo(() => {
    const convertedObjets = projectAssessments?.filter(
      (value, index, self) =>
        index ===
        self?.findIndex((product) => product.fk_product === value.fk_product)
    );

    return convertedObjets?.map(
      (obj) =>
        ({
          ...obj,
          productLocationMappings: projectAssessments?.filter(
            (mapping) => mapping.fk_product === obj.fk_product
          ),
        } as unknown as IProductWithMappings)
    );
  }, [projectAssessments]);

  useLayoutEffect(() => {
    !projectLoad && fetchProjectDetails();
  }, [featureId, projectLoad, fetchProjectDetails]);

  useLayoutEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const sidebarType = useCallback(() => {
    switch (location.pathname?.split("/").pop()) {
      case projectsTabLocations:
        return FEATURE_TYPE.LOCATION;
      default:
        return FEATURE_TYPE.OBJECT;
    }
  }, [location.pathname]);

  const onReportOptionsModalClose = useCallback(
    (reportData: IReportData | undefined) => {
      setDisplayReportOptionsModal(false);
      if (reportData) {
        navigate(reportUrl, {
          state: {
            reportData,
          },
        });
      }
    },
    [navigate]
  );

  const handleLocationFilterModal = useCallback(() => {
    setShowLocationFilterModal((prev) => !prev);
  }, []);

  const handleComponentFilterModal = useCallback(() => {
    setShowComponentFilterModal((prev) => !prev);
  }, []);

  const handleApplyLocationFilter = useCallback(
    (filter: ILocationFilter) => {
      handleLocationProjectFilter(filter);
      handleLocationFilterModal;
    },
    [handleLocationFilterModal, handleLocationProjectFilter]
  );

  const handleApplyComponentFilter = useCallback(
    (filter: Partial<IComponentFilter>) => {
      handleComponentProjectFilter(filter);
      handleComponentFilterModal;
    },
    [handleComponentFilterModal, handleComponentProjectFilter]
  );

  const [showLocationFilterModal, setShowLocationFilterModal] = useState(false);
  const [showComponentFilterModal, setShowComponentFilterModal] =
    useState(false);

  const locationFilterString = useLocationFilter(locationProjectFilter);
  const componentFilterString = useComponentFilter(componentProjectFilter);

  const locationFilterActive = !!locationFilterString;
  const componentFilterActive = !!componentFilterString;

  const handleConnectFeatureModal = () => {
    setConnectFeatureModal((prev) => !prev);
  };

  const loadProjectDetails = useCallback(async () => {
    activeTabUpdate();
    if (!featureId) return;
    !projectDetails && (await downloadGenericFeature(FEATURE_UUID.PROJECT));
    await downloadGenericFeature(
      FEATURE_UUID.PROJECT_LOCATIONS,
      `fk_project=${featureId}` +
        (locationFilterString && " AND " + locationFilterString),
      null,
      locationProjectFilter?.limit,
      locationProjectFilter?.offset,
      locationProjectFilter?.sort_order,
      locationProjectFilter?.sort_column
    );
    await downloadGenericFeature(
      FEATURE_UUID.PROJECT_PRODUCTS,
      `fk_project=${featureId}` +
        (componentFilterString && " AND " + componentFilterString),
      null,
      componentProjectFilter?.limit,
      componentProjectFilter?.offset,
      componentProjectFilter?.sort_order,
      componentProjectFilter?.sort_column
    ).then(
      (res) => res && typeof res === "number" && setTotalObjectsCount(res)
    );
    await getProjectsAssessments(featureId);
    await downloadSelectFeature(FEATURE_UUID.LOCATION);
  }, [
    activeTabUpdate,
    featureId,
    projectDetails,
    downloadGenericFeature,
    locationFilterString,
    locationProjectFilter?.limit,
    locationProjectFilter?.offset,
    locationProjectFilter?.sort_order,
    locationProjectFilter?.sort_column,
    componentFilterString,
    componentProjectFilter?.limit,
    componentProjectFilter?.offset,
    componentProjectFilter?.sort_order,
    componentProjectFilter?.sort_column,
    getProjectsAssessments,
    downloadSelectFeature,
  ]);

  const {
    showModal: showAttachmentModal,
    openModal: openAttachmentModal,
    handleClose: closeAttachmentModal,
    handleSave: saveAndCloseAttachmentModal,
    attachmentsState,
    handleDropImage,
    reloadAttachments,
    handleRemoveDropImage,
  } = useAttachmentModal(
    featureId,
    FEATURE_MEDIA_TYPE.PROJECT,
    loadProjectDetails
  );

  useEffect(() => {
    showProjectModal;
    connectFeatureModal;
    reloadAttachments();
    fetchProjectDetails();
  }, [
    connectFeatureModal,
    fetchProjectDetails,
    reloadAttachments,
    showProjectModal,
  ]);

  useLayoutEffect(() => {
    loadProjectDetails();
    return setLocationsProducts([]);
  }, [loadProjectDetails, setLocationsProducts]);

  useLayoutEffect(() => {
    if (location?.state?.featureId) {
      setSelectedAssessmentId(location?.state?.featureId);
      setActiveTab(tabs[1].text);
    }
  }, [tabs, location?.state?.featureId, loadProjectDetails, navigate]);

  const updateTableData = useCallback(
    async (tab: string) => {
      setResetBatchSelect(true);

      if (tab === "" || tab === tabs?.[0].text) {
        await downloadGenericFeature(
          FEATURE_UUID.PROJECT_PRODUCTS,
          `fk_project=${featureId}` +
            (componentFilterString && " AND " + componentFilterString),
          null,
          componentProjectFilter?.limit,
          componentProjectFilter?.offset,
          componentProjectFilter?.sort_order,
          componentProjectFilter?.sort_column
        ).then(
          (res) => res && typeof res === "number" && setTotalObjectsCount(res)
        );
        return;
      }

      if (tab === tabs?.[2].text) {
        await downloadGenericFeature(
          FEATURE_UUID.PROJECT_LOCATIONS,
          `fk_project=${featureId}` +
            (locationFilterString && " AND " + locationFilterString),
          null,
          locationProjectFilter?.limit,
          locationProjectFilter?.offset,
          locationProjectFilter?.sort_order,
          locationProjectFilter?.sort_column
        );
        return;
      }
    },
    [
      tabs,
      downloadGenericFeature,
      featureId,
      componentFilterString,
      componentProjectFilter,
      locationFilterString,
      locationProjectFilter,
    ]
  );

  useLayoutEffect(() => {
    if (!featureId) return;
    downloadGenericFeature(
      FEATURE_UUID.PROJECT_PRODUCTS,
      `fk_project=${featureId}` +
        (componentFilterString && " AND " + componentFilterString),
      null,
      componentProjectFilter?.limit,
      componentProjectFilter?.offset,
      componentProjectFilter?.sort_order,
      componentProjectFilter?.sort_column
    );
    downloadGenericFeature(
      FEATURE_UUID.PROJECT_LOCATIONS,
      `fk_project=${featureId}` +
        (locationFilterString && " AND " + locationFilterString),
      null,
      locationProjectFilter?.limit,
      locationProjectFilter?.offset,
      locationProjectFilter?.sort_order,
      locationProjectFilter?.sort_column
    );
  }, [
    featureId,
    downloadGenericFeature,
    componentFilterString,
    componentProjectFilter,
    locationProjectFilter,
    locationFilterString,
  ]);

  const closeObjectModal = useCallback(() => {
    setObjectModal(undefined);
    createdLocationId && setCreatedLocationId(undefined);
  }, [setObjectModal, createdLocationId]);

  const handleAddNewComponentFromLocation = useCallback(
    (id?: number) => {
      setObjectModal({
        mode: "add",
      });
      setCreatedLocationId(id);
    },
    [setObjectModal]
  );

  const handleDeleteModal = useCallback(
    (
      featureType: FEATURE_TYPE,
      featureDetails?: Partial<
        ILocationProperties & IObjectProperties & IProjectProperties
      > | null,
      id?: number | string | number[]
    ) => {
      if (Array.isArray(id)) {
        const selectedItems = id?.filter((item) => !isNaN(Number(item)));
        setMultipleItems(selectedItems);
      }

      if (featureType === FEATURE_TYPE.LOCATION) {
        const selectedItem = projectLocations?.find(
          (location) => location.id === id
        );

        downloadGenericFeature(
          FEATURE_UUID.LOCATION_PROJECTS,
          `fk_location=${id}`
        );

        setSelectedTableItem(selectedItem ?? null);
        setDeleteFeatureType({
          featureDetails: selectedItem,
          featureType,
          previewFeatureType: FEATURE_TYPE.PROJECT_LOCATION,
        });
      }

      if (featureType === FEATURE_TYPE.OBJECT) {
        const selectedItem = projectProducts?.find(
          (product) => product.id === id
        );
        setSelectedTableItem(selectedItem ?? null);
        setDeleteFeatureType({
          featureDetails: selectedItem,
          featureType,
          previewFeatureType: FEATURE_TYPE.PROJECT_PRODUCTS,
        });
      }

      if (featureType === FEATURE_TYPE.PROJECT) {
        setDeleteFeatureType({
          featureDetails,
          featureType,
          previewFeatureType: featureType,
        });
      }

      setShowDeleteModal((prev) => !prev);
    },
    [
      projectProducts,
      projectLocations,
      downloadGenericFeature,
      setSelectedTableItem,
    ]
  );

  return (
    <SidebarPage>
      <PageWrapper
        className={`${
          activeTab === tabs[0].text ||
          activeTab === "" ||
          activeTab === tabs[2].text
            ? "pagewrapper-wrapper--table"
            : ""
        } ${
          activeTab === tabs[1].text ? "pagewrapper-wrapper--more-padding" : ""
        }`}
      >
        <PageHeader routes={routes} />
        <PageTitle title={projectDetails?.name ?? ""}>
          <button
            className="btn light-delete"
            onClick={() =>
              handleDeleteModal(
                FEATURE_TYPE.PROJECT,
                projectDetails,
                projectDetails?.id
              )
            }
          >
            <Icon name="delete" />
            {t("common.delete")}
          </button>
          {/* TODO: share */}
          {/*<button*/}
          {/*    className="locations-title-button"*/}
          {/*    onClick={() => console.log('share project')}*/}
          {/*>*/}
          {/*  <ShareTableIcon width={30}/>*/}
          {/*  <span>{t("common.share")}</span>*/}
          {/*</button>*/}
          {/* TODO: export */}
          {/*<button*/}
          {/*    className="locations-title-button"*/}
          {/*    onClick={() => console.log('export project')}*/}
          {/*>*/}
          {/*  <ExportPlusIcon/>*/}
          {/*  <span>{t("common.export")}</span>*/}
          {/*</button>*/}
          {displayReportOptionsModal && (
            <ReportOptionsModal
              projectDetails={projectDetails}
              onClose={onReportOptionsModalClose}
            />
          )}
          <button
            className="btn light"
            onClick={() => handleProjectModal(true)}
          >
            <Icon name="edit" />
            {t("common.edit")}
          </button>
          <button className="btn light" onClick={openAttachmentModal}>
            <Icon name="attachment" />
            {t("common.attachments")}
          </button>
          <button
            className="btn light"
            onClick={() => setDisplayReportOptionsModal(true)}
          >
            <Icon name="reportIcon" />
            {t("report.modal.export")}
          </button>
          <button className="btn light" onClick={handleConnectFeatureModal}>
            <Icon name="location" />
            {t("components.connectLocation.connectLocationButton")}
          </button>
          <button
            className="btn"
            onClick={() =>
              setObjectModal({
                mode: "add",
              })
            }
          >
            <Icon name="add" />
            {t("components.locationsPreview.object")}
          </button>
        </PageTitle>
        <div className="page-horizontal-tabs-wrapper">
          <HorizontalTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onClick={(tab) => updateTableData(tab)}
          />
        </div>

        <Routes>
          <Route
            path=""
            element={
              <>
                <ProjectSummary
                  projectDetails={projectDetails}
                  registeredObjectsCount={totalObjectsCount}
                />
                <PageTable
                  title={t("components.pageTable.object")}
                  subtitle={t("components.pageTable.objectsCount")}
                  tabs={objectTableTabs}
                  columns={objectTableColumns}
                  onRecordPreview={handleRecordPreview}
                  featurePreviewFilter={
                    `fk_project=${featureId}` +
                    (componentFilterString && " AND " + componentFilterString)
                  }
                  featureUUid={FEATURE_UUID.PROJECT_PRODUCTS}
                  onRecordEdit={handleRecordEdit}
                  onRecordSelect={handleRecordSelect}
                  onRecordDelete={(id) =>
                    handleDeleteModal(
                      FEATURE_TYPE.OBJECT,
                      selectedTableItem,
                      id
                    )
                  }
                  totalItemsCount={totalProjectProductsNumber}
                  noFeatureHandler={handleConnectFeatureModal}
                  tableHeaderText={renderObjectAssessmentTableTitle}
                  onFilterClick={handleComponentFilterModal}
                  filterActive={componentFilterActive}
                  resetBatchSelect={resetBatchSelect}
                  setResetBatchSelect={setResetBatchSelect}
                />
              </>
            }
          />

          <Route
            path={projectsTabAssessments}
            element={
              <>
                {featureLoader ? (
                  <AssessmentSkeleton />
                ) : (
                  <>
                    {objects?.length ? (
                      objects?.map((object, index) => (
                        <Fragment key={`${object.fk_product}-${index}`}>
                          <Assessment
                            object={object}
                            selectedAssessmentId={selectedAssessmentId}
                            setSelectedAssessmentId={setSelectedAssessmentId}
                            setShowFullscreenModal={setShowFullscreenModal}
                          />
                        </Fragment>
                      ))
                    ) : (
                      <div className="upload-image-placeholder-wrapper">
                        <NoFeaturePlaceholder
                          grid
                          type={FEATURE_TYPE.PROJECT_LOCATION}
                          parentElementType={FEATURE_TYPE.PROJECT}
                          onClick={handleConnectFeatureModal}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            }
          />

          <Route
            path={projectsTabLocations}
            element={
              <>
                <PageTable
                  title={t("components.pageTable.building")}
                  subtitle={t("components.pageTable.buildings")}
                  tabs={locationTableTabs}
                  columns={columns}
                  featureUUid={FEATURE_UUID.PROJECT_LOCATIONS}
                  featurePreviewFilter={
                    `fk_project=${featureId}` +
                    (locationFilterString && " AND " + locationFilterString)
                  }
                  onRecordSelect={handleRecordSelect}
                  onRecordEdit={handleRecordEdit}
                  onRecordDelete={(id) =>
                    handleDeleteModal(
                      FEATURE_TYPE.LOCATION,
                      selectedTableItem,
                      id
                    )
                  }
                  onRecordPreview={handleRecordPreview}
                  statusOptions={buildStatuses}
                  totalItemsCount={columns?.data?.length}
                  noFeatureHandler={handleConnectFeatureModal}
                  onFilterClick={handleLocationFilterModal}
                  filterActive={locationFilterActive}
                  tableHeaderText={renderLocationTableTitle}
                  resetBatchSelect={resetBatchSelect}
                  setResetBatchSelect={setResetBatchSelect}
                />
              </>
            }
          />
        </Routes>
      </PageWrapper>
      {showAttachmentModal && (
        <AttachmentModal
          attachments={attachmentsState}
          onDropImage={handleDropImage}
          handleRemoveDropImage={handleRemoveDropImage}
          onClose={closeAttachmentModal}
          onSave={saveAndCloseAttachmentModal}
        />
      )}
      {showProjectModal && (
        <ProjectModal
          projectDetails={projectDetails}
          showProjectModal={showProjectModal}
          modalState={handleProjectModal}
          setAttachmentModal={handleAttachmentModal}
        />
      )}
      {objectModal && (
        <ObjectModal
          mode={objectModal.mode}
          objectDetails={objectModal.defaultValues}
          onClose={closeObjectModal}
          projectId={Number(featureId)}
          locationOptionsList={projectLocations}
          locationId={createdLocationId}
        />
      )}
      {showLocationModal && (
        <LocationModal
          locationDetails={selectedTableItem}
          onClose={handleLocationModal}
          handleAddNewComponent={handleAddNewComponentFromLocation}
        />
      )}
      {connectFeatureModal && (
        <ConnectFeatureModal
          setTotalProducts={setTotalObjectsCount}
          featureUuid={
            isSidebarVisible ? FEATURE_UUID.LOCATION : FEATURE_UUID.PROJECT
          }
          projectDetails={!isSidebarVisible ? projectDetails : undefined}
          locationDetails={
            isSidebarVisible
              ? {
                  ...selectedTableItem,
                  projects: locationProjects,
                }
              : undefined
          }
          onClose={handleConnectFeatureModal}
        />
      )}
      <FullscreenImgModal
        showModal={showFullscreenModal.showModal}
        sliderItems={showFullscreenModal.sliderData}
        setShowModal={() =>
          setShowFullscreenModal({
            ...showFullscreenModal,
            showModal: false,
          })
        }
      />
      <PageSidebar
        featureDetails={selectedTableItem}
        type={sidebarType()}
        isSidebarVisible={isSidebarVisible}
        onClose={() => setIsSidebarVisible(false)}
        onEdit={handleRecordEdit}
        setConnectProjectMode={setConnectFeatureModal}
        projectId={projectDetails?.id}
      />
      {showLocationFilterModal && (
        <LocationFilterModal
          onClose={handleLocationFilterModal}
          locationFilter={locationProjectFilter}
          onApplyFilter={handleApplyLocationFilter}
          showFilterModal={showLocationFilterModal}
        />
      )}
      {showComponentFilterModal && (
        <ComponentFilterModal
          onClose={handleComponentFilterModal}
          componentFilter={componentProjectFilter}
          onApplyFilter={handleApplyComponentFilter}
          showFilterModal={showComponentFilterModal}
          inProject={true}
        />
      )}
      {showDeleteModal && deleteFeatureType?.featureType && (
        <FeatureDeleteModal
          multipleFeatures={multipleItems}
          onClose={() => setShowDeleteModal(false)}
          feature={deleteFeatureType?.featureDetails ?? null}
          featureType={deleteFeatureType?.featureType}
          previewPageType={deleteFeatureType?.previewFeatureType}
          updatePreviewTableData={() => updateTableData(activeTab)}
        />
      )}
    </SidebarPage>
  );
}
