import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import React, {
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ActionMeta, InputActionMeta } from "react-select";
import CreatableSelect from "react-select/creatable";

import { KIND_RESPONSE } from "../../api/api-problem";
import {
  AddUserToOrganisationRequest,
  AddUserToOrganisationResponse,
} from "../../api/types";
import { emailRegex } from "../../contants/Patterns";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../context/user-context/UserContext";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import { Text } from "../text";
import "./AddNewUserModal.scss";
import { AddNewUserProps } from "./AddNewUserProps";

interface Option {
  readonly label: string;
  readonly value: string;
}

export const AddNewUserModal = ({
  modalState,
  onSubmission,
  amountOfInvites,
}: AddNewUserProps) => {
  const { t } = useTranslation();
  const header = {
    title: t("components.addUserModal.title"),
    description: "",
  };

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<readonly Option[]>([]);
  const [showInviteYourselfError, setShowInviteYourselfError] = useState(false);
  const [showNoSelectionError, setShowNoSelectionError] = useState(false);

  const { addNewUserToOrganisation, selectedOrganisation } =
    useContext(OrganisationContext);

  const { userProfile } = useContext(AppUserContext);

  const handleCloseModal = useCallback(() => {
    modalState(false);
  }, [modalState]);

  const { form, state } = useForm({
    defaultValues: {
      email: "",
      org_id: selectedOrganisation?.id,
    },
    onSubmit: (data) => {
      amountOfInvites(value.length);
      value.forEach((item) => {
        handleAddUserToOrg({ ...data, email: item.value?.toLowerCase() });
      });
    },
  });

  const handleAddUserToOrg = useCallback(
    (data: AddUserToOrganisationRequest) => {
      const error = t("errors.addUserToOrganisationError");

      addNewUserToOrganisation(data)
        .then((res: AddUserToOrganisationResponse | void) => {
          if (res && res.kind) {
            if (res.kind === KIND_RESPONSE.OK) {
              onSubmission();
              return res;
            }
          } else {
            console.error(error);
          }
        })

        .catch(() => {
          console.error(data);
        });
    },
    [addNewUserToOrganisation, onSubmission, t]
  );

  const createOption = useCallback(
    (label: string) => ({
      label,
      value: label,
    }),
    []
  );

  const isInviteYourself = useCallback(
    (values: readonly Option[]) => {
      const email = userProfile?.email;
      return values.some((item) => item.value === email);
    },
    [userProfile?.email]
  );

  const handleButtons = useCallback(
    (type: "cancel" | "submit") => {
      if (type === "cancel") {
        return modalState(false);
      } else if (type === "submit") {
        if (value.length === 0) {
          setShowNoSelectionError(true);
        } else if (isInviteYourself(value)) {
          setShowInviteYourselfError(true);
        } else {
          form.submit();
          modalState(false);
        }
      }
    },
    [form, isInviteYourself, modalState, value]
  );

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      switch (event.key) {
        case "Enter":
          if (emailRegex.test(inputValue)) {
            setValue((prev) => [...prev, createOption(inputValue)]);
            setInputValue("");
          }
          handleButtons("submit");
          break;
        case " ":
        case ",": {
          if (!emailRegex.test(inputValue)) return;
          setValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
          event.preventDefault();
        }
      }
    },
    [createOption, handleButtons, inputValue, setValue]
  );

  const handleInputChange = (
    inputValue: string,
    action: InputActionMeta | ActionMeta<Option>
  ) => {
    if (
      action.action !== "input-blur" &&
      action.action !== "menu-close" &&
      !Array.isArray(action)
    ) {
      setInputValue(inputValue);
    }
  };

  useEffect(() => {
    setShowNoSelectionError(false);
    setShowInviteYourselfError(false);
  }, [value]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      const selectElement = document.querySelector(".createable-select");

      if (
        selectElement &&
        !selectElement.contains(target) &&
        emailRegex.test(inputValue)
      ) {
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
      }
    },
    [createOption, inputValue]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="add-new-user-modal">
      <Modal className="feature-modal" show={true}>
        <div className={`feature-modal-container add-user-modal-container`}>
          <FeatureModalHeader
            subtitle={t("components.addUserModal.description")}
            modalState={modalState}
            onClose={handleCloseModal}
            {...header}
          />
          <FormProvider form={form} state={state}>
            <Text
              className="toplabel"
              text={t("components.addUserModal.addUser")}
            />
            <CreatableSelect
              className="createable-select"
              classNamePrefix={"react-select"}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={`${t("components.addUserModal.email")}`}
              value={value}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            <Text
              className="underlabel"
              text={t("components.addUserModal.upto")}
            />
            {showNoSelectionError && (
              <Text
                className="error-message add-user-error"
                text={t("components.addUserModal.noSelectionError")}
              />
            )}
            {showInviteYourselfError && (
              <Text
                className="error-message add-user-error"
                text={t("components.addUserModal.inviteYourselfError")}
              />
            )}
          </FormProvider>
          <SubmitButtons
            onCancel={() => handleButtons("cancel")}
            onSubmit={() => handleButtons("submit")}
            submitText={t("common.submit")}
            formId={"feature-form-edit"}
          />
        </div>
      </Modal>
    </div>
  );
};
