import Select from "react-select";

import { Icon } from "../icon/Icon";
import { InputMultiSearchSelectProps } from "./index.props";
import "./styles.scss";

export function InputMultiSearchSelect({
  id,
  label,
  placeholder,
  required,
  disabled,
  options,
  onChange,
  value,
}: InputMultiSearchSelectProps) {
  return (
    <div className="aui-form-control aui-text">
      <label>{label}</label>
      <div className="multiselect-wrapper">
        <Select
          id={id}
          isMulti
          name={id}
          options={options}
          isClearable={false}
          isSearchable
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          value={value}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          isDisabled={disabled}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          classNamePrefix="multi-select"
          classNames={{
            control: (state) =>
              state.isFocused
                ? "multi-select--no-border"
                : "multi-select__control",
            option: (state) =>
              state.isSelected
                ? "multi-select__option--selected"
                : "multi-select__option",
            dropdownIndicator: () => "multi-select__indicator",
            indicatorSeparator: () => "multi-select__indicator--separator",
            menu: () => "multi-select__menu",
            menuList: () => "multi-select__menu-list",
            container: () => "multi-select__container",
          }}
        />
        <Icon
          className="multi-select__custom-icon"
          name="chevron"
          rotate={90}
        />
      </div>
    </div>
  );
}
