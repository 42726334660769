import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  baseUrl,
  createNewPasswordUrl,
  dashboardUrl,
  enterCodeUrl,
  helpUrl,
  joinOrganisationUrl,
  locationsUrl,
  loginUrl,
  mapsUrl,
  notificationsUrl,
  objectsUrl,
  organizationDetailsUrl,
  profileUrl,
  projectsUrl,
  registerUrl,
  reportUrl,
  resetPasswordUrl,
  selectOrganisationUrl,
  termsAndConditionsUrl,
} from "../contants/Routes";
import { CreateNewPassword } from "../pages/create-new-password";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { EnterCode } from "../pages/enter-code";
import { Error404 } from "../pages/error-pages/404page";
import { Help } from "../pages/help/Help";
import { JoinOrganisation } from "../pages/join-organisation/JoinOrganisation";
import { Locations } from "../pages/locations";
import { LocationsPreviewPage } from "../pages/locations-preview";
import { Login } from "../pages/login/Login";
import { MapPage } from "../pages/map";
import { Notifications } from "../pages/notifications";
import { Objects } from "../pages/objects";
import { ObjectsPreviewPage } from "../pages/objects-preview";
import { OrganizationDetails } from "../pages/organization-details";
import { Profile } from "../pages/profile";
import { Projects } from "../pages/projects";
import { ProjectsPreviewPage } from "../pages/projects-preview";
import { Register } from "../pages/register";
import { ResetPassword } from "../pages/reset-password";
import { SelectOrganisation } from "../pages/select-organisation";
import { TermsAndConditions } from "../pages/terms-and-conditions/TermsAndConditions";
import { ReportGenerator } from "../report/report-page/ReportGenerator";
import { ProtectedRoute } from "./ProtectedRoute";
import { UnprotectedRoute } from "./UnprotectedRoute";

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"*"} element={<Error404 />} />
        <Route
          path={baseUrl}
          element={
            <UnprotectedRoute>
              <Register />
            </UnprotectedRoute>
          }
        />
        <Route path={termsAndConditionsUrl} element={<TermsAndConditions />} />
        <Route
          path={registerUrl}
          element={
            <UnprotectedRoute>
              <Register />
            </UnprotectedRoute>
          }
        />
        <Route
          path={loginUrl}
          element={
            <UnprotectedRoute>
              <Login />
            </UnprotectedRoute>
          }
        />
        <Route
          path={resetPasswordUrl}
          element={
            <UnprotectedRoute>
              <ResetPassword />
            </UnprotectedRoute>
          }
        />
        <Route
          path={createNewPasswordUrl}
          element={
            <ProtectedRoute redirectPath={createNewPasswordUrl}>
              <CreateNewPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={enterCodeUrl}
          element={
            <UnprotectedRoute>
              <EnterCode />
            </UnprotectedRoute>
          }
        />
        <Route
          path={selectOrganisationUrl}
          element={
            <ProtectedRoute>
              <SelectOrganisation />
            </ProtectedRoute>
          }
        />
        <Route
          path={dashboardUrl}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={joinOrganisationUrl}
          element={
            <UnprotectedRoute>
              <JoinOrganisation />
            </UnprotectedRoute>
          }
        />
        <Route
          path={projectsUrl}
          element={
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${projectsUrl}/:id/*`}
          element={
            <ProtectedRoute>
              <ProjectsPreviewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={locationsUrl}
          element={
            <ProtectedRoute>
              <Locations />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${locationsUrl}/:id/*`}
          element={
            <ProtectedRoute>
              <LocationsPreviewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={objectsUrl}
          element={
            <ProtectedRoute>
              <Objects />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${objectsUrl}/:id/*`}
          element={
            <ProtectedRoute>
              <ObjectsPreviewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={mapsUrl}
          element={
            <ProtectedRoute>
              <MapPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={notificationsUrl}
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path={helpUrl}
          element={
            <ProtectedRoute>
              <Help />
            </ProtectedRoute>
          }
        />
        <Route
          path={profileUrl}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${organizationDetailsUrl}/:id/*`}
          element={
            <ProtectedRoute>
              <OrganizationDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={reportUrl}
          element={
            <ProtectedRoute>
              <ReportGenerator />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
