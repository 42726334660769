import React, { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import loginBanner from "../../assets/images/login-bg/login-bg.png";
import { AuthLanguageSelect } from "../../components/auth-language-select";
import { RegisterContent } from "../../components/register/register-content";
import { RegisterTitle } from "../../components/register/register-title";
import { RegisterWrapper } from "../../components/register/register-wrapper";
import { VerifyCodeInput } from "../../components/verify-code-input";
import { loginUrl } from "../../contants/Routes";
import { AppUserContext } from "../../context/user-context/UserContext";
import "./styles.scss";

export const EnterCode: FC = () => {
  const { t } = useTranslation();
  const { verify, verifyBegin, register, registerUserData } =
    useContext(AppUserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const verifyCodeLength = 6;

  const handleSubmitForm = () => {
    if (verifyCode.length !== verifyCodeLength) {
      setVerifyCodeError(true);
      return;
    }

    verify(verifyCode)
      .then((token) => {
        if (token !== null && registerUserData.value !== undefined) {
          setVerifyCodeError(false);
          register(registerUserData.value, token)
            .then(() => {
              navigate(loginUrl);
            })
            .catch(() => {
              console.error(registerUserData.error);
            });
        } else {
          setVerifyCodeError(true);
        }
      })
      .catch(() => {
        setVerifyCodeError(true);
        console.error(verifyCode);
      });
  };

  const handleResendCode = useCallback(() => {
    if (registerUserData.value !== undefined) {
      verifyBegin({ email: registerUserData.value.email }).catch(() => {
        console.error(registerUserData.error);
      });
    }
  }, [registerUserData, verifyBegin]);

  const handleClearVerifyCode = () => {
    setVerifyCode("");
    setVerifyCodeError(false);
  };

  return (
    <RegisterWrapper className="enter-code">
      <RegisterContent withCopyrights>
        <RegisterTitle
          text={t("pages.enterCode.title")}
          description={t("pages.enterCode.description")}
        >
          <AuthLanguageSelect />
          {location?.state?.email && (
            <div className="enter-code__email">
              <h4>{location?.state?.email}</h4>
            </div>
          )}
          <VerifyCodeInput
            codeLength={verifyCodeLength}
            error={verifyCodeError}
            verifyCodeValue={verifyCode}
            setVerifyCodeValue={setVerifyCode}
            resentCode={() => handleResendCode()}
            onClickCancel={handleClearVerifyCode}
            onClickVerify={handleSubmitForm}
          />
        </RegisterTitle>
      </RegisterContent>
      <RegisterContent className="enter-code-banner">
        <div className="login-banner__image">
          <img src={loginBanner} alt="login-banner" />
        </div>
      </RegisterContent>
    </RegisterWrapper>
  );
};
