import React, { FC } from "react";

const EnvelopeIcon: FC = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33398 2.66699L6.77726 6.47729C7.21804 6.78583 7.43844 6.94011 7.67816 6.99986C7.88991 7.05265 8.11139 7.05265 8.32314 6.99986C8.56287 6.94011 8.78326 6.78583 9.22404 6.47729L14.6673 2.66699M4.53398 11.3337H11.4673C12.5874 11.3337 13.1475 11.3337 13.5753 11.1157C13.9516 10.9239 14.2576 10.618 14.4493 10.2416C14.6673 9.81382 14.6673 9.25376 14.6673 8.13366V3.86699C14.6673 2.74689 14.6673 2.18683 14.4493 1.75901C14.2576 1.38269 13.9516 1.07673 13.5753 0.884979C13.1475 0.666992 12.5874 0.666992 11.4673 0.666992H4.53398C3.41388 0.666992 2.85383 0.666992 2.426 0.884979C2.04968 1.07673 1.74372 1.38269 1.55197 1.75901C1.33398 2.18683 1.33398 2.74689 1.33398 3.86699V8.13366C1.33398 9.25376 1.33398 9.81382 1.55197 10.2416C1.74372 10.618 2.04968 10.9239 2.426 11.1157C2.85383 11.3337 3.41388 11.3337 4.53398 11.3337Z"
        stroke="#5D6B98"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnvelopeIcon;
