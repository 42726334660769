/* eslint-disable */
import { ApiResponse } from "apisauce";

import { DEFAULT_API_CONFIG } from "../api/api-config";
import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import * as Types from "../api/types";
import { Api } from "./api/api";

export class DictionaryApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getDictionary(
    uuid: string,
    columns?: string[]
  ): Promise<Types.DictionaryResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "WebServices/client/DataView.asmx/ReadAny",
      {
        request: {
          theme_uuid: uuid,
          columns: [...["kode", "navn"], ...(columns ?? [])],
          filter: { filterColumns: [] },
          excludeGeometry: true,
        },
      },
      { baseURL: DEFAULT_API_CONFIG.webUrl }
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }
}

/* eslint-disable */
