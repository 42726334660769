import "./styles.scss";

export function LoadingMask({ label }: { label?: string }) {
  return (
    <div className="loading-mask">
      <h3>
        <span className="label">{label}</span>{" "}
        <span className="loading-spinner">
          <span />
          <span />
          <span />
        </span>
      </h3>
    </div>
  );
}
