import { useEffect, useState } from "react";

import { IOrgMemberData } from "../api/types";

const calculateRange = (data: IOrgMemberData[] | [], rowsPerPage: number) => {
  const range = [];
  const num = Math.ceil(data.length / rowsPerPage);

  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

export const sliceData = (
  data: IOrgMemberData[] | [],
  page: number,
  rowsPerPage: number
) => {
  return page === 0
    ? data.slice(0, rowsPerPage)
    : data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
};

const useTable = (
  data: IOrgMemberData[] | [],
  page: number,
  rowsPerPage: number
) => {
  const [tableRange, setTableRange] = useState<number[] | []>([]);
  const [slice, setSlice] = useState<IOrgMemberData[] | []>([]);

  useEffect(() => {
    const range = calculateRange(data, rowsPerPage);
    setTableRange([...range]);

    const slice = sliceData(data, page, rowsPerPage);
    setSlice(slice);
  }, [data, setTableRange, page, setSlice, rowsPerPage]);

  return { slice, range: tableRange };
};

export default useTable;
