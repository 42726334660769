enum LOCAL_STORAGE {
  USER = "user_store",
  USER_ORGANISATION = "user_organisation",
  SIDEBAR_COLLAPSED = "sidebar_collapsed",
  DICTIONARY_CATEGORY = "dic_category",
  DICTIONARY_PARENT_CATEGORY = "dic_parent_category",
  DICTIONARY_ELEMENT = "dic_element",
  DICTIONARY_CATEGORY_EXPIRATION_TIME = "dic_category_expiration_time",
  DICTIONARY_PARENT_CATEGORY_EXPIRATION_TIME = "dic_parent_category_expiration_time",
  DICTIONARY_ELEMENT_EXPIRATION_TIME = "dic_element_expiration_time",
  TABLE_VIEW = "table_view",
}

export default LOCAL_STORAGE;
