import { useEffect } from "react";

/**
 *
 * @param key Keyboard key to capture. See [Named Key Attribute Values](https://www.w3.org/TR/uievents-key/#named-key-attribute-values) for possible values.
 * @param callback Function provided should be stable. False or undefined means key is not captured.
 */
export function useKeyPressHandler(
  key: string,
  callback?: (() => void) | false
) {
  useEffect(() => {
    if (!callback) return;

    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === key) {
        e.stopPropagation();
        e.preventDefault();
        callback?.();
      }
    };

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [key, callback]);
}
