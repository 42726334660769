/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useCallback } from "react";

import { ILocationProperties, IOrganisation } from "../api/types";
import { THUMBNAIL_SIZE } from "../contants/Enums";
import { useTranslationPath } from "../hooks/useTranslationPath";
import {
  getMediaUri,
  guidGenerator,
  handleTranslateBuildingCategory,
  handleTranslateBuildingDemolitionType,
} from "../utils/index.utils";
import { FrontPage } from "./FrontPage";
import { InfoTextPage } from "./InfoTextPage";
import { ReportComponentCategoryView } from "./ReportComponentCategoryView";
import { ReportFooter } from "./ReportFooter";
import { ReportHeader } from "./ReportHeader";
import { ReportTableRowContent } from "./ReportTableRowContent";
import { IReportData } from "./ReportTypes";
import { IContextFunctions } from "./ReportTypes";
import { colors } from "./colors";

const styles = StyleSheet.create({
  page: {
    marginTop: 0,
    paddingBottom: 50,
    paddingHorizontal: 0,
  },
  content: {
    paddingHorizontal: 30,
    fontSize: 16,
  },
  locationImageContainer: {
    marginVertical: 10,
    maxHeight: 300,
    width: "100%",
    objectFit: "scale-down",
    justifyContent: "center",
    alignItems: "center",
  },
  locationHeader: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 20,
    paddingHorizontal: 30,
  },
  locationTableContainer: {
    marginVertical: 10,
    backgroundColor: colors.lightContrast,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: colors.gray,
    borderWidth: 1,
    zIndex: 2,
  },
  imageContainer: {
    marginVertical: 10,
    height: 200,
    width: "49%",
    objectFit: "scale-down",
    justifyContent: "center",
    alignItems: "center",
  },
  ImageGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
  },
});

function RemoveLocationNonReportFields(): (
  value: string,
  index: number,
  array: string[]
) => unknown {
  return (k) => {
    //Filter out keys we never want to display
    // TODO: Use config and user input from modal for this
    return (
      k !== "name" && // Shown in heading instead
      k !== "id" &&
      k !== "uuid" &&
      k !== "date_created" &&
      k !== "date_modified" &&
      k !== "created_by" &&
      k !== "modified_by" &&
      k !== "geom" &&
      k !== "fk_location" &&
      k !== "fk_room" &&
      k !== "fk_floor" &&
      k !== "fk_product" &&
      k !== "fk_project" &&
      k !== "image" &&
      k !== "description" && // Shown elsewhere
      k !== "fk_organization"
    );
  };
}

export const ReportDocument = ({
  organisationData,
  reportData,
  token,
  contextFunctions,
}: {
  organisationData: IOrganisation | null;
  reportData: IReportData;
  token: string | null;
  contextFunctions: IContextFunctions;
}) => {
  const locationTranslations = useTranslationPath("report.locations");
  const { locations: locationsConfig } = reportData.config;

  const getValueText = useCallback(
    (key: string, mapping: ILocationProperties) => {
      if (key === "status") {
        return locationTranslations((mapping as any)[key]);
      }
      if (key === "building_category") {
        return handleTranslateBuildingCategory((mapping as any)[key]);
      }
      if (key === "type_of_demolition") {
        return handleTranslateBuildingDemolitionType((mapping as any)[key]);
      }

      return (mapping as any)[key]?.toString() || "";
    },
    [locationTranslations]
  );

  const logoPath = organisationData?.logo_image
    ? getMediaUri(organisationData.logo_image, token, THUMBNAIL_SIZE.SMALL)
    : undefined;

  return (
    <Document>
      <FrontPage reportData={reportData} logoPath={logoPath} />
      <InfoTextPage logoPath={logoPath} />
      {reportData.locations?.map((pl) => {
        const mediaUrl =
          pl.locationData.image && token
            ? getMediaUri(pl.locationData.image, token)
            : null;

        return (
          <Page style={styles.page} size="A4" key={pl.locationData.id}>
            <ReportHeader />
            <Text style={styles.locationHeader}>{pl.locationData.name}</Text>
            <View style={styles.content}>
              {mediaUrl && (
                <View style={styles.locationImageContainer}>
                  <Image src={mediaUrl} />
                </View>
              )}
              {pl.locationData.description && (
                <Text>{pl.locationData.description}</Text>
              )}
              <View style={styles.locationTableContainer}>
                {Object.keys(locationsConfig)
                  ?.filter(RemoveLocationNonReportFields())
                  .map((key, i) => {
                    const keyText = locationTranslations(key) || key;
                    const valueText = getValueText(key, pl.locationData);
                    return (
                      <ReportTableRowContent
                        key={guidGenerator()}
                        keyText={keyText}
                        valueText={valueText}
                        index={i}
                      />
                    );
                  })}

                {reportData.showAllImages && (
                  <View style={styles.ImageGrid}>
                    {pl.media
                      ?.filter(
                        (mediaItem) =>
                          mediaItem.media !== pl.locationData.image &&
                          mediaItem.file_type.includes("image")
                      )
                      .map((mediaItem) => (
                        <View
                          key={mediaItem.uuid}
                          style={styles.imageContainer}
                        >
                          <Image
                            src={getMediaUri(
                              mediaItem.media,
                              token,
                              THUMBNAIL_SIZE.MEDIUM
                            )}
                          />
                        </View>
                      ))}
                  </View>
                )}
              </View>

              {Object.keys(pl.componentsDictionary).map((key) => {
                return (
                  <ReportComponentCategoryView
                    key={guidGenerator()}
                    parent_category={key}
                    productMappings={pl.componentsDictionary[key]}
                    config={reportData.config}
                    contextFunctions={contextFunctions}
                    token={token}
                    showAllImages={reportData.showAllImages}
                  />
                );
              })}
            </View>
            <ReportFooter logoPath={logoPath} />
          </Page>
        );
      })}
    </Document>
  );
};
