import React, { FC } from "react";

const EnterCodeBgIcon2: FC = () => {
  return (
    <svg
      width="695"
      height="695"
      viewBox="0 0 695 695"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="641"
        y="-22"
        width="108"
        height="937.184"
        rx="54"
        transform="rotate(45 641 -22)"
        fill="#6239D9"
      />
    </svg>
  );
};

export default EnterCodeBgIcon2;
