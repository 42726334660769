import { useEffect, useState } from "react";

export const useCheckMobile = (maxWidth?: number | undefined) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= (maxWidth ?? 1024);

  return { isMobile };
};
