import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { useCallback } from "react";

import { IFloors } from "../../../../api/types";
import { useTranslationPath } from "../../../../hooks/useTranslationPath";
import { guidGenerator } from "../../../../utils/index.utils";
import { Text } from "../../../text";
import { LocationFloor } from "./floor/LocationFloor";
import "./styles.scss";

export function LocationFloorsEditor({
  floorsAbove,
  floorsUnder,
  setFloorState,
}: {
  floorsAbove: IFloors[];
  floorsUnder: IFloors[];
  setFloorState(value: React.SetStateAction<IFloors[]>): void;
}) {
  const tCommon = useTranslationPath("common");
  const tLocationModal = useTranslationPath("components.locationModal");

  const addNewFloor = useCallback(
    (ground: "above" | "under") => {
      setFloorState((current) => {
        const sequence_ids = current.map((floor) => floor.sequence_id);
        const sequence_id =
          ground === "above"
            ? Math.max(...sequence_ids, 0) + 1
            : Math.min(...sequence_ids, 0) - 1;

        const newFloor = {
          name: "",
          uuid: guidGenerator(),
          sequence_id: sequence_id,
          room_list: [],
        };

        if (ground === "above") {
          return [newFloor, ...current];
        } else {
          return [...current, newFloor];
        }
      });
    },
    [setFloorState]
  );

  const addNewRoom = useCallback(
    (floorUuid: string) => {
      setFloorState((current) => {
        return current.map((floor) => {
          if (floor.uuid === floorUuid) {
            const sequence_ids = [
              0,
              ...floor.room_list.map((floor) => floor.sequence_id),
            ];
            const sequence_id = Math.max(...sequence_ids) + 1;

            return {
              ...floor,
              room_list: [
                ...floor.room_list,
                {
                  uuid: guidGenerator(),
                  name: "",
                  sequence_id: sequence_id,
                },
              ],
            };
          }
          return floor;
        });
      });
    },
    [setFloorState]
  );

  const handleFloorRename = useCallback(
    (foorUuid: string, value: string) => {
      setFloorState((prev) =>
        prev.map((floor) =>
          floor.uuid === foorUuid
            ? {
                ...floor,
                name: value,
              }
            : floor
        )
      );
    },
    [setFloorState]
  );

  const handleRoomRename = useCallback(
    (roomUuid: string, value: string) => {
      setFloorState((prev) =>
        prev.map((floor) => ({
          ...floor,
          room_list: floor.room_list.map((room) =>
            room.uuid === roomUuid
              ? {
                  ...room,
                  name: value,
                }
              : room
          ),
        }))
      );
    },
    [setFloorState]
  );

  const handleDeleteFloor = useCallback(
    (floorUuid: string) => {
      setFloorState((prev) => prev.filter((floor) => floor.uuid !== floorUuid));
    },
    [setFloorState]
  );

  const handleDeleteRoom = useCallback(
    (roomUuid: string) => {
      setFloorState((prev) =>
        prev.map((floor) => ({
          ...floor,
          room_list: floor.room_list.filter((room) => room.uuid !== roomUuid),
        }))
      );
    },
    [setFloorState]
  );

  return (
    <div className="location-modal-floors">
      <div className="location-modal-floors__wrapper">
        <div className="location-modal-floors__button-wrapper location-modal-floors__button-wrapper__up">
          <button
            className="btn small"
            onClick={() => addNewFloor("above")}
            title={tCommon("addFloor")}
          >
            <Icon name="add" />
            {floorsAbove.length < 2 && (
              <Text text={tCommon("addFloor")} fontWeight={600} />
            )}
          </button>
        </div>
        {floorsAbove.map((floor) => (
          <LocationFloor
            key={floor.uuid}
            floor={floor}
            handleFloorRename={handleFloorRename}
            handleAddNewRoom={addNewRoom}
            handleRoomRename={handleRoomRename}
            handleDeleteFloor={handleDeleteFloor}
            handleDeleteRoom={handleDeleteRoom}
          />
        ))}

        <div className="location-modal-floors__center-text">
          <span className="location-modal-floors__text location-modal-floors__text__above">
            {tLocationModal("aboveGround")}
          </span>
          <hr className="location-modal-floors__line" />
          <span className="location-modal-floors__text location-modal-floors__text__under">
            {tLocationModal("underground")}
          </span>
        </div>

        {floorsUnder.map((floor) => (
          <LocationFloor
            key={floor.uuid}
            floor={floor}
            handleFloorRename={handleFloorRename}
            handleAddNewRoom={addNewRoom}
            handleRoomRename={handleRoomRename}
            handleDeleteFloor={handleDeleteFloor}
            handleDeleteRoom={handleDeleteRoom}
          />
        ))}
        <div className="location-modal-floors__button-wrapper location-modal-floors__button-wrapper__down">
          <button
            className="btn small"
            onClick={() => addNewFloor("under")}
            title={tCommon("addSubFloor")}
          >
            <Icon name="add" />
            {floorsUnder.length < 2 && (
              <Text text={tCommon("addSubFloor")} fontWeight={600} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
