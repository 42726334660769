import AuiIcon, {
  IconEnum as AuiIconEnum,
  IconProps,
} from "@avinet/adaptive-ui-core/ui/Icon";

import { icons } from "../../assets/icons";

export type IconEnum = keyof typeof icons | AuiIconEnum;

export function Icon(props: IconProps & { name: IconEnum }) {
  return <AuiIcon {...props} />;
}
