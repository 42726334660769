import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    position: "absolute",
    minHeight: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 30,
    marginLeft: 30,
  },
  pageNumers: {
    fontSize: 12,
  },
  img: {
    height: 24,
    width: "100%",
    maxWidth: 100,
    objectFit: "contain",
  },
});

interface FooterProps {
  logoPath?: string;
}

export const ReportFooter = ({ logoPath }: FooterProps) => {
  return (
    <View fixed style={styles.footer}>
      <Text
        style={styles.pageNumers}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
      {logoPath && (
        <View>
          <Image style={styles.img} src={logoPath} />
        </View>
      )}
    </View>
  );
};
