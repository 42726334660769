import { useMemo } from "react";

import { ASSESSMENT_RATINGS, OBJECT_STATUS } from "../contants/Enums";
import { IComponentFilter } from "../context/filter-context/FilterContext";

export function useComponentFilter(
  componentFilter: Partial<IComponentFilter> | undefined
) {
  const filterString = useMemo(() => {
    // condition
    const goodCondition = componentFilter?.good_condition;
    const goodConditionFilter =
      goodCondition && `obj_condition='${OBJECT_STATUS.GOOD}'`;
    const okCondition = componentFilter?.ok_condition;
    const okConditionFilter =
      okCondition && `obj_condition='${OBJECT_STATUS.MEDIUM}'`;
    const badCondition = componentFilter?.bad_condition;
    const badConditionFilter =
      badCondition && `obj_condition='${OBJECT_STATUS.BAD}'`;
    const noCondition = componentFilter?.no_condition;
    const noConditionFilter =
      noCondition && `obj_condition='${OBJECT_STATUS.NO_CONDITION}'`;
    let conditionFilter =
      "(" +
      [
        goodConditionFilter,
        okConditionFilter,
        badConditionFilter,
        noConditionFilter,
      ]
        .filter(Boolean)
        .join(" OR ") +
      ")";
    if (conditionFilter === "()") {
      conditionFilter = "";
    }
    //assessed
    const assessed = componentFilter?.assessed;
    const assessedFilter =
      assessed &&
      `(remaining_lifetime_score + demountability_score + volume_score + demand_score + environmental_effect_score + cost_use_score) > 0`;

    //score
    const scoreFrom = componentFilter?.score_from;
    const scoreFromFilter =
      scoreFrom &&
      `(remaining_lifetime_score + demountability_score + volume_score + demand_score + environmental_effect_score + cost_use_score) >= ${scoreFrom}`;
    const scoreTo = componentFilter?.score_to;
    const scoreToFilter =
      scoreTo &&
      `(remaining_lifetime_score + demountability_score + volume_score + demand_score + environmental_effect_score + cost_use_score) <= ${scoreTo}`;

    //evaluation (only in project)
    // this should only be a viable filter in component table in project
    const evaluations = componentFilter?.evaluation;
    const evaluationsFilter =
      evaluations &&
      `recommendation='${evaluations
        ?.toString()
        .split(",")
        .join(" OR recommendation=")}'`;

    //amount
    const amountFrom = componentFilter?.amount_from;
    const amountFromFilter = amountFrom && `quantity >= ${amountFrom}`;
    const amountTo = componentFilter?.amount_to;
    const amountToFilter = amountTo && `quantity <= ${amountTo}`;

    //quantity_unit
    const amountUnit = componentFilter?.amount_unit;
    let amountUnitFilter = amountUnit && `quantity_unit='${amountUnit}'`;
    if (amountUnit === "stk") {
      amountUnitFilter = `(quantity_unit='stk' OR quantity_unit IS NULL)`;
    }

    //parent_category
    const parentCategory = componentFilter?.parent_category;
    const parentCategoryFilter =
      parentCategory && `parent_category='${parentCategory}'`;

    //category
    const category = componentFilter?.category;
    const categoryFilter = category && `category='${category}'`;

    //element
    const element = componentFilter?.element;
    const elementFilter = element && `element='${element}'`;

    //height
    const heightFrom = componentFilter?.height_from;
    const heightFromFilter = heightFrom && `height >= ${heightFrom}`;
    const heightTo = componentFilter?.height_to;
    const heightToFilter = heightTo && `height <= ${heightTo}`;

    //width
    const widthFrom = componentFilter?.width_from;
    const widthFromFilter = widthFrom && `width >= ${widthFrom}`;
    const widthTo = componentFilter?.width_to;
    const widthToFilter = widthTo && `width <= ${widthTo}`;

    //length
    const lengthFrom = componentFilter?.length_from;
    const lengthFromFilter = lengthFrom && `length >= ${lengthFrom}`;
    const lengthTo = componentFilter?.length_to;
    const lengthToFilter = lengthTo && `length <= ${lengthTo}`;

    //diameter
    const diameterFrom = componentFilter?.diameter_from;
    const diameterFromFilter = diameterFrom && `diameter >= ${diameterFrom}`;
    const diameterTo = componentFilter?.diameter_to;
    const diameterToFilter = diameterTo && `diameter <= ${diameterTo}`;

    //weight
    const weightFrom = componentFilter?.weight_from;
    const weightFromFilter = weightFrom && `weight >= ${weightFrom}`;
    const weightTo = componentFilter?.weight_to;
    const weightToFilter = weightTo && `weight <= ${weightTo}`;

    //date_produced
    const dateProduced = componentFilter?.date_produced;
    const dateProducedFilter =
      dateProduced && `production_year='${dateProduced}'`;

    //date_registered
    const dateRegisteredFrom = componentFilter?.date_registered_from;
    const dateRegisteredFromFilter =
      dateRegisteredFrom && `date_created >= DATE('${dateRegisteredFrom}')`;
    const dateRegisteredTo = componentFilter?.date_registered_to;
    const dateRegisteredToFilter =
      dateRegisteredTo && `date_created <= DATE('${dateRegisteredTo}')`;

    const activeTab = componentFilter?.activeTab;
    let activeTabFilter = "";
    if (activeTab === "Som ny") {
      activeTabFilter = `obj_condition='${OBJECT_STATUS.GOOD}'`;
    } else if (activeTab === "Brukt") {
      activeTabFilter = `obj_condition='${OBJECT_STATUS.MEDIUM}'`;
    } else if (activeTab === "Godt brukt") {
      activeTabFilter = `obj_condition='${OBJECT_STATUS.BAD}'`;
    } else if (activeTab === "Ingen tilstand") {
      activeTabFilter = `obj_condition='${OBJECT_STATUS.NO_CONDITION}'`;
    } else if (activeTab === "Ikke vurdert") {
      activeTabFilter = `(recommendation = null OR recommendation = '')`;
    } else if (activeTab === "Egnet i prosjekt") {
      activeTabFilter = `recommendation ='${ASSESSMENT_RATINGS.SUITABLE}'`;
    } else if (activeTab === "Intern formidling") {
      activeTabFilter = `recommendation ='${ASSESSMENT_RATINGS.INTERNAL_USE}'`;
    } else if (activeTab === "Ekstern formidling") {
      activeTabFilter = `recommendation ='${ASSESSMENT_RATINGS.EXTERNAL_USE}'`;
    } else if (activeTab === "Avfall") {
      activeTabFilter = `recommendation ='${ASSESSMENT_RATINGS.WASTE}'`;
    } else if (activeTab === "Arkivert") {
      activeTabFilter = `recommendation ='${ASSESSMENT_RATINGS.ARCHIVED}'`;
    }

    // TODO: handle recommendation as activeTabFilter (product in project)

    return [
      conditionFilter,
      assessedFilter,
      scoreFromFilter,
      scoreToFilter,
      evaluationsFilter,
      amountFromFilter,
      amountToFilter,
      amountUnitFilter,
      parentCategoryFilter,
      categoryFilter,
      elementFilter,
      heightFromFilter,
      heightToFilter,
      widthFromFilter,
      widthToFilter,
      lengthFromFilter,
      lengthToFilter,
      diameterFromFilter,
      diameterToFilter,
      weightFromFilter,
      weightToFilter,
      dateProducedFilter,
      dateRegisteredFromFilter,
      dateRegisteredToFilter,
      activeTabFilter,
    ]
      .filter(Boolean)
      .join(" AND ");
  }, [componentFilter]);

  return filterString;
}
