import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IOrganisation } from "../../../api/types";
import LogoutIcon from "../../../assets/icons/settings/logout/LogoutIcon";
import { profileUrl } from "../../../contants/Routes";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { convertToInitials } from "../../../utils/index.utils";
import { Heading } from "../../heading";
import { HelpModal } from "../../help-modal";
import { Text } from "../../text";
import { UpdateOrgModal } from "../../update-org-modal/UpdateOrgModal";
import { OrganisationBox } from "../organisation-box";
import { OrganisationBoxSkeleton } from "../organisation-box/organisation-box-skeleton";
import { OrganisationsListProps } from "./index.props";
import "./styles.scss";

export const OrganisationsList = ({
  className,
  setShowModal,
  showHelpModal,
  handleHelpModal,
  chooseOrganisation,
}: OrganisationsListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userProfile, logout } = useContext(AppUserContext);
  const { organisationsList, selectedOrgLoading } =
    useContext(OrganisationContext);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);

  const handleAddOrgModal = () => {
    setShowAddOrgModal((prev) => !prev);
    setShowModal && setShowModal();
  };

  organisationsList.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const createOrganisationBox = {
    id: 0,
    name: t("pages.selectOrganisation.createOrganisation"),
  };

  const handleNavigateToUserProfile = useCallback(() => {
    navigate(profileUrl, {
      state: {
        withPrevButton: true,
      },
    });
  }, [navigate]);

  const renderSkeletonLoading = useCallback(
    () => (
      <>
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
        <OrganisationBoxSkeleton />
      </>
    ),
    []
  );

  return (
    <div className={`organisation ${className ? className : ""}`}>
      <div className="organisation__title-wrapper">
        <Heading
          title={t("pages.selectOrganisation.title")}
          description={t("pages.selectOrganisation.description")}
        />

        <div className="organisation__title-wrapper__info">
          {/* TODO: Hidded "help" section for now */}
          {/*{handleHelpModal ? (*/}
          {/*  <div*/}
          {/*    className="organisation__help"*/}
          {/*    onClick={() => handleHelpModal(true)}*/}
          {/*  >*/}
          {/*    <Icon name="helpIcon" />*/}
          {/*    <p className="organisation__help__title">*/}
          {/*      {t("pages.selectOrganisation.helpModal.title")}*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <button className="btn organisation__back" onClick={setShowModal}>*/}
          {/*    <Icon name="arrow" />*/}
          {/*    <p className="organisation__help__title">{t("common.back")}</p>*/}
          {/*  </button>*/}
          {/*)}*/}
          <div onClick={handleNavigateToUserProfile}>
            {userProfile?.image?.url ? (
              <img
                src={userProfile?.image?.url}
                alt={`${userProfile?.userData?.first_name}-${userProfile?.userData?.last_name}}`}
                className="sidebar__user__img"
              />
            ) : (
              <div className="sidebar__user__img--default">
                <p>
                  {convertToInitials(
                    userProfile?.userData?.first_name,
                    userProfile?.userData?.last_name
                  )}
                </p>
              </div>
            )}
          </div>
          <div className="organisation__logout" onClick={() => logout()}>
            <LogoutIcon />
            <Text
              text={t("common.logout")}
              size="md"
              fontWeight={600}
              className="organisation__logout__text"
            />
          </div>
        </div>
      </div>
      <div className="organisation-box-wrapper">
        <OrganisationBox
          createBox
          onClick={handleAddOrgModal}
          organisation={createOrganisationBox}
        />
        {selectedOrgLoading ? (
          renderSkeletonLoading()
        ) : (
          <>
            {organisationsList?.length
              ? organisationsList.map((organisation: IOrganisation) => (
                  <OrganisationBox
                    key={organisation?.id}
                    onClick={(edit?: boolean) =>
                      chooseOrganisation(organisation, edit)
                    }
                    organisation={organisation}
                  />
                ))
              : null}
          </>
        )}
      </div>
      {showHelpModal !== undefined && handleHelpModal !== undefined && (
        <HelpModal showHelpModal={showHelpModal} modalState={handleHelpModal} />
      )}
      {showAddOrgModal && (
        <UpdateOrgModal
          modalState={handleAddOrgModal}
          onSubmission={handleAddOrgModal}
          isOrgCreation
        />
      )}
    </div>
  );
};
