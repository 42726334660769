import React, { FC } from "react";

const MapFilledIcon: FC = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49978 0.357977V12.823L2.1801 15.8614C1.71239 16.1902 1.07865 15.8947 1.00571 15.3523L0.998779 15.2478V4.61531C0.998779 4.40599 1.08613 4.20791 1.2369 4.06714L1.31746 4.00175L6.49978 0.357977ZM18.9942 0.647796L19.0011 0.752285V11.3848C19.0011 11.5941 18.9138 11.7922 18.763 11.933L18.6824 11.9984L13.4998 15.64V3.17398L17.8198 0.138721C18.2875 -0.190072 18.9213 0.105455 18.9942 0.647796ZM7.99978 0.358977L11.9998 3.17098V15.638L7.99978 12.824V0.358977Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default MapFilledIcon;
