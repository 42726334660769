import React, { FC } from "react";

const EnterCodeBgIcon1: FC = () => {
  return (
    <svg
      width="581"
      height="720"
      viewBox="0 0 581 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="505"
        y="-19"
        width="108"
        height="1103.77"
        rx="54"
        transform="rotate(28.3088 505 -19)"
        fill="#6239D9"
      />
    </svg>
  );
};

export default EnterCodeBgIcon1;
