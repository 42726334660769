import {
  ArrowUndo24Regular,
  Attach24Regular,
  BoxMultiple24Regular,
  CheckboxChecked24Regular,
  CheckboxUnchecked24Regular,
  DocumentPdf24Regular,
  Eye24Regular,
  EyeOff24Regular,
  Filter24Regular,
  PlugConnected24Regular,
  Timer24Regular,
  WalletCreditCard24Regular,
} from "@fluentui/react-icons";

import { More } from "./icons/More";
import { ArchiveIcon } from "./icons/archive/ArchiveIcon";
import { ArrowExportIcon } from "./icons/arrow-export/ArrowExportIcon";
import { ArrowResetIcon } from "./icons/arrow-reset/ArrowResetIcon";
import BackArrowIcon from "./icons/back-arrow/BackArrowIcon";
import BellFilledIcon from "./icons/bell/BellFilledIcon";
import BellIcon from "./icons/bell/BellIcon";
import CheckmarkIcon from "./icons/checkmark/CheckmarkIcon";
import ChoiceIcon from "./icons/choice/ChoiceIcon";
import CloseIcon from "./icons/close/CloseIcon";
import { ColumnsIcon } from "./icons/columns/ColumnsIcon";
import CompanyIcon from "./icons/company/CompanyIcon";
import CurvedArrow from "./icons/curved-arrow/CurvedArrow";
import { DismissCircleIcon } from "./icons/dismiss-circle/DismissCircleIcon";
import DotsIcon from "./icons/dots/DotsIcon";
import EditFilledIcon from "./icons/edit-filled/EditFilledIcon";
import EnterCodeBgIcon1 from "./icons/enter-code/EnterCodeIcon1";
import EnterCodeBgIcon2 from "./icons/enter-code/EnterCodeIcon2";
import EnvelopeIcon from "./icons/envelope/Envelope";
import { ExportIcon } from "./icons/export/ExportIcon";
import EnglandFlag from "./icons/flags/en/EnglandFlag";
import NorwayFlag from "./icons/flags/no/NorwayFlag";
import FolderIcon from "./icons/folder/FolderIcon";
import HamburgerIcon from "./icons/hamburger/HamburgerIcon";
import HelpOutlineFilledIcon from "./icons/help-outline/HelpOutlineFilledIcon";
import HelpOutlineIcon from "./icons/help-outline/HelpOutlineIcon";
import HelpIcon from "./icons/help/HelpIcon";
import HomeFilledIcon from "./icons/home/HomeFilledIcon";
import HomeIcon from "./icons/home/HomeIcon";
import LanguageIcon from "./icons/language/LanguageIcon";
import LocationFilledIcon from "./icons/location/LocationFilledIcon";
import LocationIcon from "./icons/location/LocationIcon";
import DelioLogoIcon from "./icons/logo-contours/LogoContours";
import LogoNameIcon from "./icons/logo-name/LogoNameIcon";
import LogoIcon from "./icons/logo/LogoIcon";
import MapFilledIcon from "./icons/map/MapFilledIcon";
import MapIcon from "./icons/map/MapIcon";
import { NoImageIcon } from "./icons/no-image-icon/NoImageIcon";
import ObjectsFilledIcon from "./icons/objects/ObjectsFilledIcon";
import ObjectsIcon from "./icons/objects/ObjectsIcon";
import PasswordEye from "./icons/password-eye/PasswordEye";
import PhoneIcon from "./icons/phone/PhoneIcon";
import PlanCrownIcon from "./icons/plan-crown/PlanCrownIcon";
import PlusOutlineIcon from "./icons/plus-outline/PlusOutlineIcon";
import PlusIcon from "./icons/plus/PlusIcon";
import { PreviewLinkIcon } from "./icons/preview-link/PreviewLinkIcon";
import ProjectsFilledIcon from "./icons/projects/ProjectsFilledIcon";
import ProjectsIcon from "./icons/projects/ProjectsIcon";
import RocketCloudIcon from "./icons/rocket/RocketCloudIcon";
import RocketIcon from "./icons/rocket/RocketIcon";
import SavingsIcon from "./icons/savings/SavingsIcon";
import SecurityShieldIcon from "./icons/security-shield/SecurityShieldIcon";
import ShareIcon from "./icons/share/ShareIcon";
import StarsIcon from "./icons/stars/StarsIcon";
import StatisticsIcon from "./icons/statistics/StatisticsIcon";
import TextIcon from "./icons/text/TextIcon";
import UploadIcon from "./icons/upload/UploadIcon";

export const icons = {
  logoIcon: <LogoIcon />,
  delioLogoIcon: <DelioLogoIcon />,
  stars: <StarsIcon />,
  curvedArrow: <CurvedArrow />,
  columns: <ColumnsIcon />,
  envelope: <EnvelopeIcon />,
  enterCodeBg1: <EnterCodeBgIcon1 />,
  enterCodeBg2: <EnterCodeBgIcon2 />,
  passwordEye: <PasswordEye />,
  helpIcon: <HelpIcon />,
  helpOutlineIcon: <HelpOutlineIcon />,
  helpOutlineFilledIcon: <HelpOutlineFilledIcon />,
  rocketIcon: <RocketIcon />,
  rocketCloudIcon: <RocketCloudIcon />,
  plusIcon: <PlusIcon />,
  plusOutlineIcon: <PlusOutlineIcon />,
  logoName: <LogoNameIcon />,
  backArrow: <BackArrowIcon />,
  dotsIcon: <DotsIcon />,
  bellIcon: <BellIcon />,
  bellFilledIcon: <BellFilledIcon />,
  homeIcon: <HomeIcon />,
  homeFilledIcon: <HomeFilledIcon />,
  locationIcon: <LocationIcon />,
  locationFilledIcon: <LocationFilledIcon />,
  mapIcon: <MapIcon />,
  mapFilledIcon: <MapFilledIcon />,
  objectsIcon: <ObjectsIcon />,
  objectsFilledIcon: <ObjectsFilledIcon />,
  projectsIcon: <ProjectsIcon />,
  projectsFilledIcon: <ProjectsFilledIcon />,
  closeIcon: <CloseIcon />,
  hamburger: <HamburgerIcon />,
  editFilled: <EditFilledIcon />,
  planCrown: <PlanCrownIcon />,
  textIcon: <TextIcon />,
  shieldIcon: <SecurityShieldIcon />,
  phone: <PhoneIcon />,
  company: <CompanyIcon />,
  language: <LanguageIcon />,
  enFlag: <EnglandFlag />,
  noFlag: <NorwayFlag />,
  share: <ShareIcon />,
  upload: <UploadIcon />,
  checkmark: <CheckmarkIcon />,
  checkboxChecked: <CheckboxChecked24Regular />,
  checkboxUnchecked: <CheckboxUnchecked24Regular />,
  reportIcon: <DocumentPdf24Regular />,
  folder: <FolderIcon />,
  noImage: <NoImageIcon />,
  attachment: <Attach24Regular />,
  arrowExport: <ArrowExportIcon />,
  export: <ExportIcon />,
  preview: <PreviewLinkIcon />,
  archive: <ArchiveIcon />,
  dismissCircle: <DismissCircleIcon />,
  walletCreditCard: <WalletCreditCard24Regular />,
  more: <More />,
  savings: <SavingsIcon />,
  statistics: <StatisticsIcon />,
  choice: <ChoiceIcon />,
  arrowReset: <ArrowResetIcon />,
  eye: <Eye24Regular />,
  eyeOff: <EyeOff24Regular />,
  timer: <Timer24Regular />,
  plugConnected: <PlugConnected24Regular />,
  boxMultiple: <BoxMultiple24Regular />,
  arrowUndo: <ArrowUndo24Regular />,
  filter: <Filter24Regular />,
};
