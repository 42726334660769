import { t } from "i18next";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  IFeatureDeleteType,
  IFullscreenModalData,
  ILocationProperties,
  IObjectProperties,
  IObjectResponse,
  IProductWithMappings,
  IProjectProperties,
} from "../../api/types";
import { ObjectModal } from "../../components/add-new-feature/object/ObjectModal";
import { ProjectModal } from "../../components/add-new-feature/project/ProjectModal";
import { Assessment } from "../../components/assessment/Assessment";
import { AttachmentModal } from "../../components/attachment-modal/AttachmentModal";
import { FeatureDeleteModal } from "../../components/feature-delete-modal/FeatureDeleteModal";
import { ObjectsPreview } from "../../components/feature-preview/object";
import { ObjectPlacementPreview } from "../../components/feature-preview/object/elements/ObjectPlacementPreview";
import { ProjectFilterModal } from "../../components/filter-modal/ProjectFilterModal";
import { FullscreenImgModal } from "../../components/fullscreen-img-modal/FullscreenImgModal";
import { Icon } from "../../components/icon/Icon";
import { PageHeader } from "../../components/page/page-header";
import { PageSidebar } from "../../components/page/page-sidebar";
import { PageTable } from "../../components/page/page-table/PageTable";
import { HorizontalTabs } from "../../components/page/page-table/horizontal-tabs";
import { PageTitle } from "../../components/page/page-title";
import { PageWrapper } from "../../components/page/page-wrapper";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../../contants/FeatureUuid";
import {
  dashboardUrl,
  objectsTabAttachments,
  objectsTabObjectList,
  objectsTabPlacement,
  objectsTabProjects,
  objectsUrl,
} from "../../contants/Routes";
import { DictionaryContext } from "../../context/dictionary-context/DictionaryContext";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import {
  FilterContext,
  IProjectFilter,
} from "../../context/filter-context/FilterContext";
import { useAttachmentModal } from "../../hooks/useAttachmentModal";
import { useAttachments } from "../../hooks/useAttachments";
import { useProjectFilter } from "../../hooks/useProjectFilter";
import { useProjectTableColumns } from "../../hooks/useProjectTableColumns";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { useTableSelect } from "../../hooks/useTableSelect";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import "./styles.scss";

export function ObjectsPreviewPage() {
  const tTabs = useTranslationPath("components.objectsPreview.tabs");
  const tabs = useMemo(
    () => [
      { text: tTabs("summary"), to: "" },
      { text: tTabs("assessment"), to: objectsTabAttachments },
      { text: tTabs("placement"), to: objectsTabPlacement },
      { text: tTabs("projects"), to: objectsTabProjects },
      //   TODO: history in v2
      // { text: tTabs("history"), to: "history" },
    ],
    [tTabs]
  );
  const { id: featureId } = useParams();
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    singleProduct,
    downloadGenericFeature,
    objectProjects,
    genericProduct,
    singleLocation,
    getProductAssessments,
    productAssessments,
  } = useContext(FeatureContext);
  const { projectComponentFilter, handleProjectComponentFilter } =
    useContext(FilterContext);
  const { projectStatus } = useSelectOptions();
  const [objectDetails, setObjectDetails] = useState<
    IObjectResponse | undefined
  >(undefined);
  const { projectTableColumns, projectTableTabs, renderProjectTableTitle } =
    useProjectTableColumns(objectProjects);
  const [activeTab, setActiveTab] = useState("");
  const { getCategoryName } = useContext(DictionaryContext);
  const [showFullscreenModal, setShowFullscreenModal] =
    useState<IFullscreenModalData>({
      showModal: false,
      sliderData: [],
    });
  const [multipleItems, setMultipleItems] = useState<number[]>([]);
  const [resetBatchSelect, setResetBatchSelect] = useState(false);
  const navigate = useNavigate();
  const [deleteFeatureType, setDeleteFeatureType] =
    useState<IFeatureDeleteType | null>(null);
  const categoryName = useMemo(() => {
    const category = objectDetails?.product?.category;

    let name;

    if (category !== undefined) {
      name = getCategoryName(category);
    }

    return name ?? "-";
  }, [getCategoryName, objectDetails?.product?.category]);

  const routes = useMemo(
    () =>
      objectDetails?.product?.specification
        ? [
            { url: dashboardUrl, title: t("common.home") },
            { url: objectsUrl, title: t("pages.objects.title") },
            { url: "", title: objectDetails?.product?.specification },
          ]
        : [
            { url: dashboardUrl, title: t("common.home") },
            { url: objectsUrl, title: t("pages.objects.title") },
          ],
    [objectDetails?.product?.specification]
  );

  const assessmentList = useMemo(() => {
    const convertedObjets = productAssessments?.filter(
      (value, index, self) =>
        index === self?.findIndex((product) => product.id === value.id)
    );

    return convertedObjets?.map(
      (obj) =>
        ({
          ...obj,
          productLocationMappings: productAssessments?.filter(
            (mapping) => mapping.id === obj.id
          ),
        } as unknown as IProductWithMappings)
    );
  }, [productAssessments]);

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    number | string | undefined
  >(undefined);

  const {
    handleAttachmentModal,
    handleRecordEdit,
    handleRecordPreview,
    handleRecordSelect,
    handleProjectModal,
    selectedTableItem,
    isSidebarVisible,
    setIsSidebarVisible,
    setShowProjectModal,
    setSelectedTableItem,
    showProjectModal,
  } = useTableSelect(featureId, FEATURE_UUID.PRODUCT_PROJECTS);

  const sidebarType = useCallback(() => {
    switch (location.pathname?.split("/").pop()) {
      case objectsTabProjects:
        return FEATURE_TYPE.PROJECT;
      case objectsTabObjectList:
        return FEATURE_TYPE.OBJECT;
      case objectsTabAttachments:
        return FEATURE_TYPE.ATTACHMENT;
      default:
        return FEATURE_TYPE.PROJECT;
    }
  }, [location.pathname]);

  const [showObjectModal, setShowObjectModal] = useState(false);
  const [productLocation, setProductLocation] = useState<
    ILocationProperties | undefined
  >(undefined);
  const openObjectModal = useCallback(() => {
    setShowObjectModal(true);
  }, []);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const filterString = useProjectFilter(projectComponentFilter);

  const filterActive = !!filterString;

  const { fetchedAttachments, getAttachments } = useAttachments();

  const loadObjectDetails = useCallback(async () => {
    if (featureId === undefined) return;

    singleProduct(Number(featureId), navigate).then((res) => {
      setObjectDetails(res);
      singleLocation(
        parseInt(String(res?.productLocationMappings?.[0]?.fk_location)),
        navigate
      ).then((location) => setProductLocation(location));
    });
    genericProduct(parseInt(featureId));
    await getProductAssessments(featureId);
    getAttachments(FEATURE_MEDIA_TYPE.PRODUCT, Number(featureId));
    downloadGenericFeature(
      FEATURE_UUID.PRODUCT_PROJECTS,
      `fk_product=${featureId}` + (filterString && " AND " + filterString),
      null,
      projectComponentFilter?.limit,
      projectComponentFilter?.offset,
      projectComponentFilter?.sort_order,
      projectComponentFilter?.sort_column
    );
  }, [
    featureId,
    singleProduct,
    navigate,
    genericProduct,
    getProductAssessments,
    getAttachments,
    downloadGenericFeature,
    projectComponentFilter?.limit,
    projectComponentFilter?.offset,
    projectComponentFilter?.sort_order,
    projectComponentFilter?.sort_column,
    downloadGenericFeature,
    filterString,
    singleLocation,
  ]);

  const {
    showModal: showAttachmentModal,
    openModal: openAttachmentModal,
    handleClose: closeAttachmentModal,
    handleSave: saveAndCloseAttachmentModal,
    attachmentsState,
    handleDropImage,
    handleRemoveDropImage,
    reloadAttachments,
  } = useAttachmentModal(
    featureId,
    FEATURE_MEDIA_TYPE.PRODUCT,
    loadObjectDetails
  );

  const handleFilterModal = useCallback(() => {
    setShowFilterModal((prev) => !prev);
  }, []);

  const handleApplyFilter = useCallback(
    (filter: IProjectFilter) => {
      handleProjectComponentFilter(filter);
      handleFilterModal;
    },
    [handleProjectComponentFilter, handleFilterModal]
  );

  const closeObjectModal = useCallback(async () => {
    setShowObjectModal(false);
    loadObjectDetails();
    reloadAttachments();
  }, [loadObjectDetails, reloadAttachments]);

  useLayoutEffect(() => {
    loadObjectDetails();
  }, [loadObjectDetails]);

  useLayoutEffect(() => {
    featureId &&
      activeTab === tabs[1].text &&
      genericProduct(parseInt(featureId));
  }, [activeTab, tabs, genericProduct, featureId]);

  useLayoutEffect(() => {
    featureId &&
      activeTab === tabs[1].text &&
      genericProduct(parseInt(featureId));
  }, [activeTab, tabs, genericProduct, featureId]);

  useLayoutEffect(() => {
    location?.state?.featureId &&
      setSelectedAssessmentId(location?.state?.featureId);
  }, [location?.state?.featureId]);

  useLayoutEffect(() => {
    console.log(filterString);
    downloadGenericFeature(
      FEATURE_UUID.PRODUCT_PROJECTS,
      `fk_product=${featureId}` + (filterString && " AND " + filterString),
      null,
      projectComponentFilter?.limit,
      projectComponentFilter?.offset,
      projectComponentFilter?.sort_order,
      projectComponentFilter?.sort_column
    );
  }, [downloadGenericFeature, featureId, filterString, projectComponentFilter]);

  const handleDeleteModal = useCallback(
    (
      featureType: FEATURE_TYPE,
      featureDetails?: Partial<
        ILocationProperties & IObjectProperties & IProjectProperties
      > | null,
      id?: number | string | number[]
    ) => {
      if (Array.isArray(id)) {
        const selectedItems = id?.filter((item) => !isNaN(Number(item)));
        setMultipleItems(selectedItems);
      }

      if (featureType === FEATURE_TYPE.PROJECT) {
        const selectedItem = objectProjects?.find(
          (project) => project.id === id
        );

        setSelectedTableItem(selectedItem ?? null);
        setDeleteFeatureType({
          featureDetails,
          featureType,
          previewFeatureType: FEATURE_TYPE.OBJECT_PROJECT,
        });
      }

      if (featureType === FEATURE_TYPE.OBJECT) {
        setSelectedTableItem(featureDetails ?? null);

        setDeleteFeatureType({
          featureDetails,
          featureType,
          previewFeatureType: featureType,
        });
      }

      setShowDeleteModal((prev) => !prev);
    },
    [objectProjects, setSelectedTableItem]
  );

  const updateTableData = useCallback(
    async (tab: string) => {
      setResetBatchSelect(true);

      if (tab === tabs?.[3].text) {
        downloadGenericFeature(
          FEATURE_UUID.PRODUCT_PROJECTS,
          `fk_product=${featureId}` + (filterString && " AND " + filterString)
        );
        return;
      }
    },
    [tabs, downloadGenericFeature, featureId, filterString]
  );

  return (
    <SidebarPage>
      <PageWrapper
        className={
          activeTab === tabs[3]?.text ? "pagewrapper-wrapper--table" : ""
        }
      >
        <PageHeader routes={routes} />
        <PageTitle
          title={
            objectDetails?.product?.specification ??
            t("components.featureModalCreated.objectTitle")
          }
          description={categoryName}
        >
          <button
            className="btn light-delete"
            onClick={() =>
              handleDeleteModal(
                FEATURE_TYPE.OBJECT,
                objectDetails?.product,
                objectDetails?.product?.id
              )
            }
          >
            <Icon name="delete" />
            {t("common.delete")}
          </button>
          {/*TODO: Hided for tests */}
          {/*<button className="locations-title-button">*/}
          {/*  <BoxMultipleIcon />*/}
          {/*  <span> {t("components.objectsPreview.move")}</span>*/}
          {/*</button>*/}
          {/*<button className="locations-title-button">*/}
          {/*  <PlusIcon />*/}
          {/*  <span>{t("components.objectsPreview.publish")}</span>*/}
          {/*</button>*/}
          <button className="btn light" onClick={openAttachmentModal}>
            <Icon name="attachment" />
            {t("common.attachments")}
          </button>
          <button className="btn" onClick={openObjectModal}>
            <Icon name="edit" />
            {t("common.edit")}
          </button>
        </PageTitle>
        <div className="page-horizontal-tabs-wrapper">
          <HorizontalTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        {showObjectModal && (
          <ObjectModal
            mode="edit"
            objectDetails={objectDetails}
            onClose={closeObjectModal}
          />
        )}

        {showAttachmentModal && (
          <AttachmentModal
            attachments={attachmentsState}
            onDropImage={handleDropImage}
            onClose={closeAttachmentModal}
            onSave={saveAndCloseAttachmentModal}
            handleRemoveDropImage={handleRemoveDropImage}
          />
        )}

        <Routes>
          <Route
            path=""
            element={
              <>
                {fetchedAttachments && (
                  <ObjectsPreview
                    objectDetails={objectDetails}
                    attachmentsList={fetchedAttachments}
                    productLocation={productLocation}
                  />
                )}
              </>
            }
          />

          <Route
            path={objectsTabAttachments}
            element={
              <>
                {assessmentList?.map((object) => (
                  <Assessment
                    objectScore
                    key={object.id}
                    object={object}
                    selectedAssessmentId={selectedAssessmentId}
                    setSelectedAssessmentId={setSelectedAssessmentId}
                    setShowFullscreenModal={setShowFullscreenModal}
                  />
                ))}
              </>
            }
          />

          <Route
            path={objectsTabPlacement}
            element={
              <ObjectPlacementPreview
                objectDetails={objectDetails}
                onEdit={openObjectModal}
                setShowFullscreenModal={setShowFullscreenModal}
              />
            }
          />

          <Route
            path={objectsTabProjects}
            element={
              <>
                <PageTable
                  title={t("components.pageTable.project")}
                  subtitle={t("components.pageTable.projectsCount")}
                  tabs={projectTableTabs}
                  columns={projectTableColumns}
                  featureUUid={FEATURE_UUID.PRODUCT_PROJECTS}
                  featurePreviewFilter={
                    `fk_product=${featureId}` +
                    (filterString && " AND " + filterString)
                  }
                  onRecordSelect={handleRecordSelect}
                  onRecordEdit={handleRecordEdit}
                  onRecordPreview={handleRecordPreview}
                  statusOptions={projectStatus}
                  onRecordDelete={(id) =>
                    handleDeleteModal(
                      FEATURE_TYPE.PROJECT,
                      selectedTableItem,
                      id
                    )
                  }
                  totalItemsCount={projectTableColumns.data.length}
                  noFeatureHandler={() => setShowProjectModal(true)}
                  onFilterClick={handleFilterModal}
                  filterActive={filterActive}
                  tableHeaderText={renderProjectTableTitle}
                  resetBatchSelect={resetBatchSelect}
                  setResetBatchSelect={setResetBatchSelect}
                />
              </>
            }
          />
        </Routes>
      </PageWrapper>
      <FullscreenImgModal
        showModal={showFullscreenModal.showModal}
        sliderItems={showFullscreenModal.sliderData}
        setShowModal={() =>
          setShowFullscreenModal({
            ...showFullscreenModal,
            showModal: false,
          })
        }
      />
      {showProjectModal && (
        <ProjectModal
          projectDetails={selectedTableItem}
          showProjectModal={showProjectModal}
          modalState={handleProjectModal}
          setAttachmentModal={handleAttachmentModal}
        />
      )}
      <PageSidebar
        type={sidebarType()}
        isSidebarVisible={isSidebarVisible}
        onClose={() => setIsSidebarVisible(false)}
        setEditMode={setShowProjectModal}
        featureDetails={selectedTableItem}
      />
      {showFilterModal && (
        <ProjectFilterModal
          onClose={handleFilterModal}
          projectFilter={projectComponentFilter}
          onApplyFilter={handleApplyFilter}
          showFilterModal={showFilterModal}
        />
      )}
      {showDeleteModal && deleteFeatureType?.featureType && (
        <FeatureDeleteModal
          multipleFeatures={multipleItems}
          onClose={() => setShowDeleteModal(false)}
          feature={deleteFeatureType?.featureDetails ?? null}
          featureType={deleteFeatureType?.featureType}
          previewPageType={deleteFeatureType?.previewFeatureType}
          updatePreviewTableData={() => updateTableData(activeTab)}
        />
      )}
    </SidebarPage>
  );
}
