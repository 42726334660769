import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";
import React, { FC, useCallback, useEffect, useState } from "react";

import PlusIcon from "../../assets/icons/plus/PlusIcon";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { Text } from "../text";
import { NoFeaturePlaceholderProps } from "./index.props";
import "./styles.scss";

export const NoFeaturePlaceholder: FC<NoFeaturePlaceholderProps> = ({
  className,
  onClick,
  type,
  parentElementType,
  grid,
  status,
}) => {
  const [text, setText] = useState({
    title: t("components.uploadImage.title"),
    description: t("components.uploadImage.description"),
    button: t("common.uploadImage"),
  });

  const renderIcon = useCallback(() => {
    switch (type) {
      case FEATURE_TYPE.ATTACHMENT:
        return "attachment";
      case FEATURE_TYPE.OBJECT:
      case FEATURE_TYPE.OBJECT_SESSION:
      case FEATURE_TYPE.PROJECT_PRODUCTS:
        return "objectsIcon";
      case FEATURE_TYPE.PROJECT:
      case FEATURE_TYPE.OBJECT_PROJECT:
        return "projectsIcon";
      case FEATURE_TYPE.PROJECT_LOCATION:
      case FEATURE_TYPE.LOCATION:
        return "locationIcon";
      case FEATURE_TYPE.IMAGE:
        return "noImage";
      default:
        return "noImage";
    }
  }, [type]);

  const renderParentElementType = useCallback(() => {
    switch (parentElementType) {
      case FEATURE_TYPE.ATTACHMENT:
        return t("components.noElements.attachment");
      case FEATURE_TYPE.OBJECT:
        return t("components.noElements.object");
      case FEATURE_TYPE.OBJECT_SESSION:
        return t("components.noElements.object");
      case FEATURE_TYPE.PROJECT_LOCATION:
      case FEATURE_TYPE.LOCATION:
        return t("components.noElements.location");
      case FEATURE_TYPE.PROJECT:
      case FEATURE_TYPE.OBJECT_PROJECT:
        return t("components.noElements.project");
      case FEATURE_TYPE.IMAGE:
        return t("components.noElements.image");
      default:
        return "";
    }
  }, [parentElementType]);

  const renderText = useCallback(() => {
    switch (type) {
      case FEATURE_TYPE.ATTACHMENT:
        return setText({
          title: t("components.noElements.titleFeature", {
            feature: t("components.noElements.attachments"),
          }),
          description: t(
            status
              ? "components.noElements.descriptionStatus"
              : "components.noElements.description",
            {
              part: t("components.noElements.attachments"),
              element: renderParentElementType(),
            }
          ),
          button: t("components.locationsPreview.attachment"),
        });
      case FEATURE_TYPE.OBJECT:
        return setText({
          title: t("components.noElements.titleFeature", {
            feature: t("components.noElements.objects"),
          }),
          description: t(
            status
              ? "components.noElements.descriptionStatus"
              : "components.noElements.description",
            {
              part: t("components.noElements.objects"),
              element: renderParentElementType(),
            }
          ),
          button: t("components.locationsPreview.object"),
        });

      case FEATURE_TYPE.OBJECT_SESSION:
        return setText({
          title: t("components.noElements.titleSession"),
          description: t("components.noElements.descriptionSession"),
          button: t("components.locationsPreview.object"),
        });

      case FEATURE_TYPE.PROJECT:
        return setText({
          title: t("components.noElements.titleFeature", {
            feature: t("components.noElements.projects"),
          }),
          description: t(
            status
              ? "components.noElements.descriptionStatus"
              : "components.noElements.description",
            {
              part: t("components.noElements.projects"),
              element: renderParentElementType(),
            }
          ),
          button: t("components.locationsPreview.addProject"),
        });
      case FEATURE_TYPE.OBJECT_PROJECT:
        return setText({
          title: t("components.noElements.objectProjectsTitle"),
          description: t("components.noElements.objectProjectsDescription"),
          button: t("components.locationsPreview.addProject"),
        });
      case FEATURE_TYPE.PROJECT_LOCATION:
        return setText({
          title: t("components.noElements.projectObjectTitle"),
          description: t("components.noElements.projectObjectDescription"),
          button: t("components.locationsPreview.location"),
        });
      case FEATURE_TYPE.LOCATION:
        return setText({
          title: t("components.noElements.titleFeature", {
            feature: t("components.noElements.locations"),
          }),
          description: t(
            status
              ? "components.noElements.descriptionStatus"
              : "components.noElements.description",
            {
              part: t("components.noElements.locations"),
              element: renderParentElementType(),
            }
          ),
          button: t("components.locationsPreview.location"),
        });
      case FEATURE_TYPE.IMAGE:
        return setText({
          title: t("components.uploadImage.title"),
          description: t(
            status
              ? "components.noElements.descriptionStatus"
              : "components.uploadImage.description"
          ),
          button: t("common.uploadImage"),
        });
      case FEATURE_TYPE.PROJECT_PRODUCTS:
        return setText({
          title: t("components.noElements.projectProductsAssessmentTitle"),
          description: t(
            "components.noElements.projectProductsAssessmentDescription"
          ),
          button: t("components.connectLocation.connectLocationButton"),
        });
      default:
        return t("components.uploadImage.title");
    }
  }, [type, renderParentElementType, status]);

  useEffect(() => {
    type && renderText();
  }, [type, renderText]);

  return (
    <div
      className={`upload-image-placeholder ${
        grid ? "upload-image-placeholder--grid" : ""
      } ${className ? className : ""}`}
    >
      <Icon name={renderIcon()} className="upload-image-placeholder__icon" />
      <Text
        text={text.title}
        fontWeight={600}
        className="upload-image-placeholder__title"
      />
      <Text
        text={text.description}
        size="small"
        className="upload-image-placeholder__description"
      />
      <button onClick={onClick} className="btn locations-title-button">
        <PlusIcon />
        <span>{text.button}</span>
      </button>
    </div>
  );
};
