import React, {
  Context,
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import * as API from "../../api/types";
import {
  AddLocationRequest,
  AddLocationResponse,
  AddObjectForm,
  AddProjectForm,
  AddProjectResponse,
  AssessmentRecommendationBody,
  AssessmentScoreBody,
  EditFeatureStatusResponse,
  FloorArrayResponse,
  FloorResponse,
  IAssessmentsMappings,
  IConnectedLocationsBody,
  IConnectedProjectsBody,
  IFloorResponse,
  IFloors,
  ILocationProperties,
  IObjectProperties,
  IObjectResponse,
  IProductWithMappings,
  IProject,
  IProjectProperties,
  ObjectResponse,
} from "../../api/types";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { useAlertMessage } from "../../hooks/useAlertMessage";
import { FeaturesApi } from "../../services/FeaturesApi";
import { FloorApi } from "../../services/FloorApi";
import { ProductApi } from "../../services/ProductApi";
import { ProjectApi } from "../../services/ProjectApi";
import { APIResponseType } from "../../services/api/api";
import { ISelectedOption } from "../../utils/types/types";
import { IContext } from "../ContextInterfaces";
import { DictionaryContext } from "../dictionary-context/DictionaryContext";
import { OrganisationContext } from "../organisation-context/OrganisationContext";
import { AppUserContext } from "../user-context/UserContext";

interface FeatureContextConfig {
  downloadGenericFeature: (
    featureUuid: FEATURE_UUID,
    featurePreviewData?: string,
    status?: string | null,
    rows?: string,
    offset?: number,
    sortOrder?: string,
    sortColumn?: string,
    statusColumn?: string
  ) => Promise<void | boolean | number | ILocationProperties>;
  downloadSelectFeature: (featureUuid: FEATURE_UUID) => void;
  getProjectsAssessments: (
    projectId: string
  ) => Promise<void | IObjectProperties>;
  getProductAssessments: (
    objectId: string
  ) => Promise<void | IObjectProperties>;
  addNewLocation: (
    body: AddLocationRequest
  ) => Promise<AddLocationResponse | void>;
  editLocation: (
    body: AddLocationRequest,
    locationId: number
  ) => Promise<API.EditLocationResponse | undefined>;
  editFeatureStatus: (
    body: string,
    locationId: number,
    featureUuid: FEATURE_UUID
  ) => Promise<EditFeatureStatusResponse>;
  locationsList: ILocationProperties[];
  projectsList: IProject[] | [];
  connectProjectToLocation: (
    locationId: number,
    body: IConnectedLocationsBody[]
  ) => Promise<IConnectedProjectsBody[]>;
  connectLocationToProject: (
    projectId: number,
    locationIds: number[]
  ) => Promise<IConnectedLocationsBody[]>;
  selectFeatures: ISelectedOption[] | [];
  locationFloors: IFloorResponse[] | [];
  singleLocation: (
    locationId: number,
    navigate?: (url: string) => void
  ) => Promise<ILocationProperties | undefined>;
  singleProject: (
    projectId: number,
    navigate?: (url: string) => void
  ) => Promise<IProjectProperties | undefined>;
  getAllProducts: (
    featureStatus?: string,
    rows?: string,
    offset?: number
  ) => Promise<void>;
  getLocationProjects: (locationId: number) => Promise<void>;
  totalLocationsNumber: number;
  totalProductsNumber: number;
  totalProjectsNumber: number;
  totalProjectProductsNumber: number;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  updateFloors: (
    body: IFloors[],
    locationId: number
  ) => Promise<FloorResponse | void>;
  getLocationFloor: (
    locationId: number
  ) => Promise<FloorArrayResponse | undefined>;
  getDeletedItemInfo: (idList: number[], objectType: FEATURE_TYPE) => any;
  deleteFeature: (
    featureType: FEATURE_TYPE,
    idList: number[]
  ) => Promise<any> | undefined;
  addNewProduct: (
    body: AddObjectForm
  ) => Promise<APIResponseType<IObjectResponse>>;
  editProduct: (
    body: AddObjectForm,
    productId: number,
    projectId?: number
  ) => Promise<ObjectResponse | void>;
  getLocationProduct: (
    locationId: number,
    projectLocationAmount?: number,
    projectLocationIndex?: number
  ) => Promise<IObjectResponse | void>;
  locationsProducts: IObjectResponse[] | [];
  locationProjects: IProjectProperties[] | [];
  objectProjects: IProjectProperties[] | [];
  projectLocations: ILocationProperties[] | [];
  projectProducts: IObjectProperties[] | [];
  projectAssessments: IObjectProperties[] | [];
  productAssessments: IObjectProperties[] | [];
  productsList: IProductWithMappings[];
  projectProductsMappings: IAssessmentsMappings[] | [];
  singleProduct: (
    productId?: number,
    navigate?: (url: string) => void
  ) => Promise<IObjectResponse | undefined>;
  genericProduct: (
    productId: number
  ) => Promise<IObjectProperties[] | undefined>;
  genericProductItem: IObjectProperties[] | [];
  addNewProject: (body: AddProjectForm) => Promise<AddProjectResponse | void>;
  editProject: (
    body: AddProjectForm,
    projectId: number
  ) => Promise<AddProjectResponse | void>;
  selectLocationsName: ISelectedOption[] | [];
  selectProjectsName: ISelectedOption[] | [];
  setLocationsProducts: Dispatch<SetStateAction<IObjectResponse[] | []>>;
  editAssessmentScore: (
    id: number,
    body: AssessmentScoreBody,
    projectId?: number
  ) => Promise<void>;
  editAssessmentRecommendation: (
    projectId: number,
    body: AssessmentRecommendationBody
  ) => Promise<void>;
  featureLoader: boolean;
}

const defaultConfig: FeatureContextConfig = {
  editFeatureStatus: () => Promise.reject(),
  downloadGenericFeature: () => Promise.reject(),
  downloadSelectFeature: () => Promise.reject(),
  addNewLocation: () => Promise.reject(),
  editLocation: () => Promise.reject(),
  getLocationProjects: () => Promise.reject(),
  connectProjectToLocation: () => Promise.reject(),
  connectLocationToProject: () => Promise.reject(),
  getProjectsAssessments: () => Promise.reject(),
  getProductAssessments: () => Promise.reject(),
  getDeletedItemInfo: () => Promise.reject(),
  deleteFeature: () => Promise.reject(),
  locationsList: [],
  productsList: [],
  projectsList: [],
  locationFloors: [],
  projectProductsMappings: [],
  singleLocation: () => Promise.reject(),
  singleProject: () => Promise.reject(),
  totalLocationsNumber: 0,
  totalProductsNumber: 0,
  totalProjectsNumber: 0,
  totalProjectProductsNumber: 0,
  offset: 0,
  setOffset: () => Promise.reject(),
  updateFloors: () => Promise.reject(),
  getLocationFloor: () => Promise.reject(),
  addNewProduct: () => Promise.reject(),
  editProduct: () => Promise.reject(),
  getLocationProduct: () => Promise.reject(),
  locationsProducts: [],
  locationProjects: [],
  objectProjects: [],
  projectLocations: [],
  projectProducts: [],
  projectAssessments: [],
  productAssessments: [],
  selectFeatures: [],
  selectLocationsName: [],
  selectProjectsName: [],
  genericProductItem: [],
  singleProduct: () => Promise.reject(),
  genericProduct: () => Promise.reject(),
  getAllProducts: () => Promise.reject(),
  addNewProject: () => Promise.reject(),
  editProject: () => Promise.reject(),
  setLocationsProducts: () => Promise.reject(),
  editAssessmentScore: () => Promise.reject(),
  editAssessmentRecommendation: () => Promise.reject(),
  featureLoader: true,
};

export const FeatureContext: Context<FeatureContextConfig> =
  createContext(defaultConfig);

export const FeatureContextProvider: FC<IContext> = ({
  children,
  environment,
}) => {
  const [featureLoader, setFeatureLoader] = useState<boolean>(true);
  const [initialFeatureLoader, setInitialFeatureLoader] =
    useState<boolean>(false);
  const [locationsList, setLocationsList] = useState<ILocationProperties[]>([]);
  const [productsList, setProductsList] = useState<IProductWithMappings[]>([]);
  const [genericProductItem, setGenericProductItem] = useState<
    IObjectProperties[]
  >([]);
  const [projectsList, setProjectsList] = useState<IProject[] | []>([]);
  const [selectFeatures, setSelectFeatures] = useState<ISelectedOption[] | []>(
    []
  );
  const [selectLocationsName, setSelectLocationsName] = useState<
    ISelectedOption[] | []
  >([]);
  const [selectProjectsName, setSelectProjectsName] = useState<
    ISelectedOption[] | []
  >([]);
  const [locationsProducts, setLocationsProducts] = useState<
    IObjectResponse[] | []
  >([]);
  const [locationFloors, setLocationFloors] = useState<IFloorResponse[] | []>(
    []
  );
  const [totalLocationsNumber, setTotalLocationsNumber] = useState<number>(0);
  const [totalProductsNumber, setTotalProductsNumber] = useState<number>(0);
  const [totalProjectsNumber, setTotalProjectsNumber] = useState<number>(0);
  const [totalProjectProductsNumber, setTotalProjectProductsNumber] =
    useState<number>(0);
  const { selectedOrganisation } = useContext(OrganisationContext);
  const [offset, setOffset] = useState(0);
  const [locationProjects, setLocationProjects] = useState<
    IProjectProperties[] | []
  >([]);
  const [objectProjects, setObjectProjects] = useState<
    IProjectProperties[] | []
  >([]);
  const [projectLocations, setProjectLocations] = useState<
    ILocationProperties[] | []
  >([]);
  const [projectProducts, setProjectProducts] = useState<
    IObjectProperties[] | []
  >([]);
  const [projectProductsMappings, setProjectProductsMappings] = useState<
    IAssessmentsMappings[] | []
  >([]);
  const [projectAssessments, setProjectAssessments] = useState<
    IObjectProperties[] | []
  >([]);
  const [productAssessments, setProductAssessments] = useState<
    IObjectProperties[] | []
  >([]);
  const { getDictionaries } = useContext(DictionaryContext);

  const { setAlertMessage } = useContext(AppUserContext);

  const downloadGenericFeature = useCallback(
    async (
      featureUuid: FEATURE_UUID,
      featurePreviewData?: string,
      status?: string | null,
      rows?: string,
      offset?: number,
      sortOrder?: string,
      sortColumn?: string,
      sortStatus?: string
    ): Promise<boolean> => {
      const featureStatus = status !== undefined ? status : null;

      if (selectedOrganisation?.id) {
        !initialFeatureLoader && setFeatureLoader(true);
        const response = await new FeaturesApi(
          environment.api
        ).apiDownloadGenericFeatures(
          featureUuid,
          selectedOrganisation.id,
          featurePreviewData ?? "",
          featureStatus,
          offset ?? 0,
          rows ?? "25",
          sortOrder ?? "DESC",
          sortColumn ?? "date_created",
          sortStatus
        );

        if (response) {
          setInitialFeatureLoader(true);
          if (featureUuid === FEATURE_UUID.PROJECT) {
            const features = response?.data?.features;

            setProjectsList(features);
            setTotalProjectsNumber(response?.data?.total);
            setFeatureLoader(false);
            return true;
          }

          if (featureUuid === FEATURE_UUID.LOCATION_PROJECTS) {
            const features = response?.data?.features;
            setLocationProjects(features);
            setFeatureLoader(false);
            return true;
          }

          if (featureUuid === FEATURE_UUID.PRODUCT_PROJECTS) {
            const features = response?.data?.features;
            setObjectProjects(features);
            setFeatureLoader(false);
            return true;
          }

          if (featureUuid === FEATURE_UUID.PROJECT_LOCATIONS) {
            const features = response?.data?.features;
            setProjectLocations(features);
            setFeatureLoader(false);
            return features;
          }

          if (featureUuid === FEATURE_UUID.PROJECT_PRODUCTS) {
            const features = response?.data?.features;
            setProjectProducts(features);
            setTotalProjectProductsNumber(response?.data?.total);
            !initialFeatureLoader && setFeatureLoader(false);
            return response?.data?.total;
          }

          if (featureUuid === FEATURE_UUID.PROJECT_PRODUCTS_WITH_MAPPINGS) {
            const features = response?.data?.features;
            setProjectProductsMappings(features);
            !initialFeatureLoader && setFeatureLoader(false);
            return true;
          }

          if (featureUuid === FEATURE_UUID.LOCATION) {
            const features = response?.data?.features;

            setLocationsList(features ?? []);
            setTotalLocationsNumber(response?.data?.total);
            !initialFeatureLoader && setFeatureLoader(false);
            return true;
          }

          if (featureUuid === FEATURE_UUID.PRODUCT) {
            const features = response?.data?.features;
            setProductsList(features ?? []);
            setTotalProductsNumber(response?.data?.total);
            setFeatureLoader(false);
            return true;
          }
        }
      }

      return false;
    },
    [environment.api, selectedOrganisation, initialFeatureLoader]
  );

  const downloadSelectFeature = useCallback(
    async (featureUuid: FEATURE_UUID): Promise<boolean> => {
      if (selectedOrganisation?.id) {
        const response = await new FeaturesApi(
          environment.api
        ).apiDownloadGenericFeatures(
          featureUuid,
          selectedOrganisation.id,
          "",
          "",
          0,
          "",
          undefined,
          undefined,
          undefined
        );

        if (response) {
          if (featureUuid === FEATURE_UUID.LOCATION) {
            const convertedFeatures = response?.data?.features.length
              ? response?.data?.features.map(
                  (location: ILocationProperties) => ({
                    value: location?.id ?? 0,
                    label: location?.id
                      ? `${location?.address} ${location?.gnrbnr}, ${location?.kommune}, ${location?.fylke}`
                      : "",
                  })
                )
              : [];
            setSelectFeatures(convertedFeatures);

            const convertedLocationName = response?.data?.features.length
              ? response?.data?.features.map(
                  (location: ILocationProperties) => ({
                    value: location?.id ?? 0,
                    label: location?.name ?? "",
                  })
                )
              : [];

            setSelectLocationsName(convertedLocationName);
          }

          if (featureUuid === FEATURE_UUID.PROJECT) {
            const convertedProjectName = response?.data?.features.length
              ? response?.data?.features.map((project: IProjectProperties) => ({
                  value: project?.id ?? 0,
                  label: project?.name ?? "",
                }))
              : [];

            setSelectProjectsName(convertedProjectName);
          }
          return true;
        }
      }

      return false;
    },
    [environment.api, selectedOrganisation]
  );

  const getProjectsAssessments = useCallback(
    async (projectId: string) => {
      if (selectedOrganisation?.id) {
        !initialFeatureLoader && setFeatureLoader(true);
        const response = await new FeaturesApi(
          environment.api
        ).apiDownloadGenericFeatures(
          FEATURE_UUID.PROJECT_PRODUCTS_WITH_MAPPINGS,
          selectedOrganisation.id,
          `fk_project=${projectId}`,
          "",
          0,
          "1000",
          undefined,
          undefined,
          undefined
        );

        if (response) {
          const features = response?.data?.features;

          setInitialFeatureLoader(true);
          setProjectAssessments(features);
          !initialFeatureLoader && setFeatureLoader(false);

          return response?.data;
        }
        return response;
      }
    },
    [environment.api, selectedOrganisation, initialFeatureLoader]
  );

  const getProductAssessments = useCallback(
    async (productId: string) => {
      if (selectedOrganisation?.id) {
        try {
          !initialFeatureLoader && setFeatureLoader(true);
          const response = await new FeaturesApi(
            environment.api
          ).apiDownloadGenericFeatures(
            FEATURE_UUID.PROJECT_PRODUCTS_WITH_MAPPINGS,
            selectedOrganisation.id,
            `fk_product=${productId}`,
            "",
            0,
            "1000",
            undefined,
            undefined,
            undefined
          );

          if (response) {
            const features: IObjectProperties[] = response?.data?.features;

            features &&
              features.sort((a, b) =>
                (a.obj_condition ?? "").localeCompare(b?.obj_condition ?? "")
              );

            setInitialFeatureLoader(true);

            const uniqueProductAssessments: IObjectProperties[] = [];

            // add non-duplicate assessments to uniqueProductAssessments
            features.forEach((item: IObjectProperties) => {
              const exist = uniqueProductAssessments.some(
                (tempItem) => tempItem.obj_condition === item.obj_condition
              );

              productAssessments.forEach((tempItem) => {
                if (
                  tempItem.obj_condition === item.obj_condition &&
                  tempItem.mapping_image !== item.mapping_image
                ) {
                  item.mapping_image = tempItem.mapping_image;
                }
              });

              if (!exist) uniqueProductAssessments.push(item);
            });

            setProductAssessments(uniqueProductAssessments);
            !initialFeatureLoader && setFeatureLoader(false);

            return response?.data;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [environment.api, selectedOrganisation, initialFeatureLoader]
  );

  const addNewLocation = useCallback(
    async (body: API.AddLocationRequest) => {
      if (selectedOrganisation) {
        return new FeaturesApi(environment.api)
          .addLocation(body, selectedOrganisation.id)
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              await downloadGenericFeature(FEATURE_UUID.LOCATION);
              setLocationsList((prev) => [...prev, response.data]);
            }
            return response;
          })
          .catch(console.error);
      }
    },
    [environment.api, selectedOrganisation, downloadGenericFeature]
  );

  const updateFloors = useCallback(
    async (body: IFloors[], locationId: number) => {
      if (selectedOrganisation) {
        return new FloorApi(environment.api)
          .uploadLocationFloor(body, locationId)
          .then(async (response) => {
            return response;
          })
          .catch(console.error);
      }
    },
    [environment.api, selectedOrganisation]
  );

  const editLocation = useCallback(
    async (body: API.AddLocationRequest, locationId: number) => {
      if (!selectedOrganisation) return;
      return new FeaturesApi(environment.api)
        .editLocation(body, locationId, selectedOrganisation.id)
        .then((response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setLocationsList((prev) =>
              prev.map((location) =>
                location.id === response.data.id ? response.data : location
              )
            );
          }
          return response;
        });
    },
    [environment.api, selectedOrganisation]
  );

  const featureApiType = (featureUuid: FEATURE_UUID) => {
    switch (featureUuid) {
      case FEATURE_UUID.LOCATION:
      case FEATURE_UUID.PROJECT_LOCATIONS:
        return "location";
      case FEATURE_UUID.PROJECT:
      case FEATURE_UUID.PRODUCT_PROJECTS:
      case FEATURE_UUID.LOCATION_PROJECTS:
        return "project";
      default:
        return "";
    }
  };

  const editFeatureStatus = useCallback(
    (body: string, featureId: number, featureUuid: FEATURE_UUID) => {
      return new FeaturesApi(environment.api)
        .apiEditFeatureStatus(body, featureId, featureApiType(featureUuid))
        .then((response) => {
          if (response.kind !== KIND_RESPONSE.OK) return response;

          if (featureUuid === FEATURE_UUID.LOCATION) {
            const updatedList = locationsList?.map((location) =>
              location.id === featureId
                ? {
                    ...location,
                    status: response.data,
                  }
                : location
            );

            setLocationsList(updatedList);

            return response;
          }

          if (featureUuid === FEATURE_UUID.PROJECT) {
            const updatedList = projectsList?.map((project) =>
              project.id === featureId
                ? {
                    ...project,
                    status: response.data,
                  }
                : project
            );

            setProjectsList(updatedList);

            return response;
          }

          if (featureUuid === FEATURE_UUID.PRODUCT_PROJECTS) {
            const updatedList = objectProjects?.map((project) =>
              project.id === featureId
                ? {
                    ...project,
                    status: response.data,
                  }
                : project
            );

            setObjectProjects(updatedList);

            return response;
          }

          return response;
        });
    },
    [environment.api, projectsList, objectProjects, locationsList]
  );

  const getLocationFloor = useCallback(
    (locationId: number) => {
      return new FloorApi(environment.api)
        .getLocationFloors(locationId)
        .then((response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setLocationFloors(response.data);
          }
          return response;
        });
    },
    [environment.api]
  );

  const addNewProduct = useCallback(
    (body: API.AddObjectForm) => {
      return new ProductApi(environment.api).createProduct(body);
    },
    [environment.api]
  );

  const getLocationProduct = useCallback(
    async (
      locationId: number,
      projectLocationAmount?: number,
      projectLocationIndex?: number
    ) => {
      if (selectedOrganisation) {
        return new ProductApi(environment.api)
          .getLocationsProducts(locationId)
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              await downloadGenericFeature(FEATURE_UUID.LOCATION);
              if (
                projectLocationAmount &&
                projectLocationIndex &&
                projectLocationAmount > 0
              ) {
                const res = [...response.data];

                if (projectLocationAmount === projectLocationIndex - 1) {
                  setLocationsProducts(res);
                }
              } else {
                setLocationsProducts(response.data);
              }
            }
          })
          .catch(console.error);
      }
    },
    [environment.api, selectedOrganisation, downloadGenericFeature]
  );

  const getAllProducts = useCallback(
    async (featureStatus?: string, rows?: string, offset?: number) => {
      if (selectedOrganisation?.id) {
        return new ProductApi(environment.api)
          .getOrganizationProducts(
            selectedOrganisation?.id,
            featureStatus,
            rows ?? "25",
            offset ?? 0
          )
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              getDictionaries();
              setProductsList(response.data.data.features);
              setTotalProductsNumber(response.data.total);
            }
          })
          .catch(console.error);
      }
    },
    [environment.api, selectedOrganisation, getDictionaries]
  );

  const getLocationProjects = useCallback(
    async (locationId: number) => {
      if (selectedOrganisation?.id) {
        return new ProjectApi(environment.api)
          .apiGetLocationProjects(locationId)
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              setLocationProjects(response.data);
              return response.data;
            } else {
              return null;
            }
          })
          .catch(console.error);
      }
    },
    [environment.api, selectedOrganisation]
  );

  const editProduct = useCallback(
    async (body: API.AddObjectForm, productId: number, projectId?: number) => {
      if (selectedOrganisation) {
        return new ProductApi(environment.api)
          .updateProduct(body, productId)
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              setProductsList((prev) =>
                prev.map((product) =>
                  product.id === response?.data?.product?.id
                    ? {
                        ...product,
                        ...response.data.product,
                      }
                    : product
                )
              );
            }

            response?.data?.productLocationMappings?.[0]?.fk_location &&
              (await getLocationProduct(
                Number(
                  response?.data?.productLocationMappings?.[0]?.fk_location
                )
              ));
            //TODO: check saving by fk_location
            // response?.data?.productLocationMappings?.[0]?.fk_location &&
            //   (await downloadGenericFeature(
            //     FEATURE_UUID.PRODUCT,
            //     `fk_location=${response?.data?.productLocationMappings?.[0]?.fk_location}`
            //   ));
            projectId &&
              (await downloadGenericFeature(
                FEATURE_UUID.PROJECT_PRODUCTS,
                `fk_project=${projectId}`
              ));
            return response;
          })
          .catch(console.error);
      }
    },
    [
      environment.api,
      selectedOrganisation,
      downloadGenericFeature,
      getLocationProduct,
      setProductsList,
    ]
  );

  const singleLocation = useCallback(
    async (id: number, navigate?: (url: string) => void) => {
      const orgId = selectedOrganisation?.id;

      if (!orgId) return;

      const response = await new FeaturesApi(environment.api).getSingleFeature(
        FEATURE_UUID.LOCATION,
        id,
        orgId
      );

      if (response.kind === KIND_RESPONSE.OK) {
        return response.data as ILocationProperties;
      } else if (response.kind === KIND_RESPONSE.NOT_FOUND) {
        navigate?.("/404");
      }
    },
    [environment.api, selectedOrganisation?.id]
  );

  const singleProduct = useCallback(
    async (productId?: number, navigate?: (url: string) => void) => {
      if (selectedOrganisation?.id && productId) {
        const response = await new ProductApi(environment.api).getProduct(
          productId
        );

        getDictionaries();

        if (response.kind === KIND_RESPONSE.OK) {
          return response.data;
        } else if (response.kind === KIND_RESPONSE.NOT_FOUND) {
          navigate?.("/404");
        }
      }
    },
    [environment.api, getDictionaries, selectedOrganisation]
  );

  const genericProduct = useCallback(
    async (productId: number) => {
      const orgId = selectedOrganisation?.id;

      if (!orgId) return;

      const response = await new FeaturesApi(
        environment.api
      ).getMappingsProducts(FEATURE_UUID.PRODUCT, productId, orgId);

      if (response.kind === KIND_RESPONSE.OK) {
        setGenericProductItem(response.data);
        return response.data;
      }
    },
    [environment.api, selectedOrganisation]
  );

  const singleProject = useCallback(
    async (id: number, navigate?: (url: string) => void) => {
      if (selectedOrganisation !== null && selectedOrganisation?.id) {
        const response = await new FeaturesApi(
          environment.api
        ).getSingleFeature(FEATURE_UUID.PROJECT, id, selectedOrganisation?.id);

        if (response.kind === KIND_RESPONSE.OK) {
          return response.data;
        } else if (response.kind === KIND_RESPONSE.NOT_FOUND) {
          navigate?.("/404");
        }
      }
    },
    [environment.api, selectedOrganisation]
  );

  const addNewProject = useCallback(
    async (body: API.AddProjectForm) => {
      if (selectedOrganisation?.id) {
        return new ProjectApi(environment.api)
          .createProject(body, selectedOrganisation.id)
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              await downloadGenericFeature(FEATURE_UUID.PROJECT);
              setProjectsList([...projectsList, response.data]);
            }
            return response;
          })
          .catch(console.error);
      }
    },
    [
      environment.api,
      selectedOrganisation,
      projectsList,
      downloadGenericFeature,
    ]
  );

  const editProject = useCallback(
    async (body: API.AddProjectForm, projectId: number) => {
      if (selectedOrganisation?.id) {
        return new ProjectApi(environment.api)
          .editProject(body, projectId, selectedOrganisation?.id)
          .then((response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              const newArray = projectsList?.map((project) => {
                if (project?.id === response.data.id) {
                  return response.data;
                } else {
                  return project;
                }
              });

              setProjectsList(newArray);
            }
            return response;
          });
      }
    },
    [environment.api, selectedOrganisation, projectsList]
  );

  const editAssessmentScore = useCallback(
    async (id: number, body: AssessmentScoreBody, projectId?: number) => {
      if (selectedOrganisation) {
        return new ProductApi(environment.api)
          .apiUpdateScore(id, body)
          .then(async (response) => {
            projectId
              ? await downloadGenericFeature(
                  FEATURE_UUID.PROJECT_PRODUCTS,
                  `fk_project=${projectId}`
                )
              : await genericProduct(id);

            projectId && (await getProjectsAssessments(String(projectId)));
            return response;
          })
          .catch(console.error);
      }
    },
    [
      environment.api,
      downloadGenericFeature,
      genericProduct,
      selectedOrganisation,
      getProjectsAssessments,
    ]
  );

  const editAssessmentRecommendation = useCallback(
    async (projectId: number, body: AssessmentRecommendationBody) => {
      if (selectedOrganisation) {
        return new ProductApi(environment.api)
          .apiUpdateRecommendation(projectId, body)
          .then(async (response) => {
            await downloadGenericFeature(
              FEATURE_UUID.PROJECT_PRODUCTS,
              `fk_project=${projectId}`
            );
            await getProjectsAssessments(String(projectId));
            return response;
          })
          .catch(console.error);
      }
    },
    [
      environment.api,
      downloadGenericFeature,
      selectedOrganisation,
      getProjectsAssessments,
    ]
  );

  const connectProjectToLocation = useCallback(
    async (locationId: number, body: IConnectedLocationsBody[]) => {
      if (selectedOrganisation) {
        return new ProductApi(environment.api)
          .apiConnectProjectToLocation(locationId, body)
          .then(async (response) => {
            await downloadGenericFeature(
              FEATURE_UUID.LOCATION_PROJECTS,
              `fk_location=${locationId}`
            );

            return response;
          })
          .catch(console.error);
      }
    },
    [environment.api, selectedOrganisation, downloadGenericFeature]
  );

  const connectLocationToProject = useCallback(
    async (projectId: number, locationIds: number[]) => {
      if (selectedOrganisation) {
        return new ProductApi(environment.api)
          .apiConnectLocationToProject(projectId, locationIds)
          .then(async (response) => {
            await downloadGenericFeature(
              FEATURE_UUID.PROJECT_LOCATIONS,
              `fk_project=${projectId}`
            );
            return response;
          })
          .catch(console.error);
      }
    },
    [environment.api, downloadGenericFeature, selectedOrganisation]
  );

  const getDeletedItemInfo = useCallback(
    (idList: number[], objectType: FEATURE_TYPE) => {
      if (selectedOrganisation && selectedOrganisation?.id) {
        let objType = "";

        switch (objectType) {
          case FEATURE_TYPE.LOCATION:
            objType = "location";
            break;
          case FEATURE_TYPE.OBJECT:
            objType = "product";
            break;
          case FEATURE_TYPE.OBJECT_MAPPING:
            objType = "product_mapping";
            break;
          case FEATURE_TYPE.PROJECT:
            objType = "project";
            break;
          default:
            objType = "";
        }

        return new FeaturesApi(environment.api)
          .apiDeleteInfo(selectedOrganisation?.id, idList, objType)
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              return response?.data;
            }
          })
          .catch(console.error);
      }
    },
    [selectedOrganisation, environment]
  );

  const { successDeleteFeatureMessage, failedDeleteFeatureMessage } =
    useAlertMessage();

  const deleteFeature = useCallback(
    (featureType: FEATURE_TYPE, idList: number[]) => {
      if (selectedOrganisation?.id) {
        if (featureType === FEATURE_TYPE.OBJECT) {
          return new FeaturesApi(environment.api)
            .apiDeleteProduct(idList[0])
            .then(async (response) => {
              if (response.kind === KIND_RESPONSE.OK) {
                setAlertMessage(successDeleteFeatureMessage(featureType));
                await downloadGenericFeature(FEATURE_UUID.PRODUCT);
                return response;
              } else {
                setAlertMessage(failedDeleteFeatureMessage(featureType));
              }
            })
            .catch(console.error);
        } else {
          return new FeaturesApi(environment.api)
            .apiDeleteFeatures(selectedOrganisation.id, featureType, idList)
            .then(async (response) => {
              if (response.kind === KIND_RESPONSE.OK) {
                setAlertMessage(successDeleteFeatureMessage(featureType));

                switch (featureType) {
                  case FEATURE_TYPE.LOCATION:
                    await downloadGenericFeature(FEATURE_UUID.LOCATION);
                    break;
                  case FEATURE_TYPE.OBJECT_MAPPING:
                    break;
                  case FEATURE_TYPE.PROJECT:
                    await downloadGenericFeature(FEATURE_UUID.PROJECT);
                    break;
                  default:
                    return null;
                }

                return response;
              } else {
                setAlertMessage(failedDeleteFeatureMessage(featureType));
              }
            })
            .catch(console.error);
        }
      }
    },
    [
      selectedOrganisation,
      environment,
      downloadGenericFeature,
      setAlertMessage,
      successDeleteFeatureMessage,
      failedDeleteFeatureMessage,
    ]
  );

  const context = useMemo(() => {
    return {
      addNewLocation,
      editLocation,
      editFeatureStatus,
      locationsList,
      singleLocation,
      totalLocationsNumber,
      offset,
      setOffset,
      updateFloors,
      getLocationFloor,
      locationFloors,
      addNewProduct,
      getLocationProduct,
      locationsProducts,
      downloadSelectFeature,
      selectFeatures,
      editProduct,
      productsList,
      totalProductsNumber,
      singleProduct,
      getAllProducts,
      addNewProject,
      totalProjectsNumber,
      projectsList,
      singleProject,
      editProject,
      selectLocationsName,
      setLocationsProducts,
      getLocationProjects,
      locationProjects,
      downloadGenericFeature,
      objectProjects,
      projectLocations,
      projectProducts,
      projectProductsMappings,
      editAssessmentScore,
      editAssessmentRecommendation,
      featureLoader,
      connectProjectToLocation,
      connectLocationToProject,
      selectProjectsName,
      genericProduct,
      genericProductItem,
      getProjectsAssessments,
      projectAssessments,
      productAssessments,
      totalProjectProductsNumber,
      getProductAssessments,
      getDeletedItemInfo,
      deleteFeature,
    };
  }, [
    addNewLocation,
    addNewProduct,
    addNewProject,
    connectLocationToProject,
    connectProjectToLocation,
    downloadGenericFeature,
    downloadSelectFeature,
    editAssessmentRecommendation,
    editAssessmentScore,
    editFeatureStatus,
    editLocation,
    editProduct,
    editProject,
    featureLoader,
    genericProduct,
    genericProductItem,
    getAllProducts,
    getLocationFloor,
    getLocationProduct,
    getLocationProjects,
    getProductAssessments,
    getProjectsAssessments,
    locationFloors,
    locationProjects,
    locationsList,
    locationsProducts,
    objectProjects,
    offset,
    productsList,
    projectAssessments,
    productAssessments,
    projectLocations,
    projectProducts,
    projectProductsMappings,
    projectsList,
    selectFeatures,
    selectLocationsName,
    selectProjectsName,
    singleLocation,
    singleProduct,
    singleProject,
    totalLocationsNumber,
    totalProductsNumber,
    totalProjectProductsNumber,
    totalProjectsNumber,
    updateFloors,
    getDeletedItemInfo,
    deleteFeature,
  ]);

  if (!environment) {
    return null;
  }

  return (
    <FeatureContext.Provider value={context}>
      {children}
    </FeatureContext.Provider>
  );
};
