import { StyleSheet, Text, View } from "@react-pdf/renderer";

import { colors } from "./colors";

const styles = StyleSheet.create({
  componentTableRow: {
    backgroundColor: colors.lightContrast,
    width: "100%",
    textAlign: "center",
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 12,
    paddingBottom: 4,
    paddingTop: 4,
  },
  componentTableRowAlternate: {
    backgroundColor: colors.white,
  },
  componentTableRowKey: {
    paddingLeft: 15,
    width: "35%",
    color: colors.lightTextColor,
    textAlign: "left",
  },
  componentTableRowValue: {
    paddingRight: 15,
    color: colors.darkTextColor,
    textAlign: "right",
    width: "65%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

export const ReportTableRowContent = ({
  keyText,
  valueText,
  index,
}: {
  keyText: string;
  valueText: string | null | undefined;
  index: number;
}) => {
  return (
    <View
      style={
        index % 2 < 1
          ? styles.componentTableRow
          : [styles.componentTableRow, styles.componentTableRowAlternate]
      }
    >
      <View style={styles.componentTableRowKey}>
        <Text>{keyText}</Text>
      </View>
      <View style={styles.componentTableRowValue}>
        <Text>{valueText}</Text>
      </View>
    </View>
  );
};
