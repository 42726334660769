import React, { FC } from "react";

const LogoContours: FC = () => {
  return (
    <svg
      width="28"
      height="36"
      viewBox="0 0 28 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19305 17.0698C2.24122 17.0698 0.660156 15.4888 0.660156 13.5369V3.5329C0.660156 1.58106 2.24122 0 4.19305 0C6.14489 0 7.72595 1.58106 7.72595 3.5329V13.5439C7.72595 15.4958 6.14489 17.0768 4.19305 17.0768V17.0698Z"
        fill="#6239D9"
      />
      <path
        d="M26.3071 16.0766C24.9289 17.4548 22.6903 17.4548 21.3121 16.0766L11.2661 6.03062C9.88788 4.65244 9.88788 2.41377 11.2661 1.03559C12.6442 -0.342592 14.8829 -0.342592 16.2611 1.03559L26.3071 11.0816C27.6853 12.4598 27.6853 14.6985 26.3071 16.0766Z"
        fill="#6239D9"
      />
      <path
        d="M11.2661 34.9663C12.6442 36.3445 14.8829 36.3445 16.2611 34.9663L26.3071 24.9203C27.6853 23.5421 27.6853 21.3034 26.3071 19.9252C24.9289 18.5471 22.6903 18.5471 21.3121 19.9252L11.2661 29.9713C9.88788 31.3494 9.88788 33.5881 11.2661 34.9663Z"
        fill="#6239D9"
      />
      <path
        d="M4.19305 35.9997C2.24122 35.9997 0.660156 34.4186 0.660156 32.4668V22.4557C0.660156 20.5039 2.24122 18.9229 4.19305 18.9229C6.14489 18.9229 7.72595 20.5039 7.72595 22.4557V32.4668C7.72595 34.4186 6.14489 35.9997 4.19305 35.9997Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default LogoContours;
