import React, { FC, useContext, useState } from "react";

import { AppUserContext } from "../../../context/user-context/UserContext";
import { AlertMessage } from "../../alert-message";
import { Sidebar } from "../sidebar";
import { SidebarPageProps } from "./index.props";
import "./styles.scss";

export const SidebarPage: FC<SidebarPageProps> = ({ children, noSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    localStorage.getItem("isCollapsed")
      ? Boolean(JSON.parse(localStorage.getItem("isCollapsed") || ""))
      : false
  );
  const { alertMessage } = useContext(AppUserContext);

  const handleSetSidebarCollapsed = () => {
    if (isCollapsed) {
      localStorage.setItem("isCollapsed", false.toString());
      setIsCollapsed(false);
      return;
    }

    localStorage.setItem("isCollapsed", true.toString());
    setIsCollapsed(true);
  };

  return (
    <div className="page-container">
      {!noSidebar && (
        <Sidebar
          isCollapsed={isCollapsed}
          setCollapsed={handleSetSidebarCollapsed}
        />
      )}
      <section
        className={`page-container__content ${
          isCollapsed ? "page-container__content--collapse" : ""
        } ${noSidebar ? "page-container__content--no-sidebar" : ""}
          `}
      >
        {children}
      </section>
      <AlertMessage message={alertMessage} />
    </div>
  );
};
