import React, { FC } from "react";

const LanguageIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33996 4.37226L7.38868 4.4766L12.9488 18.9766C13.0967 19.3624 12.9099 19.7974 12.5315 19.9482C12.1847 20.0865 11.7973 19.9379 11.6212 19.6151L11.5786 19.5228L10.1456 15.7857L3.05883 15.7859L2.96479 15.7798L1.41801 19.5398C1.2609 19.9217 0.829833 20.1015 0.455197 19.9413C0.11178 19.7945 -0.0650117 19.4129 0.028924 19.0561L0.0613332 18.9596L6.02527 4.45964C6.26582 3.87479 7.04813 3.84994 7.33996 4.37226ZM16.7452 0.00292969C17.1249 0.00292969 17.4387 0.285084 17.4883 0.651159L17.4952 0.75293L17.4949 5.49893L19.2452 5.4997C19.6249 5.4997 19.9387 5.78185 19.9884 6.14793L19.9952 6.2497C19.9952 6.62939 19.7131 6.94319 19.347 6.99285L19.2452 6.9997L17.4949 6.99893L17.4952 14.2497C17.4952 14.6294 17.213 14.9432 16.847 14.9929L16.7452 14.9997C16.3655 14.9997 16.0517 14.7175 16.002 14.3515L15.9952 14.2497V0.75293C15.9952 0.338716 16.331 0.00292969 16.7452 0.00292969ZM6.81062 6.74708L3.64846 14.2857H9.75846L6.81062 6.74708ZM8.74726 0.00292969H14.245C14.6247 0.00292969 14.9385 0.285084 14.9882 0.651159L14.995 0.75293V3.75663C14.995 6.10121 13.0944 8.00187 10.7498 8.00187C10.3356 8.00187 9.99979 7.66609 9.99979 7.25187C9.99979 6.83766 10.3356 6.50187 10.7498 6.50187C12.2098 6.50187 13.4036 5.36214 13.49 3.92386L13.495 3.75663V1.50293H8.74726C8.33305 1.50293 7.99726 1.16714 7.99726 0.75293C7.99726 0.373234 8.27942 0.0594388 8.64549 0.00977635L8.74726 0.00292969H14.245H8.74726Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default LanguageIcon;
