import { additionalRenovationsProps } from "../components/add-new-feature/location/LocationModal";
import {
  ALERT_MESSAGE_STATUS,
  ALERT_MESSAGE_TYPE,
  ASSESSMENT_RATINGS,
  FEATURE_TYPE,
  LOCATION_STATUS,
  OBJECT_STATUS,
  PROJECT_STATUS,
  QUANTITY_UNIT,
} from "../contants/Enums";
import { GeneralApiProblem, KIND_RESPONSE } from "./api-problem";

export type DefaultResponseSuccess<Model> = {
  kind: KIND_RESPONSE.OK;
  data: Model;
};

export type DefaultResponse<Model> =
  | DefaultResponseSuccess<Model>
  | GeneralApiProblem;

export type FeaturesResponse<FeatureType> = {
  returned: number;
  srid: number;
  features: FeatureType[];
  total: number;
};

export type RegisterRequest = {
  name: string;
  surname: string;
  email: string;
  password: string;
  consents: boolean;
};

export type LoginRequest = {
  email: string;
  password: string;
};

export type VerifyBeginRequest = {
  email: string;
  token?: string;
};

export type VerifyRequest = {
  email: string;
  code: string;
};

export type ChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

export type TokenItem = {
  token: string;
  success?: boolean;
};

export type ResetPasswordRequest = {
  email: string;
  token?: string;
  newPassword?: string;
};

export type IUserData =
  | {
      id?: number;
      email?: string | null;
      phone?: string | null;
      displayName?: string | null;
      userData?: IUserDetails;
      uuid?: string | null;
      image?: {
        url?: string | null;
        iconUrl?: string | null;
      };
    }
  | undefined;

export type IUserDetails = {
  email?: string | null;
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  nick_name?: string | null;
  www?: string | null;
  profile?: string | null;
  phone?: string | null;
  mobile?: string | null;
  company?: string | null;
  department?: string | null;
  held_position?: string | null;
  description?: string | null;
  address_street?: string | null;
  address_house_no?: string | null;
  address_flat_no?: string | null;
  address_post_code?: string | null;
  address_post_code_area_name?: string | null;
  address_city?: string | null;
  address_country?: string | null;
};

export type IOrgMemberData = {
  uuid: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email?: string;
  invited_email?: string | null;
  organization_name: string;
  phone: string;
  mobile: string;
  company: string;
  department: string;
  held_position: string;
  image?: string;
  address?: string;
  role?: string;
  selected: boolean;
};

export type IOrganisation = {
  id: number | null;
  date_created?: string | null;
  date_modified?: string | null;
  created_by?: string | null;
  modified_by?: string | null;
  geom?: {
    lat: string[];
    lng: string[];
  };
  uuid?: string | null;
  _acl?: string[] | [];
  name: string;
  description?: string;
  image?: string;
  internal_name?: string | null;
  group_role_uuid?: string | null;
  org_nr?: string | null;
  address?: string | null;
  postal_code?: string | null;
  billing_email?: string | null;
  active?: boolean;
  member_limit?: number | null;
  yearly_payment?: boolean;
  billing_reference?: string | null;
  contact_person?: string | null;
  status?: string | null;
  logo_image?: string | null;
};

export type IOrganisationsResponse = {
  features: IOrganisation[];
};

export type AddUserToOrganisationRequest = {
  email: string | undefined;
  org_id: number | undefined | null;
};

export type UpdateUserRoleRequest = {
  uuid: string;
  org_id: number | undefined | null;
  new_role: string;
};

export type AddOrganisationRequest = {
  name: string;
  description: string;
  image: string;
  org_nr: string;
  address: string;
  postal_code: string;
  billing_email: string;
  member_limit: number;
  yearly_payment: boolean;
  billing_reference?: string;
  contact_person?: string;
};

export type AddLocationRequest = {
  geom: null;
  name: string;
  address: string;
  gnrbnr: string;
  county: string;
  municipality: string;
  building_owner: string;
  building_category: string;
  gross_floor_area: string;
  main_material: string;
  year_of_construction: string;
  year_and_type_of_renovations: string;
  demolition_date: string;
  type_of_demolition: string;
  image: string;
  attachments: string;
  comment: string;
  fk_organization: number;
  building_status: string;
  description: string;
  additional_renovations: additionalRenovationsProps[];
  kartlagt_fra: string;
  kartlagt_til: string;
  kartlagt_av: string;
};

export type AddObjectForm = {
  id?: number | string;
  uuid?: string;
  image: string;
  quantity: number;
  quantity_unit: string;
  fk_product: number;
  fk_location?: number;
  fk_floor: number;
  fk_room: number;
  obj_condition: string;
  parent_category: number;
  category: number;
  element: number;
  height: number;
  width: number;
  length: number;
  diameter: number;
  weight: number;
  producer: string;
  production_year: string;
  additional_comments: string;
  productLocationMappings: IObjectMappings[];
  specification: string | null;
  cost: number | null;
  co2: number | null;
  description: string;
};

export type AddProjectForm = {
  id?: number | null;
  uuid?: string;
  image?: string;
  name: string;
  project_leader: string;
  start_date: string;
  end_date: string;
  customer: string;
  locations: ILocationProperties[];
  status: string;
  fk_organization?: number;
  description?: string;
};

export type ProjectLocations = {
  fk_location: number | string;
  fk_project?: number;
  type_of_location: string;
};

export type IObjectMappings = {
  uuid: string;
  id?: number;
  fk_product: number | null | undefined;
  fk_organization: number;
  fk_location: number | null | undefined;
  fk_floor?: number;
  fk_room?: number;
  quantity: number;
  obj_condition: string;
  image?: string;
};

export type IObjectResponse = {
  product: {
    id: number;
    image: string;
    quantity: number;
    quantity_unit: string;
    mapping_id?: number;
    fk_floor: number;
    fk_room: number;
    fk_location: number;
    obj_condition: string;
    parent_category: number;
    category: number;
    element: number;
    height?: number;
    width?: number;
    length?: number;
    diameter?: number;
    weight?: number;
    producer: string;
    production_year: string;
    additional_comments: string;
    date_created: string;
    date_modified: string;
    created_by: string;
    modified_by: string;
    geom: null;
    uuid: string;
    demountability_score: number;
    remaining_lifetime_score: number;
    volume_score: number;
    demand_score: number;
    environmental_effect_score: number;
    cost_use_score: number;
    fk_organization: number;
    specification: string | null;
    cost: number | null;
    co2: number | null;
    description: string;
  };
  productLocationMappings: IProductWithMappings[];
};

export type IProductWithMappings = {
  id: number;
  image: string;
  quantity: number;
  sum?: number;
  quantity_unit: string;
  fk_floor: number;
  fk_room: number;
  fk_location: number;
  fk_product: number;
  fk_project?: number;
  obj_condition: string;
  parent_category: number;
  category: number;
  element: number;
  height?: number;
  width?: number;
  length?: number;
  diameter?: number;
  weight?: number;
  producer: string;
  production_year: string;
  additional_comments: string;
  date_created: string;
  date_modified: string;
  created_by: string;
  modified_by: string;
  geom: null;
  uuid: string;
  demountability_score: number;
  remaining_lifetime_score: number;
  volume_score: number;
  demand_score: number;
  environmental_effect_score: number;
  cost_use_score: number;
  fk_organization: number;
  specification: string | null;
  cost: number | null;
  co2: number | null;
  productLocationMappings?: IProductWithMappings[];
  recommendation?: string | null;
  description: string;
  mapping_image?: string;
  floor_name?: string;
  room_name?: string;
  mapping_id?: number;
};

export type AddObjectRequest = {
  fk_location: number;
  product: {
    image: string;
    productLocationMappings: [
      {
        uuid: string;
        fk_product: number;
        fk_organization: number;
        fk_location: number;
        fk_floor: number;
        fk_room: number;
        quantity: number;
        obj_condition: string;
      }
    ];
    quantity_unit: string;
    parent_category: number;
    category: number;
    element: number;
    height: number;
    width: number;
    length: number;
    diameter: number;
    weight: number;
    producer: string;
    production_year: string;
    additional_comments: string;
    cost: number | null;
    co2: number | null;
    description: string;
  };
};

export type ILocation = {
  geometry?: null;
  id?: number;
  properties: ILocationProperties;
  type?: string;
};

export type ILocationFeatures = FeaturesResponse<ILocationProperties>;

export type IObjectFeatures = {
  returned: number;
  srid: number;
  features: IObjectProperties[];
  total: number;
};

export type ILocationStatus = {
  geometry?: null;
  id?: number;
  properties: string;
  type?: string;
};

export type ILocationProperties = {
  id?: number;
  date_created?: string;
  date_modified?: string;
  created_by?: string;
  modified_by?: string;
  geom?: null;
  uuid?: string;
  name?: string;
  address?: string;
  gnrbnr?: string;
  fylke?: string;
  kommune?: string;
  owner?: string;
  building_category?: string;
  area?: number;
  main_material_use?: string;
  building_year?: number | null;
  rehab_year?: string;
  demolition_date?: string;
  comments?: string;
  fk_organization?: number;
  status?: string;
  image?: string | null;
  type_of_demolition?: string;
  media?: IMediaFeature;
  floors?: IFloorResponse | IFloorResponse[] | [];
  type_of_location?: string;
  floor_count?: number;
  room_count?: number;
  fk_location?: number;
  description?: string;
  product_quantity?: number;
  kartlagt_fra?: string;
  kartlagt_til?: string;
  kartlagt_av?: string;
  projects?: IProjectProperties[];
  media_list?: IMediaFeature[] | null;
};

export type IProject = {
  geometry?: null;
  id?: number;
  properties: IProjectProperties;
  type?: string;
};

export type IProjectProperties = {
  id?: number;
  date_created?: string;
  date_modified?: string;
  created_by?: string;
  modified_by?: string;
  uuid?: string;
  name?: string;
  fk_organization?: number;
  fk_product?: number | null;
  fk_location?: number;
  status?: string;
  image?: string | null;
  customer?: string | null;
  end_date?: string | null;
  start_date?: string | null;
  project_leader?: string | null;
  location_list?: number[];
  location_count?: number;
  product_count?: number;
  description?: string;
  media_list?: IMediaFeature[] | null;
};

export type IObjectProperties = {
  id?: number;
  image?: string | null;
  quantity?: number;
  obj_condition?: string;
  quantity_unit?: string;
  parent_category?: number;
  category?: number;
  element?: number;
  height?: number;
  width?: number;
  length?: number;
  diameter?: number;
  weight?: number;
  producer?: string;
  production_year?: string;
  additional_comments?: string;
  date_created?: string;
  date_modified?: string;
  created_by?: string;
  modified_by?: string;
  geom?: null;
  uuid?: string;
  demountability_score?: number;
  remaining_lifetime_score?: number;
  volume_score?: number;
  demand_score?: number;
  environmental_effect_score?: number;
  cost_use_score?: number;
  fk_organization?: number;
  fk_location?: number;
  fk_product?: number | null;
  specification?: string | null;
  cost?: number | null;
  co2?: number | null;
  description?: string;
  recommendation?: string;
  mapping_image?: string;
  mapping_id?: number;
  fk_floor?: number;
  fk_room?: number;
  room_name?: string;
  floor_name?: string;
  media_list?: IMediaFeature[] | null;
};

export type IObjectPropertiesWithRecommendation = IObjectProperties & {
  recommendation?: string;
};

export type AssessmentScoreBody = {
  product_condition: string;
  demountability_score: number;
  remaining_lifetime_score: number;
  volume_score: number;
  demand_score: number;
  environmental_effect_score: number;
  cost_use_score: number;
};

export type AssessmentRecommendationBody = {
  fk_product: number;
  product_condition: string;
  recommendation: string;
};

export type IAssessmentsMappings = {
  product_condition: string;
  demountability_score: number;
  remaining_lifetime_score: number;
  volume_score: number;
  demand_score: number;
  environmental_effect_score: number;
  cost_use_score: number;
  fk_organization?: number;
  fk_product?: number | null;
  fk_project?: number | null;
  obj_condition?: string;
  quantity: 1;
};

export type DefaultApiResponse =
  | DefaultResponse<{ message: string; success?: boolean }>
  | GeneralApiProblem;

export type UserItem = {
  session_id: string | undefined;
  uuid: string | undefined;
  expiration_time: number | undefined;
};

export type IPrivacyConsentResponse = {
  d: {
    data: [];
    exception: null;
    exceptions: [];
    records: {
      accepted_version: number;
      category: string;
      created_date: string;
    }[];
    sessionExpired: boolean;
    success: boolean;
    total: number;
    __type: string;
  };
};

export type IPrivacyConsentConfigRecord = {
  data_processor: string;
  data_processor_email: string;
  privacy_policy_version: number;
  privacy_short_enabled_nb: boolean;
  privacy_short_nb: string;
  privacy_policy_url_nb: string;
  privacy_policy_text_nb: string;
  privacy_short_enabled_en: boolean;
  privacy_short_en: string;
  privacy_policy_url_en: string;
  privacy_policy_text_en: string;
  last_written: string;
  last_written_by: string;
};

export type IPrivacyConsentConfigResponse = {
  d: {
    data: [];
    exception: null;
    exceptions: [];
    records: IPrivacyConsentConfigRecord;
    sessionExpired: boolean;
    success: boolean;
    total: number;
    __type: string;
  };
};

export type IAcceptPrivacyConsentResponse = {
  d: {
    __type: string;
    success: boolean;
    exception: null;
    sessionExpired: boolean;
    exceptions: [];
    data: [];
  };
};

export type IAcceptPrivacyConsent = {
  version: number;
  category: string;
};

export type ICreateMedia = {
  extraParams: [];
  data?: {
    config?: {
      configs: [
        {
          key: string;
          value: string;
        }
      ];
    };
    mime?: string;
    name?: string;
    direction?: number;
    uuid?: string;
    owner_uuid?: string;
    parent_object_uuid?: string;
    parent_object_type?: string;
  };
};

export type IMediaFeature = {
  geom: null;
  media: string;
  description: string;
  fk_location?: number;
  fk_organization?: number;
  fk_project?: number;
  fk_product?: number;
  fk_object?: number;
  geom_wkt: string;
  title: string;
  file_type: string;
  id?: number;
  uuid?: string;
  geom_modified?: boolean;
  is_thumbnail?: boolean;
  _delete?: boolean;
};

export type PostMedia = Omit<IMediaFeature, "media"> & {
  media: { dataUrl: string; name: string; comment: string; direction: number };
  generated_uuid: string;
};

export function isPostMedia(
  attachment: IMediaFeature | PostMedia
): attachment is PostMedia {
  return typeof attachment.media !== "string";
}

export type IMediaASMXResponse = {
  d: {
    data: IMediaItem[];
    exception: null;
    exceptions: [];
    records: null;
    sessionExpired: boolean;
    success: boolean;
    total: number;
  };
};

export type IMediaItem = {
  key: string;
  value: string;
};

export type IAlertMessages = {
  title: string;
  description: string;
  type: ALERT_MESSAGE_TYPE;
  status: ALERT_MESSAGE_STATUS;
};

export type IOrganisationStore = {
  type: string;
  uuid: string;
};

export type IStatusTabs = {
  text: string;
  status?:
    | LOCATION_STATUS
    | OBJECT_STATUS
    | QUANTITY_UNIT
    | PROJECT_STATUS
    | ASSESSMENT_RATINGS;
  to?: string;
};

export type ISelectedColumns = {
  id: number;
  value: boolean;
  text: string;
};

export type IFloors = {
  name: string;
  uuid: string;
  sequence_id: number;
  room_list: IRooms[];
};

export type IRooms = {
  uuid: string;
  name: string;
  sequence_id: number;
};

export type IFloorResponse = {
  id: number;
  geom: null;
  date_created: string;
  date_modified: string;
  created_by: string;
  modified_by: string;
  uuid: string;
  name: string;
  sequence_id: number;
  fk_location: number;
  room_list: [
    {
      id: number;
      geom: null;
      date_created: string;
      date_modified: string;
      created_by: string;
      modified_by: string;
      uuid: string;
      name: string;
      sequence_id: number;
      fk_floor: number;
    }
  ];
};

export type IDeleteInfoResponse = {
  project_quantity: number;
  location_quantity: number;
  media_quantity: number;
  floor_quantity: number;
  room_quantity: number;
  product_quantity: number;
  product_recommendation_quantity: number;
  product_mapping_quantity: number;
};

export type IDictionary = {
  kode: number;
  id?: number;
  navn: string;
  overkategori?: number;
  kategori?: string;
  _editable: boolean;
};

export type IDictionaryData = {
  key: string;
  value: {
    Key: string;
    Value: string;
  }[];
};

export type IDictionaryResponse = {
  d: {
    data: IDictionaryData;
    exception: null;
    exceptions: [];
    records: IDictionary[];
    sessionExpired: boolean;
    success: boolean;
    total: number;
  };
};

export type CMSResponse = {
  d: {
    data: unknown[];
    records: {
      id: number;
      uuid: string;
      created_by: string;
      page_id: string;
      parent: null;
      title: string;
      content: TrustedHTML | string;
      priority: number;
      show_in_menu: true;
      is_active: true;
      site: number;
      modified_by: string;
      menu: null;
      uri: string;
      menu_uri: string;
      data: string;
    }[];
    success: boolean;
    total: number;
    sessionExpired: boolean;
    exception: string | null;
  };
};

export type sliderData = {
  src: string;
  alt: string;
};

export type IFullscreenModalData = {
  showModal: boolean;
  sliderData: sliderData[];
};

export type IDataInfo = {
  title: string;
  description?: string | number | null;
};

export type IWasteStatisticsRequest = {
  org_id: number;
  year: number;
};

export type IWasteStatisticsResponse = {
  kg_saved: number;
  co2_saved: number;
  cost_saved: number;
};

export type IStatisticsRequest = {
  org_id: number;
  month?: number;
  year?: number;
};

export type IStatisticsResponse = {
  location_count: number;
  product_count: number;
  project_count: number;
};

export type IConnectedProjectsBody = {
  fk_project?: number;
  type_of_location?: string;
};

export type IConnectedLocationsBody = {
  fk_location?: number;
  fk_project?: number;
  type_of_location?: string;
};

export type IFeatureConnectionForm = {
  location_list: number[];
};

export type IConnectedToProjectsResponse = {
  fk_project: number;
  fk_location: number;
};

export type IFeatureDeleteType = {
  featureDetails?: Partial<ILocationProperties & IObjectProperties> | null;
  featureType: FEATURE_TYPE | null;
  previewFeatureType?: FEATURE_TYPE | null;
} | null;

export type IMediaFeatures = FeaturesResponse<IMediaFeature>;

export type VerifyApiResponse = DefaultResponse<TokenItem>;
export type LoginResponse = DefaultResponse<UserItem>;
export type RegisterResponse = DefaultResponse<UserItem>;
export type UserDataResponse = DefaultResponse<IUserData>;
export type UserUpdateResponse = DefaultResponse<IUserDetails>;
export type OrganisationsResponse = DefaultResponse<IOrganisationsResponse>;
export type OrganisationsMembersResponse = DefaultResponse<IOrgMemberData[]>;
export type AddOrganisationsResponse = DefaultResponse<IOrganisation>;
export type UpdateUserRoleResponse = DefaultResponse<IOrganisation>;
export type AddUserToOrganisationResponse = DefaultResponse<IOrganisation>;
export type JoinOrganisationResponse = DefaultResponse<IUserData>;
export type AddLocationResponse = DefaultResponse<ILocation>;
export type DeleteInfoResponse = DefaultResponse<IDeleteInfoResponse>;
export type EditLocationResponse = DefaultResponse<ILocationProperties>;
export type AddProjectResponse = DefaultResponse<IProject>;
export type EditLocationStatusResponse = DefaultResponse<ILocationStatus>;
export type PrivacyConsentResponse = DefaultResponse<IPrivacyConsentResponse>;
export type PrivacyConsentConfigResponse =
  DefaultResponse<IPrivacyConsentConfigResponse>;
export type AcceptPrivacyConsentResponse =
  DefaultResponse<IAcceptPrivacyConsentResponse>;
export type EditFeatureStatusResponse = DefaultResponse<string>;

export type FloorResponse = DefaultResponse<IFloorResponse>;
export type FloorArrayResponse = DefaultResponse<IFloorResponse[]>;
export type ObjectResponse = DefaultResponse<IObjectResponse>;
export type ConnectedProjectLocationsResponse =
  DefaultResponse<ProjectLocations>;
export type DictionaryResponse = DefaultResponse<IDictionaryResponse>;
export type WasteStatisticsResponse = DefaultResponse<IWasteStatisticsResponse>;
export type StatisticsResponse = DefaultResponse<IStatisticsResponse>;
export type ArticleResponse = DefaultResponse<CMSResponse>;
export type ExcelExportResponse = DefaultResponse<Blob>;

export type MediaResponse = DefaultResponse<IMediaFeature>;
export type MediaResponseList = DefaultResponse<IMediaFeature[]>;
export type MediaASMXResponse = DefaultResponse<IMediaASMXResponse>;
export type DownloadAttachmentsResponse = DefaultResponse<IMediaFeatures>;
