import { SelectItem } from "@avinet/adaptive-ui-core/form/controls/Select";

import {
  IObjectMappings,
  IProductWithMappings,
  IStatusTabs,
} from "../../../../api/types";
import { FEATURE_UUID } from "../../../../contants/FeatureUuid";
import {
  IComponentFilter,
  ILocationFilter,
  IProjectFilter,
} from "../../../../context/filter-context/FilterContext";
import { SortOrderDirection } from "../PageTable";

export enum PageTableColumnTypes {
  IMAGE = "image",
  TEXT = "text",
  STATUS = "status",
  CONDITION = "condition",
  LOCATION = "location",
}

export interface IPageTableColumnMeta {
  /**
   * key used to find translations and the property name
   */
  key: string;
  /**
   * Title is the translated title used in headings
   */
  title: string;
  /**
   * Active is used to determine if the column should be rendered or not
   */
  active: boolean;
  /**
   * Dictates if a column is sortable or not
   */
  sortable: boolean;
  /**
   * The column type defines the render logic for the column
   */
  type: PageTableColumnTypes;
}

export interface TableImageValue {
  image: string;
  name: string;
}

export interface TableImage {
  value: TableImageValue;
  key: "image";
}

export interface IPageTableColumnData {
  /**
   * Value is the property data. Could sometimes be calculated with combinations of other properties, or
   * an object with multiple properties where the presentation layers does the render logic
   */
  value: string | number | boolean | object | IObjectMappings | TableImage;
  /**
   * The column key
   */
  key: string;
}

export interface IPageTableContent {
  columns: IPageTableColumnMeta[];
  data: IPageTableColumnData[][];
  updateColumnVisibility: (columnKey: string, active: boolean) => void;
  resetAllColumns: () => void;
}

export interface PageTableProps {
  /**
   * Custom styles
   */
  className?: string;
  /**
   * Title text
   */
  title: string;
  /**
   * Subtitle text
   */
  subtitle: string;
  /**
   * Top tabs data
   */
  tabs?: IStatusTabs[];
  /**
   * Columns metadata and data
   */
  columns: IPageTableContent | undefined;
  /**
   * Show/hide header
   */
  noHeader?: boolean;
  /**
   * Select item handler
   */
  onRecordSelect?: (id: number | string) => void;
  /**
   * Preview item handler
   */
  onRecordPreview?: (id: number | string, mapping_id?: number) => void;
  /**
   * Selected object handler
   */
  selectObject?: (item: IProductWithMappings) => void;
  /**
   * Feature uuid
   */
  featureUUid?: FEATURE_UUID;
  /**
   * Edit item handler
   */
  onRecordEdit?: (id: number | string) => void;
  /**
   * Delete item handler
   */
  onRecordDelete?: (id: number | number[]) => void;
  /**
   * Route prefix for preview routing
   */
  setColumnVisibility?: (columnKey: string, active: boolean) => void;
  /**
   * Define custom options of status select
   */
  statusOptions?: SelectItem[];
  /**
   *  Displays amount of all data
   */
  totalItemsCount?: number;
  /**
   *  Feature filter data
   */
  featurePreviewFilter?: string;
  /**
   * Add new item handler
   */
  noFeatureHandler: () => void;
  /**
   * Disable batch select
   */
  disableBatchSelect?: boolean;
  /**
   * Filter button
   */
  onFilterClick?: () => void;
  /**
   * Filter active
   */
  filterActive?: boolean;
  /**
   *  Table header text handler
   */
  tableHeaderText?: (tab: string) => string;
  // /**
  //  *  Update filter data
  //  */
  // onUpdateFilter?: (
  //   filter: Partial<IComponentFilter> | IProjectFilter | ILocationFilter
  // ) => void;
  // /**
  //  *  Filter data
  //  */
  // filter?: Partial<IComponentFilter> | IProjectFilter | ILocationFilter;
  componentInLocation?: boolean;
  /**
   *  Reset batch select amount
   */
  resetBatchSelect?: boolean;
  /**
   *  Reset batch select handler
   */
  setResetBatchSelect?: (state: boolean) => void;
}

export interface PageTableHeadWrapperProps {
  /**
   * Title
   */
  title: string;
  /**
   * Subtitle
   */
  subtitle: string;
  /**
   * total number of items in table
   */
  totalLocationsNumber: number;
  /**
   * Feature uuid
   */
  featureUUid: FEATURE_UUID | undefined;
  /**
   * Disable the columns modal
   */
  columnsButtonDisabled?: boolean;
  /**
   * Update columns metadata when user select column
   */
  handleSelectColumn: (id: string) => void;
  /**
   * Columns metadata and data
   */
  columns: IPageTableContent | undefined;
  /**
   * Set column visibility
   */
  resetAllColumns: () => void;

  /**
   *  Reset sort columns data
   */
  resetSortColumns: () => void;
  /**
   * Grid state
   */
  gridMode: boolean;
  /**
   * Grid state  handler
   */
  handleSetGridMode: (gridMode: boolean) => void;
  /**
   *  Sort item by selected element method
   */
  handleSortItem: (id: IPageTableColumnMeta) => void;
  /**
   *  Active sort header
   */
  activeHeader?: string;
  /**
   *  Sort order type
   */
  sortOrder?: SortOrderDirection;

  /**
   * Filter button
   */
  onFilterClick?: () => void;

  /**
   * Filter active
   */
  filterActive?: boolean;
}
