import Row from "@avinet/adaptive-ui-core/layout/Row";
import {
  PopupMenu,
  PopupMenuButton,
} from "@avinet/adaptive-ui-core/ui/PopupMenu";
import { t } from "i18next";

import { Icon } from "../icon/Icon";
import { BatchSelectProps } from "./BatchSelectProps";
import "./BatchSelectSmall.scss";

export function BatchSelectSmall({
  count,
  onClose,
  children,
}: BatchSelectProps) {
  return (
    <Row className="batch-select-small" align="stretch">
      <Row className="batch-select-left">
        <button type="button" className="btn-link icon " onClick={onClose}>
          <Icon name="dismiss" />
        </button>
        <div className="item-counter">{count}</div>
      </Row>

      <PopupMenuButton
        className="btn icon"
        menu={
          <PopupMenu className="mobile-batch-select-options">
            {children}
          </PopupMenu>
        }
      >
        <span className="batch-action-text">{t("common.actions")}</span>
      </PopupMenuButton>
    </Row>
  );
}
