/* eslint-disable */
import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import * as Types from "../api/types";
import { IFloorResponse } from "../api/types";
import { Api } from "./api/api";

export class FloorApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async uploadLocationFloor(
    body: Types.IFloors[],
    locationId: number
  ): Promise<Types.FloorResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `ombruk/floor/${locationId}`,
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getLocationFloors(
    locationId: number
  ): Promise<Types.FloorArrayResponse> {
    const response: ApiResponse<IFloorResponse[]> = await this.api.apisauce.get(
      `ombruk/floor/${locationId}`
    );
    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }
}

/* eslint-disable */
