import { t } from "i18next";

import { DEFAULT_API_CONFIG } from "../api/api-config";
import { PostMedia } from "../api/types";
import {
  BUILDING_CATEGORY,
  BUILDING_DEMOLITION_TYPE,
  FILE_TYPE,
  LOCATION_STATUS,
  OBJECT_STATUS,
  PROJECT_STATUS,
  QUANTITY_UNIT,
  THUMBNAIL_SIZE,
} from "../contants/Enums";
import { ICustomScoreValidation } from "./types/types";

export const convertToInitials = (
  name?: string | null,
  surname?: string | null
) => {
  if (name) {
    return `${name?.charAt(0).toUpperCase()}${
      surname ? surname?.charAt(0).toUpperCase() : name?.charAt(1).toUpperCase()
    }`;
  }
};

export const getMediaUri = (
  uuid: string,
  token: string | null,
  thumbnailSize?: THUMBNAIL_SIZE | undefined
): string => {
  const tokenPart = token ? `&gm_session_id=${token}` : "";
  const thumbnailSizePart = thumbnailSize
    ? `&thumbnail_size=${thumbnailSize}`
    : "";
  return token
    ? `${DEFAULT_API_CONFIG.webUrl}WebServices/generic/Media.asmx/Download?uuid=${uuid}${tokenPart}${thumbnailSizePart}`
    : "";
};

export const getBase64 = (file: File, cb: (result: string) => void) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    reader.result !== null && cb(reader.result.toString());
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const objectStatusText = (status: string) => {
  switch (status) {
    case OBJECT_STATUS.GOOD:
      return t("options.condition.good");
    case OBJECT_STATUS.MEDIUM:
      return t("options.condition.medium");
    case OBJECT_STATUS.BAD:
      return t("options.condition.bad");
    default:
      return "";
  }
};

export const getFileType = (type?: string) => {
  switch (type) {
    case "application/doc":
    case "application/docx":
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return FILE_TYPE.DOC;
    case "application/xls":
    case "application/xlsx":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return FILE_TYPE.XLS;
    case "application/ppt":
    case "application/pptx":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return FILE_TYPE.PPT;
    case "application/pdf":
      return FILE_TYPE.PDF;
    default:
      return FILE_TYPE.FILE;
  }
};

export const guidGenerator = (): string => {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const handleSetNorwegianLangText = (text: string) => {
  switch (text) {
    case OBJECT_STATUS.GOOD:
      return t("options.condition.good");
    case OBJECT_STATUS.MEDIUM:
      return t("options.condition.medium");
    case OBJECT_STATUS.BAD:
      return t("options.condition.bad");
    case OBJECT_STATUS.NO_CONDITION:
      return t("options.condition.noCondition");
    default:
      return t("options.condition.noCondition");
  }
};

export const checkImageFileType = (fileName?: string) => {
  return fileName?.match(/\.(jpg|jpeg|png|gif|svg|webp|avif)$/i);
};

export function isFileMIMEImageType(fileType: string) {
  const imageFiletype = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg",
    "image/avif",
    "image/webp",
    "image",
  ];

  return imageFiletype.includes(fileType);
}

export const setScoreColor = (
  score?: number | null,
  customScoreValidation?: ICustomScoreValidation
) => {
  if (
    Number(score) > (customScoreValidation ? customScoreValidation.medium : 20)
  ) {
    return "score-rating--high";
  } else if (
    Number(score) <=
      (customScoreValidation ? customScoreValidation.good : 20) &&
    Number(score) > (customScoreValidation ? customScoreValidation.bad : 10)
  ) {
    return "score-rating--medium";
  } else if (
    Number(score) <=
      (customScoreValidation ? customScoreValidation.medium : 10) ||
    score === null ||
    score === undefined
  ) {
    return "score-rating--bad";
  } else {
    return "";
  }
};

export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export async function fileToPostMedia(file: File): Promise<PostMedia> {
  const fileUrl = await fileToBase64(file);
  return {
    generated_uuid: guidGenerator(),
    title: file.name,
    description: "",
    geom: null,
    file_type: file.type,
    geom_wkt: "",
    media: {
      name: file.name,
      dataUrl: fileUrl as string,
      comment: "",
      direction: 0,
    },
  };
}

export const handleTranslateBuildingCategory = (category: string) => {
  switch (category) {
    case BUILDING_CATEGORY.HOUSE:
      return t("options.category.house");
    case BUILDING_CATEGORY.APARTMENT:
      return t("options.category.apartment");
    case BUILDING_CATEGORY.DAYCARE:
      return t("options.category.daycare");
    case BUILDING_CATEGORY.OFFICE:
      return t("options.category.office");
    case BUILDING_CATEGORY.SCHOOL:
      return t("options.category.school");
    case BUILDING_CATEGORY.UNIVERSITY:
      return t("options.category.university");
    case BUILDING_CATEGORY.HOSPITAL:
      return t("options.category.hospital");
    case BUILDING_CATEGORY.NURSING:
      return t("options.category.nursing");
    case BUILDING_CATEGORY.HOTEL:
      return t("options.category.hotel");
    case BUILDING_CATEGORY.GYMNASIUM:
      return t("options.category.gymnasium");
    case BUILDING_CATEGORY.COMMERCIAL:
      return t("options.category.commercial");
    case BUILDING_CATEGORY.CULTURAL:
      return t("options.category.cultural");
    case BUILDING_CATEGORY.LIGHT_INDUSTRY:
      return t("options.category.lightIndustry");
    case BUILDING_CATEGORY.STORAGE:
      return t("options.category.storage");
    case BUILDING_CATEGORY.PARK:
      return t("options.category.park");
    case BUILDING_CATEGORY.OTHER:
      return t("options.category.other");
    default:
      return "-";
  }
};

export const handleTranslateBuildingStatus = (status: string) => {
  switch (status) {
    case LOCATION_STATUS.RENOVATION:
      return t("options.buildingStatuses.rehabilitated");
    case LOCATION_STATUS.NEW:
      return t("options.buildingStatuses.new");
    case LOCATION_STATUS.DEMOLISHED:
      return t("options.buildingStatuses.demolished");
    case LOCATION_STATUS.STATIC:
      return t("options.buildingStatuses.static");
    case LOCATION_STATUS.COMPLETED:
      return t("options.buildingStatuses.completed");
    default:
      return "";
  }
};

export const handleTranslateBuildingDemolitionType = (
  demolitionType: string
) => {
  switch (demolitionType) {
    case BUILDING_DEMOLITION_TYPE.PLANNED:
      return t("options.buildingDemolition.planned");
    case BUILDING_DEMOLITION_TYPE.ESTIMATED:
      return t("options.buildingDemolition.estimated");
    default:
      return "";
  }
};

export const handleTranslateProjectStatus = (status: string) => {
  switch (status) {
    case PROJECT_STATUS.PLANNING:
      return t("options.projectStatuses.planning");
    case PROJECT_STATUS.IN_PROGRESS:
      return t("options.projectStatuses.inProgress");
    case PROJECT_STATUS.COMPLETED:
      return t("options.projectStatuses.completed");
    default:
      return "";
  }
};

export const handleTranslateQuantityUnit = (quantityUnit: string) => {
  switch (quantityUnit) {
    case QUANTITY_UNIT.M2:
      return t("options.quantityUnit.m2");
    case QUANTITY_UNIT.LM:
      return t("options.quantityUnit.lm");
    case QUANTITY_UNIT.M3:
      return t("options.quantityUnit.m3");
    case QUANTITY_UNIT.OTHER:
      return t("options.quantityUnit.other");
    case QUANTITY_UNIT.STK:
      return t("options.quantityUnit.quantity");
    default:
      return t("options.quantityUnit.quantity");
  }
};
