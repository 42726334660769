import { format } from "date-fns";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useMemo } from "react";

import { IObjectProperties } from "../api/types";
import {
  IPageTableColumnData,
  IPageTableColumnMeta,
  IPageTableContent,
  PageTableColumnTypes,
} from "../components/page/page-table/table-header/index.props";
import { ASSESSMENT_RATINGS } from "../contants/Enums";
import { FEATURE_UUID } from "../contants/FeatureUuid";
import { DictionaryContext } from "../context/dictionary-context/DictionaryContext";
import { FeatureContext } from "../context/feature-context/FeatureContext";
import { handleTranslateQuantityUnit } from "../utils/index.utils";
import { useColumnState } from "./useColumnState";

export const useProjectObjectTable = (
  projectProduct: IObjectProperties[] | [],
  mappingImg?: boolean
) => {
  const { getCategoryName, getElementName, getParentCategoryName } =
    useContext(DictionaryContext);

  const initialColumnMeta: IPageTableColumnMeta[] = useMemo(() => {
    return [
      {
        key: "id",
        title: t("tables.id"),
        active: false,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "picture",
        title: t("tables.picture"),
        active: true,
        sortable: false,
        type: PageTableColumnTypes.IMAGE,
      },
      {
        key: "specification",
        title: t("tables.name"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "obj_condition",
        title: t("tables.condition"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.CONDITION,
      },
      {
        key: "quantity",
        title: t("tables.quantity"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "quantity_unit",
        title: t("tables.quantity_unit"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "fk_location",
        title: t("tables.location"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "floor_name",
        title: t("tables.floor"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "room_name",
        title: t("tables.room"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "parent_category",
        title: t("tables.category"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "category",
        title: t("tables.subCategory"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "element",
        title: t("tables.element"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "date_created",
        title: t("tables.dateCreated"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "cost",
        title: t("tables.cost"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "co2",
        title: t("tables.co2"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "production_year",
        title: t("tables.productionYear"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "producer",
        title: t("tables.producer"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "demountability_score",
        title: t("tables.demountabilityScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "remaining_lifetime_score",
        title: t("tables.remainingLifetimeScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "volume_score",
        title: t("tables.volumeScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "demand_score",
        title: t("tables.demandScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "environmental_effect_score",
        title: t("tables.environmentalEffectScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "cost_use_score",
        title: t("tables.costUseScore"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
    ];
  }, []);

  const { columnState, updateColumnVisibility, resetAllColumns } =
    useColumnState(initialColumnMeta);

  const { locationsList, downloadGenericFeature } = useContext(FeatureContext);

  useEffect(() => {
    downloadGenericFeature(FEATURE_UUID.LOCATION);
  }, [downloadGenericFeature]);

  const objectTableColumns = useMemo(() => {
    const columnData = projectProduct?.map((product) => {
      const categoryName = getCategoryName(product?.category as number) || "-";
      const parentCategoryName =
        getParentCategoryName(product?.parent_category as number) || "-";
      const elementName = getElementName(product?.element as number) || "-";
      const locationId = product?.fk_location as number;
      const floorName = (product?.floor_name as string) || "-";
      const roomName = (product?.room_name as string) || "-";

      const location = locationsList.find(
        (location) => location.id === locationId
      );

      const locationName = location?.name || "-";

      return [
        {
          key: "object_id",
          value: product.mapping_id,
          sortable: false,
        },
        {
          key: "id",
          value: product.id,
        },
        {
          key: "picture",
          value: {
            image:
              mappingImg && product.mapping_image?.length
                ? product.mapping_image
                : product.image,
            name: product.specification + " " + product.image,
          },
        },
        {
          key: "specification",
          value: product.specification,
        },
        {
          key: "obj_condition",
          value: {
            obj_condition: product.obj_condition,
            quantity: product.quantity,
          },
        },
        {
          key: "quantity",
          value: product?.quantity ?? "",
        },
        {
          key: "quantity_unit",
          value: handleTranslateQuantityUnit(product?.quantity_unit as string),
          sortable: true,
        },

        {
          key: "fk_location",
          value: locationName,
          sortable: true,
        },
        {
          key: "floor_name",
          value: floorName,
          sortable: true,
        },
        {
          key: "room_name",
          value: roomName,
          sortable: true,
        },
        {
          key: "parent_category",
          value: parentCategoryName,
          sortable: true,
        },
        {
          key: "category",
          value: categoryName,
          sortable: true,
        },
        {
          key: "element",
          value: elementName,
          sortable: true,
        },
        {
          key: "date_created",
          value: product.date_created
            ? format(new Date(product.date_created as string), "dd-MM-yyyy")
            : "-",
          sortable: true,
        },
        {
          key: "cost",
          value: product.cost,
        },
        {
          key: "co2",
          value: product.co2,
          sortable: true,
        },
        {
          key: "production_year",
          value: product.production_year || "-",
          sortable: true,
        },
        {
          key: "producer",
          value: product.producer || "-",
          sortable: true,
        },
        {
          key: "demountability_score",
          value: product.demountability_score || "-",
          sortable: true,
        },
        {
          key: "remaining_lifetime_score",
          value: product.remaining_lifetime_score || "-",
          sortable: true,
        },
        {
          key: "volume_score",
          value: product.volume_score || "-",
          sortable: true,
        },
        {
          key: "demand_score",
          value: product.demand_score || "-",
          sortable: true,
        },
        {
          key: "environmental_effect_score",
          value: product.environmental_effect_score || "-",
          sortable: true,
        },
        {
          key: "cost_use_score",
          value: product.cost_use_score || "-",
          sortable: true,
        },
      ] as IPageTableColumnData[];
    });

    return {
      columns: columnState,
      data: columnData,
      updateColumnVisibility,
      resetAllColumns,
    } as IPageTableContent;
  }, [
    projectProduct,
    columnState,
    updateColumnVisibility,
    resetAllColumns,
    getCategoryName,
    getParentCategoryName,
    getElementName,
    locationsList,
    mappingImg,
  ]);

  const objectTableTabs = useMemo(
    () => [
      {
        text: t("options.assessmentRating.all"),
        status: ASSESSMENT_RATINGS.ALL,
      },
      {
        text: t("options.assessmentRating.notRated"),
        status: ASSESSMENT_RATINGS.NOT_RATED,
      },
      {
        text: t("options.assessmentRating.suitable"),
        status: ASSESSMENT_RATINGS.SUITABLE,
      },
      {
        text: t("options.assessmentRating.internalUse"),
        status: ASSESSMENT_RATINGS.INTERNAL_USE,
      },
      {
        text: t("options.assessmentRating.externalUse"),
        status: ASSESSMENT_RATINGS.EXTERNAL_USE,
      },
      {
        text: t("options.assessmentRating.waste"),
        status: ASSESSMENT_RATINGS.WASTE,
      },
      {
        text: t("options.assessmentRating.archived"),
        status: ASSESSMENT_RATINGS.ARCHIVED,
      },
    ],
    []
  );

  const renderObjectAssessmentTableTitle = useCallback(
    (activeFilter: string) => {
      switch (activeFilter) {
        case t("options.assessmentRating.all"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.all"),
          });
        case t("options.assessmentRating.notRated"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.notRated"),
          });
        case t("options.assessmentRating.suitable"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.suitable"),
          });
        case t("options.assessmentRating.internalUse"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.internalUse"),
          });
        case t("options.assessmentRating.externalUse"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.externalUse"),
          });
        case t("options.assessmentRating.waste"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.waste"),
          });
        case t("options.assessmentRating.archived"):
          return t("pages.projectPreview.titleFilters", {
            filter: t("options.assessmentRating.archived"),
          });
        default:
          return "";
      }
    },
    []
  );

  return {
    objectTableColumns,
    objectTableTabs,
    columnState,
    renderObjectAssessmentTableTitle,
  };
};
