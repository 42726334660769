import { Image, StyleSheet, View } from "@react-pdf/renderer";

import DelioLogo from "../assets/DelioLogoName2.png";

const styles = StyleSheet.create({
  header: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  delioLogo: {
    width: 77,
    height: 24,
    marginLeft: 32,
  },
});

export const ReportHeader = () => {
  return (
    <View fixed style={styles.header}>
      <View style={styles.delioLogo}>
        <Image src={DelioLogo} />
      </View>
    </View>
  );
};
