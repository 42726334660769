import { FC } from "react";

import { RegisterWrapperProps } from "./index.props";
import "./styles.scss";

export const RegisterWrapper: FC<RegisterWrapperProps> = ({
  className,
  children,
}) => {
  return (
    <div className={`register-container ${className || ""}`}>{children}</div>
  );
};
