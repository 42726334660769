import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { Fragment, useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { IObjectMappings } from "../../../api/types";
import { OBJECT_STATUS } from "../../../contants/Enums";
import { handleTranslateQuantityUnit } from "../../../utils/index.utils";
import { ElementsCounter } from "../../counter";
import { Icon } from "../../icon/Icon";
import { Text } from "../../text";
import { ObjectStateInfoMapping } from "../object-state-info-mapping";
import { ObjectStatus } from "../object-status";
import { ObjectStateInfoProps } from "./index.props";
import "./styles.scss";

export function ObjectStateInfo({
  id,
  header,
  itemCount,
  onEdit,
  productLocationMappings,
  onAddMapping,
  onEditMapping,
  onDeleteMapping,
  locationFloors,
  onEditQuantity,
  conditionsData,
  isOpen,
  quantityUnit,
  onToggle,
}: ObjectStateInfoProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const handleAddOption = useCallback(() => {
    onAddMapping && onAddMapping();
  }, [onAddMapping]);

  const noRoomAndFloorsPlacement = useMemo(() => {
    return productLocationMappings?.filter(
      (x: IObjectMappings) =>
        (x.fk_floor === null && x.fk_room === null) ||
        !locationFloors.find((floor) => floor.id === x.fk_floor)
    );
  }, [productLocationMappings, locationFloors]);

  const openInitial = useRef(location.state?.featureId == id);

  useLayoutEffect(() => {
    onToggle?.(id, openInitial.current);
  }, [id, onToggle]);

  return (
    <details
      onToggle={(e) => {
        onToggle?.(id, e.currentTarget.open);
      }}
      open={isOpen}
      className="object-state-info"
    >
      <summary className="object-state-info__row object-state-info__row--title">
        <Text
          className="object-state-info__title"
          text={header}
          size="medium"
          fontWeight={700}
        />
        <Grow />
        <Text
          className="object-state-info__item-count"
          text={`${itemCount} ${handleTranslateQuantityUnit(
            quantityUnit as string
          )}`}
          size="xs"
          fontWeight={500}
        />
        <Icon name="chevron" className="chevron" />
      </summary>

      <div className="details-content">
        {!!noRoomAndFloorsPlacement?.length && (
          <>
            <Text
              className="object-state-info__floor-name"
              text={t("components.objectStateInfo.noPlacementTitle")}
              fontWeight={700}
              size="sm"
            />
            {noRoomAndFloorsPlacement.map((mapping) => (
              <ObjectStateInfoMapping
                key={mapping.uuid}
                mapping={mapping}
                onEditQuantity={onEditQuantity}
                onEdit={() => onEditMapping?.(mapping.uuid)}
                onDelete={() => onDeleteMapping?.(mapping.uuid)}
                quantityUnit={quantityUnit}
              />
            ))}
          </>
        )}
        {!conditionsData &&
          locationFloors
            .sort((a, b) => a.id - b.id)
            .map((floor, index) => {
              const floorMapping =
                productLocationMappings &&
                productLocationMappings
                  .filter((x) => x.fk_floor === floor.id)
                  .sort((a, b) => {
                    const objectStatusArray = [
                      ...Object.values(OBJECT_STATUS),
                      "",
                    ];
                    const orderA = objectStatusArray.indexOf(a.obj_condition);
                    const orderB = objectStatusArray.indexOf(b.obj_condition);
                    return (orderA || 0) - (orderB || 0);
                  });

              const noRoomMapping = floorMapping?.length
                ? floorMapping?.filter(
                    (x: IObjectMappings) =>
                      x.fk_room === null ||
                      !floor.room_list.length ||
                      !floor.room_list.find((room) => room.id === x.fk_room)
                  )
                : [];

              return (
                <Fragment key={`${floor.id}-${index}`}>
                  <Fragment>
                    {floorMapping?.length ? (
                      <Fragment>
                        {noRoomMapping?.length ? (
                          <Text
                            className="object-state-info__floor-name"
                            text={`${floor?.name}`}
                            fontWeight={700}
                            size="sm"
                          >
                            <hr />
                          </Text>
                        ) : null}
                        {noRoomMapping?.map((mapping) => (
                          <ObjectStateInfoMapping
                            key={mapping.uuid}
                            mapping={mapping}
                            onEditQuantity={onEditQuantity}
                            onEdit={() => onEditMapping?.(mapping.uuid)}
                            onDelete={() => onDeleteMapping?.(mapping.uuid)}
                            quantityUnit={quantityUnit}
                          />
                        ))}
                        {floor?.room_list.map((room) => {
                          const roomMapping = floorMapping?.filter(
                            (x: IObjectMappings) => x.fk_room === room.id
                          );

                          if (roomMapping?.length) {
                            return (
                              <Fragment key={room?.id}>
                                <Text
                                  className="object-state-info__room-name"
                                  text={`${room?.name}`}
                                  fontWeight={600}
                                  size="xs"
                                >
                                  <hr />
                                </Text>
                                {roomMapping.map((mapping) => (
                                  <ObjectStateInfoMapping
                                    key={mapping.uuid}
                                    className="object-state-info__row--rooms"
                                    mapping={mapping}
                                    onEditQuantity={onEditQuantity}
                                    onEdit={() => onEditMapping?.(mapping.uuid)}
                                    onDelete={() =>
                                      onDeleteMapping?.(mapping.uuid)
                                    }
                                    quantityUnit={quantityUnit}
                                  />
                                ))}
                              </Fragment>
                            );
                          }
                        })}
                      </Fragment>
                    ) : null}
                  </Fragment>
                </Fragment>
              );
            })}

        {conditionsData?.map((condition, index) => (
          <Fragment key={`${condition?.uuid}-${index}`}>
            <div className="object-state-info__row" key={condition.uuid}>
              <Row className="object-state-info__status-wrapper">
                {/*<TrashIcon />*/}
                <ObjectStatus
                  text={
                    condition?.obj_condition?.length
                      ? condition?.obj_condition
                      : t("options.condition.noCondition")
                  }
                  status={
                    condition?.obj_condition?.length
                      ? condition?.obj_condition
                      : OBJECT_STATUS.NO_CONDITION
                  }
                />
              </Row>
              <ElementsCounter
                mapId={condition.uuid}
                counter={condition?.quantity}
                onEditQuantity={onEditQuantity}
                quantityUnit={quantityUnit}
              />
            </div>
          </Fragment>
        ))}
      </div>
      <Row className="bottom-toolbar" wrap>
        <button className="btn light" onClick={onEdit}>
          <Icon name="edit" />
          {t("common.edit")}
        </button>
        <button className="btn" onClick={handleAddOption}>
          <Icon name="add" />
          {t("common.addFloorMapping")}
        </button>
      </Row>
    </details>
  );
}
