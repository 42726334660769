import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { LoadingMask } from "../components/loading/LoadingMask";
import { dashboardUrl, selectOrganisationUrl } from "../contants/Routes";
import { AppUserContext } from "../context/user-context/UserContext";

export function UnprotectedRoute({
  children,
}: {
  children: JSX.Element;
  redirectPath?: string;
}) {
  const { userProfile, userOrganisationType, userProfileLoading } =
    useContext(AppUserContext);

  if (userProfileLoading) {
    return <LoadingMask />;
  }

  if (userProfile?.uuid) {
    if (userOrganisationType) {
      return <Navigate to={dashboardUrl} replace />;
    } else {
      return <Navigate to={selectOrganisationUrl} replace />;
    }
  }

  return children;
}
