import React, { FC } from "react";

const BackArrowIcon: FC = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 5.5C11.7761 5.5 12 5.27614 12 5C12 4.72386 11.7761 4.5 11.5 4.5L1.80298 4.5L5.83448 0.871651C6.03974 0.686921 6.05638 0.370777 5.87165 0.165522C5.68692 -0.039733 5.37077 -0.0563723 5.16552 0.128357L0.165518 4.62835C0.0601606 4.72317 6.19579e-09 4.85826 0 5C-6.19579e-09 5.14174 0.0601606 5.27683 0.165518 5.37165L5.16552 9.87164C5.37077 10.0564 5.68692 10.0397 5.87165 9.83448C6.05638 9.62922 6.03974 9.31308 5.83448 9.12835L1.80298 5.5L11.5 5.5Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default BackArrowIcon;
