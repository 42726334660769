import { FC } from "react";

import { FeatureModalWrapperProps } from "./index.props";
import "./styles.scss";

export const FeatureModalWrapper: FC<FeatureModalWrapperProps> = ({
  children,
  classNames,
}) => {
  return (
    <div className={`feature-modal-wrapper ${classNames ? classNames : ""}`}>
      {children}
    </div>
  );
};
