import React, { FC } from "react";

const CheckmarkIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7538_131252)">
        <path
          d="M7.49996 9.99996L9.16663 11.6666L12.9166 7.91663M6.11143 3.18221C6.78129 3.12875 7.41722 2.86534 7.92868 2.42948C9.12223 1.41234 10.8777 1.41234 12.0712 2.42948C12.5827 2.86534 13.2186 3.12875 13.8885 3.18221C15.4517 3.30695 16.693 4.54825 16.8177 6.11143C16.8712 6.78129 17.1346 7.41722 17.5704 7.92868C18.5876 9.12223 18.5876 10.8777 17.5704 12.0712C17.1346 12.5827 16.8712 13.2186 16.8177 13.8885C16.693 15.4517 15.4517 16.693 13.8885 16.8177C13.2186 16.8712 12.5827 17.1346 12.0712 17.5704C10.8777 18.5876 9.12223 18.5876 7.92868 17.5704C7.41722 17.1346 6.78129 16.8712 6.11143 16.8177C4.54825 16.693 3.30695 15.4517 3.18221 13.8885C3.12875 13.2186 2.86534 12.5827 2.42948 12.0712C1.41234 10.8777 1.41234 9.12223 2.42948 7.92868C2.86534 7.41722 3.12875 6.78129 3.18221 6.11143C3.30695 4.54825 4.54825 3.30695 6.11143 3.18221Z"
          stroke="#039855"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7538_131252">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckmarkIcon;
