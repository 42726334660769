import React, { FC } from "react";

const HamburgerIcon: FC = () => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.752568 12.9997H19.2526C19.6668 12.9997 20.0026 13.3355 20.0026 13.7497C20.0026 14.1294 19.7204 14.4432 19.3543 14.4928L19.2526 14.4997H0.752568C0.338355 14.4997 0.00256824 14.1639 0.00256824 13.7497C0.00256824 13.37 0.284722 13.0562 0.650798 13.0065L0.752568 12.9997H19.2526H0.752568ZM0.752568 6.5027H19.2526C19.6668 6.5027 20.0026 6.83848 20.0026 7.2527C20.0026 7.63239 19.7204 7.94619 19.3543 7.99585L19.2526 8.0027H0.752568C0.338355 8.0027 0.00256824 7.66691 0.00256824 7.2527C0.00256824 6.873 0.284722 6.55921 0.650798 6.50954L0.752568 6.5027H19.2526H0.752568ZM0.751709 0.00292969H19.2517C19.6659 0.00292969 20.0017 0.338716 20.0017 0.75293C20.0017 1.13263 19.7196 1.44642 19.3535 1.49608L19.2517 1.50293H0.751709C0.337495 1.50293 0.00170898 1.16714 0.00170898 0.75293C0.00170898 0.373234 0.283863 0.0594385 0.649939 0.00977612L0.751709 0.00292969H19.2517H0.751709Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default HamburgerIcon;
