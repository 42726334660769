import Icon from "@avinet/adaptive-ui-core/ui/Icon";

import { Text } from "../../text";
import { FeatureModalHeaderProps } from "./index.props";
import "./styles.scss";

export function FeatureModalHeader({
  modalState,
  title,
  subtitle,
  onClose,
  noBorder,
}: FeatureModalHeaderProps) {
  return (
    <div
      className={`modal-header ${noBorder ? "modal-header--no-border" : ""}`}
    >
      <div className="modal-header-icons">
        <div className="modal-header-text">
          <Text
            text={title ?? ""}
            fontWeight={600}
            size="lg"
            className="modal-header-text-main"
          />
          {subtitle && (
            <Text
              text={subtitle ?? ""}
              size="sm"
              className="modal-header-text-secondary"
            />
          )}
        </div>

        <button
          onClick={onClose ? onClose : () => modalState?.(false)}
          className="close-icon"
        >
          <Icon name="dismiss" />
        </button>
      </div>
    </div>
  );
}
