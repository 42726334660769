import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { LoadingMask } from "../components/loading/LoadingMask";
import { SessionExpireModal } from "../components/session-expire-modal/SessionExpireModal";
import LOCAL_STORAGE from "../contants/LocalStorage";
import { baseUrl } from "../contants/Routes";
import { AppUserContext } from "../context/user-context/UserContext";

export function ProtectedRoute({
  children,
  redirectPath = baseUrl,
}: {
  children: JSX.Element;
  redirectPath?: string;
}) {
  const { isLogin } = useContext(AppUserContext);

  const user_store = localStorage.getItem(LOCAL_STORAGE.USER);

  const userObject = JSON.parse(user_store ?? "{}");

  const expiration_time = userObject.expiration_time;

  if (user_store && expiration_time < new Date().getTime() / 1000) {
    return <SessionExpireModal />;
  }

  if (isLogin === undefined) {
    return <LoadingMask />;
  }

  if (!isLogin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}
