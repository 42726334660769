import React from "react";
import Skeleton from "react-loading-skeleton";

import "../styles.scss";

export const OrganisationBoxSkeleton = () => {
  return (
    <div className="organisation-box-container">
      <div className="organisation-box">
        <div className="organisation-box__top">
          <div className="organisation-box__top__text">
            <Skeleton
              width={40}
              height={40}
              borderRadius={100}
              className="organisation-box__image"
            />
            <Skeleton
              width={180}
              height={14}
              borderRadius={8}
              className="organisation-box__title organisation-box__title--skeleton"
            />
          </div>
        </div>
        <Skeleton
          width="90%"
          height={12}
          borderRadius={8}
          className="organisation-box__description organisation-box__description--skeleton"
        />
      </div>
    </div>
  );
};
