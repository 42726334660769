import { t } from "i18next";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { ILocationProperties, IProjectProperties } from "../../api/types";
import PlusIcon from "../../assets/icons/plus/PlusIcon";
import { ProjectModal } from "../../components/add-new-feature/project/ProjectModal";
import { FeatureDeleteModal } from "../../components/feature-delete-modal/FeatureDeleteModal";
import { ProjectFilterModal } from "../../components/filter-modal/ProjectFilterModal";
import { PageHeader } from "../../components/page/page-header";
import { PageSidebar } from "../../components/page/page-sidebar";
import { PageTable } from "../../components/page/page-table/PageTable";
import { PageTitle } from "../../components/page/page-title";
import { PageWrapper } from "../../components/page/page-wrapper";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { dashboardUrl, projectsUrl } from "../../contants/Routes";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import {
  FilterContext,
  IProjectFilter,
} from "../../context/filter-context/FilterContext";
import { useProjectFilter } from "../../hooks/useProjectFilter";
import { useProjectTableColumns } from "../../hooks/useProjectTableColumns";
import { useSelectOptions } from "../../hooks/useSelectOptions";

export function Projects() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { projectsList, downloadSelectFeature, downloadGenericFeature } =
    useContext(FeatureContext);
  const { projectFilter, handleProjectFilter } = useContext(FilterContext);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const navigate = useNavigate();
  const [selectedTableItem, setSelectedTableItem] =
    useState<IProjectProperties | null>(null);
  const [multipleItems, setMultipleItems] = useState<number[]>([]);
  const routes = [
    { url: dashboardUrl, title: t("common.home") },
    { url: projectsUrl, title: t("pages.projects.title") },
  ];
  const { projectTableColumns, projectTableTabs, renderProjectTableTitle } =
    useProjectTableColumns(projectsList);

  const { projectStatus } = useSelectOptions();

  const handleProjectModal = useCallback((state: boolean) => {
    setShowProjectModal(state);
  }, []);

  const filterString = useProjectFilter(projectFilter);
  const filterActive = !!filterString;

  const handleAttachmentModal = useCallback(
    (refreshAttachments: boolean) => {
      setShowAttachmentModal((prev) => !prev);
      if (refreshAttachments) {
        downloadGenericFeature(FEATURE_UUID.PROJECT);
      }
    },
    [downloadGenericFeature]
  );

  const setProject = useCallback(
    (id: string | number, connectedLocations?: ILocationProperties[]) => {
      const selectedItem = projectsList?.find((project) => project.id === id);
      const location_list = connectedLocations
        ?.map((location) => location.id)
        .filter(Boolean) as number[] | undefined;

      setSelectedTableItem(
        selectedItem ? { ...selectedItem, location_list } : null
      );
    },
    [projectsList]
  );

  const handleRecordEdit = useCallback(
    async (id: string | number) => {
      let connectedLocations = undefined;
      await downloadGenericFeature(
        FEATURE_UUID.PROJECT_LOCATIONS,
        `fk_project=${Number(id)}`
      ).then((res) => {
        if (res !== null) {
          connectedLocations = res;
        }
      });

      setProject(id, connectedLocations);
      setShowProjectModal(true);
    },
    [setProject, downloadGenericFeature]
  );

  const handleRecordPreview = useCallback(
    async (id: string | number) => {
      let connectedLocations = undefined;
      await downloadGenericFeature(
        FEATURE_UUID.PROJECT_LOCATIONS,
        `fk_project=${Number(id)}`
      ).then((res) => {
        if (res !== null) {
          connectedLocations = res;
        }
      });

      if (selectedTableItem?.id === id) {
        setIsSidebarVisible((prev) => !prev);
      } else {
        setProject(id, connectedLocations);
        setIsSidebarVisible(true);
      }
    },
    [downloadGenericFeature, setProject, selectedTableItem]
  );

  const handleRecordSelect = useCallback(
    (id: string | number) => {
      navigate(String(id));
    },
    [navigate]
  );

  const handleSideBarEdit = useCallback(() => {
    if (selectedTableItem?.id !== undefined)
      handleRecordEdit(selectedTableItem?.id);
  }, [handleRecordEdit, selectedTableItem?.id]);

  const handleNewProject = useCallback(() => {
    handleProjectModal(true);
    setSelectedTableItem(null);
  }, [handleProjectModal]);

  const handleFilterModal = useCallback(() => {
    setShowFilterModal((prev) => !prev);
  }, []);

  const handleApplyFilter = useCallback(
    (filter: IProjectFilter) => {
      handleProjectFilter(filter);
      handleFilterModal;
    },
    [handleFilterModal, handleProjectFilter]
  );

  const handleDeleteModal = useCallback(
    (id?: string | number | number[]) => {
      if (Array.isArray(id)) {
        const selectedItems = id?.filter((item) => !isNaN(Number(item)));
        setMultipleItems(selectedItems);
      } else {
        const selectedItem = projectsList?.find((project) => project.id === id);
        setSelectedTableItem(selectedItem ?? null);
      }

      setShowDeleteModal((prev) => !prev);
    },
    [projectsList, multipleItems]
  );

  useLayoutEffect(() => {
    console.log(filterString);
    downloadGenericFeature(
      FEATURE_UUID.PROJECT,
      filterString,
      null,
      projectFilter?.limit,
      projectFilter?.offset,
      projectFilter?.sort_order,
      projectFilter?.sort_column
    );
    downloadSelectFeature(FEATURE_UUID.LOCATION);
  }, [
    downloadGenericFeature,
    downloadSelectFeature,
    filterString,
    projectFilter,
  ]);

  return (
    <SidebarPage>
      <PageWrapper className="pagewrapper-wrapper--table">
        <PageHeader routes={routes} />
        <PageTitle
          title={t("pages.projects.title")}
          description={t("pages.projects.description")}
        >
          <button
            className="btn locations-title-button"
            onClick={handleNewProject}
          >
            <PlusIcon />
            <span>{t("components.sidebarAddNew.project.title")}</span>
          </button>
        </PageTitle>

        <PageTable
          title={t("components.pageTable.project")}
          subtitle={t("components.pageTable.projectsCount")}
          tabs={projectTableTabs}
          columns={projectTableColumns}
          featureUUid={FEATURE_UUID.PROJECT}
          onRecordSelect={handleRecordSelect}
          onRecordEdit={handleRecordEdit}
          onRecordDelete={handleDeleteModal}
          onRecordPreview={handleRecordPreview}
          statusOptions={projectStatus}
          noFeatureHandler={handleNewProject}
          onFilterClick={handleFilterModal}
          featurePreviewFilter={filterString}
          filterActive={filterActive}
          tableHeaderText={renderProjectTableTitle}
        />

        <PageSidebar
          type={FEATURE_TYPE.PROJECT}
          isSidebarVisible={isSidebarVisible}
          onClose={() => setIsSidebarVisible(false)}
          setEditMode={handleSideBarEdit}
          featureDetails={selectedTableItem}
        />
      </PageWrapper>
      {showProjectModal && (
        <ProjectModal
          projectDetails={selectedTableItem}
          showProjectModal={showProjectModal}
          modalState={handleProjectModal}
          attachmentModal={showAttachmentModal}
          setAttachmentModal={handleAttachmentModal}
        />
      )}
      {showFilterModal && (
        <ProjectFilterModal
          onClose={handleFilterModal}
          projectFilter={projectFilter}
          onApplyFilter={handleApplyFilter}
          showFilterModal={showFilterModal}
        />
      )}
      {showDeleteModal && (multipleItems?.length || selectedTableItem?.id) && (
        <FeatureDeleteModal
          multipleFeatures={multipleItems}
          onClose={() => {
            handleDeleteModal();
            setMultipleItems([]);
          }}
          feature={selectedTableItem}
          featureType={FEATURE_TYPE.PROJECT}
        />
      )}
    </SidebarPage>
  );
}
