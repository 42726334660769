import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { loginUrl } from "../../contants/Routes";
import { AppUserContext } from "../../context/user-context/UserContext";
import { Icon } from "../icon/Icon";
import { Modal } from "../modal";
import { Text } from "../text";
import "./SessionExpireModal.scss";

export const SessionExpireModal = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AppUserContext);
  const navigate = useNavigate();

  const handleLoginButton = useCallback(() => {
    logout();
    navigate(loginUrl);
  }, [logout, navigate]);

  return (
    <Modal className="feature-modal" show>
      <div className="feature-modal-container session-expire-modal-container">
        <div className="icon-circle">
          <div className="icon-wrapper">
            <Icon name="timer" className="icon icon--rejected" />
          </div>
        </div>
        <Text
          text={t("components.sessionExpireModal.title")}
          size="medium"
          color="dark"
          fontWeight={600}
        />
        <Text
          text={t("components.sessionExpireModal.description1")}
          size="small"
        />
        <Text
          text={t("components.sessionExpireModal.description2")}
          size="small"
        />
        <Text
          text={t("components.sessionExpireModal.description3")}
          size="small"
        />
        <button className="btn btn-close" onClick={handleLoginButton}>
          {t("common.login")}
        </button>
      </div>
    </Modal>
  );
};
