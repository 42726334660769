import Switch from "@avinet/adaptive-ui-core/ui/Switch";
import { t } from "i18next";
import React, { useCallback } from "react";

import { useCheckMobile } from "../../../../hooks/useCheckMobile";
import { Icon } from "../../../icon/Icon";
import { PopupMenu } from "../../../popup-menu/PopupMenu";
import { PopupMenuButton } from "../../../popup-menu/PopupMenuButton";
import { TableSortButton } from "../../../table/table-sort-button/TableSortButton";
import { Text } from "../../../text";
import { IPageTableColumnMeta, PageTableHeadWrapperProps } from "./index.props";
import "./styles.scss";

export function PageTableHeadWrapper({
  title,
  subtitle,
  totalLocationsNumber,
  columnsButtonDisabled,
  columns,
  handleSelectColumn,
  resetAllColumns,
  resetSortColumns,
  gridMode,
  handleSetGridMode,
  handleSortItem,
  activeHeader,
  sortOrder,
  onFilterClick,
  filterActive,
}: PageTableHeadWrapperProps) {
  const { isMobile } = useCheckMobile();

  return (
    <div className="pagetable-head-wrapper">
      <div className="pagetable-head-text-wrapper">
        <span className="pagetable-head-title">{title}</span>
        <span className="pagetable-head-description">
          {`${totalLocationsNumber || 0} ${subtitle}`}
        </span>
      </div>
      <div className="pagetable-head-wrapper__buttons">
        {!isMobile && !gridMode && columns && (
          <ColumnsButton
            columns={columns.columns}
            onColumnSelect={handleSelectColumn}
            resetAllColumns={resetAllColumns}
            disabled={columnsButtonDisabled}
          />
        )}
        {!!onFilterClick && columns && (
          <button
            className={"btn light " + (filterActive ? "filter-active" : "")}
            onClick={onFilterClick}
          >
            <Icon name="filter" />
            {t("components.pageTable.filter")}
          </button>
        )}
        {!isMobile && (
          <button
            className="btn light btn-grid"
            onClick={() => handleSetGridMode(!gridMode)}
          >
            {t("components.pageTable.grid")}
            <Switch value={gridMode} onChange={handleSetGridMode} />
          </button>
        )}
        {columns && (
          <TableSortButton
            columns={columns.columns}
            handleSortItem={handleSortItem}
            resetAllColumns={resetSortColumns}
            disabled={columnsButtonDisabled}
            activeHeader={activeHeader}
            sortOrder={sortOrder}
          />
        )}
      </div>
    </div>
  );
}

function ColumnsButton({
  columns,
  onColumnSelect,
  resetAllColumns,
  disabled,
}: {
  columns: IPageTableColumnMeta[];
  onColumnSelect: (key: string) => void;
  resetAllColumns: () => void;
  disabled?: boolean;
}) {
  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      resetAllColumns();
    },
    [resetAllColumns]
  );

  const preventPropagation = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <PopupMenuButton
      icon="columns"
      className="btn light"
      disabled={disabled}
      menu={
        <PopupMenu
          className="colums-selector-popup"
          fullScreenOn={{ maxWidth: 450, maxHeight: 830 }}
        >
          <div className="columns-modal-content" onClick={preventPropagation}>
            <div className="header">
              <h4>{t("components.columnChoose.title")}</h4>
              <Text tag="p" text={t("components.columnChoose.description")} />
            </div>
            <div className="columnchoose-wrapper">
              {columns.map((element: IPageTableColumnMeta) => (
                <button
                  id={element.key}
                  className="aui-check-box column-checkbox"
                  key={element.key}
                  onClick={(e) => {
                    e.stopPropagation();
                    onColumnSelect(element.key);
                  }}
                >
                  <Icon
                    name="checkboxCheckmark"
                    className={
                      "checkmark " + (element.active ? " checked" : "")
                    }
                  />
                  {element.title}
                </button>
              ))}
            </div>
            <button className="btn-link reset" onClick={handleReset}>
              <Icon name="arrowReset" className="icon" />
              {t("components.columnChoose.reset")}
            </button>
          </div>
        </PopupMenu>
      }
    >
      {t("components.pageTable.columns")}
    </PopupMenuButton>
  );
}
