import Column from "@avinet/adaptive-ui-core/layout/Column";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";

import { FeatureContext } from "../../context/feature-context/FeatureContext";
import { Text } from "../text";
import { ColumnDescriptionProps } from "./ColumnDescription.props";
import "./ColumnDescription.scss";

export function ColumnDescription({
  className,
  label,
  text,
  small,
}: ColumnDescriptionProps) {
  const { featureLoader } = useContext(FeatureContext);

  return (
    <Column className={`column-description ${className}`}>
      <Text fontWeight={500} size={small ? "xs" : "small"} text={label} />
      {featureLoader ? (
        <Skeleton
          width={120}
          height={16}
          borderRadius={4}
          className="help-question-skeleton-title"
        />
      ) : (
        <>
          {typeof text === "string" ? (
            <Text fontWeight={700} size={small ? "xs" : "medium"} text={text} />
          ) : (
            text
          )}
        </>
      )}
    </Column>
  );
}
