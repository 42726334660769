import {
  ChevronLeft28Filled,
  ChevronRight28Filled,
  FullScreenMaximize20Regular,
} from "@fluentui/react-icons";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperRefType } from "swiper/types";

import { IFullscreenModalData, sliderData } from "../../api/types";
import { FullscreenImgModal } from "../fullscreen-img-modal/FullscreenImgModal";
import { Text } from "../text";
import { SliderPreviewProps } from "./index.props";
import "./styles.scss";

export const SliderPreview: FC<SliderPreviewProps> = ({
  sliderItems,
  className,
}) => {
  const { t } = useTranslation();
  const [slide, setSlide] = useState<number>(0);
  const [loop, setLoop] = useState(false);
  const [swiperPreviewRef, setPreviewSwiperRef] = useState<SwiperRefType>();
  const [showFullscreenModal, setShowFullscreenModal] =
    useState<IFullscreenModalData>({
      showModal: false,
      sliderData: sliderItems,
    });
  const handleDecreaseIndex = useCallback(() => {
    slide !== undefined && swiperPreviewRef?.slidePrev();
  }, [swiperPreviewRef, slide]);

  const handleIncreaseIndex = useCallback(() => {
    slide !== undefined && swiperPreviewRef?.slideNext();
  }, [swiperPreviewRef, slide]);

  const handleSlide = useCallback(
    (s: SwiperRefType) => {
      slide !== undefined && setSlide(isNaN(s.realIndex) ? 0 : s.realIndex);
    },
    [slide]
  );

  useEffect(() => {
    const loadImage = (image: sliderData) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.src;
        loadImg.onload = () => resolve(image.src);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(sliderItems.map((image) => loadImage(image))).catch((err) =>
      console.log("Failed to load images", err)
    );
  }, [sliderItems]);

  return (
    <>
      <div className="slider-preview img-hover">
        <div>
          {sliderItems?.length && (
            <Text
              className="slider-preview__img-amount"
              text={t("components.sliderPreview.images", {
                slide: Number(slide) + 1,
                amount: sliderItems?.length,
              })}
              fontWeight={500}
              size="xs"
            />
          )}
        </div>
        {sliderItems && sliderItems?.length > 1 && (
          <button
            className="slider-preview__btn slider-preview__btn--left"
            onClick={handleDecreaseIndex}
          >
            <ChevronLeft28Filled />
            <Text
              className="slider-preview__btn__text slider-preview__btn--left--text"
              text={t("common.prev")}
              fontWeight={600}
              size="sm"
            />
          </button>
        )}
        <button
          className="slider-preview__fullscreen"
          onClick={() =>
            setShowFullscreenModal({
              ...showFullscreenModal,
              showModal: true,
            })
          }
        >
          <FullScreenMaximize20Regular />
          <Text
            className="slider-preview__fullscreen__text"
            text={t("common.fullscreen")}
            fontWeight={600}
            size="sm"
            tag="span"
          />
        </button>
        <Swiper
          onSwiper={setPreviewSwiperRef}
          slidesPerView={1}
          onSlideChange={handleSlide}
          onInit={() => setTimeout(() => setLoop(true), 300)}
          centeredSlides
          initialSlide={0}
          loop={loop}
        >
          {sliderItems?.map((img) => (
            <SwiperSlide key={img.src}>
              <img alt={img?.alt} src={img?.src} className={className} />
            </SwiperSlide>
          ))}
        </Swiper>
        {sliderItems && sliderItems?.length > 1 && (
          <button
            className="slider-preview__btn slider-preview__btn--right"
            onClick={handleIncreaseIndex}
          >
            <Text
              className="slider-preview__btn__text slider-preview__btn--right--text"
              text={t("common.next")}
              fontWeight={600}
              size="sm"
            />
            <ChevronRight28Filled />
          </button>
        )}
      </div>
      {showFullscreenModal.showModal && (
        <FullscreenImgModal
          sliderItems={showFullscreenModal.sliderData}
          showModal={showFullscreenModal.showModal}
          setShowModal={() =>
            setShowFullscreenModal({
              sliderData: sliderItems,
              showModal: false,
            })
          }
        />
      )}
    </>
  );
};
