import { FC } from "react";

import { setScoreColor } from "../../utils/index.utils";
import { Text } from "../text";
import { TotalScoreProps } from "./TotalScore.props";
import "./TotalScore.scss";

export const TotalScore: FC<TotalScoreProps> = ({ small, score }) => {
  const totalScoreMaxValue = 30;

  return (
    <div
      className={`total-score ${
        small ? "total-score--small" : ""
      } ${setScoreColor(score)}`}
    >
      <Text text={`${score}/${totalScoreMaxValue}`} fontWeight={700} />
    </div>
  );
};
