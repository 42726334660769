import Row from "@avinet/adaptive-ui-core/layout/Row";
import React, { FC } from "react";

import { HeadingProps } from "./index.props";
import "./styles.scss";

export const Heading: FC<HeadingProps> = ({
  title,
  description,
  small,
  tooltip,
  children,
}) => {
  return (
    <Row className={`heading ${small ? "heading__small" : ""}`}>
      <div className="heading__text">
        <h3>
          {title}
          {tooltip && <p className="heading__tooltip">{tooltip}</p>}
        </h3>
        {description && <h6>{description}</h6>}
      </div>
      {children && children}
    </Row>
  );
};
