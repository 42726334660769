/* eslint-disable */
import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import * as Types from "../api/types";
import { Api } from "./api/api";

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async registerUser(
    body: Types.RegisterRequest,
    token: void | string
  ): Promise<Types.RegisterResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "Auth/Register",
      {
        token,
        email: body.email,
        password: body.password,
        userData: {
          first_name: body.name,
          last_name: body.surname,
        },
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async loginUser(body: Types.LoginRequest): Promise<Types.LoginResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "Auth/Authenticate",
      {
        email: body.email.toLowerCase(),
        password: body.password,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async verifyBegin(
    body: Types.VerifyBeginRequest
  ): Promise<Types.DefaultApiResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "Auth/VerifyBegin",
      {
        email: body.email.toLowerCase(),
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async verify(body: Types.VerifyRequest): Promise<Types.VerifyApiResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "Auth/Verify",
      {
        email: body.email.toLowerCase(),
        code: body.code,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async changePassword(
    body: Types.ChangePasswordRequest
  ): Promise<Types.DefaultApiResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "Auth/ChangePassword",
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async resetPassword(
    body: Types.ResetPasswordRequest
  ): Promise<Types.DefaultApiResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "Auth/ResetPassword",
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getUserData(): Promise<Types.UserDataResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      "Profile/me"
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async updateUserData(
    body: Types.IUserDetails,
    token?: void | string
  ): Promise<Types.UserUpdateResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      "Profile/me",
      token
        ? {
            ...body,
            email: body.email?.toLowerCase(),
            token,
          }
        : body
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async updateUserImage(body: File): Promise<Types.UserUpdateResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      "Profile/me/image",
      body
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async deleteUserAccount(): Promise<Types.UserUpdateResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      "Profile/me"
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }
}
/* eslint-enable */
