import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input, { InputEl } from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useActiveMenu } from "react-active-menu";

import { KIND_RESPONSE } from "../../../api/api-problem";
import {
  AddLocationRequest,
  AddLocationResponse,
  IFloors,
} from "../../../api/types";
import PlusIcon from "../../../assets/icons/plus/PlusIcon";
import { FEATURE_TYPE } from "../../../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../../../contants/FeatureUuid";
import { useNotificationApi } from "../../../context/NotificationProvider";
import { FeatureContext } from "../../../context/feature-context/FeatureContext";
import { useAttachmentEdit } from "../../../hooks/useAttachmentEdit";
import { useAttachments } from "../../../hooks/useAttachments";
import { useCheckMobile } from "../../../hooks/useCheckMobile";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { useTranslationPath } from "../../../hooks/useTranslationPath";
import theme from "../../../theme";
import { AttachmentModal } from "../../attachment-modal/AttachmentModal";
import { FormSectionLinksModalAside } from "../../feature/feature-modal-aside";
import { FeatureModalCreated } from "../../feature/feature-modal-created";
import { FeatureModalHeader } from "../../feature/feature-modal-header";
import { FeatureMainTitle } from "../../feature/feature-modal-main-title";
import { FeatureModalWrapper } from "../../feature/feature-modal-wrapper";
import { FileDropzone } from "../../file-dropzone";
import { InputSelect } from "../../input-select/InputSelect";
import { Modal } from "../../modal";
import { RadioGroup } from "../../radio-group/RadioGroup";
import { StepDots } from "../../step-dots";
import { SubmitButtons } from "../../submit-buttons";
import { Tag } from "../../tag";
import "../styles.scss";
import { locationAside } from "./LocationModal.const";
import { LocationModalProps } from "./LocationModal.props";
import "./LocationModal.scss";
import { LocationFloorsEditor } from "./location-add-floors/LocationFloorsEditor";

export type additionalRenovationsProps = {
  index: number;
  date: string;
};

enum LOCATION_STEPS {
  FORM_STEP = 1,
  FLOORS_STEP = 2,
  LAST_STEP = 3,
}
const stepsArr = [
  LOCATION_STEPS.FORM_STEP,
  LOCATION_STEPS.FLOORS_STEP,
  LOCATION_STEPS.LAST_STEP,
];

export function LocationModal({
  onClose,
  locationDetails,
  handleAddNewComponent,
}: LocationModalProps) {
  const [error, setError] = useState<string>("");
  const { addNewLocation, editLocation, updateFloors } =
    useContext(FeatureContext);
  const [additionalRenovations, setAdditionalRenovations] = useState<
    additionalRenovationsProps[]
  >([]);
  const { isMobile } = useCheckMobile();

  const [modalStep, setModalStep] = useState(LOCATION_STEPS.FORM_STEP);
  const [expandDate, setExpandDate] = useState(false);
  const [renovationsDate, setRenovationsDate] = useState("");
  const expandCount = isMobile ? 3 : 5;
  const [createdLocationId, setCreatedLocationId] = useState<number | null>(
    locationDetails?.id || null
  );

  const { getLocationFloor } = useContext(FeatureContext);
  const { buildStatuses, buildCategories } = useSelectOptions();
  const { notificationDialog } = useNotificationApi();
  const tCommon = useTranslationPath("common");
  const tCancel = useTranslationPath("components.objectModal.confirmCancel");
  const [floorState, setFloorState] = useState<IFloors[]>([]);

  const { floorsAbove, floorsUnder } = useMemo(
    () => ({
      floorsAbove: floorState
        .filter((floor) => floor.sequence_id >= 0)
        .sort((a, b) => b.sequence_id - a.sequence_id),
      floorsUnder: floorState
        .filter((floor) => floor.sequence_id < 0)
        .sort((a, b) => b.sequence_id - a.sequence_id),
    }),
    [floorState]
  );

  useEffect(() => {
    const locationId = locationDetails?.id;

    if (!locationId) return;

    getLocationFloor(locationId).then((res) => {
      if (!res || res.kind !== "ok") return;
      setFloorState(res.data);
    });
  }, [getLocationFloor, locationDetails?.id]);

  const formLabels = {
    buildingStatus: t("components.locationModal.form.labels.buildingStatus"),
    buildingCategory: t(
      "components.locationModal.form.labels.buildingCategory"
    ),
    name: t("components.locationModal.form.labels.name"),
    address: t("components.locationModal.form.labels.address"),
    buildingOwner: t("components.locationModal.form.labels.buildingOwner"),
    mainMaterial: t("components.locationModal.form.labels.mainMaterial"),
    grossFloorArea: t("components.locationModal.form.labels.grossFloorArea"),
    yearOfConstruction: t(
      "components.locationModal.form.labels.yearOfConstruction"
    ),
    yearAndTypeOfRenovations: t(
      "components.locationModal.form.labels.yearAndTypeOfRenovations"
    ),
    demolitionDate: t("components.locationModal.form.labels.demolitionDate"),
    comment: t("components.locationModal.form.labels.comment"),
    planned: t("components.locationModal.form.labels.planned"),
    estimated: t("components.locationModal.form.labels.estimated"),
    notSelected: t("components.locationModal.form.labels.notSelected"),
    description: t("components.locationModal.form.labels.description"),
    kartlagtFra: t("components.locationModal.form.labels.kartlagtFra"),
    kartlagtTil: t("components.locationModal.form.labels.kartlagtTil"),
    kartlagtAv: t("components.locationModal.form.labels.kartlagtAv"),
  };

  const formPlaceholders = {
    buildingStatus: t(
      "components.locationModal.form.placeholders.buildingStatus"
    ),
    buildingCategory: t(
      "components.locationModal.form.placeholders.buildingCategory"
    ),
    name: t("components.locationModal.form.placeholders.name"),
    address: t("components.locationModal.form.placeholders.address"),
    gnr: t("form.gnr"),
    county: t("form.county"),
    municipality: t("form.municipality"),
    buildingOwner: t(
      "components.locationModal.form.placeholders.buildingOwner"
    ),
    mainMaterial: t("components.locationModal.form.placeholders.mainMaterial"),
    grossFloorArea: t(
      "components.locationModal.form.placeholders.grossFloorArea"
    ),
    yearOfConstruction: t(
      "components.locationModal.form.placeholders.yearOfConstruction"
    ),
    yearAndTypeOfRenovations: t(
      "components.locationModal.form.placeholders.yearAndTypeOfRenovations"
    ),
    demolitionDate: t(
      "components.locationModal.form.placeholders.demolitionDate"
    ),
    comment: t("components.locationModal.form.placeholders.comment"),
    notSelected: t("components.locationModal.form.labels.notSelected"),
    description: t("components.locationModal.form.placeholders.description"),
    kartlagtFra: t("components.locationModal.form.placeholders.kartlagtFra"),
    kartlagtTil: t("components.locationModal.form.placeholders.kartlagtTil"),
    kartlagtAv: t("components.locationModal.form.placeholders.kartlagtAv"),
  };

  const headerProps = {
    title: locationDetails
      ? t("components.locationModal.headerMainEdit")
      : t("components.locationModal.headerMain"),
    subtitle: locationDetails
      ? t("components.locationModal.headerSecondaryEdit")
      : t("components.locationModal.headerSecondary"),
  };

  const headerFloorProps = {
    title: locationDetails
      ? t("components.locationModal.headerMainFloorsEdit")
      : t("components.locationModal.headerMainFloors"),
    subtitle: t("components.locationModal.headerSecondaryFloors"),
  };

  const headerCreatedProps = {
    title: null,
    subtitle: null,
  };

  const { activeId, registerContainer, registerSection, registerTrigger } =
    useActiveMenu({
      smooth: true,
      offset: 30,
    });

  const { postOrUpdateMedia } = useAttachments();

  const {
    attachmentsState,
    handleDropImage,
    showAttachmentModal,
    openAttachmentModal,
    closeAttachmentModal,
    handleRemoveDropImage,
    attachmentThumbnail,
    setAttachmentsState,
  } = useAttachmentEdit(FEATURE_MEDIA_TYPE.LOCATION, locationDetails?.id);

  const handleAddLocation = useCallback(
    (data: AddLocationRequest) => {
      if (createdLocationId) {
        editLocation(data, createdLocationId).then((res) => {
          if (res && res.kind === KIND_RESPONSE.OK) {
            if (res.data.id) {
              postOrUpdateMedia(
                attachmentsState,
                FEATURE_MEDIA_TYPE.LOCATION,
                res.data.id
              );

              setModalStep(LOCATION_STEPS.FLOORS_STEP);
            }
          } else {
            setError(t("errors.unexpectedError") ?? "");
          }
        });
      } else {
        addNewLocation(data).then((res: AddLocationResponse | void) => {
          if (res && res.kind && res.kind === KIND_RESPONSE.OK) {
            if (res.data.id) {
              setCreatedLocationId(res.data.id);
              postOrUpdateMedia(
                attachmentsState,
                FEATURE_MEDIA_TYPE.LOCATION,
                res.data.id
              );

              setModalStep(LOCATION_STEPS.FLOORS_STEP);
            }
          } else {
            setError(t("errors.unexpectedError") ?? "");
          }
        });
      }
    },
    [
      createdLocationId,
      editLocation,
      postOrUpdateMedia,
      attachmentsState,
      addNewLocation,
    ]
  );

  const defaultValues = useMemo(() => {
    return {
      ...locationDetails,
      building_status: locationDetails?.status,
      county: locationDetails?.fylke,
      municipality: locationDetails?.kommune,
      building_owner: locationDetails?.owner,
      gross_floor_area: locationDetails?.area?.toString(),
      year_of_construction: locationDetails?.building_year?.toString(),
      year_and_type_of_renovations: locationDetails?.rehab_year,
      image: locationDetails?.image ?? "",
      comment: locationDetails?.comments,
      kartlagt_fra: locationDetails?.kartlagt_fra,
      kartlagt_til: locationDetails?.kartlagt_til,
      kartlagt_av: locationDetails?.kartlagt_av,
    };
  }, [locationDetails]);

  const { form, state, isDirty, setDirty } = useForm({
    defaultValues: defaultValues,
    onSubmit: handleAddLocation,
  });

  useEffect(() => {
    if (!locationDetails?.id) return;
    setCreatedLocationId(locationDetails.id);
  }, [locationDetails]);

  const handleRemoveRenovationDate = (elementIndex: number) => {
    const arrIndex = additionalRenovations.filter(
      (el) => el.index !== elementIndex
    );

    setAdditionalRenovations(arrIndex);
  };

  const handleExpandDates = () => {
    setExpandDate((prev) => !prev);
  };

  const handleFormClose = useCallback(async () => {
    if (isDirty) {
      const choices = await notificationDialog({
        title: tCancel("title"),
        message: tCancel("message"),
        icon: "warning",
        variant: "danger",
        choices: [
          {
            label: tCommon("keep"),
            value: "keep",
          },
          {
            label: tCommon("discard"),
            value: "discard",
            primaryOption: true,
          },
        ],
      });

      if (choices === "keep") {
        return;
      }
    }

    onClose(false);
  }, [isDirty, notificationDialog, onClose, tCancel, tCommon]);

  const resetFields = useCallback(() => {
    setCreatedLocationId(null);
    form.setValue("image", "");
    form.setValue("address", "");
    form.setValue("gnrbnr", "");
    form.setValue("building_status", "");
    form.setValue("building_status", "");
    form.setValue("county", "");
    form.setValue("municipality", "");
    form.setValue("building_owner", "");
    form.setValue("main_material_use", "");
    form.setValue("gross_floor_area", "");
    form.setValue("year_of_construction", "");
    form.setValue("year_and_type_of_renovations", "");
    form.setValue("comment", "");
    form.setValue("name", "");
    form.setValue("description", "");
    form.setValue("kartlagt_fra", "");
    form.setValue("kartlagt_til", "");
    form.setValue("kartlagt_av", "");
    setAttachmentsState([]);
  }, [form, setAttachmentsState]);

  const handleClose = useCallback(
    (reset?: boolean) => {
      !reset && onClose(false);

      if (reset) {
        resetFields();
        setModalStep(LOCATION_STEPS.FORM_STEP);
      }
    },
    [onClose, resetFields]
  );

  const handleRenderAddRenovationDateButton = useCallback(() => {
    const validYearExp = new RegExp("^\\d{4}$");

    return (
      <button
        className="feature-modal-date-wrapper-button"
        disabled={!validYearExp.test(renovationsDate)}
        type="button"
        onClick={() => {
          setAdditionalRenovations((prev) => [
            ...prev,
            {
              index: prev.length
                ? Math.max(...prev.map((el) => el.index)) + 1
                : 0,
              date: state.year_and_type_of_renovations,
            },
          ]),
            setRenovationsDate("");
        }}
      >
        <PlusIcon customColor={theme.primary} width={16} /> {t("common.add")}
      </button>
    );
  }, [state, renovationsDate]);

  const handleChangeRenovationDate = useCallback(
    (value: string) => {
      setRenovationsDate(value);
      state.year_and_type_of_renovations = value;
    },
    [state]
  );

  const handleUpdateFloors = useCallback(() => {
    if (createdLocationId === null) return;

    // Update sequence_id for floors
    const floorsArr = [
      ...floorsAbove.map((floor, i) => ({
        ...floor,
        sequence_id: floorsAbove.length - i,
      })),
      ...floorsUnder.map((floor, i) => ({ ...floor, sequence_id: -(i + 1) })),
    ];

    // Update sequence_id for rooms and set default names
    const defaultValuesArr = floorsArr.map((floor) => ({
      ...floor,
      name:
        floor.name ||
        t("components.locationModal.floorCount", {
          count: floor.sequence_id,
        }),
      room_list: floor.room_list.map((room, roomIndex) => ({
        ...room,
        sequence_id: roomIndex + 1,
        name:
          room.name ||
          t("components.locationModal.room", {
            count: room.sequence_id,
          }),
      })),
    }));

    updateFloors(defaultValuesArr, createdLocationId).then((res) => {
      if (res && res.kind && res.kind === KIND_RESPONSE.OK) {
        setModalStep(LOCATION_STEPS.LAST_STEP);
        setError("");
      } else {
        setError(t("errors.unexpectedError") ?? "");
      }
    });
  }, [createdLocationId, floorsAbove, floorsUnder, updateFloors]);

  useEffect(() => {
    state.additional_renovations = additionalRenovations;
  }, [additionalRenovations, state, locationDetails]);

  const demolitionItems = useMemo(
    () => [
      { value: "", label: formLabels.notSelected },
      { value: "Planned", label: formLabels.planned },
      { value: "Estimated", label: formLabels.estimated },
    ],
    [formLabels.estimated, formLabels.notSelected, formLabels.planned]
  );

  return (
    <>
      <Modal className="feature-modal" show>
        <div
          className={`${
            modalStep === LOCATION_STEPS.LAST_STEP
              ? "feature-modal-container feature-modal-container--created"
              : "feature-modal-container"
          }`}
        >
          {modalStep === LOCATION_STEPS.FORM_STEP && (
            <FeatureModalHeader
              modalState={onClose}
              onClose={handleFormClose}
              {...headerProps}
            />
          )}

          {modalStep === LOCATION_STEPS.FLOORS_STEP && (
            <FeatureModalHeader
              modalState={onClose}
              onClose={handleClose}
              {...headerFloorProps}
            />
          )}

          {modalStep === LOCATION_STEPS.LAST_STEP && (
            <FeatureModalHeader
              modalState={onClose}
              onClose={handleClose}
              noBorder
              {...headerCreatedProps}
            />
          )}

          <FormProvider form={form} state={state}>
            {modalStep === LOCATION_STEPS.FORM_STEP && (
              <div className="form-and-links-container">
                <form
                  className="location-editor-form form"
                  id="location-editor-form"
                  onSubmit={form.submit}
                  ref={registerContainer}
                >
                  <FeatureModalWrapper>
                    <section
                      className="feature-modal-drop"
                      ref={registerSection("attachments")}
                      id="attachments"
                    >
                      <FileDropzone
                        onDrop={handleDropImage}
                        attachmentButtonClick={openAttachmentModal}
                        setDirty={setDirty}
                        attachmentsCount={attachmentsState.length}
                        text={t("components.locationModal.asideAttachments")}
                        maxFiles={20}
                        attachmentPreview
                        thumbnailMedia={attachmentThumbnail}
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper">
                    <section ref={registerSection("general")}>
                      <FeatureMainTitle
                        text={t("components.locationModal.general")}
                      />
                      <Input
                        id="address"
                        type="text"
                        minLength={5}
                        required
                        label={formLabels.address}
                        placeholder={formPlaceholders.address}
                      />
                      <div className="feature-modal-main-wrapper__input-row">
                        <Input
                          id="gnrbnr"
                          pattern="^\d{1,4}/\d{1,4}$"
                          required
                          label={formPlaceholders.gnr}
                          placeholder={formPlaceholders.gnr}
                        />
                        <Input
                          id="county"
                          type="text"
                          minLength={3}
                          required
                          label={formPlaceholders.county}
                          placeholder={formPlaceholders.county}
                        />
                        <Input
                          id="municipality"
                          type="text"
                          minLength={3}
                          required
                          label={formPlaceholders.municipality}
                          placeholder={formPlaceholders.municipality}
                        />
                      </div>
                      <InputSelect
                        id="building_status"
                        items={buildStatuses}
                        label={formLabels.buildingStatus}
                        placeholder={formPlaceholders.buildingStatus}
                        required
                      />
                      <Input
                        id="name"
                        type="text"
                        minLength={2}
                        required
                        label={formLabels.name}
                        placeholder={formPlaceholders.name}
                      />

                      <Input
                        id="description"
                        type="textarea"
                        label={formLabels.description}
                        placeholder={formPlaceholders.description}
                      />

                      <Input
                        id="building_owner"
                        type="text"
                        minLength={2}
                        label={formLabels.buildingOwner}
                        placeholder={formPlaceholders.buildingOwner}
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper">
                    <section ref={registerSection("building")}>
                      <FeatureMainTitle
                        text={t("components.locationModal.building")}
                      />

                      <InputSelect
                        id="building_category"
                        items={buildCategories}
                        label={formLabels.buildingCategory}
                        placeholder={formPlaceholders.buildingCategory}
                      />

                      <Input
                        id="main_material"
                        type="text"
                        minLength={2}
                        label={formLabels.mainMaterial}
                        placeholder={formPlaceholders.mainMaterial}
                      />

                      <Input
                        id="gross_floor_area"
                        type="text"
                        minLength={2}
                        label={formLabels.grossFloorArea}
                        placeholder={formPlaceholders.grossFloorArea}
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper">
                    <section ref={registerSection("mapping")}>
                      <FeatureMainTitle
                        text={t("components.locationModal.mapping")}
                      />

                      <Input
                        type="date"
                        id="kartlagt_fra"
                        max="2999-12-31"
                        label={formLabels.kartlagtFra}
                        placeholder={formPlaceholders.kartlagtFra}
                      />

                      <Input
                        type="date"
                        id="kartlagt_til"
                        max="2999-12-31"
                        label={formLabels.kartlagtTil}
                        placeholder={formPlaceholders.kartlagtTil}
                      />
                      <Input
                        id="kartlagt_av"
                        type="text"
                        minLength={2}
                        label={formLabels.kartlagtAv}
                        placeholder={formPlaceholders.kartlagtAv}
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper">
                    <section ref={registerSection("lifecycle")}>
                      <FeatureMainTitle
                        text={t("components.locationModal.lifecycle")}
                      />
                      <Input
                        id="year_of_construction"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]{4}"
                        minLength={4}
                        maxLength={4}
                        label={formLabels.yearOfConstruction}
                        placeholder={formPlaceholders.yearOfConstruction}
                      />
                      <div className="aui-form-control aui-text">
                        <div className="feature-modal-date-wrapper">
                          <InputEl
                            className="feature-modal-date-wrapper-input"
                            id="year_and_type_of_renovations"
                            type="text"
                            minLength={4}
                            maxLength={4}
                            label={formLabels.yearAndTypeOfRenovations}
                            placeholder={
                              formPlaceholders.yearAndTypeOfRenovations
                            }
                            pattern="^-?[0-9]\d*\.?\d*$"
                            value={renovationsDate}
                            onChange={(event) =>
                              handleChangeRenovationDate(event)
                            }
                          />
                          {handleRenderAddRenovationDateButton()}
                        </div>
                      </div>
                      <div
                        className={`feature-modal-container__tag-wrapper ${
                          expandDate
                            ? "feature-modal-container__tag-wrapper--expand"
                            : ""
                        }`}
                      >
                        {additionalRenovations.map((element, index) => (
                          <>
                            {expandDate ? (
                              <span key={`${element.date}-${index}`}>
                                <Tag
                                  text={element.date}
                                  onClick={() =>
                                    handleRemoveRenovationDate(element.index)
                                  }
                                />
                              </span>
                            ) : (
                              <>
                                {index <= expandCount ? (
                                  <span key={`${element.date}-${index}`}>
                                    <Tag
                                      text={element.date}
                                      onClick={() =>
                                        handleRemoveRenovationDate(
                                          element.index
                                        )
                                      }
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </>
                        ))}
                        {!expandDate &&
                          additionalRenovations.length > expandCount + 1 && (
                            <Tag
                              text={`+${
                                additionalRenovations.length - expandCount - 1
                              }`}
                              onClick={() => handleExpandDates()}
                            />
                          )}
                      </div>

                      <Input
                        type="date"
                        id="demolition_date"
                        max="2999-12-31"
                        label={formLabels.demolitionDate}
                        placeholder={formPlaceholders.demolitionDate}
                      />

                      <RadioGroup
                        id="type_of_demolition"
                        items={demolitionItems}
                        className="feature-modal-radio-wrapper"
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper">
                    <section ref={registerSection("additionalInfo")}>
                      <FeatureMainTitle
                        text={t("components.locationModal.additionalInfo")}
                      />
                      <Input
                        id="comment"
                        type="textarea"
                        label={formLabels.comment}
                        placeholder={formPlaceholders.comment}
                      />
                    </section>
                  </FeatureModalWrapper>
                </form>
                <FormSectionLinksModalAside
                  data={locationAside}
                  active={activeId}
                  trigger={registerTrigger}
                />
              </div>
            )}

            {modalStep === LOCATION_STEPS.FLOORS_STEP && (
              <LocationFloorsEditor
                floorsAbove={floorsAbove}
                floorsUnder={floorsUnder}
                setFloorState={setFloorState}
              />
            )}

            <FeatureModalCreated
              className={`${
                modalStep === LOCATION_STEPS.LAST_STEP
                  ? ""
                  : "feature-modal--hidden"
              }`}
              editMode={locationDetails ? !!locationDetails.id : false}
              featureType={FEATURE_TYPE.LOCATION}
              createdFeatureId={createdLocationId}
              resetForm={handleClose}
              handleNewComponent={handleAddNewComponent}
            />
            <StepDots
              isVisible={modalStep !== LOCATION_STEPS.LAST_STEP}
              activeStep={modalStep}
              steps={stepsArr}
            />
            {error && <span className="feature-modal__error">{error}</span>}

            {modalStep === LOCATION_STEPS.FORM_STEP && (
              <SubmitButtons
                onCancel={handleFormClose}
                submitText={t("common.next")}
                formId="location-editor-form"
              />
            )}

            {modalStep === LOCATION_STEPS.FLOORS_STEP && (
              <SubmitButtons
                onCancel={() => setModalStep(LOCATION_STEPS.FORM_STEP)}
                onSubmit={handleUpdateFloors}
                cancelText={t("common.prev")}
                submitText={t("common.next")}
              />
            )}
          </FormProvider>
        </div>
      </Modal>
      {showAttachmentModal && (
        <AttachmentModal
          onClose={closeAttachmentModal}
          attachments={attachmentsState}
          setDirty={setDirty}
          onDropImage={handleDropImage}
          handleRemoveDropImage={handleRemoveDropImage}
        />
      )}
    </>
  );
}
