import React, { FC } from "react";

import { SidebarPage } from "../../components/sidebar/sidebar-page";

export const MapPage: FC = () => {
  return (
    <SidebarPage>
      <p>Welcome to map page</p>
    </SidebarPage>
  );
};
