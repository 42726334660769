import React, { FC } from "react";

const HelpIcon: FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0ZM20 2.5C10.335 2.5 2.5 10.335 2.5 20C2.5 29.665 10.335 37.5 20 37.5C29.665 37.5 37.5 29.665 37.5 20C37.5 10.335 29.665 2.5 20 2.5ZM20.25 28C21.0784 28 21.75 28.6716 21.75 29.5C21.75 30.3284 21.0784 31 20.25 31C19.4216 31 18.75 30.3284 18.75 29.5C18.75 28.6716 19.4216 28 20.25 28ZM20.25 9C23.6147 9 26.5 11.8821 26.5 15.2488C26.502 17.3691 25.7314 18.7192 23.8216 20.7772L22.8066 21.8638C21.7842 23.0028 21.3794 23.7252 21.3409 24.5793L21.3379 24.7411L21.3323 24.8689L21.3143 24.9932C21.2018 25.5636 20.7009 25.9957 20.0968 26.0001C19.4065 26.0049 18.8428 25.4493 18.8379 24.7589C18.8251 22.9703 19.5147 21.7467 21.1461 19.9739L22.1734 18.8762C23.5312 17.3837 24.0012 16.503 24 15.25C24 13.2634 22.2346 11.5 20.25 11.5C18.3307 11.5 16.6142 13.1536 16.5055 15.0587L16.4935 15.3778C16.4295 16.0081 15.8972 16.5 15.25 16.5C14.5596 16.5 14 15.9404 14 15.25C14 11.8846 16.8864 9 20.25 9Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default HelpIcon;
