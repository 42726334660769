import {
  ArrowDownload20Regular,
  ChevronLeft28Filled,
  ChevronRight28Filled,
} from "@fluentui/react-icons";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperRefType } from "swiper/types";

import { sliderData } from "../../api/types";
import CloseIcon from "../../assets/icons/close/CloseIcon";
import { Modal } from "../modal";
import { Text } from "../text";
import { FullscreenImgModalProps } from "./FullscreenImgModal.props";
import "./FullscreenImgModal.styles.scss";

export function FullscreenImgModal({
  showModal,
  setShowModal,
  sliderItems,
}: FullscreenImgModalProps) {
  const { t } = useTranslation();
  const [slide, setSlide] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperRefType>();

  const handleDecreaseIndex = useCallback(() => {
    slide !== undefined && swiperRef?.slidePrev();
  }, [swiperRef, slide]);

  const handleIncreaseIndex = useCallback(() => {
    slide !== undefined && swiperRef?.slideNext();
  }, [swiperRef, slide]);

  const handleSlide = useCallback(
    (s: SwiperRefType) => {
      slide !== undefined && setSlide(isNaN(s.realIndex) ? 0 : s.realIndex);
    },
    [slide]
  );

  const downloadFile = (url: string, name: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      });
  };

  const renderSlides = useCallback(() => {
    return sliderItems?.map((img) => (
      <SwiperSlide key={img.src}>
        <img alt={img?.alt} src={img?.src} />
      </SwiperSlide>
    ));
  }, [sliderItems]);

  useEffect(() => {
    const loadImage = (image: sliderData) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.src;
        loadImg.onload = () => resolve(image.src);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(sliderItems.map((image) => loadImage(image))).catch((err) =>
      console.log("Failed to load images", err)
    );
  }, [sliderItems]);

  return (
    <Modal className="fullscreen-modal" show={showModal}>
      <div className="fullscreen-modal-wrapper">
        <div className="fullscreen-modal__top">
          <div>
            {sliderItems?.length && (
              <Text
                className="slider-preview__img-amount"
                text={t("components.sliderPreview.images", {
                  slide: Number(slide) + 1,
                  amount: sliderItems?.length,
                })}
                fontWeight={500}
                size="xs"
              />
            )}
          </div>
          <div className="fullscreen-modal__top__buttons">
            <button
              className="btn light"
              onClick={() =>
                downloadFile(
                  sliderItems?.[slide]?.src,
                  sliderItems?.[slide]?.alt
                )
              }
            >
              <ArrowDownload20Regular />
              <Text
                className=""
                text={t("common.download")}
                fontWeight={600}
                size="sm"
                tag="span"
              />
            </button>
            <button className="btn light" onClick={setShowModal}>
              <CloseIcon />
              <Text
                className=""
                text={t("common.close")}
                fontWeight={600}
                size="sm"
                tag="span"
              />
            </button>
          </div>
        </div>
        <div className="fullscreen-modal__content">
          {sliderItems && sliderItems?.length > 1 && (
            <div
              className="fullscreen-modal__content__btn-wrapper"
              onClick={handleDecreaseIndex}
              aria-disabled={sliderItems.length === 1}
            >
              <button className="fullscreen-modal__content__btn fullscreen-modal__slider-button fullscreen-modal__slider-button--left">
                <ChevronLeft28Filled />
              </button>
            </div>
          )}
          <div
            className={`fullscreen-modal__content__slider ${
              sliderItems && sliderItems?.length <= 1
                ? "fullscreen-modal__content__slider--full-width"
                : ""
            }`}
          >
            <Swiper
              onSwiper={setSwiperRef}
              autoplay
              slidesPerView={1}
              onSlideChange={handleSlide}
              centeredSlides
              loop
              className="fullscreen-modal__content__slider"
              observer
              observeParents
            >
              {renderSlides()}
            </Swiper>
          </div>
          {sliderItems && sliderItems?.length > 1 && (
            <div
              className="fullscreen-modal__content__btn-wrapper"
              onClick={handleIncreaseIndex}
              aria-disabled={sliderItems.length === 1}
            >
              <button className="fullscreen-modal__content__btn fullscreen-modal__slider-button fullscreen-modal__slider-button--right">
                <ChevronRight28Filled />
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
