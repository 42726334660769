import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import React, { FC, useState } from "react";

import HelpIcon from "../../assets/icons/help/HelpIcon";
import { InputTooltipProps } from "./index.props";
import "./styles.scss";

export const InputTooltip: FC<InputTooltipProps> = ({
  id,
  type = "text",
  label,
  placeholder,
  required,
  className,
  tooltipMessage,
  maxLength,
  minLength,
  onPaste,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={`tooltip-input ${className ? className : ""}*`}>
      <Input
        id={id}
        type={type}
        label={label}
        placeholder={placeholder}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        onPaste={onPaste}
      />
      <div
        className="tooltip-input__icon"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <HelpIcon />
      </div>
      <p
        className={`tooltip-input__message ${
          showTooltip ? "tooltip-input__message--show" : ""
        }`}
      >
        {tooltipMessage}
      </p>
    </div>
  );
};
