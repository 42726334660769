const API_URL = process.env.REACT_APP_URL || "https://ombruk-qa.avinet.no/api";
const WEBSITE_URL =
  process.env.REACT_APP_WEBSITE_URL || "https://ombruk-qa.avinet.no/";

export interface ApiConfig {
  url: string;
  webUrl: string;
  timeout: number;
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  webUrl: WEBSITE_URL,
  timeout: 60000,
};
