import { t } from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { KIND_RESPONSE } from "../../api/api-problem";
import {
  AddUserToOrganisationResponse,
  IOrgMemberData,
  UpdateUserRoleRequest,
} from "../../api/types";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import useTable, { sliceData } from "../../hooks/useTable";
import { BatchSelect } from "../batch-select/BatchSelect";
import { Checkbox } from "../checkbox";
import { Icon } from "../icon/Icon";
import { Pagination } from "../pagination";
import { ProfileModal } from "../profile/profile-modal";
import { TableProps } from "./index.props";
import "./styles.scss";
import TableRow from "./table-row";
import TableRowSkeleton from "./table-row/table-row-skeleton";

const Table = ({
  data,
  dataLoading,
  rowsPerPage,
  onSubmitDeleteModal,
  updateMembers,
  isAdmin,
  amountOfAdmins,
  amountOfMembers,
}: TableProps) => {
  const { t } = useTranslation();
  const paginationOffset = 5;
  const [page, setPage] = useState(0);
  const { slice } = useTable(data, page, rowsPerPage);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showBatchDeleteModal, setShowBatchDeleteModal] = useState(false);

  const { selectedOrganisation, updateUserRole } =
    useContext(OrganisationContext);

  const handleSelectUser = useCallback((uuid: string) => {
    setSelectedUsers((prev) => {
      if (prev.includes(uuid)) {
        return prev.filter((item) => item !== uuid);
      }
      return [...prev, uuid];
    });
  }, []);

  useEffect(() => {
    // -1 to exclude self
    if (selectedUsers.length === slice.length - 1) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers, slice]);

  const handleSelectAllUsers = useCallback(() => {
    setSelectAll(!selectAll);
    if (selectedUsers.length === slice.length - 1) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(slice.slice(1).map((item) => item.uuid));
    }
  }, [selectAll, selectedUsers, slice]);

  const handleRemoveOrChangeRole = useCallback(
    (userData: UpdateUserRoleRequest) => {
      updateUserRole(userData)
        .then((res: AddUserToOrganisationResponse | void) => {
          if (userData.new_role === "Remove") {
            onSubmitDeleteModal && onSubmitDeleteModal();
          }
          updateMembers && updateMembers();
          if (res && res.kind) {
            if (res.kind === KIND_RESPONSE.OK) {
              return res?.data;
            }
          }
        })
        .catch(() => {
          console.error(data);
        });
    },
    [data, onSubmitDeleteModal, updateMembers, updateUserRole]
  );

  const handleBatchDeleteUsers = useCallback(
    (userIds: string[]) => {
      for (let i = 0; i < userIds.length; i++) {
        const object = {
          uuid: userIds[i],
          org_id: selectedOrganisation?.id,
          new_role: "Remove",
        };
        updateUserRole(object).then(
          (res: AddUserToOrganisationResponse | void) => {
            setShowBatchDeleteModal(false);
            onSubmitDeleteModal && onSubmitDeleteModal();
            updateMembers && updateMembers();
            if (res && res.kind) {
              if (res.kind === KIND_RESPONSE.OK) {
                return res?.data;
              }
            }
          }
        );
      }
      setSelectedUsers([]);
    },
    [
      onSubmitDeleteModal,
      selectedOrganisation?.id,
      updateMembers,
      updateUserRole,
    ]
  );

  const selectedUserEmails = selectedUsers.map((user) => {
    const userObject = data.find((item) => item.uuid === user);
    return userObject?.email;
  });

  const removeUserDesc =
    selectedUserEmails.length === 1
      ? t("components.removeUserModal.descriptionOne", {
          userEmail: selectedUserEmails,
          orgName: selectedOrganisation?.name,
        })
      : t("components.removeUserModal.descriptionOneMultiple", {
          userEmails: selectedUserEmails?.join(", "),
          orgName: selectedOrganisation?.name,
        });

  const calculatePaging = useCallback(
    (val: number) => {
      const result = val / paginationOffset;

      return setPage(result);
    },
    [paginationOffset]
  );

  return (
    <>
      <table className="table">
        <thead className="table__header">
          <tr>
            <th className="table__header__text table--60">
              {isAdmin && slice.length > 1 ? (
                <Checkbox
                  className="checkbox--gray"
                  id="test"
                  title={t("form.name")}
                  value={selectAll}
                  onChange={handleSelectAllUsers}
                />
              ) : (
                t("form.name")
              )}
            </th>
            <th className="table__header__text table--40">{t("form.role")}</th>
          </tr>
        </thead>
        <tbody className={"table__body"}>
          {dataLoading ? (
            <>
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
              <TableRowSkeleton />
            </>
          ) : (
            <>
              {slice.map((element: IOrgMemberData, index: number) => (
                <TableRow
                  key={`${element?.uuid}-${index}`}
                  element={element}
                  handleSelectItem={() => handleSelectUser(element?.uuid)}
                  onSubmitDeleteModal={onSubmitDeleteModal}
                  handleRemoveOrChangeRole={handleRemoveOrChangeRole}
                  selectedElements={selectedUsers}
                  isAdmin={isAdmin}
                  amountOfAdmins={amountOfAdmins}
                  amountOfMembers={amountOfMembers}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
      {selectedUsers.length > 0 && (
        <BatchSelect
          count={selectedUsers.length}
          onClose={() => setSelectedUsers([])}
        >
          <button
            className="btn"
            onClick={() => {
              setShowBatchDeleteModal(true);
            }}
          >
            <Icon name="delete" />
            {t("common.delete")}
          </button>
        </BatchSelect>
      )}
      <ProfileModal
        deleteMode
        iconName="delete"
        title={
          selectedUserEmails.length === 1
            ? t("components.removeUserModal.title")
            : t("components.removeUserModal.titleMultiple")
        }
        description=""
        showModal={showBatchDeleteModal}
        cancelButtonClick={() => setShowBatchDeleteModal(false)}
        submitButtonClick={() => handleBatchDeleteUsers(selectedUsers)}
        submitButtonText={
          selectedUserEmails.length === 1
            ? t("common.removeUser")
            : t("common.removeUsers")
        }
        className="profile-policy__delete-modal"
      >
        <p className="profile-policy__delete-modal__text">{removeUserDesc}</p>
        <p className="profile-policy__delete-modal__text">
          {selectedUserEmails.length === 1
            ? t("components.removeUserModal.descriptionTwo")
            : t("components.removeUserModal.descriptionTwoMultiple")}
        </p>
        <p className="profile-policy__delete-modal__text">
          {selectedUserEmails.length === 1
            ? t("components.removeUserModal.descriptionThree")
            : t("components.removeUserModal.descriptionThreeMultiple")}
        </p>
      </ProfileModal>
      <div className="table__pagination-wrapper">
        <Pagination
          offset={page * paginationOffset}
          limit={rowsPerPage}
          total={data.length}
          onPaging={(val) => calculatePaging(val)}
        />
      </div>
    </>
  );
};

export default Table;
