import { FC, useCallback } from "react";

import { FileIcon } from "../../assets/icons/file/FileIcon";
import { DocIcon } from "../../assets/icons/file/doc/DocIcon";
import { PdfIcon } from "../../assets/icons/file/pdf/PdfIcon";
import { PptIcon } from "../../assets/icons/file/ppt/PptIcon";
import { XlsIcon } from "../../assets/icons/file/xls/XlsIcon";
import { NoImagePlaceholderIcon } from "../../assets/icons/no-image-placeholder/NoImagePlaceholderIcon";
import { FILE_TYPE } from "../../contants/Enums";
import { NoImagePlaceholerProps } from "./index.props";
import "./styles.scss";

export const NoImagePlaceholer: FC<NoImagePlaceholerProps> = ({
  className,
  type,
  typeIconsBig,
}) => {
  const iconsSize = typeIconsBig ? 55 : 36;

  const renderIcons = useCallback(() => {
    switch (type) {
      case FILE_TYPE.IMAGE:
        return <NoImagePlaceholderIcon />;
      case FILE_TYPE.FILE:
        return <FileIcon />;
      case FILE_TYPE.DOC:
        return <DocIcon width={iconsSize} height={iconsSize} />;
      case FILE_TYPE.XLS:
        return <XlsIcon width={iconsSize} height={iconsSize} />;
      case FILE_TYPE.PPT:
        return <PptIcon width={iconsSize} height={iconsSize} />;
      case FILE_TYPE.PDF:
        return <PdfIcon width={iconsSize} height={iconsSize} />;

      default:
        return <NoImagePlaceholderIcon />;
    }
  }, [type, iconsSize]);

  return (
    <div className={`no-image-placeholder ${className ? className : ""}`}>
      {renderIcons()}
    </div>
  );
};
