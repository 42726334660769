import { FC } from "react";

import { ErrorMessageProps } from "./index.props";
import "./styles.scss";

export const ErrorMessage: FC<ErrorMessageProps> = ({ text, className }) => {
  return (
    <p
      className={`error-message ${className ? className : ""} ${
        text.length > 0 ? "error-message--visible" : ""
      }`}
    >
      {text || ""}
    </p>
  );
};
