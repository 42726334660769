import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { OrganizationDetailsContent } from "../../components/organisation/organization-details";
import { SidebarPage } from "../../components/sidebar/sidebar-page";

export const OrganizationDetails: FC = () => {
  const location = useLocation();

  return (
    <SidebarPage noSidebar={location?.state?.withPrevButton}>
      <OrganizationDetailsContent />
    </SidebarPage>
  );
};
