import i18next from "i18next";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import { IPrivacyConsentConfigRecord } from "../../api/types";
import { PrivacyConsent } from "../../components/privacy-consent/PrivacyConsent";
import { loginUrl, termsAndConditionsUrl } from "../../contants/Routes";
import { PrivacyConsentApi } from "../../services/PrivacyConsentApi";
import { IContext } from "../ContextInterfaces";
import { AppUserContext } from "../user-context/UserContext";

export const PrivacyConsentContext = createContext({
  checkPrivacyConsent: () => {
    return;
  },
});

export function PrivacyConsentContextProvider({
  children,
  environment,
}: IContext) {
  const { registerUserData } = useContext(AppUserContext);
  const locationPath = window.location.pathname;
  const currentLang = i18next.language;
  const [policyToApprove, setPolicyToApprove] =
    useState<IPrivacyConsentConfigRecord>();

  const loadPrivacyConsentConfig = useCallback(async () => {
    const response = await new PrivacyConsentApi(
      environment.api
    ).loadPrivacyConsentConfig();

    if (response.kind === KIND_RESPONSE.OK) {
      return response.data;
    }
  }, [environment]);

  const loadUserPrivacyConsent = useCallback(async () => {
    const response = await new PrivacyConsentApi(
      environment.api
    ).loadUserPrivacyConsent();

    if (response.kind === KIND_RESPONSE.OK) {
      return response.data;
    }
  }, [environment]);

  const onAccept = useCallback(
    async (version: number) => {
      const response = await new PrivacyConsentApi(environment.api).accept({
        version: version,
        category: "privacy",
      });

      if (response.kind === "ok" && response.data.d.success) {
        setPolicyToApprove(undefined);
      }
    },
    [environment.api]
  );

  const checkPrivacyConsent = useCallback(async () => {
    const config = await loadPrivacyConsentConfig();
    const consent = await loadUserPrivacyConsent();

    const registerConsent = registerUserData.value?.consents;
    const appVersion = config?.d.records.privacy_policy_version;
    const userVersion = consent?.d.records.find(
      (consent) => consent.category === "privacy"
    )?.accepted_version;

    if (registerConsent && appVersion !== undefined) {
      onAccept(appVersion);
      return;
    }

    if (
      userVersion === undefined ||
      appVersion === undefined ||
      userVersion !== appVersion
    ) {
      setPolicyToApprove(config?.d.records);
    }
  }, [
    loadPrivacyConsentConfig,
    loadUserPrivacyConsent,
    onAccept,
    registerUserData.value?.consents,
  ]);

  const hideInPath = useMemo(() => {
    return (
      locationPath.includes(termsAndConditionsUrl) ||
      locationPath.includes(loginUrl)
    );
  }, [locationPath]);

  useEffect(() => {
    checkPrivacyConsent();
  }, [checkPrivacyConsent]);

  const message =
    currentLang === "no"
      ? policyToApprove?.privacy_short_nb
      : policyToApprove?.privacy_short_en;

  return (
    <PrivacyConsentContext.Provider value={{ checkPrivacyConsent }}>
      {policyToApprove && !hideInPath && (
        <PrivacyConsent
          message={message}
          version={policyToApprove.privacy_policy_version}
          onAccept={onAccept}
        />
      )}
      {children}
    </PrivacyConsentContext.Provider>
  );
}
