import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingMask } from "../../components/loading/LoadingMask";
import { DictionaryContext } from "../../context/dictionary-context/DictionaryContext";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../context/user-context/UserContext";
import { ReportDocument } from "../ReportDocument";
import { IReportData } from "../ReportTypes";

export const ReportGenerator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  if (!location.state) {
    navigate(-1);
  }

  const {
    reportData,
  }: {
    reportData: IReportData;
  } = location.state;
  const { token } = useContext(AppUserContext);

  const [initialized, setInitialized] = useState(false);
  const { getCategoryName, getParentCategoryName, getElementName } =
    useContext(DictionaryContext);
  const { selectedOrganisation } = useContext(OrganisationContext);

  const performPrint = useCallback(
    (reportData: IReportData) => {
      //the "pdf" function does not have access to contexts, so we need to pass in the necessary functions
      const contextFunctions = {
        getCategoryName,
        getParentCategoryName,
        getElementName,
      };
      pdf(
        <ReportDocument
          organisationData={selectedOrganisation}
          reportData={reportData}
          token={token}
          contextFunctions={contextFunctions}
        />
      )
        .toBlob()
        .then((blob) => {
          FileSaver.saveAs(blob, reportData.reportName);
          navigate("/prosjekter/" + reportData.projectDetails?.id, {});
        });
    },
    [
      getCategoryName,
      getParentCategoryName,
      getElementName,
      token,
      navigate,
      selectedOrganisation,
    ]
  );

  useEffect(() => {
    if (!initialized && reportData) {
      setInitialized(true);
      performPrint(reportData);
    }
  }, [initialized, performPrint, reportData]);

  if (!reportData) {
    return null;
  }

  return (
    <>
      <LoadingMask />
    </>
  );
};
