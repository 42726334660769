import Row from "@avinet/adaptive-ui-core/layout/Row";
import {
  PopupMenu,
  PopupMenuButton,
} from "@avinet/adaptive-ui-core/ui/PopupMenu";
import { format } from "date-fns";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { PROJECT_STATUS } from "../../contants/Enums";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import { ColumnDescription } from "../column-description/ColumnDescription";
import { Text } from "../text";
import { ProjectSummaryProps } from "./ProjectSummary.props";
import "./ProjectSummary.scss";

export function ProjectSummary({
  projectDetails,
  registeredObjectsCount,
}: ProjectSummaryProps) {
  const { t } = useTranslation();
  const { projectLocations, featureLoader } = useContext(FeatureContext);

  const setStatusColorText = useCallback(() => {
    switch (projectDetails?.status) {
      case PROJECT_STATUS.PLANNING:
        return "";
      case PROJECT_STATUS.IN_PROGRESS:
        return "project-summary__item--yellow";
      case PROJECT_STATUS.COMPLETED:
        return "project-summary__item--primary";
      default:
        return "";
    }
  }, [projectDetails?.status]);

  return (
    <Row wrap className="project-summary" align="top">
      {(featureLoader || projectDetails?.status) && (
        <ColumnDescription
          className={`project-summary__item ${setStatusColorText()}`}
          label={t("components.projectSummary.status")}
          text={projectDetails?.status ?? ""}
        />
      )}
      {(featureLoader || projectDetails?.start_date) && (
        <ColumnDescription
          className="project-summary__item"
          label={t("components.projectSummary.created")}
          text={
            projectDetails?.start_date
              ? format(
                  new Date(projectDetails?.start_date as string),
                  "dd-MM-yyyy"
                )
              : ""
          }
        />
      )}
      {(featureLoader || projectDetails?.end_date) && (
        <ColumnDescription
          className="project-summary__item"
          label={t("components.projectSummary.deadline")}
          text={
            projectDetails?.end_date
              ? format(
                  new Date(projectDetails?.end_date as string),
                  "dd-MM-yyyy"
                )
              : ""
          }
        />
      )}
      <ColumnDescription
        className="project-summary__item"
        label={t("components.projectSummary.registeredObjects")}
        text={registeredObjectsCount?.toString() ?? "0"}
      />
      <ColumnDescription
        className="project-summary__item"
        label={t("components.projectSummary.connectedLocations")}
        text={projectLocations?.length?.toString()}
      />
      <ColumnDescription
        className="project-summary__item"
        label={t("components.projectSummary.description")}
        text={
          projectDetails?.description ? (
            <PopupMenuButton
              className="btn light read-more-btn"
              icon="info"
              menu={
                <PopupMenu>
                  <Text
                    fontWeight={500}
                    size="small"
                    text={projectDetails.description}
                  />
                </PopupMenu>
              }
            >
              {t("components.projectSummary.readDescription")}
            </PopupMenuButton>
          ) : (
            "-"
          )
        }
      />
    </Row>
  );
}
