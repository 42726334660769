import Row from "@avinet/adaptive-ui-core/layout/Row";

import { useTranslationPath } from "../../hooks/useTranslationPath";
import { Icon } from "../icon/Icon";
import "./BatchSelectBar.scss";
import { BatchSelectProps } from "./BatchSelectProps";

export function BatchSelectBar({ count, onClose, children }: BatchSelectProps) {
  const t = useTranslationPath("common");

  return (
    <Row className="batch-select-bar">
      <Row className="left-part">
        <button type="button" className="btn-link icon" onClick={onClose}>
          <Icon name="dismiss" />
        </button>
        <div className="item-counter">{count + " " + t("selectedItems")}</div>
      </Row>
      <Row>{children}</Row>
    </Row>
  );
}
