import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { EditRegular, Info20Regular } from "@fluentui/react-icons";
import { t } from "i18next";
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { IOrgMemberData } from "../../../api/types";
import { DEFAULT_MEDIA_UUID, USER_ROLE } from "../../../contants/Enums";
import { organizationDetailsUrl } from "../../../contants/Routes";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { getMediaUri } from "../../../utils/index.utils";
import { ImageBlind } from "../../image-blind";
import { Text } from "../../text";
import { OrganisationBoxProps } from "./index.props";
import "./styles.scss";

export const OrganisationBox = ({
  organisation,
  icon,
  onClick,
  createBox,
}: OrganisationBoxProps) => {
  const { token, userProfile } = useContext(AppUserContext);
  const navigate = useNavigate();
  const { getOrganisationMembers } = useContext(OrganisationContext);
  const [members, setMembers] = useState<IOrgMemberData[]>([]);
  const organisationUser = members.find(
    (user) => user.uuid === userProfile?.uuid
  );

  const cancelled = useMemo<boolean>(() => {
    return organisation.status === "cancelled";
  }, [organisation.status]);

  const pending = useMemo<boolean>(() => {
    return organisation.status === "pending";
  }, [organisation.status]);

  const approved = useMemo<boolean>(() => {
    return organisation.status === "approved";
  }, [organisation.status]);

  const hoverTitle = useMemo<string | undefined>(() => {
    if (cancelled) {
      return t("pages.selectOrganisation.inactiveDesc") as string;
    } else if (pending) {
      return t("pages.selectOrganisation.pendingApprovalDesc") as string;
    } else {
      return undefined;
    }
  }, [cancelled, pending]);

  const handleNavigateToOrg = useCallback(
    (e: MouseEvent, orgName: string) => {
      e?.stopPropagation();
      onClick(true);
      navigate(
        `${organizationDetailsUrl}/${String(orgName)
          .split(" ")
          .join("-")
          .toLowerCase()}`,
        {
          state: {
            withPrevButton: true,
            role: organisationUser?.role,
          },
        }
      );
    },
    [navigate, onClick, organisationUser]
  );

  const fetchMembers = useCallback(async () => {
    if (!organisation) return;
    try {
      const fetchedMembers = await getOrganisationMembers(organisation?.id);

      if (Array.isArray(fetchedMembers)) setMembers(fetchedMembers);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  }, [organisation, getOrganisationMembers]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  return (
    <div className="organisation-box-container">
      <div
        className={`organisation-box ${
          createBox ? "organisation-box--create" : ""
        } ${!(approved || createBox) ? "organisation-box--disabled" : ""}`}
        onClick={
          approved || createBox
            ? () => {
                onClick();
              }
            : undefined
        }
        aria-disabled={!(approved || createBox)}
        title={hoverTitle}
      >
        {createBox ? (
          <>
            <div className="organisation-box__create-text">
              <Icon name="plusIcon" />
              <p>{organisation.name}</p>
            </div>

            <div className="organisation-box__rocket">
              <Icon
                name="rocketIcon"
                className="organisation-box__rocket__icon"
              />
              <Icon
                name="rocketCloudIcon"
                className="organisation-box__rocket__cloud"
              />
            </div>
          </>
        ) : (
          <>
            <div className="organisation-box__top">
              <div className="organisation-box__top__text">
                {icon ? (
                  icon
                ) : (
                  <>
                    {organisation.image &&
                    organisation.image !== DEFAULT_MEDIA_UUID ? (
                      <img
                        className="organisation-box__image"
                        src={getMediaUri(organisation.image, token)}
                        alt={organisation.name}
                      />
                    ) : (
                      <ImageBlind name={organisation.name} />
                    )}
                  </>
                )}
                <Text
                  text={organisation.name}
                  fontWeight={600}
                  className="organisation-box__title"
                />
              </div>
              <div
                onClick={(e) => handleNavigateToOrg(e, organisation.name)}
                className="org-link"
              >
                {approved && organisationUser?.role && (
                  <>
                    {organisationUser?.role === USER_ROLE.ADMIN ? (
                      <EditRegular />
                    ) : (
                      <Info20Regular />
                    )}
                    <Text
                      text={
                        organisationUser?.role === USER_ROLE.ADMIN
                          ? t("common.edit")
                          : t("common.details")
                      }
                      fontWeight={600}
                      size="sm"
                    />
                  </>
                )}
              </div>
            </div>
            {organisation.description && approved ? (
              <Text
                text={organisation.description}
                className="organisation-box__description"
                size="small"
              />
            ) : cancelled ? (
              <div className="organisation-box__inactive">
                <Text
                  text={t("pages.selectOrganisation.inactive")}
                  className="organisation-box__inactive__text"
                  size="small"
                />
                <a
                  href={`mailto:delio.support@asplanviak.no?subject=${t(
                    "pages.selectOrganisation.reactivateSubject"
                  )}`}
                >
                  <Text
                    text={t("pages.selectOrganisation.reactivate")}
                    size="small"
                    fontWeight={600}
                  />
                </a>
              </div>
            ) : (
              pending && (
                <Text
                  text={t("pages.selectOrganisation.pendingApproval")}
                  className="organisation-box__pending-approval"
                  size="small"
                />
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};
