import React, { FC } from "react";

const HelpOutlineIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.523 0 20 4.478 20 10C20 15.522 15.523 20 10 20C4.477 20 0 15.522 0 10C0 4.478 4.477 0 10 0ZM10 1.667C5.405 1.667 1.667 5.405 1.667 10C1.667 14.595 5.405 18.333 10 18.333C14.595 18.333 18.333 14.595 18.333 10C18.333 5.405 14.595 1.667 10 1.667ZM10 13.5C10.5523 13.5 11 13.9477 11 14.5C11 15.0523 10.5523 15.5 10 15.5C9.44772 15.5 9 15.0523 9 14.5C9 13.9477 9.44772 13.5 10 13.5ZM10 4.75C11.5188 4.75 12.75 5.98122 12.75 7.5C12.75 8.51075 12.4525 9.07404 11.6989 9.85858L11.5303 10.0303C10.9084 10.6522 10.75 10.9163 10.75 11.5C10.75 11.9142 10.4142 12.25 10 12.25C9.58579 12.25 9.25 11.9142 9.25 11.5C9.25 10.4892 9.54754 9.92596 10.3011 9.14142L10.4697 8.96967C11.0916 8.34777 11.25 8.08372 11.25 7.5C11.25 6.80964 10.6904 6.25 10 6.25C9.35279 6.25 8.82047 6.74187 8.75645 7.37219L8.75 7.5C8.75 7.91421 8.41421 8.25 8 8.25C7.58579 8.25 7.25 7.91421 7.25 7.5C7.25 5.98122 8.48122 4.75 10 4.75Z"
        fill="#7D89B0"
      />
    </svg>
  );
};

export default HelpOutlineIcon;
