import React, { FC } from "react";

const EditFilledIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9203 0.873168C12.1027 -0.309226 14.0271 -0.284951 15.1794 0.926893C16.2913 2.09631 16.2681 3.93899 15.1271 5.08003L14.4571 5.74999L14.7929 6.08576C15.5739 6.86681 15.5739 8.13314 14.7929 8.91419L13.8536 9.85353C13.6583 10.0488 13.3417 10.0488 13.1464 9.85353C12.9512 9.65827 12.9512 9.34169 13.1464 9.14642L14.0858 8.20708C14.4763 7.81656 14.4763 7.18339 14.0858 6.79287L13.75 6.4571L5.76907 14.4381C5.43048 14.7767 5.00237 15.0118 4.53494 15.1157L0.608549 15.9889C0.441665 16.026 0.267387 15.9753 0.146489 15.8544C0.02559 15.7335 -0.0251689 15.5592 0.0119121 15.3923L0.889529 11.4424C0.990085 10.9899 1.21763 10.5754 1.54545 10.2475L10.9203 0.873168Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default EditFilledIcon;
