import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import PlanCrownIcon from "../../../assets/icons/plan-crown/PlanCrownIcon";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
// import { Icon } from "../../icon/Icon";
import { Text } from "../../text";
import { ProfilePlanProps } from "./index.props";
import "./styles.scss";

export const ProfilePlan = ({
  className,
  freePlan,
  // cancelButton,
  // changePlanButton,
  members,
}: ProfilePlanProps) => {
  const { t } = useTranslation();

  const { selectedOrganisation } = useContext(OrganisationContext);

  return (
    <section className={`profile-plan ${className ? className : ""}`}>
      <div className="profile-plan__top">
        <div className="profile-plan__top__text">
          <h6>
            {freePlan
              ? t("components.profilePlan.freeTitle")
              : t("components.profilePlan.paidTitle")}
          </h6>
          <span
            className={`profile-plan__${
              freePlan ? "upgrade-text" : "paid-text"
            }`}
          >
            <Text
              className="seats-taken"
              text={
                freePlan
                  ? t("components.profilePlan.upgradeText")
                  : t("components.profilePlan.seatsTaken", {
                      seatsTaken: members,
                      seatsAvailable: selectedOrganisation?.member_limit,
                    })
              }
            />
            {!freePlan && (
              <Text
                className="seats-taken-text"
                text={t("components.profilePlan.seatsTakenText")}
                size="xl"
              />
            )}
          </span>
          <Text
            className="description"
            text={t("components.profilePlan.description")}
            size="sm"
          />
          {/* TODO: Add back when payment plan is a thing again
          <span className="profile-plan__top__text__subscription-status">
            {freePlan ? (
              <>
                <Icon name="checkmarkCircle" />
                <Text
                  className="subscription-status"
                  text={
                    "Subscription canceled - lasts until 22th February 2025."
                  }
                />
              </>
            ) : (
              <>
                <Icon name="checkmarkCircle" />
                <Text
                  className="subscription-status"
                  text={
                    "Subscription active - will be renewed 22th February 2025."
                  }
                />
              </>
            )}
          </span> */}
        </div>
        <PlanCrownIcon />
      </div>

      {/* TODO: Add back when payment plan is a thing again
      <div
        className={`profile-plan__buttons ${
          freePlan ? "profile-plan__buttons--big" : ""
        }`}
      >
        {freePlan ? (
          <button className="btn" onClick={changePlanButton}>
            {t("components.profilePlan.upgradeSubscriptionButton")}
          </button>
        ) : (
          <>
            <button
              className="btn light btn-cancel profile-plan__buttons--cancel"
              onClick={cancelButton}
            >
              {t("components.profilePlan.cancelSubscriptionButton")}
            </button>
            <button className="btn" onClick={changePlanButton}>
              {t("components.profilePlan.changeSubscriptionButton")}
            </button>
          </>
        )}
      </div> */}
    </section>
  );
};
