import Column from "@avinet/adaptive-ui-core/layout/Column";
import { t } from "i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { FEATURE_TYPE } from "../../../contants/Enums";
import {
  dashboardUrl,
  locationTabObjects,
  locationsUrl,
  objectsUrl,
  projectsUrl,
} from "../../../contants/Routes";
import { useTranslationPath } from "../../../hooks/useTranslationPath";
import { Icon } from "../../icon/Icon";
import { FeatureModalCreatedButton } from "./feature-modal-created-button";
import { FeatureModalCreatedProps } from "./index.props";
import "./styles.scss";

export const FeatureModalCreated = ({
  className = "",
  editMode,
  featureType,
  createdFeatureId,
  locationId,
  handleNewComponent,
  resetForm,
}: FeatureModalCreatedProps) => {
  const navigate = useNavigate();
  const tCreated = useTranslationPath("components.featureModalCreated");

  const renderFeatureType = useCallback(() => {
    switch (featureType) {
      case FEATURE_TYPE.LOCATION:
        return t("components.noElements.location");
      case FEATURE_TYPE.OBJECT:
        return t("components.noElements.object");
      case FEATURE_TYPE.PROJECT:
        return t("components.noElements.project");
      default:
        return null;
    }
  }, [featureType]);

  const renderButtons = useCallback(() => {
    switch (featureType) {
      case FEATURE_TYPE.LOCATION:
        return (
          <>
            <FeatureModalCreatedButton
              className="btn light"
              icon="location"
              title={t("components.featureModalCreated.show", {
                feature: renderFeatureType(),
              })}
              subtitle={
                editMode
                  ? t("components.featureModalCreated.openNew", {
                      feature: renderFeatureType(),
                    })
                  : t("components.featureModalCreated.openNewDescription", {
                      feature: renderFeatureType(),
                    })
              }
              onClick={() => {
                navigate(`${locationsUrl}/${createdFeatureId}`, {
                  state: {
                    featureId: createdFeatureId,
                  },
                });
                resetForm();
              }}
            />
            <FeatureModalCreatedButton
              className="btn light"
              icon="add"
              title={t("components.featureModalCreated.createNewTitle", {
                feature: renderFeatureType(),
              })}
              subtitle={t(
                "components.featureModalCreated.createNewDescription",
                { feature: renderFeatureType() }
              )}
              onClick={() => resetForm(true)}
            />
            <FeatureModalCreatedButton
              className="btn light"
              icon="boxMultiple"
              title={t("components.featureModalCreated.addObjectTitle")}
              subtitle={t(
                "components.featureModalCreated.addObjectDescription"
              )}
              onClick={() => {
                handleNewComponent &&
                  handleNewComponent(createdFeatureId ?? undefined);
                resetForm();
              }}
            />
            <FeatureModalCreatedButton
              className="btn light"
              icon="home"
              title={t("components.featureModalCreated.dashboardButtonTitle")}
              subtitle={t(
                "components.featureModalCreated.dashboardButtonDescription"
              )}
              onClick={() => {
                navigate(dashboardUrl);
                resetForm();
              }}
            />
            <FeatureModalCreatedButton
              className="btn"
              icon="dismiss"
              title={t("components.featureModalCreated.closeWindow")}
              subtitle={t("components.featureModalCreated.closeDescription")}
              onClick={() => resetForm()}
            />
          </>
        );
      case FEATURE_TYPE.OBJECT:
        return (
          <>
            <FeatureModalCreatedButton
              icon="boxMultiple"
              className="btn light"
              title={t("components.featureModalCreated.show", {
                feature: renderFeatureType(),
              })}
              subtitle={
                editMode
                  ? t("components.featureModalCreated.openNew", {
                      feature: renderFeatureType(),
                    })
                  : t("components.featureModalCreated.openNewDescription", {
                      feature: renderFeatureType(),
                    })
              }
              onClick={() => {
                navigate(`${objectsUrl}/${createdFeatureId}`, {
                  state: {
                    featureId: createdFeatureId,
                  },
                });
                resetForm();
              }}
            />
            <FeatureModalCreatedButton
              icon="add"
              className="btn light"
              title={t("components.featureModalCreated.createNewTitle", {
                feature: renderFeatureType(),
              })}
              subtitle={t(
                "components.featureModalCreated.createNewDescription",
                { feature: renderFeatureType() }
              )}
              onClick={() =>
                handleNewComponent &&
                handleNewComponent(createdFeatureId ?? undefined)
              }
            />
            <FeatureModalCreatedButton
              icon="location"
              className="btn light"
              title={t("components.featureModalCreated.showInLocation", {
                feature: renderFeatureType(),
              })}
              subtitle={
                editMode
                  ? t("components.featureModalCreated.openNew", {
                      feature: renderFeatureType(),
                    })
                  : t("components.featureModalCreated.openNewDescription", {
                      feature: renderFeatureType(),
                    })
              }
              onClick={() => {
                resetForm();
                navigate(
                  `${locationsUrl}/${locationId}/${locationTabObjects}`,
                  {
                    state: {
                      featureId: createdFeatureId,
                    },
                  }
                );
              }}
            />
            <FeatureModalCreatedButton
              className="btn"
              icon="dismiss"
              title={t("components.featureModalCreated.closeWindow")}
              subtitle={t("components.featureModalCreated.closeDescription")}
              onClick={() => resetForm()}
            />
          </>
        );
      case FEATURE_TYPE.PROJECT:
        return (
          <>
            <FeatureModalCreatedButton
              icon="boxMultiple"
              className="btn light"
              title={t("components.featureModalCreated.show", {
                feature: renderFeatureType(),
              })}
              subtitle={
                editMode
                  ? t("components.featureModalCreated.openNew", {
                      feature: renderFeatureType(),
                    })
                  : t("components.featureModalCreated.openNewDescription", {
                      feature: renderFeatureType(),
                    })
              }
              onClick={() => {
                navigate(`${projectsUrl}/${createdFeatureId}`, {
                  state: {
                    featureId: createdFeatureId,
                  },
                });
                resetForm();
              }}
            />
            <FeatureModalCreatedButton
              icon="add"
              className="btn light"
              title={t("components.featureModalCreated.createNewTitleProject", {
                feature: renderFeatureType(),
              })}
              subtitle={t(
                "components.featureModalCreated.createNewDescriptionProject",
                { feature: renderFeatureType() }
              )}
              onClick={handleNewComponent}
            />
            <FeatureModalCreatedButton
              icon="home"
              className="btn light"
              title={t("components.featureModalCreated.dashboardButtonTitle")}
              subtitle={t(
                "components.featureModalCreated.dashboardButtonDescription"
              )}
              onClick={() => {
                navigate(dashboardUrl);
                resetForm();
              }}
            />
            <FeatureModalCreatedButton
              className="btn"
              icon="dismiss"
              title={t("components.featureModalCreated.closeWindow")}
              subtitle={t("components.featureModalCreated.closeDescription")}
              onClick={() => resetForm()}
            />
          </>
        );
      default:
        return null;
    }
  }, [
    featureType,
    renderFeatureType,
    editMode,
    handleNewComponent,
    navigate,
    createdFeatureId,
    resetForm,
    locationId,
  ]);

  const renderTitle = () => {
    switch (featureType) {
      case FEATURE_TYPE.LOCATION:
        return tCreated("locationTitle");
      case FEATURE_TYPE.OBJECT:
        return tCreated("objectTitle");
      case FEATURE_TYPE.PROJECT:
        return tCreated("projectTitle");
      default:
        return null;
    }
  };

  return (
    <div className={`feature-created-modal ${className}`}>
      <div className="header">
        <Icon name="checkmarkCircle" />
        <h4>
          {renderTitle()}{" "}
          {editMode
            ? tCreated("titlePartEdited")
            : tCreated("titlePartCreated")}
          !
        </h4>
        <span className="subtitle">{tCreated("description")}</span>
      </div>
      <Column align="stretch" className="options-container">
        {renderButtons()}
      </Column>
    </div>
  );
};
