import Select, {
  SelectEl,
  SelectElProps,
} from "@avinet/adaptive-ui-core/form/controls/Select";

import { Icon } from "../icon/Icon";
import "./InputSelect.scss";
import { InputSelectProps } from "./index.props";

export function InputSelect({
  id,
  items,
  label,
  placeholder,
  required,
  disabled,
}: InputSelectProps) {
  return (
    <div className="aui-form-control custom-input-select">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="select-container">
        <Select
          id={id}
          items={items}
          label={null}
          placeholder={placeholder}
          required={required}
          translateItems={false}
          noItemTranslation
          disabled={disabled}
        />
        <Icon name="chevron" rotate={90} />
      </div>
    </div>
  );
}

export function InputSelectEl(props: SelectElProps) {
  const { label, id, ...rest } = props;

  return (
    <div className="aui-form-control custom-input-select">
      <label htmlFor={id}>{label}</label>
      <div className="select-container">
        <SelectEl id={id} label={null} {...rest} />
        <Icon name="chevron" rotate={90} />
      </div>
    </div>
  );
}
