import React, {
  Context,
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import * as API from "../../api/types";
import {
  AddOrganisationRequest,
  AddOrganisationsResponse,
  AddUserToOrganisationRequest,
  AddUserToOrganisationResponse,
  IOrgMemberData,
  IOrganisation,
} from "../../api/types";
import { USER_ROLE } from "../../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../../contants/FeatureUuid";
import { useAlertMessage } from "../../hooks/useAlertMessage";
import { useAttachments } from "../../hooks/useAttachments";
import { OrganisationsApi } from "../../services/OrganisationsApi";
import { fileToPostMedia } from "../../utils/index.utils";
import { IContext } from "../ContextInterfaces";
import { AppUserContext } from "../user-context/UserContext";

interface OrganisationContextConfig {
  downloadOrganisations: () => void;
  organisationsList: IOrganisation[] | [];
  addNewOrganisation: (
    body: AddOrganisationRequest
  ) => Promise<AddOrganisationsResponse | void>;
  selectedOrganisation: IOrganisation | null;
  getOrganisationName: (orgId: number) => IOrganisation | undefined;
  addNewUserToOrganisation: (
    body: AddUserToOrganisationRequest
  ) => Promise<AddUserToOrganisationResponse | void>;
  getOrganisationMembers: (
    orgId: number | null | undefined
  ) => Promise<void | IOrgMemberData[] | API.IOrganisationsResponse>;
  updateUserRole: (body: API.UpdateUserRoleRequest) => Promise<void>;
  getMemberProfileImage: (memberUuid: string) => Promise<void | Blob>;
  joinOrganisation: (inviteCode: string) => Promise<any>;
  updatePaymentPlan: (
    memberLimit: number,
    yearlyPayment: boolean,
    orgId: number
  ) => Promise<any>; //TODO: Fix any when payment plan is a thing again
  selectedOrgLoading: boolean;
  updateOrgImage: (file: File) => void;
  updateOrganisation: (body: IOrganisation) => Promise<void>;
  setSelectedOrganisation: (org: IOrganisation) => void;
  organisationMembers: IOrgMemberData[];
  setOrganisationMembers: Dispatch<SetStateAction<IOrgMemberData[]>>;
  isOrganisationAdmin: boolean;
}

const defaultConfig: OrganisationContextConfig = {
  downloadOrganisations: () => Promise.reject(),
  addNewOrganisation: () => Promise.reject(),
  organisationsList: [],
  selectedOrganisation: null,
  getOrganisationName: () => undefined,
  addNewUserToOrganisation: () => Promise.reject(),
  getOrganisationMembers: () => Promise.reject(),
  updateUserRole: () => Promise.reject(),
  getMemberProfileImage: () => Promise.reject(),
  joinOrganisation: () => Promise.reject(),
  updatePaymentPlan: () => Promise.reject(),
  selectedOrgLoading: false,
  updateOrgImage: () => undefined,
  updateOrganisation: () => Promise.reject(),
  setSelectedOrganisation: () => undefined,
  organisationMembers: [],
  setOrganisationMembers: () => [],
  isOrganisationAdmin: false,
};

export const OrganisationContext: Context<OrganisationContextConfig> =
  createContext(defaultConfig);

export const OrganisationContextProvider: FC<IContext> = ({
  children,
  environment,
}) => {
  const { userProfile, userOrganisationType, setAlertMessage } =
    useContext(AppUserContext);
  const [organisationsList, setOrganisationsList] = useState<
    IOrganisation[] | []
  >([]);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<IOrganisation | null>(null);
  const [selectedOrgLoading, setSelectedOrgLoading] = useState<boolean>(false);
  const { successUpdateOrgMessage, failedUpdateOrgMessage } = useAlertMessage();
  const { postOrUpdateMedia } = useAttachments();
  const [organisationMembers, setOrganisationMembers] = useState<
    IOrgMemberData[]
  >([]);

  const getOrganisationMembers = useCallback(
    async (orgId: number | null | undefined) => {
      return new OrganisationsApi(environment.api)
        .getOrganisationMembers(orgId)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setOrganisationMembers(response?.data);
            return response?.data;
          }
          return [];
        })
        .catch(console.error);
    },
    [environment.api]
  );

  const downloadOrganisations = useCallback(async () => {
    setSelectedOrgLoading(true);
    const response = await new OrganisationsApi(
      environment.api
    ).getOrganisations();

    if (response.kind === KIND_RESPONSE.OK) {
      setOrganisationsList(response?.data?.features);
      const selectedOrg = response.data?.features.find(
        (org: IOrganisation) => org.name === userOrganisationType
      );
      selectedOrg && setSelectedOrganisation(selectedOrg);
      selectedOrg && (await getOrganisationMembers(selectedOrg?.id));
      setSelectedOrgLoading(false);
      return true;
    } else {
      setOrganisationMembers([]);
      setOrganisationsList([]);
      setSelectedOrgLoading(false);
      return false;
    }
  }, [environment.api, userOrganisationType, getOrganisationMembers]);

  const addNewUserToOrganisation = useCallback(
    async (body: API.AddUserToOrganisationRequest) => {
      return new OrganisationsApi(environment.api)
        .addUserToOrganisation(body)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            const validData = [...organisationsList, response?.data];
            response?.data && setOrganisationsList(validData);
          }
          return response;
        })
        .catch(console.error);
    },
    [environment.api, organisationsList]
  );

  const addNewOrganisation = useCallback(
    async (body: API.AddOrganisationRequest) => {
      return new OrganisationsApi(environment.api)
        .addOrganisation(body)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            const validData = [...organisationsList, response?.data];
            response?.data && setOrganisationsList(validData);
          }
          return response;
        })
        .catch(console.error);
    },
    [environment.api, organisationsList]
  );

  const getOrganisationName = useCallback(
    (orgId: number) => organisationsList.find((org) => org.id === orgId),
    [organisationsList]
  );

  const updateUserRole = useCallback(
    async (body: API.UpdateUserRoleRequest) => {
      return new OrganisationsApi(environment.api)
        .updateUserRole(body)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            return response?.data;
          }
          return [];
        })
        .catch(console.error);
    },
    [environment.api]
  );

  const getMemberProfileImage = useCallback(
    async (memberUuid: string) => {
      return new OrganisationsApi(environment.api)
        .getMemberProfileImage(memberUuid)
        .then(async (response) => {
          return response;
        })
        .catch(console.error);
    },
    [environment.api]
  );

  const joinOrganisation = useCallback(
    (inviteCode: string) => {
      return new OrganisationsApi(environment.api)
        .joinOrganisation(inviteCode)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    },
    [environment.api]
  );

  const updatePaymentPlan = useCallback(
    async (memberLimit: number, yearlyPayment: boolean, orgId: number) => {
      return new OrganisationsApi(environment.api)
        .updatePaymentPlan(memberLimit, yearlyPayment, orgId)
        .then(async (response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    },
    [environment.api]
  );

  const updateOrgImage = useCallback(
    async (file: File) => {
      if (selectedOrganisation?.id) {
        const postMedia = await fileToPostMedia(file);
        await postOrUpdateMedia(
          [postMedia],
          FEATURE_MEDIA_TYPE.ORGANIZATION,
          selectedOrganisation?.id
        );
        setAlertMessage(successUpdateOrgMessage);
        return downloadOrganisations();
      }
    },
    [
      selectedOrganisation?.id,
      postOrUpdateMedia,
      setAlertMessage,
      successUpdateOrgMessage,
      downloadOrganisations,
      setAlertMessage,
    ]
  );

  const updateOrganisation = useCallback(
    async (body: IOrganisation) => {
      if (selectedOrganisation?.id) {
        setSelectedOrgLoading(true);
        return new OrganisationsApi(environment.api)
          .updateOrganisation(selectedOrganisation?.id, {
            image: selectedOrganisation.image,
            address: selectedOrganisation.address,
            billing_email: selectedOrganisation.billing_email,
            billing_reference: selectedOrganisation.billing_reference,
            contact_person: selectedOrganisation.contact_person,
            ...body,
          })
          .then(async (response) => {
            if (response.kind === KIND_RESPONSE.OK) {
              setSelectedOrganisation(response.data);
              setAlertMessage(successUpdateOrgMessage);
            } else {
              setAlertMessage(failedUpdateOrgMessage);
            }
            setSelectedOrgLoading(false);
          })
          .catch(console.error);
      }
    },
    [
      environment,
      failedUpdateOrgMessage,
      successUpdateOrgMessage,
      selectedOrganisation,
      setAlertMessage,
    ]
  );

  const isOrganisationAdmin = useMemo(() => {
    return organisationMembers.some(
      (member: any) =>
        member.uuid === userProfile?.uuid && member.role === USER_ROLE.ADMIN
    );
  }, [organisationMembers, userProfile]);

  useEffect(() => {
    userProfile?.userData && downloadOrganisations();
  }, [environment, downloadOrganisations, userProfile?.userData]);

  const org = useMemo(() => {
    return {
      organisationsList,
      downloadOrganisations,
      addNewOrganisation,
      selectedOrganisation,
      getOrganisationName,
      getOrganisationMembers,
      updateUserRole,
      getMemberProfileImage,
      addNewUserToOrganisation,
      joinOrganisation,
      updatePaymentPlan,
      selectedOrgLoading,
      setSelectedOrganisation,
      updateOrgImage,
      updateOrganisation,
      organisationMembers,
      setOrganisationMembers,
      isOrganisationAdmin,
    };
  }, [
    addNewOrganisation,
    addNewUserToOrganisation,
    downloadOrganisations,
    getMemberProfileImage,
    getOrganisationMembers,
    getOrganisationName,
    joinOrganisation,
    organisationsList,
    selectedOrgLoading,
    selectedOrganisation,
    updatePaymentPlan,
    updateUserRole,
    updateOrgImage,
    updateOrganisation,
    organisationMembers,
    setOrganisationMembers,
    isOrganisationAdmin,
  ]);

  if (!environment) {
    return null;
  }

  return (
    <OrganisationContext.Provider value={org}>
      {children}
    </OrganisationContext.Provider>
  );
};
