import { format } from "date-fns";
import { t } from "i18next";
import { useCallback, useMemo } from "react";

import { IProjectProperties, IStatusTabs } from "../api/types";
import {
  IPageTableColumnData,
  IPageTableColumnMeta,
  IPageTableContent,
  PageTableColumnTypes,
} from "../components/page/page-table/table-header/index.props";
import { PROJECT_STATUS } from "../contants/Enums";
import { useColumnState } from "./useColumnState";

export const useProjectTableColumns = (
  projectList: IProjectProperties[] | []
) => {
  const initialColumnMeta: IPageTableColumnMeta[] = useMemo(() => {
    return [
      {
        key: "id",
        title: t("tables.id"),
        active: false,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "picture",
        title: t("tables.picture"),
        active: true,
        sortable: false,
        type: PageTableColumnTypes.IMAGE,
      },
      {
        key: "name",
        title: t("tables.name"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "status",
        title: t("tables.status"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.STATUS,
      },
      {
        key: "project_leader",
        title: t("tables.leader"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "customer",
        title: t("tables.customer"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "start_date",
        title: t("tables.startDate"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "end_date",
        title: t("tables.endDate"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "location_count",
        title: t("tables.locationCount"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "product_count",
        title: t("tables.productCount"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
    ];
  }, []);

  const { columnState, updateColumnVisibility, resetAllColumns } =
    useColumnState(initialColumnMeta);

  const projectTableColumns = useMemo(() => {
    const columnData = projectList?.map((project) => {
      return [
        {
          key: "id",
          value: project.id,
        },
        {
          key: "picture",
          value: {
            image: project.image,
            name: `${project.name} ${project.image}`,
          },
        },
        {
          key: "name",
          value: project.name || "-",
        },
        {
          key: "status",
          value: project.status || "-",
        },
        {
          key: "project_leader",
          value: project.project_leader || "-",
        },
        {
          key: "customer",
          value: project.customer || "-",
        },
        {
          key: "start_date",
          value: project.start_date
            ? format(new Date(project.start_date as string), "dd-MM-yyyy")
            : "" || "-",
        },
        {
          key: "end_date",
          value: project.end_date
            ? format(new Date(project.end_date as string), "dd-MM-yyyy")
            : "" || "-",
        },
        {
          key: "location_count",
          value: project.location_count ?? 0,
        },
        {
          key: "product_count",
          value: project.product_count ?? 0,
        },
      ] as IPageTableColumnData[];
    });
    return {
      columns: columnState,
      data: columnData,
      updateColumnVisibility,
      resetAllColumns,
    } as IPageTableContent;
  }, [columnState, projectList, resetAllColumns, updateColumnVisibility]);

  const projectTableTabs: IStatusTabs[] = useMemo(
    () => [
      {
        text: t("components.pageTable.tabs.locations.all"),
        status: PROJECT_STATUS.ALL,
      },
      {
        text: t("options.projectStatuses.planning"),
        status: PROJECT_STATUS.PLANNING,
      },
      {
        text: t("options.projectStatuses.inProgress"),
        status: PROJECT_STATUS.IN_PROGRESS,
      },
      {
        text: t("options.projectStatuses.completed"),
        status: PROJECT_STATUS.COMPLETED,
      },
    ],
    []
  );

  const renderProjectTableTitle = useCallback((activeFilter: string) => {
    switch (activeFilter) {
      case t("components.pageTable.tabs.locations.all"):
        return t("pages.projects.titleFilters", {
          filter: t("options.assessmentRating.all"),
        });
      case t("options.projectStatuses.planning"):
        return t("pages.projects.titleFilters", {
          filter: t("options.projectStatuses.planning"),
        });
      case t("options.projectStatuses.inProgress"):
        return t("pages.projects.titleFilters", {
          filter: t("options.projectStatuses.inProgress"),
        });
      case t("options.projectStatuses.completed"):
        return t("pages.projects.titleFilters", {
          filter: t("options.projectStatuses.completed"),
        });
      default:
        return "";
    }
  }, []);

  return {
    projectTableColumns,
    projectTableTabs,
    renderProjectTableTitle,
  };
};
