import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { useCallback, useState } from "react";

import { PasswordInputProps } from "./PasswordInput.props";
import "./PasswordInput.scss";

export function PasswordInput({
  id,
  label,
  placeholder,
  className,
  required,
  info,
  error,
  autoComplete,
}: PasswordInputProps) {
  const [isVisible, setIsVisible] = useState(false);

  const togglePasswordVisiblity = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  return (
    <div className="aui-form-control password-input">
      <label htmlFor={id}>{label}</label>
      <div className="field-wrapper">
        <Input
          id={id}
          type={isVisible ? "text" : "password"}
          className={`${className} ${error ? "error" : ""}`}
          label={null}
          placeholder={placeholder}
          required={required}
          info={info}
          autoComplete={autoComplete}
        />
        <button
          onClick={togglePasswordVisiblity}
          className="btn-link toggle-visible-button"
          type="button"
        >
          <Icon name={isVisible ? "eyeOff" : "eye"} />
        </button>
      </div>
    </div>
  );
}
