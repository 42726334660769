import { t } from "i18next";
import React from "react";

import { ProfileMetadataRowProps } from "./ProfileMetadataRow.props";

export function ProfileMetadataRow({ type, value }: ProfileMetadataRowProps) {
  return (
    <div className="profile-metadata-modal__row">
      <p className="profile-metadata-modal__type">
        {t(`pages.profile.headings.profile.info.${type}`)}
      </p>
      <p className="profile-metadata-modal__value">{value}</p>
    </div>
  );
}
