import React, { FC } from "react";

import { ChevronRight } from "../../assets/icons/chevron-right/ChevronRight";
import { SliderCircleNavProps } from "./index.props";
import "./styles.scss";

export const SliderCircleNav: FC<SliderCircleNavProps> = ({
  className,
  prev,
  colorPrimary,
  onClick,
}) => {
  return (
    <div
      className={`slider-circle-nav ${className ? className : ""} ${
        prev ? "slider-circle-nav--prev" : ""
      } ${colorPrimary ? "slider-circle-nav--color-primary" : ""}`}
      onClick={onClick}
    >
      <ChevronRight width={22} height={22} />
    </div>
  );
};
