import React from "react";

const ProjectsIcon = () => {
  return (
    <svg
      className="projects-icon"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.20693 0C6.66749 0 7.1153 0.141286 7.49094 0.402348L7.64734 0.521502L10.022 2.5H17.75C18.8867 2.5 19.8266 3.34297 19.9785 4.43788L19.9948 4.59595L20 4.75V13.75C20 14.9409 19.0748 15.9156 17.904 15.9948L17.75 16H2.25C1.05914 16 0.0843551 15.0748 0.00519085 13.904L0 13.75V2.25C0 1.05914 0.925161 0.0843551 2.09595 0.00519085L2.25 0H6.20693ZM7.64734 5.9785C7.29353 6.27334 6.85906 6.45148 6.40335 6.49141L6.20693 6.5L1.5 6.499V13.75C1.5 14.1297 1.78215 14.4435 2.14823 14.4932L2.25 14.5H17.75C18.1297 14.5 18.4435 14.2178 18.4932 13.8518L18.5 13.75V4.75C18.5 4.3703 18.2178 4.05651 17.8518 4.00685L17.75 4H10.021L7.64734 5.9785ZM6.20693 1.5H2.25C1.8703 1.5 1.55651 1.78215 1.50685 2.14823L1.5 2.25V4.999L6.20693 5C6.34729 5 6.4841 4.96063 6.60221 4.88738L6.68706 4.82617L8.578 3.249L6.68706 1.67383C6.57923 1.58398 6.44893 1.52664 6.31129 1.5073L6.20693 1.5Z"
        fill="#7D89B0"
      />
    </svg>
  );
};

export default ProjectsIcon;
