import React, { FC } from "react";

import { RegisterTitleProps } from "./index.props";
import "./styles.scss";

export const RegisterTitle: FC<RegisterTitleProps> = ({
  text,
  description,
  children,
}) => {
  return (
    <div className="register-title">
      <div
        className={`register-title__header ${
          description && "register-title__header--desc"
        }`}
      >
        <h3>{text}</h3>
        {description && <p>{description}</p>}
      </div>
      {children}
    </div>
  );
};
