import { SelectItem } from "@avinet/adaptive-ui-core/form/controls/Select";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";
import React, { useCallback, useContext, useState } from "react";

import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { convertToInitials } from "../../../utils/index.utils";
import { Checkbox } from "../../checkbox";
import { TableSelect } from "../../page/page-table/tableSelect";
import { ProfileMetadataModal } from "../../profile/profile-metadata-modal/ProfileMetadataModal";
import { ProfileModal } from "../../profile/profile-modal";
import { TableRowProps } from "./index.props";
import "./styles.scss";

function TableRow({
  element,
  handleSelectItem,
  handleRemoveOrChangeRole,
  onSubmitDeleteModal,
  selectedElements,
  isAdmin,
  amountOfAdmins,
}: TableRowProps) {
  const [showMetadataModal, setShowMetadataModal] = useState<boolean>(false);
  const handleMetadataModal = useCallback((state: boolean) => {
    setShowMetadataModal(state);
  }, []);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModal = useCallback((state: boolean) => {
    setShowDeleteModal(state);
  }, []);

  const { userProfile } = useContext(AppUserContext);
  const { selectedOrganisation } = useContext(OrganisationContext);

  const hideDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const removeSingleUser = useCallback(() => {
    if (!handleRemoveOrChangeRole || !onSubmitDeleteModal) return;
    const object = {
      uuid: element?.uuid,
      org_id: selectedOrganisation?.id,
      new_role: "Remove",
    };
    handleRemoveOrChangeRole(object);
    setShowDeleteModal(false);
    onSubmitDeleteModal();
  }, [
    element?.uuid,
    handleRemoveOrChangeRole,
    onSubmitDeleteModal,
    selectedOrganisation?.id,
  ]);

  const removeUserDesc = t("components.removeUserModal.descriptionOne", {
    userEmail: element?.email,
    orgName: selectedOrganisation?.name,
  });

  const roleOptions: SelectItem[] =
    element?.role === "Admin" && amountOfAdmins === 1
      ? [{ value: "Admin", label: t("common.admin") ?? "" }]
      : [
          { value: "Admin", label: t("common.admin") ?? "" },
          { value: "Member", label: t("common.member") ?? "" },
        ];

  const handleChangeRole = useCallback(
    (value: string) => {
      if (!handleRemoveOrChangeRole) return;
      const object = {
        uuid: element?.uuid,
        org_id: selectedOrganisation?.id,
        new_role: value,
      };
      handleRemoveOrChangeRole(object);
    },
    [element?.uuid, handleRemoveOrChangeRole, selectedOrganisation?.id]
  );

  return (
    <>
      <tr className="table-row" key={element?.uuid}>
        <td className="table-row__cell table--60">
          <Row className="table-row__name">
            {isAdmin && (
              <Checkbox
                className={`checkbox--gray ${
                  element?.uuid === userProfile?.uuid ? "hidden" : ""
                }`}
                id={`${element?.uuid}`}
                value={
                  selectedElements
                    ? selectedElements.includes(element?.uuid)
                    : false
                }
                onChange={handleSelectItem}
              />
            )}
            {element?.image ? (
              <img
                src={element?.image}
                alt={`${element?.first_name}-${element?.last_name}}`}
                className="sidebar__user__img"
              />
            ) : (
              <div className="sidebar__user__img--default">
                <span>
                  {convertToInitials(element?.first_name, element?.last_name)}
                </span>
              </div>
            )}
            <div className="table-row__name__text">
              <p>
                {element?.first_name} {element?.last_name}
              </p>
              <p>{element?.first_name}</p>
            </div>
          </Row>
        </td>
        <td className="table-row__cell table--40">
          <Row className="table-row__options">
            {isAdmin ? (
              <TableSelect
                items={roleOptions}
                value={element?.role ?? ""}
                onChange={handleChangeRole}
              />
            ) : (
              <p>{t(`common.${element?.role?.toLowerCase()}`)}</p>
            )}
            {(isAdmin || userProfile?.uuid === element?.uuid) && (
              <div
                className="profile-delete-btn-container"
                onClick={() => handleDeleteModal(true)}
              >
                <Icon name="delete" className="table-row__delete-icon" />
              </div>
            )}
            {element?.email && (
              <div
                className="profile-metadata-btn-container"
                onClick={() => handleMetadataModal(true)}
              >
                <Icon name="share" className="table-row__share-icon" />
              </div>
            )}
          </Row>
        </td>
      </tr>
      <ProfileModal
        deleteMode
        iconName="delete"
        title={t("components.removeUserModal.title")}
        description=""
        showModal={showDeleteModal}
        cancelButtonClick={hideDeleteModal}
        submitButtonClick={removeSingleUser}
        submitButtonText={t("common.removeUser")}
        className="profile-policy__delete-modal"
      >
        <p className="profile-policy__delete-modal__text">{removeUserDesc}</p>
        <p className="profile-policy__delete-modal__text">
          {t("components.removeUserModal.descriptionTwo")}
        </p>
        <p className="profile-policy__delete-modal__text">
          {t("components.removeUserModal.descriptionThree")}
        </p>
      </ProfileModal>
      <ProfileMetadataModal
        showModal={showMetadataModal}
        cancelButtonClick={() => handleMetadataModal(false)}
        title={t("pages.profile.headings.profile.info.viewProfileTitle")}
        user={element}
      ></ProfileMetadataModal>
    </>
  );
}

export default TableRow;
