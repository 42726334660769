import { IAsideData } from "../../../utils/types/types";

export const addObjectAside: IAsideData[] = [
  {
    id: 1,
    text: "components.objectModal.aside.attachments",
    trigger: "attachments",
  },
  {
    id: 2,
    text: "components.objectModal.aside.amount",
    trigger: "amount",
  },
  {
    id: 3,
    text: "components.objectModal.aside.nameAndCategory",
    trigger: "category",
  },
  {
    id: 4,
    text: "components.objectModal.aside.dimensions",
    trigger: "dimensions",
  },
  {
    id: 5,
    text: "components.objectModal.aside.floor",
    trigger: "placement",
  },
  {
    id: 6,
    text: "components.objectModal.aside.additionalInfo",
    trigger: "information",
  },
];

export const editObjectAside: IAsideData[] = [
  {
    id: 1,
    text: "components.objectModal.aside.attachments",
    trigger: "attachments",
  },
  {
    id: 2,
    text: "components.objectModal.aside.amount",
    trigger: "amount",
  },
  {
    id: 3,
    text: "components.objectModal.aside.nameAndCategory",
    trigger: "category",
  },
  {
    id: 4,
    text: "components.objectModal.aside.dimensions",
    trigger: "dimensions",
  },
  {
    id: 5,
    text: "components.objectModal.aside.additionalInfo",
    trigger: "information",
  },
  {
    id: 6,
    text: "components.objectModal.aside.additionalComment",
    trigger: "comment",
  },
];
