import Row from "@avinet/adaptive-ui-core/layout/Row";
import React, { FC } from "react";

import { TextSwitch } from "../../text-switch";
import { ProfileNotificationOptionProps } from "./index.props";
import "./styles.scss";

export const ProfileNotificationOption: FC<ProfileNotificationOptionProps> = ({
  text,
  selectedOption,
  selectedOptionChange,
}) => {
  return (
    <Row className="notification-option">
      <p className="notification-option__text">{text}</p>
      <TextSwitch
        selectedOption={selectedOption}
        selectedOptionChange={selectedOptionChange}
      />
    </Row>
  );
};
