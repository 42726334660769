import { SVGProps } from "react";

export const PlugConnectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M19.8536 4.85355C20.0488 4.65829 20.0488 4.34171 19.8536 4.14645C19.6583 3.95118 19.3417 3.95118 19.1464 4.14645L16.4781 6.81475C14.8949 5.57957 12.6024 5.69014 11.1461 7.14646L10.8461 7.44646C10.2642 8.02832 10.2642 8.97171 10.8461 9.55357L14.4461 13.1536C15.0279 13.7354 15.9713 13.7354 16.5532 13.1536L16.8532 12.8536C18.3094 11.3974 18.42 9.10518 17.1852 7.52191L19.8536 4.85355ZM15.8461 12.4465C15.6547 12.6378 15.3445 12.6378 15.1532 12.4465L11.5532 8.84646C11.3618 8.65513 11.3618 8.34491 11.5532 8.15357L11.8532 7.85357C13.0386 6.66812 14.9606 6.66812 16.1461 7.85357C17.3315 9.03902 17.3315 10.961 16.1461 12.1465L15.8461 12.4465ZM9.55393 10.8464C8.97207 10.2646 8.02869 10.2646 7.44682 10.8464L7.14683 11.1464C5.69063 12.6026 5.57995 14.8948 6.8148 16.4781L4.14645 19.1464C3.95118 19.3417 3.95118 19.6583 4.14645 19.8536C4.34171 20.0488 4.65829 20.0488 4.85355 19.8536L7.52186 17.1852C9.10515 18.4204 11.3976 18.3099 12.8539 16.8535L13.1539 16.5535C13.7358 15.9717 13.7358 15.0283 13.1539 14.4464L9.55393 10.8464ZM8.15393 11.5535C8.34527 11.3622 8.65549 11.3622 8.84682 11.5535L12.4468 15.1535C12.6382 15.3449 12.6382 15.6551 12.4468 15.8464L12.1468 16.1464C10.9614 17.3319 9.03938 17.3319 7.85393 16.1464C6.66848 14.961 6.66848 13.039 7.85393 11.8535L8.15393 11.5535Z"
      fill="currentColor"
    />
  </svg>
);
