import Row from "@avinet/adaptive-ui-core/layout/Row";
import { useCallback, useState } from "react";

import { Text } from "../text";
import { ScoreCalculatorProps } from "./ScoreCalculator.props";
import "./ScoreCalculator.scss";

export const ScoreCalculator = ({
  value,
  updateProductScore,
}: ScoreCalculatorProps) => {
  const [val, setVal] = useState(value ?? 0);
  const scoreValues = [1, 2, 3, 4, 5];

  const handleSelectValue = useCallback(
    (score: number) => {
      if (val === score) {
        setVal(0);
        updateProductScore(0);
      } else {
        setVal(score);
        updateProductScore(score);
      }
    },
    [val, updateProductScore]
  );

  return (
    <Row className="score-calculator">
      {scoreValues.map((score) => (
        <div
          className={`score-calculator__value ${score === val ? "active" : ""}`}
          key={score}
          onClick={() => handleSelectValue(score)}
        >
          <Text text={`${score}`} fontWeight={600} size="sm" />
        </div>
      ))}
    </Row>
  );
};
