import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import Radio from "@avinet/adaptive-ui-core/form/controls/Radio";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import FileSaver from "file-saver";
import { useCallback } from "react";

import { KIND_RESPONSE } from "../api/api-problem";
import {
  ILocationProperties,
  IObjectProperties,
  IObjectPropertiesWithRecommendation,
  IProjectProperties,
} from "../api/types";
import { FeatureModalHeader } from "../components/feature/feature-modal-header";
import { Modal } from "../components/modal";
import { ALERT_MESSAGE_STATUS, ALERT_MESSAGE_TYPE } from "../contants/Enums";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../contants/FeatureUuid";
import { useNotificationApi } from "../context/NotificationProvider";
import { useEnvironment } from "../context/environment-context/EnvironmentContext";
import { Environment } from "../environment/environment";
import { useAttachments } from "../hooks/useAttachments";
import { useTranslationPath } from "../hooks/useTranslationPath";
import { ExportApi } from "../services/ExportApi";
import { FeaturesApi } from "../services/FeaturesApi";
import "./ReportOptionsModal.scss";
import { IReportConfig, IReportData } from "./ReportTypes";
import { REPORT_TYPE } from "./ReportTypes";

async function downloaProjectLocations(
  environment: Environment,
  orgId: number,
  projectId: number
) {
  //Download all locations in project
  const rows = "1000"; // TODO: loop instead of max 1000
  const response = await new FeaturesApi(
    environment.api
  ).apiDownloadGenericFeatures(
    FEATURE_UUID.PROJECT_LOCATIONS,
    orgId,
    `fk_project=${projectId}`,
    null,
    0,
    rows ?? "25",
    "DESC",
    "date_created",
    undefined
  );

  if (response) {
    return response?.data?.features as ILocationProperties[];
  }
  return [] as ILocationProperties[];
}

async function downloadProjectProducts(
  environment: Environment,
  orgId: number,
  projectId: number
) {
  //Download all products in project
  const rows = "1000"; // TODO: loop instead of max 1000
  const response = await new FeaturesApi(
    environment.api
  ).apiDownloadGenericFeatures(
    FEATURE_UUID.PROJECT_PRODUCTS,
    orgId,
    `fk_project=${projectId}`,
    null,
    0,
    rows ?? "25",
    "DESC",
    "date_created",
    undefined
  );

  if (response) {
    return response?.data?.features as IObjectPropertiesWithRecommendation[];
  }
  return [] as IObjectPropertiesWithRecommendation[];
}

const initialReportConfig: IReportConfig = {
  components: {
    id: true,
    image: true,
    quantity: true,
    quantity_unit: true,
    parent_category: true,
    category: true,
    element: true,
    specification: true,
    height: true,
    width: true,
    length: true,
    diameter: true,
    weight: true,
    producer: true,
    production_year: true,
    additional_comments: true,
    cost: true,
    co2: true,
    demountability_score: true,
    remaining_lifetime_score: true,
    volume_score: true,
    demand_score: true,
    environmental_effect_score: true,
    cost_use_score: true,
    // date_created: true,
    // date_modified: true,
    // created_by: true,
    // modified_by: true,
    // geom: true,
    // uuid: true,
    // fk_organization: true,
    // fk_location: true,
    // obj_condition: true,
  },
  locations: {
    id: true,
    geom: true,
    uuid: true,
    name: true,
    address: true,
    gnrbnr: true,
    fylke: true,
    kommune: true,
    owner: true,
    building_category: true,
    area: true,
    main_material_use: true,
    building_year: true,
    rehab_year: true,
    demolition_date: true,
    comments: true,
    status: true,
    image: true,
    type_of_demolition: true,
    type_of_location: true,
    kartlagt_fra: true,
    kartlagt_til: true,
    kartlagt_av: true,
    // fk_organization: true,
    // date_created: true,
    // date_modified: true,
    // created_by: true,
    // modified_by: true,
  },
};

function getReportName(
  extension: string,
  filename: string,
  projectDetails: IProjectProperties | undefined
) {
  let reportName =
    filename?.trim().replaceAll(" ", "").replaceAll(",", "_") ||
    projectDetails?.name?.trim().replaceAll(" ", "").replaceAll(",", "_") ||
    "";
  reportName = reportName.endsWith(`.${extension}`)
    ? reportName.slice(0, -extension.length - 1)
    : reportName;
  return reportName.replaceAll(".", "_");
}

export function ReportOptionsModal({
  projectDetails,
  onClose,
}: {
  projectDetails: IProjectProperties | undefined;
  onClose: (reportData?: IReportData | undefined) => void;
}) {
  const t = useTranslationPath("report.modal");

  const tToast = useTranslationPath("report.exportToast");

  const environment = useEnvironment();
  const { toast } = useNotificationApi();
  const { getAttachments, getAllProductImages } = useAttachments();

  const onSubmit = useCallback(
    async ({
      filename,
      pdf,
      xlxs,
      allImages,
    }: {
      filename: string;
      pdf: boolean;
      xlxs: boolean;
      allImages: boolean;
      standard: boolean;
    }) => {
      if (xlxs) {
        const reportName = getReportName(".xlsx", filename, projectDetails);
        
        if (!environment || !projectDetails?.fk_organization) return;

        new ExportApi(environment.api)
          .apiGetExportExcel(
            FEATURE_UUID.EXCEL_PRODUCTS_IN_PROJECT,
            projectDetails?.fk_organization,
            `fk_project=${projectDetails?.id}`
          )
          .then((response) => {
            const outputFilename = reportName + ".xlsx";

            if (response.kind === KIND_RESPONSE.OK) {
              FileSaver.saveAs(response.data, outputFilename);
              toast({
                title: tToast("titleSuccess"),
                message: tToast("messageExcel"),
                type: ALERT_MESSAGE_TYPE.FILE,
                status: ALERT_MESSAGE_STATUS.SUCCESS,
              });
            } else {
              toast({
                title: tToast("titleError"),
                message: tToast("messageErrorExcel"),
                type: ALERT_MESSAGE_TYPE.FILE,
                status: ALERT_MESSAGE_STATUS.FAILED,
              });
            }
            onClose();
          });
        return;
      } else if (pdf) {
        if (
          !environment ||
          !projectDetails?.fk_organization ||
          !projectDetails.id
        )
          return;
        const projectLocations = await downloaProjectLocations(
          environment,
          projectDetails?.fk_organization,
          projectDetails?.id
        );
        const projectProducts = await downloadProjectProducts(
          environment,
          projectDetails?.fk_organization,
          projectDetails?.id
        );

        const buildings = await Promise.all(
          projectLocations.map(async (pl) => {
            const filteredProducts = await Promise.all(
              projectProducts
                .filter((pp) => pp.fk_location === pl.id)
                .map(async (product) => {
                  const media =
                    product.id !== undefined
                      ? await getAllProductImages(product.id)
                      : [];

                  return { ...product, media_list: media };
                })
            );

            const groupedProducts: { [key: string]: IObjectProperties[] } =
              filteredProducts.reduce(
                (acc: { [key: string]: IObjectProperties[] }, product) => {
                  let { parent_category } = product;

                  if (!parent_category) parent_category = 0;
                  if (acc[(parent_category as number).toString()]) {
                    acc[parent_category].push(product);
                  } else {
                    acc[parent_category] = [product];
                  }
                  return acc;
                },
                {}
              );

            // const media_2 = await Promise.all(
            //   filteredProducts.map(
            //     async (product) =>
            //       product.id && (await getAllProductImages(product.id))
            //   )
            // );
            //(await getAllProductImages(filteredProducts[0].id));
            // console.log(media_2, "media");
            

            const media = allImages ? pl.id && (await getAttachments(FEATURE_MEDIA_TYPE.LOCATION, pl.id)) : [];

              console.log(groupedProducts, "groupedProducts");
              console.log(media, "media");

            return {
              locationData: pl,
              componentsDictionary: groupedProducts,
              media,
            };
          })
        );

        const reportName = getReportName(".pdf", filename, projectDetails);

        const reportData = {
          projectDetails,
          reportType: REPORT_TYPE.BREEAM,
          reportName: reportName,
          locations: buildings.map((building) => ({ ...building })),
          config: initialReportConfig,
          showAllImages: allImages,
        } as IReportData;

        toast({
          title: tToast("titleSuccess"),
          message: tToast("messagePdf"),
          type: ALERT_MESSAGE_TYPE.FILE,
          status: ALERT_MESSAGE_STATUS.SUCCESS,
        });

        onClose(reportData);
      }
    },
    [environment, getAllProductImages, getAttachments, onClose, projectDetails, tToast, toast]
  );

  const { form, state } = useForm({
    onSubmit,
    defaultValues: { pdf: true, standard: true, allImages: false },
  });

  const isPdfSelected = form.getValue("pdf") as boolean;
  const fileType = isPdfSelected ? ".pdf" : ".xlsx";

  return (
    <Modal className="report-options" show>
      <div className="modal-content-container">
        <FeatureModalHeader
          onClose={() => onClose()}
          title={t("modalTitle")}
          subtitle={t("modalDescription")}
        />
        <FormProvider form={form} state={state}>
        <form id="report-options" onSubmit={form.submit}>
            <p className="subheading">{t("chooseFileType")}</p>
            <Row className="format-options">
              <Radio group="fileformat" id="pdf" label={t("pdfLabel")} />
              <Radio group={"fileformat"} id={"xlxs"} label={t("excelLabel")} />
            </Row>
            {isPdfSelected && (
              <Row className="format-options">
                <Radio
                  group="formatMode"
                  id="standard"
                  label={t("standardLabel")}
                />
                <Radio
                  group={"formatMode"}
                  id={"allImages"}
                  label={t("allImagesLabel")}
                />
              </Row>
            )}
            <Input
              id="filename"
              type="text"
              minLength={3}
              label={t("filenameLabel")}
              placeholder={
                projectDetails?.name
                  ? projectDetails?.name
                      .trim()
                      .replaceAll(" ", "")
                      .replaceAll(".", "_")
                      .replaceAll(",", "_") + fileType
                  : t("filenamePlaceholder") + fileType
              }
            />
          </form>
          <Row className="buttom-buttons" wrap>
            <button
              className="btn light"
              type="button"
              onClick={() => onClose()}
            >
              {t("cancel")}
            </button>
            <button className="btn" type="submit" form="report-options">
              {t("next")}
            </button>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
}
