import React, { FC } from "react";

const MapIcon: FC = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20313 0.998816L7.25018 0.997559L7.29593 0.998767C7.31837 1.00006 7.34085 1.00239 7.36331 1.0058L7.37939 1.0096C7.4648 1.02332 7.54967 1.05383 7.63057 1.10175L7.68244 1.13529L12.7528 4.70056L17.8198 1.13864C18.2875 0.809848 18.9213 1.10537 18.9942 1.64772L19.0011 1.7522V12.3847C19.0011 12.5941 18.9138 12.7921 18.763 12.9329L18.6824 12.9983L13.1824 16.8647C12.9155 17.0523 12.5946 17.0366 12.3546 16.8878L7.24978 13.2966L2.1801 16.8613C1.71239 17.1901 1.07865 16.8946 1.00571 16.3523L0.998779 16.2478V5.61523C0.998779 5.40591 1.08613 5.20783 1.2369 5.06706L1.31746 5.00167L6.81746 1.13529C6.88146 1.0903 6.94857 1.057 7.01693 1.03442L7.1394 1.00642L7.20313 0.998816ZM17.5011 3.19621L13.5011 6.00812V14.8071L17.5011 11.9952V3.19621ZM6.49878 3.19287L2.49878 6.00478V14.8038L6.49878 11.9918V3.19287ZM8.00112 3.19287V11.9918L12.0011 14.8038V6.00478L8.00112 3.19287Z"
        fill="#7D89B0"
      />
    </svg>
  );
};

export default MapIcon;
