import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import React, { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { KIND_RESPONSE } from "../../api/api-problem";
import { ChangePasswordRequest } from "../../api/types";
import { PasswordInput } from "../../components/password-input/PasswordInput";
import { ImageBanner } from "../../components/register/image-banner";
import { RegisterContent } from "../../components/register/register-content";
import { RegisterTitle } from "../../components/register/register-title";
import { RegisterWrapper } from "../../components/register/register-wrapper";
import { AppUserContext } from "../../context/user-context/UserContext";

export const CreateNewPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { changePassword, changePasswordData } = useContext(AppUserContext);
  const formLabels = {
    oldPassword: t("form.oldPassword"),
    newPassword: t("form.newPassword"),
  };
  const formPlaceholders = {
    oldPasswordPlaceholder: t("form.oldPasswordPlaceholder"),
    newPasswordPlaceholder: t("form.newPasswordPlaceholder"),
  };
  const [formSent, setFormSent] = useState(false);

  const { form, state } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
    },
    onSubmit: (data: ChangePasswordRequest) => handleSubmitForm(data),
  });

  const handleSubmitForm = (data: ChangePasswordRequest) => {
    changePassword(data)
      .then(() => {
        setFormSent(true);
      })
      .catch(() => {
        console.error(data);
      });
  };

  const getErrorMsg = useCallback((): string => {
    switch (changePasswordData.error?.kind) {
      case KIND_RESPONSE.UNAUTHORIZED:
        return t("errors.errorChangePassword");
      case KIND_RESPONSE.REJECTED:
        return t("errors.errorReuseOldPassword");
      default:
        return "";
    }
  }, [t, changePasswordData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <RegisterWrapper>
      <RegisterContent withCopyrights>
        <RegisterTitle
          text={
            formSent
              ? t("pages.createNewPassword.leftSide.titlePassChanged")
              : t("pages.createNewPassword.leftSide.title")
          }
          description={
            formSent
              ? t("pages.createNewPassword.leftSide.passChangedDescription")
              : undefined
          }
        >
          {formSent ? (
            <div className="reset-password__result">
              <button className="btn light btn-cancel" onClick={handleGoBack}>
                {t("common.goBack")}
              </button>
            </div>
          ) : (
            <FormProvider form={form} state={state}>
              <form onSubmit={form.submit} className="form">
                <PasswordInput
                  id="oldPassword"
                  label={formLabels.oldPassword}
                  placeholder={formPlaceholders.oldPasswordPlaceholder}
                  error={!!changePasswordData?.error?.kind?.length}
                  required
                />
                <PasswordInput
                  id="newPassword"
                  label={formLabels.newPassword}
                  placeholder={formPlaceholders.newPasswordPlaceholder}
                  required
                  error={!!changePasswordData?.error?.kind?.length}
                  info={getErrorMsg() || ""}
                />
                <button className="btn btn-form">
                  {t("common.createPassword")}
                </button>
              </form>
            </FormProvider>
          )}
        </RegisterTitle>
      </RegisterContent>
      <ImageBanner
        title={t("pages.login.rightSide.title")}
        description={t("pages.login.rightSide.title2")}
      />
    </RegisterWrapper>
  );
};
