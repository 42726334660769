import { useEffect, useState } from "react";

import { usePortalTarget } from "./usePortalTarget";

/**
 * Implement popup logic that automatically hides the popup when user clicks
 * outside the target element.
 *
 * @param id DOM id of portal target
 * @param closeInside If true, will close after click inside the popup as well (useful for menus).
 */
export function usePopupTarget(id: string, closeInside = false) {
  const [isVisible, setVisible] = useState(false);
  const target = usePortalTarget(id);

  useEffect(() => {
    if (!isVisible) return;

    const listener = (e: TouchEvent | MouseEvent) => {
      if (isVisible && !target.contains(e.target as Node)) {
        setTimeout(() => setVisible(false), 200);
      }
    };

    const clickListener = () => {
      if (isVisible) {
        setVisible(false);
      }
    };

    setTimeout(() => {
      window.addEventListener("mousedown", listener);
      window.addEventListener("touchstart", listener);
      if (closeInside) window.addEventListener("click", clickListener);
    }, 0);

    return () => {
      window.removeEventListener("mousedown", listener);
      window.removeEventListener("touchstart", listener);
      if (closeInside) window.removeEventListener("click", clickListener);
    };
  }, [target, isVisible, closeInside]);

  return [target, isVisible, setVisible] as const;
}
