import i18next from "i18next";
import { useEffect, useState } from "react";

import { useEnvironment } from "../../context/environment-context/EnvironmentContext";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import { CMSApi } from "../../services/CMSApi";
import "../help/help-question/index";
import "./TermsAndPrivacy.scss";

export function TermsAndConditions() {
  const t = useTranslationPath("PrivacyConsent");
  const [content, setContent] = useState<string | TrustedHTML>("");
  const environment = useEnvironment();
  const currentLang = i18next.language;

  useEffect(() => {
    const articleKey = `terms_${currentLang}`;

    if (!environment) return;

    new CMSApi(environment?.api).getPage(1, articleKey).then((res) => {
      if (res.kind === "ok") setContent(res.data.d.records[0].content);
    });
  }, [currentLang, environment]);

  return (
    <div className="terms-and-privacy-page">
      <section className="content-container">
        <p className="site-label">{t("title")}</p>
        <article dangerouslySetInnerHTML={{ __html: content }} />
      </section>
    </div>
  );
}
