import { SelectEl } from "@avinet/adaptive-ui-core/form/controls/Select";
import { t } from "i18next";
import { useCallback, useContext, useLayoutEffect, useMemo } from "react";

import { FeatureContext } from "../../../../context/feature-context/FeatureContext";
import { Pagination } from "../../../pagination";
import { TableFooterProps } from "./index.props";
import "./styles.scss";

export function TableFooter({
  offset,
  handleSetOffset,
  totalFeaturesNumber,
  setActiveTab,
  activeTab,
  rowsPerPage,
  setRowsPerPage,
}: TableFooterProps) {
  const options = useMemo(
    () => [
      { value: "25", label: "25" },
      { value: "50", label: "50" },
      { value: "75", label: "75" },
    ],
    []
  );

  const handleSetRows = useCallback(
    (value: string | number | boolean | null) => {
      setRowsPerPage(value as string);
      setActiveTab(activeTab);
      handleSetOffset(0);
    },
    [setRowsPerPage, setActiveTab, activeTab, handleSetOffset]
  );

  const handlePaging = useCallback(
    (offset: number, limit?: number) => {
      setActiveTab(activeTab);
      handleSetOffset(offset < 0 || offset > totalFeaturesNumber ? 0 : offset);
      if (limit !== undefined) setRowsPerPage(rowsPerPage);
    },
    [
      handleSetOffset,
      totalFeaturesNumber,
      setActiveTab,
      activeTab,
      setRowsPerPage,
      rowsPerPage,
    ]
  );

  useLayoutEffect(() => {
    activeTab && handleSetOffset(offset);
  }, [activeTab, handleSetOffset, offset]);

  useLayoutEffect(() => {
    setRowsPerPage(rowsPerPage);
  }, [rowsPerPage, setRowsPerPage]);

  return (
    <>
      <div className="pagetable-footer">
        <SelectEl
          id="rowsPerPage"
          label={t("components.pageTable.rowsPerPage")}
          value={rowsPerPage}
          items={options}
          onChange={handleSetRows}
        />
        <Pagination
          offset={offset}
          limit={parseInt(rowsPerPage)}
          total={totalFeaturesNumber}
          onPaging={handlePaging}
        />
      </div>
    </>
  );
}
