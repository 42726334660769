import React from "react";
import Skeleton from "react-loading-skeleton";

import "./Assessment.scss";

export function AssessmentSkeleton() {
  return (
    <>
      <Skeleton
        width="100%"
        height={80}
        borderRadius={8}
        className="help-question-skeleton-title"
      />

      <Skeleton
        width="100%"
        height={80}
        borderRadius={8}
        className="help-question-skeleton-title"
      />
      <Skeleton
        width="100%"
        height={80}
        borderRadius={8}
        className="help-question-skeleton-title"
      />
      <Skeleton
        width="100%"
        height={80}
        borderRadius={8}
        className="help-question-skeleton-title"
      />
      <Skeleton
        width="100%"
        height={80}
        borderRadius={8}
        className="help-question-skeleton-title"
      />
    </>
  );
}
