import { useCallback, useState } from "react";

import { KIND_RESPONSE } from "../api/api-problem";
import { IMediaFeature, PostMedia, isPostMedia } from "../api/types";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../contants/FeatureUuid";
import { useEnvironment } from "../context/environment-context/EnvironmentContext";
import { MediaApi } from "../services/MediaApi";
import { ProductApi } from "../services/ProductApi";
import { isFileMIMEImageType } from "../utils/index.utils";

export function useAttachments() {
  const environment = useEnvironment();

  const [fetchedAttachments, setFetchedAttachments] =
    useState<IMediaFeature[]>();

  const [fetchedProductImages, setFetchedProductImages] =
    useState<IMediaFeature[]>();

  const getAttachments = useCallback(
    async (featureType: FEATURE_MEDIA_TYPE, featureId: number) => {
      setFetchedAttachments(undefined);
      if (!environment) return;

      const filterString = `${featureType}=${featureId}`;

      const response = await new MediaApi(environment.api).downloadAttachments(
        filterString
      );

      if (response.kind === KIND_RESPONSE.OK) {
        setFetchedAttachments(response?.data?.features);

        return response?.data?.features;
      }
    },
    [environment]
  );

  const getAllProductImages = useCallback(
    async (productId: number) => {
      setFetchedProductImages(undefined);
      if (!environment) return;

      const response = await new ProductApi(environment.api).getProductImages(
        productId
      );

      if (response.kind === KIND_RESPONSE.OK) {
        setFetchedProductImages(response.data);

        return response?.data;
      }
    },
    [environment]
  );

  const updateMedia = useCallback(
    (media: IMediaFeature) => {
      if (!environment) return;
      return new MediaApi(environment.api).updateMedia(
        FEATURE_UUID.MEDIA,
        media
      );
    },
    [environment]
  );

  const postMedia = useCallback(
    (
      postMedia: PostMedia,
      toFeatureType: FEATURE_MEDIA_TYPE,
      toFeatureId: number
    ) => {
      if (!environment) return;

      return new MediaApi(environment.api).postMedia({
        ...postMedia,
        [toFeatureType]: toFeatureId,
      });
    },
    [environment]
  );

  const deleteMedia = useCallback(
    (media: IMediaFeature) => {
      if (!environment || media.id === undefined) return;
      return new MediaApi(environment.api).deleteMedia(
        FEATURE_UUID.MEDIA,
        media.id
      );
    },
    [environment]
  );

  const postOrUpdateMedia = useCallback(
    async (
      data:
        | Record<string, IMediaFeature | PostMedia>
        | (IMediaFeature | PostMedia)[],
      featureMediaType: FEATURE_MEDIA_TYPE,
      featureId: number
    ) => {
      const toSave = Object.values(data).filter(Boolean);

      const hasSelectedThumbnail = toSave.some(
        (file) => !file._delete && file.is_thumbnail
      );

      if (!hasSelectedThumbnail) {
        const thumbnailIndex = toSave.findIndex(
          (file) => !file._delete && isFileMIMEImageType(file.file_type)
        );

        if (toSave[thumbnailIndex]) toSave[thumbnailIndex].is_thumbnail = true;
      }

      for (const attachment of toSave) {
        if (isPostMedia(attachment)) {
          await postMedia(attachment, featureMediaType, Number(featureId));
        } else if (attachment._delete) {
          await deleteMedia(attachment);
        } else {
          await updateMedia(attachment);
        }
      }
    },
    [deleteMedia, postMedia, updateMedia]
  );

  return {
    getAttachments,
    fetchedAttachments,
    fetchedProductImages,
    getAllProductImages,
    updateMedia,
    postMedia,
    postOrUpdateMedia,
    deleteMedia,
  };
}
