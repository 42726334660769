import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import { CheckBoxEl } from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm, { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperRefType } from "swiper/types";

import { IMediaFeature, PostMedia, isPostMedia } from "../../api/types";
import { THUMBNAIL_SIZE } from "../../contants/Enums";
import { useNotificationApi } from "../../context/NotificationProvider";
import { AppUserContext } from "../../context/user-context/UserContext";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import {
  checkImageFileType,
  getFileType,
  getMediaUri,
  isFileMIMEImageType,
} from "../../utils/index.utils";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { FileDropzone } from "../file-dropzone";
import { Icon } from "../icon/Icon";
import { Modal } from "../modal";
import { NoImagePlaceholer } from "../no-image";
import { SliderCircleNav } from "../slider-circle-nav";
import { Text } from "../text";
import { AttachmentModalProps } from "./AttachmentModal.props";
import "./AttachmentModal.scss";

export function AttachmentModal({
  attachments,
  onClose,
  onSave,
  setDirty,
  onDropImage,
  handleRemoveDropImage,
}: AttachmentModalProps) {
  const { t } = useTranslation();
  const tCommon = useTranslationPath("common");
  const tCancel = useTranslationPath("components.objectModal.confirmCancel");

  const [swiperRef, setSwiperRef] = useState<SwiperRefType>();
  const [slide, setSlide] = useState<number>(0);
  const { token } = useContext(AppUserContext);
  const [isDirtyState, setIsDirtyState] = useState(false);

  const { notificationDialog } = useNotificationApi();

  const hasSaveOption = !!onSave;

  const handlePrevNavigation = useCallback(() => {
    slide !== undefined && swiperRef?.slidePrev();
  }, [swiperRef, slide]);

  const handleNextNavigation = useCallback(() => {
    slide !== undefined && swiperRef?.slideNext();
  }, [swiperRef, slide]);

  const handleSlide = useCallback((s: SwiperRefType) => {
    setSlide(s.activeIndex);
  }, []);

  const onSubmit = useCallback(
    (data: Record<string, IMediaFeature | PostMedia>) => {
      if (!isDirtyState) {
        onClose();
        return;
      }

      if (onSave) {
        onSave(data);
      } else {
        onClose(data);
      }
    },
    [isDirtyState, onSave, onClose]
  );

  const defaultValues = useMemo(() => {
    const obj: Record<string, IMediaFeature | PostMedia> = {};

    attachments?.forEach((attachment) => {
      if (isPostMedia(attachment)) {
        obj[attachment.generated_uuid] = attachment;
      } else {
        obj[attachment.media] = attachment;
      }
    });

    return obj;
  }, [attachments]);

  const { form, state, isDirty } = useForm<
    Record<string, IMediaFeature | PostMedia>
  >({
    onSubmit,
    defaultValues,
  });

  const handleCancel = useCallback(async () => {
    if (isDirty) {
      const choices = await notificationDialog({
        title: tCancel("title"),
        message: tCancel("message"),
        icon: "warning",
        variant: "danger",
        choices: [
          {
            label: tCommon("keep"),
            value: "keep",
          },
          {
            label: tCommon("discard"),
            value: "discard",
            primaryOption: true,
          },
        ],
      });

      if (choices === "keep") {
        return;
      }
    }

    onClose();
  }, [isDirty, onClose, notificationDialog, tCancel, tCommon]);

  useEffect(() => {
    if (isDirty && setDirty) setDirty(true);
    setIsDirtyState(isDirty);
  }, [isDirty, setDirty]);

  return (
    <Modal className="attachment-modal" show>
      <FormProvider form={form} state={state}>
        <div className="attachment-modal-container">
          <FeatureModalHeader
            onClose={hasSaveOption ? handleCancel : form.submit}
            title={t("components.attachmentModal.title")}
            subtitle={t("components.attachmentModal.description")}
            noBorder
          />
          <div className="attachment-modal__form">
            <div className="attachment-modal__form__inputs">
              <form id="attachment-form" onSubmit={form.submit}>
                {attachments?.map((attachment, i) => (
                  <AttachmentForm
                    key={
                      isPostMedia(attachment)
                        ? attachment.generated_uuid
                        : attachment.media
                    }
                    isActive={i === slide}
                    attachmentId={
                      isPostMedia(attachment)
                        ? attachment.generated_uuid
                        : attachment.media
                    }
                    defaultValue={attachment}
                  />
                ))}
              </form>
            </div>

            {attachments?.[slide] && (
              <div className="attachment-modal__form__slider">
                {attachments[slide] && (
                  <DeleteAttachmentButton
                    attachment={attachments[slide]}
                    handleRemoveDropImage={handleRemoveDropImage}
                  />
                )}

                {attachments.length > 1 && (
                  <SliderCircleNav
                    className="attachment-modal__arrow attachment-modal__arrow--prev"
                    prev
                    onClick={handlePrevNavigation}
                  />
                )}

                <ImageRenderer
                  token={token}
                  attachment={attachments[slide]}
                  typeIconsBig
                />

                {attachments.length > 1 && (
                  <SliderCircleNav
                    className="attachment-modal__arrow attachment-modal__arrow--next"
                    onClick={handleNextNavigation}
                  />
                )}
              </div>
            )}
          </div>

          <div className="attachment-modal__details">
            <div className="attachment-modal__details__dropzone">
              <FileDropzone
                attachmentButtonClick={undefined}
                onDrop={onDropImage}
                maxFiles={20}
              />
            </div>
            {!!attachments?.length && (
              <div className="attachment-modal__details__slider-row">
                <SliderCircleNav
                  className="attachment-modal__arrow attachment-modal__arrow--prev"
                  prev
                  colorPrimary
                  onClick={handlePrevNavigation}
                />
                <div className="attachment-modal__bottom-swiper">
                  <Swiper
                    onSwiper={setSwiperRef}
                    spaceBetween={10}
                    slidesPerView={5}
                    onSlideChange={handleSlide}
                    centeredSlides
                    className={
                      attachments.length > 3 ? "swiper--left-shadow" : ""
                    }
                  >
                    {attachments.map((attachment, index) => (
                      <SwiperSlide
                        key={index}
                        onClick={() => swiperRef?.slideTo(index)}
                        className={attachment._delete ? "deleted" : ""}
                      >
                        <ImageRenderer
                          attachment={attachment}
                          isActive={slide === index}
                          token={token}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <SliderCircleNav
                  className="attachment-modal__arrow attachment-modal__arrow--next"
                  colorPrimary
                  onClick={handleNextNavigation}
                />
                <Text
                  text={t("components.attachmentModal.sliderOf", {
                    number: slide + 1,
                    length: attachments.length,
                  })}
                  size="small"
                />
              </div>
            )}
          </div>

          <Row className="button-row">
            <button
              className={`btn ${hasSaveOption ? "light" : ""}`}
              onClick={hasSaveOption ? handleCancel : form.submit}
            >
              {hasSaveOption ? t("common.back") : t("common.backToForm")}
            </button>
            {hasSaveOption && (
              <button className="btn" onClick={form.submit}>
                {t("common.save")}
              </button>
            )}
          </Row>
        </div>
      </FormProvider>
    </Modal>
  );
}

function AttachmentForm({
  isActive,
  attachmentId,
  defaultValue,
}: {
  isActive: boolean;
  attachmentId: string;
  defaultValue: IMediaFeature | PostMedia;
}) {
  const tForm = useTranslationPath("form");
  const tCommon = useTranslationPath("common");
  const { t } = useTranslation();
  const { getValue, setValue, getState } = useFormApi();
  const isThumbnail = getValue(`${attachmentId}.is_thumbnail`);
  const value = getValue(attachmentId);

  useEffect(() => {
    if (value === undefined) {
      setValue(attachmentId, defaultValue);
    }
  }, [attachmentId, defaultValue, setValue, value]);

  const onThumnailChange = useCallback(
    (checked: boolean) => {
      const state = getState();
      setValue(`${attachmentId}.is_thumbnail`, checked);

      for (const key of Object.keys(state as object)) {
        if (key !== attachmentId) {
          setValue(`${key}.is_thumbnail`, false);
        }
      }
    },
    [attachmentId, getState, setValue]
  );

  const isDisabled = useMemo(
    () => !isFileMIMEImageType(value?.file_type),
    [value]
  );

  return (
    <div className={"attachment-input-fields " + (isActive ? "active" : "")}>
      <Input
        id={`${attachmentId}.title`}
        placeholder={tForm("titleImagePlaceholder")}
        label={tForm("title")}
      />
      <Input
        id={`${attachmentId}.description`}
        type="textarea"
        label={tForm("description")}
        placeholder={tForm("titleImageDescription")}
      />
      <div title={isDisabled ? tCommon("tumbnailNotUsable") : undefined}>
        <CheckBoxEl
          id={`${attachmentId}.is_thumbnail`}
          label={t("common.useAsThumbnail")}
          value={isThumbnail}
          onChange={onThumnailChange}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

function ImageRenderer({
  attachment,
  token,
  isActive,
  typeIconsBig,
}: {
  attachment: IMediaFeature | PostMedia | undefined;
  token: string | null;
  isActive?: boolean;
  typeIconsBig?: boolean;
}) {
  const attachmentId = useMemo(
    () =>
      attachment &&
      (isPostMedia(attachment) ? attachment.generated_uuid : attachment.media),
    [attachment]
  );

  const { getValue } = useFormApi();
  const isDeleted = getValue(`${attachmentId}._delete`);

  const className =
    (isActive ? "active" : "") + " " + (isDeleted ? "deleted" : "");

  if (
    attachment &&
    (checkImageFileType(attachment?.title) ||
      attachment?.file_type?.includes("image"))
  ) {
    if (typeof attachment.media === "string") {
      return (
        <img
          src={getMediaUri(attachment?.media, token, THUMBNAIL_SIZE.LARGE)}
          alt={attachment?.media}
          className={className}
        />
      );
    } else {
      return (
        <img
          src={attachment.media.dataUrl}
          alt={attachment?.media.name}
          className={className}
        />
      );
    }
  } else
    return (
      <NoImagePlaceholer
        className={className}
        type={getFileType(attachment?.file_type)}
        typeIconsBig={typeIconsBig}
      />
    );
}

function DeleteAttachmentButton({
  attachment,
  handleRemoveDropImage,
}: {
  attachment: IMediaFeature | PostMedia;
  handleRemoveDropImage: (attachment: IMediaFeature | PostMedia) => void;
}) {
  const { t } = useTranslation();
  const { getValue, setValue } = useFormApi();

  const attachmentId = useMemo(
    () =>
      isPostMedia(attachment) ? attachment.generated_uuid : attachment.media,
    [attachment]
  );

  const isDeleted = getValue(`${attachmentId}._delete`);

  const handleRemoveAttachment = useCallback(() => {
    handleRemoveDropImage(attachment);
    setValue(`${attachmentId}._delete`, !isDeleted);

    if (isPostMedia(attachment)) {
      setValue(attachmentId, undefined);
    }
  }, [attachment, attachmentId, handleRemoveDropImage, isDeleted, setValue]);

  return (
    <button
      className={
        "btn delete-attachment-btn " + (isDeleted ? "light" : "btn-delete")
      }
      onClick={handleRemoveAttachment}
    >
      <Icon name={isDeleted ? "arrowUndo" : "delete"} />
      {isDeleted ? t("common.undoDelete") : t("common.deleteAttachment")}
    </button>
  );
}
