import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { contactEmailAddress } from "../../contants/Mailing";
import "./styles.scss";

export const Copyrights: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="copyrights">
      <p className="copyrights__copy">{t("common.copyrights")}</p>
      <div className="copyrights__link">
        <Icon name="envelope" className="copyrights__link__icon" />
        <Link
          className="copyrights__link__text"
          to={`mailto:${contactEmailAddress}`}
        >
          {contactEmailAddress}
        </Link>
      </div>
    </div>
  );
};
