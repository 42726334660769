import React from "react";

const CourseStarBigIcon = () => {
  return (
    <svg
      className="course-box__star1"
      width="187"
      height="143"
      viewBox="0 0 187 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10908_8013)">
        <path
          d="M93.5 0L98.1458 41.2001C100.956 66.2651 120.735 86.0167 145.8 88.8542L187 93.5L145.8 98.1458C120.735 100.956 100.983 120.735 98.1458 145.8L93.5 187L88.8542 145.8C86.0445 120.735 66.2651 100.983 41.2001 98.1458L0 93.5L41.2001 88.8542C66.2651 86.0445 86.0167 66.2651 88.8542 41.2001L93.5 0Z"
          fill="#6239D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_10908_8013">
          <rect width="187" height="187" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CourseStarBigIcon;
