import { useKeyPressHandler } from "./useKeyPress";

/**
 * Captures the Escape keydown event and triggers the callback.
 *
 * If callback is undefined, Escape keydown is not captured.
 *
 * @param callback Function provided should be stable. False or undefined means key is not captured.
 */
export function useEscapeKeyHandler(callback?: (() => void) | false) {
  useKeyPressHandler("Escape", callback);
}
