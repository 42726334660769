import i18next, { t } from "i18next";
import { useEffect, useState } from "react";

import { CMSResponse } from "../../api/types";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { Text } from "../../components/text";
import { Title } from "../../components/title";
import { contactEmailAddress } from "../../contants/Mailing";
import { useEnvironment } from "../../context/environment-context/EnvironmentContext";
import { CMSApi } from "../../services/CMSApi";
import { HelpQuestion, HelpQuestionSkeleton } from "./help-question";
import "./styles.scss";

export function Help() {
  const environment = useEnvironment();
  const currentLang = i18next.language;

  const [faqArticle, setFaqArticle] = useState<
    CMSResponse["d"]["records"] | null
  >(null);

  useEffect(() => {
    if (!environment) return;

    new CMSApi(environment.api).getPage(1, `faq_${currentLang}`).then((res) => {
      if (res.kind === "ok") {
        setFaqArticle(res.data.d.records);
      }
    });
  }, [currentLang, environment]);

  return (
    <SidebarPage>
      <div className="help-container">
        <div className="help-wrapper">
          <Text
            text={t("pages.help.aboveHeader")}
            tag="span"
            size="md"
            color="primary-contrast"
            fontWeight={600}
          />
          <Title
            title={t("pages.help.header")}
            className="help-header"
            size="lg"
            fontWeight={600}
          />
          <Text
            text={t("pages.help.subheader")}
            tag="span"
            size="medium"
            color="dark-contrast"
          />
          <hr className="help-line" />
          <div className="help-intouch-wrapper">
            <div className="help-intouch-text-wrapper">
              <Text
                text={t("pages.help.formHeader")}
                tag="span"
                size="xl"
                color="dark"
                fontWeight={600}
              />
              <Text
                text={t("pages.help.formSubheader")}
                tag="span"
                size="small"
                color="dark"
              />
            </div>
            <a
              href={`mailto:${contactEmailAddress}?Subject=${t(
                "pages.help.emailSubject"
              )}`}
            >
              <button>{t("pages.help.formButton")}</button>
            </a>
          </div>
          <div className="help-questions-wrapper">
            {faqArticle
              ? faqArticle.map((article) =>
                  article.content
                    .toString()
                    .split('<details open="">')
                    .slice(1)
                    .map((el, index) => (
                      <HelpQuestion key={index} content={`<details>${el}`} />
                    ))
                )
              : Array.from(Array(5).keys()).map((_, index) => (
                  <HelpQuestionSkeleton key={index} />
                ))}
          </div>
        </div>
      </div>
    </SidebarPage>
  );
}
