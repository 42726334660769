import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, {
  ControlProps,
  GroupBase,
  MultiValue,
  SingleValue,
  components,
} from "react-select";

import { LANGUAGE } from "../../contants/Language";
import { ISelectedOption } from "../profile/profile-preferences";
import { LanguageSelectProps } from "./index.props";
import "./styles.scss";

export const LanguageSelect: FC<LanguageSelectProps> = ({
  selectedOption,
  setSelectedOption,
  className,
  noFlag,
  customOptions,
}) => {
  const { t } = useTranslation();

  const options: ISelectedOption[] = useMemo(
    () => [
      { value: "en", label: t("components.languages.en") },
      { value: "no", label: t("components.languages.no") },
    ],
    [t]
  );

  const displayFlag = useCallback(() => {
    if (selectedOption !== null) {
      switch (Object.values(selectedOption)?.[0]) {
        case LANGUAGE.ENGLAND:
          return "enFlag";
        case LANGUAGE.NORWAY:
          return "noFlag";
        default:
          return "enFlag";
      }
    } else {
      return "enFlag";
    }
  }, [selectedOption]);

  const displayDefaultValue = useCallback(() => {
    if (selectedOption !== null) {
      switch (Object.values(selectedOption)?.[0]) {
        case LANGUAGE.ENGLAND:
          return 0;
        case LANGUAGE.NORWAY:
          return 1;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }, [selectedOption]);

  const Control = ({
    children,
    ...props
  }: ControlProps<ISelectedOption, boolean, GroupBase<ISelectedOption>>) => {
    const style = { cursor: "pointer" };

    return (
      <components.Control {...props}>
        <span style={style} className="language-select__control">
          {!noFlag && <Icon name={displayFlag()} />}
        </span>
        {children}
      </components.Control>
    );
  };

  const handleChange = (
    newValue:
      | ISelectedOption
      | MultiValue<ISelectedOption>
      | SingleValue<ISelectedOption>
  ) => {
    setSelectedOption(newValue);
  };

  return (
    <Select
      className={className ? className : "language-select"}
      value={
        customOptions
          ? customOptions[displayDefaultValue()]
          : options[displayDefaultValue()]
      }
      options={customOptions ? customOptions : options}
      onChange={handleChange}
      components={{ Control }}
      classNamePrefix="language-select"
      classNames={{
        control: (state) =>
          state.isFocused
            ? "language-select--no-border"
            : "language-select__control",
        option: (state) =>
          state.isSelected
            ? "language-select__option--selected"
            : "language-select__option",
        dropdownIndicator: () => "language-select__indicator",
        indicatorSeparator: () => "language-select__indicator--separator",
        menu: () => "language-select__menu",
        menuList: () => "language-select__menu-list",
        container: () => "language-select__container",
      }}
    />
  );
};
