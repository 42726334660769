import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import {
  PopupMenu,
  PopupMenuButton,
} from "@avinet/adaptive-ui-core/ui/PopupMenu";
import Switch from "@avinet/adaptive-ui-core/ui/Switch";
import { FullScreenMaximize20Regular } from "@fluentui/react-icons/lib/fonts";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { IProductWithMappings } from "../../api/types";
import {
  ASSESSMENT_RATINGS,
  OBJECT_STATUS,
  THUMBNAIL_SIZE,
} from "../../contants/Enums";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import { AppUserContext } from "../../context/user-context/UserContext";
import { useObjConditionQuantity } from "../../hooks/useObjConditionQuantity";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import {
  getMediaUri,
  handleTranslateQuantityUnit,
  setScoreColor,
} from "../../utils/index.utils";
import { InputSelectEl } from "../input-select/InputSelect";
import { NoImagePlaceholer } from "../no-image";
import { ObjectStatus } from "../object/object-status";
import { ScoreCalculator } from "../score-calculator/ScoreCalculator";
import { Text } from "../text";
import { TotalScore } from "../total-score/TotalScore";
import { AssessmentProps } from "./Assessment.props";
import "./Assessment.scss";

export function Assessment({
  object,
  selectedAssessmentId,
  setSelectedAssessmentId,
  objectScore,
  setShowFullscreenModal,
}: AssessmentProps) {
  const [editMode, setEditMode] = useState(!!objectScore);
  const { token } = useContext(AppUserContext);
  const {
    editAssessmentScore,
    editAssessmentRecommendation,
    getProductAssessments,
  } = useContext(FeatureContext);
  const { assessmentRatings } = useSelectOptions();
  const tPath = useTranslationPath("components.assessment");
  const { t } = useTranslation();
  const {
    allGoodQuantity,
    allMediumQuantity,
    allBadQuantity,
    allNoConditionQuantity,
  } = useObjConditionQuantity(object);
  const customLifecycleScore = useMemo(
    () => ({
      good: 4,
      medium: 3,
      bad: 2,
    }),
    []
  );

  const defaultFormValues = {
    demountability_score: 0,
    remaining_lifetime_score: 0,
    volume_score: 0,
    demand_score: 0,
    environmental_effect_score: 0,
    cost_use_score: 0,
  };

  const { form, state } = useForm({
    defaultValues: defaultFormValues,
  });

  const imgWarningRender = useCallback(
    (mapping?: IProductWithMappings) => {
      return (
        <>
          {mapping && Number(mapping?.remaining_lifetime_score) <= 3 && (
            <div
              className={`assessment__image__warning ${
                mapping?.remaining_lifetime_score <= customLifecycleScore.medium
                  ? setScoreColor(
                      Number(mapping?.remaining_lifetime_score) ?? 0,
                      customLifecycleScore
                    )
                  : ""
              }`}
            >
              <Icon name="warning" />
              <Text
                text={
                  mapping?.remaining_lifetime_score <= customLifecycleScore.bad
                    ? tPath("lifecycle.veryLow")
                    : tPath("lifecycle.low")
                }
                size="sm"
                fontWeight={600}
              />
            </div>
          )}
        </>
      );
    },
    [customLifecycleScore, tPath]
  );

  const renderAssessmentImage = useCallback(
    (
      image?: string | null,
      name?: string,
      mapping?: IProductWithMappings,
      lifeCycleInvalid?: boolean
    ) => {
      return (
        <>
          {image && image.length ? (
            <div
              className={`assessment__image ${
                mapping ? "assessment__image--mapping img-hover" : ""
              }`}
            >
              {lifeCycleInvalid !== undefined && (
                <button
                  className="btn light assessment__image__btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFullscreenModal &&
                      setShowFullscreenModal({
                        showModal: true,
                        sliderData: [
                          {
                            src: getMediaUri(image, token),
                            alt: name ?? "",
                          },
                        ],
                      });
                  }}
                >
                  <FullScreenMaximize20Regular />
                  <Text
                    className="slider-preview__fullscreen__text"
                    text={t("common.fullscreen")}
                    fontWeight={600}
                    size="sm"
                    tag="span"
                  />
                </button>
              )}
              {!lifeCycleInvalid && imgWarningRender(mapping)}
              <LazyLoadImage
                alt={name}
                src={getMediaUri(image, token, THUMBNAIL_SIZE.SMALL)}
                effect="blur"
                className={`assessment__image ${
                  mapping ? "assessment__image--mapping__img" : ""
                }`}
                wrapperClassName="assessment__image-wrapper"
              />
            </div>
          ) : (
            <div className="assessment__no-image">
              {!lifeCycleInvalid && imgWarningRender(mapping)}
              <NoImagePlaceholer
                className={
                  mapping ? "assessment__image--mapping" : "assessment__image"
                }
              />
            </div>
          )}
        </>
      );
    },
    [t, token, imgWarningRender, setShowFullscreenModal]
  );

  const renderScoreCalculations = useCallback(
    (obj: IProductWithMappings) => [
      {
        title: tPath("calculation.disassemblability"),
        tooltipMessageOne: tPath("calculation.disassemblabilityTooltipOne"),
        tooltipMessageTwo: tPath("calculation.disassemblabilityTooltipTwo"),
        key: "demountability_score",
        score: obj.demountability_score,
      },
      {
        title: tPath("calculation.remainingLife"),
        tooltipMessageOne: tPath("calculation.remainingLifeTooltipOne"),
        tooltipMessageTwo: tPath("calculation.remainingLifeTooltipTwo"),
        tooltipMessageThree: tPath("calculation.remainingLifeTooltipThree"),
        key: "remaining_lifetime_score",
        score: obj.remaining_lifetime_score,
      },
      {
        title: tPath("calculation.volume"),
        tooltipMessageOne: tPath("calculation.volumeTooltipOne"),
        tooltipMessageTwo: tPath("calculation.volumeTooltipTwo"),
        tooltipMessageThree: tPath("calculation.volumeTooltipThree"),
        key: "volume_score",
        score: obj.volume_score,
      },
      {
        title: tPath("calculation.demand"),
        tooltipMessageOne: tPath("calculation.demandTooltipOne"),
        tooltipMessageTwo: tPath("calculation.demandTooltipTwo"),
        key: "demand_score",
        score: obj.demand_score,
      },
      {
        title: tPath("calculation.production"),
        tooltipMessageOne: tPath("calculation.productionTooltipOne"),
        tooltipMessageTwo: tPath("calculation.productionTooltipTwo"),
        tooltipMessageThree: tPath("calculation.productionTooltipThree"),
        key: "environmental_effect_score",
        score: obj.environmental_effect_score,
      },
      {
        title: tPath("calculation.cost"),
        tooltipMessageOne: tPath("calculation.costTooltipOne"),
        tooltipMessageTwo: tPath("calculation.costTooltipTwo"),
        key: "cost_use_score",
        score: obj.cost_use_score,
      },
    ],
    [tPath]
  );

  const handleUpdateProductScore = useCallback(
    async (mapping: IProductWithMappings, key: string, score: number) => {
      await editAssessmentScore(
        mapping.fk_product,
        {
          demountability_score: mapping.demountability_score ?? 0,
          remaining_lifetime_score: mapping.remaining_lifetime_score ?? 0,
          volume_score: mapping.volume_score ?? 0,
          demand_score: mapping.demand_score ?? 0,
          environmental_effect_score: mapping.environmental_effect_score ?? 0,
          cost_use_score: mapping.cost_use_score ?? 0,
          product_condition: mapping.obj_condition ?? "No condition",
          [key]: score,
        },
        objectScore ? undefined : mapping.fk_project
      );

      await getProductAssessments(String(mapping.fk_product));
    },
    [editAssessmentScore, objectScore, getProductAssessments]
  );

  const handleUpdateProductRecommendation = useCallback(
    async (
      mapping: IProductWithMappings,
      value: string | number | boolean | null
    ) => {
      mapping.fk_project &&
        (await editAssessmentRecommendation(mapping.fk_project, {
          fk_product: mapping.fk_product,
          product_condition:
            mapping.obj_condition ?? OBJECT_STATUS.NO_CONDITION,
          recommendation: value?.toString() ?? ASSESSMENT_RATINGS.NOT_RATED,
        }));
    },
    [editAssessmentRecommendation]
  );

  const scoreInvalid = useCallback((mapping: IProductWithMappings) => {
    return (
      mapping.demountability_score === 0 ||
      mapping.demountability_score === null ||
      mapping.remaining_lifetime_score === 0 ||
      mapping.remaining_lifetime_score === null ||
      mapping.volume_score === 0 ||
      mapping.volume_score === null ||
      mapping.demand_score === 0 ||
      mapping.demand_score === null ||
      mapping.environmental_effect_score === 0 ||
      mapping.environmental_effect_score === null ||
      mapping.cost_use_score === 0 ||
      mapping.cost_use_score === null
    );
  }, []);

  const handleUpdateEmptyRecommendation = useCallback(
    async (mapping: IProductWithMappings) => {
      if (mapping?.recommendation === null || mapping?.recommendation === "") {
        await handleUpdateProductRecommendation(
          mapping,
          ASSESSMENT_RATINGS.NOT_RATED
        );
      }
    },
    [handleUpdateProductRecommendation]
  );

  const handleAccordion = useCallback(
    (object?: IProductWithMappings) => {
      !objectScore && setEditMode(false);
      setSelectedAssessmentId(
        object?.fk_product === selectedAssessmentId
          ? undefined
          : object?.fk_product
      );

      object?.productLocationMappings?.length &&
        object?.productLocationMappings.map(async (mapping) => {
          await handleUpdateEmptyRecommendation(mapping);
        });
    },
    [
      selectedAssessmentId,
      objectScore,
      setSelectedAssessmentId,
      handleUpdateEmptyRecommendation,
    ]
  );

  return (
    <div
      className={`assessment ${
        object?.fk_product === selectedAssessmentId ? "assessment--active" : ""
      }`}
    >
      <div className="assessment__info" onClick={() => handleAccordion(object)}>
        <Row>
          <Row className="assessment__info__name">
            {renderAssessmentImage(
              object?.mapping_image ? object?.mapping_image : object?.image,
              object?.producer
            )}
            <Text
              text={object?.specification ?? ""}
              size="medium"
              fontWeight={700}
              className="assessment__title"
            />
          </Row>
          <Row className="assessment__info__status">
            {allGoodQuantity > 0 && (
              <ObjectStatus
                text={OBJECT_STATUS.GOOD}
                status={OBJECT_STATUS.GOOD}
                withAmount={allGoodQuantity}
              />
            )}
            {allMediumQuantity > 0 && (
              <ObjectStatus
                text={OBJECT_STATUS.MEDIUM}
                status={OBJECT_STATUS.MEDIUM}
                withAmount={allMediumQuantity}
              />
            )}
            {allBadQuantity > 0 && (
              <ObjectStatus
                text={OBJECT_STATUS.BAD}
                status={OBJECT_STATUS.BAD}
                withAmount={allBadQuantity}
              />
            )}
            {allNoConditionQuantity > 0 && (
              <ObjectStatus
                text={OBJECT_STATUS.NO_CONDITION}
                status={OBJECT_STATUS.NO_CONDITION}
                withAmount={allNoConditionQuantity}
              />
            )}
          </Row>
        </Row>
        <div className="assessment__chevron">
          <Text
            className="assessment__chevron__text"
            text={tPath("open")}
            tag="p"
            size="xs"
            fontWeight={600}
          />
          <Icon
            name="chevron"
            rotate={object?.fk_product === selectedAssessmentId ? 270 : 90}
          />
        </div>
      </div>
      {object?.fk_product === selectedAssessmentId && (
        <>
          <Row className="assessment__gray-wrapper assessment__calc-info">
            <div>
              <Text text={tPath("calcTitle")} fontWeight={700} size="md" />
              <br />
              <Text text={tPath("calcText1")} size="md" />
              <br />
              <Text text={tPath("calcText2")} size="md" />
            </div>
            <div
              className="assessment__calc-info__switch"
              onClick={() => setEditMode((prev) => !prev)}
            >
              <Text text={tPath("switchEditMode")} size="sm" fontWeight={600} />
              <Switch value={editMode} onChange={setEditMode} />
            </div>
          </Row>
          {object?.productLocationMappings?.length &&
            object.productLocationMappings?.map((mapping, index) => {
              const mappingScore =
                mapping.demountability_score +
                mapping.remaining_lifetime_score +
                mapping.volume_score +
                mapping.demand_score +
                mapping.environmental_effect_score +
                mapping.cost_use_score;

              const mappingScoreInvalid = scoreInvalid(mapping);
              const scoreCalculations = renderScoreCalculations(mapping) ?? 0;

              return (
                <FormProvider key={index} form={form} state={state}>
                  <form id="object-score">
                    <Row className="assessment__bottom">
                      {renderAssessmentImage(
                        mapping?.mapping_image || mapping?.image,
                        mapping.obj_condition,
                        mapping,
                        mapping.remaining_lifetime_score === 0 ||
                          mapping.remaining_lifetime_score === null
                      )}
                      <div className="assessment__gray-wrapper assessment__mapping__details">
                        <Column className="assessment__white-wrapper">
                          <Text
                            text={tPath("quantity")}
                            size="md"
                            fontWeight={700}
                            className="assessment__white-wrapper__title-left"
                          />
                          <Text
                            className="item-quantity"
                            text={
                              (mapping?.sum
                                ? mapping?.sum?.toString()
                                : mapping?.quantity?.toString()) +
                              " " +
                              (mapping?.quantity_unit
                                ? handleTranslateQuantityUnit(
                                    mapping?.quantity_unit?.toString()
                                  )
                                : "stk")
                            }
                            size="xs"
                            fontWeight={500}
                          />
                        </Column>
                        <Column className="assessment__white-wrapper">
                          <Text
                            text={tPath("objStatus")}
                            size="md"
                            fontWeight={700}
                            className="assessment__white-wrapper__title-left"
                          />
                          <ObjectStatus
                            text={
                              mapping?.obj_condition?.length
                                ? mapping?.obj_condition
                                : t("options.condition.noCondition")
                            }
                            status={
                              mapping?.obj_condition?.length
                                ? mapping?.obj_condition
                                : OBJECT_STATUS.NO_CONDITION
                            }
                          />
                        </Column>
                      </div>
                      <div
                        className={`assessment__gray-wrapper assessment__calculator ${
                          !editMode ? "assessment__calculator--spacing" : ""
                        }`}
                      >
                        {scoreCalculations.map((calculation) => {
                          return (
                            <Column
                              className={`assessment__calculator__score ${
                                !editMode ? "assessment__white-wrapper" : ""
                              } ${
                                !editMode &&
                                !(
                                  mapping.remaining_lifetime_score === 0 ||
                                  mapping.remaining_lifetime_score === null
                                ) &&
                                calculation.title ===
                                  tPath("calculation.remainingLife")
                                  ? setScoreColor(
                                      calculation.score ?? 0,
                                      customLifecycleScore
                                    )
                                  : ""
                              }`}
                              key={calculation.title}
                            >
                              <Row className="assessment__calculator__score__top-row">
                                <Text
                                  text={calculation.title}
                                  fontWeight={700}
                                  size="xs"
                                />

                                <PopupMenuButton
                                  className="assessment__tooltip info-icon"
                                  icon="info"
                                  menu={
                                    <PopupMenu className="emission-card-info-popup">
                                      <Text
                                        text={calculation.title}
                                        fontWeight={700}
                                        size="small"
                                        color="dark"
                                      />
                                      <Text
                                        text={calculation.tooltipMessageOne}
                                        size="small"
                                      />
                                      {calculation.tooltipMessageTwo && (
                                        <Text
                                          text={calculation.tooltipMessageTwo}
                                          size="small"
                                        />
                                      )}
                                      {calculation.tooltipMessageThree && (
                                        <Text
                                          text={calculation.tooltipMessageThree}
                                          size="small"
                                        />
                                      )}
                                    </PopupMenu>
                                  }
                                />
                              </Row>
                              {editMode ? (
                                <ScoreCalculator
                                  value={calculation.score}
                                  updateProductScore={(score) =>
                                    handleUpdateProductScore(
                                      mapping,
                                      calculation.key,
                                      score
                                    )
                                  }
                                />
                              ) : (
                                <Text
                                  className="assessment__calculator__score__text"
                                  text={`${calculation.score ?? 0}/5`}
                                  fontWeight={700}
                                  size="md"
                                />
                              )}
                            </Column>
                          );
                        })}
                      </div>
                      <Column className="assessment__gray-wrapper assessment__total-score">
                        <Column className="assessment__white-wrapper assessment__total-score__top">
                          {mappingScoreInvalid ? (
                            <div className="assessment__invalid-score">
                              <Icon name="info" />
                              <Text
                                className="assessment__invalid-score__text"
                                text={tPath("invalidScore")}
                                size="sm"
                                fontWeight={500}
                              />
                            </div>
                          ) : (
                            <>
                              <Text
                                text={tPath("score")}
                                size="md"
                                fontWeight={700}
                                className="assessment__white-wrapper__title"
                              />
                              <TotalScore score={mappingScore} />
                            </>
                          )}
                        </Column>
                        {!objectScore && (
                          <InputSelectEl
                            id="recommendation"
                            value={mapping?.recommendation ?? ""}
                            multi={false}
                            onChange={(value) =>
                              handleUpdateProductRecommendation(mapping, value)
                            }
                            label={null}
                            items={assessmentRatings}
                          />
                        )}
                      </Column>
                    </Row>
                  </form>
                </FormProvider>
              );
            })}
        </>
      )}
    </div>
  );
}
