import { useCheckMobile } from "../../../../hooks/useCheckMobile";
import { DropdownTabs } from "../dropdown-tabs/DropdownTabs";
import { HorizontalTabs } from "../horizontal-tabs";
import { DropdownTabsProps } from "./index.props";

export function TabSwitch({
  tabs = [],
  activeTab,
  setActiveTab,
  onClick,
}: DropdownTabsProps) {
  const { isMobile } = useCheckMobile(800);

  return (
    <div className="pagetable-tabs-wrapper">
      {isMobile ? (
        <DropdownTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onClick={onClick}
        />
      ) : (
        <HorizontalTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onClick={onClick}
        />
      )}
    </div>
  );
}
