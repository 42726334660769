import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import {
  AddObjectForm,
  AssessmentRecommendationBody,
  AssessmentScoreBody,
  IConnectedLocationsBody,
  IObjectResponse,
  IProductWithMappings,
  MediaResponseList,
  ObjectResponse,
} from "../api/types";
import { OBJECT_STATUS } from "../contants/Enums";
import { APIResponseType, Api } from "./api/api";

interface FeatureResponse<T> {
  data: {
    features: T[];
  };
  total: number;
  returned: number;
  nextLimit: number;
  nextOffset: number;
}

export class ProductApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async createProduct(
    body: AddObjectForm
  ): Promise<APIResponseType<IObjectResponse>> {
    const mappings = body?.productLocationMappings?.map((mapping) => {
      if (mapping.obj_condition === OBJECT_STATUS.NO_CONDITION) {
        return {
          ...mapping,
          obj_condition: null,
        };
      } else {
        return mapping;
      }
    });

    const response: ApiResponse<IObjectResponse> = await this.api.apisauce.post(
      `ombruk/product/product`,
      {
        fk_location: body.fk_location || null,
        product: {
          image: body.image,
          productLocationMappings: mappings ?? [],
          parent_category: body.parent_category || 0,
          category: body.category || 0,
          element: body.element || 0,
          height: body.height || 0,
          width: body.width || 0,
          length: body.length || 0,
          diameter: body.diameter || 0,
          weight: body.weight || 0,
          producer: body.producer,
          production_year: body.production_year,
          additional_comments: body.additional_comments,
          specification: body.specification,
          quantity_unit: body.quantity_unit,
          cost: body.cost,
          co2: body.co2,
          description: body.description,
        },
      }
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async updateProduct(body: AddObjectForm, productId: number): Promise<any> {
    const mappings = body?.productLocationMappings?.map((mapping) => {
      if (mapping.obj_condition === OBJECT_STATUS.NO_CONDITION) {
        return {
          ...mapping,
          obj_condition: null,
        };
      } else {
        return mapping;
      }
    });

    const response: ApiResponse<any> = await this.api.apisauce.put(
      `ombruk/product/product/${productId}`,
      {
        image: body.image,
        productLocationMappings: mappings ?? [],
        parent_category: body.parent_category || 0,
        category: body.category || 0,
        element: body.element || 0,
        height: body.height || 0,
        width: body.width || 0,
        length: body.length || 0,
        diameter: body.diameter || 0,
        weight: body.weight || 0,
        producer: body.producer,
        production_year: body.production_year,
        additional_comments: body.additional_comments,
        specification: body.specification,
        quantity_unit: body.quantity_unit,
        cost: body.cost,
        co2: body.co2,
        description: body.description,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getProduct(productId: number): Promise<ObjectResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/product/product/${productId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getLocationsProducts(locationId: number): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/product/location/${locationId}`,
      {
        sort_order: "DESC",
        sort_column: "date_created",
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getOrganizationProducts(
    organizationId: number,
    productStatus?: string,
    rows?: string,
    offset?: number
  ): Promise<APIResponseType<FeatureResponse<IProductWithMappings>>> {
    const condition = productStatus?.length
      ? ` obj_condition='${productStatus}'`
      : "";

    const response: ApiResponse<FeatureResponse<IProductWithMappings>> =
      await this.api.apisauce.get(
        `ombruk/product/${organizationId}?filter=${condition}`,
        {
          offset: offset,
          limit: rows,
          sort_order: "DESC",
          sort_column: "date_created",
        }
      );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      return problem ?? { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async apiUpdateScore(id: number, body: AssessmentScoreBody): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      `ombruk/product/assess/${id}`,
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async apiUpdateRecommendation(
    id: number,
    body: AssessmentRecommendationBody
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      `ombruk/product/recommend/${id}`,
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async apiConnectProjectToLocation(
    locationId: number,
    body: IConnectedLocationsBody[]
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `ombruk/location/projects/${locationId}`,
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async apiConnectLocationToProject(
    projectId: number,
    locationIds: number[]
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `ombruk/project/locations/${projectId}`,
      locationIds
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getProductImages(productId: number): Promise<MediaResponseList> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/product/images/${productId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }
}
