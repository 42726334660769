import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useMemo } from "react";

import {
  IObjectProperties,
  IObjectPropertiesWithRecommendation,
} from "../api/types";
import { THUMBNAIL_SIZE } from "../contants/Enums";
import { getMediaUri, guidGenerator } from "../utils/index.utils";
import { ReportComponent } from "./ReportComponent";
import {
  IConditionQuantityDictionary,
  IConditionQuantityMapping,
  IConditionRowData,
  IReportConfig,
} from "./ReportTypes";
import { IContextFunctions } from "./ReportTypes";
import { colors } from "./colors";

const styles = StyleSheet.create({
  parentCategoryHeader: {
    width: "100%",
    backgroundColor: colors.lightContrast,
    color: colors.lightTextColor,
    fontWeight: "bold",
    fontSize: 20,
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    marginBottom: 20,
  },
  imageContainer: {
    marginVertical: 10,
    height: 200,
    width: "49%",
    objectFit: "scale-down",
    justifyContent: "center",
    alignItems: "center",
  },
  ImageGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
  },
});

export const ReportComponentCategoryView = ({
  parent_category,
  productMappings,
  config,
  contextFunctions,
  token,
  showAllImages,
}: {
  parent_category: string;
  productMappings: IObjectProperties[];
  config: IReportConfig;
  contextFunctions: IContextFunctions;
  token: string | null;
  showAllImages: boolean;
}) => {
  const { components: componentsConfig } = config;
  const { getParentCategoryName } = contextFunctions;
  const conditionDictionary = productMappings.reduce(
    (
      acc: IConditionQuantityDictionary,
      obj: IObjectPropertiesWithRecommendation
    ) => {
      if (!obj.id || !obj.obj_condition) return acc;
      if (Object.hasOwn(acc, obj.id.toString())) {
        if (Object.hasOwn(acc[obj.id?.toString()], obj.obj_condition)) {
          const m = acc[obj.id][obj.obj_condition as string];
          m.quantity = m.quantity + (obj.quantity || 0);
          acc[obj.id][obj.obj_condition as string] = m;
        } else {
          const newConditionQuantityMapping = {
            obj_condition: obj.obj_condition || "",
            quantity: obj.quantity || 0,
            demountability_score: obj.demountability_score || 0,
            remaining_lifetime_score: obj.remaining_lifetime_score || 0,
            volume_score: obj.volume_score || 0,
            demand_score: obj.demand_score || 0,
            environmental_effect_score: obj.environmental_effect_score || 0,
            cost_use_score: obj.cost_use_score || 0,
            recommendation: obj.recommendation || "",
          } as IConditionRowData;
          acc[obj.id][obj.obj_condition as string] =
            newConditionQuantityMapping;
        }
      } else {
        // const newConditionQuantityMapping = {} ;
        const newConditionQuantityMapping = {
          obj_condition: obj.obj_condition || "",
          quantity: obj.quantity || 0,
          demountability_score: obj.demountability_score || 0,
          remaining_lifetime_score: obj.remaining_lifetime_score || 0,
          volume_score: obj.volume_score || 0,
          demand_score: obj.demand_score || 0,
          environmental_effect_score: obj.environmental_effect_score || 0,
          cost_use_score: obj.cost_use_score || 0,
          recommendation: obj.recommendation || "",
        } as IConditionRowData;
        acc[obj.id] = {} as IConditionQuantityMapping;
        acc[obj.id][obj.obj_condition as string] = newConditionQuantityMapping;
      }
      return acc;
    },
    {}
  );

  const filteredProductMappings = productMappings.reduce(
    (acc: IObjectProperties[], obj: IObjectProperties) => {
      if (!acc.find((item) => item.id === obj.id)) {
        acc.push(obj);
      }
      return acc;
    },
    []
  );

  const parentCategoryString = useMemo(() => {
    return getParentCategoryName(parseInt(parent_category));
  }, [getParentCategoryName, parent_category]);

  return (
    <View break>
      <View style={styles.parentCategoryHeader}>
        <Text>{parentCategoryString}</Text>
      </View>

      {filteredProductMappings.map((plm: IObjectProperties, index) => {
        if (!plm.id) return null;
        return (
          <>
            <ReportComponent
              key={guidGenerator()}
              index={index}
              mapping={plm}
              conditionDictionary={conditionDictionary[plm.id]}
              config={componentsConfig}
              contextFunctions={contextFunctions}
              token={token}
            />

            {showAllImages &&
              plm.media_list !== undefined &&
              plm.media_list !== null && (
                <View style={styles.ImageGrid}>
                  {plm.media_list
                    ?.filter(
                      (mediaItem) =>
                        mediaItem.file_type.includes("image") &&
                        mediaItem.media !== plm.image
                    )
                    .map((mediaItem) => (
                      <View key={mediaItem.uuid} style={styles.imageContainer}>
                        <Image
                          src={getMediaUri(
                            mediaItem.media,
                            token,
                            THUMBNAIL_SIZE.MEDIUM
                          )}
                        />
                      </View>
                    ))}
                </View>
              )}
          </>
        );
      })}
    </View>
  );
};
