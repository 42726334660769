export const baseUrl = "/";
export const registerUrl = "/opprettkonto";
export const loginUrl = "/logginn";
export const resetPasswordUrl = "/tilbakestill-passord";
export const createNewPasswordUrl = "/opprettnyttpassord";
export const enterCodeUrl = "/registrer";
export const selectOrganisationUrl = "/velgorganisasjon";
export const dashboardUrl = "/hjem";
export const projectsUrl = "/prosjekter";
export const projectsTabAssessments = "vurderinger";
export const projectsTabLocations = "lokasjoner";
export const projectsTabExternalFiles = "eksterne-filer";
export const locationsUrl = "/lokasjoner";
export const locationTabConnectedComponents = "komponent-liste";
export const locationTabObjects = "komponenter";
export const locationTabProjects = "prosjekter";
export const locationTabAttachments = "vurderinger";
export const objectsUrl = "/komponenter";
export const objectsTabAttachments = "vurderinger";
export const objectsTabObjectList = "komponent-liste";
export const objectsTabPlacement = "plassering";
export const objectsTabProjects = "prosjekter";
export const notificationsUrl = "/notifikasjoner";
export const mapsUrl = "/ombrukskartet";
export const helpUrl = "/hjelp";
export const addUrl = "/legg-til";
export const profileUrl = "/profil";
export const joinOrganisationUrl = `/bli-med`;
export const reportUrl = "/rapport";
export const privacyUrl = "/personvern";
export const termsAndConditionsUrl = "/vilkar";
export const organizationDetailsUrl = "/organisasjon";
export const error404url = "/404";
