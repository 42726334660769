import { SVGProps } from "react";

export const NoImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    viewBox="15 15 26 26"
    {...props}
  >
    <path
      d="M34.75 20C36.5449 20 38 21.4551 38 23.25V34.75C38 36.5449 36.5449 38 34.75 38H23.25C21.4551 38 20 36.5449 20 34.75V28.5019C20.4743 28.6996 20.9769 28.8428 21.5001 28.9236L21.5 34.75C21.5 34.9584 21.5364 35.1583 21.6033 35.3437L27.4258 29.643C28.2589 28.8273 29.5675 28.7885 30.4458 29.5266L30.5742 29.6431L36.3964 35.3447C36.4634 35.159 36.5 34.9588 36.5 34.75V23.25C36.5 22.2835 35.7165 21.5 34.75 21.5L28.9236 21.5001C28.8428 20.9769 28.6996 20.4743 28.5019 20H34.75ZM28.5588 30.644L28.4752 30.7148L22.6685 36.4011C22.8504 36.4651 23.0461 36.5 23.25 36.5H34.75C34.9535 36.5 35.1489 36.4653 35.3305 36.4014L29.5247 30.7148C29.2596 30.4553 28.8501 30.4316 28.5588 30.644ZM32.2521 23.5C33.4959 23.5 34.5042 24.5083 34.5042 25.7521C34.5042 26.9959 33.4959 28.0042 32.2521 28.0042C31.0083 28.0042 30 26.9959 30 25.7521C30 24.5083 31.0083 23.5 32.2521 23.5ZM22.5 17C25.5376 17 28 19.4624 28 22.5C28 25.5376 25.5376 28 22.5 28C19.4624 28 17 25.5376 17 22.5C17 19.4624 19.4624 17 22.5 17ZM32.2521 25C31.8367 25 31.5 25.3367 31.5 25.7521C31.5 26.1675 31.8367 26.5042 32.2521 26.5042C32.6675 26.5042 33.0042 26.1675 33.0042 25.7521C33.0042 25.3367 32.6675 25 32.2521 25ZM22.5 18.9992L22.4101 19.0073C22.206 19.0443 22.0451 19.2053 22.0081 19.4094L22 19.4992L21.9997 21.9992L19.4976 22L19.4078 22.0081C19.2037 22.0451 19.0427 22.206 19.0057 22.4101L18.9976 22.5L19.0057 22.5899C19.0427 22.794 19.2037 22.9549 19.4078 22.9919L19.4976 23L22.0006 22.9992L22.0011 25.5035L22.0092 25.5934C22.0462 25.7975 22.2071 25.9584 22.4112 25.9954L22.5011 26.0035L22.591 25.9954C22.7951 25.9584 22.956 25.7975 22.9931 25.5934L23.0011 25.5035L23.0006 22.9992L25.5046 23L25.5944 22.9919C25.7985 22.9549 25.9595 22.794 25.9965 22.5899L26.0046 22.5L25.9965 22.4101C25.9595 22.206 25.7985 22.0451 25.5944 22.0081L25.5046 22L22.9997 21.9992L23 19.4992L22.9919 19.4094C22.9549 19.2053 22.794 19.0443 22.5899 19.0073L22.5 18.9992Z"
      fill="#6239D9"
    />
  </svg>
);
