import React, { FC } from "react";

const HomeIcon: FC = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54952 0.531895C8.38743 -0.174695 9.61257 -0.174694 10.4505 0.531896L17.2005 6.224C17.7074 6.65152 18 7.2809 18 7.94406V17.7468C18 18.7133 17.2165 19.4968 16.25 19.4968H12.75C11.7835 19.4968 11 18.7133 11 17.7468V12.2468C11 12.1088 10.8881 11.9968 10.75 11.9968H7.24999C7.11192 11.9968 6.99999 12.1088 6.99999 12.2468V17.7468C6.99999 18.7133 6.2165 19.4968 5.25 19.4968H1.75C0.783502 19.4968 0 18.7133 0 17.7468V7.94406C0 7.2809 0.292551 6.65152 0.799517 6.224L7.54952 0.531895ZM9.48349 1.6786C9.20419 1.44307 8.79581 1.44307 8.51651 1.6786L1.76651 7.37071C1.59752 7.51321 1.5 7.72301 1.5 7.94406V17.7468C1.5 17.8849 1.61193 17.9968 1.75 17.9968H5.25C5.38807 17.9968 5.49999 17.8849 5.49999 17.7468V12.2468C5.49999 11.2803 6.2835 10.4968 7.24999 10.4968H10.75C11.7165 10.4968 12.5 11.2803 12.5 12.2468V17.7468C12.5 17.8849 12.6119 17.9968 12.75 17.9968H16.25C16.3881 17.9968 16.5 17.8849 16.5 17.7468V7.94406C16.5 7.72301 16.4025 7.51321 16.2335 7.37071L9.48349 1.6786Z"
        fill="#7D89B0"
      />
    </svg>
  );
};

export default HomeIcon;
