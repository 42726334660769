import { IAsideData } from "../../../utils/types/types";

export const projectAside: IAsideData[] = [
  {
    id: 1,
    text: "components.projectModal.aside.attachments",
    trigger: "attachments",
  },
  {
    id: 2,
    text: "components.projectModal.aside.project",
    trigger: "project",
  },
  {
    id: 3,
    text: "components.projectModal.aside.locations",
    trigger: "locations",
  },
];
