import { SVGProps } from "react";

export const LogoNameIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="123" height="48" viewBox="0 0 123 48" fill="none" {...props}>
    <g clipPath="url(#clip0_6606_446761)">
      <path
        d="M38 36C44.6274 36 50 30.6274 50 24C50 17.3726 44.6274 12 38 12C31.3726 12 26 17.3726 26 24C26 30.6274 31.3726 36 38 36Z"
        fill="#6239D9"
      />
      <path
        d="M35.14 21.3603C35.35 20.8603 35.11 20.2803 34.61 20.0703C34.11 19.8603 33.53 20.1003 33.32 20.6003L30.82 26.6303C30.61 27.1303 30.85 27.7103 31.35 27.9203C31.85 28.1303 32.43 27.8903 32.64 27.3903L35.14 21.3603Z"
        fill="white"
      />
      <path
        d="M38.36 21.3603C38.15 20.8603 38.39 20.2803 38.89 20.0703C39.39 19.8603 39.97 20.1003 40.18 20.6003L42.68 26.6303C42.89 27.1303 42.65 27.7103 42.15 27.9203C41.65 28.1303 41.07 27.8903 40.86 27.3903L38.36 21.3603Z"
        fill="white"
      />
      <path
        d="M43.36 20.61C43.57 20.11 44.14 19.87 44.65 20.08C45.16 20.29 45.39 20.86 45.18 21.37L44.34 23.39C44.13 23.89 43.56 24.13 43.05 23.92C42.55 23.71 42.31 23.14 42.52 22.63L43.36 20.61Z"
        fill="white"
      />
      <path
        d="M36.8 24.6198C36.59 24.1198 36.02 23.8798 35.51 24.0898C35.01 24.2998 34.77 24.8698 34.98 25.3798L35.82 27.3998C36.03 27.8998 36.6 28.1398 37.11 27.9298C37.61 27.7198 37.85 27.1498 37.64 26.6398L36.8 24.6198Z"
        fill="white"
      />
      <path
        d="M60.61 18.48C61.61 18.48 62.52 18.81 63.19 19.36C63.45 19.57 63.84 19.39 63.84 19.05V15.61C63.84 15.39 64.02 15.21 64.24 15.21H66.65C66.87 15.21 67.05 15.39 67.05 15.61V28.69C67.05 28.91 66.87 29.09 66.65 29.09H64.24C64.02 29.09 63.84 28.91 63.84 28.69V28.65C63.84 28.31 63.44 28.13 63.18 28.36C62.56 28.89 61.69 29.24 60.61 29.24C58.04 29.24 56 27.14 56 23.84C56 20.54 58.04 18.48 60.61 18.48ZM61.55 21.27C60.35 21.27 59.26 22.17 59.26 23.84C59.26 25.51 60.35 26.45 61.55 26.45C62.75 26.45 63.84 25.53 63.84 23.86C63.84 22.19 62.77 21.27 61.55 21.27Z"
        fill="#6239D9"
      />
      <path
        d="M74.01 29.2405C70.94 29.2405 68.72 27.1805 68.72 23.8605C68.72 20.5405 70.89 18.4805 74.01 18.4805C77.13 18.4805 79.22 20.5005 79.22 23.6905C79.22 23.9205 79.21 24.1605 79.19 24.4005C79.18 24.5305 79.07 24.6205 78.94 24.6205H72.37C72.11 24.6205 71.93 24.8605 71.99 25.1105C72.24 26.1405 73 26.6205 73.9 26.6205C74.7 26.6205 75.18 26.2705 75.47 25.8005C75.54 25.6805 75.67 25.6105 75.81 25.6105H78.45C78.73 25.6105 78.91 25.8905 78.82 26.1505C78.14 27.9205 76.39 29.2305 74.02 29.2305L74.01 29.2405ZM72.44 22.8505H75.68C75.83 22.8505 75.94 22.7205 75.92 22.5805C75.79 21.6105 74.95 21.0505 73.97 21.0505C73.09 21.0505 72.38 21.5005 72.07 22.3205C71.97 22.5705 72.17 22.8505 72.44 22.8505Z"
        fill="#6239D9"
      />
      <path
        d="M81.3 15.2197H83.71C83.93 15.2197 84.11 15.3997 84.11 15.6197V28.6997C84.11 28.9197 83.93 29.0997 83.71 29.0997H81.3C81.08 29.0997 80.9 28.9197 80.9 28.6997V15.6197C80.9 15.3997 81.08 15.2197 81.3 15.2197Z"
        fill="#6239D9"
      />
      <path
        d="M86.14 15.8201C86.14 14.8501 86.91 14.0801 88.05 14.0801C89.19 14.0801 89.94 14.8501 89.94 15.8201C89.94 16.7901 89.17 17.5401 88.05 17.5401C86.93 17.5401 86.14 16.7701 86.14 15.8201ZM86.84 18.6301H89.25C89.47 18.6301 89.65 18.8101 89.65 19.0301V28.7001C89.65 28.9201 89.47 29.1001 89.25 29.1001H86.84C86.62 29.1001 86.44 28.9201 86.44 28.7001V19.0301C86.44 18.8101 86.62 18.6301 86.84 18.6301Z"
        fill="#6239D9"
      />
      <path
        d="M96.73 29.2405C93.66 29.2405 91.33 27.1805 91.33 23.8605C91.33 20.5405 93.71 18.4805 96.77 18.4805C99.83 18.4805 102.21 20.5405 102.21 23.8605C102.21 27.1805 99.81 29.2405 96.74 29.2405H96.73ZM96.73 26.4705C97.87 26.4705 98.94 25.6305 98.94 23.8605C98.94 22.0905 97.89 21.2505 96.77 21.2505C95.65 21.2505 94.6 22.0705 94.6 23.8605C94.6 25.6505 95.57 26.4705 96.74 26.4705H96.73Z"
        fill="#6239D9"
      />
    </g>
    <defs>
      <clipPath id="clip0_6606_446761">
        <rect
          width="76.2"
          height="24"
          fill="white"
          transform="translate(26 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoNameIcon;
