import React, { FC, useCallback } from "react";

import { ProfileCardProps } from "./index.props";
import "./styles.scss";

export const ProfileCard: FC<ProfileCardProps> = ({
  children,
  flow = 0,
  className,
  lastCard,
}) => {
  const handleChidlrenFlow = useCallback(() => {
    switch (flow) {
      case 0:
        return "profile-card__column";
      case 1:
        return "profile-card__row";
      default:
        return "profile-card__column";
    }
  }, [flow]);

  return (
    <section
      className={`profile-card ${handleChidlrenFlow()} ${
        className ? className : ""
      } ${lastCard ? "profile-card__last" : ""}`}
    >
      {children}
    </section>
  );
};
