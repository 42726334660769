import { useTranslation } from "react-i18next";

import { KIND_RESPONSE } from "../api/api-problem";

export const useErrorMessage = () => {
  const { t } = useTranslation();

  const getErrorMessage = (
    response: KIND_RESPONSE,
    errorMessageText?: string
  ) => {
    switch (response) {
      case KIND_RESPONSE.UNAUTHORIZED:
        return errorMessageText
          ? errorMessageText
          : t("errors.errorChangePassword");
      case KIND_RESPONSE.SERVER:
      case KIND_RESPONSE.NOT_FOUND:
      case KIND_RESPONSE.CANNOT_CONNECT:
      case KIND_RESPONSE.UNKNOWN:
        return t("errors.unexpectedError");
      case KIND_RESPONSE.BAD_DATA:
        return errorMessageText || "";
      case KIND_RESPONSE.CONFLICT:
        return errorMessageText || "";

      default:
        return t("errors.unexpectedError");
    }
  };

  return { getErrorMessage };
};
