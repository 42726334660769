export enum FEATURE_UUID {
  LOCATION = "596098e8-8991-459a-96f4-e6a8a47bc2ff",
  LOCATION_PROJECTS = "109b4f8c-1e48-47f3-9ff8-65e1c3b6a602",
  MEDIA = "f805c4b0-b104-4676-bd49-c33047e0f43d",
  ORGANIZATION = "aeac9e3a-706b-4b25-9def-8a1c5ac06277",
  PRODUCT = "7ad07dfc-998b-491e-9dd5-8bf90d2406f2",
  PRODUCT_PROJECTS = "f12283b2-a1b0-4866-9325-c79f08e87b7d",
  PROJECT = "8ae0e8d5-131b-4622-bb90-74fd19ddaaa5",
  PROJECT_LOCATIONS = "7bb754f0-bd56-43d9-bafe-df0250942279",
  PROJECT_PRODUCTS = "cbdd8705-3075-4b76-a0c5-7d6629b1183f",
  PROJECT_PRODUCTS_WITH_MAPPINGS = "04375f49-d916-4926-bead-4927cabb284c",
  EXCEL_PRODUCTS_IN_PROJECT = "bebef7af-42f1-4c48-a4c9-262eb20bd116",
}

export enum FEATURE_MEDIA_TYPE {
  ORGANIZATION = "fk_organization",
  LOCATION = "fk_location",
  PRODUCT = "fk_product",
  PRODUCT_MAPPING = "fk_product_location_mapping",
  PROJECT = "fk_project",
}

export type FEATURE_MEDIA_TYPES =
  | "product"
  | "product_mapping"
  | "organization"
  | "location"
  | "project";
