import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MediaContext } from "../../../context/media-context/MediaContext";
import { IAddNewLink } from "../../../utils/types/types";
import { LocationModal } from "../../add-new-feature/location/LocationModal";
import { ObjectModal } from "../../add-new-feature/object/ObjectModal";
import { ProjectModal } from "../../add-new-feature/project/ProjectModal";
import { AddNewLink } from "../../add-new-link";
import { AddNewSidebarProps } from "./index.props";
import "./styles.scss";

export const AddNewSidebar: FC<AddNewSidebarProps> = ({
  sidebarIsCollapsed,
  isCollapsed,
  setCollapsed,
}) => {
  const { t } = useTranslation();
  const [showButtons, setShowButtons] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showObjectModal, setShowObjectModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const { setAttachments } = useContext(MediaContext);
  const [createdLocationId, setCreatedLocationId] = useState<
    number | undefined
  >(undefined);

  const handleLocationModal = (state: boolean) => {
    setShowLocationModal(state);
  };

  const handleAttachmentModal = useCallback(() => {
    setShowAttachmentModal((prev) => !prev);
  }, [setShowAttachmentModal]);

  const closeObjectModal = useCallback(() => {
    setShowObjectModal(false);
    createdLocationId && setCreatedLocationId(undefined);
  }, [createdLocationId]);

  const handleProjectModal = useCallback(() => {
    setShowProjectModal((prev) => !prev);
  }, [setShowProjectModal]);

  useEffect(() => {
    if (!isCollapsed) {
      setTimeout(() => {
        setShowButtons(true);
      }, 300);
    } else {
      setShowButtons(false);
    }
  }, [isCollapsed]);

  const handleResetAttachmentsModal = useCallback(() => {
    setAttachments([]);
  }, [setAttachments]);

  const addNewItems: IAddNewLink[] = [
    {
      icon: <Icon name="locationIcon" />,
      title: t("components.sidebarAddNew.location.title"),
      description: t("components.sidebarAddNew.location.description"),
      onClick: () => {
        setCollapsed();
        handleLocationModal(true);
        handleResetAttachmentsModal();
      },
    },
    {
      icon: <Icon name="objectsIcon" />,
      title: t("components.sidebarAddNew.object.title"),
      description: t("components.sidebarAddNew.object.description"),
      onClick: () => {
        setCollapsed();
        setShowObjectModal(true);
        handleResetAttachmentsModal();
      },
    },
    {
      icon: <Icon name="projectsIcon" />,
      title: t("components.sidebarAddNew.project.title"),
      description: t("components.sidebarAddNew.project.description"),
      onClick: () => {
        setCollapsed();
        handleProjectModal();
        handleResetAttachmentsModal();
      },
    },
  ];

  const handleAddNewComponentFromLocation = useCallback((id?: number) => {
    setShowObjectModal(true);
    setCreatedLocationId(id);
  }, []);

  return (
    <div
      className={`add-new-sidebar ${
        isCollapsed ? "add-new-sidebar--collapse" : ""
      } ${sidebarIsCollapsed ? "add-new-sidebar--sidebar-collapse" : ""}`}
    >
      {!isCollapsed && (
        <>
          <div className="add-new-sidebar__top">
            <div className="add-new-sidebar__title">
              <Icon name="addCircle" className="add-new-sidebar__add-icon" />
              <h5>{t("components.sidebar.addButton")}</h5>
            </div>
            <div
              onClick={() => setCollapsed()}
              className="add-new-sidebar__close-icon"
            >
              <Icon name="closeIcon" />
            </div>
          </div>
          <div
            className={`add-new-sidebar__center ${
              showButtons ? "add-new-sidebar__center--show" : ""
            }`}
          >
            {addNewItems.map((link, index) => (
              <AddNewLink key={index} link={link} />
            ))}
          </div>
        </>
      )}

      {showLocationModal && (
        <LocationModal
          onClose={handleLocationModal}
          handleAddNewComponent={handleAddNewComponentFromLocation}
        />
      )}
      {showObjectModal && (
        <ObjectModal
          mode="add"
          onClose={closeObjectModal}
          locationId={createdLocationId}
        />
      )}
      {showProjectModal && (
        <ProjectModal
          showProjectModal={showProjectModal}
          modalState={handleProjectModal}
          attachmentModal={showAttachmentModal}
          setAttachmentModal={handleAttachmentModal}
        />
      )}
    </div>
  );
};
