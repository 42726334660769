import React, { FC } from "react";

const DotsIcon: FC = () => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2C4 1.46957 3.78929 0.960859 3.41421 0.585786C3.03914 0.210714 2.53043 -4.56213e-08 2 -6.2088e-08C1.46957 -7.85548e-08 0.96086 0.210714 0.585787 0.585786C0.210714 0.960859 -9.04484e-08 1.46957 -1.23095e-07 2C-1.55742e-07 2.53043 0.210714 3.03914 0.585787 3.41421C0.960859 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2ZM4 8C4 7.46957 3.78929 6.96086 3.41421 6.58579C3.03914 6.21071 2.53043 6 2 6C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 -4.59734e-07 7.46957 -4.92381e-07 8C-5.25028e-07 8.53043 0.210714 9.03914 0.585786 9.41421C0.960859 9.78929 1.46957 10 2 10C2.53043 10 3.03914 9.78929 3.41421 9.41421C3.78929 9.03914 4 8.53043 4 8ZM4 14C4 13.4696 3.78929 12.9609 3.41421 12.5858C3.03914 12.2107 2.53043 12 2 12C1.46957 12 0.960859 12.2107 0.585786 12.5858C0.210713 12.9609 -8.2902e-07 13.4696 -8.61667e-07 14C-8.94314e-07 14.5304 0.210713 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16C2.53043 16 3.03914 15.7893 3.41421 15.4142C3.78929 15.0391 4 14.5304 4 14Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default DotsIcon;
