import { t } from "i18next";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";

import {
  AddObjectForm,
  IFeatureDeleteType,
  IFullscreenModalData,
  ILocationProperties,
  IObjectProperties,
  IProjectProperties,
} from "../../api/types";
import { LocationModal } from "../../components/add-new-feature/location/LocationModal";
import { ObjectModal } from "../../components/add-new-feature/object/ObjectModal";
import { ProjectModal } from "../../components/add-new-feature/project/ProjectModal";
import { AttachmentModal } from "../../components/attachment-modal/AttachmentModal";
import { ConnectFeatureModal } from "../../components/connect-feature-modal/ConnectFeatureModal";
import { FeatureDeleteModal } from "../../components/feature-delete-modal/FeatureDeleteModal";
import { LocationPreview } from "../../components/feature-preview/location";
import { ComponentFilterModal } from "../../components/filter-modal/ComponentFilterModal";
import { ProjectFilterModal } from "../../components/filter-modal/ProjectFilterModal";
import { FullscreenImgModal } from "../../components/fullscreen-img-modal/FullscreenImgModal";
import { Icon } from "../../components/icon/Icon";
import { NoFeaturePlaceholder } from "../../components/no-feature-placeholder";
import { ObjectList } from "../../components/object/object-list/ObjectList";
import { PageHeader } from "../../components/page/page-header";
import { PageSidebar } from "../../components/page/page-sidebar";
import { PageTable } from "../../components/page/page-table/PageTable";
import { HorizontalTabs } from "../../components/page/page-table/horizontal-tabs";
import { PageTitle } from "../../components/page/page-title";
import { PageWrapper } from "../../components/page/page-wrapper";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../../contants/FeatureUuid";
import {
  dashboardUrl,
  locationTabAttachments,
  locationTabConnectedComponents,
  locationTabObjects,
  locationTabProjects,
  locationsUrl,
} from "../../contants/Routes";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import {
  FilterContext,
  IComponentFilter,
  IProjectFilter,
} from "../../context/filter-context/FilterContext";
import { useAttachmentModal } from "../../hooks/useAttachmentModal";
import { useComponentFilter } from "../../hooks/useComponentFilter";
import { useObjectEdit } from "../../hooks/useObjectEdit";
import { useObjectTableColumnsWithMappings } from "../../hooks/useObjectTableColumnsWithMappings";
import { useProjectFilter } from "../../hooks/useProjectFilter";
import { useProjectTableColumns } from "../../hooks/useProjectTableColumns";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { useTableSelect } from "../../hooks/useTableSelect";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import "./styles.scss";

export function LocationsPreviewPage() {
  const { id: featureId } = useParams();
  const location = useLocation();
  const tTabs = useTranslationPath("components.locationsPreview.tabs");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteFeatureType, setDeleteFeatureType] =
    useState<IFeatureDeleteType | null>(null);

  const {
    singleLocation,
    getLocationProduct,
    locationsProducts,
    productsList,
    getLocationFloor,
    locationProjects,
    downloadGenericFeature,
    downloadSelectFeature,
    totalProductsNumber,
  } = useContext(FeatureContext);

  const {
    handleAttachmentModal,
    handleRecordEdit,
    handleRecordPreview,
    handleRecordSelect,
    handleProjectModal,
    selectedTableItem,
    setSelectedTableItem,
    isSidebarVisible,
    setIsSidebarVisible,
    setShowProjectModal,
    showProjectModal,
  } = useTableSelect(featureId, FEATURE_UUID.LOCATION_PROJECTS);
  const {
    componentLocationFilter,
    handleComponentLocationFilter,
    projectLocationFilter,
    handleProjectLocationFilter,
  } = useContext(FilterContext);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showFullscreenModal, setShowFullscreenModal] =
    useState<IFullscreenModalData>({
      showModal: false,
      sliderData: [],
    });
  const [multipleItems, setMultipleItems] = useState<number[]>([]);
  const [resetBatchSelect, setResetBatchSelect] = useState(false);
  const tabs = useMemo(
    () => [
      { text: tTabs("summary"), to: "" },
      // { text: tTabs("attachments"), to: locationTabAttachments }, TODO: Implement later
      {
        text: tTabs("connectedComponents"),
        to: locationTabConnectedComponents,
      },
      { text: tTabs("listOfComponents"), to: locationTabObjects },
      { text: tTabs("projects"), to: locationTabProjects },
    ],
    [tTabs]
  );

  const [activeTab, setActiveTab] = useState("");
  const { projectTableColumns } = useProjectTableColumns(locationProjects);
  const { projectStatus } = useSelectOptions();
  const [connectFeatureModal, setConnectFeatureModal] = useState(false);
  const [locationDetails, setLocationDetails] = useState<ILocationProperties>();

  const objectTable = useObjectTableColumnsWithMappings(productsList);
  const objectTableEdit = useObjectEdit();

  // TODO: Implement later
  // const { columns: attachmentColumns } =
  //   useAttachmentTableColumns(attachmentsList);

  const handleProjectFilterModal = useCallback(() => {
    setShowProjectFilterModal((prev) => !prev);
  }, []);

  const handleComponentFilterModal = useCallback(() => {
    setShowComponentFilterModal((prev) => !prev);
  }, []);

  const handleApplyComponentFilter = useCallback(
    (filter: Partial<IComponentFilter>) => {
      handleComponentLocationFilter(filter);
      handleComponentFilterModal;
    },
    [handleComponentFilterModal, handleComponentLocationFilter]
  );

  const handleApplyProjectFilter = useCallback(
    (filter: IProjectFilter) => {
      handleProjectLocationFilter(filter);
      handleProjectFilterModal;
    },
    [handleProjectFilterModal, handleProjectLocationFilter]
  );

  const [showProjectFilterModal, setShowProjectFilterModal] = useState(false);
  const [showComponentFilterModal, setShowComponentFilterModal] =
    useState(false);

  const projectFilterString = useProjectFilter(projectLocationFilter);
  const componentFilterString = useComponentFilter(componentLocationFilter);

  const projectFilterActive = !!projectFilterString;
  const componentFilterActive = !!componentFilterString;

  useLayoutEffect(() => {
    if (!featureId) return;
    getLocationProduct(Number(featureId));
    getLocationFloor(parseInt(featureId));
    downloadGenericFeature(
      FEATURE_UUID.LOCATION_PROJECTS,
      `fk_location=${featureId}` +
        (projectFilterString && " AND " + projectFilterString),
      null,
      projectLocationFilter?.limit,
      projectLocationFilter?.offset,
      projectLocationFilter?.sort_order,
      projectLocationFilter?.sort_column
    );
    downloadGenericFeature(
      FEATURE_UUID.PRODUCT,
      `fk_location=${featureId}` +
        (componentFilterString && " AND " + componentFilterString),
      null,
      componentLocationFilter?.limit,
      componentLocationFilter?.offset,
      componentLocationFilter?.sort_order,
      componentLocationFilter?.sort_column
    );
  }, [
    featureId,
    getLocationProduct,
    getLocationFloor,
    downloadGenericFeature,
    projectFilterString,
    componentFilterString,
    componentLocationFilter,
    projectLocationFilter,
  ]);

  useLayoutEffect(() => {
    downloadSelectFeature(FEATURE_UUID.PROJECT);
  }, [downloadSelectFeature]);

  const { projectTableTabs, renderProjectTableTitle } =
    useProjectTableColumns(locationProjects);
  const { objectTableTabs, renderObjectTableTitle } =
    useObjectTableColumnsWithMappings(productsList);

  const title = locationDetails?.name ?? "-";

  const routes = useMemo(() => {
    return [
      { url: dashboardUrl, title: t("common.home") },
      { url: locationsUrl, title: t("common.locations") },
      { url: "", title: title },
    ];
  }, [title]);

  const openObjectModal = useCallback(() => {
    objectTableEdit.setObjectModal({
      mode: "add",
    });
  }, [objectTableEdit]);

  const objects = useMemo(() => {
    return locationsProducts.map(
      (obj) =>
        ({
          ...obj.product,
          fk_product: obj.product.id,
          productLocationMappings: obj.productLocationMappings,
        } as unknown as AddObjectForm)
    );
  }, [locationsProducts]);

  const loadLocationDetails = useCallback(() => {
    if (!featureId) return;
    singleLocation(parseInt(featureId)).then((res) => setLocationDetails(res));
  }, [featureId, singleLocation]);

  const {
    showModal: showAttachmentModal,
    openModal: openAttachmentModal,
    handleClose: closeAttachmentModal,
    handleSave: saveAndCloseAttachmentModal,
    attachmentsList,
    attachmentsState,
    handleDropImage,
    reloadAttachments,
    handleRemoveDropImage,
  } = useAttachmentModal(
    featureId,
    FEATURE_MEDIA_TYPE.LOCATION,
    loadLocationDetails
  );

  useEffect(() => {
    showLocationModal; // Trigger reload on modal close
    loadLocationDetails();
    reloadAttachments();
  }, [loadLocationDetails, reloadAttachments, showLocationModal]);

  const wrapperTableClass = useCallback(() => {
    const path = location.pathname;
    const tableAdditionalStyles =
      !objects?.length || !locationProjects?.length
        ? "pagewrapper-wrapper--big"
        : "";

    if (
      path.includes(locationTabConnectedComponents) ||
      path.includes(locationTabAttachments) ||
      path.includes(locationTabProjects)
    ) {
      return `pagewrapper-wrapper--table ${tableAdditionalStyles}`;
    }

    return "";
  }, [location.pathname, objects, locationProjects]);

  const handleConnectFeatureModal = useCallback(() => {
    setConnectFeatureModal((prev) => !prev);
    downloadGenericFeature(
      FEATURE_UUID.LOCATION_PROJECTS,
      `fk_location=${featureId}`
    );
  }, [downloadGenericFeature, featureId]);

  const closeLocationModal = useCallback(() => {
    setShowLocationModal(false);
  }, []);

  const handleDeleteModal = useCallback(
    (
      featureType: FEATURE_TYPE,
      featureDetails?: Partial<
        ILocationProperties & IObjectProperties & IProjectProperties
      > | null,
      id?: number | string | number[]
    ) => {
      if (Array.isArray(id)) {
        const selectedItems = id?.filter((item) => !isNaN(Number(item)));
        setMultipleItems(selectedItems);
      }

      if (featureType === FEATURE_TYPE.OBJECT) {
        const selectedItem = productsList?.find((product) => product.id === id);

        setSelectedTableItem(selectedItem ?? null);
        setDeleteFeatureType({
          featureDetails: selectedItem as IObjectProperties,
          featureType,
          previewFeatureType: FEATURE_TYPE.LOCATION_PRODUCT,
        });
      }

      if (featureType === FEATURE_TYPE.PROJECT) {
        const selectedItem = locationProjects?.find(
          (project) => project.id === id
        );

        setSelectedTableItem(selectedItem ?? null);
        setDeleteFeatureType({
          featureDetails: selectedItem,
          featureType,
          previewFeatureType: FEATURE_TYPE.LOCATION_PROJECT,
        });
      }

      if (featureType === FEATURE_TYPE.LOCATION) {
        setDeleteFeatureType({
          featureDetails,
          featureType,
          previewFeatureType: featureType,
        });
      }

      setShowDeleteModal((prev) => !prev);
    },
    [productsList, locationProjects, setSelectedTableItem]
  );

  const updateTableData = useCallback(
    async (tab: string) => {
      setResetBatchSelect(true);

      if (tab === tabs?.[1].text) {
        downloadGenericFeature(
          FEATURE_UUID.PRODUCT,
          `fk_location=${featureId}` +
            (componentFilterString && " AND " + componentFilterString)
        );
        return;
      }

      if (tab === tabs?.[3].text) {
        downloadGenericFeature(
          FEATURE_UUID.LOCATION_PROJECTS,
          `fk_location=${featureId}` +
            (projectFilterString && " AND " + projectFilterString)
        );
        return;
      }
    },
    [
      tabs,
      downloadGenericFeature,
      featureId,
      componentFilterString,
      projectFilterString,
    ]
  );

  return (
    <SidebarPage>
      <PageWrapper className={wrapperTableClass()}>
        <PageHeader routes={routes} />
        <PageTitle title={title}>
          <button
            className="btn light-delete"
            onClick={() =>
              handleDeleteModal(
                FEATURE_TYPE.LOCATION,
                locationDetails,
                locationDetails?.id
              )
            }
          >
            <Icon name="delete" />
            {t("common.delete")}
          </button>
          <button
            className="btn light"
            onClick={() => setShowLocationModal(true)}
          >
            <Icon name="edit" />
            {t("common.edit")}
          </button>
          <button className="btn light" onClick={openAttachmentModal}>
            <Icon name="attachment" />
            {t("common.attachments")}
          </button>
          <button className="btn light" onClick={handleConnectFeatureModal}>
            <Icon name="plugConnected" />
            {t("components.locationsPreview.project")}
          </button>
          <button className="btn" onClick={openObjectModal}>
            <Icon name="boxMultiple" />
            {t("components.locationsPreview.object")}
          </button>
        </PageTitle>
        <div className="page-horizontal-tabs-wrapper">
          <HorizontalTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        <Routes>
          <Route
            path=""
            element={
              <>
                {attachmentsList && (
                  <LocationPreview
                    locationDetails={locationDetails}
                    attachments={attachmentsList}
                  />
                )}
              </>
            }
          />

          {/*
          TODO: Implement later

          <Route
            path="attachments"
            element={
              <>
                {attachmentsList && attachmentsList.length ? (
                  <PageTable
                    className="attachments-table"
                    title={t("components.pageTable.building")}
                    subtitle={t("components.pageTable.buildings")}
                    columns={attachmentColumns}
                    onRecordSelect={handleAttachmentPreview}
                    onRecordPreview={handleAttachmentPreview}
                    onRecordEdit={openAttachmentModal}
                    noHeader
                  />
                ) : (
                  <div className="upload-image-placeholder-wrapper">
                    <NoFeaturePlaceholder
                      grid
                      type={FEATURE_TYPE.ATTACHMENT}
                      parentElementType={FEATURE_TYPE.LOCATION}
                      onClick={handleAttachmentModal}
                    />
                  </div>
                )}
              </>
            }
          /> */}

          <Route
            path={locationTabConnectedComponents}
            element={
              <>
                <PageTable
                  className="attachments-table"
                  tabs={objectTableTabs}
                  title={t("components.pageTable.object")}
                  subtitle={t("components.pageTable.objectsCount")}
                  columns={objectTable.columns}
                  featureUUid={FEATURE_UUID.PRODUCT}
                  featurePreviewFilter={
                    `fk_location=${featureId}` +
                    (componentFilterString && " AND " + componentFilterString)
                  }
                  onRecordSelect={objectTableEdit.handleRecordSelect}
                  onRecordEdit={objectTableEdit.handleRecordEdit}
                  onRecordDelete={(id) =>
                    handleDeleteModal(
                      FEATURE_TYPE.OBJECT,
                      selectedTableItem,
                      id
                    )
                  }
                  onRecordPreview={objectTableEdit.handleRecordPreview}
                  noFeatureHandler={openObjectModal}
                  totalItemsCount={totalProductsNumber}
                  onFilterClick={handleComponentFilterModal}
                  filterActive={componentFilterActive}
                  tableHeaderText={renderObjectTableTitle}
                  componentInLocation={true}
                  resetBatchSelect={resetBatchSelect}
                  setResetBatchSelect={setResetBatchSelect}
                />

                <PageSidebar
                  featureDetails={objectTableEdit.selectedTableItem}
                  type={FEATURE_TYPE.OBJECT}
                  isSidebarVisible={objectTableEdit.isSidebarVisible}
                  onClose={() => objectTableEdit.setIsSidebarVisible(false)}
                  onEdit={objectTableEdit.handleRecordEdit}
                />
              </>
            }
          />

          <Route
            path={locationTabObjects}
            element={
              <>
                {objects?.length ? (
                  <ObjectList
                    objects={objects}
                    onEdit={objectTableEdit.handleRecordEdit}
                    setShowFullscreenModal={setShowFullscreenModal}
                  />
                ) : (
                  <div className="upload-image-placeholder-wrapper">
                    <NoFeaturePlaceholder
                      grid
                      type={FEATURE_TYPE.OBJECT}
                      parentElementType={FEATURE_TYPE.LOCATION}
                      onClick={openObjectModal}
                    />
                  </div>
                )}
              </>
            }
          />

          <Route
            path={locationTabProjects}
            element={
              <>
                <PageTable
                  title={t("components.pageTable.project")}
                  subtitle={t("components.pageTable.projectsCount")}
                  tabs={projectTableTabs}
                  columns={projectTableColumns}
                  featureUUid={FEATURE_UUID.LOCATION_PROJECTS}
                  featurePreviewFilter={
                    `fk_location=${featureId}` +
                    (projectFilterString && " AND " + projectFilterString)
                  }
                  onRecordSelect={handleRecordSelect}
                  onRecordEdit={handleRecordEdit}
                  onRecordPreview={handleRecordPreview}
                  onRecordDelete={(id) =>
                    handleDeleteModal(
                      FEATURE_TYPE.PROJECT,
                      selectedTableItem,
                      id
                    )
                  }
                  statusOptions={projectStatus}
                  totalItemsCount={projectTableColumns?.data?.length}
                  noFeatureHandler={() => handleProjectModal(true)}
                  onFilterClick={handleProjectFilterModal}
                  filterActive={projectFilterActive}
                  tableHeaderText={renderProjectTableTitle}
                  resetBatchSelect={resetBatchSelect}
                  setResetBatchSelect={setResetBatchSelect}
                />

                <PageSidebar
                  type={FEATURE_TYPE.PROJECT}
                  isSidebarVisible={isSidebarVisible}
                  onClose={() => setIsSidebarVisible(false)}
                  setEditMode={setShowProjectModal}
                  featureDetails={selectedTableItem}
                />
              </>
            }
          />
        </Routes>
      </PageWrapper>

      <FullscreenImgModal
        showModal={showFullscreenModal.showModal}
        sliderItems={showFullscreenModal.sliderData}
        setShowModal={() =>
          setShowFullscreenModal({
            ...showFullscreenModal,
            showModal: false,
          })
        }
      />
      {locationDetails && showLocationModal && (
        <LocationModal
          locationDetails={locationDetails}
          onClose={closeLocationModal}
        />
      )}
      {objectTableEdit?.objectModal && (
        <ObjectModal
          objectDetails={objectTableEdit?.objectModal?.defaultValues}
          onClose={() => {
            objectTableEdit.handleObjectModalClose();
            getLocationProduct(Number(featureId));
          }}
          locationId={Number(featureId)}
          mode={objectTableEdit?.objectModal?.mode}
        />
      )}
      {showProjectModal && (
        <ProjectModal
          projectDetails={selectedTableItem}
          showProjectModal={showProjectModal}
          modalState={handleProjectModal}
          attachmentModal={showAttachmentModal}
          setAttachmentModal={handleAttachmentModal}
        />
      )}
      {connectFeatureModal && (
        <ConnectFeatureModal
          featureUuid={FEATURE_UUID.LOCATION}
          locationDetails={{
            ...locationDetails,
            projects: locationProjects,
          }}
          onClose={handleConnectFeatureModal}
        />
      )}
      {showAttachmentModal && (
        <AttachmentModal
          attachments={attachmentsState}
          onDropImage={handleDropImage}
          onClose={closeAttachmentModal}
          onSave={saveAndCloseAttachmentModal}
          handleRemoveDropImage={handleRemoveDropImage}
        />
      )}
      {showProjectFilterModal && (
        <ProjectFilterModal
          onClose={handleProjectFilterModal}
          projectFilter={projectLocationFilter}
          onApplyFilter={handleApplyProjectFilter}
          showFilterModal={showProjectFilterModal}
        />
      )}
      {showComponentFilterModal && (
        <ComponentFilterModal
          onClose={handleComponentFilterModal}
          componentFilter={componentLocationFilter}
          onApplyFilter={handleApplyComponentFilter}
          showFilterModal={showComponentFilterModal}
        />
      )}
      {showDeleteModal && deleteFeatureType?.featureType && (
        <FeatureDeleteModal
          multipleFeatures={multipleItems}
          onClose={() => setShowDeleteModal(false)}
          feature={deleteFeatureType?.featureDetails ?? null}
          featureType={deleteFeatureType?.featureType}
          previewPageType={deleteFeatureType?.previewFeatureType}
          updatePreviewTableData={() => updateTableData(activeTab)}
        />
      )}
    </SidebarPage>
  );
}
