import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { i18nextLngKey, selectedLanguage } from "../contants/Language";
import en from "./en.json";
import no from "./no.json";

if (localStorage.getItem(i18nextLngKey) === null) {
  localStorage.setItem(i18nextLngKey, selectedLanguage);
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: selectedLanguage,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      no: {
        translation: no,
      },
    },
  });

export default i18n;
