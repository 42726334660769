import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { t } from "i18next";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useActiveMenu } from "react-active-menu";
import { MultiValue } from "react-select";

import { KIND_RESPONSE } from "../../../api/api-problem";
import { AddProjectForm, AddProjectResponse } from "../../../api/types";
import { FEATURE_TYPE, PROJECT_STATUS } from "../../../contants/Enums";
import {
  FEATURE_MEDIA_TYPE,
  FEATURE_UUID,
} from "../../../contants/FeatureUuid";
import { FeatureContext } from "../../../context/feature-context/FeatureContext";
import { useAttachmentEdit } from "../../../hooks/useAttachmentEdit";
import { useAttachments } from "../../../hooks/useAttachments";
import { useCheckMobile } from "../../../hooks/useCheckMobile";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { useTranslationPath } from "../../../hooks/useTranslationPath";
import { ISelectedOption } from "../../../utils/types/types";
import { AttachmentModal } from "../../attachment-modal/AttachmentModal";
import { FormSectionLinksModalAside } from "../../feature/feature-modal-aside";
import { FeatureModalCreated } from "../../feature/feature-modal-created";
import { FeatureModalHeader } from "../../feature/feature-modal-header";
import { FeatureMainTitle } from "../../feature/feature-modal-main-title";
import { FeatureModalWrapper } from "../../feature/feature-modal-wrapper";
import { FileDropzone } from "../../file-dropzone";
import { InputMultiSearchSelect } from "../../input-multi-search";
import { InputSelect } from "../../input-select/InputSelect";
import { Modal } from "../../modal";
import { SubmitButtons } from "../../submit-buttons";
import "../styles.scss";
import { projectAside } from "./ProjectModal.const";
import { ProjectModalProps } from "./ProjectModal.props";

enum PROJECT_STEPS {
  FIRST_STEP = 1,
  LAST_STEP = 2,
}

export function ProjectModal({
  showProjectModal,
  modalState,
  projectDetails,
}: ProjectModalProps) {
  const [error, setError] = useState<string>("");
  const {
    addNewProject,
    editProject,
    selectLocationsName,
    downloadSelectFeature,
    downloadGenericFeature,
  } = useContext(FeatureContext);
  const { isMobile } = useCheckMobile();

  const [modalStep, setModalStep] = useState(PROJECT_STEPS.FIRST_STEP);
  const [createdProjectId, setCreatedProjectId] = useState<number | null>(
    projectDetails?.id || null
  );

  const { projectStatus } = useSelectOptions();
  const tForm = useTranslationPath("components.projectModal.form");

  const headerProps = useMemo(
    () => ({
      title: projectDetails
        ? t("components.projectModal.headerMainEdit")
        : t("components.projectModal.headerMain"),
      subtitle: t("components.projectModal.headerSecondary"),
    }),
    [projectDetails]
  );

  const { activeId, registerContainer, registerSection, registerTrigger } =
    useActiveMenu({
      smooth: true,
      offset: 30,
    });

  const {
    attachmentsState,
    setAttachmentsState,
    handleRemoveDropImage,
    handleDropImage,
    showAttachmentModal,
    openAttachmentModal,
    closeAttachmentModal,
    attachmentThumbnail,
  } = useAttachmentEdit(FEATURE_MEDIA_TYPE.PROJECT, projectDetails?.id);

  const { postOrUpdateMedia } = useAttachments();

  const handleSubmit = useCallback(
    async (values: AddProjectForm) => {
      let id = values.id;

      if (id) {
        await editProject(values, id).then((res: AddProjectResponse | void) => {
          if (res && res.kind && res.kind === KIND_RESPONSE.OK) {
            if (res.data.id) {
              downloadGenericFeature(
                FEATURE_UUID.PROJECT_LOCATIONS,
                `fk_project=${id}`
              );
              downloadGenericFeature(
                FEATURE_UUID.PROJECT_PRODUCTS,
                `fk_project=${id}`
              );
              setModalStep(PROJECT_STEPS.LAST_STEP);
            } else {
              setError(t("errors.unexpectedError") ?? "");
            }
          }
        });
      } else {
        await addNewProject(values).then((res: AddProjectResponse | void) => {
          if (res && res.kind && res.kind === KIND_RESPONSE.OK) {
            id = res.data.id;
            if (id) {
              downloadGenericFeature(
                FEATURE_UUID.PROJECT_LOCATIONS,
                `fk_project=${id}`
              );
              downloadGenericFeature(
                FEATURE_UUID.PROJECT_PRODUCTS,
                `fk_project=${id}`
              );
              setModalStep(PROJECT_STEPS.LAST_STEP);
            }
          } else {
            setError(t("errors.unexpectedError") ?? "");
          }
        });
      }

      if (!id) return;

      setCreatedProjectId(id);

      postOrUpdateMedia(attachmentsState, FEATURE_MEDIA_TYPE.PROJECT, id);
    },
    [
      postOrUpdateMedia,
      attachmentsState,
      editProject,
      downloadGenericFeature,
      addNewProject,
    ]
  );

  const defaultFormValues = useMemo(
    () =>
      ({
        ...projectDetails,
        status: projectDetails?.status ?? PROJECT_STATUS.PLANNING,
      } as AddProjectForm),
    [projectDetails]
  );

  const { form, state, setDirty } = useForm({
    defaultValues: defaultFormValues,
    onSubmit: handleSubmit,
  });

  useLayoutEffect(() => {
    downloadSelectFeature(FEATURE_UUID.LOCATION);
  }, [downloadSelectFeature]);

  const handleCloseModal = useCallback(
    async (noClose = true) => {
      noClose && modalState(false);
      isMobile &&
        Array.from(document?.getElementsByClassName("feature-modal")).map(
          (item) => (item.scrollTop = 0)
        );
      setTimeout(async () => {
        setModalStep(PROJECT_STEPS.FIRST_STEP);
      }, 500);
    },
    [modalState, isMobile]
  );

  const locationFormValue = form.getValue("location_list");

  const productLocations = useMemo(
    () =>
      selectLocationsName.filter((location) =>
        locationFormValue?.some(
          (locationId: number) => locationId === location.value
        )
      ),
    [locationFormValue, selectLocationsName]
  );

  const handleSelectLocation = useCallback(
    (value: MultiValue<ISelectedOption>) => {
      form.setValue(
        "location_list",
        value.map((location) => location?.value)
      );
    },
    [form]
  );

  const handleNewComponent = useCallback(() => {
    setCreatedProjectId(null);
    handleCloseModal(false);
    form.setValue("image", "");
    form.setValue("name", "");
    form.setValue("project_leader", "");
    form.setValue("start_date", "");
    form.setValue("end_date", "");
    form.setValue("customer", "");
    form.setValue("description", "");
    form.setValue("status", "");
    form.setValue("location_list", []);
    setAttachmentsState([]);
  }, [form, handleCloseModal, setAttachmentsState]);

  return (
    <>
      <Modal
        className="feature-modal"
        show={showProjectModal && !showAttachmentModal}
      >
        <div
          className={`${
            modalStep === PROJECT_STEPS.LAST_STEP
              ? "feature-modal-container feature-modal-container--created"
              : "feature-modal-container"
          }`}
        >
          {modalStep === PROJECT_STEPS.FIRST_STEP && (
            <FeatureModalHeader
              modalState={modalState}
              onClose={handleCloseModal}
              {...headerProps}
            />
          )}

          <FormProvider form={form} state={state}>
            {modalStep === PROJECT_STEPS.FIRST_STEP && (
              <div className="form-and-links-container">
                <form
                  className="form project-form"
                  id="project-form"
                  onSubmit={form.submit}
                  ref={registerContainer}
                >
                  <FeatureModalWrapper>
                    <section
                      className="feature-modal-drop"
                      ref={registerSection("attachments")}
                      id="attachments"
                    >
                      <FileDropzone
                        onDrop={handleDropImage}
                        attachmentButtonClick={openAttachmentModal}
                        setDirty={setDirty}
                        attachmentsCount={attachmentsState.length}
                        text={t("components.locationModal.asideAttachments")}
                        maxFiles={20}
                        attachmentPreview
                        thumbnailMedia={attachmentThumbnail}
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper">
                    <section ref={registerSection("project")}>
                      <FeatureMainTitle
                        text={t("components.projectModal.aside.project")}
                      />

                      <Input
                        id="name"
                        type="text"
                        minLength={2}
                        required
                        label={tForm("labels.projectName")}
                        placeholder={tForm("placeholders.projectName")}
                      />
                      <Input
                        id="description"
                        type="textarea"
                        label={tForm("labels.description")}
                        placeholder={tForm("placeholders.description")}
                        maxLength={50}
                      />
                      <Input
                        id="project_leader"
                        type="text"
                        label={tForm("labels.projectLeaderName")}
                        placeholder={tForm("placeholders.projectLeaderName")}
                      />

                      <div className="aui-form-control aui-text">
                        <label>{tForm("labels.startDate")}</label>
                        <input
                          type="date"
                          id="start_date"
                          name="start_date"
                          max="2999-12-31"
                          defaultValue={state.start_date ?? ""}
                          placeholder={tForm("placeholders.startDate")}
                          onChange={(e) => (state.start_date = e.target.value)}
                        />
                      </div>

                      <div className="aui-form-control aui-text">
                        <label>{tForm("labels.endDate")}</label>
                        <input
                          type="date"
                          id="end_date"
                          name="end_date"
                          max="2999-12-31"
                          defaultValue={state.end_date ?? ""}
                          placeholder={tForm("placeholders.endDate")}
                          onChange={(e) => (state.end_date = e.target.value)}
                        />
                      </div>

                      <Input
                        id="customer"
                        type="text"
                        label={tForm("labels.customer")}
                        placeholder={tForm("placeholders.customer")}
                      />
                      <InputSelect
                        id="status"
                        items={projectStatus}
                        label={tForm("labels.status")}
                        placeholder=""
                      />
                    </section>
                  </FeatureModalWrapper>

                  <FeatureModalWrapper classNames="feature-modal-main-wrapper feature-modal-main-wrapper">
                    <section ref={registerSection("locations")}>
                      <FeatureMainTitle
                        text={t("components.projectModal.aside.locations")}
                      />
                      <InputMultiSearchSelect
                        id="new_location"
                        options={selectLocationsName}
                        label={tForm("labels.newLocation")}
                        placeholder={tForm("placeholders.newLocation")}
                        onChange={handleSelectLocation}
                        value={productLocations}
                      />
                    </section>
                  </FeatureModalWrapper>
                </form>
                <FormSectionLinksModalAside
                  data={projectAside}
                  active={activeId}
                  trigger={registerTrigger}
                />
              </div>
            )}

            <FeatureModalCreated
              className={`${
                modalStep === PROJECT_STEPS.LAST_STEP
                  ? ""
                  : "feature-modal--hidden"
              }`}
              editMode={projectDetails ? !!projectDetails.id : false}
              featureType={FEATURE_TYPE.PROJECT}
              createdFeatureId={createdProjectId}
              resetForm={handleCloseModal}
              handleNewComponent={handleNewComponent}
            />
            {error && <span className="feature-modal__error">{error}</span>}

            {modalStep === PROJECT_STEPS.FIRST_STEP && (
              <SubmitButtons
                onCancel={handleCloseModal}
                submitText={t("common.next")}
                formId="project-form"
              />
            )}
          </FormProvider>
        </div>
      </Modal>
      {showAttachmentModal && (
        <AttachmentModal
          onClose={closeAttachmentModal}
          attachments={attachmentsState}
          handleRemoveDropImage={handleRemoveDropImage}
          setDirty={setDirty}
          onDropImage={handleDropImage}
        />
      )}
    </>
  );
}
