import { SVGProps } from "react";

export const PptIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4 12C4 16.9705 8.0295 21 13 21C17.9705 21 22 16.9705 22 12H13H4Z"
      fill="#D35230"
    />
    <path d="M13 3V12H22C22 7.0295 17.9705 3 13 3Z" fill="#FF8F6B" />
    <path d="M13 3C8.0295 3 4 7.0295 4 12H13V3Z" fill="#ED6C47" />
    <path
      opacity="0.05"
      d="M13 8.3405C13 7.324 12.176 6.5 11.1595 6.5H5.887C4.7085 8.022 4 9.926 4 12C4 14.558 5.0725 16.8615 6.7855 18.5H11.159C12.176 18.5 13 17.676 13 16.6595V8.3405Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M11.1065 6.6665H5.7625C4.66 8.1605 4 10.001 4 12C4 14.3085 4.8765 16.407 6.3055 18H11.1065C11.9685 18 12.667 17.3015 12.667 16.4395V8.227C12.6665 7.3655 11.968 6.6665 11.1065 6.6665Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M11.053 6.8335H5.638C4.609 8.2965 4 10.0755 4 12C4 14.074 4.7085 15.978 5.887 17.5H11.053C11.76 17.5 12.333 16.927 12.333 16.22V8.1135C12.3335 7.4065 11.76 6.8335 11.053 6.8335Z"
      fill="black"
    />
    <path
      d="M11 17H3C2.4475 17 2 16.5525 2 16V8C2 7.4475 2.4475 7 3 7H11C11.5525 7 12 7.4475 12 8V16C12 16.5525 11.5525 17 11 17Z"
      fill="url(#paint0_linear_11044_386)"
    />
    <path
      d="M7.3365 9.50598H5V14.506H6.012V12.7455H7.15C8.088 12.7455 8.8485 11.985 8.8485 11.047V11.018C8.8485 10.183 8.1715 9.50598 7.3365 9.50598ZM7.785 11.179C7.785 11.6085 7.4365 11.957 7.007 11.957H6.012V10.2945H7.007C7.4365 10.2945 7.785 10.643 7.785 11.0725V11.179Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11044_386"
        x1="2.293"
        y1="7.293"
        x2="11.385"
        y2="16.385"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CA4E2A" />
        <stop offset="1" stopColor="#B63016" />
      </linearGradient>
    </defs>
  </svg>
);
