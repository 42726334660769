import { PageWrapperProps } from "./index.props";
import "./styles.scss";

export const PageWrapper = ({ children, className }: PageWrapperProps) => {
  return (
    <div className={`pagewrapper-wrapper ${className ? className : ""}`}>
      {children}
    </div>
  );
};
