import React, { FC } from "react";

const RocketIcon: FC = () => {
  return (
    <svg
      width="62"
      height="95"
      viewBox="0 0 62 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.498 71.373L22.4564 73.0514C22.8655 73.7651 23.6767 74.2148 24.5632 74.2148H37.2654C38.152 74.2148 38.9631 73.7651 39.3723 73.0514L40.3306 71.373H21.5016H21.498Z"
        fill="#D7D8F7"
        stroke="#D7D8F7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.7852 68.1924L20.0198 70.353C20.5439 71.272 21.5919 71.8489 22.7333 71.8489H39.0965C40.2379 71.8489 41.2823 71.272 41.8099 70.353L43.0446 68.1924H18.7852Z"
        fill="#D7D8F7"
        stroke="#D7D8F7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M42.6102 51.6436C42.6102 51.6436 63.2517 58.0799 61.9381 76.9163C61.9381 76.9163 44.5591 60.8402 35.1016 64.2815L42.6102 51.6468V51.6436Z"
        fill="#D7D8F7"
      />
      <path
        d="M19.388 51.6436C19.388 51.6436 -1.25351 58.0831 0.0601433 76.9163C0.0601433 76.9163 17.4391 60.8402 26.8967 64.2815L19.388 51.6468V51.6436Z"
        fill="#D7D8F7"
      />
      <path
        d="M40.9375 15.6394C38.5507 10.1938 35.2953 4.87856 30.9093 0V0.0228123L30.9021 0C26.5627 4.82968 23.3325 10.083 20.9492 15.47C25.5901 14.463 32.5962 13.7819 40.9339 15.6394H40.9375Z"
        fill="#D7D8F7"
      />
      <path
        d="M41.2099 16.2784L40.9335 15.6396C29.8931 13.0683 20.9488 15.4701 20.9488 15.4701L20.597 16.2784C11.4733 37.7316 15.608 61.0197 17.076 67.5929C17.3129 68.652 18.3394 69.4113 19.531 69.4113H42.2687C43.4603 69.4113 44.4868 68.652 44.7237 67.5929C46.1917 61.0197 50.33 37.7316 41.2027 16.2784H41.2099Z"
        fill="#D7D8F7"
      />
      <path
        d="M30.4962 40.6645C35.1724 40.6645 38.9632 37.2226 38.9632 32.9768C38.9632 28.731 35.1724 25.2891 30.4962 25.2891C25.8201 25.2891 22.0293 28.731 22.0293 32.9768C22.0293 37.2226 25.8201 40.6645 30.4962 40.6645Z"
        fill="#D7D8F7"
        stroke="#D7D8F7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M33.3577 38.7792C36.6695 37.5496 38.2564 34.1152 36.9022 31.1082C35.548 28.1011 31.7654 26.6602 28.4536 27.8898C25.1418 29.1194 23.5548 32.5539 24.9091 35.5609C26.2633 38.5679 30.0459 40.0088 33.3577 38.7792Z"
        fill="#D7D8F7"
        stroke="#D7D8F7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M30.4976 38.8032C34.0419 38.8032 36.9151 36.1944 36.9151 32.9763C36.9151 29.7582 34.0419 27.1494 30.4976 27.1494C26.9533 27.1494 24.0801 29.7582 24.0801 32.9763C24.0801 36.1944 26.9533 38.8032 30.4976 38.8032Z"
        fill="#6239D9"
        stroke="#D7D8F7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <g opacity="0.08">
        <path
          d="M30.9068 28.1336C33.4479 28.1336 35.6373 29.4795 36.6782 31.4251C35.9281 28.9614 33.4515 27.1494 30.4976 27.1494C26.955 27.1494 24.0801 29.7565 24.0801 32.9763C24.0801 33.8855 24.317 34.7459 24.7261 35.5117C24.5754 35.0164 24.4893 34.4982 24.4893 33.9605C24.4893 30.744 27.3606 28.1336 30.9068 28.1336Z"
          fill="#D7D8F7"
          stroke="#D7D8F7"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};

export default RocketIcon;
