import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import {
  ChangeEvent,
  ClipboardEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  CHILDREN_FLOW,
  ORGANISATION_MODAL_TYPE,
} from "../../../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../../../contants/FeatureUuid";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { useAttachmentEdit } from "../../../hooks/useAttachmentEdit";
import { getMediaUri } from "../../../utils/index.utils";
import { Heading } from "../../heading";
import { ImageBlind } from "../../image-blind";
import { ProfileCard } from "../../profile/profile-card";
import { ProfileInfo } from "../../profile/profile-info";
import { ProfileModal } from "../../profile/profile-modal";

export const OrganizationInfo = () => {
  const { t } = useTranslation();
  const {
    selectedOrganisation,
    updateOrgImage,
    updateOrganisation,
    isOrganisationAdmin,
  } = useContext(OrganisationContext);
  const { token } = useContext(AppUserContext);
  const [showOrgEditModal, setShowOrgEditModal] = useState(false);
  const [orgTypeModal, setOrgTypeModal] = useState<
    ORGANISATION_MODAL_TYPE | undefined
  >(undefined);
  const [modalTextData, setModalTextData] = useState({
    icon: "textIcon",
    title: t("components.orgUpdateModal.titleOrgNumber"),
    description: t("components.orgUpdateModal.descriptionOrgNumber"),
    submitButtonText: t("common.changeName"),
  });

  const { setAttachmentsState } = useAttachmentEdit(
    FEATURE_MEDIA_TYPE.ORGANIZATION,
    selectedOrganisation?.id ?? undefined
  );

  const handleSelectImage = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        updateOrgImage(e.target.files?.[0]);
      }
    },
    [updateOrgImage]
  );

  const handleSetSettingsModal = useCallback(
    (type: ORGANISATION_MODAL_TYPE | undefined) => {
      setShowOrgEditModal(!showOrgEditModal);
      setOrgTypeModal(type);
    },
    [showOrgEditModal]
  );

  const { form, state } = useForm({
    defaultValues: {
      org_nr: `${
        selectedOrganisation?.org_nr ? selectedOrganisation?.org_nr : ""
      }`,
      name: `${selectedOrganisation?.name ? selectedOrganisation?.name : ""}`,
      description: `${
        selectedOrganisation?.description
          ? selectedOrganisation?.description
          : ""
      }`,
    },
    onSubmit: (data: any) => handleSubmitForm(data),
  });

  const handleSubmitForm = (data: any) => {
    updateOrganisation(data)
      .then(() => {
        handleSetSettingsModal(undefined);
      })
      .catch(() => {
        console.error(data);
      });
  };

  const onOrgNrPaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const text = (e.clipboardData.getData("Text") as string)
        ?.trim()
        .replaceAll(" ", "")
        .substring(0, 9);

      form.setValue("org_nr", text);
    },
    [form]
  );

  const renderInputs = useCallback(() => {
    switch (orgTypeModal) {
      case ORGANISATION_MODAL_TYPE.ORG_NUMBER:
        return (
          <Input
            id="org_nr"
            type="text"
            minLength={9}
            maxLength={9}
            label={t("components.addOrgModal.orgNumber")}
            placeholder={t("components.addOrgModal.orgNumberPlaceholder") ?? ""}
            required
            onPaste={onOrgNrPaste}
          />
        );
      case ORGANISATION_MODAL_TYPE.NAME:
        return (
          <Input
            id="name"
            type="text"
            label={t("components.addOrgModal.name")}
            placeholder={t("components.addOrgModal.namePlaceholder2") ?? ""}
            required
          />
        );
      case ORGANISATION_MODAL_TYPE.DESCRIBTION:
        return (
          <Input
            id="description"
            type="text"
            label={t("components.addOrgModal.orgDescription", {
              availableLetters: 48 - state.description.length,
            })}
            placeholder={
              t("components.addOrgModal.orgDescriptionPlaceholder") ?? ""
            }
            required
            maxLength={48}
          />
        );
      default:
        return <></>;
    }
  }, [onOrgNrPaste, orgTypeModal, state.description.length, t]);

  const handleCancelEdit = useCallback(() => {
    setShowOrgEditModal(false);
    setTimeout(() => {
      state.org_nr = selectedOrganisation?.org_nr ?? "";
      state.name = selectedOrganisation?.name ?? "";
      state.description = selectedOrganisation?.description ?? "";
    }, 500);
  }, [
    selectedOrganisation?.description,
    selectedOrganisation?.name,
    selectedOrganisation?.org_nr,
    state,
  ]);

  const renderModalText = useCallback(() => {
    switch (orgTypeModal) {
      case ORGANISATION_MODAL_TYPE.ORG_NUMBER:
        return setModalTextData({
          icon: "textIcon",
          title: t("components.orgUpdateModal.titleOrgNumber"),
          description: t("components.orgUpdateModal.descriptionOrgNumber"),
          submitButtonText: t("common.changeName"),
        });
      case ORGANISATION_MODAL_TYPE.NAME:
        return setModalTextData({
          icon: "textIcon",
          title: t("components.orgUpdateModal.titleName"),
          description: t("components.orgUpdateModal.descriptionName"),
          submitButtonText: t("common.changeName"),
        });
      case ORGANISATION_MODAL_TYPE.DESCRIBTION:
        return setModalTextData({
          icon: "textIcon",
          title: t("components.orgUpdateModal.titleShortDesc"),
          description: t("components.orgUpdateModal.descriptionShortDesc"),
          submitButtonText: t("common.changeName"),
        });
      default:
        return setModalTextData({
          icon: "textIcon",
          title: t("components.orgUpdateModal.titleOrgNumber"),
          description: t("components.orgUpdateModal.descriptionOrgNumber"),
          submitButtonText: t("common.confirm"),
        });
    }
  }, [orgTypeModal, t]);

  useLayoutEffect(() => {
    showOrgEditModal && renderModalText();
  }, [showOrgEditModal, renderModalText]);

  return (
    <>
      <Heading
        title={t("pages.profile.headings.organisation.title")}
        description={t("pages.profile.headings.organisation.description")}
        small
      />

      <ProfileCard className="profile-summary" flow={CHILDREN_FLOW.ROW}>
        <Column align="left" className="profile-summary__img">
          {selectedOrganisation?.image ? (
            <img src={getMediaUri(selectedOrganisation.image, token)} />
          ) : (
            <ImageBlind
              className="profile-summary__img--default"
              name={selectedOrganisation?.name ?? ""}
            />
          )}
          {isOrganisationAdmin && (
            <div
              className="profile-summary__img__edit"
              onMouseEnter={() => setAttachmentsState([])}
            >
              <input type="file" onChange={handleSelectImage} />
              <Icon name="editFilled" />
            </div>
          )}
        </Column>

        <Column align="left" className="profile-summary__text">
          <h3>{selectedOrganisation?.name}</h3>
          <p className="description">{selectedOrganisation?.description}</p>
        </Column>
      </ProfileCard>

      <ProfileCard lastCard>
        <ProfileInfo
          title={t("pages.organisationSettings.headings.orgNumber")}
          data={selectedOrganisation?.org_nr || "-"}
          onClick={() =>
            handleSetSettingsModal(ORGANISATION_MODAL_TYPE.ORG_NUMBER)
          }
          disabled={!isOrganisationAdmin}
        />
        <ProfileInfo
          title={t("pages.organisationSettings.headings.name")}
          data={selectedOrganisation?.name || "-"}
          onClick={() => handleSetSettingsModal(ORGANISATION_MODAL_TYPE.NAME)}
          disabled={!isOrganisationAdmin}
        />
        <ProfileInfo
          title={t("pages.organisationSettings.headings.description")}
          data={selectedOrganisation?.description || "-"}
          onClick={() =>
            handleSetSettingsModal(ORGANISATION_MODAL_TYPE.DESCRIBTION)
          }
          disabled={!isOrganisationAdmin}
        />
      </ProfileCard>

      <ProfileModal
        iconName={modalTextData.icon}
        title={modalTextData.title}
        description={modalTextData.description}
        showModal={showOrgEditModal}
        cancelButtonClick={handleCancelEdit}
        submitButtonClick={form.submit}
        submitButtonText={modalTextData.submitButtonText}
      >
        <FormProvider form={form} state={state}>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {renderInputs()}
          </form>
        </FormProvider>
      </ProfileModal>
    </>
  );
};
