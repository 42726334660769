import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiValue, SingleValue } from "react-select";

import { LanguageSelect } from "../language-select";
import { ISelectedOption } from "../profile/profile-preferences";
import { AuthLanguageSelectProps } from "./index.props";
import "./styles.scss";

export const AuthLanguageSelect: FC<AuthLanguageSelectProps> = ({
  className,
}) => {
  const { i18n, t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<
    | ISelectedOption
    | MultiValue<ISelectedOption>
    | SingleValue<ISelectedOption>
    | null
  >({
    value: i18n.language,
    label: t(`components.languages.${i18n.language}`),
  });

  const handleChangeLanguage = useCallback(async () => {
    if (selectedOption !== null) {
      if (Object.values(selectedOption)?.[0]) {
        await i18n.changeLanguage(Object.values(selectedOption)?.[0]);
      }
    }
  }, [selectedOption, i18n]);

  const renderOptionWithFlags = (icon: string, language: string) => {
    return (
      <div className="auth-change-language__option">
        <Icon name={icon} className="auth-change-language__option__icon" />
        <span className="auth-change-language__option__language">
          {language}
        </span>
      </div>
    );
  };

  const options = useMemo(
    () => [
      { value: "en", label: renderOptionWithFlags("enFlag", "EN") },
      { value: "no", label: renderOptionWithFlags("noFlag", "NO") },
    ],
    []
  );

  useEffect(() => {
    selectedOption && handleChangeLanguage();
  }, [selectedOption, handleChangeLanguage]);

  return (
    <div className={`auth-change-language ${className ? className : ""}`}>
      <LanguageSelect
        noFlag
        customOptions={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </div>
  );
};
