import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { t } from "i18next";
import { useCallback } from "react";

import { ILocationFilter } from "../../context/filter-context/FilterContext";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { FeatureModalWrapper } from "../feature/feature-modal-wrapper";
import { InputSelect } from "../input-select/InputSelect";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import "./FilterModal.scss";

export function LocationFilterModal({
  onClose,
  locationFilter,
  showFilterModal,
  onApplyFilter: handleLocationFilter,
  inProject,
}: {
  onClose: () => void;
  locationFilter: Partial<ILocationFilter> | undefined;
  showFilterModal: boolean;
  onApplyFilter: (filter: ILocationFilter) => void;
  inProject?: boolean;
}) {
  const { buildStatuses, buildCategories } = useSelectOptions();

  const { form, state } = useForm({
    defaultValues: locationFilter,
    onSubmit: handleLocationFilter,
  });

  const handleClearStatus = useCallback(() => {
    form.setValue("status", undefined);
  }, [form]);

  const handleClearLocation = useCallback(() => {
    form.setValue("year_built", undefined);
    form.setValue("building_category", undefined);
    form.setValue("material_type", undefined);
    form.setValue("from_size", undefined);
    form.setValue("to_size", undefined);
  }, [form]);

  const handleClearRenovation = useCallback(() => {
    form.setValue("renovation_date", undefined);
    form.setValue("demolition_date", undefined);
  }, [form]);

  const resetForm = useCallback(() => {
    handleClearStatus();
    handleClearLocation();
    handleClearRenovation();
  }, [handleClearLocation, handleClearRenovation, handleClearStatus]);

  const statusPlaceholder = t("components.locationFilterModal.statushelp");
  const buildingCategoryPlaceholder = t(
    "components.locationFilterModal.buildingTypeHelp"
  );
  const mainMaterialPlaceholder = t(
    "components.locationFilterModal.mainMaterialHelp"
  );
  const minPlaceholder = t("components.locationFilterModal.fromHelp");
  const maxPlaceholder = t("components.locationFilterModal.toHelp");
  const locationDateHelp = t("components.locationFilterModal.locationDateHelp");
  const yearRenovatedHelp = t(
    "components.locationFilterModal.yearRenovatedHelp"
  );

  return (
    <Modal className="filter-modal" show={showFilterModal}>
      <FeatureModalWrapper>
        <FeatureModalHeader
          modalState={onClose}
          onClose={onClose}
          title={t("components.locationFilterModal.headerMain")}
          subtitle={""}
        />
        <FormProvider form={form} state={state}>
          <form
            className={`form-container`}
            id="project-form"
            onSubmit={form.submit}
            translate={undefined}
          >
            {inProject && (
              <FeatureModalWrapper classNames="wrapper-content">
                <section className="feature-modal-drop" id="attachments">
                  <div className="header-container">
                    <h4>{t("components.locationFilterModal.status")}</h4>
                    <button
                      className="btn purple"
                      type="button"
                      onClick={handleClearStatus}
                    >
                      {t("components.locationFilterModal.clear")}
                    </button>
                  </div>
                  <InputSelect
                    id="status"
                    items={buildStatuses}
                    placeholder={statusPlaceholder}
                    label=""
                  />
                </section>
              </FeatureModalWrapper>
            )}

            <FeatureModalWrapper classNames="wrapper-content">
              <section className="feature-modal-drop" id="attachments">
                <div className="header-container">
                  <h4>{t("components.locationFilterModal.location")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearLocation}
                  >
                    {t("components.locationFilterModal.clear")}
                  </button>
                </div>
                <Input
                  id="year_built"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]{4}"
                  minLength={4}
                  maxLength={4}
                  label={t("components.locationFilterModal.locationDate")}
                  placeholder={locationDateHelp}
                />
                <div className="subsection">
                  <InputSelect
                    id="building_category"
                    items={buildCategories}
                    placeholder={buildingCategoryPlaceholder}
                    label={t("components.locationFilterModal.buildingType")}
                  />
                </div>
                {/* TODO: disabled for now */}
                {/* <div className="subsection"> 
                  <InputMultiSearchSelect
                  id="locations-selector"
                  options={}
                  label={t("components.locationFilterModal.locations")}
                  placeholder={" "}
                  onChange={}
                  value={}
                  />
                 </div> */}
                {/* <div className="subsection">
                  <div className="aui-form-control aui-text">
                    <Input
                      id="material_type"
                      type="text"
                      inputMode="text"
                      label={t("components.locationFilterModal.mainMaterial")}
                      placeholder={mainMaterialPlaceholder}
                    />
                  </div>
                </div> */}
                <div className="subsection">
                  <div className="header-container">
                    <p>{t("components.locationFilterModal.area")}</p>
                  </div>
                  <div className="date-container ">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="from_size"
                        defaultValue={state.from_size ?? undefined}
                        placeholder={minPlaceholder}
                        label={t("components.locationFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="to_size"
                        defaultValue={state.to_size ?? undefined}
                        placeholder={maxPlaceholder}
                        label={t("components.locationFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
              </section>{" "}
            </FeatureModalWrapper>
            <FeatureModalWrapper classNames="wrapper-content">
              <section>
                <div className="header-container">
                  <h4>
                    {t("components.locationFilterModal.renovationDemolition")}
                  </h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearRenovation}
                  >
                    {t("components.projectFilterModal.clear")}
                  </button>
                </div>
                {/* <div className="subsection">
                  <div className="aui-form-control aui-text">
                    <Input
                      id="renovation_date"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]{4}"
                      minLength={4}
                      maxLength={4}
                      label={t("components.locationFilterModal.yearRenovated")}
                      placeholder={yearRenovatedHelp}
                    />
                  </div>
                </div> */}
                <div className="subsection">
                  <div className="aui-form-control aui-text">
                    <Input
                      id="demolition_date"
                      type="date"
                      max="2999-12-31"
                      label={t("components.locationFilterModal.demolitionDate")}
                    />
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>
          </form>
          <div className="filter-modal-buttons">
            <SubmitButtons
              onCancel={resetForm}
              submitText={t("components.locationFilterModal.apply")}
              cancelText={t("components.locationFilterModal.reset")}
              formId="project-form"
              resetMode
            />
          </div>
        </FormProvider>
      </FeatureModalWrapper>
    </Modal>
  );
}
