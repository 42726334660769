import { ApiResponse } from "apisauce";

export enum KIND_RESPONSE {
  TIMEOUT = "timeout",
  CANNOT_CONNECT = "cannot-connect",
  SERVER = "server",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  NOT_FOUND = "not-found",
  REJECTED = "rejected",
  UNKNOWN = "unknown",
  BAD_DATA = "bad-data",
  OK = "ok",
  CONFLICT = "conflict",
}

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: KIND_RESPONSE.TIMEOUT; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: KIND_RESPONSE.CANNOT_CONNECT; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: KIND_RESPONSE.SERVER }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: KIND_RESPONSE.UNAUTHORIZED; body?: string }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: KIND_RESPONSE.FORBIDDEN }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: KIND_RESPONSE.NOT_FOUND }
  /**
   * All other 4xx series errors.
   */
  | { kind: KIND_RESPONSE.REJECTED }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: KIND_RESPONSE.UNKNOWN; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: KIND_RESPONSE.BAD_DATA; body?: string }
  /**
   * The data we received is in conflict with what we have. 409
   */
  | { kind: KIND_RESPONSE.CONFLICT };

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(
  response: ApiResponse<unknown>
): GeneralApiProblem | null {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: KIND_RESPONSE.CANNOT_CONNECT, temporary: true };
    case "NETWORK_ERROR":
      return { kind: KIND_RESPONSE.CANNOT_CONNECT, temporary: true };
    case "TIMEOUT_ERROR":
      return { kind: KIND_RESPONSE.TIMEOUT, temporary: true };
    case "SERVER_ERROR":
      return { kind: KIND_RESPONSE.SERVER };
    case "UNKNOWN_ERROR":
      return { kind: KIND_RESPONSE.UNKNOWN, temporary: true };
    case "CLIENT_ERROR":
      switch (response.status) {
        case 400:
          return {
            kind: KIND_RESPONSE.BAD_DATA,
            body: JSON.stringify(response.data) as string,
          };
        case 401:
          return {
            kind: KIND_RESPONSE.UNAUTHORIZED,
            body: response.data as string,
          };
        case 403:
          return { kind: KIND_RESPONSE.FORBIDDEN };
        case 404:
          return { kind: KIND_RESPONSE.NOT_FOUND };
        case 409: {
          return {
            kind: KIND_RESPONSE.CONFLICT,
          };
        }
        default:
          return { kind: KIND_RESPONSE.REJECTED };
      }
    case "CANCEL_ERROR":
      return null;
  }

  return null;
}
