import { ApisauceInstance, create } from "apisauce";

import { ApiConfig, DEFAULT_API_CONFIG } from "../../api/api-config";
import { GeneralApiProblem } from "../../api/api-problem";

interface APISuccessResponse<T> {
  kind: "ok";
  data: T;
}

export type APIResponseType<T> = APISuccessResponse<T> | GeneralApiProblem;

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;
  config: ApiConfig;
  authToken?: string | null;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  setAuthToken(token?: string | null) {
    this.authToken = token;
  }

  async setup() {
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });

    this.apisauce.addAsyncRequestTransform((request) => async () => {
      if (this.authToken) {
        request.headers["gm_session_id"] = this.authToken;
      }
    });
  }
}
