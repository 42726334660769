import React, { FC } from "react";

const ProjectsFilledIcon: FC = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.821 2.5H17.75C18.8867 2.5 19.8266 3.34297 19.9785 4.43788L19.9948 4.59595L20 4.75V13.75C20 14.9409 19.0748 15.9156 17.904 15.9948L17.75 16H2.25C1.05914 16 0.0843551 15.0748 0.00519085 13.904L0 13.75V6.499L6.20693 6.5L6.40335 6.49141C6.79396 6.45719 7.16896 6.32142 7.49094 6.09765L7.64734 5.9785L11.821 2.5ZM6.20693 0C6.66749 0 7.1153 0.141286 7.49094 0.402348L7.64734 0.521502L9.75 2.273L6.68706 4.82617L6.60221 4.88738C6.51363 4.94232 6.41452 4.9782 6.31129 4.9927L6.20693 5L0 4.999V2.25C0 1.05914 0.925161 0.0843551 2.09595 0.00519085L2.25 0H6.20693Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default ProjectsFilledIcon;
