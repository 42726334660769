import React from "react";

const StatisticsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7489 2L21.3027 2.00052L21.403 2.01444L21.5018 2.04205L21.5621 2.0676C21.6413 2.10246 21.7155 2.15315 21.7808 2.21836L21.8215 2.2624L21.865 2.31878L21.9192 2.4089L21.9579 2.49922L21.977 2.5633L21.9906 2.6273L22 2.7215L22.0004 7.25389C22.0004 7.6681 21.6647 8.00389 21.2504 8.00389C20.8708 8.00389 20.557 7.72174 20.5073 7.35566L20.5004 7.25389L20.5 4.559L14.0607 10.9997C13.7944 11.266 13.3777 11.2902 13.0841 11.0724L13 10.9998L9.96969 8.08924L4.28033 13.7786C3.98744 14.0715 3.51256 14.0715 3.21967 13.7786C2.9534 13.5123 2.9292 13.0957 3.14705 12.8021L3.21967 12.7179L9.43935 6.49826C9.70561 6.23199 10.1223 6.20779 10.4159 6.42563L10.5 6.49825L13.5303 9.40877L19.438 3.5H16.7489C16.3692 3.5 16.0554 3.21785 16.0058 2.85177L15.9989 2.75C15.9989 2.3703 16.2811 2.05651 16.6472 2.00685L16.7489 2ZM3.75 17C4.16421 17 4.5 17.3358 4.5 17.75V21.25C4.5 21.6642 4.16421 22 3.75 22C3.33579 22 3 21.6642 3 21.25V17.75C3 17.3358 3.33579 17 3.75 17ZM9.5 13.75C9.5 13.3358 9.16421 13 8.75 13C8.33579 13 8 13.3358 8 13.75V21.25C8 21.6642 8.33579 22 8.75 22C9.16421 22 9.5 21.6642 9.5 21.25V13.75ZM13.75 15C14.1642 15 14.5 15.3358 14.5 15.75V21.25C14.5 21.6642 14.1642 22 13.75 22C13.3358 22 13 21.6642 13 21.25V15.75C13 15.3358 13.3358 15 13.75 15ZM19.5 10.75C19.5 10.3358 19.1642 10 18.75 10C18.3358 10 18 10.3358 18 10.75V21.25C18 21.6642 18.3358 22 18.75 22C19.1642 22 19.5 21.6642 19.5 21.25V10.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StatisticsIcon;
