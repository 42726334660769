import React, { FC } from "react";

import { convertToInitials } from "../../utils/index.utils";
import { ImageBlindProps } from "./index.props";
import "./styles.scss";

export const ImageBlind: FC<ImageBlindProps> = ({
  name,
  surname,
  className,
}) => {
  return (
    <div className={`image-blind ${className ? className : ""}`}>
      <div className="image-blind__initials">
        <p>{convertToInitials(name, surname ? surname : undefined)}</p>
      </div>
    </div>
  );
};
