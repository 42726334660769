import { FC } from "react";
import { useTranslation } from "react-i18next";

import { handleSetNorwegianLangText } from "../../../utils/index.utils";
import { Text } from "../../text";
import { ObjectStatusProps } from "./index.props";
import "./styles.scss";

export const ObjectStatus: FC<ObjectStatusProps> = ({
  text,
  status,
  withAmount,
}) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <Text
      text={currentLang === "no" ? handleSetNorwegianLangText(text) : text}
      fontWeight={500}
      size="xs"
      className={`object-status object-status--${status} ${
        withAmount !== undefined ? "object-status--amount-wrapper" : ""
      }`}
    >
      {withAmount !== undefined ? (
        <Text
          size="xs"
          className="object-status__amount"
          fontWeight={500}
          text={withAmount?.toString()}
        />
      ) : (
        <></>
      )}
    </Text>
  );
};
