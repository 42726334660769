import React, { FC } from "react";

const LocationFilledIcon: FC = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.157 14.8823L13.97 16.0562C13.0952 16.9149 11.96 18.0188 10.5642 19.3684C9.69194 20.2118 8.30811 20.2117 7.436 19.3682L3.9449 15.9723C3.50614 15.5415 3.13887 15.1782 2.84303 14.8823C-0.557378 11.4819 -0.557378 5.96878 2.84303 2.56837C6.24344 -0.832036 11.7566 -0.832036 15.157 2.56837C18.5574 5.96878 18.5574 11.4819 15.157 14.8823ZM11.5002 9.00006C11.5002 7.61924 10.3808 6.49986 9.00002 6.49986C7.6192 6.49986 6.49982 7.61924 6.49982 9.00006C6.49982 10.3809 7.6192 11.5003 9.00002 11.5003C10.3808 11.5003 11.5002 10.3809 11.5002 9.00006Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default LocationFilledIcon;
