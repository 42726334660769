import { SVGProps } from "react";

export const ExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 2C4.89543 2 4 2.89543 4 4V9.20703C4.32228 9.11588 4.65659 9.05337 5 9.02242V4C5 3.44772 5.44772 3 6 3H10V6.5C10 7.32843 10.6716 8 11.5 8H15V16C15 16.5523 14.5523 17 14 17H10.4003C10.2174 17.3578 9.99647 17.6929 9.74284 18H14C15.1046 18 16 17.1046 16 16V7.41421C16 7.01639 15.842 6.63486 15.5607 6.35355L11.6464 2.43934C11.3651 2.15804 10.9836 2 10.5858 2H6ZM14.7929 7H11.5C11.2239 7 11 6.77614 11 6.5V3.20711L14.7929 7ZM5.5 19C7.98528 19 10 16.9853 10 14.5C10 12.0147 7.98528 10 5.5 10C3.01472 10 1 12.0147 1 14.5C1 16.9853 3.01472 19 5.5 19ZM3.14645 14.8536C2.95118 14.6583 2.95118 14.3417 3.14645 14.1464C3.34171 13.9512 3.65829 13.9512 3.85355 14.1464L5 15.2929L5 12.5C5 12.2239 5.22386 12 5.5 12C5.77614 12 6 12.2239 6 12.5V15.2929L7.14645 14.1464C7.34171 13.9512 7.65829 13.9512 7.85355 14.1464C8.04882 14.3417 8.04882 14.6583 7.85355 14.8536L5.85355 16.8536C5.80561 16.9015 5.75036 16.9377 5.69139 16.9621C5.63331 16.9861 5.5697 16.9996 5.503 17L5.5 17L5.497 17C5.4303 16.9996 5.36669 16.9861 5.30861 16.9621C5.25051 16.938 5.19602 16.9026 5.14857 16.8557L5.14605 16.8532L3.14645 14.8536Z"
      fill="currentColor"
    />
  </svg>
);
