import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { IMediaFeature, PostMedia, isPostMedia } from "../api/types";
import { ALERT_MESSAGE_STATUS, ALERT_MESSAGE_TYPE } from "../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../contants/FeatureUuid";
import { useNotificationApi } from "../context/NotificationProvider";
import { fileToPostMedia } from "../utils/index.utils";
import { useAttachments } from "./useAttachments";
import { useTranslationPath } from "./useTranslationPath";

export function useAttachmentModal(
  featureId: number | string | undefined,
  featureMediaType: FEATURE_MEDIA_TYPE,
  onAttachmentSaved: () => void
) {
  const { toast } = useNotificationApi();
  const [showModal, setShowModal] = useState(false);

  const { fetchedAttachments, getAttachments, postOrUpdateMedia } =
    useAttachments();

  const [attachmentsState, setAttachmentsState] = useState<
    (IMediaFeature | PostMedia)[]
  >([]);

  const handleDropImage = useCallback(async (file: File) => {
    const postMedia = await fileToPostMedia(file);
    setAttachmentsState((prev) => [...prev, postMedia]);
  }, []);

  const handleRemoveDropImage = useCallback(
    (attachment: IMediaFeature | PostMedia) => {
      if (isPostMedia(attachment)) {
        setAttachmentsState((prev) =>
          prev.filter(
            (a) =>
              !(
                isPostMedia(a) && a.generated_uuid === attachment.generated_uuid
              )
          )
        );
      } else {
        setAttachmentsState((prev) =>
          prev.map((a) =>
            a.uuid === attachment.uuid ? { ...a, _deleted: true } : a
          )
        );
      }
    },
    [setAttachmentsState]
  );

  const tToast = useTranslationPath(
    "components.locationsPreview.attachmentToast"
  );

  const reloadAttachments = useCallback(() => {
    if (featureId === undefined) return;
    getAttachments(featureMediaType, Number(featureId));
  }, [featureId, featureMediaType, getAttachments]);

  useLayoutEffect(() => {
    if (featureId === undefined) return;
    getAttachments(featureMediaType, Number(featureId));
  }, [featureId, featureMediaType, getAttachments]);

  useEffect(() => {
    if (fetchedAttachments) setAttachmentsState(fetchedAttachments);
  }, [fetchedAttachments]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setAttachmentsState((prev) => prev.filter((a) => !isPostMedia(a)));
  }, []);

  const handleSave = useCallback(
    (data: Record<string, IMediaFeature | PostMedia>) => {
      if (!featureId) return;

      setShowModal(false);

      postOrUpdateMedia(data, featureMediaType, Number(featureId)).then(() => {
        getAttachments(featureMediaType, Number(featureId));
        onAttachmentSaved?.();
        toast({
          title: tToast("title"),
          message: tToast("message"),
          type: ALERT_MESSAGE_TYPE.PROFILE,
          status: ALERT_MESSAGE_STATUS.SUCCESS,
        });
      });
    },
    [
      featureId,
      featureMediaType,
      getAttachments,
      onAttachmentSaved,
      postOrUpdateMedia,
      tToast,
      toast,
    ]
  );

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  return {
    showModal,
    openModal,
    handleClose,
    handleSave,
    attachmentsList: fetchedAttachments,
    attachmentsState,
    handleDropImage,
    reloadAttachments,
    handleRemoveDropImage,
  };
}
