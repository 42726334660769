import { SVGProps } from "react";

export const XlsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_11044_724)">
      <path d="M22 7.5H14V12H22V7.5Z" fill="#21A366" />
      <path
        d="M22 12H6V20C6 20.5525 6.4475 21 7 21H21C21.5525 21 22 20.5525 22 20V12Z"
        fill="#185C37"
      />
      <path d="M22 12H14V16.5H22V12Z" fill="#107C42" />
      <path d="M14 7.5H6V12H14V7.5Z" fill="#3FA071" />
      <path d="M21 3H14V7.5H22V4C22 3.4475 21.5525 3 21 3Z" fill="#33C481" />
      <path d="M7 3H14V7.5H6V4C6 3.4475 6.4475 3 7 3Z" fill="#21A366" />
      <path
        opacity="0.05"
        d="M11.1595 6.5H6V18.5H11.1595C12.176 18.5 13 17.676 13 16.6595V8.3405C13 7.324 12.176 6.5 11.1595 6.5Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M11.1065 18H6V6.6665H11.1065C11.9685 6.6665 12.667 7.365 12.667 8.227V16.4395C12.6665 17.3015 11.968 18 11.1065 18Z"
        fill="black"
      />
      <path
        opacity="0.09"
        d="M11.053 17.5H6V6.8335H11.053C11.76 6.8335 12.333 7.4065 12.333 8.1135V16.22C12.3335 16.927 11.76 17.5 11.053 17.5Z"
        fill="black"
      />
      <path
        d="M11 17H3C2.4475 17 2 16.5525 2 16V8C2 7.4475 2.4475 7 3 7H11C11.5525 7 12 7.4475 12 8V16C12 16.5525 11.5525 17 11 17Z"
        fill="url(#paint0_linear_11044_724)"
      />
      <path
        d="M4.9035 9.5H6.0965L7.0645 11.377L8.0875 9.5H9.202L7.6665 12L9.237 14.5H8.0615L7.0065 12.535L5.956 14.5H4.763L6.3595 11.991L4.9035 9.5Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11044_724"
        x1="2.3625"
        y1="7.3625"
        x2="11.5275"
        y2="16.5275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18884F" />
        <stop offset="1" stopColor="#0B6731" />
      </linearGradient>
      <clipPath id="clip0_11044_724">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
