import { Fragment } from "react";
import { NavLink } from "react-router-dom";

import { HorizontalTabsProps } from "./index.props";
import "./styles.scss";

export function HorizontalTabs({
  tabs,
  activeTab,
  setActiveTab,
  onClick,
  disabledIndex,
}: HorizontalTabsProps) {
  return (
    <div className="horizontaltabs-wrapper">
      {tabs.map((tab, index) => {
        return (
          <Fragment key={index}>
            {tab.to !== undefined ? (
              <NavLink
                end={index === 0}
                to={tab.to}
                className={`${
                  activeTab === tab.text
                    ? "horizontaltabs-item horizontaltabs-item--active"
                    : "horizontaltabs-item"
                }`}
                onClick={() => {
                  onClick && onClick(tab.text);
                  setActiveTab(tab.text);
                }}
              >
                {tab.text}
              </NavLink>
            ) : (
              <button
                className={`${
                  activeTab === tab.text
                    ? "horizontaltabs-item horizontaltabs-item--active"
                    : "horizontaltabs-item"
                }`}
                onClick={() => {
                  onClick && onClick(tab.text);
                  setActiveTab(tab.text);
                }}
                disabled={disabledIndex === index}
              >
                {tab.text}
              </button>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
