import { ApiResponse } from "apisauce";
import { format } from "date-fns";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import * as Types from "../api/types";
import {
  IDeleteInfoResponse,
  ILocationFeatures,
  IObjectFeatures,
  IObjectProperties,
} from "../api/types";
import { FEATURE_TYPE } from "../contants/Enums";
import { FEATURE_UUID } from "../contants/FeatureUuid";
import { APIResponseType, Api } from "./api/api";

export class FeaturesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async apiDownloadGenericFeatures(
    uuid: string,
    orgId: number,
    featurePreviewData: string,
    featureStatus: string | null,
    offset: number,
    rowsPerPage: string,
    sortOrder?: string,
    sortColumn?: string,
    statusColumn?: string
  ): Promise<any> {
    const featurePreview = featurePreviewData.length
      ? `${featurePreviewData ? `${featurePreviewData}` : ""}`
      : "";
    const statusEmptyString =
      statusColumn === "recommendation" &&
      featureStatus !== null &&
      featureStatus === "";

    const status =
      statusEmptyString || featureStatus?.length
        ? `${statusColumn ? statusColumn : "general_condition"}='${
            statusEmptyString ? "" : featureStatus
          }'`
        : "";

    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/genericombruktheme/read/${uuid}`,
      {
        organizationId: orgId,
        offset,
        limit: rowsPerPage,
        filter: `${featurePreview}${
          featurePreview.length && status.length ? " AND " : ""
        }${status}`,
        sort_order: sortOrder ?? "DESC",
        sort_column: sortColumn ?? "",
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async addLocation(
    body: Types.AddLocationRequest,
    orgId: number | null
  ): Promise<Types.AddLocationResponse> {
    const last4DigitsCount = -4;
    const renovationsYears = body.additional_renovations.length
      ? body.additional_renovations
          .map(
            (year) =>
              `${format(new Date(year.date.slice(last4DigitsCount)), "yyyy")}`
          )
          .join(",")
      : "";

    const response: ApiResponse<any> = await this.api.apisauce.post(
      "ombruk/location/location",
      {
        name: body.name,
        address: body.address,
        gnrbnr: body.gnrbnr,
        fylke: body.county,
        kommune: body.municipality,
        owner: body.building_owner,
        building_category: body.building_category,
        area: parseInt(body.gross_floor_area) || 0,
        main_material_use: body.main_material,
        status: body.building_status,
        building_year: body.year_of_construction
          ? parseInt(format(new Date(body.year_of_construction), "yyyy"))
          : null,
        rehab_year: renovationsYears,
        demolition_date: body.demolition_date
          ? new Date(body.demolition_date)
          : "",
        type_of_demolition: body.type_of_demolition,
        comments: body.comment,
        fk_organization: orgId || 0,
        image: body.image,
        description: body.description,
        kartlagt_fra: body.kartlagt_fra,
        kartlagt_til: body.kartlagt_til,
        kartlagt_av: body.kartlagt_av,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async editLocation(
    body: Types.AddLocationRequest,
    locationId: number,
    orgId: number | null
  ): Promise<Types.EditLocationResponse> {
    const getLast4Digits = -4;
    const renovationsYears = body.additional_renovations.length
      ? body.additional_renovations
          .map(
            (year) =>
              `${format(new Date(year.date.slice(getLast4Digits)), "yyyy")}`
          )
          .join(",")
      : "";

    const response: ApiResponse<Types.ILocationProperties> =
      await this.api.apisauce.put(`ombruk/location/${locationId}`, {
        name: body.name,
        address: body.address,
        gnrbnr: body.gnrbnr,
        fylke: body.county,
        kommune: body.municipality,
        owner: body.building_owner,
        building_category: body.building_category,
        area: parseInt(body.gross_floor_area) || 0,
        main_material_use: body.main_material,
        status: body.building_status,
        building_year: body.year_of_construction
          ? parseInt(format(new Date(body.year_of_construction), "yyyy"))
          : null,
        rehab_year: renovationsYears,
        demolition_date: body.demolition_date
          ? new Date(body.demolition_date)
          : "",
        type_of_demolition: body.type_of_demolition,
        comments: body.comment,
        fk_organization: orgId || 0,
        image: body.image,
        geom: null,
        description: body.description,
        kartlagt_fra: body.kartlagt_fra,
        kartlagt_til: body.kartlagt_til,
        kartlagt_av: body.kartlagt_av,
      });

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async apiEditFeatureStatus(
    body: string,
    featureId: number,
    featureType: string
  ): Promise<Types.EditFeatureStatusResponse> {
    const response: ApiResponse<string> = await this.api.apisauce.put(
      `ombruk/${featureType}/status/${featureId}`,
      {
        status: body,
      }
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data };
  }

  async getSingleFeature(
    featureUuid: FEATURE_UUID,
    locationId: number,
    orgId: number
  ): Promise<
    APIResponseType<Types.ILocationProperties | Types.IObjectProperties>
  > {
    const response: ApiResponse<ILocationFeatures> =
      await this.api.apisauce.get(
        `ombruk/genericombruktheme/read/${featureUuid}`,
        {
          organizationId: orgId,
          filter: `id = ${locationId}`,
        }
      );

    const { ok, data } = response;

    const feature = data?.features[0];

    if (!ok || !feature) {
      const problem = getGeneralApiProblem(response);
      return problem ?? { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: feature };
  }

  async getMappingsProducts(
    featureUuid: FEATURE_UUID,
    locationId: number,
    orgId: number
  ): Promise<APIResponseType<IObjectProperties[]>> {
    const response: ApiResponse<IObjectFeatures> = await this.api.apisauce.get(
      `ombruk/genericombruktheme/read/${featureUuid}`,
      {
        organizationId: orgId,
        filter: `id = ${locationId}`,
      }
    );

    const { ok, data } = response;

    if (!ok || !data?.features) {
      const problem = getGeneralApiProblem(response);
      return problem ?? { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data?.features };
  }

  async apiDeleteInfo(
    orgId: number,
    idList: number[],
    objectType: string
  ): Promise<APIResponseType<IDeleteInfoResponse>> {
    console.log(idList, "idList");

    const response: ApiResponse<IDeleteInfoResponse> =
      await this.api.apisauce.get(`/ombruk/deleteinfo`, {
        organizationId: orgId,
        idList,
        objectType,
      });

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      return problem ?? { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data };
  }

  async apiDeleteProduct(productId: number): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `/ombruk/product/${productId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async apiDeleteFeatures(
    orgId: number,
    featureType: FEATURE_TYPE,
    featureIdList: number[]
  ): Promise<any> {
    let featureData = {
      url: "",
      body: {},
    };

    switch (featureType) {
      case FEATURE_TYPE.LOCATION:
        featureData = {
          url: "location",
          body: {
            organizationId: orgId,
            locationIdList: featureIdList,
          },
        };
        break;
      case FEATURE_TYPE.OBJECT_MAPPING:
        featureData = {
          url: "product/mappings",
          body: {
            organizationId: orgId,
            mappingIdList: featureIdList,
          },
        };
        break;
      case FEATURE_TYPE.PROJECT:
        featureData = {
          url: "project",
          body: {
            organizationId: orgId,
            projectIdList: featureIdList,
          },
        };
        break;
    }

    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `/ombruk/${featureData.url}/${orgId}`,
      featureData.body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }
}
