import {
  PersonAdd24Regular,
  Settings24Filled,
  Settings24Regular,
} from "@fluentui/react-icons";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import BellFilledIcon from "../../../assets/icons/bell/BellFilledIcon";
import BellIcon from "../../../assets/icons/bell/BellIcon";
import HelpOutlineFilledIcon from "../../../assets/icons/help-outline/HelpOutlineFilledIcon";
import HelpOutlineIcon from "../../../assets/icons/help-outline/HelpOutlineIcon";
import HomeFilledIcon from "../../../assets/icons/home/HomeFilledIcon";
import HomeIcon from "../../../assets/icons/home/HomeIcon";
import LocationFilledIcon from "../../../assets/icons/location/LocationFilledIcon";
import LocationIcon from "../../../assets/icons/location/LocationIcon";
import MapFilledIcon from "../../../assets/icons/map/MapFilledIcon";
import MapIcon from "../../../assets/icons/map/MapIcon";
import ObjectsFilledIcon from "../../../assets/icons/objects/ObjectsFilledIcon";
import ObjectsIcon from "../../../assets/icons/objects/ObjectsIcon";
import ProjectsFilledIcon from "../../../assets/icons/projects/ProjectsFilledIcon";
import ProjectsIcon from "../../../assets/icons/projects/ProjectsIcon";
import LogoutIcon from "../../../assets/icons/settings/logout/LogoutIcon";
import { SIDEBAR_ICON_TYPE } from "../../../contants/Enums";
import { SidebarLinkProps } from "./index.props";
import "./styles.scss";

export const SidebarLink: FC<SidebarLinkProps> = ({
  text,
  icon,
  to,
  isCollapsed,
  onClick,
  noLink,
}) => {
  const renderIcon = (isActive: boolean) => {
    switch (icon) {
      case SIDEBAR_ICON_TYPE.HOME:
        return isActive ? <HomeFilledIcon /> : <HomeIcon />;
      case SIDEBAR_ICON_TYPE.PROJECT:
        return isActive ? <ProjectsFilledIcon /> : <ProjectsIcon />;
      case SIDEBAR_ICON_TYPE.LOCATION:
        return isActive ? <LocationFilledIcon /> : <LocationIcon />;
      case SIDEBAR_ICON_TYPE.OBJECT:
        return isActive ? <ObjectsFilledIcon /> : <ObjectsIcon />;
      case SIDEBAR_ICON_TYPE.MAP:
        return isActive ? <MapFilledIcon /> : <MapIcon />;
      case SIDEBAR_ICON_TYPE.NOTIFICATION:
        return isActive ? <BellFilledIcon /> : <BellIcon />;
      case SIDEBAR_ICON_TYPE.HELP:
        return isActive ? <HelpOutlineFilledIcon /> : <HelpOutlineIcon />;
      case SIDEBAR_ICON_TYPE.SETTINGS_COG:
        return isActive ? <Settings24Filled /> : <Settings24Regular />;
      case SIDEBAR_ICON_TYPE.INVITE_PEOPLE:
        return <PersonAdd24Regular />;
      case SIDEBAR_ICON_TYPE.LOGOUT:
        return <LogoutIcon />;
      default:
        return isActive ? <HomeFilledIcon /> : <HomeIcon />;
    }
  };

  return (
    <NavLink
      className={({ isActive }) =>
        `sidebar-link ${isCollapsed ? "sidebar-link--collapsed" : ""} ${
          isActive && !noLink ? "sidebar-link--active" : ""
        }`
      }
      to={noLink ? "" : to}
      onClick={onClick}
    >
      {({ isActive }) => (
        <>
          <div>{renderIcon(isActive)}</div>
          {!isCollapsed && <span>{text}</span>}
        </>
      )}
    </NavLink>
  );
};
