import React, { FC } from "react";

const BellFilledIcon: FC = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.04173 17.003H11.9583C11.7196 18.4211 10.486 19.5015 9 19.5015C7.51402 19.5015 6.28037 18.4211 6.04173 17.003ZM9 0.00390625C13.1421 0.00390625 16.5 3.36177 16.5 7.50391V11.5024L17.9183 14.6625C17.9732 14.7848 18.0016 14.9174 18.0016 15.0515C18.0016 15.5762 17.5763 16.0015 17.0516 16.0015H0.952189C0.818404 16.0015 0.686128 15.9733 0.564021 15.9186C0.0851465 15.7042 -0.129269 15.1422 0.08511 14.6633L1.50001 11.5028L1.50011 7.49099L1.50453 7.24107C1.6436 3.21035 4.95588 0.00390625 9 0.00390625Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default BellFilledIcon;
