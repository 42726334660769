import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ASSESSMENT_RATINGS,
  BUILDING_CATEGORY,
  LOCATION_STATUS,
  OBJECT_STATUS,
  PROJECT_STATUS,
} from "../contants/Enums";

export const useSelectOptions = () => {
  const { t } = useTranslation();

  const buildStatuses = useMemo(() => {
    return [
      {
        value: LOCATION_STATUS.RENOVATION,
        label: t("options.buildingStatuses.rehabilitated"),
      },
      {
        value: LOCATION_STATUS.NEW,
        label: t("options.buildingStatuses.new"),
      },
      {
        value: LOCATION_STATUS.DEMOLISHED,
        label: t("options.buildingStatuses.demolished"),
      },
      {
        value: LOCATION_STATUS.STATIC,
        label: t("options.buildingStatuses.static"),
      },
      {
        value: LOCATION_STATUS.COMPLETED,
        label: t("options.buildingStatuses.completed"),
      },
    ];
  }, [t]);

  const buildCategories = useMemo(() => {
    return [
      {
        value: BUILDING_CATEGORY.HOUSE,
        label: t("options.category.house"),
      },
      {
        value: BUILDING_CATEGORY.APARTMENT,
        label: t("options.category.apartment"),
      },
      {
        value: BUILDING_CATEGORY.DAYCARE,
        label: t("options.category.daycare"),
      },
      {
        value: BUILDING_CATEGORY.OFFICE,
        label: t("options.category.office"),
      },
      {
        value: BUILDING_CATEGORY.SCHOOL,
        label: t("options.category.school"),
      },
      {
        value: BUILDING_CATEGORY.UNIVERSITY,
        label: t("options.category.university"),
      },
      {
        value: BUILDING_CATEGORY.HOSPITAL,
        label: t("options.category.hospital"),
      },
      {
        value: BUILDING_CATEGORY.NURSING,
        label: t("options.category.nursing"),
      },
      {
        value: BUILDING_CATEGORY.HOTEL,
        label: t("options.category.hotel"),
      },
      {
        value: BUILDING_CATEGORY.GYMNASIUM,
        label: t("options.category.gymnasium"),
      },
      {
        value: BUILDING_CATEGORY.COMMERCIAL,
        label: t("options.category.commercial"),
      },
      {
        value: BUILDING_CATEGORY.CULTURAL,
        label: t("options.category.cultural"),
      },
      {
        value: BUILDING_CATEGORY.LIGHT_INDUSTRY,
        label: t("options.category.lightIndustry"),
      },
      {
        value: BUILDING_CATEGORY.STORAGE,
        label: t("options.category.storage"),
      },
      {
        value: BUILDING_CATEGORY.PARK,
        label: t("options.category.park"),
      },
      {
        value: BUILDING_CATEGORY.OTHER,
        label: t("options.category.other"),
      },
    ];
  }, [t]);

  const conditionStatus = useMemo(() => {
    return [
      {
        value: OBJECT_STATUS.GOOD,
        label: t("options.condition.good"),
      },
      {
        value: OBJECT_STATUS.MEDIUM,
        label: t("options.condition.medium"),
      },
      {
        value: OBJECT_STATUS.BAD,
        label: t("options.condition.bad"),
      },
      {
        value: OBJECT_STATUS.NO_CONDITION,
        label: t("options.condition.noCondition"),
      },
    ];
  }, [t]);

  const projectStatus = useMemo(() => {
    return [
      {
        value: PROJECT_STATUS.PLANNING,
        label: t("options.projectStatuses.planning"),
      },
      {
        value: PROJECT_STATUS.IN_PROGRESS,
        label: t("options.projectStatuses.inProgress"),
      },
      {
        value: PROJECT_STATUS.COMPLETED,
        label: t("options.projectStatuses.completed"),
      },
    ];
  }, [t]);

  const assessmentRatings = useMemo(() => {
    return [
      {
        value: ASSESSMENT_RATINGS.NOT_RATED,
        label: t("options.assessmentRating.notRated"),
      },
      {
        value: ASSESSMENT_RATINGS.ARCHIVED,
        label: t("options.assessmentRating.archived"),
      },
      {
        value: ASSESSMENT_RATINGS.SUITABLE,
        label: t("options.assessmentRating.suitable"),
      },
      {
        value: ASSESSMENT_RATINGS.INTERNAL_USE,
        label: t("options.assessmentRating.internalUse"),
      },
      {
        value: ASSESSMENT_RATINGS.EXTERNAL_USE,
        label: t("options.assessmentRating.externalUse"),
      },
      {
        value: ASSESSMENT_RATINGS.WASTE,
        label: t("options.assessmentRating.waste"),
      },
    ];
  }, [t]);

  return {
    buildStatuses,
    buildCategories,
    conditionStatus,
    projectStatus,
    assessmentRatings,
  };
};
