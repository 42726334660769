import { t } from "i18next";
import { useCallback, useContext, useLayoutEffect, useState } from "react";

import { IObjectProperties } from "../../api/types";
import PlusIcon from "../../assets/icons/plus/PlusIcon";
import { ObjectModal } from "../../components/add-new-feature/object/ObjectModal";
import { FeatureDeleteModal } from "../../components/feature-delete-modal/FeatureDeleteModal";
import { ComponentFilterModal } from "../../components/filter-modal/ComponentFilterModal";
import { PageHeader } from "../../components/page/page-header";
import { PageSidebar } from "../../components/page/page-sidebar";
import { PageTable } from "../../components/page/page-table/PageTable";
import { PageTitle } from "../../components/page/page-title";
import { PageWrapper } from "../../components/page/page-wrapper";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_TYPE } from "../../contants/Enums";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { dashboardUrl, objectsUrl } from "../../contants/Routes";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import {
  FilterContext,
  IComponentFilter,
} from "../../context/filter-context/FilterContext";
import { useComponentFilter } from "../../hooks/useComponentFilter";
import { useObjectEdit } from "../../hooks/useObjectEdit";
import { useObjectTableColumnsWithMappings } from "../../hooks/useObjectTableColumnsWithMappings";

export function Objects() {
  const {
    objectModal,
    setObjectModal,
    setIsSidebarVisible,
    selectedTableItem,
    setSelectedTableItem,
    isSidebarVisible,
    handleObjectModalClose,
    handleRecordPreview,
    handleRecordEdit,
    handleRecordSelect,
  } = useObjectEdit();
  const { componentFilter, handleComponentFilter } = useContext(FilterContext);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { productsList, downloadGenericFeature } = useContext(FeatureContext);
  const { columns, objectTableTabs, renderObjectTableTitle } =
    useObjectTableColumnsWithMappings(productsList);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [multipleItems, setMultipleItems] = useState<number[]>([]);

  const routes = [
    { url: dashboardUrl, title: t("common.home") },
    { url: objectsUrl, title: t("pages.objects.title") },
  ];

  const filterString = useComponentFilter(componentFilter);

  const filterActive = !!filterString;

  const handleFilterModal = useCallback(() => {
    setShowFilterModal((prev) => !prev);
  }, []);

  const handleApplyFilter = useCallback(
    (filter: Partial<IComponentFilter>) => {
      handleComponentFilter(filter as Partial<IComponentFilter>);
      handleFilterModal;
    },
    [handleComponentFilter, handleFilterModal]
  );

  const handleDeleteModal = useCallback(
    (id?: number | number[]) => {
      if (Array.isArray(id)) {
        const selectedItems = id?.filter((item) => !isNaN(Number(item)));
        setMultipleItems(selectedItems);
      } else {
        const selectedItem = productsList?.find((product) => product.id === id);
        setSelectedTableItem((selectedItem as IObjectProperties) ?? null);
      }

      setShowDeleteModal((prev) => !prev);
    },
    [productsList, setSelectedTableItem]
  );

  useLayoutEffect(() => {
    downloadGenericFeature(
      FEATURE_UUID.PRODUCT,
      filterString,
      null,
      componentFilter?.limit,
      componentFilter?.offset,
      componentFilter?.sort_order,
      componentFilter?.sort_column
    );
  }, [componentFilter, downloadGenericFeature, filterString]);

  const handleCloseSidebar = useCallback(() => {
    setIsSidebarVisible(false);
  }, [setIsSidebarVisible]);

  return (
    <SidebarPage>
      <PageWrapper className="pagewrapper-wrapper--table">
        <PageHeader routes={routes} />
        <PageTitle
          title={t("pages.objects.title")}
          description={t("pages.objects.description")}
        >
          <button
            className="btn locations-title-button"
            onClick={() => {
              setObjectModal({
                mode: "add",
              });
            }}
          >
            <PlusIcon />
            <span>{t("components.sidebarAddNew.object.title")}</span>
          </button>
        </PageTitle>
        <PageTable
          title={t("components.pageTable.object")}
          subtitle={t("components.pageTable.objectsCount")}
          tabs={objectTableTabs}
          columns={columns}
          onRecordPreview={handleRecordPreview}
          featureUUid={FEATURE_UUID.PRODUCT}
          onRecordEdit={handleRecordEdit}
          onRecordDelete={handleDeleteModal}
          onRecordSelect={handleRecordSelect}
          noFeatureHandler={() => {
            setObjectModal({
              mode: "add",
            });
          }}
          onFilterClick={handleFilterModal}
          featurePreviewFilter={filterString}
          filterActive={filterActive}
          tableHeaderText={renderObjectTableTitle}
        />
        <PageSidebar
          featureDetails={selectedTableItem}
          type={FEATURE_TYPE.OBJECT}
          isSidebarVisible={isSidebarVisible}
          onClose={handleCloseSidebar}
          onEdit={handleRecordEdit}
        />
      </PageWrapper>

      {objectModal && (
        <ObjectModal
          mode={objectModal.mode}
          objectDetails={objectModal.defaultValues}
          onClose={handleObjectModalClose}
        />
      )}
      {showFilterModal && (
        <ComponentFilterModal
          onClose={handleFilterModal}
          componentFilter={componentFilter}
          onApplyFilter={handleApplyFilter}
          showFilterModal={showFilterModal}
        />
      )}
      {showDeleteModal && (multipleItems?.length || selectedTableItem?.id) && (
        <FeatureDeleteModal
          multipleFeatures={multipleItems}
          onClose={() => {
            handleDeleteModal();
            setMultipleItems([]);
          }}
          feature={selectedTableItem ?? null}
          featureType={FEATURE_TYPE.OBJECT}
        />
      )}
    </SidebarPage>
  );
}
