import Column from "@avinet/adaptive-ui-core/layout/Column";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ALERT_MESSAGE_STATUS, ALERT_MESSAGE_TYPE } from "../../contants/Enums";
import { defaultAlertMessage } from "../../contants/MockData";
import { AppUserContext } from "../../context/user-context/UserContext";
import { TextButton } from "../text-button";
import { AlertMessageProps } from "./index.props";
import "./styles.scss";

export const AlertMessage: FC<AlertMessageProps> = ({ message }) => {
  const { setAlertMessage } = useContext(AppUserContext);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const alertIcon = useCallback(() => {
    switch (message.type) {
      case ALERT_MESSAGE_TYPE.PROFILE:
        return "checkmark";
      case ALERT_MESSAGE_TYPE.FILE:
        return "folder";
      case ALERT_MESSAGE_TYPE.PLAN:
        return "upload";
      default:
        return "checkmark";
    }
  }, [message.type]);

  const alertColor = useCallback(() => {
    switch (message.status) {
      case ALERT_MESSAGE_STATUS.SUCCESS:
        return "success";
      case ALERT_MESSAGE_STATUS.FAILED:
        return "failed";
      case ALERT_MESSAGE_STATUS.REJECTED:
        return "rejected";
      default:
        return "success";
    }
  }, [message.status]);

  const handleDismissAlert = useCallback(() => {
    setVisible(false);

    setTimeout(() => {
      setAlertMessage(defaultAlertMessage);
    }, 500);
  }, [setVisible, setAlertMessage]);

  useEffect(() => {
    message.title.length > 0 && setVisible(true);
  }, [message.title]);

  useEffect(() => {
    visible &&
      setTimeout(() => {
        handleDismissAlert();
      }, 10000);
  }, [visible, setAlertMessage, handleDismissAlert]);

  return (
    <Row className={`alert-message ${visible ? "alert-message--visible" : ""}`}>
      <div className="alert-message__icon">
        <Icon name={alertIcon()} className={`icon icon--${alertColor()}`} />
      </div>

      <Column className="alert-message__text">
        <p className="alert-message__title">{message.title || ""}</p>
        <span className="alert-message__description">
          {message.description || ""}
        </span>
        <TextButton
          dark
          text={t("common.dismiss")}
          onClick={handleDismissAlert}
        />
      </Column>
      <div onClick={handleDismissAlert}>
        <Icon name="closeIcon" className="alert-message__close" />
      </div>
    </Row>
  );
};
