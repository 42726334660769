import { t } from "i18next";
import { FC } from "react";

import { AsideItemProps } from "./index.props";
import "./styles.scss";

export const AsideItem: FC<AsideItemProps> = ({
  item,
  active,
  trigger,
  error,
}) => {
  return (
    <button
      className={`aside-item-step ${
        active === item.trigger ? "aside-item-step--active" : ""
      } ${item.id < 0 ? "aside-item-step--hidden" : ""}`}
      type="button"
      ref={trigger(item.trigger)}
    >
      <span className="aside-item-step__circle">{item.id}</span>
      <div>
        <span>{t(item.text)}</span>
        {error && (
          <span className="aside-item-step__subtext aside-item-step__subtext--required">
            {t("components.locationModal.mandatory")}
          </span>
        )}
      </div>
    </button>
  );
};
