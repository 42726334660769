import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useCallback } from "react";

import { IObjectProperties } from "../api/types";
import { THUMBNAIL_SIZE } from "../contants/Enums";
import { useTranslationPath } from "../hooks/useTranslationPath";
import { getMediaUri, guidGenerator } from "../utils/index.utils";
import { ReportTableRowContent } from "./ReportTableRowContent";
import {
  IComponentReportConfig,
  IConditionQuantityMapping,
  IConditionRowData,
} from "./ReportTypes";
import { IContextFunctions } from "./ReportTypes";
import { colors } from "./colors";

function RemoveComponentNonReportFields(): (
  value: string,
  index: number,
  array: string[]
) => unknown {
  return (k) => {
    //Filter out keys we never want to display
    // TODO: Use config and user input from modal for this
    return (
      k !== "id" &&
      k !== "uuid" &&
      k !== "date_created" &&
      k !== "date_modified" &&
      k !== "created_by" &&
      k !== "modified_by" &&
      k !== "geom" &&
      k !== "fk_location" &&
      k !== "fk_room" &&
      k !== "fk_floor" &&
      k !== "fk_product" &&
      k !== "fk_project" &&
      k !== "image" &&
      k !== "mapping_uuid" &&
      k !== "mapping_image" &&
      k !== "fk_organization" &&
      k !== "specification" && // Shown in heading instead
      k !== "obj_condition" && // shown in separate table pr condition
      k !== "quantity" && // shown in separate table pr condition
      k !== "demountability_score" && // shown in separate table pr condition
      k !== "remaining_lifetime_score" && // shown in separate table pr condition
      k !== "volume_score" && // shown in separate table pr condition
      k !== "demand_score" && // shown in separate table pr condition
      k !== "environmental_effect_score" && // shown in separate table pr condition
      k !== "cost_use_score" && // shown in separate table pr condition
      k !== "recommendation" // shown in separate table pr condition
    );
  };
}

const styles = StyleSheet.create({
  componentView: {},
  componentHeader: {
    width: "100%",
    color: colors.darkTextColor,
    fontWeight: "bold",
    fontSize: 20,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.darkGray,
    marginBottom: 20,
  },
  componentHeroSectionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxHeight: 180,
  },
  componentImageContainer: {
    maxHeight: 180,
    maxWidth: 200,
    marginRight: 10,
    backgroundColor: "lightgrey",
    borderRadius: 5,
    objectFit: "scale-down",
  },
  componentDescriptionContainer: {
    padding: 15,
    borderRadius: 5,
    flex: 1,
    borderColor: colors.gray,
    borderWidth: 1,
  },
  componentDescriptionHeading: {
    fontWeight: "bold",
    fontSize: 16,
    paddingBottom: 10,
  },
  componentDescriptionText: {
    fontSize: 12,
  },
  componentTableContainer: {
    marginVertical: 10,
    backgroundColor: colors.lightContrast,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: colors.gray,
    borderWidth: 1,
    zIndex: 2,
  },
  conditionTableHeadingContainer: {
    padding: 5,
    backgroundColor: colors.success,
    borderRadius: 30,
    flexDirection: "row",
    flexGrow: 0,
    height: 30,
    width: 150,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 15,
    marginTop: 20,
    marginBottom: 5,
  },
  conditionTableHeading: {
    fontSize: 12,
    color: colors.darkTextColor,
  },
});

function getColor(condition: string) {
  if (condition === "goodcondition") {
    return colors.success700;
  } else if (condition === "okcondition") {
    return colors.warning700;
  } else if (condition === "badcondition") {
    return colors.error700;
  }
  return colors.darkContrast;
}

function getBackgroundColor(condition: string) {
  if (condition === "goodcondition") {
    return colors.success50;
  } else if (condition === "okcondition") {
    return colors.warning50;
  } else if (condition === "badcondition") {
    return colors.error50;
  }
  return colors.nocondition50;
}

export const ReportComponent = ({
  index,
  mapping,
  config,
  contextFunctions,
  conditionDictionary,
  token,
}: {
  index: number;
  mapping: IObjectProperties;
  config: IComponentReportConfig;
  contextFunctions: IContextFunctions;
  conditionDictionary: IConditionQuantityMapping;
  token: string | null;
}) => {
  const componentFieldsTranslations = useTranslationPath(
    "report.componentFields"
  );
  const componentPageTranslations = useTranslationPath("report.componentPage");
  const { getCategoryName, getParentCategoryName, getElementName } =
    contextFunctions;

  const mediaUrl =
    mapping.image && token
      ? getMediaUri(mapping.image, token, THUMBNAIL_SIZE.MEDIUM)
      : null;

  const getValueText = useCallback(
    (
      key: string,
      mapping: IObjectProperties | IConditionRowData,
      objectTranslations: (key: string) => string
    ) => {
      //Lookup dictionary values and translations for keys
      if (key === "quantity_unit") {
        return objectTranslations((mapping as any)[key] || "stk");
      } else if (key === "parent_category") {
        return getParentCategoryName((mapping as any)[key] || 0);
      } else if (key === "recommendation") {
        if ((mapping as any)[key]) {
          return componentFieldsTranslations((mapping as any)[key]);
        }
        return "";
      } else if (key === "category") {
        return getCategoryName((mapping as any)[key] || 0);
      } else if (key === "element") {
        if ((mapping as any)[key]) {
          return getElementName((mapping as any)[key] || 0);
        } else {
          return "";
        }
      }
      return (mapping as any)[key]?.toString();
    },
    [
      componentFieldsTranslations,
      getCategoryName,
      getElementName,
      getParentCategoryName,
    ]
  );

  return (
    <View break={index !== 0} style={styles.componentView}>
      <View style={styles.componentHeader}>
        <Text>{mapping.specification}</Text>
      </View>
      {(mediaUrl || mapping.additional_comments) && (
        <View style={styles.componentHeroSectionContainer}>
          {mediaUrl && (
            <View style={styles.componentImageContainer}>
              <Image src={mediaUrl} />
            </View>
          )}
          <View style={styles.componentDescriptionContainer}>
            <Text style={styles.componentDescriptionHeading}>
              {componentPageTranslations("descriptionOfComponent")}
            </Text>
            <Text style={styles.componentDescriptionText}>
              {mapping.description}
            </Text>
          </View>
        </View>
      )}
      <View style={styles.componentTableContainer}>
        {Object.keys(config)
          ?.filter(RemoveComponentNonReportFields())
          .map((p, i) => {
            const keyText = componentFieldsTranslations(p) || p;
            const valueText = getValueText(
              p,
              mapping,
              componentFieldsTranslations
            );
            return (
              <ReportTableRowContent
                key={guidGenerator()}
                keyText={keyText}
                valueText={valueText}
                index={i}
              />
            );
          })}
      </View>
      {conditionDictionary &&
        Object.keys(conditionDictionary).map((condition, i) => {
          const conditionMapping = conditionDictionary[condition];
          const conditionKey = condition.replaceAll(" ", "").toLowerCase();
          return (
            <View key={guidGenerator()}>
              <View
                style={[
                  styles.conditionTableHeadingContainer,
                  {
                    backgroundColor: getBackgroundColor(conditionKey),
                  },
                ]}
              >
                <Text
                  style={[
                    styles.conditionTableHeading,
                    { color: getColor(conditionKey) },
                  ]}
                >
                  {componentFieldsTranslations(conditionKey)}
                </Text>
              </View>
              <View style={styles.componentTableContainer}>
                {Object.keys(conditionMapping)
                  .filter((key) => key !== "obj_condition")
                  .map((key, i) => {
                    const keyText = componentFieldsTranslations(key) || key;
                    const valueText = getValueText(
                      key,
                      conditionMapping,
                      componentFieldsTranslations
                    );
                    return (
                      <ReportTableRowContent
                        key={guidGenerator()}
                        keyText={keyText}
                        valueText={valueText}
                        index={i}
                      />
                    );
                  })}
              </View>
            </View>
          );
        })}
    </View>
  );
};
