import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "swiper/css";

import { KIND_RESPONSE } from "../../api/api-problem";
import { ResetPasswordRequest } from "../../api/types";
import { AuthLanguageSelect } from "../../components/auth-language-select";
import { PasswordInput } from "../../components/password-input/PasswordInput";
import { ImageBanner } from "../../components/register/image-banner";
import { RegisterContent } from "../../components/register/register-content";
import { RegisterTitle } from "../../components/register/register-title";
import { RegisterWrapper } from "../../components/register/register-wrapper";
import { VerifyCodeInput } from "../../components/verify-code-input";
import { emailPattern } from "../../contants/Patterns";
import { AppUserContext } from "../../context/user-context/UserContext";
import "./styles.scss";

export const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const formPlaceholders = {
    emailPlaceholder: t("form.emailPlaceholder"),
    passwordPlaceholder: t("form.passwordPlaceholder"),
  };

  const { form, state } = useForm({
    defaultValues: {
      email: "",
      newPassword: "",
    },
    onSubmit: (data: ResetPasswordRequest) => handleSubmitForm(data),
    onErrors: () => getVerifyBeginError(),
  });

  const {
    resetPasswordData,
    verifyBegin,
    verify,
    resetPassword,
    setResetPasswordData,
  } = useContext(AppUserContext);
  const [verifyCode, setVerifyCode] = useState("");
  const [token, setToken] = useState<string | void>("");
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const handleSubmitForm = useCallback(
    (data: ResetPasswordRequest) => {
      if (step === 0 || step === 1) {
        verifyBegin({ email: data.email })
          .then(() => {
            setStep(1);
          })
          .catch(() => {
            console.error(data);
          });
      }

      if (step === 2 && data.newPassword) {
        resetPassword(data.newPassword)
          .then(() => {
            setStep(3);
          })
          .catch(() => {
            console.error(data);
          });
      }
    },
    [step, verifyBegin, resetPassword]
  );

  const handleVerifyResetPasswordForm = useCallback(() => {
    verify(verifyCode)
      .then((token) => {
        setToken(token);
      })
      .catch(() => {
        console.error(verifyCode);
      });
  }, [verify, verifyCode]);

  useEffect(() => {
    if (verifyCode.length === 6 && !state.newPassword) {
      if (resetPasswordData.success && resetPasswordData.value?.token) {
        setStep(2);
        setVerifyCodeError(false);
      } else if (resetPasswordData.value?.token === null) {
        setVerifyCodeError(true);
      }
    } else {
      setVerifyCodeError(false);
      resetPasswordData?.value &&
        setResetPasswordData({
          ...resetPasswordData,
          value: {
            ...resetPasswordData.value,
            token: token ?? "",
          },
        });
    }
  }, [
    verifyCode,
    resetPasswordData,
    setResetPasswordData,
    resetPasswordData.success,
    resetPasswordData.value,
    state.newPassword,
    token,
  ]);

  const getVerifyBeginError = useCallback((): string => {
    switch (resetPasswordData.error?.kind) {
      case KIND_RESPONSE.UNAUTHORIZED:
        return t("errors.errorLogin");
      case KIND_RESPONSE.CANNOT_CONNECT:
        return t("errors.serverError");
      default:
        return "";
    }
  }, [t, resetPasswordData]);

  const resetPasswordTitle = useCallback((): string => {
    switch (step) {
      case 0:
        return t("pages.resetPassword.leftSide.title");
      case 1:
        return t("pages.resetPassword.leftSide.formSendTitle");
      case 2:
        return t("pages.resetPassword.leftSide.newPasswordResetTitle");
      case 3:
        return t("pages.resetPassword.leftSide.passwordResetTitle");
      default:
        return t("pages.resetPassword.leftSide.title");
    }
  }, [t, step]);

  const resetPasswordDescription = useCallback((): string => {
    switch (step) {
      case 0:
        return t("pages.resetPassword.leftSide.description");
      case 1:
        return t("pages.resetPassword.leftSide.formSendDescription");
      case 2:
        return t("pages.resetPassword.leftSide.newPasswordResetDescription");
      case 3:
        return t("pages.resetPassword.leftSide.passwordResetDescription");
      default:
        return t("pages.resetPassword.leftSide.formSendDescription");
    }
  }, [t, step]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const renderSteps = useCallback(() => {
    switch (step) {
      case 0:
        return (
          <FormProvider form={form} state={state}>
            <form onSubmit={form.submit} className="form">
              <Input
                id="email"
                type="text"
                label={t("form.email")}
                placeholder={formPlaceholders.emailPlaceholder}
                pattern={emailPattern}
                required
                info={getVerifyBeginError()}
              />
              <button className="btn btn-form">{t("common.sendCode")}</button>
            </form>
          </FormProvider>
        );
      case 1:
        return (
          <div className="reset-password__result">
            <h4>{state.email}</h4>
            <VerifyCodeInput
              codeLength={6}
              error={verifyCodeError}
              verifyCodeValue={verifyCode}
              setVerifyCodeValue={setVerifyCode}
              resentCode={() => handleSubmitForm({ email: state.email })}
              onClickVerify={handleVerifyResetPasswordForm}
            />
          </div>
        );

      case 2:
        return (
          <FormProvider form={form} state={state}>
            <form onSubmit={form.submit} className="form">
              <PasswordInput
                id="newPassword"
                label={t("form.password")}
                placeholder={formPlaceholders.passwordPlaceholder}
                required
              />
              <button className="btn btn-form">
                {t("pages.resetPassword.leftSide.buttonText")}
              </button>
            </form>
          </FormProvider>
        );
      case 3:
        return (
          <div className="reset-password__result">
            <button className="btn light btn-cancel" onClick={handleGoBack}>
              {t("common.goBack")}
            </button>
          </div>
        );
      default:
        return null;
    }
  }, [
    t,
    step,
    form,
    getVerifyBeginError,
    state,
    formPlaceholders.emailPlaceholder,
    verifyCode,
    setVerifyCode,
    handleSubmitForm,
    handleVerifyResetPasswordForm,
    handleGoBack,
    formPlaceholders.passwordPlaceholder,
    verifyCodeError,
  ]);

  return (
    <RegisterWrapper className="reset-password">
      <RegisterContent withCopyrights>
        <RegisterTitle
          text={resetPasswordTitle()}
          description={resetPasswordDescription()}
        >
          <div className="reset-password__back">
            <button className="btn light btn-cancel" onClick={handleGoBack}>
              {t("common.back")}
            </button>
          </div>
          <AuthLanguageSelect />
          {renderSteps()}
        </RegisterTitle>
      </RegisterContent>
      <ImageBanner
        title={t("pages.login.rightSide.title")}
        description={t("pages.login.rightSide.title2")}
      />
    </RegisterWrapper>
  );
};
