import CheckBox from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import { t } from "i18next";
import React, { useState } from "react";

import { OBJECT_STATUS, THUMBNAIL_SIZE } from "../../contants/Enums";
import { BatchSelect } from "../batch-select/BatchSelect";
import { Icon } from "../icon/Icon";
import { ImagePreview } from "../image-preview/ImagePreview";
import { NoImagePlaceholer } from "../no-image";
import { ObjectStatus } from "../object/object-status";
import { Text } from "../text";
import { GridItemProps } from "./GridItem.props";
import "./GridItem.styles.scss";

export const GridItem = ({
  id = 1,
  name,
  imageData,
  objectCondition,
  conditionSum,
  handleRecordSelect,
  disableBatchSelect = false,
  selectedItems,
  handleSelectBatchItem,
}: GridItemProps) => {
  return (
    <div className="grid-item" onClick={handleRecordSelect}>
      <div className="grid-item__image">
        {!disableBatchSelect && (
          <CheckBox
            className="grid-item__image__checkbox"
            id={String(id)}
            label={null}
            onChange={(val: boolean) => handleSelectBatchItem(val, id)}
            value={selectedItems.includes(id)}
          />
        )}
        <div className="grid-item__image__condition">
          {(objectCondition?.quantity || conditionSum) && (
            <ObjectStatus
              text={
                !objectCondition?.obj_condition ||
                objectCondition?.obj_condition === ""
                  ? OBJECT_STATUS.NO_CONDITION
                  : objectCondition?.obj_condition
              }
              status={
                !objectCondition?.obj_condition ||
                objectCondition?.obj_condition === ""
                  ? OBJECT_STATUS.NO_CONDITION
                  : objectCondition?.obj_condition?.split(" ")[0]
              }
              withAmount={
                objectCondition?.quantity
                  ? objectCondition?.quantity
                  : Number(conditionSum)
              }
            />
          )}
        </div>
        {imageData?.image ? (
          <ImagePreview
            image={imageData?.image}
            name={imageData?.name}
            size={THUMBNAIL_SIZE.MEDIUM}
          />
        ) : (
          <NoImagePlaceholer />
        )}
      </div>
      <div className="grid-item__text">
        <Text text={name} fontWeight={500} size="sm" />
      </div>
    </div>
  );
};
