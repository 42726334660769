import React, { FC } from "react";

import { Copyrights } from "../../copyrights";
import { RegisterContentProps } from "./index.props";
import "./styles.scss";

export const RegisterContent: FC<RegisterContentProps> = ({
  children,
  className,
  withCopyrights = false,
}) => {
  return (
    <div
      className={`register-content ${className || ""} ${
        withCopyrights ? "register-content--with-copyrights" : ""
      }`}
    >
      {withCopyrights ? (
        <>
          <div className="register-content__items">{children}</div>
          <Copyrights />
        </>
      ) : (
        children
      )}
    </div>
  );
};
