import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import { ReportFooter } from "./ReportFooter";
import { ReportHeader } from "./ReportHeader";
import { colors } from "./colors";

const styles = StyleSheet.create({
  infoTextPage: {
    backgroundColor: colors.white,
    color: colors.darkTextColor,
  },
  content: {
    paddingHorizontal: 30,
    fontSize: 12,
  },
  heading: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 20,
  },
  paragraph: {
    marginBottom: 10,
  },
});

interface InfoTextPageProps {
  logoPath?: string;
}

export const InfoTextPage = ({ logoPath }: InfoTextPageProps) => {
  return (
    <Page style={styles.infoTextPage}>
      <ReportHeader />
      <View break={false} style={styles.content}>
        <Text style={styles.heading}>Om rapporten</Text>

        <Text style={styles.paragraph}>
          Ombrukspotensiale vurderes etter følgende momenter;
        </Text>
        <Text>
          • Demonterbarhet: Elementer som er enkle å demontere og remontere.
        </Text>
        <Text>
          • Kvalitet/Restlevetid: Elementer med god teknisk kvalitet og lang
          restlevetid.
        </Text>
        <Text>
          • Volum: Elementer som det er mange av. Det øker sjansene for ombruk,
          internt i prosjekt eller eksternt, når det er større partier av samme
          type elementer.
        </Text>
        <Text>
          • Etterspørsel: Spesielle elementer som det er etterspørsel etter,
          eller som generelt har kulturhistorisk verdi, lokal identitet eller
          andre spesielle egenskaper som kan økeattraktiviteten for ombruk.
        </Text>
        <Text>
          • Miljøeffekt: Elementer som kan gi store miljøbesparelser ved ombruk,
          dvs. elementer som har store utslipp i produksjonsfasen og/eller
          transport fra produksjonssted. Legg merke til at det er flere grunner
          til å ombruke enn kun klima, som bærekraftig ressursbruk og mindre
          avfall / deponering.
        </Text>
        <Text style={styles.paragraph}>
          • Kost/nytte: Elementer som kan gi kostnadsbesparelser ved ombruk.
        </Text>

        <Text style={styles.paragraph}>
          Det er også viktig å unngå farlig avfall, slik at elementer med helse-
          og miljøfarlige stoffer skal ut av kretsløpet, og bør ikke brukes om
          igjen. Komponenter med farlig avfall er ikke egnet for ombruk.
        </Text>

        <Text style={styles.paragraph}>
          Når det gjelder hvilke komponenter som regnes som faktisk ombrukbare,
          vil dette avhenge av behov/strategi ved ombyggingen internt, og av
          ekstern etterspørsel etter disse varene. Kunde kan velge å formidle
          overskudd av ombrukbare komponenter til egne prosjekter i regionen og
          til eksterne byggeiere/private.
        </Text>
        <Text style={styles.paragraph}>
          Komponentene er gruppert og presenteres iht inndeling i
          bygningsdelstabellen (NS 3451:2022).
        </Text>
      </View>
      <ReportFooter logoPath={logoPath} />
    </Page>
  );
};
