import useRefValue from "@avinet/adaptive-ui-core/hooks/useRefValue";
import debounce from "@avinet/adaptive-ui-core/utils/debounce";
import { FullScreenMaximize20Regular } from "@fluentui/react-icons/lib/fonts";
import { t } from "i18next";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../../api/api-problem";
import {
  AddObjectForm,
  IFullscreenModalData,
  ObjectResponse,
} from "../../../api/types";
import { FEATURE_TYPE, THUMBNAIL_SIZE } from "../../../contants/Enums";
import { useNotificationApi } from "../../../context/NotificationProvider";
import { FeatureContext } from "../../../context/feature-context/FeatureContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { useCheckMobile } from "../../../hooks/useCheckMobile";
import { getMediaUri } from "../../../utils/index.utils";
import { AddNewMapping } from "../../add-new-feature/object/add-new-mapping/AddNewMapping";
import { NoImagePlaceholer } from "../../no-image";
import { Text } from "../../text";
import { ObjectStateInfo } from "../object-state-info/ObjectStateInfo";
import "./ObjectItemList.scss";

export function ObjectItemList({
  object,
  onEdit,
  locationId,
  isOpen,
  handleItemToggle,
  setShowFullscreenModal,
}: {
  object: AddObjectForm;
  onEdit: (id: string | number) => void;
  locationId: number;
  isOpen?: boolean;
  handleItemToggle?: (id: number | string | undefined, open: boolean) => void;
  setShowFullscreenModal?: Dispatch<SetStateAction<IFullscreenModalData>>;
}) {
  const { locationFloors, getLocationFloor, editProduct, deleteFeature } =
    useContext(FeatureContext);
  const { token } = useContext(AppUserContext);

  const [productState, setProductState] = useState(() => object);
  const { isMobile } = useCheckMobile();
  const id = productState?.id;
  const image = object.image;
  const name = object.specification;
  const productLocationMappings = productState?.productLocationMappings;
  const thumbnailSizeForImages: THUMBNAIL_SIZE = useMemo(() => {
    return isMobile ? THUMBNAIL_SIZE.MEDIUM : THUMBNAIL_SIZE.LARGE;
  }, [isMobile]);

  const quantity = useMemo(
    () =>
      productState.productLocationMappings.reduce((a, b) => a + b.quantity, 0),
    [productState.productLocationMappings]
  );

  const [mappingModal, setMappingModal] = useState<{
    visible: boolean;
    mapId?: string;
  }>();

  const handleEdit = useCallback(() => {
    if (id) onEdit(id);
  }, [id, onEdit]);

  const handleEditMapping = useCallback((mapId: string) => {
    setMappingModal({ visible: true, mapId });
  }, []);

  const handleMappingClose = useCallback(() => {
    setMappingModal(undefined);
  }, []);

  const handleAddMapping = useCallback(() => {
    setMappingModal({ visible: true });
  }, []);

  const handleProductResponse = useCallback(
    (res: ObjectResponse | void) => {
      if (res?.kind === KIND_RESPONSE.OK) {
        setProductState({
          ...res.data.product,
          productLocationMappings: res.data.productLocationMappings,
        } as unknown as AddObjectForm);
      }

      if (locationId) getLocationFloor(locationId);
    },
    [getLocationFloor, locationId]
  );

  const stateRef = useRefValue(productState);

  const updateProduct = useMemo(
    () =>
      debounce(() => {
        if (!stateRef.current.id) return;
        editProduct(stateRef.current, stateRef.current.id as number);
      }, 1000),
    [editProduct, stateRef]
  );

  const handleEditQuantity = useCallback(
    (mapId: string, val: number, delta: boolean) => {
      setProductState((prev) => ({
        ...prev,
        productLocationMappings: prev.productLocationMappings.map((product) => {
          if (product.uuid !== mapId) return product;

          const quantity = delta ? product.quantity + val : val;

          return {
            ...product,
            quantity,
          };
        }),
      }));

      updateProduct();
    },
    [updateProduct]
  );

  const { notificationDialog } = useNotificationApi();

  const handleDeleteMapping = useCallback(
    async (mapId: string) => {
      const mappingItem = productLocationMappings?.find(
        (mapping) => mapping.uuid === mapId
      );
      const choice = await notificationDialog({
        icon: "delete",
        variant: "danger",
        title: t("common.delete"),
        message: t("components.objectMapping.deleteMessage"),
        choices: [
          { label: t("common.no"), value: "cancel" },
          { label: t("common.yes"), value: "delete", primaryOption: true },
        ],
      });

      if (choice === "delete") {
        mappingItem?.id &&
          deleteFeature(FEATURE_TYPE.OBJECT_MAPPING, [mappingItem?.id])?.then(
            async (res) => {
              if (res && res.kind === KIND_RESPONSE.OK) {
                const response = await editProduct(
                  {
                    ...productState,
                    productLocationMappings:
                      productState.productLocationMappings.filter(
                        (product) => product.uuid !== mapId
                      ),
                  },
                  productState.id as number
                );

                handleProductResponse(response);
              }
            }
          );
      }
    },
    [
      editProduct,
      handleProductResponse,
      notificationDialog,
      productState,
      productLocationMappings,
      deleteFeature,
    ]
  );

  const renderImg = useCallback(() => {
    return (
      <div
        className={`object-item-list__img-wrapper ${isOpen ? "img-hover" : ""}`}
      >
        {isOpen && (
          <button
            className="btn light object-item-list__img-wrapper__img-btn"
            onClick={(e) => {
              e.preventDefault();
              setShowFullscreenModal &&
                setShowFullscreenModal({
                  showModal: true,
                  sliderData: [
                    {
                      src: getMediaUri(image, token, thumbnailSizeForImages),
                      alt: name ?? "",
                    },
                  ],
                });
            }}
          >
            <FullScreenMaximize20Regular />
            <Text
              className="slider-preview__fullscreen__text"
              text={t("common.fullscreen")}
              fontWeight={600}
              size="sm"
              tag="span"
            />
          </button>
        )}
        <img
          className="object-preview-image"
          src={getMediaUri(
            image,
            token,
            isOpen ? THUMBNAIL_SIZE.LARGE : THUMBNAIL_SIZE.SMALL
          )}
          alt={image}
        />
      </div>
    );
  }, [setShowFullscreenModal, image, token, name, isOpen]);

  return (
    <>
      <div className="object-item-list">
        {image ? renderImg() : <NoImagePlaceholer />}
        <ObjectStateInfo
          id={id}
          header={name ?? "-"}
          locationFloors={locationFloors}
          itemCount={quantity}
          productLocationMappings={productLocationMappings}
          onEdit={handleEdit}
          onAddMapping={handleAddMapping}
          onEditMapping={handleEditMapping}
          onDeleteMapping={handleDeleteMapping}
          onEditQuantity={handleEditQuantity}
          isOpen={isOpen}
          quantityUnit={productState?.quantity_unit}
          onToggle={handleItemToggle}
        />
      </div>
      {mappingModal?.visible && (
        <AddNewMapping
          onClose={handleMappingClose}
          onProductUpdate={handleProductResponse}
          object={productState}
          locationId={locationId}
          mapId={mappingModal?.mapId}
        />
      )}
    </>
  );
}
