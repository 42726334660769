/* eslint-disable */
import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import { AddProjectForm } from "../api/types";
import { Api } from "./api/api";

export class ProjectApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async createProject(
    body: AddProjectForm,
    organizationId: number
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `ombruk/project/project`,
      {
        ...body,
        fk_organization: organizationId,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async editProject(
    body: AddProjectForm,
    projectId: number,
    organizationId: number
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      `ombruk/project/${projectId}`,
      {
        ...body,
        fk_organization: organizationId,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async apiGetLocationProjects(locationId: number): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/project/${locationId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }
}

/* eslint-disable */
