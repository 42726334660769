import { FC, useCallback } from "react";

import { TitleProps } from "./index.props";
import "./styles.scss";

export const Title: FC<TitleProps> = ({
  title,
  fontWeight,
  className,
  size,
  color,
  tag,
}) => {
  const titleBold = useCallback(() => {
    return fontWeight ? `title--bold--${fontWeight}` : "";
  }, [fontWeight]);

  const titleSize = useCallback(() => {
    return size ? `title--size--${size}` : "";
  }, [size]);

  const titleColor = useCallback(() => {
    return color ? `title--color--${color}` : "";
  }, [color]);

  const renderText = useCallback(() => {
    switch (tag) {
      case "h1":
        return (
          <h1
            className={`title ${
              className ? className : ""
            } ${titleSize()} ${titleBold()} ${titleColor()}`}
          >
            {title}
          </h1>
        );
      case "h2":
        return (
          <h2
            className={`title ${
              className ? className : ""
            } ${titleSize()} ${titleBold()} ${titleColor()}`}
          >
            {title}
          </h2>
        );
      case "h3":
        return (
          <h3
            className={`title ${
              className ? className : ""
            } ${titleSize()} ${titleBold()} ${titleColor()}`}
          >
            {title}
          </h3>
        );
      case "h4":
        return (
          <h4
            className={`title ${
              className ? className : ""
            } ${titleSize()} ${titleBold()} ${titleColor()}`}
          >
            {title}
          </h4>
        );

      default:
        return (
          <h1
            className={`title ${
              className ? className : ""
            } ${titleSize()} ${titleBold()} ${titleColor()}`}
          >
            {title}
          </h1>
        );
    }
  }, [tag, className, titleSize, titleBold, title, titleColor]);

  return <>{renderText()}</>;
};
