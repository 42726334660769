import Row from "@avinet/adaptive-ui-core/layout/Row";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { TextSwitchProps } from "./index.props";
import "./styles.scss";

export const TextSwitch: FC<TextSwitchProps> = ({
  customOptions,
  selectedOption,
  selectedOptionChange,
}) => {
  const { t } = useTranslation();

  const options = customOptions?.length
    ? customOptions
    : [t("common.none"), t("common.inApp"), t("common.email")];

  const handleSelectOption = useCallback(
    (option: string) => {
      selectedOptionChange(option);
    },
    [selectedOptionChange]
  );

  return (
    <Row className="text-switch">
      {options.length &&
        options.map((option, index) => (
          <p
            onClick={() => handleSelectOption(option)}
            className={`text-switch__option ${
              selectedOption === option ? "text-switch__option--active" : ""
            }`}
            key={`${option}-${index}`}
          >
            {option}
          </p>
        ))}
    </Row>
  );
};
