import { useMemo } from "react";

import { TableSelectProps } from "./index.props";
import "./styles.scss";

export const TableSelect = ({
  items,
  value,
  onChange,
  className,
}: TableSelectProps) => {
  const options = useMemo(
    () =>
      items.map((item) => (
        <option key={item.value as string} value={item.value as string}>
          {item.label}
        </option>
      )),
    [items]
  );

  return (
    <div className={`select ${className}`}>
      <select
        className="table-select"
        name="table-select"
        value={value}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options}
      </select>
    </div>
  );
};
