import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { UserDetails } from "../../components/profile/";
import { SidebarPage } from "../../components/sidebar/sidebar-page";

export const Profile: FC = () => {
  const location = useLocation();

  return (
    <SidebarPage noSidebar={location?.state?.withPrevButton}>
      <UserDetails />
    </SidebarPage>
  );
};
