import {useContext, useEffect, useMemo, useState} from "react";

import { IMediaFeature, sliderData } from "../../api/types";
import { AppUserContext } from "../../context/user-context/UserContext";
import { getMediaUri, isFileMIMEImageType } from "../../utils/index.utils";
import { NoImagePlaceholer } from "../no-image";
import { SliderPreview } from "../slider-preview";
import "./styles.scss";
import {useCheckMobile} from "../../hooks/useCheckMobile";
import {THUMBNAIL_SIZE} from "../../contants/Enums";

export function FeatureImagePreview({
  imagesList,
  thumbnailUuid,
}: {
  imagesList: IMediaFeature[];
  thumbnailUuid?: string | null;
}) {
  const { token } = useContext(AppUserContext);
  const [sliderItems, setSliderItems] = useState<sliderData[]>();
  const { isMobile } = useCheckMobile();

  const thumbnailSizeForImages: THUMBNAIL_SIZE = useMemo(() => {
    return isMobile ? THUMBNAIL_SIZE.MEDIUM : THUMBNAIL_SIZE.LARGE;
  }, [isMobile]);

  useEffect(() => {
    const arrCopy = [
      ...imagesList.filter((item: IMediaFeature) =>
        isFileMIMEImageType(item.file_type)
      ),
    ];

    arrCopy.sort((a) => (a.media === thumbnailUuid ? -1 : 0));

    setSliderItems(
      arrCopy.map((item: IMediaFeature) => ({
        src: getMediaUri(item.media, token, thumbnailSizeForImages),
        alt: item.title,
      }))
    );
  }, [imagesList, thumbnailUuid, token, thumbnailSizeForImages]);

  if (sliderItems && sliderItems.length) {
    return <SliderPreview sliderItems={sliderItems} />;
  } else {
    return <NoImagePlaceholer className="feature-image-preview--no-image" />;
  }
}
