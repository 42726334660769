import { useContext, useEffect, useState } from "react";

import { AppUserContext } from "../context/user-context/UserContext";
import { IInvite } from "../utils/types/types";

export const useHasInvite = () => {
  const { userProfile } = useContext(AppUserContext);
  const [hasInvite, setHasInvite] = useState(false);

  useEffect(() => {
    const storedInvites = localStorage.getItem("invites");
    const existingInvites = storedInvites ? JSON.parse(storedInvites) : [];

    const invitesToHandle = existingInvites.filter(
      (invite: IInvite) => invite.email === userProfile?.email
    );

    setHasInvite(invitesToHandle.length > 0);
  }, [userProfile]);

  return hasInvite;
};
