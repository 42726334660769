import { InputEl } from "@avinet/adaptive-ui-core/form/controls/Input";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";

import { Text } from "../../../../text";
import { LocationFloorProps } from "./index.props";
import "./styles.scss";

export function LocationFloor({
  className = "",
  floor,
  handleFloorRename,
  handleAddNewRoom,
  handleRoomRename,
  handleDeleteFloor,
  handleDeleteRoom,
}: LocationFloorProps) {
  return (
    <div className={`location-modal-floor ${className}`}>
      <div
        className={`location-modal-floor__room ${
          floor.room_list.length ? "location-modal-floor__room--border" : ""
        } `}
      >
        <InputEl
          id="floor_name"
          placeholder={t("form.enterFloorPlaceholder") ?? ""}
          label={t("components.locationModal.floor")}
          value={floor.name}
          onChange={(value) => handleFloorRename(floor.uuid, value)}
          required
        />
        <button
          className="btn-link icon danger"
          onClick={() => handleDeleteFloor(floor.uuid)}
        >
          <Icon name="delete" />
        </button>
      </div>

      {floor.room_list.map((room, count) => {
        return (
          <div className="location-modal-floor__room" key={room.uuid}>
            <InputEl
              id={`room_name_${room.uuid}`}
              placeholder={t("form.enterRoomPlaceholder") ?? ""}
              label={`${t("components.locationModal.room", {
                count: count + 1,
              })}`}
              value={room.name}
              onChange={(value) => handleRoomRename(room.uuid, value)}
            />
            <button
              className="btn-link icon danger"
              onClick={() => handleDeleteRoom(room.uuid)}
            >
              <Icon name="delete" />
            </button>
          </div>
        );
      })}

      <button
        className="btn small"
        onClick={() => handleAddNewRoom(floor.uuid)}
      >
        <Icon name="add" />
        <Text
          text={t("common.addRoom")}
          fontWeight={600}
          className={floor.room_list.length >= 2 ? "text__hidden" : ""}
        />
      </button>
    </div>
  );
}
