import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import CheckBox from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import FormError from "@avinet/adaptive-ui-core/form/utils/FormError";
import { FC, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RegisterRequest } from "../../api/types";
import { AuthLanguageSelect } from "../../components/auth-language-select";
import { Navigation } from "../../components/navigation";
import { PasswordInput } from "../../components/password-input/PasswordInput";
import { ImageBanner } from "../../components/register/image-banner";
import { RegisterContent } from "../../components/register/register-content";
import { RegisterTitle } from "../../components/register/register-title";
import { RegisterWrapper } from "../../components/register/register-wrapper";
import {
  enterCodeUrl,
  loginUrl,
  termsAndConditionsUrl,
} from "../../contants/Routes";
import { AppUserContext } from "../../context/user-context/UserContext";
import "./styles.scss";

export const Register: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { verifyBegin, setRegisterUserData } = useContext(AppUserContext);

  const formLabels = {
    nameLabel: `${t("form.name")}*`,
    surnameLabel: `${t("form.surname")}*`,
    emailLabel: `${t("form.email")}*`,
    passwordLabel: `${t("form.password")}*`,
  };

  const formPlaceholders = {
    namePlaceholder: t("form.namePlaceholder"),
    surnamePlaceholder: t("form.surnamePlaceholder"),
    emailPlaceholder: t("form.emailPlaceholder"),
    passwordPlaceholder: t("form.passwordPlaceholder"),
  };

  const onSubmit = useCallback(
    (data: RegisterRequest) => {
      verifyBegin({ email: data.email })
        .then(() => {
          setRegisterUserData({
            loading: false,
            value: data,
            error: undefined,
          });
          navigate(enterCodeUrl, {
            state: {
              data,
            },
          });
        })
        .catch(() => {
          console.error(data);
        });
    },
    [navigate, setRegisterUserData, verifyBegin]
  );

  const { form, state } = useForm({
    onSubmit,
  });

  return (
    <RegisterWrapper>
      <RegisterContent withCopyrights>
        <RegisterTitle
          text={t("pages.register.leftSide.title")}
          description={t("pages.register.leftSide.description")}
        >
          <AuthLanguageSelect className="register-language" />
          <FormProvider form={form} state={state}>
            <form onSubmit={form.submit} className="form">
              <Input
                id="name"
                type="text"
                minLength={2}
                label={formLabels.nameLabel}
                placeholder={formPlaceholders.namePlaceholder}
                required
              />
              <Input
                id="surname"
                type="text"
                minLength={2}
                label={formLabels.surnameLabel}
                placeholder={formPlaceholders.surnamePlaceholder}
                required
              />
              <Input
                id="email"
                type="email"
                label={formLabels.emailLabel}
                placeholder={formPlaceholders.emailPlaceholder}
                required
              />
              <FormError id="error" key="email" />
              <PasswordInput
                id="password"
                label={formLabels.passwordLabel}
                placeholder={formPlaceholders.passwordPlaceholder}
                required
                autoComplete="new-password"
              />
              <div className="form__privacy">
                <CheckBox
                  id="consents"
                  className="terms-checkbox"
                  label={
                    <>
                      {t("PrivacyConsent.iAccept")}
                      <a
                        href={termsAndConditionsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("PrivacyConsent.termsAndConditions")}
                      </a>
                      *
                    </>
                  }
                  required
                />
              </div>
              <button className="btn btn-form">
                {t("common.createAccount")}
              </button>
            </form>
          </FormProvider>
          <Navigation
            url={loginUrl}
            navText={t("pages.register.leftSide.accountTextButton")}
            text={t("pages.register.leftSide.accountText")}
          />
        </RegisterTitle>
      </RegisterContent>
      <ImageBanner
        title={t("pages.register.rightSide.title")}
        description={t("pages.register.rightSide.description")}
      />
    </RegisterWrapper>
  );
};
