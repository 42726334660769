import { useMemo } from "react";

import { PROJECT_STATUS } from "../contants/Enums";
import { IProjectFilter } from "../context/filter-context/FilterContext";

export function useProjectFilter(
  projectFilter: Partial<IProjectFilter> | undefined
) {
  const filterString = useMemo(() => {
    // status
    const status = projectFilter?.status;
    const statusFilter = status && `status='${status}'`;

    // start and end date
    const startDate = projectFilter?.start_date;
    const startDateFilter = startDate && `start_date >= DATE('${startDate}')`;
    const endDate = projectFilter?.end_date;
    const endDateFilter = endDate && `end_date <= DATE('${endDate}')`;

    //creation date
    const creationStartDate = projectFilter?.creation_start_date;
    const creationEndDate = projectFilter?.creation_end_date;
    const creationStartDateFilter =
      creationStartDate && `date_created >= DATE('${creationStartDate}')`;
    const creationEndDateFilter =
      creationEndDate && `date_created <=DATE('${creationEndDate}')`;
    const creationDateFilter =
      (creationStartDate &&
        creationEndDate &&
        `date_created BETWEEN DATE('${creationStartDate}') AND DATE('${creationEndDate}')`) ||
      [creationStartDateFilter, creationEndDateFilter]
        .filter(Boolean)
        .join(" AND ");

    // locations
    const locations = projectFilter?.locations;
    const locationFilter =
      locations && `'{${locations?.toString()}}'` + " <@ location_list";

    // active tab
    const activeTab = projectFilter?.activeTab;
    let activeTabFilter = "";
    if (activeTab === "Planlagt") {
      activeTabFilter = `status='${PROJECT_STATUS.PLANNING}'`;
    } else if (activeTab === "Pågår") {
      activeTabFilter = `status='${PROJECT_STATUS.IN_PROGRESS}'`;
    } else if (activeTab === "Fullført") {
      activeTabFilter = `status='${PROJECT_STATUS.COMPLETED}'`;
    }

    return [
      statusFilter,
      startDateFilter,
      endDateFilter,
      creationDateFilter,
      locationFilter,
      activeTabFilter,
    ]
      .filter(Boolean)
      .join(" AND ");
  }, [projectFilter]);

  return filterString;
}
