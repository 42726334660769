import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IOrganisation } from "../../api/types";
import { OrganisationsList } from "../../components/organisation/organisations-list";
import { dashboardUrl } from "../../contants/Routes";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../context/user-context/UserContext";
import { IInvite } from "../../utils/types/types";

export const SelectOrganisation = () => {
  const { handleSetUserOrganisation, userProfile } = useContext(AppUserContext);
  const { joinOrganisation, downloadOrganisations, setSelectedOrganisation } =
    useContext(OrganisationContext);
  const navigate = useNavigate();
  const [showHelpModal, setShowHelpModal] = useState(false);

  const handleChooseOrganisation = useCallback(
    (organisation: IOrganisation, edit?: boolean) => {
      handleSetUserOrganisation(organisation.name);
      setSelectedOrganisation(organisation);

      !edit && navigate(dashboardUrl);
    },
    [navigate, handleSetUserOrganisation, setSelectedOrganisation]
  );

  const handleHelpModal = (state: boolean) => {
    setShowHelpModal(state);
  };

  useEffect(() => {
    const storedInvites = localStorage.getItem("invites");
    const existingInvites = storedInvites ? JSON.parse(storedInvites) : [];

    const invitesToHandle = existingInvites.filter(
      (invite: IInvite) => invite.email === userProfile?.email
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleJoinPromises: Promise<any>[] = [];

    invitesToHandle.forEach((invite: IInvite) => {
      handleJoinPromises.push(joinOrganisation(invite.inviteCode));
    });

    Promise.all(handleJoinPromises)
      .then(() => {
        const updatedInvites = existingInvites.filter(
          (existingInvite: IInvite) => !invitesToHandle.includes(existingInvite)
        );
        localStorage.setItem("invites", JSON.stringify(updatedInvites));

        return downloadOrganisations();
      })
      .catch((error) => {
        console.error("Error:", error);
        return downloadOrganisations();
      });
  }, [downloadOrganisations, joinOrganisation, userProfile?.email]);

  return (
    <>
      <OrganisationsList
        showHelpModal={showHelpModal}
        handleHelpModal={handleHelpModal}
        chooseOrganisation={handleChooseOrganisation}
      />
    </>
  );
};
