import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import { RadioEl } from "@avinet/adaptive-ui-core/form/controls/Radio";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import Switch from "@avinet/adaptive-ui-core/ui/Switch";
import React, {
  ClipboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { KIND_RESPONSE } from "../../api/api-problem";
import {
  AddOrganisationRequest,
  AddOrganisationsResponse,
} from "../../api/types";
import { DEFAULT_MEDIA_UUID } from "../../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../../contants/FeatureUuid";
import { emailRegex } from "../../contants/Patterns";
// import { discountPerSeat, seatPrice } from "../../contants/Prices";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import { useDropImage } from "../../hooks/useDropImage";
import { useErrorMessage } from "../../hooks/useErrorMessage";
// import { ElementsCounter } from "../counter";
import { ErrorMessage } from "../error-message";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { FileDropzone } from "../file-dropzone";
import { Heading } from "../heading";
import { InputTooltip } from "../input-tooltip";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import { Text } from "../text";
import { UpdateOrgModalProps } from "./UpdateOrgModal.props";
import "./UpdateOrgModal.scss";

export const UpdateOrgModal = ({
  modalState,
  onSubmission,
  orgMembers,
  isOrgCreation,
}: UpdateOrgModalProps) => {
  const { t } = useTranslation();
  const [seatsUsed] = useState(orgMembers);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(true);
  //TODO: in future: Refactor code for Adaptive Form and inputs
  const [billingAddress, setBillingAddress] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingContact, setBillingContact] = useState("");
  const [billingReference, setBillingReference] = useState("");
  const [isAddressInput, setIsAddressInput] = useState(true);
  const [isEmailInput, setIsEmailInput] = useState(false);
  const [isValidFields, setIsValidFields] = useState(false);
  const [radioValue, setRadioValue] = useState("Email");

  const { selectedOrganisation, updatePaymentPlan } =
    useContext(OrganisationContext);

  const seatsAvailable = useMemo<number>(() => {
    return isOrgCreation ? 0 : selectedOrganisation?.member_limit || 0;
  }, [isOrgCreation, selectedOrganisation?.member_limit]);

  const isAnnualPayment = useMemo(() => {
    if (isOrgCreation) return false;
    if (selectedOrganisation?.yearly_payment) {
      return selectedOrganisation.yearly_payment;
    }
    return false;
  }, [isOrgCreation, selectedOrganisation?.yearly_payment]);

  const [counter] = useState<number>(isOrgCreation ? 1 : seatsAvailable);
  const [annualPayment, setAnnualPayment] = useState<boolean>(isAnnualPayment);

  useEffect(() => {
    if (!showAddOrgModal) {
      if (radioValue === "Email") {
        setIsEmailInput(true);
        setIsAddressInput(false);
        setBillingAddress("");
        setBillingPostalCode("");
      } else if (radioValue === "Address") {
        setIsEmailInput(false);
        setIsAddressInput(true);
        setBillingEmail("");
      }
    }
  }, [showAddOrgModal, radioValue]);

  function isValidPostalCode(postalCode: string): boolean {
    const postalCodeRegex = /^\d{4}$/;
    return postalCodeRegex.test(postalCode);
  }

  const handleValidateFields = useCallback(() => {
    const emailAddressInput = document.getElementById("billingAddress");
    const billingEmailInput = document.getElementById("billingEmail");
    const billingContactInput = document.getElementById("billingContact");
    const billingPostalCodeInput = document.getElementById("billingPostalCode");
    const billingReferenceInput = document.getElementById("billingReference");

    if (isAddressInput) {
      billingAddress.length < 4
        ? emailAddressInput?.parentElement?.classList.add("input-error")
        : emailAddressInput?.parentElement?.classList.remove("input-error");
      !isValidPostalCode(billingPostalCode)
        ? billingPostalCodeInput?.parentElement?.classList.add("input-error")
        : billingPostalCodeInput?.parentElement?.classList.remove(
            "input-error"
          );
    } else if (isEmailInput) {
      !emailRegex.test(billingEmail)
        ? billingEmailInput?.parentElement?.classList.add("input-error")
        : billingEmailInput?.parentElement?.classList.remove("input-error");
    }

    billingContact.length <= 0
      ? billingContactInput?.parentElement?.classList.add("input-error")
      : billingContactInput?.parentElement?.classList.remove("input-error");
    billingReference.length <= 0
      ? billingReferenceInput?.parentElement?.classList.add("input-error")
      : billingReferenceInput?.parentElement?.classList.remove("input-error");

    const addressValid =
      billingAddress.length > 4 && isValidPostalCode(billingPostalCode);
    const contactAndReferenceValid =
      billingContact.length > 0 && billingReference.length > 0;

    let isValid = false;

    if (isAddressInput && radioValue === "Address") {
      isValid = addressValid && contactAndReferenceValid;
    } else if (billingEmail && radioValue === "Email") {
      isValid = emailRegex.test(billingEmail) && contactAndReferenceValid;
    }

    return setIsValidFields(isValid);
  }, [
    radioValue,
    isEmailInput,
    billingEmail,
    billingAddress,
    billingPostalCode,
    billingContact,
    billingReference,
    isAddressInput,
  ]);

  useEffect(() => {
    (billingAddress.length || billingEmail.length) &&
      showUpdatePaymentModal &&
      handleValidateFields();
  }, [
    showUpdatePaymentModal,
    handleValidateFields,
    billingAddress,
    billingEmail,
  ]);

  const handleSubmission = useCallback(async () => {
    handleValidateFields();

    if (isOrgCreation && isValidFields) {
      setShowAddOrgModal(true);
      setShowUpdatePaymentModal(false);
    } else {
      if (!selectedOrganisation?.id) return;
      try {
        await updatePaymentPlan(
          counter,
          annualPayment,
          selectedOrganisation?.id
        );
        onSubmission(counter, annualPayment);
        modalState(false);
      } catch (error) {
        console.error("Error updating payment plan:", error);
      }
    }
  }, [
    handleValidateFields,
    isValidFields,
    selectedOrganisation?.id,
    isOrgCreation,
    updatePaymentPlan,
    counter,
    annualPayment,
    onSubmission,
    modalState,
  ]);

  const handleSwitch = useCallback(() => {
    setAnnualPayment(!annualPayment);
  }, [annualPayment]);

  const handleCloseModal = useCallback(() => {
    modalState(false);
  }, [modalState]);

  //------------------------ code for create org modal --------------------------------

  const { getErrorMessage } = useErrorMessage();
  const { addNewOrganisation } = useContext(OrganisationContext);
  const [error, setError] = useState<string>("");

  const { form, state, setDirty } = useForm({
    defaultValues: {
      org_nr: "",
      name: "",
      description: "",
      image: DEFAULT_MEDIA_UUID,
    },
    onSubmit: (data: AddOrganisationRequest) => {
      const modifiedData = {
        ...data,
        address: billingAddress + " " + billingPostalCode,
        billing_email: billingEmail,
        member_limit: counter,
        yearly_payment: annualPayment,
        contact_person: billingContact,
        billing_reference: billingReference,
      };
      handleSubmitForm(modifiedData);
    },
  });

  const formLabels = {
    orgNumberLabel: t("components.addOrgModal.orgNumber"),
    orgNameLabel: t("components.addOrgModal.name"),
    orgDescriptionLabel: t("components.addOrgModal.orgDescription", {
      availableLetters: 48 - state.description.length,
    }),
  };
  const formPlaceholders = {
    orgNumberPlaceholder: t("components.addOrgModal.orgNumberPlaceholder"),
    orgNamePlaceholder: t("components.addOrgModal.namePlaceholder2"),
    orgDescriptionPlaceholder: t(
      "components.addOrgModal.orgDescriptionPlaceholder"
    ),
  };

  const { addedImg, onDropImage, handleClearImage, imgId, updateImage } =
    useDropImage();

  const handleSubmitForm = (data: AddOrganisationRequest) => {
    const addOrgError = t("errors.addOrganisationError");
    addNewOrganisation(data)
      .then((res: AddOrganisationsResponse | void) => {
        if (res && res.kind) {
          if (res.kind === KIND_RESPONSE.OK) {
            res.data.id &&
              updateImage(FEATURE_MEDIA_TYPE.ORGANIZATION, res.data.id);
            handleCloseModal();
            setDirty(false);
          } else {
            if (res.kind === KIND_RESPONSE.BAD_DATA) {
              if (res.body?.includes("org_nr")) {
                setError(t("errors.orgNumberError") || "");
              } else {
                setError(addOrgError || "");
              }
            }
            if (res.kind === KIND_RESPONSE.CONFLICT) {
              setError(t("errors.orgNumberTaken") || "");
            } else {
              typeof res.kind === "string" &&
                setError(getErrorMessage(res.kind, addOrgError));
            }
          }
        }
      })
      .catch(() => {
        console.error(data);
      });
  };

  const handleCloseAddOrgModal = () => {
    setShowAddOrgModal(false);
    setShowUpdatePaymentModal(true);
    setIsValidFields(false);
  };

  useEffect(() => {
    if (imgId.length > 0) {
      state.image = imgId;
    }
  }, [imgId, state, state.image]);

  const onOrgNrPaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault(); // Prevent the normal paste event
      const text = (e.clipboardData.getData("Text") as string) // Clean the pasted input
        ?.trim()
        .replaceAll(" ", "")
        .substring(0, 9);

      form.setValue("org_nr", text);
    },
    [form]
  );

  return (
    <>
      {/* ------------------------ payment modal -------------------------------- */}
      <div className="change-plan-modal">
        <Modal className="feature-modal" show={showUpdatePaymentModal}>
          <div className="feature-modal-container change-plan">
            <FeatureModalHeader
              subtitle={
                isOrgCreation
                  ? t("components.createPlanModal.description")
                  : t("components.profilePlanModal.description", {
                      seatsUsed: orgMembers,
                      available: seatsAvailable,
                    })
              }
              modalState={modalState}
              onClose={handleCloseModal}
              noBorder
              {...{
                title: isOrgCreation
                  ? t("components.createPlanModal.title")
                  : t("components.profilePlanModal.title"),
                description: "",
              }}
            />
            {seatsUsed && seatsUsed > counter && (
              <div className="change-plan__occupied">
                <div className="change-plan__occupied__text">
                  <Text
                    className="occupied__title"
                    text={t("components.profilePlanModal.occupiedSeats")}
                  />
                  <Text
                    className="occupied__description"
                    text={t(
                      "components.profilePlanModal.occupiedSeatsDescription"
                    )}
                  />
                </div>
                <button className="btn light" onClick={handleCloseModal}>
                  {t("components.profilePlanModal.seatOverview")}
                </button>
              </div>
            )}
            <div className="change-plan__payment-settings">
              <div className="change-plan__annual-billing">
                <div className="change-plan__annual-billing__text">
                  <Text
                    className="annual-billing__title"
                    text={t("components.profilePlanModal.annualBilling")}
                  />
                  <div className="annual-billing__description">
                    <Text
                      text={t("components.profilePlanModal.annualPriceOffer")}
                    />
                    {/* <Text
                      text={t("components.profilePlanModal.annualPriceOffer1")}
                    /> */}
                    {/* <Text text={annualPriceOffer2} /> */}
                  </div>
                </div>
                <div className="change-plan__annual-billing__switch">
                  <Switch value={annualPayment} onChange={handleSwitch} />
                  <Text
                    className="annual-billing__switch__text"
                    text={t("components.profilePlanModal.payAnnually")}
                  />
                </div>
              </div>
              <hr />
              {isOrgCreation && (
                <div className="change-plan__billing-info-input">
                  <div className="change-plan__billing-info-input__header">
                    <div className="change-plan__billing-info-input__header__title">
                      <Text
                        className="billing-info__title"
                        text={t("components.profilePlanModal.billingInfo")}
                      />
                      <Text
                        className="billing-info__description"
                        text={t("components.createPlanModal.legalAddress")}
                      />
                    </div>
                    <div className="change-plan__billing-info-input__header__radio">
                      <RadioEl
                        id="billing_type"
                        group="email"
                        label={t("common.email")}
                        value={radioValue === "Email"}
                        onChange={() => setRadioValue("Email")}
                      />
                      <RadioEl
                        id="billing_type"
                        group="address"
                        label={t("common.address")}
                        value={radioValue === "Address"}
                        onChange={() => setRadioValue("Address")}
                      />
                    </div>
                  </div>
                  {isAddressInput && (
                    <div className="change-plan__billing-info-input__row">
                      <label htmlFor="billingAddress">
                        {t("components.createPlanModal.billingAddress")}
                      </label>
                      <input
                        id="billingAddress"
                        type="text"
                        className="billing-info__input"
                        minLength={5}
                        placeholder={
                          t(
                            "components.createPlanModal.billingAddressPlaceholder"
                          ) ?? ""
                        }
                        value={billingAddress}
                        onChange={(e) => setBillingAddress(e.target.value)}
                      />
                    </div>
                  )}
                  {isEmailInput && (
                    <div className="change-plan__billing-info-input__row">
                      <label htmlFor="billingEmail">
                        {t("components.createPlanModal.billingEmail")}
                      </label>
                      <input
                        id="billingEmail"
                        type="text"
                        className="billing-info__input"
                        placeholder={
                          t(
                            "components.createPlanModal.billingEmailPlaceholder"
                          ) ?? ""
                        }
                        value={billingEmail}
                        onChange={(e) => setBillingEmail(e.target.value)}
                      />
                    </div>
                  )}
                  {isAddressInput && (
                    <div className="change-plan__billing-info-input__row">
                      <label htmlFor="billingPostalCode">
                        {t("components.createPlanModal.postalCode")}
                      </label>
                      <input
                        id="billingPostalCode"
                        type="text"
                        className="billing-info__input"
                        placeholder={
                          t(
                            "components.createPlanModal.postalCodePlaceholder"
                          ) ?? ""
                        }
                        value={billingPostalCode}
                        onChange={(e) => setBillingPostalCode(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="change-plan__billing-info-input__row">
                    <label htmlFor="billingContact">
                      {t("components.createPlanModal.contactPerson")}
                    </label>
                    <input
                      id="billingContact"
                      type="text"
                      className="billing-info__input"
                      placeholder={
                        t("components.createPlanModal.contactPerson") ?? ""
                      }
                      value={billingContact}
                      onChange={(e) => setBillingContact(e.target.value)}
                    />
                  </div>
                  <div className="change-plan__billing-info-input__row">
                    <label htmlFor="billingReference">
                      {t("components.createPlanModal.reference")}
                    </label>
                    <input
                      id="billingReference"
                      type="text"
                      className="billing-info__input"
                      placeholder={
                        t("components.createPlanModal.referencePlaceholder") ??
                        ""
                      }
                      value={billingReference}
                      onChange={(e) => setBillingReference(e.target.value)}
                    />
                  </div>
                </div>
              )}
              {/*
              <hr />
               <div className="change-plan__seats">
                <div className="change-plan__seats__text">
                  <Text
                    className="seats__title"
                    text={t("components.profilePlanModal.seats")}
                  />
                  <Text className="seats__description" text={pricePerSeat} />
                </div>
                <div className="change-plan__seats__counter">
                  <ElementsCounter
                    mapId="seats"
                    counter={counter}
                    onEditQuantity={handleQuantityChange}
                  />
                </div>
              </div> */}
            </div>

            {/* <div className="change-plan__overview">
              <div className="change-plan__summary">
                <div className="change-plan__summary__text">
                  <Text
                    className="summary__title"
                    text={t("components.profilePlanModal.summary")}
                  />
                  <Text
                    className="summary__current-seats"
                    text={currentSeats}
                  />
                  {isRemovingSeats ? (
                    <Text
                      className="summary__remove-seats"
                      text={removingSeats}
                    />
                  ) : (
                    <Text className="summary__add-seats" text={addingSeats} />
                  )}
                  {annualPayment && (
                    <Text
                      className="summary__discount"
                      text={annualBillingDiscount}
                    />
                  )}
                </div>
                <div className="change-plan__summary__price">
                  <Text className="total" text={`${currentSeatsPrice} NOK`} />
                  <Text
                    className="diff"
                    text={
                      priceDiff > 0 ? `+${priceDiff} NOK` : `${priceDiff} NOK`
                    }
                  />
                  {annualPayment && (
                    <Text className="discount" text={`-${totalDiscount} NOK`} />
                  )}
                </div>
              </div>
              <div className="change-plan__billing-info">
                <Text
                  className="billing-info__title"
                  text={t("components.profilePlanModal.billingInfo")}
                />
                <Text
                  className="billing-info__text"
                  text={
                    isOrgCreation
                      ? isAddressInput
                        ? billingAddress + " " + billingPostalCode
                        : billingEmail
                      : (selectedOrganisation?.address as string)
                  }
                />
                {!isOrgCreation && (
                  <Text
                    className="billing-info__text"
                    text={("Org.No: " + selectedOrganisation?.org_nr) as string}
                  />
                )}
              </div>
              <div className="change-plan__next-payment">
                <div className="next-payment__text">
                  <Text
                    className="next-payment__title"
                    text={t("components.profilePlanModal.nextPayment")}
                  />
                  <Text text={t("components.profilePlanModal.date")} />
                </div>
                <Text className="next-payment__date" text="Feb 2024" />{" "}
                {/* TODO: Add billing date when ready from backend }
              </div>
              
            <div className="change-plan__next-bill">
              <div className="next-bill__logo-text">
                <Icon name="walletCreditCard" className="wallet-icon" />
                <Text text={t("components.profilePlanModal.nextBill")} />
              </div>
              <Text text={`${totalPrice} NOK`} />
            </div>
            </div> */}
            <div className="change-plan__buttons">
              <button className="btn light" onClick={handleCloseModal}>
                {t("components.profilePlanModal.cancelButton")}
              </button>
              <button
                className="btn"
                onClick={handleSubmission}
                type="submit"
                // disabled={isSubmitButtonDisabled}
              >
                {isOrgCreation
                  ? t("common.next")
                  : t("components.profilePlanModal.updatePlanButton")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {/* ------------------------ create org modal -------------------------------- */}
      {isOrgCreation && (
        <>
          <Modal show={showAddOrgModal}>
            <div className="add-org-modal">
              <div onClick={handleCloseModal} className="add-org-modal__close">
                <Icon name="closeIcon" />
              </div>
              <div>
                <Heading
                  title={t("components.addOrgModal.title")}
                  description={t("components.addOrgModal.description")}
                  small
                />
                <FormProvider form={form} state={state}>
                  <form className="form" id="org-form" onSubmit={form.submit}>
                    <InputTooltip
                      id="org_nr"
                      type="text"
                      minLength={9}
                      maxLength={9}
                      label={formLabels.orgNumberLabel}
                      placeholder={formPlaceholders.orgNumberPlaceholder}
                      required
                      tooltipMessage={t(
                        "components.addOrgModal.orgNumberTooltip"
                      )}
                      onPaste={onOrgNrPaste}
                    />
                    <Input
                      id="name"
                      minLength={2}
                      type="text"
                      label={formLabels.orgNameLabel}
                      placeholder={formPlaceholders.orgNamePlaceholder}
                      required
                    />
                    <InputTooltip
                      id="description"
                      type="text"
                      label={formLabels.orgDescriptionLabel}
                      placeholder={formPlaceholders.orgDescriptionPlaceholder}
                      maxLength={48}
                      tooltipMessage={t(
                        "components.addOrgModal.orgDescriptionTooltip"
                      )}
                    />
                  </form>
                  <FileDropzone
                    onDrop={onDropImage}
                    file={addedImg}
                    deleteFile={handleClearImage}
                    text={t("components.addOrgModal.companyLogo")}
                  />
                  <ErrorMessage text={error.length > 0 ? error : ""} />
                  <SubmitButtons
                    cancelText={t("common.back")}
                    onCancel={handleCloseAddOrgModal}
                    formId="org-form"
                  />
                </FormProvider>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
