import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import CheckBox from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { t } from "i18next";
import { useCallback, useContext, useLayoutEffect, useMemo } from "react";

import { QUANTITY_UNIT } from "../../contants/Enums";
import { DictionaryContext } from "../../context/dictionary-context/DictionaryContext";
import { IComponentFilter } from "../../context/filter-context/FilterContext";
import { useTranslationPath } from "../../hooks/useTranslationPath";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { FeatureModalWrapper } from "../feature/feature-modal-wrapper";
import { InputSelect } from "../input-select/InputSelect";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import "./FilterModal.scss";

export function ComponentFilterModal({
  onClose,
  componentFilter,
  showFilterModal,
  onApplyFilter: handleComponentFilter,
  inProject,
}: {
  onClose: () => void;
  componentFilter: Partial<IComponentFilter> | undefined;
  showFilterModal: boolean;
  onApplyFilter: (filter: Partial<IComponentFilter>) => void;
  inProject?: boolean;
}) {
  const tForm = useTranslationPath("components.objectModal.form");
  const tPlaceholder = useTranslationPath("components.objectFilterModal");

  const handleSubmit = useCallback(
    (data: Partial<IComponentFilter>) => {
      handleComponentFilter(data);
      onClose();
    },
    [handleComponentFilter, onClose]
  );

  const { form, state } = useForm({
    defaultValues: componentFilter,
    onSubmit: handleSubmit,
  });

  const {
    categoryDictionary,
    parentCategoryDictionary,
    elementDictionary,
    getDictionaries,
  } = useContext(DictionaryContext);

  useLayoutEffect(() => {
    getDictionaries();
  }, [getDictionaries]);

  const quantityUnits = useMemo(
    () => [
      {
        label: tForm("labels.quantity"),
        value: QUANTITY_UNIT.STK,
      },
      {
        label: tForm("labels.m2"),
        value: QUANTITY_UNIT.M2,
      },
      {
        label: tForm("labels.m3"),
        value: QUANTITY_UNIT.M3,
      },
      {
        label: tForm("labels.lm"),
        value: QUANTITY_UNIT.LM,
      },
      {
        label: tForm("labels.other"),
        value: QUANTITY_UNIT.OTHER,
      },
    ],
    [tForm]
  );

  const filteredCategoryDictionary = useMemo(() => {
    if (state.parent_category) {
      return categoryDictionary.filter(
        (category) => category.parent_category === state.parent_category
      );
    } else {
      return categoryDictionary;
    }
  }, [categoryDictionary, state.parent_category]);

  const filteredElementsDictionary = useMemo(() => {
    if (state.category) {
      return elementDictionary.filter(
        (element) => element.parent_category === state.category
      );
    } else {
      return elementDictionary;
    }
  }, [elementDictionary, state.category]);

  const handleClearCondition = useCallback(() => {
    form.setValue("good_condition", undefined);
    form.setValue("ok_condition", undefined);
    form.setValue("bad_condition", undefined);
    form.setValue("no_condition", undefined);
  }, [form]);

  const handleClearAssessment = useCallback(() => {
    form.setValue("assessed", undefined);
    form.setValue("score_from", undefined);
    form.setValue("score_to", undefined);
    form.setValue("evaluation", undefined);
  }, [form]);

  const handleClearAmount = useCallback(() => {
    form.setValue("amount_from", undefined);
    form.setValue("amount_to", undefined);
    form.setValue("amount_unit", undefined);
  }, [form]);

  const handleClearCategory = useCallback(() => {
    form.setValue("parent_category", undefined);
    form.setValue("category", undefined);
    form.setValue("element", undefined);
  }, [form]);

  const handleClearHeight = useCallback(() => {
    form.setValue("height_from", undefined);
    form.setValue("height_to", undefined);
  }, [form]);

  const handleClearWidth = useCallback(() => {
    form.setValue("width_from", undefined);
    form.setValue("width_to", undefined);
  }, [form]);

  const handleClearLength = useCallback(() => {
    form.setValue("length_from", undefined);
    form.setValue("length_to", undefined);
  }, [form]);

  const handleClearDiameter = useCallback(() => {
    form.setValue("diameter_from", undefined);
    form.setValue("diameter_to", undefined);
  }, [form]);

  const handleClearWeight = useCallback(() => {
    form.setValue("weight_from", undefined);
    form.setValue("weight_to", undefined);
  }, [form]);

  const handleClearDates = useCallback(() => {
    form.setValue("date_produced", undefined);
    form.setValue("date_registered_from", undefined);
    form.setValue("date_registered_to", undefined);
  }, [form]);

  const resetForm = useCallback(() => {
    handleClearCondition();
    handleClearAssessment();
    handleClearAmount();
    handleClearCategory();
    handleClearHeight();
    handleClearWidth();
    handleClearLength();
    handleClearDiameter();
    handleClearWeight();
    handleClearDates();
  }, [
    handleClearAmount,
    handleClearAssessment,
    handleClearCategory,
    handleClearCondition,
    handleClearDates,
    handleClearDiameter,
    handleClearHeight,
    handleClearLength,
    handleClearWeight,
    handleClearWidth,
  ]);

  const scoreFromPlaceholder = tPlaceholder("scoreFromHelp");
  const scoreToPlaceholder = tPlaceholder("scoreToHelp");
  const fromPlaceholder = tPlaceholder("fromHelp");
  const fromLargePlaceholder = tPlaceholder("fromHelpLarge");
  const toPlaceholder = tPlaceholder("toHelp");
  const toLargePlaceholder = tPlaceholder("toHelpLarge");

  const unitPlaceholder = tPlaceholder("unitHelp");
  const categoryPlaceholder = tPlaceholder("categoryHelp");
  const mainCategoryPlaceholder = tPlaceholder("mainCategoryHelp");
  const elementPlaceholder = tPlaceholder("elementHelp");
  const yearProductedPlaceholder = tPlaceholder("yearProducedHelp");

  return (
    <Modal className="filter-modal" show={showFilterModal}>
      <FeatureModalWrapper>
        <FeatureModalHeader
          modalState={onClose}
          onClose={onClose}
          title={t("components.objectFilterModal.headerMain")}
          subtitle={""}
        />
        <FormProvider form={form} state={state}>
          <form
            className={`form-container`}
            id="project-form"
            onSubmit={form.submit}
            translate={undefined}
          >
            {/* CONDITION */}
            {inProject && (
              <FeatureModalWrapper classNames="wrapper-content">
                <section className="feature-modal-drop" id="condition">
                  <div className="header-container">
                    <h4>{t("components.objectFilterModal.condition")}</h4>
                    <button
                      className="btn purple"
                      type="button"
                      onClick={handleClearCondition}
                    >
                      {t("components.objectFilterModal.clear")}
                    </button>
                  </div>
                  <div className="checkbox-container-outer">
                    <div className="checkbox-container">
                      <CheckBox
                        id={"good_condition"}
                        label={
                          <div className="container object-status--Good">
                            <p className="">{t("options.condition.good")}</p>
                          </div>
                        }
                      />
                    </div>
                    <div className="checkbox-container">
                      <CheckBox
                        id={"ok_condition"}
                        label={
                          <div className="container object-status--Ok">
                            <p>{t("options.condition.medium")}</p>
                          </div>
                        }
                      />
                    </div>
                    <div className="checkbox-container">
                      <CheckBox
                        id={"bad_condition"}
                        label={
                          <div className="container object-status--Bad">
                            <p>{t("options.condition.bad")}</p>
                          </div>
                        }
                      />
                    </div>
                    <div className="checkbox-container">
                      <CheckBox
                        id={"no_condition"}
                        label={
                          <div className="container object-status--No">
                            <p>{t("options.condition.noCondition")}</p>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </section>
              </FeatureModalWrapper>
            )}

            {/* ASSESSMENT*/}
            <FeatureModalWrapper classNames="wrapper-content">
              <section className="feature-modal-drop" id="assessment">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.assessment")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearAssessment}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>

                <CheckBox
                  id={"assessed"}
                  label={t("components.objectFilterModal.assessmentHelp")}
                />

                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="score_from"
                        min={0}
                        max={30}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={scoreFromPlaceholder}
                        label={t("components.objectFilterModal.scoreFrom")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="score_to"
                        min={0}
                        max={30}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={scoreToPlaceholder}
                        label={t("components.objectFilterModal.scoreTo")}
                      />
                    </div>
                  </div>
                </div>
              </section>{" "}
            </FeatureModalWrapper>
            {/* AMOUNT*/}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="amount">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.amount")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearAmount}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="amount_from"
                        min={0}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={fromPlaceholder}
                        label={t("components.objectFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="amount_to"
                        min={0}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={toPlaceholder}
                        label={t("components.objectFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
                <div className="subsection">
                  <InputSelect
                    id="amount_unit"
                    items={quantityUnits}
                    label={t("components.objectFilterModal.unit")}
                    placeholder={unitPlaceholder}
                  />
                </div>
              </section>
            </FeatureModalWrapper>

            {/* CATEGORY */}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="category">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.categoryHeader")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearCategory}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <InputSelect
                    id="parent_category"
                    items={parentCategoryDictionary}
                    label={t("components.objectFilterModal.mainCategory")}
                    placeholder={mainCategoryPlaceholder}
                  />
                </div>
                <div className="subsection">
                  <InputSelect
                    id="category"
                    items={filteredCategoryDictionary}
                    label={t("components.objectFilterModal.category")}
                    placeholder={categoryPlaceholder}
                  />
                </div>
                <div className="subsection">
                  <InputSelect
                    id="element"
                    items={filteredElementsDictionary}
                    label={t("components.objectFilterModal.element")}
                    placeholder={elementPlaceholder}
                  />
                </div>
              </section>
            </FeatureModalWrapper>

            {/* HEIGHT */}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="height">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.height")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearHeight}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="height_from"
                        min={0}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={fromLargePlaceholder}
                        label={t("components.objectFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="height_to"
                        min={0}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={toLargePlaceholder}
                        label={t("components.objectFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>

            {/* WIDTH */}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="width">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.width")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearWidth}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="width_from"
                        min={0}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={fromLargePlaceholder}
                        label={t("components.objectFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="width_to"
                        min={0}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={toLargePlaceholder}
                        label={t("components.objectFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>

            {/* LENGTH */}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="length">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.length")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearLength}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="length_from"
                        min={0}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={fromLargePlaceholder}
                        label={t("components.objectFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="length_to"
                        min={0}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={toLargePlaceholder}
                        label={t("components.objectFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>

            {/* DIAMETER */}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="diameter">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.diameter")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearDiameter}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="diameter_from"
                        min={0}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={fromLargePlaceholder}
                        label={t("components.objectFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="diameter_to"
                        min={0}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={toLargePlaceholder}
                        label={t("components.objectFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>

            {/* WEIGHT */}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="weight">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.weight")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearWeight}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="weight_from"
                        min={0}
                        defaultValue={state.width_from ?? undefined}
                        placeholder={fromLargePlaceholder}
                        label={t("components.objectFilterModal.from")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <NumberInput
                        type="integer"
                        id="weight_to"
                        min={0}
                        defaultValue={state.width_to ?? undefined}
                        placeholder={toLargePlaceholder}
                        label={t("components.objectFilterModal.to")}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>

            {/* DATES*/}
            <FeatureModalWrapper classNames="wrapper-content">
              <section id="dates">
                <div className="header-container">
                  <h4>{t("components.objectFilterModal.date")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearDates}
                  >
                    {t("components.objectFilterModal.clear")}
                  </button>
                </div>
                <div className="subsection">
                  <div className="aui-form-control aui-text">
                    <Input
                      id="date_produced"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]{4}"
                      minLength={4}
                      maxLength={4}
                      label={t("components.objectFilterModal.yearProduced")}
                      placeholder={yearProductedPlaceholder}
                    />
                  </div>
                </div>
                <div className="subsection">
                  <div className="date-container">
                    <div className="aui-form-control aui-text">
                      <Input
                        type="date"
                        id="date_registered_from"
                        max="2999-12-31"
                        placeholder={""}
                        label={t("components.objectFilterModal.registeredFrom")}
                      />
                    </div>

                    <div className="aui-form-control aui-text">
                      <Input
                        type="date"
                        id="date_registered_to"
                        max="2999-12-31"
                        placeholder={""}
                        label={t("components.objectFilterModal.registeredTo")}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </FeatureModalWrapper>
          </form>
          <div className="filter-modal-buttons">
            <SubmitButtons
              onCancel={resetForm}
              submitText={t("components.objectFilterModal.apply")}
              cancelText={t("components.objectFilterModal.reset")}
              formId="project-form"
              resetMode
            />
          </div>
        </FormProvider>
      </FeatureModalWrapper>
    </Modal>
  );
}
