import { isAfter } from "date-fns";
import React, {
  Context,
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import * as API from "../../api/types";
import {
  IAlertMessages,
  IOrganisationStore,
  IUserDetails,
  UserUpdateResponse,
} from "../../api/types";
import {
  ALERT_MESSAGE_STATUS,
  ALERT_MESSAGE_TYPE,
  USER_PROFILE_MODAL_TYPE,
} from "../../contants/Enums";
import LOCAL_STORAGE from "../../contants/LocalStorage";
import { defaultAlertMessage } from "../../contants/MockData";
import { Environment } from "../../environment/environment";
import { useAlertMessage } from "../../hooks/useAlertMessage";
import { UserApi } from "../../services/UserApi";
import { getFromStore, saveToStore } from "../../utils/functions/local-storage";
import { ContextStateValue, IContext } from "../ContextInterfaces";

interface AppUserContextConfig {
  register: (body: API.RegisterRequest, token: void | string) => Promise<void>;
  updateUser: (
    body: IUserDetails,
    token?: void | string,
    profileType?: USER_PROFILE_MODAL_TYPE
  ) => Promise<UserUpdateResponse | void>;
  updateUserImage: (body: File) => Promise<void>;
  login: (body: API.LoginRequest) => Promise<void>;
  verifyBegin: (body: API.VerifyBeginRequest) => Promise<void>;
  verify: (code: string) => Promise<void | string>;
  logout: () => void;
  isLogin: boolean | undefined;
  user: ContextStateValue<API.UserItem>;
  resetPassword: (newPassword: string) => Promise<void>;
  resetPasswordData: ContextStateValue<API.VerifyBeginRequest>;
  changePassword: (
    body: API.ChangePasswordRequest
  ) => Promise<void | KIND_RESPONSE>;
  changePasswordData: ContextStateValue<API.ChangePasswordRequest>;
  registerUserData: ContextStateValue<API.RegisterRequest>;
  setChangePasswordData: (
    body: ContextStateValue<API.ChangePasswordRequest>
  ) => void;
  setResetPasswordData: (
    body: ContextStateValue<API.VerifyBeginRequest>
  ) => void;
  setRegisterUserData: (body: ContextStateValue<API.RegisterRequest>) => void;
  handleSetUserOrganisation: (type: string) => Promise<void | string>;
  userOrganisationType: string;
  allUserOrganisationTypes: IOrganisationStore[] | [];
  getUser: () => Promise<boolean>;
  userProfile: API.IUserData;
  userProfileLoading: boolean | undefined;
  deleteUserAccount: () => Promise<void>;
  token: string | null;
  alertMessage: IAlertMessages;
  setAlertMessage: Dispatch<SetStateAction<IAlertMessages>>;
  environment?: Environment;
}

const defaultConfig: AppUserContextConfig = {
  register: () => Promise.reject(),
  login: () => Promise.reject(),
  verifyBegin: () => Promise.reject(),
  verify: () => Promise.reject(),
  changePassword: () => Promise.reject(),
  logout: () => undefined,
  resetPassword: () => Promise.reject(),
  resetPasswordData: { loading: false, value: undefined, error: undefined },
  changePasswordData: { loading: false, value: undefined, error: undefined },
  registerUserData: { loading: false, value: undefined, error: undefined },
  setChangePasswordData: () => Promise.reject(),
  setResetPasswordData: () => Promise.reject(),
  setRegisterUserData: () => Promise.reject(),
  handleSetUserOrganisation: () => Promise.reject(),
  userOrganisationType: "",
  allUserOrganisationTypes: [],
  isLogin: undefined,
  user: { loading: undefined, value: undefined, error: undefined },
  userProfile: undefined,
  userProfileLoading: undefined,
  getUser: () => Promise.reject(),
  updateUser: () => Promise.reject(),
  updateUserImage: () => Promise.reject(),
  deleteUserAccount: () => Promise.reject(),
  token: null,
  alertMessage: {
    title: "",
    description: "",
    type: ALERT_MESSAGE_TYPE.PROFILE,
    status: ALERT_MESSAGE_STATUS.SUCCESS,
  },
  setAlertMessage: () => Promise.reject(),
  environment: undefined,
};

export const AppUserContext: Context<AppUserContextConfig> =
  createContext(defaultConfig);

export const AppUserContextProvider: FC<IContext> = ({
  children,
  environment,
}) => {
  const [registerUserData, setRegisterUserData] = useState<
    ContextStateValue<API.RegisterRequest>
  >({
    loading: false,
    value: undefined,
    error: undefined,
  });
  const [token, setToken] = useState<string | null>(null);
  const [isLogin, setIsLogin] = useState<boolean>();
  const [user, setUser] = useState<ContextStateValue<API.UserItem>>({
    loading: undefined,
    value: undefined,
    error: undefined,
  });
  const [alertMessage, setAlertMessage] =
    useState<IAlertMessages>(defaultAlertMessage);
  const {
    successProfileMessage,
    failedProfileMessage,
    failedChangedEmailMessage,
    successRegisterMessage,
    failedRegisterMessage,
  } = useAlertMessage();
  const [userProfile, setUserProfile] = useState<API.IUserData>(undefined);
  const [userProfileLoading, setUserProfileLoading] = useState<boolean>();

  const [resetPasswordData, setResetPasswordData] = useState<
    ContextStateValue<API.VerifyBeginRequest>
  >({
    loading: false,
    value: undefined,
    error: undefined,
  });

  const [changePasswordData, setChangePasswordData] = useState<
    ContextStateValue<API.ChangePasswordRequest>
  >({
    loading: false,
    value: undefined,
    error: undefined,
  });
  const [userOrganisationType, setUserOrganisationType] = useState<string>("");
  const [allUserOrganisationTypes, setAllUserOrganisationTypes] = useState<
    IOrganisationStore[] | []
  >([]);

  const register = useCallback(
    async (body: API.RegisterRequest, token: void | string) => {
      setRegisterUserData((prevState) => ({
        ...prevState,
        error: null,
        loading: true,
      }));

      return new UserApi(environment.api)
        .registerUser(body, token)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setRegisterUserData((prevState) => ({
              ...prevState,
              error: null,
              loading: true,
              value: {
                name: body.name,
                surname: body.name,
                email: body.email,
                password: "",
                consents: body.consents,
              },
            }));
            setAlertMessage(successRegisterMessage);
          } else {
            if (response.kind !== KIND_RESPONSE.UNAUTHORIZED) {
              setAlertMessage(failedRegisterMessage);
            }
            setRegisterUserData((prevState) => ({
              ...prevState,
              loading: false,
              error: response,
            }));
          }
        })
        .catch(console.error);
    },
    [environment.api, successRegisterMessage, failedRegisterMessage]
  );

  const login = useCallback(
    async (body: API.LoginRequest) => {
      setUser((prevState) => ({ ...prevState, loading: true }));
      return new UserApi(environment.api)
        .loginUser(body)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            await saveToStore<API.UserItem>(
              LOCAL_STORAGE.USER,
              response.data
            ).then(async () => {
              const userOrganisation = await getFromStore<IOrganisationStore[]>(
                LOCAL_STORAGE.USER_ORGANISATION
              );

              userOrganisation?.length
                ? setAllUserOrganisationTypes(userOrganisation)
                : await saveToStore(LOCAL_STORAGE.USER_ORGANISATION, []);
            });
            setToken(response.data.session_id || null);
            setUser((prevState) => ({
              ...prevState,
              loading: false,
              value: response.data,
              error: null,
            }));
          } else {
            setUser({
              loading: false,
              error: response,
            });
          }
        })
        .catch(console.error);
    },
    [environment]
  );

  const verifyBegin = useCallback(
    async (body: API.VerifyBeginRequest) => {
      setResetPasswordData((prevState) => ({
        ...prevState,
        loading: true,
        error: null,
      }));

      return new UserApi(environment.api)
        .verifyBegin(body)
        .then((response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setResetPasswordData((prevState) => ({
              ...prevState,
              loading: false,
              value: {
                email: body.email,
                token: "",
              },
            }));
          } else {
            setResetPasswordData((prevState) => ({
              ...prevState,
              loading: false,
              error: response,
            }));
          }
        })
        .catch(console.error);
    },
    [environment.api]
  );

  const verify = useCallback(
    async (code: string) => {
      setResetPasswordData((prevState) => ({
        ...prevState,
        error: null,
        loading: true,
      }));
      const body: API.VerifyRequest = {
        email: resetPasswordData?.value?.email ?? "",
        code,
      };

      return new UserApi(environment.api)
        .verify(body)
        .then((response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setResetPasswordData((prevState) => ({
              ...prevState,
              loading: false,
              value: {
                email: body.email,
                token: response.data.token,
                password: "",
              },
              success: response.data.success,
            }));
            return response.data.token;
          } else {
            setResetPasswordData((prevState) => ({
              ...prevState,
              loading: false,
              error: response,
            }));
          }
        })
        .catch(console.error);
    },
    [resetPasswordData, environment.api]
  );

  const resetPassword = React.useCallback(
    async (newPassword: string) => {
      setResetPasswordData((prevState) => ({
        ...prevState,
        error: null,
        loading: true,
      }));

      const body: API.ResetPasswordRequest = {
        email: resetPasswordData?.value?.email ?? "",
        token: resetPasswordData?.value?.token ?? "",
        newPassword,
      };

      return new UserApi(environment.api)
        .resetPassword(body)
        .then((response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setResetPasswordData((prevState) => ({
              ...prevState,
              value: {
                email: resetPasswordData?.value?.email ?? "",
                token: resetPasswordData?.value?.token ?? "",
                password: newPassword,
              },
              loading: false,
            }));
          } else {
            setResetPasswordData((prevState) => ({
              ...prevState,
              loading: false,
              error: response,
            }));
          }
        })
        .catch(console.error);
    },
    [resetPasswordData, environment.api]
  );

  const changePassword = React.useCallback(
    async (body: API.ChangePasswordRequest) => {
      setResetPasswordData((prevState) => ({
        ...prevState,
        loading: true,
        error: null,
      }));

      return new UserApi(environment.api)
        .changePassword(body)
        .then((response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setChangePasswordData((prevState) => ({
              ...prevState,
              error: null,
              loading: false,
              value: {
                oldPassword: body.oldPassword,
                newPassword: body.newPassword,
              },
            }));
            setAlertMessage(successProfileMessage);
            return response.kind;
          } else {
            if (response.kind !== KIND_RESPONSE.UNAUTHORIZED) {
              setAlertMessage(failedProfileMessage);
            }
            setChangePasswordData((prevState) => ({
              ...prevState,
              loading: false,
              error: response,
            }));
            return response.kind;
          }
        })
        .catch(console.error);
    },
    [environment.api, successProfileMessage, failedProfileMessage]
  );

  const logout = useCallback(async () => {
    setIsLogin(false);
    setToken(null);
    setUserOrganisationType("");
    setUserProfile(undefined);
    setUserProfileLoading(false);

    await saveToStore<API.UserItem>(LOCAL_STORAGE.USER, {
      uuid: user?.value?.uuid,
      session_id: undefined,
      expiration_time: undefined,
    });
    setUser({ loading: false, value: undefined });
  }, [user?.value?.uuid]);

  const getUser = useCallback(async () => {
    setUserProfileLoading(true);

    const response = await new UserApi(environment.api).getUserData();

    setUserProfileLoading(false);

    if (response.kind === KIND_RESPONSE.OK) {
      setUserProfile(response.data);
      return true;
    } else {
      setUserProfile(undefined);
      return false;
    }
  }, [environment.api]);

  const deleteUserAccount = useCallback(async () => {
    return new UserApi(environment.api)
      .deleteUserAccount()
      .then(async (response) => {
        if (response.kind === KIND_RESPONSE.OK) {
          await logout();
        }
      })
      .catch(console.error);
  }, [environment, logout]);

  const handleSetUserOrganisation = useCallback(
    async (type: string) => {
      setUserOrganisationType(type);

      if (userProfile?.uuid) {
        const userIndex = allUserOrganisationTypes.findIndex(
          (org) => org.uuid === userProfile.uuid
        );
        allUserOrganisationTypes[userIndex] = { type, uuid: userProfile.uuid };

        allUserOrganisationTypes && allUserOrganisationTypes?.length > 0
          ? await saveToStore<IOrganisationStore[]>(
              LOCAL_STORAGE.USER_ORGANISATION,
              userIndex === -1
                ? [
                    ...allUserOrganisationTypes,
                    { type, uuid: userProfile.uuid },
                  ]
                : allUserOrganisationTypes
            )
          : await saveToStore<IOrganisationStore[]>(
              LOCAL_STORAGE.USER_ORGANISATION,
              [{ type, uuid: userProfile.uuid }]
            );

        return type ?? "";
      }
    },
    [userProfile?.uuid, allUserOrganisationTypes]
  );

  const updateUser = useCallback(
    async (
      body: IUserDetails,
      token?: void | string,
      profileType?: USER_PROFILE_MODAL_TYPE
    ) => {
      setUserProfileLoading(true);
      return new UserApi(environment.api)
        .updateUserData(body, token)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setUserProfile(response.data);
            !token && setAlertMessage(successProfileMessage);
            setUserProfileLoading(false);
            return response;
          } else {
            if (
              profileType === USER_PROFILE_MODAL_TYPE.EMAIL &&
              response.kind === KIND_RESPONSE.REJECTED
            ) {
              setAlertMessage(failedChangedEmailMessage);
              return response;
            }

            setAlertMessage(failedProfileMessage);
            setUserProfileLoading(false);
            return response;
          }
        })
        .catch(console.error);
    },
    [
      environment,
      successProfileMessage,
      failedProfileMessage,
      failedChangedEmailMessage,
    ]
  );

  const updateUserImage = useCallback(
    async (body: File) => {
      setUserProfileLoading(true);
      return new UserApi(environment.api)
        .updateUserImage(body)
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            setUserProfile(response.data);
            setAlertMessage(successProfileMessage);
          } else {
            setAlertMessage(failedProfileMessage);
          }
          setUserProfileLoading(false);
        })
        .catch(console.error);
    },
    [environment, failedProfileMessage, successProfileMessage]
  );

  useEffect(() => {
    (async function () {
      const userStore = await getFromStore<API.UserItem>(LOCAL_STORAGE.USER);
      const userOrganisation = await getFromStore<IOrganisationStore[]>(
        LOCAL_STORAGE.USER_ORGANISATION
      );
      userOrganisation?.length && setAllUserOrganisationTypes(userOrganisation);
      if (userStore) {
        if (isAfter(new Date(), userStore.expiration_time as number)) {
          setToken(userStore.session_id || null);
          setUser({ value: userStore, loading: false, error: null });
          userOrganisation?.length &&
            userOrganisation.find(
              (org) =>
                org.uuid === userStore.uuid &&
                setUserOrganisationType(org.type.length ? org.type : "")
            );
        } else {
          await logout();
        }
      }
    })();
  }, [environment, logout]);

  useEffect(() => {
    token && environment.addAuthToken(token);
    token && !userProfile?.userData && getUser();
  }, [environment, token, userProfile?.userData, getUser]);

  useLayoutEffect(() => {
    if (user.loading !== false) return;
    setIsLogin(!!token && user.value !== undefined);
  }, [token, user]);

  if (!environment) {
    return null;
  }

  return (
    <AppUserContext.Provider
      value={{
        register,
        login,
        user,
        verifyBegin,
        verify,
        logout,
        isLogin,
        resetPassword,
        resetPasswordData,
        setResetPasswordData,
        changePassword,
        changePasswordData,
        registerUserData,
        setChangePasswordData,
        handleSetUserOrganisation,
        userOrganisationType,
        allUserOrganisationTypes,
        getUser,
        userProfile,
        userProfileLoading,
        updateUser,
        updateUserImage,
        deleteUserAccount,
        token,
        alertMessage,
        setAlertMessage,
        setRegisterUserData,
        environment,
      }}
    >
      {children}
    </AppUserContext.Provider>
  );
};
