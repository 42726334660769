import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { ProfileCardProps } from "./index.props";
import "./styles.scss";

export const ProfileInfo: FC<ProfileCardProps> = ({
  title,
  data,
  onClick,
  editOnly,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`profile-info ${disabled && "profile-info--disabled"}`}
      onClick={onClick}
    >
      <div className="profile-info__text">
        <p className="profile-info__text__title">{title}</p>
        {editOnly ? (
          <>
            <div className="profile-info__edit">{editOnly}</div>
          </>
        ) : (
          <span className="profile-info__text__data">{data}</span>
        )}
      </div>
      {!editOnly && !disabled && (
        <div className="profile-info__edit">{t("common.edit")}</div>
      )}
    </div>
  );
};
