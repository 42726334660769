import React from "react";

const CourseStarMediumIcon = () => {
  return (
    <svg
      className="course-box__star2"
      width="95"
      height="95"
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10908_8019)">
        <path
          d="M47.5 0L49.8602 20.9305C51.2876 33.6641 61.3359 43.6983 74.0695 45.1398L95 47.5L74.0695 49.8602C61.3359 51.2876 51.3017 61.3359 49.8602 74.0695L47.5 95L45.1398 74.0695C43.7124 61.3359 33.6641 51.3017 20.9305 49.8602L0 47.5L20.9305 45.1398C33.6641 43.7124 43.6983 33.6641 45.1398 20.9305L47.5 0Z"
          fill="#6239D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_10908_8019">
          <rect width="95" height="95" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CourseStarMediumIcon;
