import Row from "@avinet/adaptive-ui-core/layout/Row";
import React from "react";
import Skeleton from "react-loading-skeleton";

import "../styles.scss";

function TableRowSkeleton() {
  return (
    <tr className="table-row">
      <td className="table-row__cell">
        <Row className="table-row__name">
          <Skeleton
            width={17}
            height={17}
            borderRadius={4}
            className="table-row--checkbox-skeleton"
          />
          <Skeleton
            width={40}
            height={40}
            borderRadius={100}
            className="sidebar__user__img"
          />
          <div className="table-row__name__text">
            <Skeleton
              width={160}
              height={14}
              borderRadius={8}
              className="organisation-box__title organisation-box__title--skeleton"
            />
            <Skeleton
              width={70}
              height={14}
              borderRadius={8}
              className="organisation-box__title organisation-box__title--skeleton"
            />
          </div>
        </Row>
      </td>
      <td className="table-row__cell table--40">
        <Row className="table-row__options">
          <Skeleton
            width={90}
            height={33}
            borderRadius={24}
            className="organisation-box__title organisation-box__title--skeleton"
          />
        </Row>
      </td>
    </tr>
  );
}

export default TableRowSkeleton;
