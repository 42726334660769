import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import {
  Open20Regular,
  PanelLeftContract20Regular,
  PanelRightExpand20Regular,
} from "@fluentui/react-icons";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PlusOutlineIcon from "../../../assets/icons/plus-outline/PlusOutlineIcon";
import { SIDEBAR_ICON_TYPE } from "../../../contants/Enums";
import {
  dashboardUrl,
  helpUrl,
  locationsUrl,
  objectsUrl,
  organizationDetailsUrl,
  profileUrl,
  projectsUrl,
  selectOrganisationUrl,
} from "../../../contants/Routes";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { useAlertMessage } from "../../../hooks/useAlertMessage";
import { useCheckMobile } from "../../../hooks/useCheckMobile";
import { convertToInitials } from "../../../utils/index.utils";
import { AddNewUserModal } from "../../add-new-user/AddNewUserModal";
import { AlertMessage } from "../../alert-message";
import { OrganisationSidebarInfo } from "../../organisation/organisation-sidebar-info";
import { Text } from "../../text";
import { AddNewSidebar } from "../add-new-sidebar";
import { SidebarLink } from "../sidebar-link";
import { SidebarProps } from "./index.props";
import "./styles.scss";

interface RouteItem {
  to: string;
  text: string;
  icon: SIDEBAR_ICON_TYPE;
  noLink: boolean;
  onClick?: () => void;
}

export function Sidebar({ isCollapsed, setCollapsed }: SidebarProps) {
  const { t } = useTranslation();
  const { isMobile } = useCheckMobile();
  const [addNewCollapsed, setAddNewCollapsed] = useState(true);
  const { userProfile, logout } = useContext(AppUserContext);
  const { selectedOrganisation, isOrganisationAdmin, setOrganisationMembers } =
    useContext(OrganisationContext);
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showInviteAlertMessage, setShowInviteAlertMessage] = useState(false);
  const [invitationsSent, setInvitationsSent] = useState(0);
  const { successInviteMessage } = useAlertMessage(invitationsSent);

  const handleSetAddNew = useCallback(() => {
    setAddNewCollapsed((prev) => !prev);
  }, []);

  const handleUserProfile = useCallback(() => {
    navigate(profileUrl);
    isMobile && setCollapsed();
  }, [navigate, isMobile, setCollapsed]);

  const handleChooseOrg = useCallback(() => {
    navigate(selectOrganisationUrl);
    isMobile && setCollapsed();
    setOrganisationMembers([]);
  }, [navigate, isMobile, setCollapsed, setOrganisationMembers]);

  const handleAddUserModal = useCallback((state: boolean) => {
    setShowAddUserModal(state);
    setShowInviteAlertMessage(false);
  }, []);

  const organisationsRoutes = useMemo(
    () => [
      {
        to: dashboardUrl,
        text: t("components.sidebar.organisationContent.home"),
        icon: SIDEBAR_ICON_TYPE.HOME,
      },
      {
        to: projectsUrl,
        text: t("components.sidebar.organisationContent.projects"),
        icon: SIDEBAR_ICON_TYPE.PROJECT,
      },
      {
        to: locationsUrl,
        text: t("components.sidebar.organisationContent.locations"),
        icon: SIDEBAR_ICON_TYPE.LOCATION,
      },
      {
        to: objectsUrl,
        text: t("components.sidebar.organisationContent.objects"),
        icon: SIDEBAR_ICON_TYPE.OBJECT,
      },
    ],
    [t]
  );

  const orgSettingsRoutes = useMemo(() => {
    const routeArray = [
      {
        to: `${organizationDetailsUrl}/${String(selectedOrganisation?.name)
          .split(" ")
          .join("-")
          .toLowerCase()}`,
        text: t("components.sidebar.organizationSettingsContent.orgSettings"),
        icon: SIDEBAR_ICON_TYPE.SETTINGS_COG,
        noLink: false,
      },
    ] as RouteItem[];
    if (isOrganisationAdmin) {
      routeArray.push({
        to: "",
        text: t("components.sidebar.organizationSettingsContent.invitePeople"),
        icon: SIDEBAR_ICON_TYPE.INVITE_PEOPLE,
        noLink: true,
        onClick: () => handleAddUserModal(true),
      });
    }
    return routeArray;
  }, [selectedOrganisation?.name, t, isOrganisationAdmin, handleAddUserModal]);

  const generalRoutes = useMemo(
    () => [
      {
        to: helpUrl,
        text: t("components.sidebar.generalContent.help"),
        icon: SIDEBAR_ICON_TYPE.HELP,
      },
      {
        to: "",
        text: t("components.profileModal.logout"),
        icon: SIDEBAR_ICON_TYPE.LOGOUT,
        noLink: true,
        onClick: () => logout(),
      },
    ],
    [t, logout]
  );

  const getValueFromChild = useCallback((value: number) => {
    setInvitationsSent(value);
  }, []);

  useEffect(() => {
    if (showInviteAlertMessage) {
      setTimeout(() => {
        setShowInviteAlertMessage(false);
      }, 10000);
    }
  }, [showInviteAlertMessage]);

  return (
    <section className={`sidebar ${isCollapsed ? "sidebar--collapse" : ""}`}>
      <div className="sidebar__top">
        {(!isCollapsed || isMobile) && (
          <Icon name="logoName" className="sidebar__top__logo" />
        )}
        <div
          className="sidebar__top__collapse-button"
          onClick={() => setCollapsed()}
        >
          {isMobile ? (
            <Icon
              name={isCollapsed ? "closeIcon" : "hamburger"}
              className={
                isMobile && isCollapsed ? "sidebar__top__close-icon" : ""
              }
            />
          ) : (
            <div className="sidebar__top__collapse-button__icon">
              {isCollapsed ? (
                <PanelRightExpand20Regular />
              ) : (
                <PanelLeftContract20Regular />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="sidebar__center">
        <OrganisationSidebarInfo
          isCollapsed={isCollapsed}
          onClick={handleChooseOrg}
        />
        <Text
          className="sidebar__link-title"
          text={
            isCollapsed && !isMobile
              ? t("components.sidebar.labelOrganisationShort")
              : t("components.sidebar.labelOrganisation")
          }
          size="xs"
        />
        {organisationsRoutes.map((route, index) => (
          <SidebarLink
            key={index}
            to={route.to}
            text={route.text}
            icon={route.icon}
            isCollapsed={isCollapsed && !isMobile}
            onClick={() => isMobile && setCollapsed()}
          />
        ))}
      </div>

      <div className="sidebar__bottom-links">
        <Text
          className="sidebar__link-title"
          text={t("components.sidebar.labelOrganisationShort")}
          size="xs"
        />
        {orgSettingsRoutes.map((route, index) => (
          <SidebarLink
            key={index}
            noLink={route.noLink}
            to={route.to}
            text={route.text}
            icon={route.icon}
            isCollapsed={isCollapsed && !isMobile}
            onClick={() => {
              isMobile && setCollapsed();
              route.noLink && route.onClick?.();
            }}
          />
        ))}
      </div>

      <div className="sidebar__bottom-links">
        <Text
          className="sidebar__link-title"
          text={t("components.sidebar.generalOrganisation")}
          size="xs"
        />
        {generalRoutes.map((route, index) => (
          <SidebarLink
            key={index}
            noLink={route.noLink}
            to={route.to}
            text={route.text}
            icon={route.icon}
            isCollapsed={isCollapsed && !isMobile}
            onClick={() => {
              isMobile && setCollapsed();
              route.noLink && route.onClick();
            }}
          />
        ))}
      </div>

      <div className="sidebar__add-button">
        <button className="btn" onClick={handleSetAddNew}>
          <PlusOutlineIcon />
          {(!isCollapsed || (isCollapsed && isMobile)) && (
            <p>{t("components.sidebar.addButton")}</p>
          )}
        </button>
      </div>
      <div className="sidebar__bottom" onClick={handleUserProfile}>
        {userProfile?.image?.url ? (
          <img
            src={userProfile?.image?.url}
            alt={`${userProfile?.userData?.first_name}-${userProfile?.userData?.last_name}}`}
            className="sidebar__user__img"
          />
        ) : (
          <div className="sidebar__user__img--default">
            <p>
              {convertToInitials(
                userProfile?.userData?.first_name,
                userProfile?.userData?.last_name
              )}
            </p>
          </div>
        )}
        {(!isCollapsed || (isCollapsed && isMobile)) && (
          <>
            <div className="sidebar__user">
              <p className="sidebar__user__name">
                {userProfile?.userData?.first_name}{" "}
                {userProfile?.userData?.last_name}
              </p>
              <span className="sidebar__user__email">{userProfile?.email}</span>
            </div>
            <Open20Regular />
          </>
        )}
      </div>
      <AddNewSidebar
        sidebarIsCollapsed={isCollapsed}
        isCollapsed={addNewCollapsed}
        setCollapsed={handleSetAddNew}
      />
      {showAddUserModal ? (
        <AddNewUserModal
          modalState={handleAddUserModal}
          onSubmission={() => setShowInviteAlertMessage(true)}
          amountOfInvites={getValueFromChild}
        />
      ) : null}
      {showInviteAlertMessage && (
        <AlertMessage message={successInviteMessage} />
      )}
    </section>
  );
}
