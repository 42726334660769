import { RadioEl } from "@avinet/adaptive-ui-core/form/controls/Radio";
import { useFormApi } from "@avinet/adaptive-ui-core/form/useForm";

export function RadioGroup({
  id,
  items,
  className,
}: {
  id: string;
  items: { value: string; label: string }[];
  className?: string;
}) {
  const form = useFormApi();
  const value = form?.getValue(id);

  return (
    <div className={className}>
      {items.map((item) => (
        <RadioEl
          id={id}
          key={item.value}
          group="planned"
          label={item.label}
          value={value === item.value}
          onChange={(val) => {
            val && form?.setValue(id, item.value);
          }}
        />
      ))}
    </div>
  );
}
