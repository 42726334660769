import React, { FC } from "react";

const EnglandFlag: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7163_21803)">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#F0F0F0"
        />
        <path
          d="M2.06644 3.91113C1.28094 4.93312 0.688633 6.11105 0.34375 7.3907H5.54601L2.06644 3.91113Z"
          fill="#0052B4"
        />
        <path
          d="M19.6553 7.39066C19.3104 6.11105 18.7181 4.93313 17.9326 3.91113L14.4531 7.39066H19.6553Z"
          fill="#0052B4"
        />
        <path
          d="M0.34375 12.6084C0.688672 13.888 1.28098 15.0659 2.06645 16.0879L5.5459 12.6084H0.34375Z"
          fill="#0052B4"
        />
        <path
          d="M16.0889 2.06649C15.0669 1.28098 13.889 0.688672 12.6094 0.34375V5.54598L16.0889 2.06649Z"
          fill="#0052B4"
        />
        <path
          d="M3.91016 17.9316C4.93215 18.7171 6.11008 19.3094 7.38969 19.6543V14.4521L3.91016 17.9316Z"
          fill="#0052B4"
        />
        <path
          d="M7.38965 0.34375C6.11004 0.688672 4.93211 1.28098 3.91016 2.06644L7.38965 5.54593V0.34375Z"
          fill="#0052B4"
        />
        <path
          d="M12.6094 19.6543C13.889 19.3094 15.0669 18.7171 16.0889 17.9316L12.6094 14.4521V19.6543Z"
          fill="#0052B4"
        />
        <path
          d="M14.4531 12.6084L17.9326 16.0879C18.7181 15.066 19.3104 13.888 19.6553 12.6084H14.4531Z"
          fill="#0052B4"
        />
        <path
          d="M19.9154 8.69566H11.3044H11.3044V0.0846484C10.8774 0.0290625 10.4421 0 10 0C9.55785 0 9.12262 0.0290625 8.69566 0.0846484V8.69559V8.69563H0.0846484C0.0290625 9.12262 0 9.55793 0 10C0 10.4421 0.0290625 10.8774 0.0846484 11.3043H8.69559H8.69563V19.9154C9.12262 19.9709 9.55785 20 10 20C10.4421 20 10.8774 19.971 11.3043 19.9154V11.3044V11.3044H19.9154C19.9709 10.8774 20 10.4421 20 10C20 9.55793 19.9709 9.12262 19.9154 8.69566Z"
          fill="#D80027"
        />
        <path
          d="M12.6094 12.6094L17.0717 17.0718C17.277 16.8666 17.4727 16.6521 17.6595 16.4298L13.8391 12.6094H12.6094V12.6094Z"
          fill="#D80027"
        />
        <path
          d="M7.39207 12.6094H7.39199L2.92969 17.0717C3.13484 17.2769 3.34934 17.4727 3.57168 17.6595L7.39207 13.839V12.6094Z"
          fill="#D80027"
        />
        <path
          d="M7.39 7.39117V7.39109L2.92766 2.92871C2.72242 3.13387 2.52664 3.34836 2.33984 3.5707L6.16027 7.39113H7.39V7.39117Z"
          fill="#D80027"
        />
        <path
          d="M12.6094 7.39199L17.0718 2.92957C16.8666 2.72434 16.6521 2.52855 16.4298 2.3418L12.6094 6.16223V7.39199Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_7163_21803">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnglandFlag;
