import { createContext, useMemo, useState } from "react";
import { MultiValue } from "react-select";

import { SortOrderDirection } from "../../components/page/page-table/PageTable";
import { ISelectedOption } from "../../utils/types/types";
import { IContext } from "../ContextInterfaces";

export type IProjectFilter = {
  status: string | undefined;
  start_date: string | undefined;
  end_date: string | undefined;
  creation_start_date: string | undefined;
  creation_end_date: string | undefined;
  locations: MultiValue<ISelectedOption> | undefined;

  gridmode: boolean | undefined;
  activeTab: string | undefined;

  offset: number | undefined;
  limit: string | undefined;
  sort_order: SortOrderDirection | undefined;
  sort_column: string | undefined;
};

export type ILocationFilter = {
  status: string | undefined;
  year_built: string | undefined;
  building_category: string | undefined;
  material_type: MultiValue<ISelectedOption> | undefined;
  from_size: number | undefined;
  to_size: number | undefined;
  renovation_date: string | undefined;
  demolition_date: string | undefined;

  gridmode: boolean | undefined;
  activeTab: string | undefined;

  offset: number | undefined;
  limit: string | undefined;
  sort_order: SortOrderDirection | undefined;
  sort_column: string | undefined;
};

export type IComponentFilter = {
  good_condition: boolean | undefined;
  ok_condition: boolean | undefined;
  bad_condition: boolean | undefined;
  no_condition: boolean | undefined;
  assessed: boolean | undefined;
  score_from: number | undefined;
  score_to: number | undefined;
  evaluation: MultiValue<ISelectedOption> | undefined;
  amount_from: number | undefined;
  amount_to: number | undefined;
  amount_unit: string | undefined;
  parent_category: string | undefined;
  category: string | undefined;
  element: string | undefined;
  height_from: number | undefined;
  height_to: number | undefined;
  width_from: number | undefined;
  width_to: number | undefined;
  length_from: number | undefined;
  length_to: number | undefined;
  diameter_from: number | undefined;
  diameter_to: number | undefined;
  weight_from: number | undefined;
  weight_to: number | undefined;
  date_produced: string | undefined;
  date_registered_from: string | undefined;
  date_registered_to: string | undefined;

  recommendation: string | undefined;
  gridmode: boolean | undefined;
  activeTab: string | undefined;

  offset: number | undefined;
  limit: string | undefined;
  sort_order: SortOrderDirection | undefined;
  sort_column: string | undefined;
};

interface FilterContextConfig {
  projectFilter: Partial<IProjectFilter> | undefined;
  projectLocationFilter: Partial<IProjectFilter> | undefined;
  projectComponentFilter: Partial<IProjectFilter> | undefined;

  locationFilter: Partial<ILocationFilter> | undefined;
  locationProjectFilter: Partial<ILocationFilter> | undefined;

  componentFilter: Partial<IComponentFilter> | undefined;
  componentProjectFilter: Partial<IComponentFilter> | undefined;
  componentLocationFilter: Partial<IComponentFilter> | undefined;

  handleProjectFilter: React.Dispatch<
    React.SetStateAction<Partial<IProjectFilter>>
  >;
  handleProjectLocationFilter: React.Dispatch<
    React.SetStateAction<Partial<IProjectFilter>>
  >;
  handleProjectComponentFilter: React.Dispatch<
    React.SetStateAction<Partial<IProjectFilter>>
  >;

  handleLocationFilter: React.Dispatch<
    React.SetStateAction<Partial<ILocationFilter>>
  >;
  handleLocationProjectFilter: React.Dispatch<
    React.SetStateAction<Partial<ILocationFilter>>
  >;

  handleComponentFilter: React.Dispatch<
    React.SetStateAction<Partial<IComponentFilter>>
  >;
  handleComponentProjectFilter: React.Dispatch<
    React.SetStateAction<Partial<IComponentFilter>>
  >;
  handleComponentLocationFilter: React.Dispatch<
    React.SetStateAction<Partial<IComponentFilter>>
  >;
}

export const FilterContextConfig = createContext<FilterContextConfig>({
  projectFilter: undefined,
  projectLocationFilter: undefined,
  projectComponentFilter: undefined,

  locationFilter: undefined,
  locationProjectFilter: undefined,

  componentFilter: undefined,
  componentProjectFilter: undefined,
  componentLocationFilter: undefined,

  handleProjectFilter: () => ({}),
  handleProjectLocationFilter: () => ({}),
  handleProjectComponentFilter: () => ({}),

  handleLocationFilter: () => ({}),
  handleLocationProjectFilter: () => ({}),

  handleComponentFilter: () => ({}),
  handleComponentProjectFilter: () => ({}),
  handleComponentLocationFilter: () => ({}),
});

export const FilterContext = FilterContextConfig;

export function FilterContextProvider({ children }: IContext) {
  const [projectFilter, setProjectFilter] = useState<Partial<IProjectFilter>>(
    {}
  );
  // project filter in location-preview
  const [projectLocationFilter, setProjectLocationFilter] = useState<
    Partial<IProjectFilter>
  >({});
  // project filter in component-preview
  const [projectComponentFilter, setProjectComponentFilter] = useState<
    Partial<ILocationFilter>
  >({});

  const [locationFilter, setLocationFilter] = useState<
    Partial<ILocationFilter>
  >({});
  // location filter in project-preview
  const [locationProjectFilter, setLocationProjectFilter] = useState<
    Partial<ILocationFilter>
  >({});

  const [componentFilter, setComponentFilter] = useState<
    Partial<IComponentFilter>
  >({});
  // component filter in project-preview
  const [componentProjectFilter, setComponentProjectFilter] = useState<
    Partial<IComponentFilter>
  >({});
  // component filter in location-preview
  const [componentLocationFilter, setComponentLocationFilter] = useState<
    Partial<IComponentFilter>
  >({});

  const cfg = useMemo(() => {
    return {
      projectFilter,
      projectLocationFilter,
      projectComponentFilter,
      locationFilter,
      locationProjectFilter,
      componentFilter,
      componentProjectFilter,
      componentLocationFilter,

      handleProjectFilter: setProjectFilter,
      handleProjectLocationFilter: setProjectLocationFilter,
      handleProjectComponentFilter: setProjectComponentFilter,
      handleLocationFilter: setLocationFilter,
      handleLocationProjectFilter: setLocationProjectFilter,
      handleComponentFilter: setComponentFilter,
      handleComponentProjectFilter: setComponentProjectFilter,
      handleComponentLocationFilter: setComponentLocationFilter,
    };
  }, [
    componentFilter,
    componentLocationFilter,
    componentProjectFilter,
    locationFilter,
    locationProjectFilter,
    projectComponentFilter,
    projectFilter,
    projectLocationFilter,
  ]);

  return (
    <FilterContextConfig.Provider value={cfg}>
      {children}
    </FilterContextConfig.Provider>
  );
}
