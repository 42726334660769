import React, {
  ClipboardEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";

import { TextButton } from "../text-button";
import { VerifyCodeInputProps } from "./index.props";
import "./styles.scss";

export const VerifyCodeInput: FC<VerifyCodeInputProps> = ({
  verifyCodeValue,
  setVerifyCodeValue,
  codeLength,
  resentCode,
  onClickCancel,
  onClickVerify,
  error,
}) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const inputLength = useMemo(
    () => (codeLength ? codeLength : 4),
    [codeLength]
  );

  const handleResetCode = useCallback(() => {
    resentCode();
    setCounter(30);
  }, [resentCode]);

  const handleClearForm = useCallback(() => {
    onClickCancel && onClickCancel();
    setVerifyCodeValue("");
  }, [onClickCancel, setVerifyCodeValue]);

  useEffect(() => {
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [counter]);

  const handlePaste: ClipboardEventHandler = (event) => {
    const data = event.clipboardData.getData("text");
    setVerifyCodeValue(data);
  };

  return (
    <div className="verify-code">
      <OtpInput
        value={verifyCodeValue}
        onChange={setVerifyCodeValue}
        onPaste={handlePaste}
        numInputs={inputLength}
        inputType="number"
        shouldAutoFocus={verifyCodeValue.length !== inputLength}
        renderInput={(props) => <input {...props} />}
        containerStyle={`verify-code__otp ${
          verifyCodeValue.length === inputLength
            ? "verify-code__otp--filled"
            : ""
        } ${codeLength ? "verify-code__otp--big" : ""} ${
          error ? "verify-code__otp--error" : ""
        }`}
        inputStyle="verify-code__input"
      />
      <div className="verify-code__resend">
        <p>{t("pages.enterCode.getCodeText")}</p>
        <TextButton
          disabled={counter > 0}
          onClick={handleResetCode}
          text={`${t("pages.enterCode.getCodeLink")}${
            counter > 0 ? ` (${counter}).` : "."
          } `}
        />
      </div>
      <div className="verify-code__buttons">
        {error && (
          <div className="verify-code__wrong-code">
            <p>{t("errors.wrongCode")}</p>
          </div>
        )}
        <button
          className="btn light btn-cancel verify-code__buttons--cancel"
          onClick={handleClearForm}
        >
          {t("common.clear")}
        </button>
        <button className="btn" onClick={onClickVerify}>
          {t("common.verify")}
        </button>
      </div>
    </div>
  );
};
