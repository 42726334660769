import ModalContainer from "@avinet/adaptive-ui-core/ui/ModalContainer";
import { useEffect } from "react";

import { ModalProps } from "./index.props";
import "./styles.scss";

export function Modal({
  show,
  children,
  className,
  sidebarIsCollapsed,
}: ModalProps) {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);

  return (
    <ModalContainer
      className={`modal ${className || ""} ${
        sidebarIsCollapsed ? `${className}--sidebar-collapse` : ""
      } ${show ? "modal--visible" : ""}`}
    >
      {children}
    </ModalContainer>
  );
}
