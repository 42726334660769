import React from "react";

const LocationIcon = () => {
  return (
    <svg
      className="location-icon"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84303 2.56837C6.24344 -0.832036 11.7566 -0.832036 15.157 2.56837C18.5574 5.96878 18.5574 11.4819 15.157 14.8823L13.97 16.0562C13.0952 16.9149 11.96 18.0188 10.5642 19.3684C9.69194 20.2118 8.30811 20.2117 7.436 19.3682L3.9449 15.9723C3.50614 15.5415 3.13887 15.1782 2.84303 14.8823C-0.557378 11.4819 -0.557378 5.96878 2.84303 2.56837ZM14.0963 3.62903C11.2817 0.81441 6.71832 0.81441 3.90369 3.62903C1.08907 6.44366 1.08907 11.0071 3.90369 13.8217L5.39077 15.2891C6.20967 16.0906 7.23913 17.091 8.47881 18.29C8.76952 18.5711 9.23079 18.5712 9.52154 18.2901L12.9164 14.9886C13.3854 14.5283 13.7787 14.1393 14.0963 13.8217C16.911 11.0071 16.911 6.44366 14.0963 3.62903ZM9.00002 5.99903C10.6577 5.99903 12.0016 7.34287 12.0016 9.00058C12.0016 10.6583 10.6577 12.0021 9.00002 12.0021C7.34231 12.0021 5.99847 10.6583 5.99847 9.00058C5.99847 7.34287 7.34231 5.99903 9.00002 5.99903ZM9.00002 7.49903C8.17074 7.49903 7.49847 8.1713 7.49847 9.00058C7.49847 9.82986 8.17074 10.5021 9.00002 10.5021C9.8293 10.5021 10.5016 9.82986 10.5016 9.00058C10.5016 8.1713 9.8293 7.49903 9.00002 7.49903Z"
        fill="#7D89B0"
      />
    </svg>
  );
};

export default LocationIcon;
