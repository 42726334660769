import { IAsideData } from "../../../utils/types/types";

export const locationAside: IAsideData[] = [
  {
    id: 1,
    text: "components.locationModal.locationThumbnail",
    trigger: "attachments",
  },
  {
    id: 2,
    text: "components.locationModal.general",
    trigger: "general",
  },
  {
    id: 3,
    text: "components.locationModal.building",
    trigger: "building",
  },
  {
    id: 4,
    text: "components.locationModal.mapping",
    trigger: "mapping",
  },
  {
    id: 5,
    text: "components.locationModal.lifecycle",
    trigger: "lifecycle",
  },
  {
    id: 6,
    text: "components.locationModal.additionalInfo",
    trigger: "additionalInfo",
  },
];
