import React, { FC } from "react";

const LogoIcon: FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use transform="scale(0.00416667)" />
        </pattern>
        <image id="image0_6032_40347" width="240" height="240" />
      </defs>
    </svg>
  );
};

export default LogoIcon;
