import React, { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { contactEmailAddress } from "../../../contants/Mailing";
import { termsAndConditionsUrl } from "../../../contants/Routes";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { Heading } from "../../heading";
import { ProfileCard } from "../profile-card";
import { ProfileModal } from "../profile-modal";
import "./styles.scss";

export const ProfilePolicy: FC = () => {
  const { t } = useTranslation();
  const { userProfile, deleteUserAccount } = useContext(AppUserContext);
  // const [termsCheckbox, setTermsCheckbox] = useState(true);
  // const [newsletterCheckbox, setNewsletterCheckbox] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const { selectedOrganisation, updateUserRole } =
    useContext(OrganisationContext);

  const handleDeleteUserAccount = useCallback(async () => {
    if (!userProfile?.uuid || !selectedOrganisation?.id) return;
    const object = {
      uuid: userProfile?.uuid,
      org_id: selectedOrganisation?.id,
      new_role: "Remove",
    };
    try {
      await updateUserRole(object);
      await deleteUserAccount();
    } catch (error) {
      console.error(error);
    }
  }, [
    userProfile?.uuid,
    selectedOrganisation?.id,
    updateUserRole,
    deleteUserAccount,
  ]);

  return (
    <>
      <Heading
        title={t("pages.profile.headings.policy.title")}
        description={t("pages.profile.headings.policy.description")}
        small
      />
      <ProfileCard className="profile-policy">
        <p className="profile-policy__title">{t("PrivacyConsent.title")}</p>
        <p className="profile-policy__text">
          {t("components.profilePolicy.read")}{" "}
          <a
            href={termsAndConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("PrivacyConsent.termsAndConditions")}
          </a>
        </p>
      </ProfileCard>
      {/* <ProfileCard>
        <p className="profile-policy__title">
          {t("components.profilePolicy.termsTitle")}
        </p>
        <p className="profile-policy__text">
          {t("components.profilePolicy.termsDescription")}
        </p>

        <div className="profile-policy__checkboxes">
          <Checkbox
            id="test"
            title={t("components.profilePolicy.termsCheckOneTitle")}
            description={t("components.profilePolicy.termsCheckOneDescription")}
            value={termsCheckbox}
            onChange={() => setTermsCheckbox(!termsCheckbox)}
          />
          <Checkbox
            id="test"
            title={t("components.profilePolicy.termsCheckTwoTitle")}
            description={t("components.profilePolicy.termsCheckTwoDescription")}
            moreDescription={userProfile?.email}
            value={newsletterCheckbox}
            onChange={() => setNewsletterCheckbox(!newsletterCheckbox)}
          />
        </div>
      </ProfileCard> */}
      <ProfileCard lastCard>
        <p className="profile-policy__title">
          {t("components.profilePolicy.deleteTitle")}
        </p>
        <p className="profile-policy__text">
          {t("components.profilePolicy.deleteDescription")}
        </p>
        <div className="profile-policy__delete-wrapper">
          <button
            className="btn btn-delete"
            onClick={() => setDeleteModal(true)}
          >
            {t("common.delete")}
          </button>
        </div>
      </ProfileCard>
      <ProfileModal
        deleteMode
        iconName="delete"
        title={t("components.deleteUserModal.title")}
        description=""
        showModal={showDeleteModal}
        cancelButtonClick={() => setDeleteModal(false)}
        submitButtonClick={handleDeleteUserAccount}
        submitButtonText={t("common.deleteUser")}
        className="profile-policy__delete-modal"
      >
        <p className="profile-policy__delete-modal__text">
          {t("components.deleteUserModal.descriptionOne")}
        </p>
        <p className="profile-policy__delete-modal__text">
          {t("components.deleteUserModal.descriptionTwo")}
        </p>
        <a
          className="profile-policy__delete-modal__text"
          href={`mailto:${contactEmailAddress}`}
        >
          {contactEmailAddress}
        </a>
        <p className="profile-policy__delete-modal__text profile-policy__delete-modal__text--bold">
          {t("components.deleteUserModal.descriptionThree")}
        </p>
      </ProfileModal>
    </>
  );
};
