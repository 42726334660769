import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { AddObjectForm, IFullscreenModalData } from "../../../api/types";
import { ObjectItemList } from "./ObjectItemList";
import "./ObjectList.scss";

export function ObjectList({
  objects,
  onEdit,
  setShowFullscreenModal,
}: {
  objects: AddObjectForm[];
  onEdit: (id: string | number) => void;
  setShowFullscreenModal?: Dispatch<SetStateAction<IFullscreenModalData>>;
}) {
  const { id: locationId } = useParams();

  const [openItem, setOpenItem] = useState<number | string>();

  const handleItemToggle = useCallback(
    (id: number | string | undefined, open: boolean) => {
      setOpenItem((v) => (v === id ? (open ? id : undefined) : open ? id : v));
    },
    []
  );

  return (
    <div className="object-list">
      {objects.map((object) => (
        <ObjectItemList
          key={object.id}
          object={object}
          locationId={Number(locationId)}
          onEdit={onEdit}
          setShowFullscreenModal={setShowFullscreenModal}
          isOpen={openItem === object.id}
          handleItemToggle={handleItemToggle}
        />
      ))}
    </div>
  );
}
