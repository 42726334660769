import { FC, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { MinusCircleIcon } from "../../../assets/icons/minus-circle/MinusCircleIcon";
import { PlusCircleIcon } from "../../../assets/icons/plus-circle/PlusCircleIcon";
import { HelpQuestionProps } from "./index.props";

export const HelpQuestion: FC<HelpQuestionProps> = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleSummaryClick = (event: Event) => {
      const summaryElement = (event.target as HTMLElement).closest("summary");

      if (
        summaryElement &&
        contentWrapperRef.current?.contains(summaryElement)
      ) {
        setIsOpen((prevIsOpen) => !prevIsOpen);
      }
    };

    const contentWrapper = contentWrapperRef.current;

    if (contentWrapper) {
      contentWrapper.addEventListener("click", handleSummaryClick);
    }

    return () => {
      if (contentWrapper) {
        contentWrapper.removeEventListener("click", handleSummaryClick);
      }
    };
  }, []);

  return (
    <div
      ref={contentWrapperRef}
      className={`help-question-wrapper${isOpen ? " open" : ""}`}
    >
      <div
        className="help-question-text-wrapper"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <button>{isOpen ? <MinusCircleIcon /> : <PlusCircleIcon />}</button>
    </div>
  );
};

export const HelpQuestionSkeleton = () => {
  return (
    <div className="help-question-wrapper">
      <div className="help-question-text-wrapper">
        <Skeleton
          width={200}
          height={28}
          className="help-question-skeleton-title"
        />
      </div>
      <Skeleton
        width={24}
        height={24}
        borderRadius={50}
        className="help-question-skeleton-button"
      />
    </div>
  );
};
