import {
  ILocationProperties,
  IMediaFeature,
  IObjectProperties,
  IProjectProperties,
} from "../api/types";

export type ILocationReportConfig = {
  id: boolean;
  //   date_created: boolean;
  //   date_modified: boolean;
  //   created_by: boolean;
  //   modified_by: boolean;
  geom: boolean;
  uuid: boolean;
  name: boolean;
  address: boolean;
  gnrbnr: boolean;
  fylke: boolean;
  kommune: boolean;
  owner: boolean;
  building_category: boolean;
  area: boolean;
  main_material_use: boolean;
  building_year: boolean;
  rehab_year: boolean;
  demolition_date: boolean;
  comments: boolean;
  //   fk_organization: boolean;
  status: boolean;
  image: boolean;
  type_of_demolition: boolean;
  // media: boolean;
  // floors: boolean;
  type_of_location: boolean;
  kartlagt_fra: boolean;
  kartlagt_til: boolean;
  kartlagt_av: boolean;
};

export type IComponentReportConfig = {
  id: boolean;
  image: boolean;
  quantity: boolean;
  quantity_unit: boolean;
  parent_category: boolean;
  category: boolean;
  element: boolean;
  height: boolean;
  width: boolean;
  length: boolean;
  diameter: boolean;
  weight: boolean;
  producer: boolean;
  production_year: boolean;
  additional_comments: boolean;
  specification: boolean;
  cost: boolean;
  co2: boolean;
  demountability_score: boolean;
  remaining_lifetime_score: boolean;
  volume_score: boolean;
  demand_score: boolean;
  environmental_effect_score: boolean;
  cost_use_score: boolean;
  //   date_created: boolean;
  //   date_modified: boolean;
  //   created_by: boolean;
  //   modified_by: boolean;
  //   geom: boolean;
  //   uuid: boolean;
  //   fk_organization: boolean;
  //   fk_location: boolean;
  //   obj_condition: boolean;
};

export type IConditionRowData = {
  obj_condition: string;
  quantity: number;
  demountability_score: number;
  remaining_lifetime_score: number;
  volume_score: number;
  demand_score: number;
  environmental_effect_score: number;
  cost_use_score: number;
  recommendation: string;
};

export type IReportConfig = {
  locations: ILocationReportConfig;
  components: IComponentReportConfig;
};

export type IReportLocationData = {
  locationData: ILocationProperties;
  componentsDictionary: { [key: string]: IObjectProperties[] };
  media: IMediaFeature[];
};

export type IReportData = {
  reportType: string;
  reportName: string;
  projectDetails: IProjectProperties | undefined;
  locations: IReportLocationData[];
  config: IReportConfig;
  showAllImages: boolean;
};

export type IConditionQuantityDictionary = {
  [id: string]: IConditionQuantityMapping;
};

export type IConditionQuantityMapping = {
  [condition: string]: IConditionRowData;
};

export const REPORT_TYPE = {
  BREEAM: "BREEAM",
  TEK: "TEK",
  CUSTOM: "CUSTOM",
};

export type IContextFunctions = {
  getCategoryName: (id: number) => string | undefined;
  getParentCategoryName: (id: number) => string | undefined;
  getElementName: (id: number) => string | undefined;
};
