import React, { FC } from "react";

const HelpOutlineFilledIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.523 0 20 4.478 20 10C20 15.522 15.523 20 10 20C4.477 20 0 15.522 0 10C0 4.478 4.477 0 10 0ZM10 13.5C9.44772 13.5 9 13.9477 9 14.5C9 15.0523 9.44772 15.5 10 15.5C10.5523 15.5 11 15.0523 11 14.5C11 13.9477 10.5523 13.5 10 13.5ZM10 4.75C8.48122 4.75 7.25 5.98122 7.25 7.5C7.25 7.91421 7.58579 8.25 8 8.25C8.3797 8.25 8.69349 7.96785 8.74315 7.60177L8.75 7.5C8.75 6.80964 9.30964 6.25 10 6.25C10.6904 6.25 11.25 6.80964 11.25 7.5C11.25 8.03882 11.115 8.30526 10.6051 8.8322L10.4697 8.96967C9.59157 9.84777 9.25 10.4171 9.25 11.5C9.25 11.9142 9.58579 12.25 10 12.25C10.4142 12.25 10.75 11.9142 10.75 11.5C10.75 10.9612 10.885 10.6947 11.3949 10.1678L11.5303 10.0303C12.4084 9.15223 12.75 8.58295 12.75 7.5C12.75 5.98122 11.5188 4.75 10 4.75Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default HelpOutlineFilledIcon;
