import { FC } from "react";

import { TagProps } from "./index.props";
import "./styles.scss";

export const Tag: FC<TagProps> = ({ text, date, onClick }) => {
  return (
    <div className="tag" onClick={onClick}>
      <p className="tag__text">{date ? text.slice(6) : text}</p>
    </div>
  );
};
