import React, { FC } from "react";

import { HelpModalSlideProps } from "./index.props";
import "./styles.scss";

export const HelpModalSlide: FC<HelpModalSlideProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <div className="help-modal-slide">
      <img className="help-modal-slide__image" src={image} alt="help-slide" />
      <p className="help-modal-slide__title">{title}</p>
      <span className="help-modal-slide__description">{description}</span>
    </div>
  );
};
