import { format } from "date-fns";
import { t } from "i18next";
import { useCallback, useMemo } from "react";

import { ILocationProperties, IStatusTabs } from "../api/types";
import {
  IPageTableColumnData,
  IPageTableColumnMeta,
  IPageTableContent,
  PageTableColumnTypes,
} from "../components/page/page-table/table-header/index.props";
import { LOCATION_STATUS } from "../contants/Enums";
import { useColumnState } from "./useColumnState";

export const useLocationTableColumns = (
  locationsList: ILocationProperties[]
) => {
  const initialColumnMeta: IPageTableColumnMeta[] = useMemo(() => {
    return [
      {
        key: "id",
        title: t("tables.id"),
        active: false,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "picture",
        title: t("tables.picture"),
        active: true,
        sortable: false,
        type: PageTableColumnTypes.IMAGE,
      },
      {
        key: "name",
        title: t("tables.name"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "status",
        title: t("tables.status"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.STATUS,
      },
      {
        key: "building_category",
        title: t("tables.buildingCategory"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "address",
        title: t("tables.address"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "kommune",
        title: t("tables.municipality"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "area",
        title: t("tables.area"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "kartlagt_fra",
        title: t("tables.kartlagtFra"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "kartlagt_til",
        title: t("tables.kartlagtTil"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "kartlagt_av",
        title: t("tables.kartlagtAv"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "building_year",
        title: t("tables.buildingYear"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "main_material_use",
        title: t("tables.mainMaterial"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "demolition_date",
        title: t("tables.demolitionDate"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "floor_count",
        title: t("tables.floorCount"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "room_count",
        title: t("tables.roomCount"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
      {
        key: "product_quantity",
        title: t("tables.productCount"),
        active: true,
        sortable: true,
        type: PageTableColumnTypes.TEXT,
      },
    ];
  }, []);

  const { columnState, updateColumnVisibility, resetAllColumns } =
    useColumnState(initialColumnMeta);

  const columns = useMemo(() => {
    const columnData = locationsList?.map((location) => {
      return [
        {
          key: "id",
          value: location.id,
        },
        {
          key: "picture",
          value: {
            image: location.image,
            name: location.name + " " + location.image,
          },
        },
        {
          key: "name",
          value: location.name || "-",
        },
        {
          key: "status",
          value: location.status || "-",
        },
        {
          key: "date_created",
          value: location.date_created
            ? format(new Date(location.date_created as string), "dd-MM-yyyy")
            : "",
        },
        {
          key: "building_category",
          value: location.building_category?.toString(), //null value handled in the table component,
        },
        {
          key: "address",
          value: location.address + " " + location.gnrbnr || "-",
        },
        {
          key: "kommune",
          value: location.kommune || "-",
        },

        {
          key: "area",
          value: location.area || "-",
        },
        {
          key: "kartlagt_fra",
          value: location.kartlagt_fra || "-",
        },
        {
          key: "kartlagt_til",
          value: location.kartlagt_til || "-",
        },
        {
          key: "kartlagt_fra",
          value: location.kartlagt_av || "-",
        },
        {
          key: "building_year",
          value: location.building_year || "-",
        },
        {
          key: "main_material_use",
          value: location.main_material_use || "-",
        },
        {
          key: "demolition_date",
          value: location.demolition_date
            ? format(new Date(location.demolition_date as string), "dd-MM-yyyy")
            : "" || "-",
        },
        {
          key: "floor_count",
          value: location.floor_count ?? 0,
        },
        {
          key: "room_count",
          value: location.room_count ?? 0,
        },
        {
          key: "product_quantity",
          value: location.product_quantity ?? 0,
        },
      ] as IPageTableColumnData[];
    });
    return {
      columns: columnState,
      data: columnData,
      updateColumnVisibility,
      resetAllColumns,
    } as IPageTableContent;
  }, [columnState, locationsList, resetAllColumns, updateColumnVisibility]);

  const tabs: IStatusTabs[] = useMemo(
    () => [
      {
        text: t("components.pageTable.tabs.locations.all"),
        status: LOCATION_STATUS.ALL,
      },
      {
        text: t("components.pageTable.tabs.locations.isBeingRehabilitated"),
        status: LOCATION_STATUS.RENOVATION,
      },
      {
        text: t("components.pageTable.tabs.locations.newBuildings"),
        status: LOCATION_STATUS.NEW,
      },
      {
        text: t("components.pageTable.tabs.locations.beingDemolished"),
        status: LOCATION_STATUS.DEMOLISHED,
      },
      {
        text: t("components.pageTable.tabs.locations.static"),
        status: LOCATION_STATUS.STATIC,
      },
      {
        text: t("components.pageTable.tabs.locations.completed"),
        status: LOCATION_STATUS.COMPLETED,
      },
    ],
    []
  );

  const renderLocationTableTitle = useCallback((activeFilter: string) => {
    switch (activeFilter) {
      case t("components.pageTable.tabs.locations.all"):
        return t("pages.locations.titleFilters", {
          filter: t("components.pageTable.tabs.locations.all"),
        });
      case t("components.pageTable.tabs.locations.newBuildings"):
        return t("pages.locations.titleFilters", {
          filter: t("components.pageTable.tabs.locations.newBuildings"),
        });
      case t("components.pageTable.tabs.locations.isBeingRehabilitated"):
        return t("pages.locations.titleFilters", {
          filter: t("components.pageTable.tabs.locations.isBeingRehabilitated"),
        });
      case t("components.pageTable.tabs.locations.beingDemolished"):
        return t("pages.locations.titleFilters", {
          filter: t("components.pageTable.tabs.locations.beingDemolished"),
        });
      case t("components.pageTable.tabs.locations.static"):
        return t("pages.locations.titleFilters", {
          filter: t("components.pageTable.tabs.locations.static"),
        });
      case t("components.pageTable.tabs.locations.completed"):
        return t("pages.locations.titleFilters", {
          filter: t("components.pageTable.tabs.locations.completed"),
        });
      default:
        return "";
    }
  }, []);

  return {
    columns,
    tabs,
    renderLocationTableTitle,
  };
};
