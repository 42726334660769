import React, { FC } from "react";

const ShareIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0H5.5C5.77614 0 6 0.223858 6 0.5C6 0.776142 5.77614 1 5.5 1H3ZM8 0.5C8 0.223858 8.22386 0 8.5 0H13.5C13.7761 0 14 0.223858 14 0.5V5.5C14 5.77614 13.7761 6 13.5 6C13.2239 6 13 5.77614 13 5.5V1.70711L8.85355 5.85355C8.65829 6.04882 8.34171 6.04882 8.14645 5.85355C7.95118 5.65829 7.95118 5.34171 8.14645 5.14645L12.2929 1H8.5C8.22386 1 8 0.776142 8 0.5Z"
        fill="#212121"
      />
    </svg>
  );
};

export default ShareIcon;
