import React from "react";

const CourseStarSmallIcon = () => {
  return (
    <svg
      className="course-box__star3"
      width="72"
      height="30"
      viewBox="0 0 72 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10908_8016)">
        <path
          d="M36 -42L37.7887 -26.1369C38.8706 -16.4862 46.4862 -8.88129 56.1369 -7.78875L72 -6L56.1369 -4.21125C46.4862 -3.12943 38.8813 4.48616 37.7887 14.1369L36 30L34.2112 14.1369C33.1294 4.48616 25.5138 -3.11872 15.8631 -4.21125L0 -6L15.8631 -7.78875C25.5138 -8.87057 33.1187 -16.4862 34.2112 -26.1369L36 -42Z"
          fill="#6239D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_10908_8016">
          <rect
            width="72"
            height="72"
            fill="white"
            transform="translate(0 -42)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CourseStarSmallIcon;
