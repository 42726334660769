import Row from "@avinet/adaptive-ui-core/layout/Row";

import { PageTitleProps } from "./index.props";
import "./styles.scss";

export function PageTitle({ title, description, children }: PageTitleProps) {
  return (
    <Row wrap className="pagetitle-wrapper">
      <div className="pagetitle-text">
        <span className="pagetitle-title">{title}</span>
        {!!description && (
          <span className="pagetitle-subtitle">{description}</span>
        )}
      </div>
      <Row wrap className="pagetitle-buttons">
        {children}
      </Row>
    </Row>
  );
}
