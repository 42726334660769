import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import CheckBox from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { useCallback } from "react";

import { useTranslationPath } from "../../hooks/useTranslationPath";
import { Modal } from "../modal";
import "./PrivacyConsent.scss";

export function PrivacyConsent({
  message,
  version,
  onAccept,
}: {
  message?: TrustedHTML | string;
  version: number;
  onAccept: (version: number) => void;
}) {
  const t = useTranslationPath("PrivacyConsent");

  const onSubmit = useCallback(() => {
    onAccept(version);
  }, [onAccept, version]);

  const { form, state } = useForm({
    onSubmit,
  });

  return (
    <Modal show={true} className="accept-privacy-modal">
      <div className="accept-privacy-container">
        <h4>{t("title")}</h4>
        {message && (
          <div
            className="message-container"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <FormProvider form={form} state={state}>
          <form
            onSubmit={form.submit}
            className="form"
            id="privacy-consent-form"
          >
            <div className="form__privacy">
              <CheckBox
                id="consents"
                className="terms-checkbox"
                label={
                  <>
                    {t("iAccept")}
                    <a href="/vilkar" target="_blank" rel="noopener noreferrer">
                      {t("termsAndConditions")}
                    </a>
                    *
                  </>
                }
                required
              />
            </div>
            <button type="submit" form="privacy-consent-form" className="btn">
              {t("accept")}
            </button>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
}
