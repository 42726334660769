import {
  CheckBoxEl,
  CheckBoxElProps,
} from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { FC } from "react";

import { CheckboxProps } from "./index.props";
import "./styles.scss";

export const Checkbox: FC<CheckboxProps & CheckBoxElProps> = ({
  title,
  description,
  moreDescription,
  onChange,
  id,
  value,
  disabled,
  className,
}) => {
  return (
    <Row className={`checkbox ${className ? className : ""}`}>
      <CheckBoxEl
        id={id}
        label={null}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {title && (
        <Column align="left" className="checkbox__text">
          <p className="checkbox__title">{title}</p>
          {description && (
            <p className="checkbox__description">
              {description} <strong>{moreDescription}</strong>
            </p>
          )}
        </Column>
      )}
    </Row>
  );
};
