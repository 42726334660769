import { createContext, useContext } from "react";

import { Environment } from "../../environment/environment";
import { IContext } from "../ContextInterfaces";

interface EnvironmentContextConfig {
  environment?: Environment;
}

export const EnvironmentContext = createContext<EnvironmentContextConfig>({});

export function useEnvironment() {
  return useContext(EnvironmentContext).environment;
}

export function EnvironmentContextProvider({
  children,
  environment,
}: IContext) {
  return (
    <EnvironmentContext.Provider value={{ environment }}>
      {children}
    </EnvironmentContext.Provider>
  );
}
