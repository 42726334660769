import React, { FC } from "react";

import { TextButtonProps } from "./index.props";
import "./styles.scss";

export const TextButton: FC<TextButtonProps> = ({
  onClick,
  text,
  className,
  disabled,
  dark,
}) => {
  return (
    <p
      className={`text-button ${className || ""} ${
        disabled ? "text-button--disabled" : ""
      } ${dark ? "text-button--dark" : ""}`}
      onClick={onClick}
    >
      {text}
    </p>
  );
};
