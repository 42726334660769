import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { IOrgMemberData } from "../../../api/types";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { useAlertMessage } from "../../../hooks/useAlertMessage";
import { AddNewUserModal } from "../../add-new-user/AddNewUserModal";
import { AlertMessage } from "../../alert-message";
import { Heading } from "../../heading";
import { Icon } from "../../icon/Icon";
import { ProfileCard } from "../../profile/profile-card";
import { ProfilePlan } from "../../profile/profile-plan";
import Table from "../../table";
import "./styles.scss";

interface IMemberImageMapping {
  [uuid: string]: string;
}

export const OrganisationMembers = () => {
  const { t } = useTranslation();
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [membersLoading, setMembersLoading] = useState<boolean>(false);
  const [invitationsSent, setInvitationsSent] = useState(0);
  const [showInviteAlertMessage, setShowInviteAlertMessage] =
    useState<boolean>(false);
  const [showDeleteAlertMessage, setShowDeleteAlertMessage] =
    useState<boolean>(false);
  const [showUpdatedPlanAlertMessage, setShowUpdatedPlanAlertMessage] =
    useState<boolean>(false);
  const [members, setMembers] = useState<IOrgMemberData[]>([]);
  const [memberImages, setMemberImages] = useState<IMemberImageMapping>({});

  const organisations = useContext(OrganisationContext);
  const {
    getOrganisationMembers,
    selectedOrganisation,
    getMemberProfileImage,
  } = useContext(OrganisationContext);

  const selectedOrganisationId = useMemo(() => {
    return selectedOrganisation?.id;
  }, [selectedOrganisation]);

  // TODO: Add back when payment plan is a thing again
  // const handleSubmitOrgModal = useCallback(() => {
  //   setShowUpdatedPlanAlertMessage(true);
  // }, []);

  const { successInviteMessage, successDeleteMessage } =
    useAlertMessage(invitationsSent);

  const handleAddUserModal = useCallback((state: boolean) => {
    setShowAddUserModal(state);
    setShowInviteAlertMessage(false);
    setShowDeleteAlertMessage(false);
    setShowUpdatedPlanAlertMessage(false);
  }, []);

  const handleUpdateOrgModal = useCallback((state: boolean) => {
    setShowInviteAlertMessage(false);
    setShowDeleteAlertMessage(false);
  }, []);

  const getValueFromChild = useCallback((value: number) => {
    setInvitationsSent(value);
  }, []);

  const handleDeleteAlertMessage = useCallback(() => {
    setShowInviteAlertMessage(false);
    setShowDeleteAlertMessage(true);
  }, []);

  useEffect(() => {
    if (showInviteAlertMessage || showDeleteAlertMessage) {
      setTimeout(() => {
        setShowInviteAlertMessage(false);
        setShowDeleteAlertMessage(false);
      }, 10000);
    }
  }, [
    showInviteAlertMessage,
    showDeleteAlertMessage,
    showUpdatedPlanAlertMessage,
  ]);

  useEffect(() => {
    if (showUpdatedPlanAlertMessage) {
      setTimeout(() => {
        setShowUpdatedPlanAlertMessage(false);
      }, 10000);
    }
  }, [showUpdatedPlanAlertMessage]);

  const fetchMembers = useCallback(async () => {
    if (!selectedOrganisationId) return;
    try {
      setMembersLoading(true);
      const fetchedMembers = await getOrganisationMembers(
        selectedOrganisationId
      );
      if (Array.isArray(fetchedMembers)) setMembers(fetchedMembers);
      setMembersLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
      setMembersLoading(false);
    }
  }, [selectedOrganisationId]);

  useLayoutEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  useEffect(() => {
    async function fetchMemberImages() {
      if (!members) return;

      const newMemberImages = {} as IMemberImageMapping;
      for (const member of members) {
        const blob = await getMemberProfileImage(member.uuid);
        if (!blob) continue;
        const url = URL.createObjectURL(blob);
        newMemberImages[member.uuid] = url;
      }

      setMemberImages(newMemberImages);
    }
    fetchMemberImages();
  }, [members, setMemberImages]);

  useEffect(() => {
    members.map((member) => {
      const image = memberImages[member.uuid];
      if (image) {
        member.image = image;
      }
    });
  }, [members, memberImages]);

  // TODO: Add back when payment plan is a thing again
  // const updateMemberLimit = useCallback(
  //   (newLimit: number) => {
  //     if (organisations.selectedOrganisation) {
  //       organisations.selectedOrganisation.member_limit = newLimit;
  //     }
  //   },
  //   [organisations]
  // );

  // const updateAnnualPayment = useCallback(
  //   (annualPayment: boolean) => {
  //     if (organisations.selectedOrganisation) {
  //       organisations.selectedOrganisation.yearly_payment = annualPayment;
  //     }
  //   },
  //   [organisations]
  // );

  return (
    <>
      <Heading
        title={t("pages.profile.headings.organisation.membertitle")}
        description={t("pages.profile.headings.organisation.memberdescription")}
        small
      />
      {/* TODO: Hidden org select after orf flow update */}
      {/*<TableSelect*/}
      {/*  items={orgOptions}*/}
      {/*  value={selectedOrgOption}*/}
      {/*  onChange={handleChangeOrg}*/}
      {/*  className="organisation-select"*/}
      {/*/>*/}
      {organisations.isOrganisationAdmin && (
        <ProfileCard>
          <ProfilePlan
            cancelButton={() => console.log("cancel")}
            changePlanButton={() => handleUpdateOrgModal(true)}
            members={members.length}
          />
        </ProfileCard>
      )}
      <ProfileCard className="organisation-table" lastCard>
        <div className="organisation-table__heading">
          <Heading
            title={t("components.profileOrganisation.title")}
            description={
              organisations.isOrganisationAdmin
                ? t("components.profileOrganisation.description")
                : undefined
            }
            small
            tooltip={t("components.profileOrganisation.members", {
              users: members.length,
              usersAvailable: organisations?.selectedOrganisation?.member_limit,
            })}
          >
            {organisations.isOrganisationAdmin &&
              members.length <
                (organisations?.selectedOrganisation?.member_limit ?? 0) && (
                <button
                  className="btn organisation-table__add-user"
                  onClick={() => handleAddUserModal(true)}
                >
                  <Icon name="add" />
                  <p>{t("components.profileOrganisation.addUser")}</p>
                </button>
              )}
          </Heading>
        </div>
        <div className="organisation-table__table">
          <Table
            data={members}
            dataLoading={membersLoading}
            rowsPerPage={5}
            amountOfAdmins={
              members.filter((member) => member.role === "Admin").length
            }
            onSubmitDeleteModal={handleDeleteAlertMessage}
            updateMembers={fetchMembers}
            isAdmin={organisations.isOrganisationAdmin}
            amountOfMembers={members.length}
          />
        </div>
      </ProfileCard>
      {showAddUserModal && handleAddUserModal !== undefined ? (
        <AddNewUserModal
          modalState={handleAddUserModal}
          onSubmission={() => setShowInviteAlertMessage(true)}
          amountOfInvites={getValueFromChild}
        />
      ) : null}
      {/* TODO: Add back when payment plan is a thing again
      {showUpdateOrgModal && handleUpdateOrgModal !== undefined && (
        <UpdateOrgModal
          modalState={handleUpdateOrgModal}
          onSubmission={(newLimit: number, annualPayment: boolean) => {
            handleSubmitOrgModal();
            updateMemberLimit(newLimit);
            updateAnnualPayment(annualPayment);
          }}
          orgMembers={members.length}
        />
      )} */}
      {showInviteAlertMessage && (
        <AlertMessage message={successInviteMessage} />
      )}
      {showDeleteAlertMessage && (
        <AlertMessage message={successDeleteMessage} />
      )}
      {/* {showUpdatedPlanAlertMessage && (
        <AlertMessage message={successChangePlanMessage} />
      )} */}
    </>
  );
};
