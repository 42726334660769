import React, { FC } from "react";

const ObjectsFilledIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2999 0.331147C13.461 0.0211279 12.539 0.0211284 11.7001 0.331147L7.39337 1.92273C6.70625 2.17666 6.25 2.83169 6.25 3.56423V6.35743C7.05071 6.2294 7.87632 6.30772 8.64657 6.59237L12.9533 8.18395C14.033 8.58299 14.75 9.61231 14.75 10.7634V16.4335C14.75 16.7557 14.6938 17.0684 14.5892 17.3604L18.6066 15.8757C19.2937 15.6218 19.75 14.9668 19.75 14.2343V3.56422C19.75 2.83169 19.2937 2.17666 18.6066 1.92273L14.2999 0.331147ZM9.76921 5.20905C9.37772 5.07373 9.17006 4.64667 9.30538 4.25518C9.4407 3.8637 9.86776 3.65604 10.2593 3.79136L12.9178 4.71031C12.9708 4.72863 13.0284 4.7286 13.0813 4.71025L15.7318 3.79185C16.1232 3.65624 16.5504 3.86357 16.686 4.25496C16.8216 4.64634 16.6143 5.07356 16.2229 5.20918L13.5725 6.12758C13.2017 6.25604 12.7986 6.25619 12.4278 6.12801L9.76921 5.20905ZM5.70008 7.53037C6.53897 7.22035 7.46103 7.22035 8.29992 7.53037L12.6066 9.12195C13.2937 9.37588 13.75 10.0309 13.75 10.7634V16.4335C13.75 17.166 13.2937 17.821 12.6066 18.075L8.29992 19.6665C7.46103 19.9766 6.53897 19.9766 5.70008 19.6665L1.39337 18.075C0.706254 17.821 0.25 17.166 0.25 16.4335V10.7634C0.25 10.0309 0.706252 9.37588 1.39337 9.12195L5.70008 7.53037ZM3.30538 11.7142C3.17006 12.1057 3.37772 12.5327 3.76921 12.668L6.24989 13.5255V16.2243C6.24989 16.6386 6.58567 16.9743 6.99989 16.9743C7.4141 16.9743 7.74989 16.6386 7.74989 16.2243V13.5251L10.2229 12.6682C10.6143 12.5325 10.8216 12.1053 10.686 11.7139C10.5504 11.3226 10.1232 11.1152 9.73178 11.2508L6.99959 12.1976L4.25925 11.2503C3.86776 11.115 3.4407 11.3227 3.30538 11.7142Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default ObjectsFilledIcon;
