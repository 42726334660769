import { t } from "i18next";

import { IAsideData } from "../../../utils/types/types";
import { FileDropzone } from "../../file-dropzone";
import { FeatureModalWrapper } from "../feature-modal-wrapper";
import { AsideItem } from "./aside-item";
import { FeatureModalAsideProps } from "./index.props";
import "./styles.scss";

export function FeatureModalAside({
  data,
  onDrop,
  file,
  deleteFile,
  classNames,
  trigger,
  active,
  attachments,
  setAttachmentModal,
}: FeatureModalAsideProps) {
  return (
    <div className={`feature-modal-aside ${classNames ? `${classNames}` : ""}`}>
      <FeatureModalWrapper classNames="feature-modal-aside-wrapper">
        <div className="feature-modal-aside-steps">
          {data.map((item, index) => (
            <AsideItem
              key={item.id}
              item={item}
              trigger={trigger}
              active={active}
              error={index === 1}
            />
          ))}
        </div>
        {/* {setAttachmentModal && (
          <div className="feature-modal-drop feature-modal-drop--aside">
            <FileDropzone
              onDrop={onDrop}
              attachmentButtonClick={() => {
                setAttachmentModal && setAttachmentModal(true);
              }}
              attachments={attachments}
              file={file}
              deleteFile={deleteFile}
              text={t("components.locationModal.asideAttachments")}
              maxFiles={20}
              attachmentPreview
              fileTypeText=""
            />
          </div>
        )} */}
      </FeatureModalWrapper>
    </div>
  );
}

export function FormSectionLinksModalAside({
  data,
  trigger,
  active,
}: {
  data: IAsideData[];
  trigger: (id: string) => (el: unknown) => void;
  active?: string;
}) {
  return (
    <aside className="form-links">
      {data.map((item) => (
        <AsideItem
          key={item.id}
          item={item}
          trigger={trigger}
          active={active}
          error={item.error}
        />
      ))}
    </aside>
  );
}
