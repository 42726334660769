import Row from "@avinet/adaptive-ui-core/layout/Row";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

import { AlertMessage } from "../components/alert-message";
import { Icon, IconEnum } from "../components/icon/Icon";
import { Modal } from "../components/modal";
import { ALERT_MESSAGE_STATUS, ALERT_MESSAGE_TYPE } from "../contants/Enums";
import "./NotificationProvider.scss";

type variant = "danger" | "warning" | "primary";

interface IDialogOption {
  value: string | number;
  label: string;
  primaryOption?: boolean;
}

interface INotificationDialogProps {
  title?: string | null;
  message: string;
  choices: IDialogOption[];
  icon?: IconEnum;
  variant?: variant;
}

interface IToastProps {
  title: string;
  message: string;
  type: ALERT_MESSAGE_TYPE;
  status: ALERT_MESSAGE_STATUS;
}

interface NotificationApi {
  notificationDialog: ({
    title,
    message,
    choices,
  }: INotificationDialogProps) => Promise<string | number>;
  toast: ({ title, message, type, status }: IToastProps) => Promise<string>;
}

const NotificationContext = createContext({} as NotificationApi);
const NotificationContextProvider = NotificationContext.Provider;

export function useNotificationApi() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [dialogState, setDialogState] = useState<{
    message: string;
    title?: string | null;
    icon?: IconEnum;
    variant?: variant;
    choices: IDialogOption[];
    callback: (choice: string | number) => void;
  }>();

  const notificationDialog = useCallback(
    ({ title, message, choices, icon, variant }: INotificationDialogProps) => {
      return new Promise<string | number>((resolve) => {
        setDialogState({
          title,
          message,
          icon,
          variant,
          choices,
          callback: (choice: string | number) => {
            setDialogState(undefined);
            resolve(choice);
          },
        });
      });
    },
    []
  );

  const [toastState, setToastState] = useState<{
    title: string;
    message: string;
    type: ALERT_MESSAGE_TYPE;
    status: ALERT_MESSAGE_STATUS;
  }>();

  const toast = useCallback(({ title, message, type, status }: IToastProps) => {
    return new Promise<string>((resolve) => {
      setToastState({
        title,
        message,
        type,
        status,
      });
      setTimeout(() => {
        setToastState(undefined);
        resolve("finished");
      }, 10000);
    });
  }, []);

  return (
    <NotificationContextProvider value={{ notificationDialog, toast }}>
      {children}
      {dialogState && (
        <Modal show>
          <div className="modal-content-container notification-dialog">
            <Row className="dialog-header">
              {dialogState.icon && (
                <Icon
                  name={dialogState.icon}
                  className={dialogState.variant ? dialogState.variant : ""}
                />
              )}
              {dialogState.title && <h4>{dialogState.title}</h4>}
            </Row>
            <p>{dialogState.message}</p>
            <Row wrap className="buttons">
              {dialogState.choices.map((choice) => (
                <button
                  className={`btn ${choice.primaryOption ? "" : "light"}`}
                  key={choice.value}
                  onClick={() => dialogState.callback(choice.value)}
                >
                  {choice.label}
                </button>
              ))}
            </Row>
          </div>
        </Modal>
      )}
      {toastState && (
        <AlertMessage
          message={{
            title: toastState.title,
            description: toastState.message,
            type: toastState.type,
            status: toastState.status,
          }}
        />
      )}
    </NotificationContextProvider>
  );
}
