import AdaptiveUiTheme from "@avinet/adaptive-ui-core/adaptive-ui/AdaptiveUiTheme";

import { icons } from "./assets/icons";

const theme: AdaptiveUiTheme = {
  name: "ombruk",
  defaultTextColor: "#404968",
  headingTextColor: "#ffffff",
  headingFontWeight: 500,
  headingFontSizes: ["4.8rem", "4rem", "3rem", "2.4rem", "2rem", "1.6rem"],
  primary: "#6239D9",
  primaryContrast: "#4E2EAE",
  secondary: "#5157BD",
  secondaryContrast: "#393D7A",
  tertiary: "D9D9D9",
  tertiaryContrast: "#F1F2FC",
  light: "#DCDFEA",
  medium: "#6239D9",
  mediumContrast: "#404040",
  dark: "#111322",
  success: "#D1FADF",
  warning: "#F79009",
  danger: "#F04438",
  radius: 8,
  buttonPadding: "12px 12px",
  inputShadowBlur: 0,
  inputShadowOffset: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  inputBackground: "#ffffff",
  inputTextColor: "#5D6B98",
  inputBorderColor: "#B9C0D4",
  inputBorderColorSingle: "#EBEDFB",
  inputPadding: "10px 14px",
  inputRadius: 8,
  labelTextColor: "#404968",
  labelFontWeight: 500,
  icons,
  checkboxRadius: 4,
  lightContrast: "#F9F9FB",
  darkContrast: "#4A5578",
  warningContrast: "#121926",
  successContrast: "#eff1f5",
  linkTextColor: "#d7d8f7",
  fontStack: "Inter",
  headingFontStack: "Inter",
};

export default theme;
