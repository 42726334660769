import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC } from "react";

import { Modal } from "../../modal";
import { SubmitButtons } from "../../submit-buttons";
import { ProfileModalProps } from "./index.props";
import "./styles.scss";

export const ProfileModal: FC<ProfileModalProps> = ({
  iconName,
  title,
  description,
  children,
  showModal,
  cancelButtonClick,
  submitButtonClick,
  submitButtonText,
  className,
  deleteMode,
}) => {
  return (
    <Modal show={showModal}>
      <div
        className={`profile-modal ${className ? className : ""} ${
          deleteMode ? "profile-modal--delete" : ""
        }`}
      >
        <div onClick={cancelButtonClick} className="profile-modal__close">
          <Icon name="closeIcon" />
        </div>
        <div>
          <Icon name={iconName} className="profile-modal__center-icon" />
          <p className="profile-modal__title">{title}</p>
          <p className="profile-modal__description">{description}</p>
        </div>
        {children}
        <SubmitButtons
          deleteMode={deleteMode}
          submitText={submitButtonText}
          onCancel={cancelButtonClick}
          onSubmit={submitButtonClick}
        />
      </div>
    </Modal>
  );
};
