import { SVGProps } from "react";

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={29} viewBox="0 0 23 29" fill="none" {...props}>
    <path
      d="M3.5 0.5C1.84315 0.5 0.5 1.84315 0.5 3.5V25.5C0.5 27.1569 1.84315 28.5 3.5 28.5H19.5C21.1569 28.5 22.5 27.1569 22.5 25.5V9.32843C22.5 8.53278 22.1839 7.76972 21.6213 7.20711L15.7929 1.37868C15.2303 0.81607 14.4672 0.5 13.6716 0.5H3.5ZM2.5 3.5C2.5 2.94772 2.94772 2.5 3.5 2.5H12.5V7.5C12.5 9.15685 13.8431 10.5 15.5 10.5H20.5V25.5C20.5 26.0523 20.0523 26.5 19.5 26.5H3.5C2.94772 26.5 2.5 26.0523 2.5 25.5V3.5ZM20.0858 8.5H15.5C14.9477 8.5 14.5 8.05228 14.5 7.5V2.91421L20.0858 8.5Z"
      fill="#404968"
    />
  </svg>
);
