import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC } from "react";
import { useActiveMenu } from "react-active-menu";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { organizationDetailsMenu } from "../../../contants/MockData";
import { Text } from "../../text";
import { OrganisationMembers } from "../organisation-members";
import { OrganizationInfo } from "../organization-info";
import { OrganizationReportLogo } from "../organization-report-logo/OrganizationReportLogo";

export const OrganizationDetailsContent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { registerContainer, registerSection, registerTrigger } = useActiveMenu(
    {
      smooth: true,
      offset: 40,
    }
  );

  return (
    <section className="user-settings" id="organization-settings">
      <div className="menu">
        {location?.state?.withPrevButton && (
          <div className="user-settings__back" onClick={() => navigate(-1)}>
            <Icon name="arrow" rotate={180} />
            <Text text={t("common.backToOrg")} fontWeight={600} size="sm" />
          </div>
        )}
        <ul>
          {organizationDetailsMenu.map((item, index) => (
            <li className="menu__item" key={index}>
              <button
                className="menu__item__link"
                ref={registerTrigger(item.id)}
              >
                {t(`${item.text}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="user-settings__details" ref={registerContainer}>
        <section ref={registerSection("organisation")}>
          <OrganizationInfo />
        </section>

        <section ref={registerSection("members")}>
          <OrganisationMembers />
        </section>
        <section ref={registerSection("logo")}>
          <OrganizationReportLogo />
        </section>
      </div>
    </section>
  );
};
