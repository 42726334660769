import Row from "@avinet/adaptive-ui-core/layout/Row";
import { on } from "events";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../icon/Icon";
import { Text } from "../text";
import { PaginationProps } from "./index.props";
import "./styles.scss";

export const Pagination: FC<PaginationProps> = ({
  className = "",
  offset,
  limit,
  total,
  onPaging,
}) => {
  const { t } = useTranslation();
  const pageLengthLimit = 7;
  const amountOfPageButtonDisplay = 1;
  const arrLength = Math.floor(
    total / (limit ?? 1) + (total % limit > 0 ? 1 : 0)
  );
  const pageButtons = Array.from(
    { length: arrLength },
    (_: unknown, i: number) => i + 1
  );

  // calculate how many records need to be skipped after click on selected page depends on selected page limit
  const calculatePageOffset = useCallback(
    (page: number) => page * limit - limit,
    [limit]
  );

  useEffect(() => {
    const handleGrid = () => {
      if (total > 0 && offset >= total) {
        onPaging(0);
      }
    };

    handleGrid();
  }, [limit, offset, onPaging, total]);

  return (
    <>
      {arrLength > 0 && (
        <Row className={`pagination ${className}`}>
          {total > 0 && (
            <>
              <button
                className="pagination__button__prev"
                disabled={offset === 0}
                onClick={() => onPaging(offset - limit)}
              >
                <Icon className="pagination__arrow" name="arrow" rotate={180} />
                <Text text={t("common.prev")} size="sm" fontWeight={600} />
              </button>

              {pageButtons.length && pageButtons.length >= pageLengthLimit ? (
                <>
                  {pageButtons.map((page: number, index) => {
                    const paginationLast3Items =
                      pageButtons.length - amountOfPageButtonDisplay;
                    const activePage = calculatePageOffset(page) === offset;
                    const pageIndex = pageButtons.indexOf(page);

                    const selectedPageIndex = pageButtons.filter(
                      (pages, pagesIndex) => {
                        if (page === 1) {
                          return (
                            pagesIndex === Number(pageIndex) + 1 ||
                            pagesIndex === Number(pageIndex) + 2 ||
                            pagesIndex === Number(pageIndex) + 3
                          );
                        } else if (page === 2) {
                          return (
                            pagesIndex === Number(pageIndex) ||
                            pagesIndex === Number(pageIndex) + 1 ||
                            pagesIndex === Number(pageIndex) + 2
                          );
                        } else if (page === paginationLast3Items) {
                          return (
                            pagesIndex === Number(pageIndex) ||
                            pagesIndex === Number(pageIndex) - 1 ||
                            pagesIndex === Number(pageIndex) - 2
                          );
                        } else if (page === paginationLast3Items + 1) {
                          return (
                            pagesIndex === Number(pageIndex) - 1 ||
                            pagesIndex === Number(pageIndex) - 2 ||
                            pagesIndex === Number(pageIndex) - 3
                          );
                        } else {
                          return (
                            pagesIndex === Number(pageIndex) ||
                            pagesIndex === Number(pageIndex) - 1 ||
                            pagesIndex === Number(pageIndex) + 1
                          );
                        }
                      }
                    );

                    return (
                      <>
                        {activePage && index === paginationLast3Items && (
                          <>
                            {index === pageButtons.length - 1 && (
                              <button
                                className="pagination__button__number"
                                disabled
                              >
                                ...
                              </button>
                            )}
                            {selectedPageIndex?.length &&
                              selectedPageIndex.map((extendedPage) => (
                                <button
                                  className={`className="pagination__button__number" ${
                                    calculatePageOffset(extendedPage) === offset
                                      ? "active"
                                      : ""
                                  }`}
                                  key={extendedPage}
                                  onClick={() =>
                                    onPaging(calculatePageOffset(extendedPage))
                                  }
                                >
                                  {extendedPage}
                                </button>
                              ))}
                          </>
                        )}

                        {(index < 1 || index >= pageButtons.length - 1) && (
                          <button
                            className={`className="pagination__button__number" ${
                              activePage ? "active" : ""
                            }`}
                            key={page}
                            onClick={() => onPaging(calculatePageOffset(page))}
                          >
                            {page}
                          </button>
                        )}

                        {activePage && index < pageButtons.length - 1 && (
                          <>
                            {page !== 1 && page !== 2 && page !== 3 && (
                              <button
                                className="pagination__button__number"
                                disabled
                              >
                                ...
                              </button>
                            )}
                            {selectedPageIndex?.length &&
                              selectedPageIndex.map((extendedPage) => (
                                <button
                                  className={`className="pagination__button__number" ${
                                    calculatePageOffset(extendedPage) === offset
                                      ? "active"
                                      : ""
                                  }`}
                                  key={extendedPage}
                                  onClick={() =>
                                    onPaging(calculatePageOffset(extendedPage))
                                  }
                                >
                                  {extendedPage}
                                </button>
                              ))}

                            {pageButtons.length !== 9 &&
                              page !== pageButtons.length - 1 &&
                              page !== pageButtons.length - 2 && (
                                <button
                                  className="pagination__button__number"
                                  disabled
                                >
                                  ...
                                </button>
                              )}
                          </>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {pageButtons.map((page: number) => (
                    <button
                      className={`className="pagination__button__number" ${
                        calculatePageOffset(page) === offset ? "active" : ""
                      }`}
                      key={page}
                      onClick={() => onPaging(calculatePageOffset(page))}
                    >
                      {page}
                    </button>
                  ))}
                </>
              )}
              <button
                className="pagination__button__next"
                disabled={offset + limit >= total}
                onClick={() => onPaging(offset + limit)}
              >
                <Text text={t("common.next")} size="sm" fontWeight={600} />
                <Icon className="pagination__arrow" name="arrow" />
              </button>
            </>
          )}
        </Row>
      )}
    </>
  );
};
