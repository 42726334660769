import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import CheckBox from "@avinet/adaptive-ui-core/form/controls/CheckBox";
import { SelectItem } from "@avinet/adaptive-ui-core/form/controls/Select";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { set } from "date-fns";
import { t } from "i18next";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import "react-lazy-load-image-component/src/effects/blur.css";

import { KIND_RESPONSE } from "../../../api/api-problem";
import { IObjectMappings } from "../../../api/types";
import {
  FEATURE_TYPE,
  GRID_OPTION,
  LOCATION_STATUS,
  OBJECT_STATUS,
  PROJECT_STATUS,
  THUMBNAIL_SIZE,
} from "../../../contants/Enums";
import { FEATURE_UUID } from "../../../contants/FeatureUuid";
import LOCAL_STORAGE from "../../../contants/LocalStorage";
import { DictionaryContext } from "../../../context/dictionary-context/DictionaryContext";
import { FeatureContext } from "../../../context/feature-context/FeatureContext";
import {
  FilterContext,
  IComponentFilter,
  ILocationFilter,
  IProjectFilter,
} from "../../../context/filter-context/FilterContext";
import { useCheckMobile } from "../../../hooks/useCheckMobile";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import {
  getFromStore,
  saveToStore,
} from "../../../utils/functions/local-storage";
import {
  guidGenerator,
  handleTranslateBuildingCategory,
} from "../../../utils/index.utils";
import { BatchSelect } from "../../batch-select/BatchSelect";
import { GridItem } from "../../grid-item/GridItem";
import { GridView } from "../../grid-view/GridView";
import { Icon } from "../../icon/Icon";
import { ImagePreview } from "../../image-preview/ImagePreview";
import { NoFeaturePlaceholder } from "../../no-feature-placeholder";
import { ObjectStatus } from "../../object/object-status";
import { TabSwitch } from "./Tab-switch";
import "./styles.scss";
import { TableFooter } from "./table-footer/TableFooter";
import { PageTableHeadWrapper } from "./table-header/PageTableHeadWrapper";
import {
  IPageTableColumnData,
  IPageTableColumnMeta,
  PageTableColumnTypes,
  PageTableProps,
  TableImageValue,
} from "./table-header/index.props";
import { TableSelect } from "./tableSelect";

export enum SortOrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export function PageTable({
  className = "",
  title,
  subtitle,
  tabs,
  columns,
  noHeader = false,
  featureUUid,
  onRecordSelect,
  onRecordPreview,
  onRecordEdit,
  onRecordDelete,
  statusOptions,
  totalItemsCount,
  featurePreviewFilter,
  noFeatureHandler,
  disableBatchSelect = false,
  onFilterClick,
  filterActive,
  tableHeaderText,
  resetBatchSelect,
  setResetBatchSelect,
  componentInLocation = false,
}: PageTableProps) {
  const {
    projectFilter,
    projectLocationFilter,
    projectComponentFilter,
    locationFilter,
    locationProjectFilter,
    componentFilter,
    componentProjectFilter,
    componentLocationFilter,

    handleProjectFilter,
    handleProjectLocationFilter,
    handleProjectComponentFilter,
    handleLocationFilter,
    handleLocationProjectFilter,
    handleComponentFilter,
    handleComponentProjectFilter,
    handleComponentLocationFilter,
  } = useContext(FilterContext);

  let filter = null as any;
  let handleFilter = null as any;

  switch (featureUUid) {
    case FEATURE_UUID.PROJECT: {
      filter = projectFilter;
      handleFilter = handleProjectFilter;
      break;
    }
    case FEATURE_UUID.LOCATION_PROJECTS: {
      filter = projectLocationFilter;
      handleFilter = handleProjectLocationFilter;
      break;
    }
    case FEATURE_UUID.PRODUCT_PROJECTS: {
      filter = projectComponentFilter;
      handleFilter = handleProjectComponentFilter;
      break;
    }

    case FEATURE_UUID.LOCATION: {
      filter = locationFilter;
      handleFilter = handleLocationFilter;
      break;
    }

    case FEATURE_UUID.PROJECT_LOCATIONS: {
      filter = locationProjectFilter;
      handleFilter = handleLocationProjectFilter;
      break;
    }
    case FEATURE_UUID.PRODUCT: {
      if (componentInLocation) {
        filter = componentLocationFilter;
        handleFilter = handleComponentLocationFilter;
      } else {
        filter = componentFilter;
        handleFilter = handleComponentFilter;
      }
      break;
    }

    case FEATURE_UUID.PROJECT_PRODUCTS: {
      filter = componentProjectFilter;
      handleFilter = handleComponentProjectFilter;
      break;
    }
    default:
      break;
  }

  const [activeTab, setActiveTab] = useState(
    filter?.activeTab ?? tabs?.[0]?.text ?? ""
  );

  const handleActiveTab = useCallback(
    (newActiveTab: string) => {
      if (handleFilter) {
        handleFilter(
          (
            current: Partial<
              IComponentFilter | ILocationFilter | IProjectFilter
            >
          ) => {
            return {
              ...current,
              activeTab: newActiveTab,
            };
          }
        );

        setActiveTab(newActiveTab);
      }
    },
    [handleFilter]
  );

  const {
    totalLocationsNumber,
    totalProductsNumber,
    totalProjectsNumber,
    totalProjectProductsNumber,
  } = useContext(FeatureContext);
  const [activeHeader, setActiveHeader] = useState<string | undefined>();
  const [sortOrder, setSortOrder] = useState<SortOrderDirection>(
    filter?.sort_order ?? SortOrderDirection.DESC
  );
  const [offset, setOffset] = useState<number>(filter?.offset ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState<string>(filter?.limit ?? "25");
  const [gridMode, setGridMode] = useState(filter?.gridmode ?? false);
  const [selectedItems, setSelectedItems] = useState<(number | string)[]>([]);
  const { buildStatuses } = useSelectOptions();
  const { form, state } = useForm();
  const { isMobile } = useCheckMobile();
  const { categoryDictionary, getDictionaries } = useContext(DictionaryContext);

  const ImageColumn = useCallback(
    (properties: { image?: string | null; name?: string }) => {
      return (
        <div key={properties.image} className="data-cell image">
          <div>
            <ImagePreview
              image={properties.image}
              name={properties.name}
              size={THUMBNAIL_SIZE.SMALL}
              className="pagetable-body-image-wrapper"
            />
          </div>
        </div>
      );
    },
    []
  );

  const handleSetRowsPerPage = useCallback(
    (newLimit: string) => {
      if (handleFilter) {
        handleFilter(
          (
            current: Partial<
              IComponentFilter | ILocationFilter | IProjectFilter
            >
          ) => {
            return {
              ...current,
              limit: newLimit,
            };
          }
        );

        setRowsPerPage(newLimit);
      }
    },
    [handleFilter]
  );

  const handleSetOffset = useCallback(
    (newOffset: number) => {
      if (handleFilter) {
        handleFilter(
          (
            current: Partial<
              IComponentFilter | ILocationFilter | IProjectFilter
            >
          ) => {
            return {
              ...current,
              offset: newOffset,
            };
          }
        );

        setOffset(newOffset);
      }
    },
    [handleFilter]
  );

  const handleSetSortOrder = useCallback(
    (newSortOrder: SortOrderDirection) => {
      if (handleFilter) {
        handleFilter(
          (
            current: Partial<
              IComponentFilter | ILocationFilter | IProjectFilter
            >
          ) => {
            return {
              ...current,
              sort_order: newSortOrder,
            };
          }
        );

        setSortOrder(newSortOrder);
      }
    },
    [handleFilter]
  );

  const TextColumn = useCallback((property: string) => {
    return (
      <div key={guidGenerator()} className="data-cell text">
        <div className="text">{property}</div>
      </div>
    );
  }, []);

  useLayoutEffect(() => {
    !categoryDictionary.length && getDictionaries();
  }, [categoryDictionary, getDictionaries]);

  const ConditionColumn = useCallback((mapping: IObjectMappings) => {
    return (
      <div key={guidGenerator()} className="data-cell">
        {mapping?.quantity > 0 || mapping?.obj_condition ? (
          <ObjectStatus
            text={
              mapping?.quantity > 0 && mapping?.obj_condition === ""
                ? OBJECT_STATUS.NO_CONDITION
                : mapping?.obj_condition
            }
            status={
              mapping?.quantity > 0 && mapping?.obj_condition === ""
                ? OBJECT_STATUS.NO_CONDITION
                : mapping?.obj_condition?.split(" ")[0]
            }
          />
        ) : (
          <div />
        )}
      </div>
    );
  }, []);

  const getColumnMeta = useCallback(
    (headerKey: string) => {
      return columns?.columns.find((column) => column.key === headerKey);
    },
    [columns?.columns]
  );

  const featureStatusColumnType = useCallback(() => {
    switch (featureUUid) {
      case FEATURE_UUID.LOCATION:
        return "status";
      case FEATURE_UUID.PROJECT:
        return "status";
      case FEATURE_UUID.PRODUCT_PROJECTS:
        return "status";
      case FEATURE_UUID.LOCATION_PROJECTS:
        return "status";
      case FEATURE_UUID.PROJECT_LOCATIONS:
        return "status";
      case FEATURE_UUID.PRODUCT:
        return "obj_condition";
      case FEATURE_UUID.PROJECT_PRODUCTS:
        return "recommendation";
      default:
        return undefined;
    }
  }, [featureUUid]);

  const handleReload = useCallback(() => {
    if (activeTab) {
      return;
    }
  }, [activeTab]);

  const toCell = useCallback(
    (row: IPageTableColumnData, id: number | string) => {
      const columnMeta = getColumnMeta(row.key);
      const type = columnMeta?.type;
      const active = columnMeta?.active;

      if (!active) {
        return null;
      }
      switch (type) {
        case PageTableColumnTypes.IMAGE:
          return ImageColumn(row.value as object);
        case PageTableColumnTypes.STATUS:
          return (
            <SelectStatus
              id={id}
              items={statusOptions || buildStatuses}
              value={row.value?.toString()}
              reload={handleReload}
              featureUUid={featureUUid}
            />
          );
        case PageTableColumnTypes.TEXT:
          switch (row.key) {
            case "building_category":
              return TextColumn(
                handleTranslateBuildingCategory(row.value as string)
              );
            default:
              return TextColumn(row.value as string);
          }
        case PageTableColumnTypes.CONDITION:
          return ConditionColumn(row.value as IObjectMappings);
        default:
          return TextColumn(row.value as string);
      }
    },
    [
      ConditionColumn,
      ImageColumn,
      TextColumn,
      buildStatuses,
      featureUUid,
      getColumnMeta,
      handleReload,
      statusOptions,
    ]
  );

  const handleSelectColumn = useCallback(
    (id: string) => {
      const column = getColumnMeta(id);
      if (column && columns) {
        columns.updateColumnVisibility(id, !column.active);
      }
    },
    [columns, getColumnMeta]
  );

  const handleResetSelectColumn = useCallback(() => {
    columns?.resetAllColumns();
  }, [columns]);

  const noDataPlaceholderType = () => {
    if (
      featureUUid === FEATURE_UUID.LOCATION ||
      featureUUid === FEATURE_UUID.PROJECT_LOCATIONS
    ) {
      return FEATURE_TYPE.LOCATION;
    }

    if (featureUUid === FEATURE_UUID.PRODUCT) {
      return FEATURE_TYPE.OBJECT;
    }

    if (
      featureUUid === FEATURE_UUID.PROJECT ||
      featureUUid === FEATURE_UUID.LOCATION_PROJECTS
    ) {
      return FEATURE_TYPE.PROJECT;
    }

    if (featureUUid === FEATURE_UUID.PROJECT_PRODUCTS) {
      return FEATURE_TYPE.PROJECT_PRODUCTS;
    }

    if (featureUUid === FEATURE_UUID.PRODUCT_PROJECTS) {
      return FEATURE_TYPE.OBJECT_PROJECT;
    }

    return FEATURE_TYPE.LOCATION;
  };

  const handleSelectBatchItem = (val: boolean, id: number | string) => {
    setSelectedItems((prev) =>
      val ? [...prev, id] : prev.filter((itemId) => itemId !== id)
    );
  };

  const getIdFromRow = useCallback((row: IPageTableColumnData[]) => {
    const idColumn = row.find((column) => column.key === "id");

    const value = idColumn?.value;

    if (typeof value === "string" || typeof value === "number") return value;
  }, []);

  const getMappingIdFromRow = useCallback((row: IPageTableColumnData[]) => {
    const mappingIdColumn = row.find((column) => column.key === "object_id");

    const value = mappingIdColumn?.value;

    if (typeof value === "number") return value;
  }, []);

  const allPageItemsSelected = useMemo(() => {
    return columns?.data
      ?.map(getIdFromRow)
      .every((id) => id !== undefined && selectedItems.includes(id));
  }, [columns?.data, getIdFromRow, selectedItems]);

  const handleSelectAllBatchItems = useCallback(
    (selectAll: boolean) => {
      const ids = columns?.data
        ?.map(getIdFromRow)
        .filter((id): id is string | number => id !== undefined);

      if (!ids) return;

      if (allPageItemsSelected) {
        setSelectedItems((values) =>
          values.filter((value) => !ids.includes(value))
        );
      } else if (selectAll) {
        setSelectedItems((prev) => [
          ...prev,
          ...ids.filter((id) => !prev.includes(id)),
        ]);
      } else {
        setSelectedItems([]);
      }
    },
    [allPageItemsSelected, columns?.data, getIdFromRow]
  );

  const hasData = !!columns?.data?.length;

  const featureTotalCount = useCallback(() => {
    if (totalItemsCount === undefined) {
      switch (featureUUid) {
        case FEATURE_UUID.LOCATION:
          return totalLocationsNumber;
        case FEATURE_UUID.PRODUCT:
          return totalProductsNumber;
        case FEATURE_UUID.PROJECT:
          return totalProjectsNumber;
        case FEATURE_UUID.PROJECT_PRODUCTS:
          return totalProjectProductsNumber;
        default:
          return 0;
      }
    } else {
      return totalItemsCount;
    }
  }, [
    featureUUid,
    totalProjectsNumber,
    totalLocationsNumber,
    totalProductsNumber,
    totalProjectProductsNumber,
    totalItemsCount,
  ]);

  const closeBatchSelect = useCallback(() => {
    setSelectedItems([]);
    setResetBatchSelect && setResetBatchSelect(false);
  }, [setResetBatchSelect]);

  const handleSetNewSortOrder = useCallback(
    (el: string) => {
      return activeHeader === el && sortOrder === SortOrderDirection.DESC
        ? SortOrderDirection.ASC
        : SortOrderDirection.DESC;
    },
    [activeHeader, sortOrder]
  );

  const handleSortColumn = useCallback(
    (newSortColumn: string) => {
      if (handleFilter) {
        handleFilter(
          (
            current: Partial<
              IComponentFilter | ILocationFilter | IProjectFilter
            >
          ) => {
            return {
              ...current,
              sort_column: newSortColumn,
            };
          }
        );
      }
      setActiveHeader(newSortColumn);
    },
    [handleFilter]
  );

  const handleSetSortColumn = useCallback(
    (el: IPageTableColumnMeta | undefined) => {
      if (!el?.sortable) {
        handleSortColumn("date_created");
      } else {
        const newSortOrder = handleSetNewSortOrder(el.key);
        handleSetSortOrder(newSortOrder);
        handleSetOffset(0);
        handleSortColumn(el.key);
      }
    },
    [
      handleSetNewSortOrder,
      handleSetSortOrder,
      handleSetOffset,
      handleSortColumn,
    ]
  );

  const handleSetGridMode = useCallback(
    (gridMode: boolean) => {
      setGridMode(gridMode);
      if (handleFilter) {
        handleFilter(
          (
            current: Partial<
              IComponentFilter | ILocationFilter | IProjectFilter
            >
          ) => {
            return {
              ...current,
              gridmode: gridMode,
            };
          }
        );
      }
    },
    [handleFilter]
  );

  const handleResetSortColumns = useCallback(() => {
    setActiveHeader(undefined);
    handleSetSortOrder(SortOrderDirection.DESC);
    handleSetOffset(0);
    handleSetSortColumn(undefined);
  }, [handleSetSortOrder, handleSetOffset, handleSetSortColumn]);

  useEffect(() => {
    const handleGrid = async () => {
      isMobile && !gridMode && setGridMode(true);
      !isMobile && gridMode && handleSetGridMode(filter?.gridmode);
    };

    handleGrid();
  }, [isMobile, filter?.gridmode, gridMode, handleSetGridMode]);

  useEffect(() => {
    resetBatchSelect && closeBatchSelect();
  }, [resetBatchSelect, closeBatchSelect]);

  const handleMultipleDeleteItems = useCallback(() => {
    const items = selectedItems?.length
      ? selectedItems.filter((item) => typeof item === "number")
      : [];

    onRecordDelete && onRecordDelete(items as number[]);
  }, [selectedItems, onRecordDelete]);

  return (
    <div className={`pagetable-wrapper ${className}`}>
      <FormProvider form={form} state={state}>
        {!noHeader && (
          <PageTableHeadWrapper
            title={tableHeaderText ? tableHeaderText(activeTab) : title}
            subtitle={subtitle}
            totalLocationsNumber={featureTotalCount()}
            featureUUid={featureUUid}
            columns={columns}
            handleSelectColumn={handleSelectColumn}
            resetAllColumns={handleResetSelectColumn}
            onFilterClick={onFilterClick}
            filterActive={filterActive}
            resetSortColumns={handleResetSortColumns}
            gridMode={gridMode}
            handleSetGridMode={handleSetGridMode}
            handleSortItem={handleSetSortColumn}
            activeHeader={activeHeader}
            sortOrder={sortOrder}
          />
        )}

        {tabs && activeTab && (
          <TabSwitch
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={handleActiveTab}
            onClick={(value) => {
              setActiveHeader(undefined);
              handleActiveTab(value);
              handleSetSortColumn(activeTab);
            }}
          />
        )}

        <div className="pagetable-scroll-area">
          <div className="page-table-content-wrapper">
            <div
              className={`pagetable-content ${
                !hasData ? "pagetable-content--no-data" : ""
              }`}
            >
              {!hasData ? (
                <NoFeaturePlaceholder
                  className="pagetable-scroll-area__no-data"
                  type={noDataPlaceholderType()}
                  parentElementType={noDataPlaceholderType()}
                  onClick={noFeatureHandler}
                  status
                />
              ) : (
                <>
                  {gridMode ? (
                    <GridView>
                      {columns?.data?.map((dataRow, index) => {
                        const id = getIdFromRow(dataRow);
                        const imageData = dataRow?.find((row) =>
                          row.key === "picture" || row.key === "image"
                            ? row.value
                            : null
                        );
                        const itemName = dataRow?.find((row) =>
                          row.key === "name" || row.key === "specification"
                            ? row.value
                            : null
                        );
                        const objCondition = dataRow?.find((row) =>
                          row.key === "obj_condition" ? row.value : null
                        );
                        const objSum = dataRow?.find((row) =>
                          row.key === "sum" ? row.value : null
                        );

                        if (id === undefined) return null;

                        return (
                          <GridItem
                            id={id}
                            key={`${id}-${index}`}
                            imageData={imageData?.value as TableImageValue}
                            name={String(itemName?.value)}
                            objectCondition={
                              objCondition?.value as IObjectMappings
                            }
                            conditionSum={objSum?.value}
                            handleRecordSelect={() =>
                              onRecordSelect && onRecordSelect(id)
                            }
                            selectedItems={selectedItems}
                            handleSelectBatchItem={handleSelectBatchItem}
                            disableBatchSelect={disableBatchSelect}
                          />
                        );
                      })}
                    </GridView>
                  ) : (
                    <>
                      {/*  Header */}
                      <div
                        className={
                          noHeader
                            ? "pagetable-head pagetable-head--noheader"
                            : "pagetable-head"
                        }
                      >
                        <div className="pagetable-row">
                          <div className="data-cell sticky left">
                            {!disableBatchSelect && (
                              <CheckBox
                                id="id"
                                label=" "
                                onChange={handleSelectAllBatchItems}
                                value={allPageItemsSelected}
                              />
                            )}
                          </div>
                          {columns?.columns?.map((el) => {
                            if (!el || !el.title || !el.active) {
                              return null;
                            }

                            return (
                              <div key={guidGenerator()} className="data-cell">
                                <button
                                  onClick={() => handleSetSortColumn(el)}
                                  className={
                                    "btn-link " +
                                    (el.sortable ? "sortable " : "disabled") +
                                    (activeHeader === el.key ? "active" : "")
                                  }
                                >
                                  {el.title}
                                  <Icon
                                    name="arrow"
                                    className={`sort-arrow ${
                                      activeHeader && activeHeader === el.key
                                        ? "sort-arrow--visible"
                                        : ""
                                    }`}
                                    rotate={
                                      sortOrder === SortOrderDirection.DESC
                                        ? 270
                                        : 90
                                    }
                                  />
                                </button>
                              </div>
                            );
                          })}
                          <div className="data-cell sticky right" />
                        </div>
                      </div>
                      {/* Body */}
                      <div className="pagetable-body">
                        {columns?.data?.map((dataRow, index) => {
                          const id = getIdFromRow(dataRow);
                          const mapping_id = getMappingIdFromRow(dataRow);

                          if (id === undefined) return null;

                          return (
                            <div
                              className="pagetable-row"
                              key={`${id}-${index}`}
                              onClick={() => {
                                onRecordSelect?.(id);
                              }}
                            >
                              <div className="data-cell sticky left">
                                <Row className="row-options">
                                  {!disableBatchSelect && (
                                    <CheckBox
                                      id={String(id)}
                                      label={null}
                                      onChange={(val: boolean) =>
                                        handleSelectBatchItem(val, id)
                                      }
                                      value={selectedItems.includes(id)}
                                    />
                                  )}

                                  {onRecordPreview && (
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onRecordPreview(id, mapping_id);
                                      }}
                                    >
                                      <Icon name="preview" />
                                    </button>
                                  )}
                                </Row>
                              </div>
                              {dataRow?.map((cell, index) => {
                                return (
                                  <Fragment key={index}>
                                    {toCell(cell, id)}
                                  </Fragment>
                                );
                              })}
                              <div className="data-cell sticky right">
                                <Row className="row-options">
                                  {onRecordDelete && (
                                    <button
                                      className="btn btn-icon-delete"
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onRecordDelete(Number(id));
                                      }}
                                    >
                                      <Icon name="delete" />
                                    </button>
                                  )}
                                  {onRecordEdit && (
                                    <button
                                      className="btn"
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onRecordEdit(id);
                                      }}
                                    >
                                      <Icon name="edit" />
                                    </button>
                                  )}
                                </Row>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <Grow />
          {!!selectedItems.length && (
            <BatchSelect
              count={selectedItems.length}
              onClose={closeBatchSelect}
            >
              <button
                className="btn"
                onClick={() => onRecordDelete && handleMultipleDeleteItems()}
              >
                <Icon name="delete" />
                {t("common.delete")}
              </button>
              {/* TODO: handle other buttons in batch select modal */}
              {/*<button*/}
              {/*  className="btn"*/}
              {/*  onClick={() => {*/}
              {/*    console.log("archive");*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Icon name="archive" />*/}
              {/*  {t("common.archive")}*/}
              {/*</button>*/}
              {/*<button*/}
              {/*  className="btn"*/}
              {/*  onClick={() => {*/}
              {/*    console.log("edit");*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Icon name="edit" />*/}
              {/*  {t("common.edit")}*/}
              {/*</button>*/}
              {/*<button*/}
              {/*  className="btn"*/}
              {/*  onClick={() => {*/}
              {/*    console.log("export");*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Icon name="export" />*/}
              {/*  {t("common.export")}*/}
              {/*</button>*/}
            </BatchSelect>
          )}
        </div>
        <TableFooter
          hasData={hasData}
          offset={offset}
          handleSetOffset={handleSetOffset}
          totalFeaturesNumber={featureTotalCount()}
          setActiveTab={handleActiveTab}
          activeTab={activeTab}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={handleSetRowsPerPage}
          sortColumn={activeHeader}
          sortOrder={sortOrder}
          statusColumn={featureStatusColumnType()}
          featurePreviewFilter={featurePreviewFilter}
        />
      </FormProvider>
    </div>
  );
}

function SelectStatus({
  items,
  value,
  id,
  reload,
  featureUUid,
}: {
  items: SelectItem[];
  value: string;
  id: number | string;
  reload: () => void;
  featureUUid?: FEATURE_UUID;
}) {
  const { editFeatureStatus } = useContext(FeatureContext);
  const [status, setStatus] = useState<string>(value);

  const handleColumnStatus = useCallback(
    (value: string) => {
      if (!featureUUid) return;

      editFeatureStatus(value, id as number, featureUUid).then((res) => {
        if (res.kind === KIND_RESPONSE.OK) setStatus(res.data);
        reload();
      });
    },

    [editFeatureStatus, id, reload, featureUUid]
  );

  if (!value) return <div className="data-cell" />;

  return (
    <div className="data-cell">
      <TableSelect items={items} value={status} onChange={handleColumnStatus} />
    </div>
  );
}
