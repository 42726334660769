import { ColorShades } from "@avinet/adaptive-ui-core/adaptive-ui/AdaptiveUiTheme";
import { SVGProps } from "react";

type SVGRProps = {
  customColor?: string | ColorShades;
};

const PlusIcon = ({
  customColor,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fill={`${customColor ? customColor : "#fff"}`}
      d="M8.74984 0C9.12954 0 9.44339 0.282013 9.49312 0.648078L9.49999 0.749848L9.5012 8H16.7543C17.1685 8 17.5043 8.33579 17.5043 8.75C17.5043 9.1297 17.2221 9.44349 16.8561 9.49315L16.7543 9.5H9.5012L9.50324 16.7491C9.50333 17.1633 9.16761 17.4993 8.7534 17.4993C8.3737 17.4993 8.05985 17.2173 8.01011 16.8512L8.00324 16.7494L8.0012 9.5H0.752197C0.337984 9.5 0.00219727 9.16421 0.00219727 8.75C0.00219727 8.3703 0.284351 8.05651 0.650427 8.00685L0.752197 8H8.0012L7.99999 0.750152C7.99991 0.335939 8.33563 0 8.74984 0Z"
    />
  </svg>
);
export default PlusIcon;
