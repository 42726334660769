import { ApiResponse } from "apisauce";

import { DEFAULT_API_CONFIG } from "../api/api-config";
import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import { ArticleResponse, CMSResponse } from "../api/types";
import { Api } from "./api/api";

export class CMSApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getPage(site: number, uri: string): Promise<ArticleResponse> {
    const response: ApiResponse<CMSResponse> = await this.api.apisauce.post(
      `WebServices/cms/Page.asmx/GetPage`,
      { site: site, uri: uri },
      { baseURL: DEFAULT_API_CONFIG.webUrl }
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }

    return { kind: KIND_RESPONSE.OK, data: data };
  }
}
