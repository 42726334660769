import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IObjectProperties, IObjectResponse } from "../api/types";
import { objectsUrl } from "../contants/Routes";
import { FeatureContext } from "../context/feature-context/FeatureContext";

export const useObjectEdit = (locationsProducts?: IObjectResponse[]) => {
  const { productsList, singleProduct } = useContext(FeatureContext);

  const [objectModal, setObjectModal] = useState<{
    mode: "add" | "edit";
    defaultValues?: IObjectResponse;
  }>();

  const [selectedTableItem, setSelectedTableItem] =
    useState<IObjectProperties>();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const navigate = useNavigate();

  const handleObjectModalClose = useCallback(async () => {
    setObjectModal(undefined);

    const id = selectedTableItem?.id;

    if (!id) return;

    const updatedObject = await singleProduct(id, navigate);

    if (updatedObject) setSelectedTableItem(updatedObject.product);
  }, [selectedTableItem?.id, singleProduct, navigate]);

  const handleRecordPreview = useCallback(
    async (id: number | string, mapping_id?: number) => {
      if (selectedTableItem?.mapping_id === mapping_id) {
        setIsSidebarVisible((prev) => !prev);
      } else {
        setIsSidebarVisible(true);
        const selectedObjectMapping = productsList?.find(
          (product) => product.mapping_id === mapping_id
        );

        if (selectedObjectMapping) {
          setSelectedTableItem(selectedObjectMapping as IObjectProperties);
        }
      }
    },
    [productsList, selectedTableItem?.mapping_id]
  );

  const handleRecordEdit = useCallback(
    async (id: string | number) => {
      const objectId = Number(String(id).split("-")[0]);
      const selectedObject =
        productsList?.find((product) => product.id === objectId) ?? undefined;
      const selectedLocationObject =
        locationsProducts?.find((locProduct) => locProduct.product.id === id) ??
        undefined;

      if (
        selectedObject ||
        (selectedLocationObject && selectedLocationObject?.product)
      ) {
        const res = await singleProduct(
          selectedLocationObject?.product?.id
            ? selectedLocationObject.product.id
            : selectedObject?.id
        );
        setObjectModal({
          mode: "edit",
          defaultValues: res,
        });
        setSelectedTableItem(res?.product);
      }
    },
    [productsList, singleProduct, setSelectedTableItem, locationsProducts]
  );

  const handleRecordSelect = useCallback(
    (id?: string | number) => {
      const objectId = Number(String(id).split("-")[0]);
      navigate(`${objectsUrl}/${objectId}`);
    },
    [navigate]
  );

  return {
    objectModal,
    setObjectModal,
    setIsSidebarVisible,
    selectedTableItem,
    setSelectedTableItem,
    isSidebarVisible,
    handleObjectModalClose,
    handleRecordPreview,
    handleRecordEdit,
    handleRecordSelect,
  };
};
