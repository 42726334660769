import React from "react";

const ChoiceIcon = () => {
  return (
    <svg
      className="choice-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0656 8.00389L11.25 7.99875H18.75C20.483 7.99875 21.8992 9.3552 21.9949 11.0643L22 11.2487V12.8096C21.5557 12.3832 21.051 12.0194 20.5 11.7322V11.2487C20.5 10.2822 19.7165 9.49875 18.75 9.49875H11.25C10.3318 9.49875 9.57881 10.2059 9.5058 11.1052L9.5 11.2487V18.7487C9.5 19.6669 10.2071 20.4199 11.1065 20.4929L11.25 20.4987H11.7316C12.0186 21.0497 12.3822 21.5544 12.8084 21.9987H11.25C9.51697 21.9987 8.10075 20.6423 8.00514 18.9332L8 18.7487V11.2487C8 9.51571 9.35645 8.0995 11.0656 8.00389ZM15.5818 4.23284L15.6345 4.40964L16.327 6.998H14.774L14.1856 4.79787C13.9355 3.86431 12.9759 3.31029 12.0423 3.56044L4.79787 5.50158C3.91344 5.73856 3.36966 6.61227 3.52756 7.49737L3.56044 7.64488L5.50158 14.8893C5.69372 15.6064 6.30445 16.0996 7.00045 16.1764L7.00056 17.6816C5.69932 17.6051 4.52962 16.7445 4.10539 15.4544L4.05269 15.2776L2.11155 8.03311C1.66301 6.35913 2.6067 4.6401 4.23284 4.10539L4.40964 4.05269L11.6541 2.11155C13.3281 1.66301 15.0471 2.6067 15.5818 4.23284ZM23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5ZM17.4101 14.0073L17.5 13.9992L17.5899 14.0073C17.794 14.0443 17.9549 14.2053 17.9919 14.4094L18 14.4992L17.9996 16.9992L20.5046 17L20.5944 17.0081C20.7985 17.0451 20.9595 17.206 20.9965 17.4101L21.0046 17.5L20.9965 17.5899C20.9595 17.794 20.7985 17.9549 20.5944 17.9919L20.5046 18L18.0007 17.9992L18.0011 20.5035L17.9931 20.5934C17.956 20.7975 17.7951 20.9584 17.591 20.9954L17.5011 21.0035L17.4112 20.9954C17.2071 20.9584 17.0462 20.7975 17.0092 20.5934L17.0011 20.5035L17.0007 17.9992L14.4977 18L14.4078 17.9919C14.2037 17.9549 14.0427 17.794 14.0057 17.5899L13.9977 17.5L14.0057 17.4101C14.0427 17.206 14.2037 17.0451 14.4078 17.0081L14.4977 17L16.9996 16.9992L17 14.4992L17.0081 14.4094C17.0451 14.2053 17.206 14.0443 17.4101 14.0073Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChoiceIcon;
