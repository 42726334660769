import { ArrowSort20Regular } from "@fluentui/react-icons";
import React, { useContext } from "react";

import { DEFAULT_MEDIA_UUID } from "../../../contants/Enums";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { useCheckMobile } from "../../../hooks/useCheckMobile";
import { getMediaUri } from "../../../utils/index.utils";
import { ImageBlind } from "../../image-blind";
import { Text } from "../../text";
import { OrganisationSidebarInfoProps } from "./index.props";
import "./styles.scss";

export const OrganisationSidebarInfo = ({
  onClick,
  isCollapsed,
}: OrganisationSidebarInfoProps) => {
  const { token } = useContext(AppUserContext);
  const { selectedOrganisation } = useContext(OrganisationContext);
  const { isMobile } = useCheckMobile();
  const displayContent = isMobile ? isCollapsed : !isCollapsed;

  return (
    <div
      className={`organization-sidebar-info ${
        !isMobile && isCollapsed ? "organization-sidebar-info--collapsed" : ""
      }`}
      onClick={onClick}
    >
      <div className="organization-sidebar-info__content">
        {selectedOrganisation?.image &&
        selectedOrganisation?.image !== DEFAULT_MEDIA_UUID ? (
          <img
            className="organization-sidebar-info__img"
            src={getMediaUri(selectedOrganisation?.image, token)}
          />
        ) : (
          <ImageBlind
            className="organization-sidebar-info__img"
            name={selectedOrganisation?.name ?? ""}
          />
        )}
        {displayContent && (
          <Text
            text={selectedOrganisation?.name ?? ""}
            fontWeight={500}
            size="sm"
          />
        )}
      </div>
      {displayContent && <ArrowSort20Regular />}
    </div>
  );
};
