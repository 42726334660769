import { useCallback, useMemo } from "react";

import { InputSelectEl } from "../../../input-select/InputSelect";
import { DropdownTabsProps } from "./DropdownTabs.props";

export function DropdownTabs({
  tabs = [],
  activeTab,
  setActiveTab,
  onClick,
}: DropdownTabsProps) {
  const options = useMemo(() => {
    return tabs.map((tab) => ({ value: tab.text, label: tab.text }));
  }, [tabs]);

  const handleChange = useCallback(
    (value: string | number | boolean | null) => {
      if (onClick) onClick(value as string);
      setActiveTab(value as string);
    },
    [onClick, setActiveTab]
  );

  return (
    <InputSelectEl
      id={"single"}
      label={null}
      items={options}
      value={activeTab}
      onChange={handleChange}
    />
  );
}
