import Row from "@avinet/adaptive-ui-core/layout/Row";
import { t } from "i18next";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  ILocationProperties,
  IMediaFeature,
  IObjectResponse,
} from "../../../api/types";
import { OBJECT_STATUS } from "../../../contants/Enums";
import { locationsUrl } from "../../../contants/Routes";
import { DictionaryContext } from "../../../context/dictionary-context/DictionaryContext";
import { useObjConditionQuantity } from "../../../hooks/useObjConditionQuantity";
import { handleTranslateQuantityUnit } from "../../../utils/index.utils";
import { FeatureImagePreview } from "../../feature-image-preview";
import { ObjectStatus } from "../../object/object-status";
import { Text } from "../../text";
import "../styles.scss";

export function ObjectsPreview({
  objectDetails,
  attachmentsList,
  productLocation,
}: {
  objectDetails: IObjectResponse | undefined;
  productLocation: ILocationProperties | undefined;
  attachmentsList: IMediaFeature[];
}) {
  const { getCategoryName, getParentCategoryName, getElementName } =
    useContext(DictionaryContext);
  const {
    allGoodQuantity,
    allMediumQuantity,
    allBadQuantity,
    allNoConditionQuantity,
  } = useObjConditionQuantity(objectDetails);
  const navigate = useNavigate();
  const { categoryName, parentCategoryName, elementName } = useMemo(() => {
    let category;
    let parentCategory;
    let element;

    if (objectDetails) {
      category = getCategoryName(objectDetails.product.category);
      parentCategory = getParentCategoryName(
        objectDetails.product.parent_category
      );
      element = getElementName(objectDetails.product.element);
    }

    return {
      categoryName: category || "-",
      parentCategoryName: parentCategory || "-",
      elementName: element || "-",
    };
  }, [getCategoryName, getElementName, getParentCategoryName, objectDetails]);

  const objectData = useMemo(
    () => [
      {
        title: t("components.objectModal.aside.building"),
        description: productLocation?.name,
        featureId: productLocation?.id,
        isLink: true,
      },
      {
        title: t("components.objectModal.form.labels.unit"),
        description: handleTranslateQuantityUnit(
          objectDetails?.product?.quantity_unit as string
        ),
      },
      {
        title: t("components.objectModal.form.labels.otherCategory"),
        description: parentCategoryName,
      },
      {
        title: t("components.objectModal.form.labels.category"),
        description: categoryName,
      },
      {
        title: t("components.objectModal.form.labels.element"),
        description: elementName,
      },
      {
        title: t("components.objectModal.form.labels.height"),
        description: objectDetails?.product?.height,
      },
      {
        title: t("components.objectModal.form.labels.width"),
        description: objectDetails?.product?.width,
      },
      {
        title: t("components.objectModal.form.labels.length"),
        description: objectDetails?.product?.length,
      },
      {
        title: t("components.objectModal.form.labels.diameter"),
        description: objectDetails?.product?.diameter,
      },
      {
        title: t("components.objectModal.form.labels.weight"),
        description: objectDetails?.product?.weight,
      },
      {
        title: t("components.objectModal.form.labels.producer"),
        description: objectDetails?.product?.producer,
      },
      {
        title: t("components.objectModal.form.labels.productionYear"),
        description: objectDetails?.product?.production_year,
      },
      {
        title: t("components.objectModal.form.labels.cost"),
        description: objectDetails?.product?.cost,
      },
      {
        title: t("components.objectModal.form.labels.co2"),
        description: objectDetails?.product?.co2,
      },
      {
        title: t("components.objectModal.form.labels.additionalComment"),
        description: objectDetails?.product?.additional_comments,
      },
    ],
    [
      objectDetails,
      categoryName,
      parentCategoryName,
      elementName,
      productLocation,
    ]
  );

  return (
    <div className="feature-preview">
      <div className="feature-preview__left">
        <Text
          text={t("pages.objectsPreview.details")}
          size="medium"
          fontWeight={700}
          className="feature-preview__description-title"
        />
        <Row className="feature-preview__obj-condition-wrapper">
          {allGoodQuantity > 0 && (
            <ObjectStatus
              text={OBJECT_STATUS.GOOD}
              status={OBJECT_STATUS.GOOD}
              withAmount={allGoodQuantity}
            />
          )}
          {allMediumQuantity > 0 && (
            <ObjectStatus
              text={OBJECT_STATUS.MEDIUM}
              status={OBJECT_STATUS.MEDIUM}
              withAmount={allMediumQuantity}
            />
          )}
          {allBadQuantity > 0 && (
            <ObjectStatus
              text={OBJECT_STATUS.BAD}
              status={OBJECT_STATUS.BAD}
              withAmount={allBadQuantity}
            />
          )}
          {allNoConditionQuantity > 0 && (
            <ObjectStatus
              text={OBJECT_STATUS.NO_CONDITION}
              status={OBJECT_STATUS.NO_CONDITION}
              withAmount={allNoConditionQuantity}
            />
          )}
        </Row>
        <div className="feature-preview__table">
          {objectData.map(
            ({ title, description, isLink, featureId }, index) => {
              return (
                <div className="feature-preview__table-item" key={index}>
                  <Text
                    text={`${title}:`}
                    fontWeight={500}
                    size="small"
                    tag="span"
                  />
                  {isLink && featureId !== undefined ? (
                    <span
                      className="feature-preview__table-item__right link"
                      onClick={() => {
                        navigate(`${locationsUrl}/${featureId}`, {
                          state: {
                            featureId: featureId,
                          },
                        });
                      }}
                    >
                      {" "}
                      {typeof description === "number"
                        ? description
                        : description || "-"}
                    </span>
                  ) : (
                    <Text
                      text={description ? String(description) : "-"}
                      fontWeight={500}
                      size="small"
                      tag="span"
                    />
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className="feature-preview__right">
        {/* TODO: hide tabs until reuse map will be ready  */}
        {/*<HorizontalTabs*/}
        {/*  tabs={tabs}*/}
        {/*  activeTab={activeTab}*/}
        {/*  setActiveTab={setActiveTab}*/}
        {/*  disabledIndex={1}*/}
        {/*/>*/}

        <FeatureImagePreview
          imagesList={attachmentsList}
          thumbnailUuid={objectDetails?.product.image}
        />

        {objectDetails?.product?.description?.length ? (
          <div className="feature-preview__description">
            <Text
              text={t("components.locationsPreview.descriptionTitle")}
              tag="span"
              fontWeight={700}
              color="dark"
              size="medium"
            />
            <Text
              text={objectDetails?.product?.description}
              tag="span"
              color="dark"
              size="md"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
