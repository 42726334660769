/* eslint-disable */
import { ApiResponse } from "apisauce";

import { KIND_RESPONSE, getGeneralApiProblem } from "../api/api-problem";
import * as Types from "../api/types";
import {
  AddUserToOrganisationRequest,
  AddUserToOrganisationResponse,
  OrganisationsMembersResponse,
  UpdateUserRoleRequest,
} from "../api/types";
import { FEATURE_UUID } from "../contants/FeatureUuid";
import { Api } from "./api/api";

export class OrganisationsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getOrganisations(): Promise<Types.OrganisationsResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `features/${FEATURE_UUID.ORGANIZATION}/json`
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async addUserToOrganisation(
    body: AddUserToOrganisationRequest
  ): Promise<AddUserToOrganisationResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "ombruk/organization/invite",
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async addOrganisation(
    body: Types.AddOrganisationRequest
  ): Promise<Types.AddOrganisationsResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      "ombruk/organization/organization",
      body
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async getOrganisationMembers(
    organisationId: number | null | undefined
  ): Promise<Types.OrganisationsMembersResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `ombruk/organization/members/${organisationId}`
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async updateUserRole(body: UpdateUserRoleRequest) {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `ombruk/organization/updateRole`,
      body
    );
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const data = response.data;
      return { kind: KIND_RESPONSE.OK, data: data };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  // Using fetch here, as apisauce does not work for some reason.
  async getMemberProfileImage(memberUuid: string): Promise<Blob | undefined> {
    const imageUrl = `${this.api.config.url}/profile/${memberUuid}/icon.jpg`;

    try {
      const r = await fetch(imageUrl);

      if (!r.ok) {
        if (r.status === 404) {
          return undefined;
        }
        throw new Error(`Failed to fetch image. Status: ${r.status}`);
      }

      const data = await r.arrayBuffer();

      // Convert the binary data to a Blob
      const blob = new Blob([data], {
        type: r.headers.get("Content-Type") as string,
      });

      return blob;
    } catch (error) {
      console.error(`Error fetching image for member ${memberUuid}:`, error);
      return undefined;
    }
  }

  async joinOrganisation(inviteCode: string) {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `ombruk/organization/join?inviteCode=${inviteCode}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      const status = response.data;
      return { kind: KIND_RESPONSE.OK, data: status };
    } catch (error) {
      console.log(`Request error: ${error}`);
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
  }

  async updatePaymentPlan(
    memberLimit: number,
    yearlyPayment: boolean,
    orgId: number
  ) {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `ombruk/organization/memberlimit/${orgId}`,
      { member_limit: memberLimit, yearly_payment: yearlyPayment }
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
    return { kind: KIND_RESPONSE.OK, data: data };
  }

  async updateOrganisation(orgId: number, body: any) {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      `ombruk/organization/${orgId}`,
      body
    );

    const { ok, data } = response;

    if (!ok || !data) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      return { kind: KIND_RESPONSE.BAD_DATA };
    }
    return { kind: KIND_RESPONSE.OK, data: data };
  }
}

/* eslint-enable */
