import React, { FC } from "react";

import { SidebarPage } from "../../components/sidebar/sidebar-page";

export const Notifications: FC = () => {
  return (
    <SidebarPage>
      <p>Welcome to notifications page</p>
    </SidebarPage>
  );
};
