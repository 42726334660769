import React, { FC } from "react";

const RocketCloudIcon: FC = () => {
  return (
    <svg
      width="220"
      height="47"
      viewBox="0 0 220 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.3327 48.3511L0 48.4218V116H298V48.9615H210.36C209.956 38.4174 201.319 29.9948 190.722 29.9948C187.875 29.9948 185.173 30.6089 182.731 31.6994C181.47 26.619 176.911 22.8488 171.462 22.8488C171.459 22.8488 171.455 22.8488 171.451 22.8488C171.444 22.6366 171.429 22.4282 171.403 22.2235C171.44 21.9295 171.462 21.6317 171.462 21.3302C171.462 17.4409 168.323 14.2847 164.446 14.2847C163.56 14.2847 162.715 14.4559 161.932 14.7574C161.532 12.0665 159.627 9.87056 157.095 9.0778L153.667 -0.00732422H148.11L144.745 8.91403C144.311 8.81354 143.863 8.75772 143.399 8.75772C140.074 8.75772 137.376 11.4635 137.376 14.8058C137.376 15.1072 137.406 15.3976 137.45 15.6879C137.439 15.7549 137.428 15.8181 137.42 15.8851C136.449 15.5241 135.408 15.3157 134.311 15.3157C129.366 15.3157 125.355 19.3353 125.344 24.3003C125.036 24.2482 124.721 24.2147 124.395 24.2147C122.079 24.2147 120.088 25.5918 119.176 27.5718C116.074 25.5174 112.363 24.3226 108.371 24.3226C97.5146 24.3226 88.715 33.1584 88.715 44.0598C88.715 44.3501 88.7261 44.6367 88.7372 44.9233C88.7335 45.0163 88.7335 45.1131 88.7298 45.2061C87.7031 43.6206 85.9313 42.571 83.9075 42.571C80.7309 42.571 77.873 45.716 77.873 48.9057"
        fill="#D7D8F7"
      />
    </svg>
  );
};

export default RocketCloudIcon;
