import React, { FC } from "react";

const TextIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 1C0.75 0.447715 1.19772 0 1.75 0H14.25C14.8023 0 15.25 0.447715 15.25 1V3C15.25 3.55228 14.8023 4 14.25 4C13.6977 4 13.25 3.55228 13.25 3V2H9L9 14H10C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16H6C5.44772 16 5 15.5523 5 15C5 14.4477 5.44772 14 6 14H7L7 2H2.75V3C2.75 3.55228 2.30228 4 1.75 4C1.19772 4 0.75 3.55228 0.75 3V1Z"
        fill="#6239D9"
      />
    </svg>
  );
};

export default TextIcon;
