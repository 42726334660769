import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { t } from "i18next";
import { useCallback } from "react";

import { IProjectFilter } from "../../context/filter-context/FilterContext";
import { useSelectOptions } from "../../hooks/useSelectOptions";
import { FeatureModalHeader } from "../feature/feature-modal-header";
import { FeatureModalWrapper } from "../feature/feature-modal-wrapper";
import { InputSelect } from "../input-select/InputSelect";
import { Modal } from "../modal";
import { SubmitButtons } from "../submit-buttons";
import "./FilterModal.scss";

export function ProjectFilterModal({
  onClose,
  projectFilter,
  showFilterModal,
  onApplyFilter: handleProjectFilter,
  showStatusFilter,
}: {
  onClose: () => void;
  projectFilter: Partial<IProjectFilter> | undefined;
  showFilterModal: boolean;
  onApplyFilter: (filter: IProjectFilter) => void;
  showStatusFilter?: boolean;
}) {
  const { projectStatus } = useSelectOptions();

  const filterStatusPlaceholder = t("components.projectFilterModal.statushelp");

  const handleSubmit = useCallback(
    (data: IProjectFilter) => {
      handleProjectFilter(data);

      onClose();
    },
    [handleProjectFilter, onClose]
  );

  const { form, state } = useForm({
    defaultValues: projectFilter,
    onSubmit: handleSubmit,
  });

  const handleClearStatus = useCallback(() => {
    form.setValue("status", undefined);
  }, [form]);

  const handleClearStartEndDate = useCallback(() => {
    form.setValue("start_date", undefined);
    form.setValue("end_date", undefined);
  }, [form]);

  const handleClearCreationDate = useCallback(() => {
    form.setValue("creation_start_date", undefined);
    form.setValue("creation_end_date", undefined);
  }, [form]);

  const handleClearLocations = useCallback(() => {
    form.setValue("locations", undefined);
  }, [form]);

  // TODO: DISABLED UNTIL WE FIND A WAY TO COMPARE LIST OF LOCATIONS
  // const handleLocationChange = useCallback(
  //   (value: MultiValue<ISelectedOption>) => {
  //     const newValue = value.map((v) => v.value);
  //     form.setValue("locations", newValue);
  //   },
  //   [form]
  // );

  const resetForm = useCallback(() => {
    handleClearStatus();
    handleClearStartEndDate();
    handleClearCreationDate();
    handleClearLocations();
  }, [
    handleClearCreationDate,
    handleClearLocations,
    handleClearStartEndDate,
    handleClearStatus,
  ]);

  // TODO: DISABLED UNTIL WE FIND A WAY TO COMPARE LIST OF LOCATIONS
  // const formLocations = form.getValue("locations") as unknown as number[];

  // TODO: DISABLED UNTIL WE FIND A WAY TO COMPARE LIST OF LOCATIONS
  // const locations = useMemo(() => {
  //   return [...selectLocationsName].filter((l) =>
  //     formLocations?.includes(l.value)
  //   );
  // }, [formLocations, selectLocationsName]);

  return (
    <Modal className="filter-modal" show={showFilterModal}>
      <FeatureModalWrapper>
        <FeatureModalHeader
          modalState={onClose}
          onClose={onClose}
          title={t("components.projectFilterModal.headerMain")}
          subtitle={""}
        />
        <FormProvider form={form} state={state}>
          <form
            className={`form-container`}
            id="project-form"
            onSubmit={form.submit}
            translate={undefined}
          >
            {showStatusFilter && (
              <FeatureModalWrapper classNames="wrapper-content">
                <section className="feature-modal-drop" id="attachments">
                  <div className="header-container">
                    <h4>{t("components.projectFilterModal.status")}</h4>
                    <button
                      className="btn purple"
                      type="button"
                      onClick={handleClearStatus}
                    >
                      {t("components.projectFilterModal.clear")}
                    </button>
                  </div>
                  <InputSelect
                    id="status"
                    items={projectStatus}
                    label=""
                    placeholder={filterStatusPlaceholder}
                  />
                </section>
              </FeatureModalWrapper>
            )}

            <FeatureModalWrapper classNames="wrapper-content">
              <section className="feature-modal-drop" id="attachments">
                <div className="header-container">
                  <h4>{t("components.projectFilterModal.startandend")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearStartEndDate}
                  >
                    {t("components.projectFilterModal.clear")}
                  </button>
                </div>
                <div className="date-container">
                  <div className="aui-form-control aui-text">
                    <Input
                      type="date"
                      id="start_date"
                      max="2999-12-31"
                      defaultValue={state.start_date ?? ""}
                      placeholder={"placeholders.startDate"}
                      label={t("components.projectFilterModal.from")}
                    />
                  </div>

                  <div className="aui-form-control aui-text">
                    <Input
                      type="date"
                      id="end_date"
                      max="2999-12-31"
                      defaultValue={state.end_date ?? ""}
                      placeholder={"placeholders.endDate"}
                      label={t("components.projectFilterModal.to")}
                    />
                  </div>
                </div>
              </section>{" "}
            </FeatureModalWrapper>
            <FeatureModalWrapper classNames="wrapper-content">
              <section className="feature-modal-drop" id="attachments">
                <div className="header-container">
                  <h4>{t("components.projectFilterModal.creationdate")}</h4>
                  <button
                    className="btn purple"
                    type="button"
                    onClick={handleClearCreationDate}
                  >
                    {t("components.projectFilterModal.clear")}
                  </button>
                </div>
                <div className="date-container">
                  <div className="aui-form-control aui-text">
                    <Input
                      type="date"
                      id="creation_start_date"
                      max="2999-12-31"
                      defaultValue={state.creation_start_date ?? ""}
                      placeholder={"placeholders.creationStartDate"}
                      label={t("components.projectFilterModal.from")}
                    />
                  </div>

                  <div className="aui-form-control aui-text">
                    <Input
                      type="date"
                      id="creation_end_date"
                      max="2999-12-31"
                      defaultValue={state.creation_end_date ?? ""}
                      placeholder={"placeholders.creationEndDate"}
                      label={t("components.projectFilterModal.to")}
                    />
                  </div>
                </div>
              </section>{" "}
            </FeatureModalWrapper>
            {/* TODO: disabled until filter allows for array comparison*/}
            {/* <FeatureModalWrapper classNames="wrapper-content">
              <section>
                <div className="header-container">
                  <h4>{t("components.projectFilterModal.locations")}</h4>
                  <button className="btn purple" onClick={handleClearLocations}>
                    {t("components.projectFilterModal.clear")}
                  </button>
                </div>
                <InputMultiSearchSelect
                  id="locations-selector"
                  options={selectLocationsName}
                  label={t("components.projectFilterModal.connectedLocations")}
                  placeholder={" "}
                  onChange={handleLocationChange}
                  value={locations}
                />
              </section>
            </FeatureModalWrapper> */}
          </form>
          <div className="filter-modal-buttons">
            <SubmitButtons
              onCancel={resetForm}
              submitText={t("components.projectFilterModal.apply")}
              cancelText={t("components.projectFilterModal.reset")}
              formId="project-form"
              resetMode
            />
          </div>
        </FormProvider>
      </FeatureModalWrapper>
    </Modal>
  );
}
