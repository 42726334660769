import React, { FC } from "react";

const SecurityShieldIcon: FC = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.30201 20.6152C8.5234 20.7444 8.6341 20.809 8.79032 20.8425C8.91156 20.8685 9.08844 20.8685 9.20968 20.8425C9.3659 20.809 9.4766 20.7444 9.69799 20.6152C11.646 19.4788 17 15.9088 17 11.0004V6.21796C17 5.41845 17 5.01869 16.8692 4.67506C16.7537 4.3715 16.566 4.10064 16.3223 3.88589C16.0465 3.6428 15.6722 3.50243 14.9236 3.22171L9.5618 1.21103C9.3539 1.13307 9.24995 1.09409 9.14302 1.07864C9.04816 1.06493 8.95184 1.06493 8.85698 1.07864C8.75005 1.09409 8.6461 1.13307 8.4382 1.21103L3.0764 3.22171C2.3278 3.50243 1.9535 3.6428 1.67766 3.88589C1.43398 4.10064 1.24627 4.3715 1.13076 4.67506C1 5.01869 1 5.41845 1 6.21796V11.0004C1 15.9088 6.35396 19.4788 8.30201 20.6152Z"
        stroke="#6239D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SecurityShieldIcon;
