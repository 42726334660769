import { SVGProps } from "react";

export const PdfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_11044_585)">
      <path
        d="M19 21H5C3.8955 21 3 20.1045 3 19V5C3 3.8955 3.8955 3 5 3H19C20.1045 3 21 3.8955 21 5V19C21 20.1045 20.1045 21 19 21Z"
        fill="#B30B00"
      />
      <path
        d="M17.6205 13.3995C16.7745 12.521 14.4635 12.879 13.9105 12.944C13.097 12.163 12.5435 11.219 12.3485 10.8935C12.6415 10.015 12.8365 9.136 12.869 8.1925C12.869 7.379 12.5435 6.5 11.6325 6.5C11.307 6.5 11.014 6.6955 10.8515 6.9555C10.461 7.639 10.6235 9.006 11.242 10.405C10.884 11.414 10.5585 12.39 9.6475 14.115C8.7035 14.5055 6.7185 15.417 6.556 16.393C6.491 16.686 6.5885 16.979 6.8165 17.2065C7.044 17.4025 7.3365 17.5 7.6295 17.5C8.8335 17.5 10.005 15.84 10.819 14.441C11.5025 14.213 12.5765 13.8875 13.6505 13.6925C14.9195 14.799 16.026 14.9615 16.612 14.9615C17.393 14.9615 17.686 14.636 17.7835 14.343C17.946 14.018 17.8485 13.66 17.6205 13.3995ZM16.807 13.9525C16.7745 14.1805 16.4815 14.408 15.961 14.278C15.3425 14.1155 14.7895 13.8225 14.301 13.432C14.724 13.367 15.668 13.2695 16.3515 13.3995C16.612 13.4645 16.872 13.627 16.807 13.9525ZM11.372 7.2485C11.437 7.151 11.5345 7.086 11.6325 7.086C11.9255 7.086 11.9905 7.444 11.9905 7.737C11.958 8.4205 11.828 9.104 11.6 9.755C11.112 8.4525 11.2095 7.5415 11.372 7.2485ZM11.307 13.562C11.5675 13.0415 11.9255 12.13 12.0555 11.7395C12.3485 12.2275 12.8365 12.8135 13.097 13.074C13.097 13.1065 12.088 13.302 11.307 13.562ZM9.387 14.864C8.6385 16.1005 7.8575 16.882 7.4345 16.882C7.3695 16.882 7.3045 16.8495 7.239 16.817C7.1415 16.752 7.109 16.6545 7.1415 16.524C7.239 16.068 8.085 15.4495 9.387 14.864Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_11044_585">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
