import { useMemo } from "react";

import { IObjectResponse, IProductWithMappings } from "../api/types";
import { OBJECT_STATUS } from "../contants/Enums";

export const useObjConditionQuantity = (
  objectDetails?: IObjectResponse | IProductWithMappings
) => {
  // sort by condition (to make sure the mapping images are always the same)
  objectDetails?.productLocationMappings?.sort((a, b) => {
    const conditionA = a.obj_condition.toUpperCase();
    const conditionB = b.obj_condition.toUpperCase();
    if (conditionA < conditionB) {
      return -1;
    }
    if (conditionA > conditionB) {
      return 1;
    }
    return 0;
  });

  const allGoodQuantity = useMemo(
    () =>
      objectDetails?.productLocationMappings
        ?.filter((mapping) => mapping.obj_condition === OBJECT_STATUS.GOOD)
        .reduce(
          (prev, current) =>
            current?.sum ? prev + current?.sum : prev + current?.quantity ?? 0,
          0
        ) ?? 0,
    [objectDetails]
  );
  const allMediumQuantity = useMemo(
    () =>
      objectDetails?.productLocationMappings
        ?.filter((mapping) => mapping.obj_condition === OBJECT_STATUS.MEDIUM)
        .reduce(
          (prev, current) =>
            current?.sum ? prev + current?.sum : prev + current?.quantity ?? 0,
          0
        ) ?? 0,
    [objectDetails]
  );
  const allBadQuantity = useMemo(
    () =>
      objectDetails?.productLocationMappings
        ?.filter((mapping) => mapping.obj_condition === OBJECT_STATUS.BAD)
        .reduce(
          (prev, current) =>
            current?.sum ? prev + current?.sum : prev + current?.quantity ?? 0,
          0
        ) ?? 0,
    [objectDetails]
  );
  const allNoConditionQuantity = useMemo(
    () =>
      objectDetails?.productLocationMappings
        ?.filter(
          (mapping) =>
            mapping.obj_condition === "" ||
            mapping.obj_condition === null ||
            mapping.obj_condition === OBJECT_STATUS.NO_CONDITION
        )
        .reduce(
          (prev, current) =>
            current?.sum ? prev + current?.sum : current?.quantity,
          0
        ) ?? 0,
    [objectDetails]
  );

  return {
    allGoodQuantity,
    allMediumQuantity,
    allBadQuantity,
    allNoConditionQuantity,
  };
};
