import { FC, useCallback } from "react";

import { TextProps } from "./index.props";
import "./styles.scss";

export const Text: FC<TextProps> = ({
  text,
  fontWeight,
  className,
  size,
  color,
  tag,
  children,
}) => {
  const textBold = useCallback(() => {
    return fontWeight ? `text--bold--${fontWeight}` : "";
  }, [fontWeight]);

  const textSize = useCallback(() => {
    return size ? `text--size--${size}` : "";
  }, [size]);

  const textColor = useCallback(() => {
    return color ? `text--color--${color}` : "";
  }, [color]);

  const renderText = useCallback(() => {
    switch (tag) {
      case "span":
        return (
          <span
            className={`text ${
              className ? className : ""
            } ${textSize()} ${textBold()} ${textColor()}`}
          >
            {text} {children ? children : null}
          </span>
        );
      default:
        return (
          <p
            className={`text ${
              className ? className : ""
            } ${textSize()} ${textBold()} ${textColor()}`}
          >
            {text} {children ? children : null}
          </p>
        );
    }
  }, [tag, className, textSize, textBold, text, textColor, children]);

  return <>{renderText()}</>;
};
