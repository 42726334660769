import Column from "@avinet/adaptive-ui-core/layout/Column";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";

import { FeatureModalCreatedButtonProps } from "./index.props";

export const FeatureModalCreatedButton = ({
  icon,
  title,
  subtitle,
  className = "",
  onClick,
}: FeatureModalCreatedButtonProps) => {
  return (
    <button className={"option-btn " + className} onClick={onClick}>
      <Icon name={icon} />
      <Column align="left">
        <span>{title}</span>
        <span className="subtitle">{subtitle}</span>
      </Column>
      <Icon name="chevron" className="chevron" />
    </button>
  );
};
