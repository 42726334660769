import React from "react";

import loginBanner from "../../../assets/images/login-bg/login-bg.png";
import { RegisterContent } from "../register-content";
import { ImageBannerProps } from "./index.props";
import "./styles.scss";

export const ImageBanner = ({ title, description }: ImageBannerProps) => {
  return (
    <RegisterContent className="login-banner">
      <div className="login-banner__image">
        <img src={loginBanner} alt="login-banner" />
      </div>
      <div className="login-banner__text">
        <h1 className="login-banner__text__title">{title}</h1>
        <p className="login-banner__text__description">{description}</p>
      </div>
    </RegisterContent>
  );
};
