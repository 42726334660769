import { SVGProps } from "react";

export const ArrowExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#4A5578"
      d="M19.646 12.146a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1-.708.708L20.5 13.707V24.5a.5.5 0 0 1-1 0V13.707l-3.146 3.147a.5.5 0 0 1-.708-.708l4-4ZM14 27.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5Z"
    />
  </svg>
);
