import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { FC } from "react";
import { useActiveMenu } from "react-active-menu";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { userProfileMenu } from "../../contants/MockData";
import { Text } from "../text";
import { ProfilePolicy } from "./profile-policy";
import { ProfilePreferences } from "./profile-preferences";
import { ProfileSecurity } from "./profile-security";
import "./styles.scss";
import { ProfileDetails } from "./user-profile/";

export const UserDetails: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { registerContainer, registerSection, registerTrigger } = useActiveMenu(
    {
      smooth: true,
      offset: 40,
    }
  );

  return (
    <section className="user-settings" id="user-profile">
      <div className="menu">
        {location?.state?.withPrevButton && (
          <div className="user-settings__back" onClick={() => navigate(-1)}>
            <Icon name="arrow" rotate={180} />
            <Text text={t("common.backToOrg")} fontWeight={600} size="sm" />
          </div>
        )}
        <ul>
          {userProfileMenu.map((item, index) => (
            <li className="menu__item" key={index}>
              <button
                className="menu__item__link"
                ref={registerTrigger(item.id)}
              >
                {t(`${item.text}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="user-settings__details" ref={registerContainer}>
        <section ref={registerSection("profile")}>
          <ProfileDetails />
        </section>
        <section ref={registerSection("security")}>
          <ProfileSecurity />
        </section>
        <section ref={registerSection("preferences")}>
          <ProfilePreferences />
        </section>
        <section ref={registerSection("policy")}>
          <ProfilePolicy />
        </section>
      </div>
    </section>
  );
};
