import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperRefType } from "swiper/types";

import { KIND_RESPONSE } from "../../../api/api-problem";
import { IUserDetails } from "../../../api/types";
import {
  CHILDREN_FLOW,
  USER_PROFILE_MODAL_TYPE,
} from "../../../contants/Enums";
import { emailRegex } from "../../../contants/Patterns";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { convertToInitials } from "../../../utils/index.utils";
import { Heading } from "../../heading";
import { InputPhone } from "../../input-phone";
import { VerifyCodeInput } from "../../verify-code-input";
import { ProfileCard } from "../profile-card";
import { ProfileInfo } from "../profile-info";
import { ProfileModal } from "../profile-modal";
import "./styles.scss";

export const ProfileDetails: FC = () => {
  const { userProfile, updateUser, updateUserImage, verifyBegin, verify } =
    useContext(AppUserContext);
  const { t } = useTranslation();
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [changeEmailStep, setChangeEmailStep] = useState<number>(0);
  const [profileTypeModal, setProfileTypeModal] = useState<
    USER_PROFILE_MODAL_TYPE | undefined
  >(undefined);
  const [modalTextData, setModalTextData] = useState({
    icon: "textIcon",
    title: t("components.profileUpdateModal.titleName"),
    description: t("components.profileUpdateModal.titleNameDescription"),
    submitButtonText: t("common.changeName"),
  });

  const displayUserAddress = () => {
    if (
      userProfile?.userData?.address_street &&
      userProfile?.userData?.address_post_code &&
      userProfile?.userData?.address_city
    ) {
      return `${userProfile?.userData?.address_street}, ${userProfile?.userData?.address_post_code}, ${userProfile?.userData?.address_city}`;
    } else {
      return "-";
    }
  };

  const formLabels = useMemo(
    () => ({
      firstNameLabel: `${t("form.firstName")}`,
      middleNameLabel: `${t("form.middleName")}`,
      lastNameLabel: `${t("form.lastName")}`,
      streetLabel: `${t(
        "pages.profile.headings.profile.info.changeAddress.street"
      )}`,
      zipCodeLabel: `${t(
        "pages.profile.headings.profile.info.changeAddress.zipCode"
      )}`,
      placeLabel: `${t(
        "pages.profile.headings.profile.info.changeAddress.place"
      )}`,
      phoneLabel: `${t(
        "pages.profile.headings.profile.info.changePhone.phoneNumber"
      )}`,
      companyLabel: `${t(
        "pages.profile.headings.profile.info.changeWork.company"
      )}`,
      newEmailLabel: `${t(
        "pages.profile.headings.profile.info.changeEmail.newEmail"
      )}`,
    }),
    [t]
  );

  const formPlaceholders = useMemo(
    () => ({
      firstNamePlaceholder: `${t("form.firstNamePlaceholder")}`,
      middleNamePlaceholder: `${t("form.middleNamePlaceholder")}`,
      lastNamePlaceholder: `${t("form.lastNamePlaceholder")}`,
      streetPlaceholder: `${t(
        "pages.profile.headings.profile.info.changeAddress.streetPlaceholder"
      )}`,
      zipCodePlaceholder: `${t(
        "pages.profile.headings.profile.info.changeAddress.zipCodePlaceholder"
      )}`,
      placePlaceholder: `${t(
        "pages.profile.headings.profile.info.changeAddress.placePlaceholder"
      )}`,
      phonePlaceholder: `${t(
        "pages.profile.headings.profile.info.changePhone.phonePlaceholder"
      )}`,
      companyPlaceholder: `${t(
        "pages.profile.headings.profile.info.changeWork.companyPlaceholder"
      )}`,
    }),
    [t]
  );

  const { form, state } = useForm({
    defaultValues: {
      ...userProfile?.userData,
      email: `${userProfile?.email ? userProfile?.email : ""}`,
      first_name: `${
        userProfile?.userData?.first_name
          ? userProfile?.userData?.first_name
          : ""
      }`,
      middle_name: `${
        userProfile?.userData?.middle_name
          ? userProfile?.userData?.middle_name
          : ""
      }`,
      last_name: `${
        userProfile?.userData?.last_name ? userProfile?.userData?.last_name : ""
      }`,
      address_city: `${
        userProfile?.userData?.address_city
          ? userProfile?.userData?.address_city
          : ""
      }`,
      address_street: `${
        userProfile?.userData?.address_street
          ? userProfile?.userData?.address_street
          : ""
      }`,
      address_post_code: `${
        userProfile?.userData?.address_post_code
          ? userProfile?.userData?.address_post_code
          : ""
      }`,
      phone: `${
        userProfile?.userData?.phone ? userProfile?.userData?.phone : ""
      }`,
      company: `${
        userProfile?.userData?.company ? userProfile?.userData?.company : ""
      }`,
    },
    onSubmit: (data: IUserDetails) => handleSubmitForm(data),
    onErrors: (errors: string | string[]) => handleFormError(errors),
  });

  const handleFormError = (errors: string | string[]) => {
    console.log(errors, "errors");
  };

  const handleSubmitForm = (data: IUserDetails) => {
    updateUser(data)
      .then(() => {
        handleSetProfileModal(undefined);
      })
      .catch(() => {
        console.error(data);
      });
  };

  const [swiperRef, setSwiperRef] = useState<SwiperRefType>();
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState(false);

  const closeEmailModal = useCallback(() => {
    state.email = userProfile?.email;
    setTimeout(() => {
      swiperRef?.slideTo(0);
      setChangeEmailStep(0);
      setVerifyCode("");
    }, 500);
    setShowProfileModal(false);
    setError(null);
  }, [swiperRef, setChangeEmailStep, state, userProfile?.email]);

  const handleVerifyChangeEmail = useCallback(() => {
    verify(verifyCode)
      .then((token) => {
        if (token !== null && token !== undefined) {
          setVerifyCodeError(false);
          updateUser(state, token, USER_PROFILE_MODAL_TYPE.EMAIL)
            .then((res) => {
              if (res && res.kind === KIND_RESPONSE.REJECTED) {
                closeEmailModal();
              } else if (res && res.kind === KIND_RESPONSE.OK) {
                setChangeEmailStep((prev) => (prev !== 3 ? prev + 1 : 3));
                swiperRef?.slideNext();
              }
            })
            .catch(() => {
              console.error("Failed upload email");
            });
        } else {
          setVerifyCodeError(true);
        }
      })
      .catch(() => {
        setVerifyCodeError(true);
        console.error(verifyCode);
      });
  }, [verifyCode, verify, updateUser, state, swiperRef, closeEmailModal]);

  const swiperStyles = useCallback(() => {
    switch (changeEmailStep) {
      case 0:
        return "profile-summary__swiper--first-step";
      case 1:
        return "";
      case 2:
        return "profile-summary__swiper--last-step";
      default:
        return "";
    }
  }, [changeEmailStep]);

  const handleSendCodeToEmail = useCallback(() => {
    if (state.email?.length) {
      if (emailRegex.test(state.email)) {
        setError(null);
        verifyBegin({ email: state.email })
          .then(() => {
            changeEmailStep !== 1 &&
              setChangeEmailStep((prev) => (prev !== 3 ? prev + 1 : 3));
            changeEmailStep !== 1 && swiperRef?.slideNext();
          })
          .catch(() => {
            console.error("Error change email");
          });
      } else {
        setError(t("errors.emailNotValid"));
      }
    }
  }, [state.email, verifyBegin, swiperRef, t, changeEmailStep]);

  const renderEmailModal = useCallback(() => {
    return (
      <div className={`profile-summary__swiper ${swiperStyles()}`}>
        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={1}
          onSlideChange={(slide: SwiperRefType) =>
            setChangeEmailStep(slide.activeIndex)
          }
          pagination={{
            clickable: false,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <Input
              id="email"
              type="email"
              label={formLabels.newEmailLabel}
              required
              className={error?.length ? "input-error" : ""}
            />
          </SwiperSlide>

          <SwiperSlide>
            <VerifyCodeInput
              codeLength={6}
              error={verifyCodeError}
              verifyCodeValue={verifyCode}
              setVerifyCodeValue={setVerifyCode}
              resentCode={handleSendCodeToEmail}
              onClickVerify={handleVerifyChangeEmail}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }, [
    formLabels.newEmailLabel,
    verifyCode,
    setVerifyCode,
    handleVerifyChangeEmail,
    swiperStyles,
    verifyCodeError,
    handleSendCodeToEmail,
    error?.length,
  ]);

  const renderInputs = useCallback(() => {
    switch (profileTypeModal) {
      case USER_PROFILE_MODAL_TYPE.NAME:
        return (
          <>
            <Input
              id="first_name"
              type="text"
              label={formLabels.firstNameLabel}
              placeholder={formPlaceholders.firstNamePlaceholder}
              required
            />
            <Input
              id="middle_name"
              type="text"
              label={formLabels.middleNameLabel}
              placeholder={formPlaceholders.middleNamePlaceholder}
            />
            <Input
              id="last_name"
              type="text"
              label={formLabels.lastNameLabel}
              placeholder={formPlaceholders.lastNamePlaceholder}
              required
            />
          </>
        );
      case USER_PROFILE_MODAL_TYPE.ADDRESS:
        return (
          <>
            <Input
              id="address_street"
              type="text"
              label={formLabels.streetLabel}
              placeholder={formPlaceholders.streetPlaceholder}
              required
            />
            <Input
              id="address_post_code"
              type="text"
              label={formLabels.zipCodeLabel}
              placeholder={formPlaceholders.zipCodePlaceholder}
            />
            <Input
              id="address_city"
              type="text"
              label={formLabels.placeLabel}
              placeholder={formPlaceholders.placePlaceholder}
              required
            />
          </>
        );
      case USER_PROFILE_MODAL_TYPE.PHONE:
        return (
          <InputPhone
            label={formLabels.phoneLabel}
            placeholder={formPlaceholders.phonePlaceholder}
            id="phone"
          />
        );
      case USER_PROFILE_MODAL_TYPE.WORK:
        return (
          <Input
            id="company"
            type="text"
            label={formLabels.companyLabel}
            placeholder={formPlaceholders.companyPlaceholder}
            required
          />
        );
      case USER_PROFILE_MODAL_TYPE.EMAIL:
        return renderEmailModal();
      default:
        return <></>;
    }
  }, [profileTypeModal, formLabels, formPlaceholders, renderEmailModal]);

  const renderEmailModalText = useCallback(() => {
    switch (changeEmailStep) {
      case 0:
        return {
          icon: "envelope",
          title: t("components.profileUpdateModal.titleEmail"),
          description: t("components.profileUpdateModal.titleEmailDescription"),
          submitButtonText: t("common.next"),
        };
      case 1:
        return {
          icon: "envelope",
          title: t("components.profileUpdateModal.titleEmailVerify"),
          description: t(
            "components.profileUpdateModal.titleEmailVerifyDescription",
            {
              email: state.email,
            }
          ),
          submitButtonText: t("common.verify"),
        };
      case 2:
        return {
          icon: "envelope",
          title: t("components.profileUpdateModal.titleEmailChanged"),
          description: t(
            "components.profileUpdateModal.titleEmailChangedDescription"
          ),
          submitButtonText: t("common.confirm"),
        };
      default:
        return {
          icon: "envelope",
          title: t("components.profileUpdateModal.titleEmail"),
          description: t("components.profileUpdateModal.titleEmailDescription"),
          submitButtonText: t("common.next"),
        };
    }
  }, [changeEmailStep, t, state.email]);

  const renderModalText = useCallback(() => {
    switch (profileTypeModal) {
      case USER_PROFILE_MODAL_TYPE.NAME:
        return setModalTextData({
          icon: "textIcon",
          title: t("components.profileUpdateModal.titleName"),
          description: t("components.profileUpdateModal.titleNameDescription"),
          submitButtonText: t("common.changeName"),
        });
      case USER_PROFILE_MODAL_TYPE.ADDRESS:
        return setModalTextData({
          icon: "home",
          title: t("components.profileUpdateModal.titleAddress"),
          description: t(
            "components.profileUpdateModal.titleAddressDescription"
          ),
          submitButtonText: t("components.profileUpdateModal.titleAddress"),
        });
      case USER_PROFILE_MODAL_TYPE.PHONE:
        return setModalTextData({
          icon: "phone",
          title: t("components.profileUpdateModal.titlePhone"),
          description: t("components.profileUpdateModal.titlePhoneDescription"),
          submitButtonText: t("common.changeNumber"),
        });
      case USER_PROFILE_MODAL_TYPE.WORK:
        return setModalTextData({
          icon: "company",
          title: t("pages.profile.headings.profile.info.changeWork.title"),
          description: t(
            "pages.profile.headings.profile.info.changeWork.description"
          ),
          submitButtonText: t("common.save"),
        });
      case USER_PROFILE_MODAL_TYPE.EMAIL:
        return setModalTextData(renderEmailModalText());
      default:
        return setModalTextData({
          icon: "textIcon",
          title: t("components.profileUpdateModal.titleName"),
          description: t("components.profileUpdateModal.titleNameDescription"),
          submitButtonText: t("common.confirm"),
        });
    }
  }, [profileTypeModal, renderEmailModalText, t]);

  const handleSetProfileModal = useCallback(
    (type: USER_PROFILE_MODAL_TYPE | undefined) => {
      setShowProfileModal(!showProfileModal);
      setProfileTypeModal(type);
    },
    [showProfileModal]
  );

  const handleSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      updateUserImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    showProfileModal && renderModalText();
  }, [showProfileModal, renderModalText]);

  const handleChangeEmail = useCallback(() => {
    switch (changeEmailStep) {
      case 0:
        handleSendCodeToEmail();
        return true;
      case 1:
        handleVerifyChangeEmail();
        return true;
      case 2:
        return closeEmailModal();
      default:
        return false;
    }
  }, [
    changeEmailStep,
    closeEmailModal,
    handleSendCodeToEmail,
    handleVerifyChangeEmail,
  ]);

  const cancelButtonHandler = useCallback(() => {
    if (
      changeEmailStep !== 2 &&
      profileTypeModal === USER_PROFILE_MODAL_TYPE.EMAIL
    ) {
      return (
        setShowProfileModal(false), setChangeEmailStep(0), swiperRef?.slideTo(0)
      );
    } else {
      setShowProfileModal(false);
    }
  }, [changeEmailStep, profileTypeModal, swiperRef]);

  return (
    <>
      <Heading
        title={t("pages.profile.headings.profile.title")}
        description={t("pages.profile.headings.profile.description")}
        small
      />
      <ProfileCard className="profile-summary" flow={CHILDREN_FLOW.ROW}>
        <Column align="left" className="profile-summary__img">
          {userProfile?.image?.url ? (
            <img src={userProfile?.image?.url} />
          ) : (
            <div className="profile-summary__img--default">
              <p>
                {convertToInitials(
                  userProfile?.userData?.first_name,
                  userProfile?.userData?.last_name
                )}
              </p>
            </div>
          )}
          <div className="profile-summary__img__edit">
            <input type="file" onChange={handleSelectImage} />
            <Icon name="editFilled" />
          </div>
        </Column>

        <Column align="left" className="profile-summary__text">
          <h3>{userProfile?.displayName}</h3>
          <p className="description">{userProfile?.email}</p>
        </Column>
      </ProfileCard>
      <ProfileCard lastCard>
        <Heading
          title={t("pages.profile.headings.profile.info.title")}
          description={t("pages.profile.headings.profile.info.description")}
          small
        />
        <ProfileInfo
          title={t("pages.profile.headings.profile.info.name")}
          data={userProfile?.displayName || "-"}
          onClick={() => handleSetProfileModal(USER_PROFILE_MODAL_TYPE.NAME)}
        />
        <ProfileInfo
          title={t("pages.profile.headings.profile.info.email")}
          data={userProfile?.email || "-"}
          onClick={() => handleSetProfileModal(USER_PROFILE_MODAL_TYPE.EMAIL)}
        />
        <ProfileInfo
          title={t("pages.profile.headings.profile.info.workplace")}
          data={userProfile?.userData?.company || "-"}
          onClick={() => handleSetProfileModal(USER_PROFILE_MODAL_TYPE.WORK)}
        />
        <ProfileInfo
          title={t("pages.profile.headings.profile.info.address")}
          data={displayUserAddress()}
          onClick={() => handleSetProfileModal(USER_PROFILE_MODAL_TYPE.ADDRESS)}
        />
        <ProfileInfo
          title={t("pages.profile.headings.profile.info.phone")}
          data={userProfile?.userData?.phone || "-"}
          onClick={() => handleSetProfileModal(USER_PROFILE_MODAL_TYPE.PHONE)}
        />
      </ProfileCard>
      <ProfileModal
        iconName={modalTextData.icon}
        title={modalTextData.title}
        description={modalTextData.description}
        showModal={showProfileModal}
        cancelButtonClick={cancelButtonHandler}
        submitButtonClick={
          profileTypeModal === USER_PROFILE_MODAL_TYPE.EMAIL
            ? handleChangeEmail
            : form.submit
        }
        submitButtonText={modalTextData.submitButtonText}
        className={
          profileTypeModal === USER_PROFILE_MODAL_TYPE.EMAIL
            ? "profile-summary__email-modal"
            : ""
        }
      >
        <FormProvider form={form} state={state}>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {renderInputs()}
          </form>
        </FormProvider>
      </ProfileModal>
    </>
  );
};
