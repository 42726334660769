import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { TranslatorProvider } from "@avinet/adaptive-ui-translate";
import i18n, { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";

import { i18nextLngKey } from "./contants/Language";
import { NotificationProvider } from "./context/NotificationProvider";
import { DashboardContextProvider } from "./context/dashboard-context/DashboardContext";
import { DictionaryContextProvider } from "./context/dictionary-context/DictionaryContext";
import { EnvironmentContextProvider } from "./context/environment-context/EnvironmentContext";
import { FeatureContextProvider } from "./context/feature-context/FeatureContext";
import { FilterContextProvider } from "./context/filter-context/FilterContext";
import { MediaContextProvider } from "./context/media-context/MediaContext";
import { OrganisationContextProvider } from "./context/organisation-context/OrganisationContext";
import { PrivacyConsentContextProvider } from "./context/privacy-consent-context/PrivacyConsentContext";
import { AppUserContextProvider } from "./context/user-context/UserContext";
import { Environment, setupEnvironment } from "./environment/environment";
import "./index.scss";
import { Router } from "./router/Router";

const translations = {
  locale: "nb",
  Form: { optional: "valgfri", required: "påkrevd" },
  SelectItem: {},
};

function App() {
  const [environment, setEnvironment] = useState<Environment | undefined>(
    undefined
  );
  const [userLng, setUserLng] = useState<string | null>(
    localStorage.getItem(i18nextLngKey)
  );

  useEffect(() => {
    (async () => {
      try {
        await setupEnvironment().then((env) => setEnvironment(env));
      } catch (err) {
        console.error(`Error setup ${err}`);
      }
    })();
  }, []);

  const isDev =
    environment?.api.config.webUrl === "https://ombruk-qa.avinet.no/";

  const handleLanguageChanged = useCallback(() => {
    setUserLng(localStorage.getItem(i18nextLngKey));
  }, []);

  const renderTestInstance = useCallback(() => {
    return (
      userLng &&
      isDev && (
        <div className="dev-instance-warning">
          <Icon name={"warning"}></Icon>
          <p>{t("common.testInstance")}</p>
        </div>
      )
    );
  }, [userLng, isDev]);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);
    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  if (!environment) {
    return null;
  } else {
    return (
      <TranslatorProvider translations={translations}>
        <EnvironmentContextProvider environment={environment}>
          <FilterContextProvider environment={environment}>
            <AppUserContextProvider environment={environment}>
              <OrganisationContextProvider environment={environment}>
                <DashboardContextProvider environment={environment}>
                  <DictionaryContextProvider environment={environment}>
                    <FeatureContextProvider environment={environment}>
                      <MediaContextProvider environment={environment}>
                        <PrivacyConsentContextProvider
                          environment={environment}
                        >
                          <NotificationProvider>
                            {renderTestInstance()}
                            <Router />
                          </NotificationProvider>
                        </PrivacyConsentContextProvider>
                      </MediaContextProvider>
                    </FeatureContextProvider>
                  </DictionaryContextProvider>
                </DashboardContextProvider>
              </OrganisationContextProvider>
            </AppUserContextProvider>
          </FilterContextProvider>
        </EnvironmentContextProvider>
      </TranslatorProvider>
    );
  }
}

export default App;
