import { clamp } from "@avinet/adaptive-ui-core/utils/clamp";
import { useTranslate } from "@avinet/adaptive-ui-translate";
import { FocusScope } from "@react-aria/focus";
import type { ReactElement, ReactNode } from "react";
import {
  cloneElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { useEscapeKeyHandler } from "../../hooks/useEscapeKeyHandler";
import { usePopupTarget } from "../../hooks/usePopupTarget";
import { Icon } from "../icon/Icon";
import "./PopupMenu.scss";

interface PopupMenuDimensions {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  maxHeight?: number;
  maxWidth?: number;
}

interface PopupMenuProps {
  className?: string;
  children: ReactNode | ((dismiss: () => void) => ReactNode);
  rect?: { x: number; y: number; width: number; height: number };
  distance?: number;
  onToggle?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDismiss?: () => void;
  onShow?: () => void;
  fullScreenOn?: { maxWidth: number; maxHeight: number };
}

interface PopupMenuTargetProps {
  closeInside?: boolean;
  menu: ReactElement;
  children: (
    onToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    isVisible: boolean
  ) => void;
}

export function PopupMenuTarget({
  menu,
  closeInside = true,
  children,
}: PopupMenuTargetProps) {
  const rectRef = useRef<DOMRect>();
  const [target, isVisible, setVisible] = usePopupTarget(
    "popup-menu",
    closeInside
  );

  const onToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      rectRef.current = (
        event.target as HTMLButtonElement
      ).getBoundingClientRect();
      setVisible((visible) => !visible);
      event.preventDefault();
    },
    [setVisible]
  );

  const onDismiss = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onClear = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEscapeKeyHandler(isVisible && onClear);

  useEffect(() => {
    function onOverlayClick(e: MouseEvent) {
      if (e.target === target) setVisible(false);
    }
    target?.addEventListener("click", onOverlayClick);
    return () => target?.removeEventListener("click", onOverlayClick);
  }, [setVisible, target]);

  return (
    <>
      {children(onToggle, isVisible)}
      {isVisible &&
        createPortal(
          cloneElement(menu as ReactElement<PopupMenuProps>, {
            rect: rectRef.current,
            fullScreenOn: menu.props.fullScreenOn,
            onToggle,
            onDismiss,
          }),
          target
        )}
    </>
  );
}

export function PopupMenu({
  className = "",
  children,
  rect = { x: 0, y: 0, width: 100, height: 100 },
  distance = 8,
  fullScreenOn,
  onToggle,
  onDismiss,
  onShow,
}: PopupMenuProps) {
  const t = useTranslate("Default");
  const menuRef = useRef<HTMLDivElement>(null);
  const [dim, setDim] = useState<PopupMenuDimensions>();
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => onShow && onShow(), [onShow]);

  useLayoutEffect(() => {
    if (!menuRef.current) return;
    const menuRect = menuRef.current.getBoundingClientRect();
    const ww = window.innerWidth;
    const wh = window.innerHeight;

    if (
      fullScreenOn &&
      (fullScreenOn.maxHeight > wh || fullScreenOn.maxWidth > ww)
    ) {
      const fullScreenDim = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        maxWidth: ww,
        maxHeight: wh,
      };
      setDim(fullScreenDim);
      setIsFullScreen(true);
      return;
    }

    const dim: PopupMenuDimensions = {};

    const prefersRightAlign = rect.x + rect.width / 2 > ww / 2;

    if (prefersRightAlign && rect.x + rect.width - menuRect.width > 0) {
      dim.left = rect.x + rect.width - menuRect.width;
    } else if (rect.x + menuRect.width < ww) {
      dim.left = rect.x;
    } else {
      dim.left = (ww - menuRect.width) / 2;
      dim.maxWidth = ww;
    }

    if (rect.y + rect.height + menuRect.height + distance < wh) {
      dim.top = Math.max(0, rect.y + rect.height + distance);
    } else if (rect.y - menuRect.height > 0) {
      dim.bottom = Math.max(0, wh - (rect.y - distance));
    } else {
      const centerPos = rect.y + rect.height / 2 - menuRect.height / 2;

      dim.top = clamp(0, centerPos, Math.max(0, wh - menuRect.height));

      if (prefersRightAlign) {
        dim.left = rect.x - menuRect.width - distance;
      } else {
        dim.left = rect.x + rect.width + distance;
      }
    }

    dim.maxHeight = wh;

    setDim(dim);
    setIsFullScreen(false);
  }, [distance, fullScreenOn, rect]);

  return (
    <FocusScope autoFocus contain restoreFocus>
      <div
        role="menu"
        ref={menuRef}
        className={`popup-menu ${
          isFullScreen ? "fullscreen" : ""
        } ${className}`}
        style={dim}
      >
        {isFullScreen && (
          <button
            type="button"
            className="btn close-fullscreen"
            onClick={onToggle}
          >
            <Icon name="dismiss" />
          </button>
        )}
        {typeof children === "function"
          ? children(() => onDismiss?.())
          : children}
        <button
          type="button"
          className="btn-link close-popup-menu"
          onClick={onToggle}
        >
          {t("dismiss")}
        </button>
      </div>
    </FocusScope>
  );
}
