import Row from "@avinet/adaptive-ui-core/layout/Row";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";
import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";

import { KIND_RESPONSE } from "../../api/api-problem";
import {
  ILocationProperties,
  IStatisticsResponse,
  IWasteStatisticsResponse,
} from "../../api/types";
import ContactIcon from "../../assets/icons/contact/ContactIcon";
import LocationIcon from "../../assets/icons/location/LocationIcon";
import ObjectsIcon from "../../assets/icons/objects/ObjectsIcon";
import ProjectsIcon from "../../assets/icons/projects/ProjectsIcon";
import CourseStarBigIcon from "../../assets/icons/stars/CourseStarBigIcon";
import CourseStarMediumIcon from "../../assets/icons/stars/CourseStarMediumIcon";
import CourseStarSmallIcon from "../../assets/icons/stars/CourseStarSmallIcon";
import { LocationModal } from "../../components/add-new-feature/location/LocationModal";
import { ObjectModal } from "../../components/add-new-feature/object/ObjectModal";
import { ProjectModal } from "../../components/add-new-feature/project/ProjectModal";
import { AddNewLink } from "../../components/add-new-link";
import { SidebarPage } from "../../components/sidebar/sidebar-page";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import { contactEmailAddress } from "../../contants/Mailing";
import { selectOrganisationUrl } from "../../contants/Routes";
import { DashboardContext } from "../../context/dashboard-context/DashboardContext";
import { DictionaryContext } from "../../context/dictionary-context/DictionaryContext";
import { FeatureContext } from "../../context/feature-context/FeatureContext";
import { OrganisationContext } from "../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../context/user-context/UserContext";
import { useHasInvite } from "../../hooks/useHasInvite";
import { IAddNewLink } from "../../utils/types/types";
import "./Dashboard.scss";
import { EmissionCard } from "./EmissionCard";
import { StatisticsCard } from "./StatisticsCard";

export function Dashboard() {
  const { userOrganisationType, userProfile } = useContext(AppUserContext);
  const { downloadGenericFeature } = useContext(FeatureContext);
  const { getDictionaries } = useContext(DictionaryContext);
  const { selectedOrganisation, selectedOrgLoading } =
    useContext(OrganisationContext);
  const { getWasteStatistics, getStatistics } = useContext(DashboardContext);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedTableItem] = useState<ILocationProperties | null>(null);
  const [objectModal, setObjectModal] = useState<{
    mode: "add" | "edit";
  }>();
  const [createdLocationId, setCreatedLocationId] = useState<
    number | undefined
  >(undefined);

  const navigate = useNavigate();
  const hasPendingInvite = useHasInvite();

  const [wasteStatisticsData, setWasteStatisticsData] =
    useState<IWasteStatisticsResponse>();
  const [statisticsData, setStatisticsData] = useState<IStatisticsResponse>();
  const [selectedStatsPeriod, setSelectedStatsPeriod] = useState("total");

  useEffect(() => {
    if (selectedOrgLoading === false) {
      if (!selectedOrganisation) {
        navigate(selectOrganisationUrl);
      }
    }
  }, [navigate, selectedOrgLoading, selectedOrganisation]);

  useEffect(() => {
    downloadGenericFeature(FEATURE_UUID.LOCATION);
    downloadGenericFeature(FEATURE_UUID.PRODUCT);
    getDictionaries();
  }, [downloadGenericFeature, getDictionaries]);

  useEffect(() => {
    hasPendingInvite && navigate(selectOrganisationUrl);
  }, [hasPendingInvite, navigate]);

  const handleLocationModal = useCallback((show: boolean) => {
    setShowLocationModal(show);
  }, []);

  const closeObjectModal = useCallback(() => {
    setObjectModal(undefined);
    createdLocationId && setCreatedLocationId(undefined);
  }, [createdLocationId]);

  const handleProjectModal = useCallback((show: boolean) => {
    setShowProjectModal(show);
  }, []);

  const handleAttachmentModal = useCallback(
    (refreshAttachments: boolean) => {
      setShowAttachmentModal((prev) => !prev);
      if (refreshAttachments) {
        downloadGenericFeature(FEATURE_UUID.LOCATION);
      }
    },
    [downloadGenericFeature]
  );

  const handleAddNewComponentFromLocation = useCallback((id?: number) => {
    setObjectModal({
      mode: "add",
    });
    setCreatedLocationId(id);
  }, []);

  useEffect(() => {
    const orgId = selectedOrganisation?.id;
    if (!orgId) return;
    const currentYear = new Date().getFullYear();

    async function fetchWasteStatistics() {
      try {
        const fetchedWasteStats = await getWasteStatistics(orgId, currentYear);

        if (fetchedWasteStats === KIND_RESPONSE.UNAUTHORIZED) {
          navigate(selectOrganisationUrl);
        } else {
          setWasteStatisticsData(
            fetchedWasteStats as SetStateAction<
              IWasteStatisticsResponse | undefined
            >
          );
        }
      } catch (error) {
        console.error("Error fetching waste stats:", error);
      }
    }

    fetchWasteStatistics();
  }, [getWasteStatistics, navigate, selectedOrganisation]);

  useEffect(() => {
    const orgId = selectedOrganisation?.id;
    if (!orgId) return;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    async function fetchStatistics() {
      try {
        let fetchedStats;
        if (selectedStatsPeriod === "month") {
          fetchedStats = await getStatistics(orgId, currentYear, currentMonth);
        } else if (selectedStatsPeriod === "year") {
          fetchedStats = await getStatistics(orgId, currentYear);
        } else if (selectedStatsPeriod === "total") {
          fetchedStats = await getStatistics(orgId);
        }
        setStatisticsData(
          fetchedStats as SetStateAction<IStatisticsResponse | undefined>
        );
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    }

    fetchStatistics();
  }, [getStatistics, selectedOrganisation, selectedStatsPeriod]);

  const handleStatsPeriod = useCallback((period: string) => {
    setSelectedStatsPeriod(period);
  }, []);

  const addNewItems: IAddNewLink[] = [
    {
      icon: <Icon name="locationIcon" />,
      title: t("components.sidebarAddNew.location.title"),
      description: t("components.sidebarAddNew.location.description"),
      onClick: () => {
        handleLocationModal(true);
      },
    },
    {
      icon: <Icon name="objectsIcon" />,
      title: t("components.sidebarAddNew.object.title"),
      description: t("components.sidebarAddNew.object.description"),
      onClick: () => {
        setObjectModal({
          mode: "add",
        });
      },
    },
    {
      icon: <Icon name="projectsIcon" />,
      title: t("components.sidebarAddNew.project.title"),
      description: t("components.sidebarAddNew.project.description"),
      onClick: () => {
        handleProjectModal(true);
      },
    },
  ];

  return (
    <SidebarPage>
      <div className="dashboard">
        <header>
          <h1>
            {t("pages.dashboard.welcome")} {userProfile?.userData?.first_name}
          </h1>
          <Row className="dashboard__organisation">
            <p className="current-organisation">
              {t("pages.dashboard.organisation")}{" "}
              <Link to={selectOrganisationUrl}>{userOrganisationType}</Link>
            </p>
          </Row>
        </header>

        <Row className="section-header">
          <Icon name="choice" />
          <h4>{t("pages.dashboard.yourChoices")}</h4>
        </Row>
        <div className="card-container">
          {addNewItems.map((link, index) => (
            <AddNewLink key={index} link={link} />
          ))}
        </div>

        <a className="course-box" href={`mailto:${contactEmailAddress}`}>
          <div className="course-box__content">
            <h3>{t("pages.dashboard.courseBy")}</h3>
            <h2>{t("pages.dashboard.courseDescription")}</h2>
            <div className="course-box__contact">
              <p className="course-box__contact-text">
                {t("pages.dashboard.contactUs")}
              </p>
              <ContactIcon />
            </div>
          </div>
          <CourseStarBigIcon />
          <CourseStarMediumIcon />
          <CourseStarSmallIcon />
        </a>

        <Row className="section-header">
          <Icon name="savings" />
          <h4>{t("pages.dashboard.savings")}</h4>
        </Row>
        <div className="card-container">
          <EmissionCard
            title={t("pages.dashboard.costs")}
            description={t("pages.dashboard.savedCostDesc")}
            totalAmount={
              wasteStatisticsData ? wasteStatisticsData.cost_saved : 0
            }
            yearAmount={
              wasteStatisticsData ? wasteStatisticsData.cost_saved : 0
            }
            unit="NOK"
          />
          <EmissionCard
            title={t("pages.dashboard.emissions")}
            description={t("pages.dashboard.savedEmissionsDesc")}
            totalAmount={
              wasteStatisticsData ? wasteStatisticsData.co2_saved : 0
            }
            yearAmount={wasteStatisticsData ? wasteStatisticsData.co2_saved : 0}
            unit="kg CO²"
          />
          <EmissionCard
            title={t("pages.dashboard.waste")}
            description={t("pages.dashboard.savedWasteDesc")}
            totalAmount={wasteStatisticsData ? wasteStatisticsData.kg_saved : 0}
            yearAmount={wasteStatisticsData ? wasteStatisticsData.kg_saved : 0}
            unit="kg"
          />
        </div>

        <Row className="section-header section-header__statistics">
          <Icon name="statistics" />
          <h4>{t("pages.dashboard.statistics")}</h4>
          <div className="statistics__period-buttons">
            <span
              className={selectedStatsPeriod === "month" ? "selected" : ""}
              onClick={() => handleStatsPeriod("month")}
            >
              {t("common.month")}
            </span>
            <span
              className={selectedStatsPeriod === "year" ? "selected" : ""}
              onClick={() => handleStatsPeriod("year")}
            >
              {t("common.year")}
            </span>
            <span
              className={selectedStatsPeriod === "total" ? "selected" : ""}
              onClick={() => handleStatsPeriod("total")}
            >
              {t("common.total")}
            </span>
          </div>
        </Row>
        <div className="card-container">
          <StatisticsCard
            title={t("pages.dashboard.locations")}
            icon={<LocationIcon />}
            amount={statisticsData ? statisticsData.location_count : 0}
            period={selectedStatsPeriod}
          />
          <StatisticsCard
            title={t("pages.dashboard.objects")}
            icon={<ObjectsIcon />}
            amount={statisticsData ? statisticsData.product_count : 0}
            period={selectedStatsPeriod}
          />
          <StatisticsCard
            title={t("pages.dashboard.projects")}
            icon={<ProjectsIcon />}
            amount={statisticsData ? statisticsData.project_count : 0}
            period={selectedStatsPeriod}
          />
        </div>
      </div>
      {showLocationModal && (
        <LocationModal
          locationDetails={selectedTableItem}
          onClose={handleLocationModal}
          handleAddNewComponent={handleAddNewComponentFromLocation}
        />
      )}
      {objectModal && (
        <ObjectModal
          mode={objectModal.mode}
          onClose={closeObjectModal}
          locationId={createdLocationId}
        />
      )}
      {showProjectModal && (
        <ProjectModal
          showProjectModal={showProjectModal}
          modalState={handleProjectModal}
          attachmentModal={showAttachmentModal}
          setAttachmentModal={handleAttachmentModal}
        />
      )}
    </SidebarPage>
  );
}
