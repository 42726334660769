import React, { FC } from "react";

const NorwayFlag: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7147_475485)">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#F0F0F0"
        />
        <path
          d="M0.34375 12.6084C0.922344 14.7552 2.19754 16.6159 3.9123 17.9335V12.6084H0.34375Z"
          fill="#D80027"
        />
        <path
          d="M9.12891 19.9617C9.41555 19.9863 9.70543 19.9997 9.99848 19.9997C14.6188 19.9997 18.5065 16.8661 19.654 12.6084H9.12891V19.9617Z"
          fill="#D80027"
        />
        <path
          d="M19.654 7.39129C18.5065 3.13363 14.6188 0 9.99848 0C9.70543 0 9.41555 0.0133594 9.12891 0.0380469V7.39129H19.654Z"
          fill="#D80027"
        />
        <path
          d="M3.9123 2.06641C2.19754 3.38398 0.922344 5.24465 0.34375 7.39148H3.9123V2.06641Z"
          fill="#D80027"
        />
        <path
          d="M19.9154 8.69527H7.82613H7.82609V0.237305C6.90195 0.442227 6.02582 0.774375 5.21738 1.21551V8.6952V8.69523H0.0846484C0.0289844 9.12223 0 9.55754 0 9.99961C0 10.4417 0.0289844 10.877 0.0846484 11.3039H5.21734H5.21738V18.7837C6.02582 19.2248 6.90195 19.557 7.82609 19.7619V11.3041V11.304H19.9154C19.9709 10.877 20 10.4417 20 9.99961C20 9.55754 19.9709 9.12223 19.9154 8.69527Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_7147_475485">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NorwayFlag;
