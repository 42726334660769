import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FEATURE_MEDIA_TYPE } from "../../../contants/FeatureUuid";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { useAttachments } from "../../../hooks/useAttachments";
import { fileToPostMedia, getMediaUri } from "../../../utils/index.utils";
import { FileDropzone } from "../../file-dropzone";
import { Heading } from "../../heading";
import { ProfileModal } from "../../profile/profile-modal";
import "./OrganizationReportLogo.scss";

export const OrganizationReportLogo = () => {
  const { t } = useTranslation();

  const { selectedOrganisation, updateOrganisation } =
    useContext(OrganisationContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { token } = useContext(AppUserContext);

  const recordId = selectedOrganisation?.id;

  const { postMedia, deleteMedia, getAttachments, fetchedAttachments } =
    useAttachments();

  useEffect(() => {
    if (!recordId) return;

    getAttachments(FEATURE_MEDIA_TYPE.ORGANIZATION, recordId);
  }, [getAttachments, recordId]);

  const handleDrop = useCallback(
    async (file: File) => {
      if (!recordId) return;

      const postMediaItem = await fileToPostMedia(file);

      const mediaResponse = await postMedia(
        postMediaItem,
        FEATURE_MEDIA_TYPE.ORGANIZATION,
        recordId
      );

      if (mediaResponse?.kind === "ok") {
        updateOrganisation({
          ...selectedOrganisation,
          logo_image: mediaResponse.data.media,
        });
        getAttachments(FEATURE_MEDIA_TYPE.ORGANIZATION, recordId);
      }
    },
    [
      getAttachments,
      postMedia,
      recordId,
      selectedOrganisation,
      updateOrganisation,
    ]
  );

  const handleDeleteImage = useCallback(async () => {
    setShowDeleteModal(false);
    if (!selectedOrganisation) return;

    const logo_image = selectedOrganisation?.logo_image;

    const mediaItem = fetchedAttachments?.find(
      (media) => media.media === logo_image
    );

    if (mediaItem) {
      const response = await deleteMedia(mediaItem);

      if (response?.kind === "ok") {
        updateOrganisation({
          ...selectedOrganisation,
          logo_image: null,
        });
      }
    }
  }, [
    deleteMedia,
    fetchedAttachments,
    selectedOrganisation,
    updateOrganisation,
  ]);

  return (
    <>
      <Heading
        title={t("pages.profile.headings.organisation.reportLogoTitle")}
        description={t(
          "pages.profile.headings.organisation.reportLogoDescription"
        )}
        small
      />
      <section className="profile-card">
        {selectedOrganisation?.logo_image ? (
          <div className="logo-section">
            <div className="img-container">
              <img src={getMediaUri(selectedOrganisation.logo_image, token)} />
            </div>
            <button
              className="btn btn-delete"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              {t("common.delete")}
            </button>
          </div>
        ) : (
          <FileDropzone
            onDrop={handleDrop}
            attachmentButtonClick={undefined}
            text={""}
            maxFiles={1}
          />
        )}
      </section>
      <ProfileModal
        deleteMode
        iconName="delete"
        title={t("components.deleteLogoImageModal.title")}
        description=""
        showModal={showDeleteModal}
        cancelButtonClick={() => setShowDeleteModal(false)}
        submitButtonClick={() => handleDeleteImage()}
        submitButtonText={t("components.deleteLogoImageModal.delete")}
        className="profile-policy__delete-modal"
      >
        <p className="profile-policy__delete-modal__text"></p>
        <p className="profile-policy__delete-modal__text">
          {t("components.deleteLogoImageModal.areYouSure")}
        </p>
      </ProfileModal>
    </>
  );
};
