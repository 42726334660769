import { format } from "date-fns";
import { t } from "i18next";
import { useContext, useMemo } from "react";

import { ILocationProperties, IMediaFeature } from "../../../api/types";
import { FeatureContext } from "../../../context/feature-context/FeatureContext";
import {
  handleTranslateBuildingCategory,
  handleTranslateBuildingDemolitionType,
  handleTranslateBuildingStatus,
} from "../../../utils/index.utils";
import { FeatureImagePreview } from "../../feature-image-preview";
import { Text } from "../../text";
import "../styles.scss";

export function LocationPreview({
  locationDetails,
  attachments,
}: {
  locationDetails: ILocationProperties | undefined;
  attachments: IMediaFeature[];
}) {
  const { locationFloors } = useContext(FeatureContext);

  const locationData = useMemo(
    () => [
      {
        title: t("components.locationModal.form.labels.buildingStatus"),
        description: handleTranslateBuildingStatus(
          locationDetails?.status as string
        ),
      },
      {
        title: t("form.name"),
        description: locationDetails?.name,
      },
      {
        title: t("components.locationModal.form.labels.address"),
        description: `${locationDetails?.address} ${locationDetails?.gnrbnr}, ${locationDetails?.fylke}, ${locationDetails?.kommune}`,
      },
      {
        title: t("components.locationModal.form.labels.buildingOwner"),
        description: locationDetails?.owner,
      },
      {
        title: t("components.locationModal.form.labels.buildingCategory"),
        description: handleTranslateBuildingCategory(
          locationDetails?.building_category as string
        ),
      },
      {
        title: t("components.locationModal.form.labels.mainMaterial"),
        description: locationDetails?.main_material_use,
      },
      {
        title: t("components.locationModal.form.labels.grossFloorArea"),
        description: locationDetails?.area,
      },
      {
        title: t("components.locationModal.form.labels.kartlagtFra"),
        description: locationDetails?.kartlagt_fra,
      },
      {
        title: t("components.locationModal.form.labels.kartlagtTil"),
        description: locationDetails?.kartlagt_til,
      },
      {
        title: t("components.locationModal.form.labels.kartlagtAv"),
        description: locationDetails?.kartlagt_av,
      },
      {
        title: t("components.locationModal.form.labels.yearOfConstruction"),
        description: locationDetails?.building_year,
      },
      {
        title: t(
          "components.locationModal.form.labels.yearAndTypeOfRenovations"
        ),
        description: locationDetails?.rehab_year?.split(",").join(", "),
      },
      {
        title: t("components.locationModal.form.labels.demolitionDate"),
        description: locationDetails?.demolition_date
          ? format(new Date(locationDetails?.demolition_date), "dd-MM-yyyy")
          : "",
      },
      {
        title: t("components.locationModal.form.labels.typeOfdemolition"),
        description: handleTranslateBuildingDemolitionType(
          locationDetails?.type_of_demolition as string
        ),
      },
      {
        title: t("components.locationModal.form.labels.comment"),
        description: locationDetails?.comments,
      },
    ],
    [locationDetails]
  );

  return (
    <div className="feature-preview">
      <div className="feature-preview__left">
        <Text
          text={t("pages.locationsPreview.details")}
          size="medium"
          fontWeight={700}
          className="feature-preview__description-title"
        />

        <div className="feature-preview__table">
          {locationData.map(({ title, description }, index) => {
            return (
              <div className="feature-preview__table-item" key={index}>
                <Text
                  text={`${title}:`}
                  fontWeight={500}
                  size="small"
                  tag="span"
                />
                <Text
                  text={description ? String(description) : "-"}
                  fontWeight={500}
                  size="small"
                  tag="span"
                />
              </div>
            );
          })}
        </div>

        {!!locationFloors.length && (
          <>
            <Text
              text={t("pages.locationsPreview.roomsAndFloors")}
              size="medium"
              fontWeight={700}
            />

            <div className="feature-preview__table">
              {locationFloors
                .sort((a, b) => b.sequence_id - a.sequence_id)
                .map((floor) => (
                  <div className="feature-preview__table-item" key={floor.uuid}>
                    <Text
                      text={
                        floor.name
                          ? `${floor.name}:`
                          : `${t("pages.locationsPreview.floor")} ${
                              floor.sequence_id
                            }:`
                      }
                      fontWeight={500}
                      size="small"
                      tag="span"
                    />
                    <Text
                      text={
                        floor.room_list
                          .map((room) => room.name)
                          .filter(Boolean)
                          .join(", ") || "-"
                      }
                      fontWeight={500}
                      size="small"
                      tag="span"
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
      <div className="feature-preview__right">
        {/* TODO: hide tabs until reuse map will be ready  */}
        {/*<HorizontalTabs*/}
        {/*  tabs={tabs}*/}
        {/*  activeTab={activeTab}*/}
        {/*  setActiveTab={setActiveTab}*/}
        {/*  disabledIndex={1}*/}
        {/*/>*/}
        <FeatureImagePreview
          imagesList={attachments}
          thumbnailUuid={locationDetails?.image}
        />

        {locationDetails?.description?.length ? (
          <div className="feature-preview__description">
            <Text
              text={t("components.locationsPreview.descriptionTitle")}
              tag="span"
              fontWeight={700}
              color="dark"
              size="medium"
            />
            <Text
              text={locationDetails?.description}
              tag="span"
              color="dark"
              size="md"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
