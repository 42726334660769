import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { IDataInfo, IMediaFeature, sliderData } from "../../../api/types";
// import { ArrowLeftIcon } from "../../../assets/icons/arrow-left/ArrowLeftIcon";
// import { ArrowRightIcon } from "../../../assets/icons/arrow-right/ArrowRightIcon";
import { ChevronRight } from "../../../assets/icons/chevron-right/ChevronRight";
import ObjectsIcon from "../../../assets/icons/objects/ObjectsIcon";
import { PlugConnectedIcon } from "../../../assets/icons/plug-connected";
import { XCloseIcon } from "../../../assets/icons/x-close/XCloseIcon";
import {FEATURE_TYPE, THUMBNAIL_SIZE} from "../../../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../../../contants/FeatureUuid";
import {
  objectsTabAttachments,
  objectsUrl,
  projectsTabAssessments,
  projectsUrl,
} from "../../../contants/Routes";
import { DictionaryContext } from "../../../context/dictionary-context/DictionaryContext";
import { OrganisationContext } from "../../../context/organisation-context/OrganisationContext";
import { AppUserContext } from "../../../context/user-context/UserContext";
import { useAttachmentModal } from "../../../hooks/useAttachmentModal";
import {
  getMediaUri,
  handleTranslateBuildingCategory,
  handleTranslateBuildingStatus,
  handleTranslateProjectStatus,
  isFileMIMEImageType,
} from "../../../utils/index.utils";
import { NoFeaturePlaceholder } from "../../no-feature-placeholder";
import { SliderPreview } from "../../slider-preview";
import { PageSidebarProps } from "./index.props";
import { dataSidebar } from "./mocks";
import "./styles.scss";
import {useCheckMobile} from "../../../hooks/useCheckMobile";

export function PageSidebar({
  isSidebarVisible,
  onClose,
  type,
  featureDetails,
  setEditMode,
  setConnectProjectMode,
  onEdit,
  projectId,
}: PageSidebarProps) {
  const [isInfoVisible, setIsInfoVisible] = useState(true);
  const { getOrganisationName } = useContext(OrganisationContext);
  const { token } = useContext(AppUserContext);
  const { getCategoryName, getParentCategoryName, getElementName } =
    useContext(DictionaryContext);
  const categoryName =
    (featureDetails?.category && getCategoryName(featureDetails?.category)) ||
    "-";
  const parentCategoryName =
    (featureDetails?.parent_category &&
      getParentCategoryName(featureDetails?.parent_category)) ||
    "-";
  const elementName =
    (featureDetails?.element && getElementName(featureDetails?.element)) || "-";
  const navigate = useNavigate();
  const { isMobile } = useCheckMobile();

  const thumbnailSizeForImages: THUMBNAIL_SIZE = useMemo(() => {
      return isMobile ? THUMBNAIL_SIZE.MEDIUM : THUMBNAIL_SIZE.LARGE;
  }, [isMobile]);

  // const image = featureDetails?.mapping_image
  //   ? featureDetails?.mapping_image
  //   : featureDetails?.image;

  const basicLocationData = useMemo(
    () =>
      featureDetails
        ? [
            {
              title: t("form.name"),
              description: featureDetails.name,
            },
            {
              title: t("components.locationModal.form.labels.address"),
              description: `${featureDetails.address} ${featureDetails.gnrbnr}, ${featureDetails.fylke}, ${featureDetails.kommune}`,
            },
            {
              title: t("components.locationModal.form.labels.buildingStatus"),
              description: handleTranslateBuildingStatus(
                featureDetails.status as string
              ),
            },
            {
              title: t("components.userProfileMenu.organisation"),
              description: featureDetails.fk_organization
                ? getOrganisationName(featureDetails.fk_organization)?.name
                : "",
            },
          ]
        : [],
    [featureDetails, getOrganisationName]
  );
  const [sliderItems, setSliderItems] = useState<sliderData[]>();
  const [featureMediaType, setFeatureMediaType] = useState<FEATURE_MEDIA_TYPE>(
    FEATURE_MEDIA_TYPE.LOCATION
  );

  const { attachmentsList } = useAttachmentModal(
    featureDetails?.id,
    featureMediaType,
    () => undefined
  );
  const handleSetFeatureMediaType = useCallback(() => {
    const arrCopy = attachmentsList?.length
      ? attachmentsList?.filter((item: IMediaFeature) =>
          isFileMIMEImageType(item?.file_type)
        )
      : [];

    arrCopy?.length &&
      arrCopy.sort((a) => (a.media === featureDetails?.image ? -1 : 0));

    const sliderMediaData = arrCopy.map((img: IMediaFeature) => ({
      src: getMediaUri(img.media, token, thumbnailSizeForImages),
      alt: img.title,
    }));

    setSliderItems(sliderMediaData);

    switch (type) {
      case FEATURE_TYPE.LOCATION:
        return setFeatureMediaType(FEATURE_MEDIA_TYPE.LOCATION);
      case FEATURE_TYPE.PROJECT:
        return setFeatureMediaType(FEATURE_MEDIA_TYPE.PROJECT);
      case FEATURE_TYPE.OBJECT:
        return setFeatureMediaType(FEATURE_MEDIA_TYPE.PRODUCT);
      default:
        return setFeatureMediaType(FEATURE_MEDIA_TYPE.LOCATION);
    }
  }, [attachmentsList, token, type, featureDetails?.image]);

  useLayoutEffect(() => {
    handleSetFeatureMediaType();
  }, [handleSetFeatureMediaType]);

  const locationData = useCallback(() => {
    return type === FEATURE_TYPE.LOCATION && featureDetails
      ? [
          {
            title: t("components.locationModal.form.labels.buildingCategory"),
            description: handleTranslateBuildingCategory(
              featureDetails.building_category as string
            ),
          },
          {
            title: t("components.locationModal.form.labels.buildingOwner"),
            description: featureDetails.owner,
          },
          {
            title: t("components.locationModal.form.labels.yearOfConstruction"),
            description: featureDetails.building_year,
          },
          {
            title: t(
              "components.locationModal.form.labels.yearAndTypeOfRenovations"
            ),
            description: featureDetails.rehab_year,
          },
          {
            title: t("components.locationModal.form.labels.mainMaterial"),
            description: featureDetails.main_material_use,
          },
          {
            title: t("components.locationModal.form.labels.grossFloorArea"),
            description: featureDetails.area,
          },
        ]
      : [];
  }, [featureDetails, type]);

  const objectData = useMemo(
    () => [
      {
        title: t("components.objectModal.form.labels.name"),
        description: featureDetails?.specification ?? "",
      },
      {
        title: t("components.objectModal.form.labels.category"),
        description: categoryName,
      },
      {
        title: t("components.objectModal.form.labels.otherCategory"),
        description: parentCategoryName,
      },
      {
        title: t("components.objectModal.form.labels.element"),
        description: elementName,
      },
      {
        title: t("components.objectModal.form.labels.height"),
        description: featureDetails?.height ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.width"),
        description: featureDetails?.width ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.length"),
        description: featureDetails?.length ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.diameter"),
        description: featureDetails?.diameter ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.weight"),
        description: featureDetails?.weight ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.producer"),
        description: featureDetails?.producer ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.productionYear"),
        description: featureDetails?.production_year ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.cost"),
        description: featureDetails?.cost ?? 0,
      },
      {
        title: t("components.objectModal.form.labels.co2"),
        description: featureDetails?.co2 ?? 0,
      },
    ],
    [featureDetails, categoryName, parentCategoryName, elementName]
  );

  const projectData = useMemo(
    () => [
      {
        title: t("tables.name"),
        description: featureDetails?.name ?? "",
      },
      {
        title: t("tables.leader"),
        description: featureDetails?.project_leader ?? "",
      },
      {
        title: t("tables.startDate"),
        description: featureDetails?.start_date ?? "",
      },
      {
        title: t("tables.endDate"),
        description: featureDetails?.end_date ?? "",
      },
      {
        title: t("tables.customer"),
        description: featureDetails?.customer ?? "",
      },
      {
        title: t("tables.status"),
        description: handleTranslateProjectStatus(
          featureDetails?.status as string
        ),
      },
    ],
    [featureDetails]
  );

  const renderInfoItem = useCallback((item: IDataInfo, index: number) => {
    return (
      <div className="locations-sidebar-info-item" key={index}>
        <span className="locations-sidebar-info-item-title">{item.title}</span>
        <span className="locations-sidebar-info-item-text">
          {item.description}
        </span>
      </div>
    );
  }, []);

  const handleRenderSpecificData = useCallback(() => {
    switch (type) {
      case FEATURE_TYPE.PROJECT:
        return (
          <>
            {[...projectData].map((item, index) => {
              if (item.description) {
                return renderInfoItem(item, index);
              } else {
                return null;
              }
            })}
          </>
        );
      case FEATURE_TYPE.LOCATION:
        return (
          <>
            {[...basicLocationData, ...locationData()].map((item, index) => {
              if (item.description) {
                return renderInfoItem(item, index);
              } else {
                return null;
              }
            })}
          </>
        );
      case FEATURE_TYPE.OBJECT:
        return (
          <>
            {[...objectData].map((item, index) => {
              if (item.description) {
                return renderInfoItem(item, index);
              } else {
                return null;
              }
            })}
          </>
        );
      default:
        return renderMockData();
    }
  }, [
    type,
    basicLocationData,
    locationData,
    objectData,
    renderInfoItem,
    projectData,
  ]);

  //TODO: add render for PROJECT AND OBJECT
  const renderMockData = () => {
    return dataSidebar.map(({ title, data }, index) => {
      return (
        <div className="locations-sidebar-info-item" key={index}>
          <span className="locations-sidebar-info-item-title">{title}</span>
          <span className="locations-sidebar-info-item-text">{data}</span>
        </div>
      );
    });
  };

  const handleEditFeature = useCallback(() => {
    if (!featureDetails?.id) return;
    setEditMode?.(true);
    onEdit?.(featureDetails?.id);
  }, [featureDetails?.id, onEdit, setEditMode]);

  const editFeatureText = useCallback(() => {
    switch (type) {
      case FEATURE_TYPE.PROJECT:
        return t("components.noElements.project");
      case FEATURE_TYPE.LOCATION:
        return t("components.noElements.location");
      case FEATURE_TYPE.OBJECT:
        return t("components.noElements.object");
      default:
        return renderMockData();
    }
  }, [type]);

  const renderCustomButton = useCallback(() => {
    switch (type) {
      case FEATURE_TYPE.PROJECT:
        return (
          <button
            onClick={() => {
              onClose();
              navigate(
                `${projectsUrl}/${featureDetails?.id}/${projectsTabAssessments}`,
                {
                  state: {
                    featureId: featureDetails?.id,
                  },
                }
              );
            }}
          >
            <ObjectsIcon /> {t("components.pageSidebar.assessProject")}
          </button>
        );
      case FEATURE_TYPE.LOCATION:
        return (
          <button
            onClick={() =>
              setConnectProjectMode && setConnectProjectMode((prev) => !prev)
            }
          >
            <PlugConnectedIcon /> {t("components.locationsPreview.project")}
          </button>
        );
      case FEATURE_TYPE.OBJECT:
        return (
          <button
            onClick={() => {
              onClose();
              navigate(
                projectId
                  ? `${projectsUrl}/${projectId}/${objectsTabAttachments}`
                  : `${objectsUrl}/${featureDetails?.id}/${objectsTabAttachments}`,
                {
                  state: {
                    featureId: featureDetails?.id,
                  },
                }
              );
            }}
          >
            <ObjectsIcon /> {t("components.pageSidebar.assessComponent")}
          </button>
        );

      default:
        return <button></button>;
    }
  }, [
    type,
    onClose,
    navigate,
    featureDetails?.id,
    setConnectProjectMode,
    projectId,
  ]);

  return (
    <>
      <div
        className={`locations-sidebar-wrapper ${
          isSidebarVisible ? "locations-sidebar-wrapper--visible" : ""
        }`}
      >
        <div
          className={`locations-sidebar ${
            !isInfoVisible ? "locations-sidebar--hidden" : ""
          }`}
        >
          <button className="locations-sidebar-close-button" onClick={onClose}>
            <XCloseIcon />
          </button>
          {/* For now there is only 1 thumbnail to location */}
          {/*{item && item.image && (*/}
          {/*  <button className="locations-sidebar-button locations-sidebar-button--left">*/}
          {/*    <ArrowLeftIcon />*/}
          {/*  </button>*/}
          {/*)}*/}
          {/*{item && item.image && (*/}
          {/*  <button className="locations-sidebar-button  locations-sidebar-button--right">*/}
          {/*    <ArrowRightIcon />*/}
          {/*  </button>*/}
          {/*)}*/}

          {featureDetails && featureDetails.image ? (
            <SliderPreview
              sliderItems={sliderItems ?? []}
              className="locations-sidebar-img"
            />
          ) : (
            <NoFeaturePlaceholder
              type={FEATURE_TYPE.IMAGE}
              onClick={handleEditFeature}
            />
          )}
          <div className="locations-sidebar-info-wrapper">
            <div className="locations-sidebar-info-container">
              <div className="locations-sidebar-info">
                <span>{t("components.pageSidebar.info")}</span>
                <button
                  onClick={() => setIsInfoVisible((prev) => !prev)}
                  className={`${
                    isInfoVisible
                      ? "locations-sidebar-info-button locations-sidebar-info-button--visible"
                      : "locations-sidebar-info-button"
                  }`}
                >
                  <ChevronRight />
                </button>
              </div>

              {isInfoVisible && (
                <div className="locations-sidebar-info-items">
                  {handleRenderSpecificData()}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="locations-sidebar-settings">
          {/*  TODO: delete items in v2 app */}
          {/*<button onClick={() => setIsSidebarVisible(false)}>*/}
          {/*  <TrashIcon /> {t("common.delete")}*/}
          {/*</button>*/}

          {renderCustomButton()}
          <button onClick={handleEditFeature}>
            <Icon name="edit" />{" "}
            {t("common.editFeature", {
              feature: editFeatureText(),
            })}
          </button>
        </div>
      </div>
    </>
  );
}
