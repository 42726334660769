import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import {
  AddObjectForm,
  IFullscreenModalData,
  IObjectResponse,
} from "../../../../api/types";
import { FeatureContext } from "../../../../context/feature-context/FeatureContext";
import { ObjectItemList } from "../../../object/object-list/ObjectItemList";

export function ObjectPlacementPreview({
  objectDetails,
  onEdit,
  setShowFullscreenModal,
}: {
  objectDetails: IObjectResponse | undefined;
  onEdit: (id: string | number) => void;
  setShowFullscreenModal?: Dispatch<SetStateAction<IFullscreenModalData>>;
}) {
  const [openItem, setOpenItem] = useState<number | string>();
  const { getLocationFloor } = useContext(FeatureContext);

  const object = useMemo(() => {
    if (objectDetails === undefined) return;
    return {
      ...objectDetails.product,
      productLocationMappings: objectDetails.productLocationMappings,
    } as unknown as AddObjectForm;
  }, [objectDetails]);

  const fk_location = objectDetails?.productLocationMappings?.[0]?.fk_location;

  const handleItemToggle = useCallback(
    (id: number | string | undefined, open: boolean) => {
      setOpenItem((v) => (v === id ? (open ? id : undefined) : open ? id : v));
    },
    []
  );

  useLayoutEffect(() => {
    if (fk_location) getLocationFloor(fk_location);
  }, [fk_location, getLocationFloor]);

  if (!object || !fk_location) return null;

  return (
    <ObjectItemList
      onEdit={onEdit}
      object={object}
      locationId={fk_location}
      setShowFullscreenModal={setShowFullscreenModal}
      isOpen={openItem === object.id}
      handleItemToggle={handleItemToggle}
    />
  );
}
