import { FC } from "react";

import { StepDotsProps } from "./index.props";
import "./styles.scss";

export const StepDots: FC<StepDotsProps> = ({
  isVisible,
  activeStep,
  steps,
}) => {
  return (
    <div className={`${isVisible ? "step-dots" : "step-dots--hidden"}`}>
      {steps.map((step) => (
        <button
          key={step}
          className={`step-dots__dot ${
            activeStep === step ? "step-dots__dot--active" : ""
          }`}
        />
      ))}
    </div>
  );
};
