import {
  Context,
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import { IMediaFeature, IMediaItem, MediaASMXResponse } from "../../api/types";
import { FEATURE_MEDIA_TYPE, FEATURE_UUID } from "../../contants/FeatureUuid";
import { MediaApi } from "../../services/MediaApi";
import { IAttachment } from "../../utils/types/types";
import { IContext } from "../ContextInterfaces";

interface MediaContextConfig {
  saveMedia: (image: File, isPublic: boolean) => Promise<void | string>;
  updateMedia: (
    image: File | null,
    mediaUuid: string,
    featureType: FEATURE_MEDIA_TYPE,
    featureUuid: number,
    attachment?: IAttachment,
    existingRowId?: number
  ) => Promise<void | string>;
  deleteMedia: (id: number) => Promise<MediaASMXResponse> | undefined;
  setAttachments: Dispatch<SetStateAction<IAttachment[] | []>>;
  getAttachments: (
    featureId: number,
    featureType: FEATURE_MEDIA_TYPE
  ) => Promise<IMediaFeature[]>;
  attachments: IAttachment[];
  attachmentsList: IMediaFeature[] | [];
  clearAttachmentsList: () => void;
}

const defaultConfig: MediaContextConfig = {
  saveMedia: () => Promise.reject(),
  updateMedia: () => Promise.reject(),
  deleteMedia: () => Promise.reject(),
  setAttachments: () => Promise.reject(),
  getAttachments: () => Promise.reject(),
  attachments: [],
  attachmentsList: [],
  clearAttachmentsList: () => undefined,
};

export const MediaContext: Context<MediaContextConfig> =
  createContext(defaultConfig);

export const MediaContextProvider: FC<IContext> = ({
  children,
  environment,
}) => {
  const [attachments, setAttachments] = useState<IAttachment[] | []>([]);
  const [attachmentsList, setAttachmentsList] = useState<IMediaFeature[] | []>(
    []
  );

  const saveMedia = useCallback(
    async (image: File, isPublic = false) => {
      return new MediaApi(environment.api)
        .createMedia({
          extraParams: [],
          data: {
            config: {
              configs: [
                { key: "isPublic", value: isPublic ? "true" : "false" },
              ],
            },
            mime: image.type,
            name: image.name,
            direction: 0,
            owner_uuid: "",
            parent_object_uuid: FEATURE_UUID.MEDIA || "",
            parent_object_type: "digi_theme_column_temp",
          },
        })
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            if (!response.data.d.success) {
              console.error("Could not create media", response.data.d);
              return;
            }
            const uuid = response.data.d.data.filter(
              (d: IMediaItem) => d.key === "uuid"
            )[0].value;

            return new MediaApi(environment.api)
              .saveMedia(uuid, image)
              .then(async (response) => {
                if (response.kind === KIND_RESPONSE.OK) {
                  return uuid;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            console.log("createMedia failed");
          }
        })
        .catch(console.error);
    },
    [environment]
  );

  const updateMedia = useCallback(
    async (
      image: File | null,
      mediaUuid: string,
      featureType: FEATURE_MEDIA_TYPE,
      featureUuid: number,
      attachment?: IAttachment,
      mediaRowId?: number
    ) => {
      return new MediaApi(environment.api)
        .updateMedia(FEATURE_UUID.MEDIA, {
          geom: null,
          media: mediaUuid,
          description:
            attachment && attachment.description?.length
              ? attachment.description
              : "",

          fk_location: undefined,
          fk_organization: undefined,
          fk_product: undefined,
          [featureType]: featureUuid,
          geom_wkt: "",
          title:
            attachment && attachment.title?.length
              ? attachment.title
              : image?.name ?? "",
          file_type: image?.type ?? attachment?.file_type ?? "",
          id: mediaRowId,
          geom_modified: false,
        })
        .then(async (response) => {
          if (response.kind === KIND_RESPONSE.OK) {
            if (!response.data.d.success) {
              return;
            }
          } else {
            console.log("updateMedia failed");
          }
        })
        .catch(console.error);
    },
    [environment]
  );

  const deleteMedia = useCallback(
    (id: number) => {
      if (!environment) return;
      return new MediaApi(environment.api).deleteMedia(FEATURE_UUID.MEDIA, id);
    },
    [environment]
  );

  const getAttachments = useCallback(
    async (
      featureId: number,
      featureType: FEATURE_MEDIA_TYPE
    ): Promise<IMediaFeature[]> => {
      setAttachmentsList([]);
      const response = await new MediaApi(environment.api).downloadAttachments(
        `${featureType}=${featureId}`
      );

      if (response) {
        if (response.kind === KIND_RESPONSE.OK) {
          setAttachmentsList(response?.data?.features);

          setAttachments(
            response?.data?.features.map((item: IMediaFeature) => ({
              mediaUuid: item.media,
              title: item?.title,
              owner: "",
              description: item.description,
              file_type: item.file_type,
              id: item.id,
            }))
          );

          return response?.data?.features;
        }
      }

      return [];
    },
    [environment]
  );

  const clearAttachmentsList = useCallback(() => {
    setAttachmentsList([]);
  }, [setAttachmentsList]);

  if (!environment) {
    return null;
  }

  return (
    <MediaContext.Provider
      value={{
        saveMedia,
        updateMedia,
        deleteMedia,
        getAttachments,
        setAttachments,
        attachments,
        attachmentsList,
        clearAttachmentsList,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
