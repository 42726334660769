import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import NumberInput from "@avinet/adaptive-ui-core/form/controls/NumberInput";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { t } from "i18next";
import { useCallback, useContext, useMemo, useRef } from "react";

import { KIND_RESPONSE } from "../../../../api/api-problem";
import {
  AddObjectForm,
  IMediaFeature,
  IObjectMappings,
  ObjectResponse,
  PostMedia,
} from "../../../../api/types";
import {
  ALERT_MESSAGE_STATUS,
  ALERT_MESSAGE_TYPE,
} from "../../../../contants/Enums";
import { FEATURE_MEDIA_TYPE } from "../../../../contants/FeatureUuid";
import { useNotificationApi } from "../../../../context/NotificationProvider";
import { FeatureContext } from "../../../../context/feature-context/FeatureContext";
import { OrganisationContext } from "../../../../context/organisation-context/OrganisationContext";
import { useAttachmentEdit } from "../../../../hooks/useAttachmentEdit";
import { useAttachments } from "../../../../hooks/useAttachments";
import { useSelectOptions } from "../../../../hooks/useSelectOptions";
import { useTranslationPath } from "../../../../hooks/useTranslationPath";
import { guidGenerator } from "../../../../utils/index.utils";
import { AttachmentModal } from "../../../attachment-modal/AttachmentModal";
import { FeatureModalHeader } from "../../../feature/feature-modal-header";
import { FeatureMainTitle } from "../../../feature/feature-modal-main-title";
import { FeatureModalWrapper } from "../../../feature/feature-modal-wrapper";
import { FileDropzone } from "../../../file-dropzone";
import { InputSelect } from "../../../input-select/InputSelect";
import { Modal } from "../../../modal";
import "./AddNewMapping.scss";

export interface ObjectMappingFormState {
  fk_floor?: number;
  fk_room?: number;
  quantity: number;
  obj_condition: string;
  uuid?: string;
}

export function AddNewMapping({
  onClose,
  onProductUpdate,
  locationId,
  object,
  mapId,
}: {
  onClose: () => void;
  onProductUpdate: (res: ObjectResponse | void) => void;
  locationId: number;
  object: AddObjectForm;
  mapId?: string;
}) {
  const mappingFormRef = useRef(null);
  const { editProduct, locationFloors } = useContext(FeatureContext);
  const { selectedOrganisation } = useContext(OrganisationContext);
  const { conditionStatus } = useSelectOptions();
  const { notificationDialog, toast } = useNotificationApi();
  const tForm = useTranslationPath("components.objectModal.form");
  const tNotificationDialog = useTranslationPath(
    "components.objectModal.form.notficationDialog"
  );

  const { postOrUpdateMedia } = useAttachments();

  const objectId = useMemo(
    () => (object?.id ? Number(object.id) : undefined),
    [object.id]
  );

  const defaultValues = useMemo(
    () =>
      object?.productLocationMappings?.find(
        (mapping: IObjectMappings) => mapping.uuid === mapId
      ),
    [mapId, object.productLocationMappings]
  );

  const {
    attachmentsState,
    handleDropImage,
    showAttachmentModal,
    openAttachmentModal,
    closeAttachmentModal,
    attachmentThumbnail,
    handleRemoveDropImage,
  } = useAttachmentEdit(FEATURE_MEDIA_TYPE.PRODUCT_MAPPING, defaultValues?.id);

  const mode = defaultValues ? "edit" : "add";

  const checkProductMatch = useCallback(
    (data: ObjectMappingFormState, product: IObjectMappings) => {
      return (
        product.fk_organization === selectedOrganisation?.id &&
        product.fk_location === locationId &&
        product.fk_product === objectId &&
        product.fk_floor == data.fk_floor &&
        product.fk_room == data.fk_room &&
        (product.obj_condition == data.obj_condition ||
          (product.obj_condition === "No condition" && !data.obj_condition))
      );
    },
    [locationId, objectId, selectedOrganisation?.id]
  );

  const checkIfMappingExist = useCallback(
    (data: ObjectMappingFormState) => {
      return object.productLocationMappings.some(
        (product) => checkProductMatch(data, product) && product.uuid !== mapId
      );
    },
    [checkProductMatch, mapId, object.productLocationMappings]
  );

  const handleCreateSubmit = useCallback(
    async (dataInput: ObjectMappingFormState) => {
      if (!selectedOrganisation?.id || !objectId) return;

      let existChoice: string | null = null;

      const data = { ...dataInput };

      const isRoomValid = !!locationFloors
        .find((floor) => floor.id === data.fk_floor)
        ?.room_list.some((room) => room.id === data.fk_room);

      if (!isRoomValid) {
        data.fk_room = undefined;
      }

      if (checkIfMappingExist(data)) {
        const choice = await notificationDialog({
          title: tNotificationDialog("title"),
          message: tNotificationDialog("message"),
          icon: "warning",
          choices: [
            { value: "cancel", label: t("common.cancel") },
            {
              value: "overwrite",
              label: tNotificationDialog("overwrite"),
            },
            {
              value: "summarize",
              label: tNotificationDialog("summarize"),
              primaryOption: true,
            },
          ],
        });

        if (choice === "cancel") return;
        existChoice = choice as string;
      }

      const objCopy = { ...object };

      const newMappingObject = {
        uuid: guidGenerator(),
        fk_product: objectId,
        fk_organization: selectedOrganisation?.id,
        fk_location: locationId,
        ...data,
      };

      if (mode === "add") {
        if (!existChoice) {
          // Adding new mapping
          objCopy.productLocationMappings = [
            ...objCopy.productLocationMappings,
            newMappingObject,
          ];
        }

        if (existChoice === "overwrite") {
          // Overwriting existing mapping
          objCopy.productLocationMappings = objCopy.productLocationMappings.map(
            (product) =>
              checkProductMatch(data, product)
                ? {
                    ...product,
                    quantity: newMappingObject.quantity,
                  }
                : product
          );
        }

        if (existChoice === "summarize") {
          // Adding to existing mapping
          objCopy.productLocationMappings = objCopy.productLocationMappings.map(
            (product) =>
              checkProductMatch(data, product)
                ? {
                    ...product,
                    quantity: product?.quantity + newMappingObject?.quantity,
                  }
                : product
          );
        }
      } else if (mode === "edit") {
        if (!existChoice) {
          // Editing existing mapping
          objCopy.productLocationMappings = objCopy.productLocationMappings.map(
            (product) => (product.uuid === mapId ? newMappingObject : product)
          );
        }

        if (existChoice === "overwrite") {
          // Overwriting existing simular mapping
          objCopy.productLocationMappings = objCopy.productLocationMappings
            .map((product) =>
              checkProductMatch(data, product)
                ? {
                    ...product,
                    quantity: newMappingObject.quantity,
                  }
                : product
            )
            .filter((product) => product.uuid !== mapId);
        }

        if (existChoice === "summarize") {
          // Summarizing to existing simular mapping
          objCopy.productLocationMappings = objCopy.productLocationMappings
            .map((product) =>
              checkProductMatch(data, product)
                ? {
                    ...product,
                    quantity: product?.quantity + newMappingObject?.quantity,
                  }
                : product
            )
            .filter((product) => product.uuid !== mapId);
        }
      }

      // Submitting the changes
      // If not exist, submiting changes first and then adding media
      // If exist, adding media first and then submiting changes

      let res;

      if (!existChoice) {
        res = await editProduct(objCopy, objectId);

        const mappingId =
          res?.kind === "ok" &&
          res.data.productLocationMappings.find((mapping) =>
            checkProductMatch(data, mapping)
          )?.id;

        if (!mappingId) return;

        postOrUpdateMedia(
          attachmentsState,
          FEATURE_MEDIA_TYPE.PRODUCT_MAPPING,
          mappingId
        );
      } else {
        const mappingId = object.productLocationMappings.find((mapping) =>
          checkProductMatch(data, mapping)
        )?.id;

        if (!mappingId) return;

        const attachmentCopy = structuredClone(attachmentsState).map(
          (attachment: IMediaFeature | PostMedia) => ({
            ...attachment,
            fk_product_location_mapping: mappingId,
          })
        );

        await postOrUpdateMedia(
          attachmentCopy,
          FEATURE_MEDIA_TYPE.PRODUCT_MAPPING,
          mappingId
        );

        res = await editProduct(objCopy, objectId);
      }

      if (res?.kind === KIND_RESPONSE.OK) {
        onProductUpdate(res);
      } else {
        toast({
          title: t("common.error"),
          message: t("common.saveError"),
          type: ALERT_MESSAGE_TYPE.PROFILE,
          status: ALERT_MESSAGE_STATUS.FAILED,
        });
      }

      onClose();
    },
    [
      selectedOrganisation?.id,
      objectId,
      checkIfMappingExist,
      object,
      locationFloors,
      locationId,
      mode,
      onClose,
      notificationDialog,
      tNotificationDialog,
      checkProductMatch,
      mapId,
      editProduct,
      postOrUpdateMedia,
      attachmentsState,
      onProductUpdate,
      toast,
    ]
  );

  const { form, state, setDirty } = useForm<ObjectMappingFormState>({
    defaultValues: defaultValues,
    onSubmit: handleCreateSubmit,
  });

  const floorsOptions = useMemo(() => {
    const options = locationFloors.map((floor) => ({
      value: floor.id,
      label: floor.name,
    }));

    return options || [];
  }, [locationFloors]);

  const roomsOptions = useMemo(() => {
    const selectedFloor = locationFloors.find(
      (floor) => floor.id === state.fk_floor
    );

    return (
      (selectedFloor &&
        selectedFloor.room_list.map((room) => ({
          value: room.id,
          label: room.name,
        }))) ||
      []
    );
  }, [locationFloors, state.fk_floor]);

  return (
    <>
      <Modal show>
        <div className="modal-content-container object-mapping-modal">
          <FeatureModalHeader
            modalState={onClose}
            onClose={onClose}
            title={
              mode === "add"
                ? t("components.objectModal.titleRegisterObject")
                : t("components.objectModal.titleEditObject")
            }
            subtitle={
              mode === "add"
                ? t("components.objectModal.descriptionRegisterObject")
                : t("components.objectModal.descriptionEditObject")
            }
          />
          <FormProvider form={form} state={state}>
            <form
              ref={mappingFormRef}
              className="form"
              id="add-mapping-form"
              onSubmit={form.submit}
              translate={undefined}
            >
              <FeatureModalWrapper>
                <section className="feature-modal-drop">
                  <FileDropzone
                    onDrop={handleDropImage}
                    attachmentButtonClick={openAttachmentModal}
                    setDirty={setDirty}
                    attachmentsCount={attachmentsState.length}
                    text={t("components.locationModal.asideAttachments")}
                    maxFiles={20}
                    attachmentPreview
                    thumbnailMedia={attachmentThumbnail}
                  />
                </section>
              </FeatureModalWrapper>
              <FeatureModalWrapper>
                <section>
                  <FeatureMainTitle
                    text={t("components.objectModal.aside.amount")}
                  />
                  <NumberInput
                    id="quantity"
                    required
                    label={tForm("labels.amount")}
                    placeholder={tForm("placeholders.amount")}
                  />
                </section>
              </FeatureModalWrapper>
              <FeatureModalWrapper>
                <section>
                  <FeatureMainTitle
                    text={t("components.objectModal.aside.floor")}
                  />
                  <InputSelect
                    id="fk_floor"
                    items={floorsOptions}
                    label={tForm("labels.floor")}
                    placeholder={tForm("placeholders.floor")}
                  />
                  <InputSelect
                    id="fk_room"
                    disabled={!state.fk_floor || !roomsOptions.length}
                    items={roomsOptions}
                    label={tForm("labels.room")}
                    placeholder={tForm("placeholders.room")}
                  />
                </section>
              </FeatureModalWrapper>

              <FeatureModalWrapper>
                <section>
                  <FeatureMainTitle
                    text={t("components.objectModal.aside.additionalInfo")}
                  />
                  <InputSelect
                    id="obj_condition"
                    items={conditionStatus}
                    label={tForm("labels.condition")}
                    placeholder={tForm("placeholders.condition")}
                  />
                </section>
              </FeatureModalWrapper>
            </form>
            <Row className="buttom-buttons" wrap>
              <button className="btn light" type="button" onClick={onClose}>
                {t("common.cancel")}
              </button>
              <button className="btn" type="submit" form="add-mapping-form">
                {t("common.save")}
              </button>
            </Row>
          </FormProvider>
        </div>
      </Modal>
      {showAttachmentModal && (
        <AttachmentModal
          onClose={closeAttachmentModal}
          attachments={attachmentsState}
          setDirty={setDirty}
          onDropImage={handleDropImage}
          handleRemoveDropImage={handleRemoveDropImage}
        />
      )}
    </>
  );
}
