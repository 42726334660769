import React, { FC } from "react";

const UploadIcon: FC = () => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66602 11.8333L9.99935 8.5M9.99935 8.5L13.3327 11.8333M9.99935 8.5V16M16.666 12.4524C17.6839 11.6117 18.3327 10.3399 18.3327 8.91667C18.3327 6.38536 16.2807 4.33333 13.7493 4.33333C13.5673 4.33333 13.3969 4.23833 13.3044 4.08145C12.2177 2.23736 10.2114 1 7.91602 1C4.46424 1 1.66602 3.79822 1.66602 7.25C1.66602 8.97175 2.36222 10.5309 3.48847 11.6613"
        stroke="#4A5578"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
