import {
  Context,
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { KIND_RESPONSE } from "../../api/api-problem";
import { DICTIONARY_UUID } from "../../contants/DictionaryUuid";
import { FEATURE_UUID } from "../../contants/FeatureUuid";
import LOCAL_STORAGE from "../../contants/LocalStorage";
import { DictionaryApi } from "../../services/DictionaryApi";
import { getFromStore, saveToStore } from "../../utils/functions/local-storage";
import { ISelectedOption } from "../../utils/types/types";
import { IContext } from "../ContextInterfaces";
import { FeatureContext } from "../feature-context/FeatureContext";

const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000; // 7 days, 24 hours, 60 minutes, 60 seconds, 1000 milliseconds

interface HelpContextConfig {
  getDictionaries: () => void;
  getCategoryName: (id: number) => string | undefined;
  getParentCategoryName: (id: number) => string | undefined;
  getElementName: (id: number) => string | undefined;
  fetchCategoryDictionary: (
    uuid: DICTIONARY_UUID,
    localStorageKey: LOCAL_STORAGE,
    columns?: string[]
  ) => void;
  categoryDictionary: ISelectedOption[];
  parentCategoryDictionary: ISelectedOption[];
  elementDictionary: ISelectedOption[];
}

const defaultConfig: HelpContextConfig = {
  getDictionaries: () => Promise.reject(),
  getCategoryName: () => undefined,
  getParentCategoryName: () => undefined,
  getElementName: () => undefined,
  fetchCategoryDictionary: () => Promise.reject(),
  categoryDictionary: [],
  parentCategoryDictionary: [],
  elementDictionary: [],
};

function getExpirationKey(key: LOCAL_STORAGE) {
  if (key === LOCAL_STORAGE.DICTIONARY_CATEGORY) {
    return LOCAL_STORAGE.DICTIONARY_CATEGORY_EXPIRATION_TIME;
  } else if (key === LOCAL_STORAGE.DICTIONARY_PARENT_CATEGORY) {
    return LOCAL_STORAGE.DICTIONARY_PARENT_CATEGORY_EXPIRATION_TIME;
  } else {
    return LOCAL_STORAGE.DICTIONARY_ELEMENT_EXPIRATION_TIME; //element
  }
}

export const DictionaryContext: Context<HelpContextConfig> =
  createContext(defaultConfig);

export const DictionaryContextProvider: FC<IContext> = ({
  children,
  environment,
}) => {
  const [categoryDictionary, setCategoryDictionary] = useState<
    ISelectedOption[] | []
  >([]);
  const [parentCategoryDictionary, setParentCategoryDictionary] = useState<
    ISelectedOption[] | []
  >([]);
  const [elementDictionary, setElementDictionary] = useState<
    ISelectedOption[] | []
  >([]);
  const { downloadSelectFeature } = useContext(FeatureContext);

  const setDictionaryData = useCallback(
    (uuid: DICTIONARY_UUID, data: ISelectedOption[]) => {
      switch (uuid) {
        case DICTIONARY_UUID.CATEGORY:
          return setCategoryDictionary(data);
        case DICTIONARY_UUID.PARENT_CATEGORY:
          return setParentCategoryDictionary(data);
        case DICTIONARY_UUID.ELEMENT:
          return setElementDictionary(data);
        default:
          return null;
      }
    },
    []
  );

  const fetchCategoryDictionary = useCallback(
    async (
      uuid: DICTIONARY_UUID,
      localStorageKey: LOCAL_STORAGE,
      columns?: string[]
    ): Promise<void> => {
      const dictionaryData = await getFromStore<ISelectedOption[]>(
        localStorageKey
      );

      const expirationKey = getExpirationKey(localStorageKey);
      const expirationTime = await getFromStore<number>(expirationKey);

      const shouldReloadDictionary =
        !expirationTime || expirationTime < Date.now();

      if (!dictionaryData || shouldReloadDictionary) {
        const response = await new DictionaryApi(environment.api).getDictionary(
          uuid,
          columns
        );

        if (response) {
          if (response.kind === KIND_RESPONSE.OK) {
            const convertedDictionary = response?.data?.d?.records.map(
              (dictionary) => {
                const parent_category =
                  dictionary.overkategori ?? Number(dictionary.kategori);

                return {
                  value: dictionary.id ?? dictionary.kode,
                  label: dictionary.navn,
                  parent_category,
                };
              }
            );

            setDictionaryData(uuid, convertedDictionary);

            await saveToStore<ISelectedOption[]>(
              localStorageKey,
              convertedDictionary
            );

            //Cache for one week
            const expiresTimestamp = Date.now() + ONE_WEEK_IN_MS;
            await saveToStore<number>(expirationKey, expiresTimestamp);
          }
        }
      } else {
        setDictionaryData(uuid, dictionaryData);
      }
    },
    [environment.api, setDictionaryData]
  );

  const getDictionaries = useCallback(async () => {
    downloadSelectFeature(FEATURE_UUID.LOCATION);
    await fetchCategoryDictionary(
      DICTIONARY_UUID.CATEGORY,
      LOCAL_STORAGE.DICTIONARY_CATEGORY,
      ["overkategori"]
    );
    await fetchCategoryDictionary(
      DICTIONARY_UUID.PARENT_CATEGORY,
      LOCAL_STORAGE.DICTIONARY_PARENT_CATEGORY
    );
    await fetchCategoryDictionary(
      DICTIONARY_UUID.ELEMENT,
      LOCAL_STORAGE.DICTIONARY_ELEMENT,
      ["id", "kategori"]
    );
  }, [fetchCategoryDictionary, downloadSelectFeature]);

  const getCategoryName = useCallback(
    (id: number) => {
      const category = categoryDictionary.find(
        (dictionary) => dictionary.value === id
      );
      return category?.label;
    },
    [categoryDictionary]
  );

  const getParentCategoryName = useCallback(
    (id: number) => {
      const parentCategory = parentCategoryDictionary.find(
        (dictionary) => dictionary.value === id
      );
      return parentCategory?.label;
    },
    [parentCategoryDictionary]
  );

  const getElementName = useCallback(
    (id: number) => {
      const element = elementDictionary.find(
        (dictionary) => dictionary.value === id
      );
      return element?.label;
    },
    [elementDictionary]
  );

  const context = useMemo(() => {
    return {
      fetchCategoryDictionary,
      categoryDictionary,
      parentCategoryDictionary,
      elementDictionary,
      getDictionaries,
      getCategoryName,
      getParentCategoryName,
      getElementName,
    };
  }, [
    categoryDictionary,
    elementDictionary,
    fetchCategoryDictionary,
    getCategoryName,
    getDictionaries,
    getElementName,
    getParentCategoryName,
    parentCategoryDictionary,
  ]);

  if (!environment) {
    return null;
  }

  return (
    <DictionaryContext.Provider value={context}>
      {children}
    </DictionaryContext.Provider>
  );
};
