export enum SIDEBAR_ICON_TYPE {
  HOME = "HOME",
  PROJECT = "PROJECT",
  LOCATION = "LOCATION",
  OBJECT = "OBJECT",
  MAP = "MAP",
  NOTIFICATION = "NOTIFICATION",
  HELP = "HELP",
  LOGOUT = "LOGOUT",
  SETTINGS_COG = "SETTINGS_COG",
  INVITE_PEOPLE = "INVITE_PEOPLE",
}

export enum CHILDREN_FLOW {
  COLUMMN = 0,
  ROW = 1,
}

export enum USER_PROFILE_MODAL_TYPE {
  NAME = "NAME",
  EMAIL = "EMAIL",
  WORK = "WORK",
  ADDRESS = "ADDRESS",
  PHONE = "PHONE",
  PASSWORD = "PASSWORD",
  NOTIFICATION = "NOTIFICATION",
  LANGUAGE = "LANGUAGE",
}

export enum ORGANISATION_MODAL_TYPE {
  NAME = "NAME",
  ORG_NUMBER = "ORG_NUMBER",
  DESCRIBTION = "DESCRIBTION",
}

export enum ALERT_MESSAGE_TYPE {
  PROFILE = "PROFILE",
  FILE = "FILE",
  INVITE = "INVITE",
  PLAN = "PLAN",
}

export enum ALERT_MESSAGE_STATUS {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  REJECTED = "REJECTED",
}
export enum FEATURE_TYPE {
  LOCATION = "LOCATION",
  LOCATION_PRODUCT = "LOCATION_PRODUCT",
  LOCATION_PROJECT = "LOCATION_PROJECT",
  PROJECT = "PROJECT",
  PROJECT_LOCATION = "PROJECT_LOCATION",
  OBJECT = "OBJECT",
  OBJECT_MAPPING = "OBJECT_MAPPING",
  OBJECT_PROJECT = "OBJECT_PROJECT",
  OBJECT_SESSION = "OBJECT_SESSION",
  ATTACHMENT = "ATTACHMENT",
  IMAGE = "IMAGE",
  PROJECT_PRODUCTS = "PROJECT_PRODUCTS",
}

export enum FK_FEATURE_TYPE {
  LOCATION = "fk_location",
  PROJECT = "fk_project",
  OBJECT = "fk_product",
}

export enum LOCATION_STATUS {
  ALL = "All",
  RENOVATION = "Renovation",
  NEW = "New",
  DEMOLISHED = "Demolition",
  STATIC = "Static",
  COMPLETED = "Completed",
}

export enum OBJECT_STATUS {
  ALL = "All",
  GOOD = "Good condition",
  MEDIUM = "Ok condition",
  BAD = "Bad condition",
  NO_CONDITION = "No condition",
}

export enum QUANTITY_UNIT {
  STK = "stk",
  M2 = "m2",
  M3 = "m3",
  LM = "lm",
  OTHER = "other",
}

export enum PROJECT_STATUS {
  ALL = "All",
  PLANNING = "Planning",
  IN_PROGRESS = "In progress",
  COMPLETED = "Completed",
}

export enum BUILDING_CATEGORY {
  HOUSE = "House",
  APARTMENT = "Apartment building",
  DAYCARE = "Daycare/kindergarten",
  OFFICE = "Office building",
  SCHOOL = "School",
  UNIVERSITY = "University",
  HOSPITAL = "Hospital",
  NURSING = "Nursing",
  HOTEL = "Hotel",
  GYMNASIUM = "Gymnasium",
  COMMERCIAL = "Commercial building",
  CULTURAL = "Cultural building",
  LIGHT_INDUSTRY = "Light industry/workshop",
  STORAGE = "Storage",
  PARK = "Park",
  OTHER = "Other",
}

export enum BUILDING_DEMOLITION_TYPE {
  PLANNED = "Planned",
  ESTIMATED = "Estimated",
}

export enum FILE_TYPE {
  IMAGE,
  FILE,
  XLS,
  DOC,
  PPT,
  PDF,
}

export enum THUMBNAIL_SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum GRID_OPTION {
  GRID = "grid",
  TABLE = "table",
}

export enum ASSESSMENT_RATINGS {
  ALL = "",
  NOT_RATED = "",
  ARCHIVED = "Archived",
  SUITABLE = "Project",
  INTERNAL_USE = "Internal",
  EXTERNAL_USE = "External",
  WASTE = "Waste",
}

export enum USER_ROLE {
  ADMIN = "Admin",
  MEMBER = "Member",
}

export const DEFAULT_MEDIA_UUID = "00000000-0000-0000-0000-000000000000";
