import { ColorShades } from "@avinet/adaptive-ui-core/adaptive-ui/AdaptiveUiTheme";
import { SVGProps } from "react";

type SVGRProps = {
  customColor?: string | ColorShades;
  strokeWidth?: number;
};

const CloseIcon = ({
  customColor,
  strokeWidth,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width={12} height={12} fill="none" viewBox="0 0 12 12" {...props}>
    <path
      stroke={`${customColor ? customColor : "#5D6B98"}`}
      strokeWidth={strokeWidth ? strokeWidth : 1}
      d="M11 1 1 11M1 1l10 10"
    />
  </svg>
);
export default CloseIcon;
