import FormProvider from "@avinet/adaptive-ui-core/form/FormProvider";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import useForm from "@avinet/adaptive-ui-core/form/useForm";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { KIND_RESPONSE } from "../../api/api-problem";
import { LoginRequest } from "../../api/types";
import { AlertMessage } from "../../components/alert-message";
import { AuthLanguageSelect } from "../../components/auth-language-select";
import { Navigation } from "../../components/navigation";
import { PasswordInput } from "../../components/password-input/PasswordInput";
import { ImageBanner } from "../../components/register/image-banner";
import { RegisterContent } from "../../components/register/register-content";
import { RegisterTitle } from "../../components/register/register-title";
import { RegisterWrapper } from "../../components/register/register-wrapper";
import { registerUrl, resetPasswordUrl } from "../../contants/Routes";
import { PrivacyConsentContext } from "../../context/privacy-consent-context/PrivacyConsentContext";
import { AppUserContext } from "../../context/user-context/UserContext";
import { useTranslationPath } from "../../hooks/useTranslationPath";

export function Login() {
  const { t } = useTranslation();
  const { login, user, alertMessage } = useContext(AppUserContext);
  const { checkPrivacyConsent } = useContext(PrivacyConsentContext);
  const tForm = useTranslationPath("form");

  const onSubmit = useCallback(
    (data: LoginRequest) => {
      login(data).then(checkPrivacyConsent);
    },
    [checkPrivacyConsent, login]
  );

  const { form, state } = useForm({
    onSubmit,
  });

  const getUserErrorMsg = useCallback((): string => {
    switch (user.error?.kind) {
      case KIND_RESPONSE.UNAUTHORIZED:
        if (user.error.body === "auth_11") {
          return t("errors.tooManyLoginAttempts");
        } else {
          return t("errors.errorLogin");
        }
      default:
        return "";
    }
  }, [t, user]);

  return (
    <RegisterWrapper>
      <RegisterContent withCopyrights>
        <RegisterTitle
          text={t("pages.login.leftSide.title")}
          description={t("pages.login.leftSide.description")}
        >
          <AuthLanguageSelect />
          <FormProvider form={form} state={state}>
            <form onSubmit={form.submit} className="form">
              <Input
                id="email"
                type="email"
                label={tForm("email")}
                placeholder={tForm("emailPlaceholder")}
                required
                autoComplete="email"
              />
              <PasswordInput
                id="password"
                label={tForm("password")}
                placeholder={tForm("enterPasswordPlaceholder")}
                required
                info={getUserErrorMsg()}
                autoComplete="current-password"
              />
              <div className="form__forgot-password">
                <Navigation
                  url={resetPasswordUrl}
                  navText={t("pages.login.leftSide.forgotPassword")}
                />
              </div>
              <button className="btn btn-form">{t("common.login")}</button>
            </form>
          </FormProvider>
          <Navigation
            url={registerUrl}
            navText={t("pages.login.leftSide.accountTextButton")}
            text={t("pages.login.leftSide.accountText")}
          />
        </RegisterTitle>
      </RegisterContent>
      <ImageBanner
        title={t("pages.login.rightSide.title")}
        description={t("pages.login.rightSide.title2")}
      />
      <AlertMessage message={alertMessage} />
    </RegisterWrapper>
  );
}
