import { useMemo } from "react";

/**
 * Hook to create a target for React.createPortal.
 *
 * @example
 * const target = usePortalTarget(id);
 * return createPortal(children, target);
 *
 * @param id The id of the target container, e.g 'modal' or 'spotlight'
 * @param className Class name for the
 * @returns The DOM node to use as the Portal target.
 */
export function usePortalTarget(
  id: string,
  className = "",
  tag = "div"
): HTMLElement {
  return useMemo(() => {
    let parent = document.getElementById(id);

    if (!parent) {
      parent = document.createElement(tag);
      parent.id = id;
      document.body.append(parent);
    }

    parent.className = className;

    return parent;
  }, [id, className, tag]);
}
