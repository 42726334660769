import { useCheckMobile } from "../../hooks/useCheckMobile";
import "./BatchSelect.scss";
import { BatchSelectBar } from "./BatchSelectBar";
import { BatchSelectProps } from "./BatchSelectProps";
import { BatchSelectSmall } from "./BatchSelectSmall";

export function BatchSelect({ count, onClose, children }: BatchSelectProps) {
  const { isMobile } = useCheckMobile(600);
  const Component = isMobile ? BatchSelectSmall : BatchSelectBar;

  return (
    <div className="batch-select-bar-wrapper">
      <Component count={count} onClose={onClose}>
        {children}
      </Component>
    </div>
  );
}
