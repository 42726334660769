import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { THUMBNAIL_SIZE } from "../../contants/Enums";
import { ImagePreview } from "../image-preview/ImagePreview";
import { FileDropzoneProps } from "./index.props";
import "./styles.scss";

export function FileDropzone({
  file,
  deleteFile,
  onDrop,
  text,
  maxFiles = 1,
  attachmentsCount,
  attachmentButtonClick,
  attachmentPreview,
  editMode,
  noFilesAccept,
  fileTypeText,
  setDirty,
  thumbnailMedia,
}: FileDropzoneProps) {
  const { t } = useTranslation();
  const [loadedFile, setLoadedFile] = useState<File | null>(null);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        onDrop(file);
      }
      setLoadedFile(acceptedFiles?.[0]);
      setDirty?.(true);
    },
    [onDrop, setDirty]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    multiple: maxFiles !== 1,
    noClick: maxFiles === 1 && !!file && file.length > 0,
    onDrop: handleDrop,
    accept: noFilesAccept
      ? {
          "image/*": [".jpeg", ".jpg", ".png", ".svg", ".avif", ".webp"],
        }
      : {
          "image/*": [".jpeg", ".jpg", ".png", ".svg", ".avif", ".webp"],
          "application/file": [
            ".pdf",
            ".doc",
            ".docx",
            ".xls",
            ".xlsx",
            ".ppt",
            ".pptx",
          ],
        },
  });

  return (
    <div>
      {text && <p className="dropzone-label">{text}</p>}
      <div className="container">
        {attachmentPreview && (
          <div className="dropzone-attachments">
            <ImagePreview
              image={thumbnailMedia}
              size={THUMBNAIL_SIZE.SMALL}
              className="thumbnail-image"
            />
            <button
              type="button"
              className="btn light"
              onClick={attachmentButtonClick}
              disabled={!attachmentsCount}
            >
              {t("common.openAttachments")}
              <div className="dropzone-attachments__btn-amount">
                {attachmentsCount ?? 0}
              </div>
            </button>
          </div>
        )}
        <div
          className={`dropzone ${
            maxFiles === 1 && file && file?.length && (loadedFile || editMode)
              ? "dropzone--has-img"
              : ""
          }`}
          {...getRootProps()}
        >
          <input {...getInputProps()} className="dropzone__input" />
          {maxFiles === 1 &&
          file &&
          file?.length &&
          (loadedFile || editMode) ? (
            <>
              <img src={file} className="dropzone__img" />
              <div
                onClick={() => {
                  deleteFile?.(true);
                  setLoadedFile(null);
                }}
                className="dropzone__img__delete"
              >
                <Icon name="delete" />
              </div>
            </>
          ) : (
            <>
              <Icon name="upload" className="dropzone__icon" />
              <p className="dropzone__upload-text">
                {t("common.clickToUpload")}
                <span>{t("common.dragDrop")}</span>
              </p>
              <span className="dropzone__available-image">{fileTypeText}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
