import { useCallback, useState } from "react";

import { IPageTableColumnMeta } from "../components/page/page-table/table-header/index.props";

export function useColumnState(initialColumnMeta: IPageTableColumnMeta[]) {
  const [columnState, setColumnState] =
    useState<IPageTableColumnMeta[]>(initialColumnMeta);

  const updateColumnVisibility = useCallback(
    (columnKey: string, active: boolean) => {
      setColumnState((prev) =>
        prev.map((column) =>
          column.key === columnKey
            ? {
                ...column,
                active: active,
              }
            : column
        )
      );
    },
    []
  );

  const resetAllColumns = useCallback(() => {
    setColumnState(initialColumnMeta);
  }, [initialColumnMeta]);

  return {
    columnState,
    updateColumnVisibility,
    resetAllColumns,
  };
}
