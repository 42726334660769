import { IAlertMessages } from "../api/types";
import { ALERT_MESSAGE_STATUS, ALERT_MESSAGE_TYPE } from "./Enums";

export const userProfileMenu = [
  {
    id: "profile",
    text: "components.userProfileMenu.profile",
  },
  {
    id: "security",
    text: "components.userProfileMenu.security",
  },
  {
    id: "preferences",
    text: "components.userProfileMenu.preferences",
  },
  {
    id: "policy",
    text: "components.userProfileMenu.policy",
  },
];

export const organizationDetailsMenu = [
  {
    id: "organisation",
    text: "components.organizationProfileMenu.organisation",
  },
  {
    id: "members",
    text: "components.organizationProfileMenu.members",
  },
];

export const defaultAlertMessage: IAlertMessages = {
  title: "",
  description: "",
  type: ALERT_MESSAGE_TYPE.FILE,
  status: ALERT_MESSAGE_STATUS.SUCCESS,
};
