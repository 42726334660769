import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import { NavigationProps } from "./index.props";
import "./styles.scss";

export const Navigation: FC<NavigationProps> = ({ url, text, navText }) => {
  return (
    <div className="nav-link">
      <p>
        {text ?? <p>{text}</p>} <NavLink to={url}>{navText}</NavLink>
      </p>
    </div>
  );
};
